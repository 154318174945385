import { withStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect, useMemo, useState } from 'react';
import { useNotify, usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import ItemActions from '../../../components/navigation/aside/itemActions';
import { AVATAR_PROPERTY, TICKETING_PLACE_OR_EVENT } from '../../../providers/resources';
import { registerPlacesOrEventsPlaceCount } from '../../../redux/actions/placesOrEvents';
import { CustomReducerState } from '../../../redux/reducers';
import { FieldCapitalizeListStyles, FieldHeaderListStyles, StyledBadge } from '../../../theme';
import {
    PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION,
    PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION,
} from '../../../utils/CONST';
import extractIdFromURI from '../../../utils/id';
import { hasPermissions } from '../../../utils/permissions';
import { getPlaceOrEventCount } from '../../../utils/placeOrEvent';
import { ROLE_TICKETING_VIEW_EDIT_STRUCTURE } from '../../../utils/ROLES';
import { Avatar, NavigationItemActionType, PlaceOrEventCount } from '../../../utils/types';
import ShowMediaResolver from '../../fields/showMediaResolver/showMediaResolver';
import useGetList from '../../react-query-hooks/useGetList';
import { useActionsContext } from '../useActionsContext';

const useTreeItemStyles = makeStyles({
    image: {
        marginRight: 5,
        height: 20,
        width: 20,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
    },
    label: {
        fontSize: 14,
        marginRight: 10,
    },
    labelMarginLeft: {
        fontSize: 14,
        marginRight: 10,
        marginLeft: 12,
    },
    labelMarginLeftWithBadge: {
        fontSize: 14,
        marginRight: 10,
        marginLeft: 17,
    },
    iconButton: {
        padding: 2,
        marginLeft: 5,
    },
    icon: {
        fontSize: 20,
    },
    checkbox: {
        padding: 0,
    },
    //@ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
        ...FieldCapitalizeListStyles,
    },
    metadataAvatarTypeImageField: {
        marginRight: 5,
        display: 'flex',
    },
    badgeRoot: {
        backgroundColor: 'red',
    },
});

interface Props {
    label: string;
    icon: React.ElementType<SvgIconProps>;
    item: Avatar;
    notifyAction: (actionType: NavigationItemActionType) => void;
}

export const StyledRootBadge = withStyles((theme) => ({
    badge: {
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 3px',
        minWidth: 10,
        height: 15,
        top: 9,
        marginRight: '-7px',
        // marginLeft: 10,
    },
}))(Badge);

const Item = ({ label, item, notifyAction }: Props) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const translate = useTranslate();
    const classes = useTreeItemStyles();

    const {
        itemToReorder,
        itemToMove,
        itemToClone,
        reorderActionState,
        cloneActionState,
        cloneTo,
        moveActionState,
        moveTo,
        setItemMoveToAction,
        setItemReorderToAction,
        setItemToMoveAction,
        setItemCloneToAction,
    } = useActionsContext();

    const [mouseIn, setMouseIn] = useState(false);

    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);
    const currentPlacesOrEventsCount: PlaceOrEventCount | null = useSelector((state: CustomReducerState) => {
        const key = Object.keys(state.ticketing.placesOrEventsCount).find(
            (placeOrEventId) => placeOrEventId === item.id
        );
        return key ? state.ticketing.placesOrEventsCount[key] : null;
    });

    const [hasItemIncidentInProgress, setHasItemIncidentInProgress] = useState<boolean>(false);
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const { data: rootAvatarPropertyList } = useGetList(
        AVATAR_PROPERTY,
        {
            pagination: {
                page: 1,
                perPage: 1,
            },
            sort: {
                field: 'id',
                order: 'ASC',
            },
            filter: {
                'avatar.id': item.id,
                definition: PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION,
                name: PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION,
            },
        },
        {
            keyToFind: `navigationIsRoot_${extractIdFromURI(item.id as string)}`,
        }
    );
    const isRoot = useMemo(
        () =>
            rootAvatarPropertyList && rootAvatarPropertyList.length > 0
                ? JSON.parse(rootAvatarPropertyList[0].value)
                : false,
        [rootAvatarPropertyList]
    );

    const toggleMouseIn = (value: boolean) => () => setMouseIn(value);

    const treeLevelSelectedUri = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.levelItemSelected
    );

    const handleSelectItemToMoveClick = (e: any) => {
        e.stopPropagation();
        setItemToMoveAction(item);
        notify('app.messages.selectWhereToMoveTheItem', 'info');
    };

    const handleSelectItemToReorderClick = (e: any) => {
        e.stopPropagation();
        setItemReorderToAction(item);
        notify('app.messages.selectWhereToMoveTheItem', 'info');
    };

    const handleSelectMoveToClick = (e: any) => {
        e.stopPropagation();
        setItemMoveToAction(item);
    };

    const handleSelectCloneToClick = (e: any) => {
        e.stopPropagation();
        setItemCloneToAction(item);
    };

    const showBadgeIncidentInProgress = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.configuration.options.enabled
    );

    useEffect(() => {
        if (item && showBadgeIncidentInProgress && !currentPlacesOrEventsCount) {
            getPlaceOrEventCount(
                (dataJson: any) => {
                    if (dataJson && Array.isArray(dataJson)) {
                        const placeOrEvent: PlaceOrEventCount = dataJson[0];
                        setHasItemIncidentInProgress(placeOrEvent.count > 0);
                        dispatch(registerPlacesOrEventsPlaceCount.fn(placeOrEvent, item.id));
                    }
                },
                (error: any) => {
                    setHasItemIncidentInProgress(false);
                    console.error('Getting ', TICKETING_PLACE_OR_EVENT, 'count', error);
                },
                extractIdFromURI(item.id as string),
                tokenWithRoles,
                true
            ).catch((error: any) => {
                console.error('Getting ', TICKETING_PLACE_OR_EVENT, 'count', error);
            });
        }
        if (item && showBadgeIncidentInProgress && currentPlacesOrEventsCount) {
            setHasItemIncidentInProgress(currentPlacesOrEventsCount.count > 0);
        }
    }, []);

    const renderRootBadge = () => {
        return isRoot ? (
            <div style={{ display: 'flex' }}>
                <StyledRootBadge badgeContent={translate('app.navigation.tree.shortRoot')} color='primary' />
                <Typography variant='body2' className={classes.labelMarginLeftWithBadge}>
                    {label}
                </Typography>
            </div>
        ) : (
            <div>
                <Typography variant='body2' className={classes.label}>
                    {label}
                </Typography>
            </div>
        );
    };

    const isChild = (itemToReorder: Avatar, item: Avatar) => {
        // @ts-ignore
        const parentId =
            itemToReorder && 'parent' in itemToReorder && itemToReorder.parent && '@id' in itemToReorder.parent
                ? itemToReorder.parent['@id']
                : null;
        // @ts-ignore
        const itemParentId =
            item && 'parent' in item && item.parent && '@id' in item.parent ? item.parent['@id'] : null;

        if (parentId === null || itemParentId === null) return false;

        return parentId === itemParentId;
    };

    return (
        <div className={classes.root} onMouseEnter={toggleMouseIn(true)} onMouseLeave={toggleMouseIn(false)}>
            <ShowMediaResolver
                itemId={
                    item.metadataAvatarType && typeof item.metadataAvatarType === 'object'
                        ? (item.metadataAvatarType.id as string)
                        : item.metadataAvatarType
                }
            />

            <div>
                {hasItemIncidentInProgress && showBadgeIncidentInProgress ? (
                    <StyledBadge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        badgeContent=' '
                        overlap={'circular'}
                        classes={{
                            badge: classes.badgeRoot,
                        }}
                    >
                        {renderRootBadge()}
                    </StyledBadge>
                ) : (
                    renderRootBadge()
                )}
            </div>

            {treeLevelSelectedUri &&
                hasPermissions([ROLE_TICKETING_VIEW_EDIT_STRUCTURE], loadedPermissions, permissions) && (
                    <ItemActions item={item} isMouseIn={mouseIn} notifyAction={notifyAction} />
                )}

            {((moveActionState && itemToMove && item['@id'] === itemToMove['@id']) ||
                (reorderActionState && itemToReorder && item['@id'] === itemToReorder['@id']) ||
                (cloneActionState && itemToClone && item['@id'] === itemToClone['@id'])) && (
                <ArrowForwardIcon className={classes.icon} />
            )}

            {moveActionState && moveTo && item['@id'] === moveTo['@id'] && <ArrowBackIcon className={classes.icon} />}
            {moveActionState && !itemToMove && (
                <Checkbox className={classes.checkbox} onClick={handleSelectItemToMoveClick} />
            )}
            {moveActionState && itemToMove && item['@id'] !== itemToMove['@id'] && !moveTo && (
                <Checkbox className={classes.checkbox} onClick={handleSelectMoveToClick} />
            )}

            {cloneActionState &&
                itemToClone &&
                cloneTo &&
                item['@id'] === cloneTo['@id'] &&
                itemToClone['@id'] !== cloneTo['@id'] && <ArrowBackIcon className={classes.icon} />}
            {cloneActionState && !itemToClone && (
                <Checkbox className={classes.checkbox} onClick={handleSelectItemToMoveClick} />
            )}
            {cloneActionState && itemToClone && item['@id'] !== itemToClone['@id'] && !cloneTo && (
                <Checkbox className={classes.checkbox} onClick={handleSelectCloneToClick} />
            )}

            {reorderActionState &&
                itemToReorder &&
                item['@id'] !== itemToReorder['@id'] &&
                isChild(itemToReorder, item) && (
                    <Checkbox className={classes.checkbox} onClick={handleSelectItemToReorderClick} />
                )}
        </div>
    );
};

export default Item;
