import { createTheme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import { Avatar } from '../../../utils/types';
import '../index.css';
import SeeIncidentButton from '../actions/listActionsButtons/seeIncidentButton';
import ReminderButton from '../actions/listActionsButtons/reminderButton';
import RestartButton from '../actions/listActionsButtons/restartButton';
import DelegateButton from '../actions/listActionsButtons/delegateButton';
import CloseButton from '../actions/listActionsButtons/closeButton';
import EditButton from '../actions/listActionsButtons/editButton';

const ContainerDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '30px 30px',
    alignItems: 'center',
    justifyContent: 'center',
});

interface Props {
    record: Avatar;
}

const greenButtonTheme = createTheme({
    palette: {
        // primary: {
        //     main: '#008000',
        // },
        secondary: {
            main: '#008000',
        },
    },
});

const ListActionButtons = ({ record }: Props) => {
    return (
        <ContainerDiv>
            <div>
                <SeeIncidentButton record={record} />
            </div>

            <div>
                <ReminderButton record={record as Avatar} />
            </div>

            <div>
                <RestartButton record={record as Avatar} />
            </div>

            <div>
                <DelegateButton record={record as Avatar} />
            </div>

            <div>
                <CloseButton record={record as Avatar} />
            </div>

            <div>
                <EditButton record={record as Avatar} />
            </div>
        </ContainerDiv>
    );
};

export default ListActionButtons;
