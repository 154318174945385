import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import {
    BooleanInput,
    CreateResult,
    Record,
    ReduxState,
    TextInput,
    UpdateResult,
    useCreate,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AutocompleteInput } from '../../../../commons/ra/input/AutocompleteInput';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import useCreateLanguage from '../../../../hooks/useCreateLanguages';
import {
    GROUP_METADATA_AVATAR_TYPES,
    LANGUAGES,
    MEDIA_LINKS,
    MEDIA_LINKS_FOR_CREATE,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
} from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL, TAG_TICKETING_LOGO } from '../../../../utils/CONST';
import {
    CATEGORY_FIELD,
    CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER,
    CUSTOM_FIELDS_ICON_SVG_COLLECTION,
    FORCE_FILE_SYSTEM_MAPPER_FIELD,
    ICON_FIELD,
    ICON_SVG_COLLECTION_FIELD,
    IMAGE_FIELD,
    NAME_FIELD,
    SVG_ICON_SYSTEM,
    TICKETING_FILESYSTEM_MAPPER_ENABLED,
    TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD,
} from '../../../../utils/metadataAvatarType';
import {
    eventTypeCustomProcessFileSystemMapperEnabledLanguages
} from '../../../../utils/properties/metadataAvatarTypeProperties';
import { getCleanMediaName } from '../../../../utils/strings';
import {
    Language,
    Media,
    MetadataAvatarType,
    MetadataAvatarTypeProperty,
    MetadataAvatarTypePropertyDatum,
    User,
} from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import ImageIconSelect from '../../../image-icon-select/image-icon-select';
import { useQueryClient } from '@tanstack/react-query';

const collection = process.env.REACT_APP_ICONS_COLLECTION;

interface EditFormProps {
    loadImage: boolean;
    imageSrc?: string;
    currentMedia?: Media;
    selected: MetadataAvatarType;
    updateAndResolveImage: (data: any, justRemove?: boolean) => Promise<void>;
    onCancel: () => void;
    submitFinish: boolean;
    removingMedia: boolean;
    savingMedia: boolean;
    handleFinish: any;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const URL_KEY = 'url';

const EditForm = ({
    selected,
    loadImage,
    imageSrc,
    currentMedia,
    updateAndResolveImage,
    onCancel,
    removingMedia,
    submitFinish,
    savingMedia,
    handleFinish,
}: EditFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [icon, setIcon] = useState<any>(null);
    const [iconEdited, setIconEdited] = useState(false);
    const [create, { loading: loadingCreate }] = useCreate();
    const [update, { loading: loadingUpdate }] = useUpdate();
    const queryClient = useQueryClient();
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );
    const [updateMetadataAvatarType, { loading }] = useUpdate(METADATA_AVATAR_TYPES);
    const [updateMetadataAvatarTypeProperties] = useUpdate(METADATA_AVATAR_TYPE_PROPERTIES);

    const propertiesDataInState = useSelector(
        (state: ReduxState) => state.admin.resources[METADATA_AVATAR_TYPE_PROPERTIES].data
    );
    const fileSystemMapperEnabledPropertyInState: Record | undefined = Object.values(propertiesDataInState).find(
        (property) =>
            (property as MetadataAvatarTypeProperty).metadataAvatarType === selected.id &&
            (property as MetadataAvatarTypeProperty).name.toLowerCase() ===
                TICKETING_FILESYSTEM_MAPPER_ENABLED.toLowerCase()
    );

    const [initialFileSystemMapperEnableProperty, setInitialFileSystemMapperEnableProperty] = useState<
        boolean | undefined
    >(
        fileSystemMapperEnabledPropertyInState
            ? JSON.parse((fileSystemMapperEnabledPropertyInState as MetadataAvatarTypeProperty).values[0])
            : undefined
    );

    const getProperties = (metadataAvatarTypeId: string, name: string, onSuccess: (value: boolean) => void) => {
        dataProvider
            .getList(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: { field: 'createAt', order: 'DESC' },
                    filter: {
                        metadataAvatarType: selected.id,
                        name,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (
                            response &&
                            'data' in response &&
                            response.data &&
                            Array.isArray(response.data) &&
                            response.data.length > 0
                        ) {
                            if ((response.data[0] as MetadataAvatarTypeProperty).values.length === 0) return;
                            onSuccess(JSON.parse((response.data[0] as MetadataAvatarTypeProperty).values[0]));
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getSubcategoriesPropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.getSubcategoriesPropertyError', 'error');
            });
    };

    const findLogoInMedias = (medias: any[]) => {
        let logo = null;

        medias.map((media: any) => {
            if (
                typeof media === 'object' &&
                'customFields' in media &&
                'type' in media.customFields &&
                media.customFields.type === TAG_TICKETING_LOGO
            ) {
                logo = media;
            }
        });

        return logo;
    };

    useEffect(() => {
        if (fileSystemMapperEnabledPropertyInState === undefined) {
            getProperties(selected.id as string, TICKETING_FILESYSTEM_MAPPER_ENABLED, (value: boolean) =>
                setInitialFileSystemMapperEnableProperty(value)
            );
        }
    }, [fileSystemMapperEnabledPropertyInState]);

    useEffect(() => {
        if (selected) {
            const medias = 'medias' in selected ? selected.medias : [];
            const logo: any = findLogoInMedias(medias);
            if (logo) {
                setIcon(logo);
            }
        }
    }, [selected]);

    const findLogo = () => {
        const medias = 'medias' in selected ? selected.medias : [];
        const logo: any = findLogoInMedias(medias);
        if (logo) {
            const { fileType } = logo.customFields;
            const { name } = logo;
            return `${name}.${fileType}`;
        }
        return null;
    };

    /**Create and get languages*/
    for (const key of Object.keys(eventTypeCustomProcessFileSystemMapperEnabledLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessFileSystemMapperEnabledLanguages[key]);
    }

    /**Get properties from state*/
    const getLanguagesFromState = (propertyLanguages: { en: string; fr: string; es: string }) => {
        const languagesData = useSelector((state: ReduxState) => state.admin.resources[LANGUAGES].data);
        return Object.values(languagesData)
            .map((item: Record) => {
                for (const key of Object.keys(propertyLanguages)) {
                    if (
                        (item as Language).twoLetters.toLowerCase().trim() === key.toLowerCase().trim() &&
                        (item as Language).value.toLowerCase().trim() ===
                            // @ts-ignore
                            propertyLanguages[key].toLowerCase().trim()
                    ) {
                        return (item as Language).id;
                    }
                }
            })
            .filter((item) => item !== undefined);
    };

    const eventTypeCustomProcessFileSystemMapperEnabledLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessFileSystemMapperEnabledLanguages
    );

    const createMetadataAvatarTypeProperty = (data: MetadataAvatarTypePropertyDatum, _private = false) => {
        dataProvider
            .create(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    data: {
                        metadataAvatarType: data.metadataAvatarType,
                        name: data.name,
                        values: data.values,
                        languages: data.languages,
                        fieldType: data.fieldType,
                        option: data.option,
                        private: _private,
                        migrateToAvatar: data.migrateToAvatar,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (response && 'data' in response && response.data) {
                            refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.updateFileSystemMapperEnabledMetadataAvatarTypePropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.createForceMetadataAvatarTypePropertyError', 'error');
            });
    };

    const createOrUpdateMetadataAvatarTypeProperties = (fileSystemMapperEnabled: boolean) => {
        // update fileSystemMapperEnableProperty if the state value is different to value entered by user
        if (
            fileSystemMapperEnabledPropertyInState &&
            JSON.parse((fileSystemMapperEnabledPropertyInState as MetadataAvatarTypeProperty).values[0]) !==
                fileSystemMapperEnabled
        ) {
            updateMetadataAvatarTypeProperties(
                METADATA_AVATAR_TYPE_PROPERTIES,
                fileSystemMapperEnabledPropertyInState.id,
                {
                    id: fileSystemMapperEnabledPropertyInState.id,
                    values: [fileSystemMapperEnabled ? 'true' : 'false'],
                },
                {
                    ...fileSystemMapperEnabledPropertyInState,
                },
                {
                    onSuccess: (response: UpdateResult) => {
                        if (response && 'data' in response && response.data) {
                            refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.updateFileSystemMapperEnabledMetadataAvatarTypePropertyError', 'error');
                    },
                }
            );
        } else if (!fileSystemMapperEnabledPropertyInState) {
            createMetadataAvatarTypeProperty(
                {
                    metadataAvatarType: selected.id as string,
                    name: TICKETING_FILESYSTEM_MAPPER_ENABLED,
                    values: [fileSystemMapperEnabled ? 'true' : 'false'],
                    languages: eventTypeCustomProcessFileSystemMapperEnabledLanguagesInState,
                    option: 'value',
                    fieldType: 'boolean',
                    migrateToAvatar: true,
                },
                false
            );
        }
    };

    const refreshProperties = (id: string) => {
        dataProvider
            .getOne(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    id,
                },
                {
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getSubcategoriesPropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.getSubcategoriesPropertyError', 'error');
            });
    };

    const handleUpdateMetadataAvatarType = (
        metadataAvatarTypeId: any,
        data: any,
        previousData: MetadataAvatarType,
        onSuccessCallback: any
    ) => {
        update(METADATA_AVATAR_TYPES, metadataAvatarTypeId, data, previousData, {
            onSuccess: () => {
                notify('app.subCategory.edit.notification', 'success');
                onSuccessCallback();
            },
            onFailure: (error: any) => {
                console.log('error', error);
                notify('app.subCategory.edit.errors.subcategoryNotUpdated', 'error');
            },
        });
    };

    const handleCreateIconMedia = (data: any, onSuccessCallback: any) => {
        create(MEDIA_LINKS_FOR_CREATE, data, {
            onSuccess: ({ data }: CreateResult) => {
                onSuccessCallback(data);
            },
            onFailure: (error) => {
                notify('app.subCategory.edit.errors.iconNotAdded', 'error');
            },
        });
    };

    const filterMedias = (medias: any[], iconId: string) => {
        const newMedias = [...medias.map((media) => media['@id'])];
        const oldIconIndex = newMedias.findIndex((id) => id.includes(MEDIA_LINKS));
        if (oldIconIndex !== -1) {
            newMedias[oldIconIndex] = iconId;
        } else {
            newMedias.push(iconId);
        }
        return newMedias;
    };

    const filterMediasToDelete = (medias: any[]) => {
        const newMedias = [...medias.map((media) => media['@id'])];
        return newMedias.filter((id) => !id.includes(MEDIA_LINKS));
    };

    const hasImage = () => imageSrc && currentMedia?.name;

    const hasIcon = () => selected && findLogo() !== null;

    const handleSubmit = (formValues: {
        [NAME_FIELD]: string;
        [IMAGE_FIELD]: { fileName: string; fileData: string; cropping?: boolean };
        [CATEGORY_FIELD]: string;
        [TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD]: boolean;
        [ICON_SVG_COLLECTION_FIELD]: boolean;
        [ICON_FIELD]: string;
    }) => {
        let newData: any = {
            alphaId: !selected.alphaId ? moment().toString() : selected.alphaId,
            name: formValues[NAME_FIELD],
            groupMetadataAvatarType: formValues[CATEGORY_FIELD],
            customFields: {
                [CUSTOM_FIELDS_ICON_SVG_COLLECTION]: formValues[ICON_SVG_COLLECTION_FIELD],
                [CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER]: !formValues[ICON_SVG_COLLECTION_FIELD],
            },
        };

        const iconInitValue = hasIcon() ? findLogo() : null;
        const currentIconNameValue = ICON_FIELD in formValues ? formValues[ICON_FIELD] : null;
        const iconEdited = iconInitValue !== currentIconNameValue;

        const handleIcon = (data: any, iconEdited: boolean, currentIconNameValue: string | null) => {
            const updateFn = (data: any) => {
                handleUpdateMetadataAvatarType(selected?.id, data, selected, () => {
                    handleFinish();
                    queryClient.invalidateQueries([`metadataAvatarType_${selected.id}`]);
                });
            };

            const deleteFn = (mediaId: string) => {
                dataProvider.delete(
                    MEDIA_LINKS,
                    { id: mediaId },
                    {
                        onSuccess: () => {
                            console.log('success');
                        },
                        onFailure: (error) => {
                            console.log('error', error);
                            notify('app.subCategory.edit.errors.iconNotRemoved', 'error');
                        },
                    }
                );
            };

            if (iconEdited) {
                if (icon) deleteFn(icon['@id']);

                if (currentIconNameValue) {
                    handleCreateIconMedia(
                        JSON.stringify({
                            fileType: 'svg',
                            name: currentIconNameValue.split('.')[0],
                            customFields: {
                                collection,
                                type: TAG_TICKETING_LOGO,
                            },
                            destinationCompany: userConnected?.company['@id'],
                            source: SVG_ICON_SYSTEM,
                        }),
                        (createdIcon: any) => {
                            newData = { ...data, medias: filterMedias(selected.medias, createdIcon['@id']) };
                            updateFn(newData);
                        }
                    );
                } else {
                    newData = { ...data, medias: filterMediasToDelete(selected.medias) };
                    updateFn(newData);
                }
            } else {
                updateFn(newData);
            }
        };

        const handleMedia = (justRemoveImage?: boolean) => {
            updateMetadataAvatarType(METADATA_AVATAR_TYPES, selected?.id, newData, selected, {
                onSuccess: async () => {
                    await updateAndResolveImage(formValues, justRemoveImage);
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.messages.imageNotRemoved', 'warning');
                    }
                },
            });
        };

        const previousIconValue: boolean =
            selected && 'customFields' in selected && CUSTOM_FIELDS_ICON_SVG_COLLECTION in selected.customFields
                ? selected.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                : false;
        const currentIconValue = formValues[ICON_SVG_COLLECTION_FIELD];

        if (currentIconValue) {
            if (previousIconValue !== currentIconValue) {
                handleMedia(true);
            }
            handleIcon(newData, iconEdited, currentIconNameValue);
        } else {
            if (previousIconValue !== currentIconValue) {
                handleIcon(newData, true, null);
            }
            handleMedia();
        }

        const fileSystemMapperEnabledFieldValue: boolean | null = _.get(
            formValues,
            FORCE_FILE_SYSTEM_MAPPER_FIELD,
            null
        );

        if (
            fileSystemMapperEnabledFieldValue !== null &&
            fileSystemMapperEnabledFieldValue !== initialFileSystemMapperEnableProperty
        ) {
            createOrUpdateMetadataAvatarTypeProperties(fileSystemMapperEnabledFieldValue);
        }
    };

    return (
        <Form
            initialValues={{
                [NAME_FIELD]: selected?.name,
                [CATEGORY_FIELD]:
                    typeof selected.groupMetadataAvatarType === 'object'
                        ? selected.groupMetadataAvatarType.id
                        : selected.groupMetadataAvatarType,
                ...(hasImage() && currentMedia
                    ? {
                          [IMAGE_FIELD]: {
                              [URL_KEY]: imageSrc,
                              fileName: getCleanMediaName(currentMedia.name),
                          },
                      }
                    : {}),
                ...(hasIcon()
                    ? {
                          [ICON_FIELD]: findLogo(),
                      }
                    : {}),
                [FORCE_FILE_SYSTEM_MAPPER_FIELD]: initialFileSystemMapperEnableProperty,
                [ICON_SVG_COLLECTION_FIELD]:
                    selected && 'customFields' in selected && CUSTOM_FIELDS_ICON_SVG_COLLECTION in selected.customFields
                        ? selected.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                        : false,
            }}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit, pristine, submitting, valid, values, form: { change } }) => {
                return (
                    <Grid container>
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <Grid item xs>
                                <TextInput
                                    required
                                    autoFocus
                                    variant={'standard'}
                                    key={NAME_FIELD}
                                    fullWidth
                                    type={'text'}
                                    label={translate('app.subCategory.edit.input.name')}
                                    source={NAME_FIELD}
                                />
                            </Grid>
                            <ReferenceInput
                                isRequired={true}
                                variant={'standard'}
                                label={translate('app.subCategory.edit.input.categories')}
                                source={CATEGORY_FIELD}
                                reference={GROUP_METADATA_AVATAR_TYPES}
                                filterToQuery={(searchText: string) => ({
                                    name: searchText,
                                })}
                                filter={{
                                    alias: GROUP_METADATA_AVATAR_TYPE_LEVEL,
                                    order__createdAt: 'DESC',
                                    hidden: false,
                                }}
                            >
                                <AutocompleteInput
                                    resettable
                                    translateChoice={false}
                                    fullWidth
                                    clearAlwaysVisible
                                    optionText={'name'}
                                    optionValue='@id'
                                    filterToQuery={(searchText: string) => ({
                                        name: searchText,
                                    })}
                                />
                            </ReferenceInput>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.subCategory.edit.input.fileSystemMapperEnabled')}
                                        </Typography>
                                    }
                                    source={FORCE_FILE_SYSTEM_MAPPER_FIELD}
                                />
                            </Grid>

                            <ImageIconSelect
                                values={values}
                                iconOrImageActive={values[ICON_SVG_COLLECTION_FIELD]}
                                changeIconType={change}
                                name={IMAGE_FIELD}
                                accept='image/*'
                            />

                            {loadImage && <LinearProgress style={{ marginBottom: 20 }} />}

                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={onCancel}
                                withCreate
                                isSubmitting={loadingCreate || loadingUpdate || loading || savingMedia || removingMedia}
                                isValidForm={
                                    iconEdited ||
                                    (!pristine &&
                                        valid &&
                                        !values[IMAGE_FIELD]?.cropping &&
                                        !submitting &&
                                        !submitFinish)
                                }
                                cancelButtonLabel={translate('app.cancel')}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    </Grid>
                );
            }}
        />
    );
};
export default EditForm;
