import React from 'react';
import { useSelector } from 'react-redux';
import Authentication from '../commons/auth/Authentication';
import CategoriesLevel from '../components/categoriesLevel';
import { CustomReducerState } from '../redux/reducers';
import { categoryLevelNameRoute } from './paths';

const CategoriesLevelPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={categoryLevelNameRoute}>{userConnected && <CategoriesLevel />}</Authentication>;
};

export default CategoriesLevelPage;
