export const FIREFOX_KEY = 'firefox';
export const CHROME_KEY = 'chrome';

export const detectBrowserName = () => {
    let browserName = '';
    if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'CHROME_KEY';
    } else if (navigator.userAgent.match(/firefox|fxios/i)) {
        browserName = FIREFOX_KEY;
    } else if (navigator.userAgent.match(/safari/i)) {
        browserName = 'safari';
    } else if (navigator.userAgent.match(/opr\//i)) {
        browserName = 'opera';
    } else if (navigator.userAgent.match(/edg/i)) {
        browserName = 'edge';
    } else {
        browserName = 'No browser detection';
    }
    return browserName;
};
