import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslate } from 'react-admin';
import BackDiv from '../../commons/BackDiv/BackDiv';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import CategoriesLevelList from './list/list';
import './styles.css';

const CategoriesLevelPage = () => {
    const translate = useTranslate();

    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.category.breadcrumbs.1')]}
                    lastItem={translate('app.category.breadcrumbs.0')}
                />
            </Grid>

            <Grid item>
                <BackDiv>
                    {({ divHeight }: { divHeight: number }) => (
                        <div className='categoriesTable' style={{ height: divHeight }}>
                            <CategoriesLevelList divHeight={divHeight} />
                        </div>
                    )}
                </BackDiv>
            </Grid>
        </Grid>
    );
};

export default CategoriesLevelPage;
