import { ListItemIcon, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import { makeStyles, styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FileCopy, StayCurrentPortrait } from '@material-ui/icons';
import CropFreeIcon from '@material-ui/icons/CropFree';
import SettingsIcon from '@material-ui/icons/Settings';
import axios from 'axios';
import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import extractIdFromURI from '../../utils/id';
import { Avatar } from '../../utils/types';
import QrCodeDialog from '../QrCodeDialog/QrCodeDialog';
import ReferenceField from '../ra/field/ReferenceField';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    backDiv: {
        borderTop: '2px solid #C4D600',
        height: '80vh',
        width: '100%',
        overflow: 'auto',
    },
    backDivWithoutHeight: {
        borderTop: '2px solid #C4D600',
        // height: '50vh',
        width: '100%',
        // overflow: 'auto',
    },
    backDivWhite: {
        backgroundColor: 'white',
    },
    backDivWithShadow: {
        backgroundColor: '#f1f1f1 !important',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    backHistoricDiv: {
        backgroundColor: '#f1f1f1',
    },
    root: {
        overflowY: 'auto',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    gridContainer: {
        backgroundColor: 'white',
        padding: '10px',
    },
    containerTitle: {
        // marginBottom: '8px',
        cursor: 'pointer',
    },
    typographyTitle: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: theme.typography.pxToRem(13),
        color: 'rgba(0, 0, 0, 0.64)',
    },
    cardTitle: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 'bold',
        marginBottom: 3,
    },
    cardSubheader: {
        fontSize: theme.typography.pxToRem(13),
        wordBreak: 'break-word',
    },
    cardRoot: {
        paddingTop: 7,
        paddingBottom: 5,
        paddingLeft: 3,
        paddingRight: 3,
    },
    fingersTableCellRoot: {
        padding: '5px',
    },
    links: {
        color: `${theme.palette.primary.main} !important`,
        // @ts-ignore
        margin: theme.spacing.unit,
        // @ts-ignore
        marginLeft: theme.spacing.unit * 3,
    },
    image: {
        // @ts-ignore
        marginLeft: theme.spacing.unit * 3,
    },
    imageButton: {
        width: 'fit-content',
        // @ts-ignore
        marginTop: theme.spacing.unit * 2,
        // @ts-ignore
        marginLeft: theme.spacing.unit * 3,
    },
    mcTableRoot: {
        width: '100%',
        marginLeft: '0px',
        backgroundColor: 'white',
    },
    mcEditButton: {
        width: '35px',
        padding: '0',
    },
    mcTableHeader: {
        paddingLeft: '8px !important',
        paddingRight: '4px !important',
        fontSize: 14,
    },
    mcTableCell: {
        paddingLeft: '8px !important',
        paddingRight: '4px !important',
        textTransform: 'uppercase',
        fontSize: 14,
    },
    textTrunc: {
        '&:before': {},
        '&:after': {},
        color: 'black',
        textTransform: 'uppercase',
        overflow: 'hidden',
        position: 'relative',
        lineHeight: '1.2em',
        maxHeight: '2.4em',
        textAlign: 'justify',
        marginRight: '0px',
        paddingRight: '0px',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapsedContainer: {
        maxHeight: '300px',
        overflowY: 'auto',
        width: '100%',
    },
    gridItemWithSize: {
        paddingTop: '0px !important',
        display: 'flow-root',
        overflowX: 'hidden',
    },
    divHistoric: {
        overflowX: 'hidden',
    },
    removeFlexbasis: {
        flexBasis: 'unset !important',
    },
    tableRowSelected: {
        backgroundColor: '#aaaaaaa6 !important',
    },
    iconButton: {
        padding: 8,
    },
    tableRow: {
        height: 40,
    },
    cardActions: {
        width: '100%',
        marginLeft: -20,
        marginTop: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        zIndex: 1,
    },
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        marginRight: 10,
        minWidth: 0,
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
    },
    menuText: {
        fontSize: 13,
        fontWeight: 500,
    },
    rootCheckbox: {
        padding: 0,
        // @ts-ignore
        marginRight: theme.spacing.unit,
    },
    multiSelectLabel: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
        color: theme.palette.primary.main,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
    },
}));

const CustomTableCell = styled(TableCell)({
    padding: 0,
});

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const GetYourls = ({ record, self }: any) => {
    useEffect(() => {
        /*If not exists then add to PlatformAvatarInfoMCs state*/
        const { yourls, setYourls } = self;
        let exists = false;
        yourls.map((item: any) => {
            if (item.id === record.id) exists = true;
        });

        if (!exists && record) {
            setYourls([
                ...yourls,
                {
                    id: record.id,
                    mcUrl: record.mcUrl,
                },
            ]);
        }
    });

    return (
        <span>
            <input
                type='text'
                value={record.mcUrl}
                id={`copy-${extractIdFromURI(record.id)}`}
                style={{ display: 'none' }}
            />
            {record.finger.name}
        </span>
    );
};

export const findYourls = (yourlsId: any, tokenWithRoles: string) => {
    const urlToFetch = `${entrypoint}/yourls/${extractIdFromURI(yourlsId)}`;
    return axios
        .get(urlToFetch, {
            headers: {
                Authorization: `Bearer ${tokenWithRoles}`,
            },
        })
        .then(({ data }: any) => {
            return data;
        })
        .catch((error: any) => {
            console.error(error);
            return null;
        });
};

interface Props {
    avatar: Avatar;
}

const ShowMcInfo = ({ avatar }: Props) => {
    const classes: any = useStyles();
    const [dialogState, setDialogState] = useState(false);
    const [mcMenuAnchorEl, setMcMenuAnchorEl] = useState(null);
    const [editingMC, setEditingMC] = useState<any>(null);
    const [mcToEdit, setMcToEdit] = useState(null);
    const [yourls, setYourls] = useState([]);
    const [currentMCUrl, setCurrentMCUrl] = useState('');
    const [showQrCode, setShowQrCode] = useState(false);
    const [downloadQrCode, setDownloadQrCode] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(1);
    const translate = useTranslate();
    const notify = useNotify();
    const tokenWithRoles: any = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );

    const handleDialogOpen = () => {
        setDialogState(true);
    };

    const handleDialogClose = () => {
        setDialogState(false);
    };

    const mcMenuHandleClick = (mc: any, event: any) => {
        const yourl: any = yourls.filter((yourl: any) => {
            return yourl.id === mc.yourls;
        })[0];
        setMcMenuAnchorEl(event.currentTarget);
        setEditingMC(mc);
        setCurrentMCUrl(yourl ? yourl.mcUrl : null);
    };

    const simulateMC = async () => {
        const objectMcs: any = avatar && avatar.mcs && avatar.mcs.length > 0 ? avatar.mcs : [];
        // @ts-ignore
        const mc = objectMcs.find((item: any) => 'id' in item && 'id' in editingMC && item.id === editingMC.id);
        if (mc) {
            const yourl = await findYourls(mc.yourls, tokenWithRoles);
            yourl && yourl.mcUrl && window.open(yourl.mcUrl, '_blank', 'width=411px,height=731px');
            mcMenuHandleClose();
        }
    };

    const showQrCodeFun = () => {
        setMcMenuAnchorEl(null);
        setDownloadQrCode(false);
        setTimeout(() => {
            setShowQrCode(true);
        }, 500);
    };

    const renderCopyToClipboard = () => (
        <CopyToClipboard text={currentMCUrl} onCopy={onCopy}>
            <MenuItem className={classes.menuRoot} data-test-sc={'menuItemCopy'}>
                <ListItemIcon className={classes.menuListItemRoot}>
                    <FileCopy color={'primary'} className={classes.menuIcon} />
                </ListItemIcon>
                <ListItemText
                    primary={translate('app.mc.copy')}
                    className={classes.menuTextRoot}
                    primaryTypographyProps={{
                        className: classes.menuText,
                        color: 'primary',
                    }}
                />
            </MenuItem>
        </CopyToClipboard>
    );

    const mcMenuHandleClose = () => {
        setMcMenuAnchorEl(null);
        setEditingMC(null);
    };

    const onCopy = () => {
        notify('app.notification.copied');
        mcMenuHandleClose();
    };

    const getYourlsClicked = () => {
        const yourl: any =
            yourls &&
            yourls.filter((yourl: any) => {
                return editingMC && yourl.id === editingMC.yourls;
            })[0];

        return yourl && yourl.mcUrl ? yourl.mcUrl : null;
    };

    const yourlsClicked = getYourlsClicked();

    const menuOptions: any = [
        {
            icon: <StayCurrentPortrait />,
            text: 'app.objects.actions.simulateWebApp',
            onClick: simulateMC,
            disabled: false,
            dataTestSC: 'menuItemVisitApp',
            roles: [],
        },
        {
            icon: <CropFreeIcon />,
            text: 'app.qrCode',
            onClick: showQrCodeFun,
            disabled: false,
            dataTestSC: 'menuItemShowQrCode',
            roles: [],
        },
        {
            renderComponent: renderCopyToClipboard(),
        },
    ];

    const mcs = avatar && avatar.mcs ? avatar.mcs : [];

    return (
        <Fragment>
            <Button
                size={'small'}
                onClick={handleDialogOpen}
                // disabled={!(avatar.mcs.length > 1)}
                color={'primary'}
            >
                <CropFreeIcon />
            </Button>

            <Dialog open={dialogState} onClose={handleDialogClose}>
                <DialogContent>
                    <Table className={classes.mcTableRoot}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <CustomTableCell key={`header-00`} className={classes.mcEditButton}>
                                    {' '}
                                </CustomTableCell>

                                <CustomTableCell className={classes.mcTableHeader}>
                                    {translate('app.mc.name')}
                                </CustomTableCell>

                                <CustomTableCell className={classes.mcTableHeader} align='right'>
                                    {translate('app.objects.service')}
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {mcs &&
                                mcs.map((item: any, i) => {
                                    return (
                                        <TableRow key={item.id} className={classes.tableRow}>
                                            <CustomTableCell key={`edit-${i}`} className={classes.mcEditButton}>
                                                {/*@ts-ignore*/}
                                                <IconButton
                                                    data-test-sc={'buttonActionMCs'}
                                                    onClick={(event: any) => mcMenuHandleClick(item, event)}
                                                    aria-label='Edit'
                                                    aria-owns={mcMenuAnchorEl ? 'mc-menu' : null}
                                                    aria-haspopup='true'
                                                    className={classes.iconButton}
                                                >
                                                    <SettingsIcon color={'primary'} />
                                                </IconButton>
                                            </CustomTableCell>

                                            <CustomTableCell key={`value${i}name`} className={classes.mcTableCell}>
                                                <Tooltip title={item.name}>
                                                    <div className={classes.textTrunc}>{item.name}</div>
                                                </Tooltip>
                                            </CustomTableCell>

                                            <CustomTableCell
                                                key={`value${i}-url`}
                                                className={classes.mcTableCell}
                                                align='right'
                                            >
                                                <ReferenceField
                                                    key={`key-${forceUpdate}-${0}-update`}
                                                    record={item}
                                                    source='yourls'
                                                    reference='yourls'
                                                    linkType={false}
                                                    basePath={'/app/objects'}
                                                >
                                                    <GetYourls self={{ yourls, setYourls }} />
                                                </ReferenceField>
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Menu id='mc-menu' anchorEl={mcMenuAnchorEl} open={Boolean(mcMenuAnchorEl)} onClose={mcMenuHandleClose}>
                {menuOptions.map((item: any, index: any) =>
                    'renderComponent' in item ? (
                        item.renderComponent
                    ) : (
                        <MenuItem
                            onClick={item.onClick}
                            className={classes.menuRoot}
                            disabled={item.disabled}
                            data-test-sc={item.dataTestSC ? item.dataTestSC : `item${index}`}
                        >
                            <ListItemIcon className={classes.menuListItemRoot}>
                                {cloneElement(item.icon, {
                                    ...item.icon.props,
                                    color: 'primary',
                                    className: classes.menuIcon,
                                })}
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(item.text)}
                                className={classes.menuTextRoot}
                                primaryTypographyProps={{
                                    className: classes.menuText,
                                    color: 'primary',
                                }}
                            />
                        </MenuItem>
                    )
                )}
            </Menu>

            {showQrCode && yourlsClicked && (
                <QrCodeDialog open={true} name={avatar.name} url={yourlsClicked} onClose={() => setShowQrCode(false)} />
            )}
        </Fragment>
    );
};

export default ShowMcInfo;
