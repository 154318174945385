import { Avatar, Node } from './types';

const getValueFromNode = (object: Avatar, nodeProperty: string, fn = (v: any) => v): any => {
    if (
        object &&
        'currentReferencing' in object &&
        object.currentReferencing &&
        object.currentReferencing?.standardReferencing?.runner?.node &&
        nodeProperty in object.currentReferencing.standardReferencing.runner.node
    ) {
        return fn(
            // @ts-ignore
            object.currentReferencing.standardReferencing.runner.node[nodeProperty]
        );
    }
    return '';
};

const getCurrentNode = (object: Avatar): Node | undefined => {
    return 'currentReferencing' in object &&
        object.currentReferencing &&
        object.currentReferencing?.standardReferencing?.runner?.node
        ? object?.currentReferencing?.standardReferencing?.runner?.node
        : undefined;
};

const isIdentifiersInAvatar = (
    avatar: Avatar | null,
    identifierToSearch: string
): { found: boolean; id: string | null } => {
    if (avatar && 'identifiers' in avatar && avatar.identifiers && Array.isArray(avatar.identifiers)) {
        const index = avatar.identifiers.findIndex((id) => id.startsWith(identifierToSearch));
        if (index !== -1) {
            return {
                found: true,
                id: avatar.identifiers[index].split(':')[1],
            };
            // return [true, avatar.identifiers[index].split(':')[1]];
        }
    }
    // return [false, null];
    return { found: false, id: null };
};

export { getValueFromNode, getCurrentNode, isIdentifiersInAvatar };
