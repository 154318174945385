import React from 'react';
import { AutocompleteInput } from '../../ra/input/AutocompleteInput';
import ReferenceInput from '../../ra/input/ReferenceInput';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';

interface Props {
    label: string;
    alwaysOn: boolean;
    disabled?: boolean;
    emptyText?: string;
}

const CategoryReferenceInputFilter = ({ label, alwaysOn, emptyText, disabled = false }: Props) => {
    return (
        <ReferenceInput
            disabled={disabled}
            alwaysOn={alwaysOn}
            variant={'standard'}
            label={label}
            filterToQuery={(searchText: string) => ({
                name: searchText,
            })}
            reference={GROUP_METADATA_AVATAR_TYPES}
            filter={{
                alias: GROUP_METADATA_AVATAR_TYPE_LEVEL,
            }}
            allowEmpty
            source={'parent___metadataAvatarType___groupMetadataAvatarType'}
        >
            <AutocompleteInput
                resettable
                allowEmpty
                emptyValue={null}
                emptyText={emptyText}
                translateChoice={false}
                fullWidth
                clearAlwaysVisible
                optionText={'name'}
                optionValue='@id'
                filterToQuery={(searchText: string) => ({
                    name: searchText,
                })}
            />
        </ReferenceInput>
    );
};
export default CategoryReferenceInputFilter;
