import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useEffect, useState } from 'react';
import { Identifier, useNotify, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import MenuButtonActions, { MenuItemType } from '../../../../commons/menuButtonActions';
import { selectTreeLevelItemCreator } from '../../../../redux/actions/navigation';
import { CustomReducerState } from '../../../../redux/reducers';
import {
    ROLE_DELETE_AVATAR,
    ROLE_READ_AVATAR_PROPERTY,
    ROLE_READ_OBJECT_TYPE,
    ROLE_READ_OBJECT_TYPE_PROPERTIES,
    ROLE_TICKETING_CREATE_PLACE_OR_EVENT,
    ROLE_TICKETING_VIEW_EDIT_STRUCTURE,
    ROLE_UPDATE_AVATAR,
} from '../../../../utils/ROLES';
import { Avatar, NavigationItemActionType } from '../../../../utils/types';
import AddItemDialog from '../../actions/AddItemDialog/AddItemDialog';
import DeleteItemDialog from '../../actions/DeleteItemDialog/DeleteItemDialog';
import EditItemDialog from '../../actions/EditItemDialog/EditItemDialog';
import ItemActionDialog from './ItemActionDialog';
import { useActionsContext } from '../../../../commons/tree/useActionsContext';

const createDialogKey = 'create';
const editDialogKey = 'edit';
const deleteDialogKey = 'delete';

interface DialogsStateType {
    [createDialogKey]: boolean;
    [editDialogKey]: boolean;
    [deleteDialogKey]: boolean;
}

interface Props {
    item: Avatar;
    isMouseIn: boolean;
    notifyAction?: (actionType: NavigationItemActionType) => void;
}

const ItemActions = ({ item, isMouseIn, notifyAction }: Props) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const notify = useNotify();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const showActionButton = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.configuration.options.enabled
    );
    const [menuState, setMenuState] = useState(false);
    const [dialogsState, setDialogsState] = useState<DialogsStateType>({
        [createDialogKey]: false,
        [editDialogKey]: false,
        [deleteDialogKey]: false,
    });
    const selectedItemUri: Identifier | null = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.levelItemSelected
    );

    const {
        reorderActionState,
        moveActionState,
        cloneActionState,
        setItemToMoveAction,
        setMoveActionState,
        setReorderActionState,
        setItemToReorderAction,
        setCloneActionState,
        setItemToCloneAction,
    } = useActionsContext();

    const onClick = (e: any) => {
        e.preventDefault();
        setShowDialog(true);
    };

    const handleToggle = () => {
        setMenuState(!menuState);
    };

    const handleOpenAddItemDialog = () => {
        setDialogStateByKey(createDialogKey, true);
    };

    const handleCloseAddItemDialog = () => {
        setDialogStateByKey(createDialogKey, false);
    };

    const handleOpenEditItemDialog = () => {
        setDialogStateByKey(editDialogKey, true);
    };

    const handleCloseEditItemDialog = () => {
        setDialogStateByKey(editDialogKey, false);
    };

    const handleOpenDeleteItemDialog = () => {
        setDialogStateByKey(deleteDialogKey, true);
    };

    const handleCloseDeleteItemDialog = () => {
        setDialogStateByKey(deleteDialogKey, false);
    };

    const setDialogStateByKey = (key: string, toState: boolean) => {
        const newDialogsState: DialogsStateType = { ...dialogsState };
        // @ts-ignore
        newDialogsState[key] = toState;
        setDialogsState(newDialogsState);
    };

    const handleMoveTo = () => {
        setMoveActionState(true);
        setItemToMoveAction(item);
        notify('app.messages.selectItemToMove', 'info');
    };

    const handleReorder = () => {
        setReorderActionState(true);
        setItemToReorderAction(item);
        notify('app.messages.selectItemToMove', 'info');
    };

    const handleClone = () => {
        setCloneActionState(true);
        setItemToCloneAction(item);
        notify('app.messages.selectItemToClone', 'info');
    };

    useEffect(() => {
        if (!isMouseIn) setMenuState(false);
    }, [isMouseIn]);

    const closeDialog = () => setShowDialog(false);

    const selectItemInTree = () => {
        dispatch(selectTreeLevelItemCreator.fn(item.id, false));
    };

    const menuActions: MenuItemType[] = [
        {
            icon: <AddIcon />,
            text: translate('app.menuActions.addItem'),
            onClick: () => {
                selectItemInTree();
                handleOpenAddItemDialog();
            },
            disabled: moveActionState || !selectedItemUri,
            roles: [ROLE_TICKETING_VIEW_EDIT_STRUCTURE, ROLE_READ_OBJECT_TYPE, ROLE_TICKETING_CREATE_PLACE_OR_EVENT],
        },
        {
            icon: <EditIcon />,
            text: translate('app.navigation.editItem.title'),
            onClick: () => {
                selectItemInTree();
                handleOpenEditItemDialog();
            },
            disabled: moveActionState || !selectedItemUri,
            roles: [ROLE_TICKETING_VIEW_EDIT_STRUCTURE, ROLE_UPDATE_AVATAR],
        },
        {
            icon: <AccountTreeIcon />,
            text: translate('app.menuActions.moveTo'),
            onClick: () => {
                selectItemInTree();
                handleMoveTo();
            },
            disabled: false,
            roles: [ROLE_TICKETING_VIEW_EDIT_STRUCTURE, ROLE_UPDATE_AVATAR],
        },
        {
            icon: <AccountTreeIcon />,
            text: translate('app.menuActions.reorder'),
            onClick: () => {
                handleReorder();
            },
            disabled: !('parent' in item && item.parent !== null),
            roles: [],
        },
        {
            icon: <FileCopyIcon />,
            text: translate('app.menuActions.clone'),
            onClick: () => {
                handleClone();
            },
            disabled: false,
            roles: [],
        },
        {
            icon: <DeleteIcon />,
            text: translate('app.menuActions.remove'),
            onClick: () => {
                selectItemInTree();
                handleOpenDeleteItemDialog();
            },
            disabled: moveActionState,
            roles: [ROLE_TICKETING_VIEW_EDIT_STRUCTURE, ROLE_DELETE_AVATAR],
        },
        {
            icon: <SettingsIcon />,
            onClick: (e) => {
                selectItemInTree();
                onClick(e);
            },
            text: translate('app.menuActions.config'),
            roles: [ROLE_TICKETING_VIEW_EDIT_STRUCTURE, ROLE_READ_AVATAR_PROPERTY, ROLE_READ_OBJECT_TYPE_PROPERTIES],
        },
    ];

    return (
        <div
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            {!reorderActionState && !moveActionState && !cloneActionState && isMouseIn && (
                <MenuButtonActions
                    buttonProps={{
                        disabled: !showActionButton,
                    }}
                    justIcon
                    buttonLabel={'app.menuActions.title'}
                    overwriteButtonLabel
                    menuActions={menuActions}
                    menuState={menuState}
                    onButtonClick={handleToggle}
                    startIcon={<SettingsIcon />}
                />
            )}

            {showDialog && <ItemActionDialog item={item} open={true} handleClose={closeDialog} />}

            {dialogsState[createDialogKey] && (
                <AddItemDialog
                    open={dialogsState[createDialogKey]}
                    onClose={handleCloseAddItemDialog}
                    onCancel={handleCloseAddItemDialog}
                    item={item}
                />
            )}

            {dialogsState[editDialogKey] && (
                <EditItemDialog
                    item={item}
                    notifyAction={notifyAction}
                    open={dialogsState[editDialogKey]}
                    onClose={handleCloseEditItemDialog}
                    onCancel={handleCloseEditItemDialog}
                />
            )}
            {dialogsState[deleteDialogKey] && (
                <DeleteItemDialog
                    open={dialogsState[deleteDialogKey]}
                    onClose={handleCloseDeleteItemDialog}
                    onCancel={handleCloseDeleteItemDialog}
                    item={item}
                    notifyAction={notifyAction}
                />
            )}
        </div>
    );
};

export default ItemActions;
