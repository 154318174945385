import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import { Datagrid, FunctionField, Identifier, List, Record, ReduxState, TextField, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { users } from '../../pages/paths';
import { USERS } from '../../providers/resources';
import { CustomReducerState } from '../../redux/reducers';
import { rowStyle } from '../../utils/rowListStyles';
import { User } from '../../utils/types';
import UserSideBar from './aside';
import { getUserSelected } from '../../redux/reducers/user';
import { selectUser } from '../../redux/actions/users';
import Actions from './actions';
import { resolverUserTagNameTranslations } from '../../utils/user/tags';
import { FieldHeaderListStyles } from '../../theme';
import './styles.css';

const useStyles = (divHeight: number) =>
    makeStyles({
        //@ts-ignore
        field: {
            ...FieldHeaderListStyles,
            // ...FieldCapitalizeListStyles,
        },
        content: {
            overflow: 'auto',
        },
        main: {
            height: divHeight,
        },
    });

const UserList = ({ divHeight }: { divHeight: number }) => {
    const classes = useStyles(divHeight - 40)();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(true);
    const onCloseCreateForm = () => {
        setShowCreateForm(false);
    };

    const listProps = {
        resource: USERS,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: users,
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            serialization_groups: ['read_user_profiles'],
        },
        classes: {
            main: classes.main,
            content: classes.content,
        },
    };

    const translate = useTranslate();
    const dispatch = useDispatch();

    const selected: User | undefined = useSelector((state: CustomReducerState & ReduxState) => getUserSelected(state));

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectUser.fn(record as User));
        // @ts-ignore
        return Promise.resolve(undefined);
    };

    const renderTag = (record: Record | undefined) => {
        if (!record) return;
        if ((record as User)?.tags) {
            //@ts-ignore
            const tagNameTranslated = resolverUserTagNameTranslations((record as User).tags);
            return <span>{tagNameTranslated ? translate(tagNameTranslated) : ''}</span>;
        }
    };

    return (
        <div className='usersTable' style={{ height: divHeight }}>
            <List
                {...listProps}
                aside={<UserSideBar divHeight={divHeight} />}
                bulkActionButtons={false}
                actions={<Actions />}
            >
                <Datagrid rowClick={onClick} rowStyle={(record, index) => rowStyle(record, index, selected)}>
                    <TextField
                        sortByOrder={'DESC'}
                        source='firstName'
                        label={translate('app.users.firstName')}
                        headerClassName={classes.field}
                    />
                    <TextField
                        sortByOrder={'DESC'}
                        source='lastName'
                        label={translate('app.users.lastName')}
                        headerClassName={classes.field}
                    />
                    <TextField
                        sortByOrder={'DESC'}
                        source='username'
                        label={translate('app.users.username')}
                        headerClassName={classes.field}
                    />
                    <FunctionField
                        headerClassName={classes.field}
                        label={'app.users.serviceProviderOrManager'}
                        render={(record: Record | undefined) => (record ? renderTag(record) : <span />)}
                    />
                </Datagrid>
            </List>
        </div>
    );
};
export default UserList;
