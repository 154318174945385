import React, { useState } from 'react';
import IconsSelectDialog from '../icons-select-dialog/icons-select-dialog';
import ImageCropField from '../../commons/ImageCrop/ImageCropField';
import { ICON_FIELD, ICON_SVG_COLLECTION_FIELD, IMAGE_FIELD } from '../../utils/metadataAvatarType';
import AppsIcon from '@material-ui/icons/Apps';
import GetAppIcon from '@material-ui/icons/GetApp';
import { styled } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { MenuItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const CustomMenu = styled(Menu)({
    '& .MuiMenu-list': {
        padding: 0,
    },
});

const CustomListItemIcon = styled(ListItemIcon)({
    minWidth: '25px !important',
});

const CustomMenuItem = styled(MenuItem)({
    padding: '2px 10px 2px 5px',
});

const CustomListItemText = styled(ListItemText)({
    '& .MuiListItemText-root': {
        padding: 0,
    },
    '& .MuiListItemText-primary': {
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 'bold',
    },
});

interface Props {
    iconOrImageActive: boolean;
    changeIconType: any;
    name: any;
    accept: any;
    values: any;
}

const ImageIconSelect = ({ iconOrImageActive, name, accept, changeIconType, values }: Props) => {
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [iconOpenAction, setIconOpenAction] = useState<any>(null);
    const [imageOpenAction, setImageOpenAction] = useState<any>(null);

    const iconNameValue = ICON_FIELD in values ? values[ICON_FIELD] : '';

    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div
            style={{
                position: 'relative',
                height: 400,
            }}
        >
            {!(ICON_FIELD in values) && !(IMAGE_FIELD in values) && (
                <Button
                    color={'primary'}
                    onClick={handleOpenMenu}
                    startIcon={<AddIcon />}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 3,
                    }}
                >
                    {translate('app.buttons.addIcon')}
                </Button>
            )}

            <CustomMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <CustomMenuItem
                    onClick={() => {
                        changeIconType(ICON_SVG_COLLECTION_FIELD, true);
                        handleCloseMenu();
                        iconOpenAction();
                    }}
                >
                    <CustomListItemIcon>
                        <AppsIcon color={'primary'} style={{ fontSize: 20 }} />
                    </CustomListItemIcon>
                    <CustomListItemText
                        primary={translate('app.buttons.iconLibrary')}
                        primaryTypographyProps={{
                            color: 'primary',
                        }}
                    />
                </CustomMenuItem>

                <CustomMenuItem
                    onClick={() => {
                        changeIconType(ICON_SVG_COLLECTION_FIELD, false);
                        handleCloseMenu();
                        imageOpenAction();
                    }}
                >
                    <CustomListItemIcon>
                        <GetAppIcon color={'primary'} style={{ fontSize: 20 }} />
                    </CustomListItemIcon>
                    <CustomListItemText
                        primary={translate('app.buttons.uploadFile')}
                        primaryTypographyProps={{
                            color: 'primary',
                        }}
                    />
                </CustomMenuItem>
            </CustomMenu>

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    ...(iconOrImageActive ? { zIndex: 1 } : {}),
                }}
            >
                <IconsSelectDialog
                    iconNameValue={iconNameValue}
                    changeIconType={changeIconType}
                    setIconOpenAction={setIconOpenAction}
                />
            </div>

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    ...(iconOrImageActive ? {} : { zIndex: 1 }),
                }}
            >
                <ImageCropField
                    name={name}
                    accept={accept}
                    changeIconType={changeIconType}
                    setImageOpenAction={setImageOpenAction}
                />
            </div>
        </div>
    );
};

export default ImageIconSelect;
