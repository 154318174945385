import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    CreateResult,
    GetListResult,
    Identifier,
    Record,
    ReduxState,
    required,
    UpdateResult,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import useCreateLanguage from '../../../../hooks/useCreateLanguages';
import { incidentTypes } from '../../../../pages/paths';
import {
    GROUP_METADATA_AVATAR_TYPES,
    LANGUAGES,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
} from '../../../../providers/resources';
import {
    GROUP_METADATA_AVATAR_TYPE_EVENT,
    TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD,
    TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD,
} from '../../../../utils/CONST';
import {
    ASK_CODE_PIN_FIELD,
    ASK_INCIDENT_CREATOR_EMAIL_FIELD,
    CATEGORY_FIELD,
    FORCE_IMAGE_FIELD,
    FORCE_TEXT_FIELD,
    IMAGE_FIELD,
    NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD,
    NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD,
    REPEATABLE_FIELD,
    WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD,
} from '../../../../utils/metadataAvatarType';
import {
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT,
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE,
    eventTypeCustomProcessFileSystemMapperEnabledLanguages,
    eventTypeCustomProcessForceImageLanguages,
    eventTypeCustomProcessForceTextLanguages,
} from '../../../../utils/properties/metadataAvatarTypeProperties';
import { Language, MetadataAvatarType, MetadataAvatarTypeProperty } from '../../../../utils/types';
import CreateFromAutocompleteInput from '../../../categoriesEvent/actions/create/CreateFromAutoCompleteInput';
import BooleanInputEventRepeatable from '../../../../commons/inputs/BooleanInputEventRepeatable';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import AddIcon from '@material-ui/icons/Add';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';

interface Props {
    selectedIds?: Identifier[];
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const URL_KEY = 'url';

interface PropertyIdMapper {
    [key: Identifier]: Record | undefined;
}

const PROPERTY_ERROR = 'property_error';
const GET_PROPERTY_ERROR = 'get_property_error';
const UPDATE_PROPERTY_ERROR = 'update_property_error';
const CREATE_PROPERTY_ERROR = 'create_property_error';
const CREATE_METADATA_AVATAR_TYPE_ERROR = 'create_metadata_avatar_type_error';
const EDIT_METADATA_AVATAR_TYPE_ERROR = 'edit_metadata_avatar_type_error';

interface ErrorsMapperMessage {
    [identifier: string]: {
        [id: Identifier]: string;
    };
}

const EditMultipleIncidentTypes = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [isLoaded, setIsLoaded] = useState(true);
    const [errors, setErrors] = useState<ErrorsMapperMessage>({});
    const [errorsDisplayed, setErrorsDisplayed] = useState(false);
    const [isSelectedIdsEdited, setIsSelectedIdsEdited] = useState(false);

    const [updateMetadataAvatarType, { loading }] = useUpdate(METADATA_AVATAR_TYPES);
    const [updateMetadataAvatarTypeProperties] = useUpdate(METADATA_AVATAR_TYPE_PROPERTIES);

    const propertiesDataInState = useSelector(
        (state: ReduxState) => state.admin.resources[METADATA_AVATAR_TYPE_PROPERTIES].data
    );

    let forceImagePropertyInStateMapperId: PropertyIdMapper | undefined = {};
    props.selectedIds?.forEach(
        (id) =>
            (forceImagePropertyInStateMapperId = {
                ...forceImagePropertyInStateMapperId,
                [id]: Object.values(propertiesDataInState).find(
                    (property) =>
                        (property as MetadataAvatarTypeProperty).metadataAvatarType === id &&
                        (property as MetadataAvatarTypeProperty).name.toLowerCase() ===
                            EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE.toLowerCase()
                ),
            })
    );

    let forceTextPropertyInStateMapperId: PropertyIdMapper | undefined = {};
    props.selectedIds?.map(
        (id) =>
            (forceTextPropertyInStateMapperId = {
                ...forceTextPropertyInStateMapperId,
                [id]: Object.values(propertiesDataInState).find(
                    (property) =>
                        (property as MetadataAvatarTypeProperty).metadataAvatarType === id &&
                        (property as MetadataAvatarTypeProperty).name.toLowerCase() ===
                            EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT.toLowerCase()
                ),
            })
    );

    /**Create and get languages*/
    for (const key of Object.keys(eventTypeCustomProcessForceTextLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessForceTextLanguages[key]);
    }
    for (const key of Object.keys(eventTypeCustomProcessForceImageLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessForceImageLanguages[key]);
    }

    for (const key of Object.keys(eventTypeCustomProcessFileSystemMapperEnabledLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessFileSystemMapperEnabledLanguages[key]);
    }

    /**Get properties from state*/
    const getLanguagesFromState = (propertyLanguages: { en: string; fr: string; es: string }) => {
        const languagesData = useSelector((state: ReduxState) => state.admin.resources[LANGUAGES].data);
        return Object.values(languagesData)
            .map((item: Record) => {
                for (const key of Object.keys(propertyLanguages)) {
                    if (
                        (item as Language).twoLetters.toLowerCase().trim() === key.toLowerCase().trim() &&
                        (item as Language).value.toLowerCase().trim() ===
                            // @ts-ignore
                            propertyLanguages[key].toLowerCase().trim()
                    ) {
                        return (item as Language).id;
                    }
                }
            })
            .filter((item) => item !== undefined);
    };

    const eventTypeCustomProcessForceImageLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessForceImageLanguages
    );

    useEffect(() => {
        if (isSelectedIdsEdited && isLoaded && Object.values(errors) && !errorsDisplayed) {
            setErrorsDisplayed(true);
            notify('app.messages.editMultipleIncidentTypes', 'error');
        }
    }, [isSelectedIdsEdited, isLoaded, errors, errorsDisplayed]);

    const handleSubmit = async (formValues: {
        [IMAGE_FIELD]: { fileName: string; fileData: string; cropping?: boolean };
        [REPEATABLE_FIELD]: string;
        [CATEGORY_FIELD]: string;
        [WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]: boolean;
        [FORCE_IMAGE_FIELD]: boolean;
        [FORCE_TEXT_FIELD]: boolean;
        [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]: boolean;
        [ASK_INCIDENT_CREATOR_EMAIL_FIELD]: boolean;
        [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]: boolean;
        [ASK_CODE_PIN_FIELD]: boolean;
    }) => {
        const newData: any = {
            customFields: {
                [TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD]: formValues[REPEATABLE_FIELD] ? '*' : 1,
                [TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD]: formValues[
                    REPEATABLE_FIELD
                ]
                    ? formValues[WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]
                    : false,
                [ASK_INCIDENT_CREATOR_EMAIL_FIELD]: formValues[ASK_INCIDENT_CREATOR_EMAIL_FIELD],
                [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]: formValues[NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD],
                [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]:
                    formValues[NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD],
                [ASK_CODE_PIN_FIELD]: formValues[ASK_CODE_PIN_FIELD],
            },
            groupMetadataAvatarType: formValues[CATEGORY_FIELD],
        };
        if (!props.selectedIds) return;

        setIsLoaded(false);

        for (let i = 0; i <= props.selectedIds?.length - 1; i++) {
            const selectedId: Identifier = props.selectedIds[i];

            let initialForceTextProperty =
                forceTextPropertyInStateMapperId &&
                selectedId in forceTextPropertyInStateMapperId &&
                forceTextPropertyInStateMapperId[selectedId]
                    ? (forceTextPropertyInStateMapperId[selectedId] as MetadataAvatarTypeProperty)
                    : undefined;

            const initialForceTextPropertyValue =
                initialForceTextProperty && initialForceTextProperty.values.length > 0
                    ? JSON.parse(initialForceTextProperty.values[0])
                    : undefined;

            let initialForceImageProperty: MetadataAvatarTypeProperty | undefined =
                forceImagePropertyInStateMapperId &&
                selectedId in forceImagePropertyInStateMapperId &&
                forceImagePropertyInStateMapperId[selectedId]
                    ? (forceImagePropertyInStateMapperId[selectedId] as MetadataAvatarTypeProperty)
                    : undefined;

            const initialForceImagePropertyValue =
                initialForceImageProperty && initialForceImageProperty.values.length > 0
                    ? JSON.parse(initialForceImageProperty.values[0])
                    : undefined;

            if (!initialForceTextProperty) {
                const result: GetListResult<Record> | void = await getProperties(
                    selectedId as string,
                    EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT
                );
                initialForceTextProperty =
                    result && 'data' in result && result.data.length > 0
                        ? (result.data[0] as MetadataAvatarTypeProperty)
                        : undefined;
            }
            if (!initialForceImageProperty) {
                const result: GetListResult<Record> | void = await getProperties(
                    selectedId as string,
                    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                );
                initialForceImageProperty =
                    result && 'data' in result && result.data.length > 0
                        ? (result.data[0] as MetadataAvatarTypeProperty)
                        : undefined;
            }
            // if the current properties are different to the property values entered, so edit or create
            if (
                !(
                    formValues[FORCE_IMAGE_FIELD] === initialForceImagePropertyValue &&
                    formValues[FORCE_TEXT_FIELD] === initialForceTextPropertyValue
                )
            ) {
                await createOrUpdateMetadataAvatarTypeProperties(
                    initialForceTextProperty,
                    formValues[FORCE_TEXT_FIELD],
                    selectedId,
                    EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT
                );
                await createOrUpdateMetadataAvatarTypeProperties(
                    initialForceImageProperty,
                    formValues[FORCE_IMAGE_FIELD],
                    selectedId,
                    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                );
            }

            updateMetadataAvatarType(
                METADATA_AVATAR_TYPES,
                selectedId,
                newData,
                { id: selectedId },
                {
                    onSuccess: () => {
                        const thereAreErrors = Object.values(errors).length > 0;
                        if (props.selectedIds && props.selectedIds?.length - 1 === i && !thereAreErrors) {
                            notify('app.incidentTypes.edit.severalItemsEdited', 'success');
                            setIsLoaded(true);
                        }
                        if (thereAreErrors) {
                            notify('app.messages.editMultipleIncidentTypes', 'error');
                        }
                        props.handleClose();
                    },
                    onFailure: (...args: unknown[]) => {
                        const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                        if (violations && violations.length > 0) {
                            const violation = violations[0];
                            notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                            setErrors({
                                ...errors,
                                [EDIT_METADATA_AVATAR_TYPE_ERROR]: {
                                    ...errors[EDIT_METADATA_AVATAR_TYPE_ERROR],
                                    [selectedId]: `${getCodeFromViolationMessage(violation?.message)}`,
                                },
                            });
                        } else {
                            setErrors({
                                ...errors,
                                [EDIT_METADATA_AVATAR_TYPE_ERROR]: {
                                    ...errors[EDIT_METADATA_AVATAR_TYPE_ERROR],
                                    [selectedId]: '',
                                },
                            });
                        }
                        setIsLoaded(true);
                    },
                }
            );

            if (props.selectedIds && props.selectedIds?.length - 1 === i) {
                setIsSelectedIdsEdited(true);
            }
        }
    };

    const getProperties = (
        metadataAvatarTypeId: string,
        name: string,
        onSuccess?: (value: boolean) => void
    ): Promise<GetListResult<Record> | void> => {
        return dataProvider
            .getList(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {
                        metadataAvatarType: metadataAvatarTypeId,
                        name,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (
                            response &&
                            'data' in response &&
                            response.data &&
                            Array.isArray(response.data) &&
                            response.data.length > 0
                        ) {
                            if ((response.data[0] as MetadataAvatarTypeProperty).values.length === 0) return;
                            onSuccess &&
                                onSuccess(JSON.parse((response.data[0] as MetadataAvatarTypeProperty).values[0]));
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                                    ? translate('app.incidentTypes.edit.input.forceImage')
                                    : translate('app.incidentTypes.edit.input.forceText'),
                        });
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                setErrors({
                    ...errors,
                    [GET_PROPERTY_ERROR]: {
                        ...errors[GET_PROPERTY_ERROR],
                        [metadataAvatarTypeId]: 'app.messages.getForceMetadataAvatarTypePropertyError',
                    },
                });
            });
    };

    const createMetadataAvatarTypeProperty = (
        metadataAvatarTypeId: string,
        name: string,
        values: string[],
        languages: (Identifier | undefined)[],
        fieldName = 'boolean',
        option = 'pre_filled',
        _private = false
    ): Promise<CreateResult<Record> | void> => {
        return dataProvider
            .create(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    data: {
                        metadataAvatarType: metadataAvatarTypeId,
                        name,
                        values,
                        languages,
                        fieldName,
                        option,
                        private: _private,
                    },
                },
                {
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.createForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                                    ? translate('app.incidentTypes.edit.input.forceImage')
                                    : translate('app.incidentTypes.edit.input.forceText'),
                        });
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                setErrors({
                    ...errors,
                    [CREATE_PROPERTY_ERROR]: {
                        ...errors[UPDATE_PROPERTY_ERROR],
                        [metadataAvatarTypeId]: 'app.messages.createForceMetadataAvatarTypePropertyError',
                    },
                });
            });
    };

    const createOrUpdateMetadataAvatarTypeProperties = (
        property: MetadataAvatarTypeProperty | undefined,
        propertyValue: boolean,
        selectedId: Identifier,
        propertyName: string
    ): Promise<CreateResult<Record>> | void | Promise<CreateResult<Record> | void> => {
        // update forceImageProperty if the state value is different to value entered by user

        return property && property.id
            ? updateMetadataAvatarTypeProperties(
                  METADATA_AVATAR_TYPE_PROPERTIES,
                  property.id as string,
                  {
                      id: property.id,
                      values: [propertyValue ? 'true' : 'false'],
                  },
                  {
                      ...property,
                  },
                  {
                      onSuccess: (response: UpdateResult) => {
                          if (response && 'data' in response && response.data) {
                              refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                          }
                      },
                      onFailure: (error: any) => {
                          console.error(error);
                          setErrors({
                              ...errors,
                              [UPDATE_PROPERTY_ERROR]: {
                                  ...errors[UPDATE_PROPERTY_ERROR],
                                  [property.id]: 'app.messages.updateForceMetadataAvatarTypePropertyError',
                              },
                          });
                      },
                  }
              )
            : createMetadataAvatarTypeProperty(
                  selectedId as string,
                  propertyName,
                  [propertyValue ? 'true' : 'false'],
                  eventTypeCustomProcessForceImageLanguagesInState
              );
    };

    const refreshProperties = (id: string) => {
        dataProvider
            .getOne(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    id,
                },
                {
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getIncidentTypePropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.getIncidentTypePropertyError', 'error');
            });
    };

    return (
        <DialogTemplate
            title={translate('app.incidentTypes.edit.severalItem')}
            open={props.open}
            onClose={props.handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <Form
                initialValues={{
                    [REPEATABLE_FIELD]: false,
                    [WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]: false,
                    [FORCE_IMAGE_FIELD]: false,
                    [FORCE_TEXT_FIELD]: false,
                    [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]: true,
                    [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]: true,
                    [ASK_INCIDENT_CREATOR_EMAIL_FIELD]: true,
                    [ASK_CODE_PIN_FIELD]: true,
                }}
                onSubmit={handleSubmit}
                keepDirtyOnReinitialize={true}
                render={({ handleSubmit, valid, pristine, values, submitting }) => {
                    return (
                        <Grid container>
                            <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                <Grid item>
                                    <ReferenceInput
                                        basePath={incidentTypes}
                                        reference={GROUP_METADATA_AVATAR_TYPES}
                                        filter={{
                                            alias: GROUP_METADATA_AVATAR_TYPE_EVENT,
                                            hidden: false,
                                            order__createdAt: 'DESC',
                                        }}
                                        isRequired={true}
                                        source={CATEGORY_FIELD}
                                        label={translate('app.incidentTypes.edit.input.selectEvent')}
                                        validate={[required()]}
                                        filterToQuery={(search: string) => ({ name: search })}
                                    >
                                        <AutocompleteInput
                                            create={<CreateFromAutocompleteInput />}
                                            optionValue={'@id'}
                                            optionText={'name'}
                                            fullWidth
                                            variant={'standard'}
                                            filterToQuery={(search: string) => ({ name: search })}
                                        />
                                    </ReferenceInput>
                                </Grid>

                                <BooleanInputEventRepeatable />

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.forceText')}
                                            </Typography>
                                        }
                                        source={FORCE_TEXT_FIELD}
                                    />
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.forceImage')}
                                            </Typography>
                                        }
                                        source={FORCE_IMAGE_FIELD}
                                    />
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.noLoggerUserCanCreateEvents')}
                                            </Typography>
                                        }
                                        source={NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD}
                                    />
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.noLoggerUserCanSeeHistory')}
                                            </Typography>
                                        }
                                        source={NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD}
                                    />
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.askIncidentCreatorEmail')}
                                            </Typography>
                                        }
                                        source={ASK_INCIDENT_CREATOR_EMAIL_FIELD}
                                    />
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.incidentTypes.edit.input.askIncidentCodePin')}
                                            </Typography>
                                        }
                                        source={ASK_CODE_PIN_FIELD}
                                    />
                                </Grid>

                                <DialogTemplateButtons
                                    outside
                                    withCancel
                                    onCancelClick={props.handleClose}
                                    withCreate
                                    isSubmitting={!isLoaded}
                                    isValidForm={valid && !pristine}
                                    cancelButtonLabel={translate('app.cancel')}
                                    createButtonLabel={translate('app.valid')}
                                />
                            </form>
                        </Grid>
                    );
                }}
            />
        </DialogTemplate>
    );
};

export default EditMultipleIncidentTypes;
