import InfoIcon from '@material-ui/icons/Info';
import React, { FC, useEffect, useState } from 'react';
import {
    GetListResult,
    Record,
    TextInput,
    useCreate,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import HeaderBlock from '../../../../../commons/HeaderBlock/HeaderBlock';
import { Avatar, AvatarProperty } from '../../../../../utils/types';
import { AVATAR_PROPERTY, AVATARS } from '../../../../../providers/resources';
import { Form as FinalForm } from 'react-final-form';
import ButtonsBlockForm from '../../../../forms/buttonsBlock';
import Grid from '@material-ui/core/Grid';
import { TICKETING_LOCATION_OVERRIDDEN_PROPERTY } from '../../../../../utils/CONST';

interface Props {
    item: Avatar;
    disabled: boolean;
    isRoot: boolean;
}

const ADDRESS_FIELD = 'addressText';
const POSTAL_CODE_FIELD = 'postalCode';
const CITY_FIELD = 'city';
const COUNTRY_FIELD = 'country';

const fieldFormArray = [ADDRESS_FIELD, POSTAL_CODE_FIELD, CITY_FIELD, COUNTRY_FIELD];

const Location: FC<Props> = ({ item, disabled, isRoot }: Props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const [update] = useUpdate();
    const [create] = useCreate();

    const [forcedRefresh, setForceRefresh] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [locationOverriddenProperty, setLocationOverriddenProperty] = useState<AvatarProperty | null>(null);

    const forceRefresh = () => setForceRefresh(forcedRefresh + 1);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            dataProvider
                .getList(AVATARS, {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {
                        id: item.id,
                    },
                })
                .then((response: GetListResult<Record>) => {
                    if (response && 'data' in response && response.data.length > 0) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('Getting avatar', item.id);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [forcedRefresh]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onSubmit = (data: { [key: string]: any }) => {
        updateAvatar(data, item);
    };

    const updateAvatar = (data: { [key: string]: any }, item: Avatar) => {
        let dataToSend = {
            ...data,
        };
        const dataKeys = Object.keys(data);
        if (dataKeys.length === 0) {
            fieldFormArray.map((field) => {
                dataToSend = {
                    ...dataToSend,
                    [field]: '',
                };
            });
        }
        if (dataKeys.length !== fieldFormArray.length) {
            const fieldValueDifference = fieldFormArray.filter((x) => dataKeys.indexOf(x) === -1);
            fieldValueDifference.map((field) => {
                dataToSend = {
                    ...dataToSend,
                    [field]: '',
                };
            });
        }
        update(AVATARS, item.id, dataToSend, item, {
            onSuccess: () => {
                forceRefresh();
                notify('app.avatar.updated.notification', 'success');
                if (!isRoot) {
                    if (Object.keys(data).length >= 1) {
                        createOrUpdateAvatarPropertyLocation(true);
                    } else {
                        createOrUpdateAvatarPropertyLocation(false);
                    }
                }
            },
            onFailure: (error: any) => {
                console.error('Error updating avatar', error);
                notify('app.avatar.updated.error', 'warning');
            },
        });
    };

    const getAvatarPropertyLocation = () => {
        dataProvider
            .getList(AVATAR_PROPERTY, {
                sort: { field: 'id', order: 'asc' },
                pagination: { page: 1, perPage: 1 },
                filter: {
                    'avatar.id': item.id,
                    name: TICKETING_LOCATION_OVERRIDDEN_PROPERTY,
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && 'data' in result) {
                    if (result.data.length > 0) {
                        setLocationOverriddenProperty(result.data[0] as AvatarProperty);
                    }
                }
            })
            .catch((e) => {
                console.error('avatar-property', e);
                notify('app.avatarProperty.get.error', 'warning', {
                    property_name: translate('app.avatarProperty.location'),
                });
            });
    };

    const createOrUpdateAvatarPropertyLocation = (value: boolean) => {
        const data = {
            name: TICKETING_LOCATION_OVERRIDDEN_PROPERTY,
            value: String(value),
            avatar: item.id,
            private: false,
            enabled: true,
        };
        if (locationOverriddenProperty) {
            update(
                AVATAR_PROPERTY,
                locationOverriddenProperty.id,
                {
                    value: String(value),
                },
                locationOverriddenProperty,
                {
                    onFailure: (error: any) => {
                        console.error('Error updating avatar property', error);
                        notify('app.avatarProperty.updated.error', 'warning', {
                            property_name: translate('app.avatarProperty.location'),
                        });
                    },
                }
            );
        } else {
            create(AVATAR_PROPERTY, data, {
                onFailure: (error: any) => {
                    console.error('Error creating avatar property', error);
                    notify('app.avatarProperty.updated.error', 'warning', {
                        property_name: translate('app.avatarProperty.location'),
                    });
                },
            });
        }
    };

    useEffect(() => {
        if (!isRoot) {
            getAvatarPropertyLocation();
        }
    }, []);

    const regexp = new RegExp('[0-9]|Backspace|Delete|ArrowLeft|ArrowRight');

    return (
        <HeaderBlock
            icon={<InfoIcon />}
            label={translate('app.navigation.configuration.location.postalAddress')}
            withoutMarginTop
        >
            <FinalForm
                initialValues={{
                    [ADDRESS_FIELD]: item[ADDRESS_FIELD],
                    [CITY_FIELD]: item[CITY_FIELD],
                    [COUNTRY_FIELD]: item[COUNTRY_FIELD],
                    [POSTAL_CODE_FIELD]: item[POSTAL_CODE_FIELD],
                }}
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, submitting }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextInput
                                        disabled={false}
                                        variant={'standard'}
                                        source={ADDRESS_FIELD}
                                        label={translate('app.navigation.configuration.location.address')}
                                        helperText={false}
                                        fullWidth
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        disabled={false}
                                        variant={'standard'}
                                        source={POSTAL_CODE_FIELD}
                                        label={translate('app.navigation.configuration.location.postalCode')}
                                        helperText={false}
                                        fullWidth
                                        inputProps={{ maxLength: 13, type: 'number' }}
                                        onKeyDown={(evt) => {
                                            console.log('evt.key', evt.key);
                                            return !regexp.test(evt.key) && evt.preventDefault();
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        disabled={false}
                                        variant={'standard'}
                                        source={CITY_FIELD}
                                        label={translate('app.navigation.configuration.location.city')}
                                        helperText={false}
                                        fullWidth
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        disabled={false}
                                        variant={'standard'}
                                        source={COUNTRY_FIELD}
                                        label={translate('app.navigation.configuration.location.country')}
                                        helperText={false}
                                        fullWidth
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Grid>
                            </Grid>

                            <ButtonsBlockForm
                                validButtonIsSubmitType={true}
                                showValidButton={true}
                                disableValidButton={pristine || submitting || loading}
                                showCancelButton={false}
                            />
                        </form>
                    );
                }}
            />
        </HeaderBlock>
    );
};

export default Location;
