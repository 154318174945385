import React from 'react';
import { useSelector } from 'react-redux';
import Authentication from '../commons/auth/Authentication';
import { CustomReducerState } from '../redux/reducers';
import { usersNameRoute } from './paths';
import Users from '../components/users';

const UserPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={usersNameRoute}>{userConnected && <Users />}</Authentication>;
};

export default UserPage;
