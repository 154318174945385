import React, { Component } from 'react';

class FalseShouldUpdate extends Component<any, any> {
    shouldComponentUpdate(): boolean {
        return false;
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default FalseShouldUpdate;
