import MUIAvatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import SortIcon from '@material-ui/icons/Sort';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';
import {
    DataProviderContext,
    GetListResult,
    Identifier,
    Record,
    useDelete,
    useNotify,
    usePermissions,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES } from '../../../../../../providers/resources';
import { CustomReducerState } from '../../../../../../redux/reducers';
import { APP_ENABLED_COLOR, CustomIconButton, StyledBadge } from '../../../../../../theme';
import extractIdFromURI, { getUriFromItems } from '../../../../../../utils/id';
import { hasPermissions } from '../../../../../../utils/permissions';
import { ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES } from '../../../../../../utils/ROLES';
import { capitalizeFirstLetter } from '../../../../../../utils/strings';
import { Avatar, MetadataAvatarType, SubcategoryEventTypeDependencies } from '../../../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../../../utils/validations/parseValidations';
import DndContainer from '../../../../../dndContainer/dndContainer';
import AddIncidentTypeDialog from './form/addEventType';
import { registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn } from '../../../../../../redux/actions/ticketingSubcategoryEventTypeDependencies';

const useStyles = makeStyles(() => ({
    badgeRoot: {
        backgroundColor: APP_ENABLED_COLOR,
    },
    badgeTransparent: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    avatarStyledLikeChip: {
        opacity: 0.5,
        height: 32,
        pointerEvents: 'none',
        border: 'none',
    },
}));

const CustomDivFlex = styled('div')({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
});

const CustomChip = styled(Chip)({
    margin: 2,
});

interface EventTypesFieldProps {
    subcategoryEventTypeDependencies: SubcategoryEventTypeDependencies;
    place: Avatar;
}

const EventTypesField = ({ place, subcategoryEventTypeDependencies }: EventTypesFieldProps) => {
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [loadingDependencies, isLoadingDependencies] = useState<boolean>(false);
    const [update] = useUpdate(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES);
    const [deleteOneDependency] = useDelete(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES);
    const [dndList, setDndList] = useState<any[]>([]);
    const [dndState, setDndState] = useState<boolean>(false);

    const { loaded: loadedPermissions, permissions } = usePermissions();

    const [
        lastSubcategoryEventTypeDependencies,
        setLastSubcategoryEventTypeDependencies,
    ] = useState<SubcategoryEventTypeDependencies | null>(null);

    // const [
    //     firstSubcategoryEventTypeDependencies,
    //     setFirstSubcategoryEventTypeDependenciesOfParentPlace,
    // ] = useState<SubcategoryEventTypeDependencies | null>(null);
    //
    // const firstSubcategoryEventTypeDependenciesPlace: Avatar | null = useSelector((state: ReduxState) => {
    //     return firstSubcategoryEventTypeDependencies && firstSubcategoryEventTypeDependencies.place
    //         ? (state.admin.resources[AVATARS].data[firstSubcategoryEventTypeDependencies?.place] as Avatar)
    //         : null;
    // });

    const lastSubcategoryEventTypeDependenciesPlace: Avatar | null = useSelector((state: CustomReducerState) => {
        return lastSubcategoryEventTypeDependencies &&
            lastSubcategoryEventTypeDependencies.place &&
            lastSubcategoryEventTypeDependencies?.place in state.ticketing.navigation.tree.avatarMapper
            ? (state.ticketing.navigation.tree.avatarMapper[lastSubcategoryEventTypeDependencies?.place] as Avatar)
            : null;
    });

    const [subcategoryEventTypeDependenciesResult, setSubcategoryEventTypeDependenciesResult] = useState<
        SubcategoryEventTypeDependencies[] | null
    >(null);

    const [showUpdateEventTypeForm, setShowUpdateEventTypeForm] = useState<boolean>(false);
    const [itemToUpdate, setItemToUpdate] = useState<SubcategoryEventTypeDependencies | undefined>();

    const handleOnClickUpdateEventType = () => {
        setShowUpdateEventTypeForm(true);
        setItemToUpdate(subcategoryEventTypeDependencies);
    };

    const dismissUpdateEventTypeFormDialog = () => {
        setShowUpdateEventTypeForm(false);
        setItemToUpdate(undefined);
        refresh();
    };
    const cancelUpdateEventTypeFormDialog = () => {
        setShowUpdateEventTypeForm(false);
        setItemToUpdate(undefined);
    };

    const registerTicketingSubcategoryDependenciesMapper = (payload: {
        ticketingSubcategoryEventTypesDepsInList: Identifier;
        ticketingSubcategoryEventTypesDepsIdShownInEachColumn: Identifier;
        placeId: Identifier;
        toDelete: boolean;
    }) => {
        dispatch(registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.fn(payload));
    };

    useEffect(() => {
        getDependencies();
    }, []);

    useEffect(() => {
        getDependencies();
    }, [subcategoryEventTypeDependencies.subCategory]);

    const changeDndState = () => {
        setDndState(!dndState);
        const $eventTypesLastSubcategoryDeps =
            lastSubcategoryEventTypeDependencies && 'eventTypes' in lastSubcategoryEventTypeDependencies
                ? lastSubcategoryEventTypeDependencies.eventTypes
                : [];
        if (!dndState) {
            if (lastSubcategoryEventTypeDependencies) setDndList($eventTypesLastSubcategoryDeps);
        } else {
            if (lastSubcategoryEventTypeDependencies && !_.isEqual($eventTypesLastSubcategoryDeps, dndList)) {
                lastSubcategoryEventTypeDependencies.eventTypes = dndList;

                const updateDependencies = (
                    subCategoryToUpdate: SubcategoryEventTypeDependencies | null,
                    eventTypes: Identifier[]
                ) => {
                    if (!subCategoryToUpdate) {
                        refresh();
                        return;
                    }

                    const dataToSendUpdate: { eventTypes: any } = {
                        eventTypes,
                    };

                    return update(
                        TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
                        subCategoryToUpdate.id,
                        dataToSendUpdate,
                        subCategoryToUpdate,
                        {
                            onSuccess: () => {
                                notify(
                                    dndState
                                        ? 'app.navigation.configuration.incident.eventType.updated.success'
                                        : 'app.navigation.configuration.incident.eventType.updated.notification',
                                    'success'
                                );
                                setDndState(false);
                                getDependencies();
                            },
                            onFailure: (...args: unknown[]) => {
                                const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                                if (violations && violations.length > 0) {
                                    const violation = violations[0];
                                    notify(
                                        `app.navigation.configuration.incident.eventType.updated.${getCodeFromViolationMessage(
                                            violation?.message
                                        )}`,
                                        'error'
                                    );
                                } else {
                                    notify('app.navigation.configuration.incident.eventType.updated.error', 'error');
                                }
                                // handleCancel();

                                console.error(
                                    `ERRROR updating, event types ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`,
                                    ...args
                                );
                            },
                        }
                    );
                };

                const onSubmit = async (list: any[]) => updateDependencies(subcategoryEventTypeDependencies, list);

                onSubmit(dndList.map((item) => item['@id'])).catch((error) =>
                    console.error('error updating the sub catagory event type', error)
                );
            }
        }
    };

    const getDependencies = () => {
        if (subcategoryEventTypeDependencies.subCategory && !loadingDependencies) {
            isLoadingDependencies(true);
            dataProvider
                .getList(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, {
                    pagination: { page: 1, perPage: 2 },
                    sort: { field: 'place.lvl', order: 'DESC' },
                    filter: {
                        serialization_groups: [
                            'read_meta',
                            'ticketing:metaadataavatartype-dependencies:read-eventtypes',
                        ],
                        'place.root':
                            place && typeof place?.root === 'string' ? place.root : place && (place.root as Avatar).id,
                        'place.lvl__lte__': place.lvl,
                        'place.lft__lte__': place.lft,
                        'place.rgt__gte__': place.rgt,
                        subCategory: subcategoryEventTypeDependencies.subCategory.id,
                    },
                })
                .then((response: GetListResult<Record>) => {
                    if (response && 'data' in response && response.data.length > 0) {
                        isLoadingDependencies(false);
                        setSubcategoryEventTypeDependenciesResult(response.data as SubcategoryEventTypeDependencies[]);
                    }
                })
                .catch((error) => {
                    isLoadingDependencies(false);
                    console.error('error getting ticketing subcategory event dependencies ', error);
                });
        }
    };

    useEffect(() => {
        if (!subcategoryEventTypeDependenciesResult) return;

        if (subcategoryEventTypeDependenciesResult?.length >= 0) {
            orderEventTypes(subcategoryEventTypeDependenciesResult[0]);
        }
    }, [subcategoryEventTypeDependenciesResult]);

    const onDeleteEventType = (
        record: SubcategoryEventTypeDependencies | null,
        eventType: MetadataAvatarType
    ) => () => {
        if (!record || !('eventTypes' in record)) return;
        const eventTypes = getUriFromItems(record.eventTypes.filter((event) => event.id !== eventType.id));

        if (eventTypes.length === 0) {
            deleteOneDependency(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, record.id, record, {
                onSuccess: () => {
                    notify('app.navigation.configuration.incident.eventType.removed.notification', 'info');
                    refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'error');
                    } else {
                        notify('app.navigation.configuration.incident.eventType.removed.error', 'warning');
                    }
                    console.error(
                        `ERRROR removing, event types ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`,
                        ...args
                    );
                },
            });
            return;
        }

        const dataToSend: { eventTypes: string[] } = {
            eventTypes,
        };

        update(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, record.id, dataToSend, record, {
            onSuccess: () => {
                notify('app.navigation.configuration.incident.eventType.removed.notification', 'info');
                refresh();
            },
            onFailure: (...args: unknown[]) => {
                const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                if (violations && violations.length > 0) {
                    const violation = violations[0];
                    notify(`${getCodeFromViolationMessage(violation?.message)}`, 'error');
                } else {
                    notify('app.navigation.configuration.incident.eventType.removed.error', 'warning');
                }
                console.error(
                    `ERRROR removing, event types ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`,
                    ...args
                );
            },
        });
    };

    const orderEventTypes = (toOrder: SubcategoryEventTypeDependencies) => {
        if (toOrder && !toOrder?.customFields?.event_types_order) {
            setLastSubcategoryEventTypeDependencies(toOrder);
            return;
        }

        const indexArray = toOrder?.customFields?.event_types_order
            ? Object.values(toOrder?.customFields?.event_types_order).sort()
            : [];

        const eventTypeIdsSort = indexArray
            .map((eventTypeOrder) =>
                toOrder?.customFields?.event_types_order
                    ? Object.keys(toOrder?.customFields?.event_types_order).find(
                          (key) =>
                              //@ts-ignore
                              toOrder?.customFields?.event_types_order[key] === eventTypeOrder
                      )
                    : ''
            )
            .filter((eventTypeId) => eventTypeId !== undefined);

        const newEventTypesSort: (MetadataAvatarType | undefined)[] = toOrder
            ? eventTypeIdsSort.map((eventTypeIdSort) =>
                  toOrder?.eventTypes.find((eventType) => extractIdFromURI(eventType.id as string) === eventTypeIdSort)
              )
            : [];

        if (toOrder && toOrder?.eventTypes) {
            // @ts-ignore
            toOrder.eventTypes = newEventTypesSort.filter((eventType) => eventType !== undefined);
        }

        setLastSubcategoryEventTypeDependencies(toOrder);
        registerTicketingSubcategoryDependenciesMapper({
            ticketingSubcategoryEventTypesDepsInList: subcategoryEventTypeDependencies.id,
            ticketingSubcategoryEventTypesDepsIdShownInEachColumn: toOrder.id,
            placeId: place.id,
            toDelete: lastSubcategoryEventTypeDependencies?.place === place.id,
        });
    };

    return (
        <CustomDivFlex>
            {hasPermissions(
                [ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES],
                loadedPermissions,
                permissions
            ) && (
                <CustomIconButton onClick={handleOnClickUpdateEventType} disabled={dndState} color={'primary'}>
                    <AddIcon />
                </CustomIconButton>
            )}

            {hasPermissions(
                [ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES],
                loadedPermissions,
                permissions
            ) && (
                <CustomIconButton
                    onClick={changeDndState}
                    color={'primary'}
                    disabled={
                        lastSubcategoryEventTypeDependencies === null ||
                        lastSubcategoryEventTypeDependencies.eventTypes.length < 2
                    }
                >
                    {!dndState ? <SortIcon /> : <CheckIcon />}
                </CustomIconButton>
            )}

            <div>
                {/*{firstSubcategoryEventTypeDependencies && firstSubcategoryEventTypeDependencies.eventTypes.length > 0 && (*/}
                {/*    // <CustomPointerAvatar*/}
                {/*    //     style={{*/}
                {/*    //         backgroundColor: 'gray',*/}
                {/*    //     }}*/}
                {/*    // >*/}
                {/*    //     {firstSubcategoryEventTypeDependenciesPlace?.name.slice(0, 3).toUpperCase()}*/}
                {/*    // </CustomPointerAvatar>*/}

                {/*    <MUIAvatar className={classes.avatarStyledLikeChip}>*/}
                {/*        {firstSubcategoryEventTypeDependenciesPlace?.name.slice(0, 3).toUpperCase()}*/}
                {/*    </MUIAvatar>*/}
                {/*    // <CustomChip*/}
                {/*    //     avatar={*/}
                {/*    //         <Tooltip*/}
                {/*    //             placement='top'*/}
                {/*    //             title={*/}
                {/*    //                 firstSubcategoryEventTypeDependenciesPlace?.name*/}
                {/*    //                     ? firstSubcategoryEventTypeDependenciesPlace?.name.toUpperCase()*/}
                {/*    //                     : ''*/}
                {/*    //             }*/}
                {/*    //         >*/}
                {/*    //             <MUIAvatar>*/}
                {/*    //                 {firstSubcategoryEventTypeDependenciesPlace?.name.slice(0, 3).toUpperCase()}*/}
                {/*    //             </MUIAvatar>*/}
                {/*    //         </Tooltip>*/}
                {/*    //     }*/}
                {/*    //     disabled={true}*/}
                {/*    // />*/}
                {/*)}*/}
                {/*{loadingDependencies && '....'}*/}

                {!dndState &&
                    lastSubcategoryEventTypeDependencies &&
                    lastSubcategoryEventTypeDependencies.eventTypes &&
                    lastSubcategoryEventTypeDependencies.eventTypes.map(
                        (eventType: MetadataAvatarType, key: number) => (
                            <Tooltip
                                key={`tooltip_${key}`}
                                placement='top'
                                title={
                                    lastSubcategoryEventTypeDependenciesPlace?.name &&
                                    lastSubcategoryEventTypeDependencies?.place !== place.id
                                        ? translate('app.navigation.configuration.incident.eventType.assigned', {
                                              place_name: lastSubcategoryEventTypeDependenciesPlace?.name.toUpperCase(),
                                          })
                                        : ''
                                }
                            >
                                <StyledBadge
                                    key={`styled_badge_${key}`}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    badgeContent=' '
                                    classes={{
                                        badge: classes.badgeRoot,
                                    }}
                                    overlap={'circular'}
                                >
                                    <CustomChip
                                        avatar={
                                            <Tooltip
                                                placement='top'
                                                title={
                                                    lastSubcategoryEventTypeDependenciesPlace?.name
                                                        ? translate(
                                                              'app.navigation.configuration.incident.eventType.assigned',
                                                              {
                                                                  place_name: lastSubcategoryEventTypeDependenciesPlace?.name.toUpperCase(),
                                                              }
                                                          )
                                                        : ''
                                                }
                                            >
                                                <MUIAvatar>
                                                    {lastSubcategoryEventTypeDependenciesPlace?.name
                                                        .slice(0, 3)
                                                        .toUpperCase()}
                                                </MUIAvatar>
                                            </Tooltip>
                                        }
                                        key={key}
                                        disabled={lastSubcategoryEventTypeDependencies?.place !== place.id}
                                        label={capitalizeFirstLetter(eventType.name)}
                                        onDelete={
                                            hasPermissions(
                                                [ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES],
                                                loadedPermissions,
                                                permissions
                                            )
                                                ? onDeleteEventType(lastSubcategoryEventTypeDependencies, eventType)
                                                : undefined
                                        }
                                    />
                                </StyledBadge>
                            </Tooltip>
                        )
                    )}

                {dndState && lastSubcategoryEventTypeDependencies && (
                    <DndContainer dndList={dndList} setDndList={setDndList} />
                )}
            </div>
            {showUpdateEventTypeForm && itemToUpdate && (
                <AddIncidentTypeDialog
                    place={place}
                    item={itemToUpdate}
                    handleClose={dismissUpdateEventTypeFormDialog}
                    open={true}
                    handleCancel={cancelUpdateEventTypeFormDialog}
                />
            )}
        </CustomDivFlex>
    );
};

export default EventTypesField;
