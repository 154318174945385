import React, { useEffect, useState } from 'react';
import { GetOneResult, Identifier, ReduxState, useDataProvider, useTranslate } from 'react-admin';
import DashboardBox from '../../commons/dashboard-box/dashboard-box';
import DashboardTable from '../../commons/dashboard-table/dashboard-table';
import GetMetabaseUrlOfTheData from '../../commons/get-metabase-url-of-the-data/get-metabase-url-of-the-data';
import { ResponseForwarderToMetabaseType } from '../../utils/ticketingBridgeMetabase/viewsDynamic';
import { Avatar, ChartDashboardComponent, FieldsDashboard } from '../../utils/types';
import TableChartIcon from '@material-ui/icons/TableChart';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import Tooltip from '@material-ui/core/Tooltip';
import ShowTreeDialog from '../../commons/dialog/ShowTreeDialog';
import { updateGlobalDashboard } from '../../redux/actions/dashboard';
import { AVATARS } from '../../providers/resources';
import extractIdFromURI from '../../utils/id';
import { MenuItemType } from '../../commons/menuButtonActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import OptionMenu from '../statistics/actions/OptionMenu';
import makeStyles from '@material-ui/styles/makeStyles';
import { addDays } from '../../utils/date';

const useStyles = makeStyles(() => ({
    boldUppercase: {
        padding: 0,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 'bold',
    },
}));

const RenderData = ({ data }: { data: ResponseForwarderToMetabaseType }) => {
    const translate = useTranslate();

    const fieldsToShow: FieldsDashboard = {
        time: {
            label: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.fields.time'),
            type: 'time_hours',
        },
        date: { label: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.fields.date'), type: 'date' },
        user_name: { label: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.fields.user') },
        navigation: { label: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.fields.navigation') },
        avatar_name: { label: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.fields.avatarName') },
    };

    return <DashboardTable data={data} fieldsToShow={fieldsToShow} />;
};

interface Props extends ChartDashboardComponent {
    dialogTitle: string;
    label: string;
    dashboardTitle: string;
    dialogHelpText: string;
    infoText: string;
    requestQuestionId: number;
    instanceId: string;
}

interface ConfigProp {
    [key: string]: Identifier;
}

const PLACE = 'place';

const IncidentTimeFromTheBeginningDashboard = (props: Props) => {
    const classes = useStyles();

    const [version, setVersion] = useState(0);
    const dashboardVersion: number = useSelector((state: CustomReducerState) => state.ticketing.dashboards.version);

    const translate = useTranslate();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const periodConfigs = useSelector((state: CustomReducerState) => state.ticketing.dashboards.globalFilters.period);

    const config: ConfigProp | undefined = useSelector((state: CustomReducerState) => {
        const ownDashboardType = state.ticketing.dashboards.global[props.id];
        const chartRegisterData = ownDashboardType.find((item) => item.instanceId === props.instanceId);
        return chartRegisterData ? chartRegisterData.customFields : undefined;
    });

    const placeIdSelected: string | undefined = config && PLACE in config ? (config[PLACE] as string) : undefined;
    // const initialPlaceSelected: Avatar | undefined = useSelector((state: ReduxState) =>
    //     placeIdSelected && state.admin.resources[AVATARS].data && placeIdSelected in state.admin.resources[AVATARS].data
    //         ? (state.admin.resources[AVATARS].data[placeIdSelected] as Avatar)
    //         : undefined
    // );

    // const [placeSelected, setPlaceSelected] = useState<Avatar | undefined>(initialPlaceSelected);
    const [placeSelected, setPlaceSelected] = useState<Avatar | undefined>(undefined);
    const [showTreeDialog, setShowTreeDialog] = useState<boolean>(false);
    const [eventRunningFilter, setEventRunningFilter] = useState<boolean>(true);

    // update version when global dashboard versio change
    useEffect(() => {
        if (dashboardVersion) {
            setVersion(version + 1);
        }
    }, [dashboardVersion]);

    useEffect(() => {
        if (placeSelected) {
            setVersion(version + 1);
        }
    }, [placeSelected]);

    useEffect(() => {
        if (placeIdSelected) {
            // search place always, the place can be removed from finger or backoffice
            dataProvider
                .getOne(
                    AVATARS,
                    { id: placeIdSelected },
                    {
                        onSuccess: (response: GetOneResult) => {
                            if (response && 'data' in response && response.data) {
                                // @ts-ignore
                                setPlaceSelected(response.data as Avatar);
                            }
                        },
                        onFailure: (error: any) => {
                            console.error('ERRROR getting one ', AVATARS, error);
                            setPlaceSelected(undefined);
                        },
                    }
                )
                .catch((e: any) => {
                    console.error('Catch exception getting one ', e);
                    setPlaceSelected(undefined);
                });
        }
    }, [placeIdSelected]);

    useEffect(() => {
        if (periodConfigs && periodConfigs.enabled && periodConfigs.afterAt && periodConfigs.beforeAt) {
            setVersion(version + 1);
        }
        if (periodConfigs && !periodConfigs.enabled && !periodConfigs.afterAt && !periodConfigs.beforeAt) {
            setVersion(version + 1);
        }
    }, [periodConfigs]);

    const validateSelectedPlace = (place?: Avatar) => {
        if (!place) return;
        updateConfig({ [PLACE]: place.id });
    };

    const updateConfig = (config: ConfigProp) => {
        dispatch(updateGlobalDashboard.fn(props.id, props.instanceId, config));
    };

    const handleChangeEventRunning = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventRunningFilter(event.target.checked);
        setVersion(version + 1);
    };

    const optionMenu: MenuItemType[] = [
        {
            icon: null,
            text: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.eventRunning'),
            disabled: false,
            renderComponent: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'primary'}
                            checked={eventRunningFilter}
                            onChange={handleChangeEventRunning}
                            name='eventRunning'
                        />
                    }
                    label={
                        <Typography color={'primary'} variant={'body1'} className={classes.boldUppercase}>
                            {translate('app.dashboards.incidentTimeFromTheBeginningDashboard.eventRunning')}
                        </Typography>
                    }
                />
            ),
            roles: [],
        },
        {
            icon: null,
            onClick: () => setShowTreeDialog(true),
            text: translate('app.dashboards.incidentTimeFromTheBeginningDashboard.place'),
            disabled: false,
            roles: [],
        },
    ];

    return (
        <>
            <DashboardBox
                icon={
                    <Tooltip
                        title={translate(props.infoText, {
                            question: props.requestQuestionId,
                        })}
                    >
                        <TableChartIcon />
                    </Tooltip>
                }
                config={<OptionMenu buttonLabel={'app.dashboards.option'} menuActions={optionMenu} />}
                subtitle={
                    placeSelected
                        ? translate('app.dashboards.incidentTimeFromTheBeginningDashboard.in', {
                              name: (placeSelected as Avatar).name,
                          })
                        : translate('app.dashboards.incidentTimeFromTheBeginningDashboard.nonePlace')
                }
                onClose={props.close}
                title={translate('app.dashboards.incidentTimeFromTheBeginningDashboard.title')}
            >
                <GetMetabaseUrlOfTheData
                    version={version}
                    requestBody={{
                        id: props.requestQuestionId,
                        dashboard_type: 'question',
                        place_id: placeSelected ? extractIdFromURI(placeSelected.id as string) : null,
                        creation_date_before: periodConfigs.beforeAt ? addDays(periodConfigs.beforeAt) : null,
                        creation_date_after: periodConfigs.afterAt ? periodConfigs.afterAt : null,
                        event_running: eventRunningFilter ? 'true' : 'false',
                        event_id: null,
                    }}
                >
                    {(data: any) => <RenderData data={data} />}
                </GetMetabaseUrlOfTheData>
            </DashboardBox>
            {showTreeDialog && (
                <ShowTreeDialog
                    closeAfterValidate={true}
                    dialogTitle={props.dialogTitle}
                    dialogHelpText={props.dialogHelpText}
                    handleValidate={validateSelectedPlace}
                    showButton={false}
                    onClose={() => setShowTreeDialog(false)}
                />
            )}
        </>
    );
};

export default IncidentTimeFromTheBeginningDashboard;
