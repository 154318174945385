import moment from 'moment';

const toDashboardFormatDate = (stringDate: string, momentFormat = 'YYYY-MM-DD') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

const addDays = (stringDate: string, momentFormat = 'YYYY-MM-DD', amount = 1) => {
    const date = moment(stringDate);
    // return date.endOf('day').format(momentFormat);
    return date.add(amount, 'day').format(momentFormat);
};

const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  HH:mm:ss') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};
const toShortFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

export { toShortFormatDate, toFormatDate, toDashboardFormatDate, addDays };
