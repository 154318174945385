import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { DashboardGlobalState } from '../../../redux/reducers/dashboards';
import { charts } from '../types';
import DashboardsList from '../../../commons/dashboards-list/dashboards-list';
import { registerGlobalDashboard } from '../../../redux/actions/dashboard';
import StatisticsAppBar from '../StatisticsAppBar';

interface Props {
    divHeight: number;
}

const Data = ({ divHeight }: Props) => {
    divHeight;

    const dispatch = useDispatch();

    const dashboardsEnabled: DashboardGlobalState = useSelector(
        (state: CustomReducerState) => state.ticketing.dashboards.global
    );

    const removeDashboard = (id: number, instanceId: string) => () => {
        dispatch(registerGlobalDashboard.fn(id, instanceId, false));
    };

    return (
        <div>
            <StatisticsAppBar />
            <DashboardsList items={[]}>
                {dashboardsEnabled &&
                    Object.keys(dashboardsEnabled)
                        .flatMap((chartDashboardId: string) =>
                            parseInt(chartDashboardId) in dashboardsEnabled
                                ? dashboardsEnabled[parseInt(chartDashboardId)].map((chartRegisterData) => {
                                      const chartDashboard = charts.find((c) => c.id === parseInt(chartDashboardId));

                                      if (!chartDashboard) return null;

                                      const Component = chartDashboard.component;

                                      return (
                                          <Component
                                              key={chartRegisterData.instanceId}
                                              instanceId={chartRegisterData.instanceId}
                                              id={chartDashboard.id}
                                              close={removeDashboard(chartDashboard.id, chartRegisterData.instanceId)}
                                              {...(chartDashboard.componentProps ? chartDashboard.componentProps : {})}
                                          />
                                      );
                                  })
                                : []
                        )
                        .filter((i) => !!i)}
            </DashboardsList>
        </div>
    );
};

export default Data;
