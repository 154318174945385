import React from 'react';
import IncidentTypes from '../components/incidentTypes/index';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import Authentication from '../commons/auth/Authentication';
import { incidentTypeNameRoute } from './paths';

const IncidentTypesPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={incidentTypeNameRoute}>{userConnected && <IncidentTypes />}</Authentication>;
};
export default IncidentTypesPage;
