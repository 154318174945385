import React, { useState } from 'react';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';
import { useDispatch } from 'react-redux';
import { changeViewList } from '../../../redux/actions/incidents';
import { IncidentListViewMode } from '../../../utils/types';

const ChangeView = () => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [menuState, setMenuState] = useState(false);
    const handleToggle = () => {
        setMenuState(!menuState);
    };

    const menuActions: MenuItemType[] = [
        {
            icon: <ViewHeadlineIcon />,
            text: translate('app.incidents.view.list'),
            onClick: () => {
                dispatch(changeViewList.fn(IncidentListViewMode.SIMPLE_LIST));
            },
            disabled: false,
            roles: [],
        },
        {
            icon: <ViewColumnIcon />,
            text: translate('app.incidents.view.kanban'),
            onClick: () => {
                dispatch(changeViewList.fn(IncidentListViewMode.KANBAN_LIST));
            },
            disabled: false,
            roles: [],
        },
    ];
    return (
        <Grid item>
            <MenuButtonActions
                buttonLabel={'app.menuActions.changeView'}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />
        </Grid>
    );
};
export default ChangeView;
