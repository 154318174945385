import { Record } from 'react-admin';
import { User } from '../../../utils/types';

export const tokenFoundCreator: TokenFoundCreator = {
    action: '360SC_TICKETING/TOKEN_FOUND',
    fn: (token: string, refreshToken: string): any => ({
        type: tokenFoundCreator.action,
        payload: {
            token,
            refreshToken,
        },
    }),
};

export interface TokenFoundAction {
    type: typeof tokenFoundCreator.action;
    payload: {
        token: string;
        refreshToken: string;
    };
}

export interface TokenFoundCreator {
    action: string;
    fn: (token: string, refreshToken: string) => TokenFoundAction;
}

//----

export interface ResolvedUserConnectedAction {
    type: typeof resolvedUserConnectedCreator.action;
    payload: {
        user: Record | User;
    };
}

export const resolvedUserConnectedCreator: ResolvedUserConnectedCreator = {
    action: '360SC_TICKETING/RESOLVED_USER_CONNECTED',
    fn: (user: Record | User): ResolvedUserConnectedAction => ({
        type: resolvedUserConnectedCreator.action,
        payload: {
            user,
        },
    }),
};

export interface ResolvedUserConnectedCreator {
    action: string;
    fn: (user: Record | User) => ResolvedUserConnectedAction;
}

// ---
export interface ResolvedCompanyLogoOnFileSystemMapperAction {
    type: typeof resolvedCompanyLogoOnFileSystemMapperCreator.action;
    payload: {
        companyLogoUrl: string;
    };
}

export const resolvedCompanyLogoOnFileSystemMapperCreator: ResolvedCompanyLogoOnFileSystemMapperCreator = {
    action: '360SC_TICKETING/RESOLVE_COMPANY_LOGO_ON_FILE_SYSTEM_MAPPER_SERVICE',
    fn: (companyLogoUrl: string): ResolvedCompanyLogoOnFileSystemMapperAction => ({
        type: resolvedCompanyLogoOnFileSystemMapperCreator.action,
        payload: {
            companyLogoUrl,
        },
    }),
};

export interface ResolvedCompanyLogoOnFileSystemMapperCreator {
    action: string;
    fn: (companyLogoUrl: string) => ResolvedCompanyLogoOnFileSystemMapperAction;
}

export interface SelectUserAction {
    type: typeof selectUser.action;
    payload: {
        user: User | null;
    };
}

export interface SelectUserCreator {
    action: string;
    fn: (user: User | null) => SelectUserAction;
}

export const selectUser: SelectUserCreator = {
    action: '360SC_TICKETING/SELECT_USER',
    fn: (user: User | null): SelectUserAction => ({
        type: selectUser.action,
        payload: {
            user,
        },
    }),
};

export type ACTIONS =
    | TokenFoundAction
    | ResolvedUserConnectedAction
    | ResolvedCompanyLogoOnFileSystemMapperAction
    | SelectUserAction;
