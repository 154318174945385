import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import React from 'react';

import { AuthContext, DataProviderContext, Notification, Resource, TranslationProvider } from 'react-admin';
import { Provider } from 'react-redux';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import {
    AVATAR_PROPERTY,
    AVATAR_TRACEABILITY_NOTES,
    AVATARS,
    AVATARS_MOVE_TO,
    COMPANIES,
    FINGERS,
    GROUP_METADATA_AVATAR_TYPES,
    LANGUAGES,
    MEDIA,
    MEDIAS,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
    PROFILES,
    RUNNER_TEMPLATES,
    TICKETING_EVENT_INSTRUCTION_HELPS,
    TICKETING_EVENT_USERS,
    TICKETING_MANAGER_NOTIFICATIONS,
    TICKETING_MANAGER_PLACES,
    TICKETING_PLACE_OR_EVENT,
    TICKETING_PLACE_OR_EVENT_RESET_EVENT,
    TICKETING_PLACE_OR_EVENT_ROOT_EVENT,
    TICKETING_PROCESS_CUSTOMIZATIONS,
    TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
    TICKETING_USER_ALLOCATIONS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
    YOURLS,
} from './providers/resources';
import createAdminStore from './redux/createAdminStore';
import './styles.css';

import theme from './theme';
import MainPage from './pages/MainPage';
import { IncidentStatus } from './utils/CONST';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const history = createHashHistory();

const statusNames = Object.keys(IncidentStatus);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 5000,
            retry: false,
        },
    },
});

const App = () => (
    <Provider
        store={createAdminStore({
            authProvider,
            dataProvider,
            history,
        })}
    >
        <AuthContext.Provider value={authProvider}>
            <DataProviderContext.Provider value={dataProvider}>
                <TranslationProvider i18nProvider={i18nProvider}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <Resource name={AVATARS} intent={'registration'} />
                            <Resource name={METADATA_AVATAR_TYPES} intent={'registration'} />
                            <Resource name={GROUP_METADATA_AVATAR_TYPES} intent={'registration'} />
                            <Resource name={USERS} intent={'registration'} />
                            <Resource name={FINGERS} intent={'registration'} />
                            <Resource name={RUNNER_TEMPLATES} intent={'registration'} />
                            <Resource name={COMPANIES} intent={'registration'} />
                            <Resource name={MEDIAS} intent={'registration'} />
                            <Resource name={MEDIA} intent={'registration'} />
                            <Resource name={YOURLS} intent={'registration'} />
                            <Resource name={TICKETING_EVENT_USERS} intent={'registration'} />
                            <Resource name={TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES} intent={'registration'} />
                            <Resource name={TICKETING_USER_ALLOCATIONS} intent={'registration'} />
                            <Resource name={AVATAR_PROPERTY} intent={'registration'} />
                            <Resource name={TICKETING_PLACE_OR_EVENT_ROOT_EVENT} intent={'registration'} />
                            <Resource name={TICKETING_PLACE_OR_EVENT_RESET_EVENT} intent={'registration'} />
                            <Resource name={TICKETING_PLACE_OR_EVENT} intent={'registration'} />
                            <Resource name={TICKETING_PROCESS_CUSTOMIZATIONS} intent={'registration'} />
                            <Resource name={AVATARS_MOVE_TO} intent={'registration'} />
                            <Resource name={TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS} intent={'registration'} />
                            <Resource name={LANGUAGES} intent={'registration'} />
                            <Resource name={METADATA_AVATAR_TYPE_PROPERTIES} intent={'registration'} />
                            <Resource name={TICKETING_MANAGER_NOTIFICATIONS} intent={'registration'} />
                            <Resource name={TICKETING_MANAGER_PLACES} intent={'registration'} />
                            <Resource name={PROFILES} intent={'registration'} />
                            {statusNames.map((status) => (
                                <Resource
                                    name={`${AVATARS}-${status}`}
                                    key={`${AVATARS}-${status}`}
                                    intent={'registration'}
                                />
                            ))}
                            <Resource name={TICKETING_EVENT_INSTRUCTION_HELPS} intent={'registration'} />
                            <Resource name={AVATAR_TRACEABILITY_NOTES} intent={'registration'} />
                            <ConnectedRouter history={history}>
                                <MainPage />
                            </ConnectedRouter>
                            <Notification />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </ThemeProvider>
                </TranslationProvider>
            </DataProviderContext.Provider>
        </AuthContext.Provider>
    </Provider>
);

export default App;
