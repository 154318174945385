import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { createTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomIconButton = styled(IconButton)({
    padding: 4,
});

const GreenButtonTheme = createTheme({
    palette: {
        secondary: {
            main: '#008000',
        },
    },
});

const CustomCircularProgress = styled(CircularProgress)({
    marginLeft: 6,
});

export { CustomIconButton, GreenButtonTheme, CustomCircularProgress };
