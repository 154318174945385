import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import './index.css';
import Divider from '@material-ui/core/Divider';
import { IncidentListViewMode } from '../../../utils/types';
import Input from '@material-ui/core/Input';
import ShowIncidentStatusDialog from '../../../commons/dialog/ShowIncidentStatusDialog';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { GridTitleFilterListStyled } from '../../../commons/filters/utils';

const useStyles = makeStyles({
    filterForm: {
        marginTop: '0 !important',
        display: 'grid !important',
    },
    fontWeightBold: {
        fontWeight: 700,
    },
    marginTop: {
        marginTop: 4,
    },
    paddingTop: {
        paddingTop: 32,
    },
    customFilter: {
        paddingTop: 32,
        marginLeft: 12,
        marginRight: 12,
    },
    filterFormPaddingTop: {
        paddingTop: 32,
        width: '100%',
    },
    paddingTopBold: {
        fontWeight: 'bold',
        paddingTop: 15,
    },
    fontWeightBoldAndPaddingTop: {
        fontWeight: 'bold',
        paddingTop: 15,
    },
    inputFilter: {
        marginBottom: 20,
    },
    clearButton: {
        width: 24,
        height: 24,
        padding: 0,
        minWidth: 24,
        color: 'grey',
        background: 'transparent',
    },
    closeIcon: {
        width: 16,
        height: 24,
    },
    filterMarginBottomForm: {
        marginTop: '0 !important',
        display: 'grid !important',
        marginBottom: '-16px',
    },
});

interface Props {
    filters?: any;
    alwaysOn?: boolean;
}

const EventStateFilter = (props: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { setFilters, displayedFilters, filterValues } = useListContext();

    const currentNameInFilterValue =
        filterValues && 'currentNode.name' in filterValues && filterValues['currentNode.name']
            ? filterValues['currentNode.name']
            : null;

    const [showStatusDialog, setShowStatusDialog] = useState<boolean>(false);

    const [statusFilter, setStatusFilter] = useState<string[] | null>(currentNameInFilterValue);

    const viewMode: IncidentListViewMode = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.viewMode
    );

    const showStatusDialogFn = () => {
        setShowStatusDialog(true);
    };

    const closeStatusDialogFn = () => {
        setShowStatusDialog(false);
    };

    const handleIncidentStatus = (status: string[]) => {
        setStatusFilter(status);
        setFilters(
            {
                ...filterValues,
                'currentNode.name': status,
            },
            displayedFilters
        );
        closeStatusDialogFn();
    };

    const cleanIncidentStatus = () => {
        setStatusFilter([]);
        delete filterValues['currentNode.name'];
        setFilters(
            {
                ...filterValues,
            },
            displayedFilters
        );
        closeStatusDialogFn();
    };

    return (
        <>
            {viewMode === IncidentListViewMode.SIMPLE_LIST ? (
                <>
                    <Divider />
                    <GridTitleFilterListStyled>
                        <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                            {translate('app.incidents.filters.status.label')}
                        </Typography>
                    </GridTitleFilterListStyled>
                </>
            ) : (
                <span />
            )}
            {viewMode === IncidentListViewMode.SIMPLE_LIST ? (
                <Input
                    key={'state'}
                    multiline={true}
                    onClick={showStatusDialogFn}
                    fullWidth
                    onReset={() => console.log('reset')}
                    placeholder={translate('app.incidents.filters.status.label')}
                    className={classes.inputFilter}
                    value={statusFilter ? statusFilter : ''}
                />
            ) : (
                <span />
            )}
            {showStatusDialog && (
                <ShowIncidentStatusDialog
                    initialValues={statusFilter}
                    open={showStatusDialog}
                    handleValidate={handleIncidentStatus}
                    dialogTitle={'app.incidents.filters.status.title'}
                    onClose={closeStatusDialogFn}
                    onCancel={closeStatusDialogFn}
                    thirdButtonLabel={'app.incidents.filters.status.noFilter'}
                    thirdButtonAction={cleanIncidentStatus}
                />
            )}
        </>
    );
};

export default EventStateFilter;
