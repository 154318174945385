import { Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import PhotoIcon from '@material-ui/icons/Photo';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { resolveMedia as resolveMediaAction } from '../../../redux/actions/medias';
import { CustomReducerState } from '../../../redux/reducers';
import { ImageStyles } from '../../../theme';
import { getDownloadFileSystemMapperUrl } from '../../../utils/medias';
import { Avatar } from '../../../utils/types';
import ShowImages from '../../show-images/show-images';
import DialogTemplate from '../DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../DialogTemplateButtons/DialogTemplateButtons';

const useStyles = makeStyles((theme: any) => ({
    image: {
        ...ImageStyles,
    },
    imageField: {
        height: 160,
        width: 'auto',
        margin: '0 0 -5px 0',
    },
    linearProgress: {
        height: 3,
        width: 20,
        margin: 0,
    },
    bold: {
        fontWeight: 'bold',
    },
    badge: {
        '& .MuiBadge-badge': {
            right: 20,
            top: 20,
            border: `1px solid ${theme.palette.background.paper}`,
        },
    },
    customDiv: {
        height: 150,
        width: 150,
        cursor: 'pointer',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

interface Props {
    open: boolean;
    imagesIds?: string[];
    comment?: string;
    event: Avatar;
    onClose: () => void;
    recordId: any;
}

const DialogMediaAndComment = ({ comment, imagesIds, event, open, onClose, recordId }: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [showImageDialogState, setShowImageDialogState] = useState(false);

    const medias: any = useSelector((state: CustomReducerState) => state.ticketing.medias);

    useEffect(() => {
        if (imagesIds) {
            imagesIds.map((image) => {
                if (!(image in medias)) {
                    dispatch(resolveMediaAction.fn(image, recordId));
                }
            });
        }
    }, []);

    const getMediasUrls = () => {
        const mediasUrls: any = [];

        if (imagesIds) {
            imagesIds.map((imageId) => {
                if (imageId in medias) {
                    mediasUrls.push(
                        getDownloadFileSystemMapperUrl(medias[imageId].media, medias[imageId].fileSystemMapper)
                    );
                }
            });
        }

        return mediasUrls;
    };

    return (
        <DialogTemplate
            title={translate('app.incidents.imageAndComment')}
            open={open}
            onClose={onClose}
            icon={<PhotoIcon />}
            maxWidth={'sm'}
        >
            <Grid container>
                <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                    <Typography variant={'subtitle1'} className={classes.bold}>
                        {translate('app.incidents.comment', { eventName: event.name })}
                    </Typography>

                    <Typography paragraph>{comment ? comment : ''}</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {imagesIds && imagesIds.length > 0 && (
                        <Badge
                            badgeContent={imagesIds ? imagesIds.length : 0}
                            color='primary'
                            invisible={imagesIds ? imagesIds.length === 1 : true}
                            className={classes.badge}
                        >
                            <div
                                className={classes.customDiv}
                                style={{
                                    backgroundImage: `url(${getMediasUrls()[0]})`,
                                }}
                                onClick={(e) => {
                                    setShowImageDialogState(true);
                                }}
                            />
                        </Badge>
                    )}

                    {showImageDialogState && imagesIds && imagesIds.length > 0 && (
                        <ShowImages
                            autoplay={false}
                            handleOnClose={() => {
                                setShowImageDialogState(false);
                            }}
                            imageJsons={getMediasUrls().map((imageUrl: any) => ({
                                name: 'image',
                                url: imageUrl,
                                key: imageUrl,
                            }))}
                            open={showImageDialogState}
                        />
                    )}
                </Grid>
            </Grid>

            <DialogTemplateButtons outside withCancel onCancelClick={onClose} />
        </DialogTemplate>
    );
};

export default DialogMediaAndComment;
