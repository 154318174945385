import React, { useEffect } from 'react';
import {
    USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD,
    USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD,
    USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD,
    USER_SERVICE_PROVIDER_TYPE_FIELD,
} from '../actions/CONST';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { BooleanInput, RadioButtonGroupInput, TextInput, usePermissions, useTranslate } from 'react-admin';
import UserInfoInput from './userInfoInput';
import Typography from '@material-ui/core/Typography';
import {
    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../utils/user/customFields';
import ImageCropperField from '../field/ImageCropperField';
import { hasPermissions } from '../../../utils/permissions';
import { ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../../utils/ROLES';

interface Props {
    initializeUserServiceProviderType?: boolean;
    handleImageDataUser: (data: { name: string; data: string } | null) => void;
    initialImageData?: { name: string; data: string };
    loadingImage?: boolean;
}
const UserServiceProviderInput = (props: Props) => {
    const translate = useTranslate();
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const { input: userServiceProviderType } = useField(USER_SERVICE_PROVIDER_TYPE_FIELD);
    const isUserAPerson = userServiceProviderType.value === USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD;

    useEffect(() => {
        if (props.initializeUserServiceProviderType) {
            userServiceProviderType.onChange(USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD);
        }
    }, []);

    return (
        <>
            <Grid item>
                <RadioButtonGroupInput
                    label={translate('app.users.serviceProvider.type')}
                    source={USER_SERVICE_PROVIDER_TYPE_FIELD}
                    choices={[
                        {
                            id: USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD,
                            name: translate('app.users.serviceProvider.person'),
                        },
                        {
                            id: USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD,
                            name: translate('app.users.serviceProvider.company'),
                        },
                    ]}
                />
            </Grid>

            <UserInfoInput isUserAPerson={isUserAPerson} />
            {hasPermissions([ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD], loadedPermissions, permissions) && (
                <ImageCropperField
                    handleImageDataUser={props.handleImageDataUser}
                    initialImageData={props.initialImageData}
                />
            )}

            <>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <BooleanInput
                        label={
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                {translate('app.users.serviceProvider.underContract')}
                            </Typography>
                        }
                        source={USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD}
                    />
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <TextInput
                        variant={'standard'}
                        fullWidth
                        type={'text'}
                        label={translate('app.users.serviceProvider.referenceClientOrContract')}
                        source={TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD}
                    />
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <TextInput
                        variant={'standard'}
                        fullWidth
                        type={'text'}
                        label={translate('app.users.serviceProvider.serviceType')}
                        source={TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}
                    />
                </Grid>
            </>
        </>
    );
};

export default UserServiceProviderInput;
