import { Tooltip, Typography, TypographyVariant } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import * as _ from 'lodash';
import React from 'react';
import { Avatar } from '../../../utils/types';
import ManagerAndDelegatedSubManager from './ManagerAndDelegatedSubManager';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import {
    AVATAR_TICKETING_CUSTOM_FIELDS,
    AVATAR_TICKETING_USER_CONFIRMATION_AMOUNT,
    END_NODE_TYPE,
} from '../../../utils/CONST';
import { useTranslate } from 'react-admin';
import ResolutionIncidentOpinionField from './ResolutionIncidentOpinionField';
import { Variant } from '@material-ui/core/styles/createTypography';

export const StyledRootBadge = withStyles((theme) => ({
    badge: {
        // border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 3px',
        minWidth: 20,
        height: 20,
        top: 9,
        marginRight: '-9px',
        // backgroundColor: 'red',
        color: 'red',
        fontWeight: 'bold',
    },
}))(Badge);

const useStyles = makeStyles({
    labelMarginLeftWithBadge: {
        // fontSize: 14,
        // fontSize: '1rem',
        marginRight: 10,
        marginLeft: 24,
    },
});

interface OverrideStylesProps {
    name: any;
}

const IncidentNameAndExtraInformation = ({
    avatar,
    showDivider = true,
    overrideStyles,
    nameTypographyVariant,
}: {
    avatar: Avatar;
    showDivider?: boolean;
    overrideStyles?: OverrideStylesProps;
    nameTypographyVariant?: TypographyVariant;
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const eventConfirmationAmount: number =
        avatar?.customFields?.[AVATAR_TICKETING_CUSTOM_FIELDS]?.[AVATAR_TICKETING_USER_CONFIRMATION_AMOUNT];

    const renderBadge = () => {
        return (
            <div style={{ display: 'flex' }}>
                {eventConfirmationAmount ? (
                    <Tooltip title={translate('app.incidents.confirmationAmount')}>
                        <StyledRootBadge badgeContent={`x${eventConfirmationAmount}`} />
                    </Tooltip>
                ) : (
                    <span />
                )}
                <Typography
                    variant={nameTypographyVariant ? nameTypographyVariant : 'body2'}
                    className={eventConfirmationAmount ? classes.labelMarginLeftWithBadge : ''}
                >
                    {_.capitalize(avatar.name)}
                </Typography>
            </div>
        );
    };

    const canBeShownClosedEventOpinion = () => {
        const currentNode = avatar.currentReferencing?.standardReferencing?.runner?.node;
        const nodeTypes = currentNode ? currentNode.nodeTypes : [];
        return currentNode && nodeTypes && nodeTypes.includes(END_NODE_TYPE);
    };
    return (
        <Grid container direction={'column'}>
            {renderBadge()}

            {showDivider && (
                <Grid
                    item
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginBottom: 2,
                    }}
                >
                    <Divider />
                </Grid>
            )}

            {canBeShownClosedEventOpinion() && (
                <ResolutionIncidentOpinionField event={avatar} enableDialog={true} showSeeMoreText={true} />
            )}
            <ManagerAndDelegatedSubManager record={avatar} />
        </Grid>
    );
};

export default IncidentNameAndExtraInformation;
