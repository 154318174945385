import React from 'react';
import { useSelector } from 'react-redux';
import Authentication from '../commons/auth/Authentication';
import NavigationPageIndex from '../components/navigation';
import { CustomReducerState } from '../redux/reducers';
import { main } from './paths';
import ActionsContextProvider from '../commons/tree/ActionsContextProvider';
import useActionsController from '../commons/tree/useActionsController';

const NavigationPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return (
        <Authentication redirectTo={main}>
            {userConnected && (
                <ActionsContextProvider value={useActionsController()}>
                    <NavigationPageIndex />
                </ActionsContextProvider>
            )}
        </Authentication>
    );
};

export default NavigationPage;
