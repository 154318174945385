import React, { useEffect } from 'react';
import { ListControllerProps, useListContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { Avatar } from '../../../utils/types';
import TreeView from '../../../commons/tree/v1/CustomTreeView';
import FalseShouldUpdate from '../../../commons/fields/recomposeLike/falseShouldUpdate';

interface Props {
    divHeight: number;
}

const NavigationSideBar = ({ divHeight }: Props) => {
    const listContext: ListControllerProps<Avatar> = useListContext();

    const treeLevelSelected = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.levelItemSelected
    );

    const updateFilter = () => {
        listContext.setFilters(
            {
                ...listContext.filterValues,
                parent: treeLevelSelected,
            },
            listContext.displayedFilters
        );
    };

    useEffect(() => {
        updateFilter();
    }, [treeLevelSelected]);

    return (
        <FalseShouldUpdate>
            {/*@ts-ignore*/}
            <TreeView updateFilter={updateFilter} divHeight={divHeight} />
        </FalseShouldUpdate>
    );
};

export default NavigationSideBar;
