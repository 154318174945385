export const publishTokenActionCreator = {
    action: '360/PUBLISH_TOKEN',
    fn: (id: string, tokenWithRoles: string) => ({
        type: publishTokenActionCreator.action,
        payload: {
            id,
            tokenWithRoles,
        },
    }),
};
