import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { useTranslate } from 'react-admin';
import PersonalizeDashboardButton from './actions/PersonalizeDashboardButton';
import PreconfiguredPeriodFilterButton from './actions/PreconfiguredPeriodFilterButton';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import makeStyles from '@material-ui/styles/makeStyles';
import { ButtonFontWeightBoldStyles } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardVersion } from '../../redux/actions/dashboard';
import { CustomReducerState } from '../../redux/reducers';

const useStyles = makeStyles({
    button: {
        paddingLeft: 10,
        paddingRight: 10,
        ...ButtonFontWeightBoldStyles,
    },
    paddingTop: {
        paddingTop: 10,
    },
    positionSticky: {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
});

const StatisticAppBar = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const dashboardVersion: number = useSelector((state: CustomReducerState) => state.ticketing.dashboards.version);

    const refreshView = () => {
        dispatch(updateDashboardVersion.fn(dashboardVersion + 1));
    };

    return (
        <AppBar color={'default'} position={'sticky'} classes={{ positionSticky: classes.positionSticky }}>
            <Grid container direction={'row'} spacing={2} className={classes.paddingTop}>
                <Grid item>
                    <PersonalizeDashboardButton />
                </Grid>
                <Grid item>
                    <Button color='primary' onClick={refreshView} className={classes.button}>
                        <RefreshIcon />
                        {translate('app.menuActions.refresh')}
                    </Button>
                </Grid>
            </Grid>

            <Grid item>
                <PreconfiguredPeriodFilterButton />
            </Grid>
        </AppBar>
    );
};

export default StatisticAppBar;
