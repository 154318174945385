import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { Record, useTranslate } from 'react-admin';
import { Avatar } from '../../utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import DialogTemplate from '../Dialog/DialogTemplate/DialogTemplate';
import Tree from '../tree';
import Grid from '@material-ui/core/Grid';
import ButtonsBlockForm from '../../components/forms/buttonsBlock';
import { setConfigurationCreator } from '../../redux/actions/navigation';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';

interface Props {
    dialogTitle: string;
    dialogHelpText: string;
    thirdButtonLabel?: string;
    handleValidate: (item: Avatar | undefined) => void;
    showButton?: boolean;
    onClose?: () => void;
    unSelectItem?: () => void;
    closeAfterValidate?: boolean;
}

const ShowTreeDialog = ({
    dialogTitle,
    dialogHelpText,
    handleValidate,
    showButton = true,
    onClose,
    closeAfterValidate = false,
    unSelectItem,
    thirdButtonLabel,
}: Props) => {
    const [open, setOpen] = useState<boolean>(!showButton);
    const dispatch = useDispatch();

    const handleOnClick = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };

    const translate = useTranslate();

    const treeLevelSelectedUri = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.levelItemSelected
    );

    const avatarSelected: Record | undefined = useSelector((state: CustomReducerState) =>
        treeLevelSelectedUri && treeLevelSelectedUri in state.ticketing.navigation.tree.avatarMapper
            ? state.ticketing.navigation.tree.avatarMapper[treeLevelSelectedUri]
            : undefined
    );

    console.log('avatarselected', avatarSelected?.id);

    useEffect(() => {
        dispatch(setConfigurationCreator.fn({ options: { enabled: false } }, false));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setConfigurationCreator.fn({ options: { enabled: true } }, false));
        };
    }, []);

    return (
        <>
            {showButton && (
                <IconButton onClick={handleOnClick} style={{ padding: 2, marginLeft: 10 }}>
                    <SettingsIcon color={'primary'} />
                </IconButton>
            )}
            {open && (
                <DialogTemplate
                    open={open}
                    helpText={translate(dialogHelpText, {
                        name: avatarSelected ? (avatarSelected as Avatar).name : '',
                    })}
                    title={translate(dialogTitle)}
                    onClose={handleClose}
                    icon={<SettingsIcon />}
                    maxWidth={'md'}
                >
                    <Tree />
                    <Grid item style={{ paddingTop: 8 }}>
                        <ButtonsBlockForm
                            onValid={() => {
                                handleValidate(avatarSelected ? (avatarSelected as Avatar) : undefined);
                                closeAfterValidate && handleClose();
                            }}
                            disableValidButton={!avatarSelected}
                            validButtonIsSubmitType={false}
                            labelValidButton={'app.valid'}
                            onCancel={handleClose}
                            thirdButtonLabel={
                                thirdButtonLabel ? translate(thirdButtonLabel) : translate('app.noElement')
                            }
                            thirdButtonAction={unSelectItem}
                        />
                    </Grid>
                </DialogTemplate>
            )}
        </>
    );
};
export default ShowTreeDialog;
