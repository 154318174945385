import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import {
    CreateResult,
    Record,
    ReduxState,
    useCreate,
    useDataProvider,
    useDelete,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { AutocompleteInput } from '../../../../commons/ra/input/AutocompleteInput';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';

import { AVATARS, TICKETING_MANAGER_PLACES, USERS } from '../../../../providers/resources';
import {
    AVATAR_IDENTIFIER_TICKETING_EVENT_DELEGATED,
    TICKETING_MANAGER_TAG,
    TICKETING_SUB_MANAGER_TAG,
} from '../../../../utils/CONST';
import { Avatar, TicketingManagerPlaces, User } from '../../../../utils/types';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import { MANAGER_AND_SUBMANAGER_DELEGATION_ERROR_CODE } from '../../../../utils/ERROR_CODE';
import queryString from 'query-string';
import { CustomReducerState } from '../../../../redux/reducers';

const USER_FIELD = 'user';

interface Props {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    avatar: Avatar;
}

const DelegateConfigForm = ({ open, onClose, onCancel, avatar }: Props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const notify = useNotify();
    const [update] = useUpdate();
    const [create] = useCreate();
    const [deleteOne] = useDelete();
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const currentDelegatedManagerPlace: Record | undefined = useSelector((state: CustomReducerState & ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_MANAGER_PLACES].data).find(
            (recordManagerPlace: Record) => {
                return (recordManagerPlace as TicketingManagerPlaces).placeOrEvent === avatar.id;
            }
        );
    });

    const currentDelegatedUserManagerUri = currentDelegatedManagerPlace
        ? typeof currentDelegatedManagerPlace.userManager === 'string'
            ? currentDelegatedManagerPlace.userManager
            : currentDelegatedManagerPlace.userManager['@id']
        : null;

    const onSubmit = async (data: { [key: string]: any }) => {
        setLoadingState(true);

        const userId = USER_FIELD in data ? data[USER_FIELD] : null;

        if (currentDelegatedManagerPlace) {
            if (userId) {
                updateManagerPlace(
                    currentDelegatedManagerPlace,
                    { userManager: userId },
                    () => {
                        onClose();
                        notify('app.incidents.delegateDialog.update.success', 'success');
                        getAvatar(
                            avatar.id as string,
                            () => {
                                //
                            },
                            (error: any) => {
                                console.error(error);
                            }
                        );
                    },
                    () => {
                        notify('app.incidents.delegateDialog.update.fail', 'error');
                        setLoadingState(false);
                    }
                );
            } else {
                deleteManagerPlace(
                    currentDelegatedManagerPlace,
                    { userManager: userId },
                    () => {
                        notify('app.incidents.delegateDialog.delete.success', 'success');
                        updateAvatarIdentifiers(
                            avatar,
                            ``,
                            false,
                            () => {
                                // notify('app.incidents.delegateDialog.update.success', 'success');
                                setLoadingState(false);
                                onClose();
                                // refresh();
                            },
                            () => {
                                notify('app.incidents.delegateDialog.update.fail', 'success');
                                setLoadingState(false);
                            }
                        );
                    },
                    () => {
                        notify('app.incidents.delegateDialog.delete.fail', 'error', {
                            errorCode: MANAGER_AND_SUBMANAGER_DELEGATION_ERROR_CODE,
                        });
                        setLoadingState(false);
                    }
                );
            }
        } else {
            createManagerPlace(
                {
                    placeOrEvent: avatar.id,
                    company: typeof avatar.company === 'object' ? avatar.company['@id'] : avatar.company,
                    userManager: userId,
                },
                (data: any) => {
                    notify('app.incidents.delegateDialog.create.success', 'success');
                    setLoadingState(false);
                    onClose();
                },
                (error: any) => {
                    notify('app.incidents.delegateDialog.create.fail', 'success');
                    onClose();
                }
            );
        }
    };

    const createManagerPlace = (data: any, successCallback: any, failureCallback: any) => {
        create(TICKETING_MANAGER_PLACES, data, {
            onSuccess: (response: CreateResult) => {
                successCallback(response.data);
            },
            onFailure: (error: any) => {
                failureCallback(error);
            },
        });
    };

    const updateManagerPlace = (managerPlace: any, data: any, successCallback: any, failureCallback: any) => {
        update(TICKETING_MANAGER_PLACES, managerPlace['@id'], data, managerPlace, {
            onSuccess: (response: CreateResult) => {
                successCallback(response.data);
            },
            onFailure: (error: any) => {
                failureCallback(error);
            },
        });
    };

    const deleteManagerPlace = (managerPlace: any, data: any, successCallback: any, failureCallback: any) => {
        deleteOne(TICKETING_MANAGER_PLACES, managerPlace['@id'], managerPlace, {
            onSuccess: () => {
                successCallback();
            },
            onFailure: (error: any) => {
                failureCallback(error);
            },
        });
    };

    const updateAvatarIdentifiers = (
        avatar: Avatar,
        identifier: string,
        addOrRemove: boolean,
        successCallback: any,
        failureCallback: any
    ) => {
        const identifiers = 'identifiers' in avatar && Array.isArray(avatar.identifiers) ? [...avatar.identifiers] : [];
        const index = identifiers.findIndex((item) => item.startsWith(AVATAR_IDENTIFIER_TICKETING_EVENT_DELEGATED));
        if (addOrRemove) {
            if (index !== -1) {
                identifiers[index] = identifier;
            } else {
                identifiers.push(identifier);
            }
        } else {
            if (index !== -1) identifiers.splice(index, 1);
        }

        update(AVATARS, avatar.id, { identifiers }, avatar, {
            onSuccess: () => {
                getAvatar(
                    avatar.id as string,
                    () => {
                        successCallback();
                    },
                    (error: any) => {
                        failureCallback(error);
                    }
                );
            },
            onFailure: (error: any) => {
                failureCallback(error);
            },
        });
    };

    const renderOptionText = (user: User | undefined) => (user ? `${user?.username} ` : '');

    const getAvatar = (avatarUri: string, successCallback: any, failureCallback: any) => {
        dataProvider.getOne(
            AVATARS,
            {
                id: `${avatarUri}?${queryString.stringify(
                    {
                        serialization_groups: [
                            'read_meta',
                            'read_mc',
                            'read_simple_company',
                            'read_avatar_current_referencing',
                            'read_avatar_current_referencing_nodes',
                            'read_meta_group_meta',
                        ],
                    },
                    //@ts-ignore
                    { arrayFormat: 'bracket' }
                )}`,
            },
            {
                onSuccess: () => {
                    successCallback();
                },
                onFailure: (error: any) => {
                    failureCallback(error);
                },
            }
        );
    };

    return (
        <DialogTemplate
            open={open}
            icon={<PersonIcon />}
            onClose={onCancel}
            maxWidth={'xs'}
            title={translate('app.incidents.delegateDialog.title')}
        >
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{ [USER_FIELD]: currentDelegatedUserManagerUri }}
                render={({ handleSubmit, pristine, submitting }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <ReferenceInput
                                        reference={USERS}
                                        variant={'standard'}
                                        filterToQuery={(searchText: string) => ({
                                            lastName: searchText,
                                        })}
                                        filter={{
                                            'tags[one]': [TICKETING_SUB_MANAGER_TAG, TICKETING_MANAGER_TAG],
                                        }}
                                        source={USER_FIELD}
                                        label={translate('app.incidents.delegateDialog.user')}
                                    >
                                        <AutocompleteInput
                                            resettable
                                            filterToQuery={(search: string) => ({ lastName: search })}
                                            variant={'standard'}
                                            optionText={renderOptionText}
                                            fullWidth
                                        />
                                    </ReferenceInput>
                                </Grid>

                                <ButtonsBlockForm
                                    disableValidButton={pristine || submitting || loadingState}
                                    validButtonIsSubmitType={true}
                                    labelValidButton={'app.valid'}
                                    onCancel={onCancel}
                                    loading={loadingState}
                                />
                            </Grid>
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default DelegateConfigForm;
