import { Grid, makeStyles } from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { TopToolbar, useRefresh, useTranslate } from 'react-admin';

import { ButtonFontWeightBoldStyles, TopToolbarButtonStyled, TopToolbarStyles } from '../../../theme';
import SimpleActionMenu from './menuActions';

const useStyles = makeStyles({
    // @ts-ignore
    topToolbar: {
        ...TopToolbarStyles,
    },
    buttonFontWeightBold: {
        ...ButtonFontWeightBoldStyles,
    },
    gridButtonOpenNewView: {
        // ...GridButtonShowViewStyles.gridButtonOpenNewView,
    },
    buttonOpenNewView: {
        // ...GridButtonShowViewStyles.buttonOpenNewView,
    },
});

// interface Props {
//     filters: any;
//     maxResults: any;
//     resource: any;
//     showFilter: any;
//     displayedFilters: any;
//     filterValues: any;
// }

const Actions = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const refreshView: () => void = useRefresh();

    return (
        <TopToolbar className={classes.topToolbar}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                    <SimpleActionMenu />
                </Grid>

                <Grid item>
                    <TopToolbarButtonStyled
                        className={classes.buttonFontWeightBold}
                        startIcon={<RefreshIcon />}
                        onClick={refreshView}
                    >
                        {translate('app.menuActions.refresh')}
                    </TopToolbarButtonStyled>
                </Grid>
            </Grid>
        </TopToolbar>
    );
};

export default Actions;
