import React, { useEffect, useState } from 'react';
import { Avatar, TicketingBridgeResponseForwarderMetabase } from '../../../utils/types';
import { toFormatDate } from '../../../utils/date';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { Question13 } from '../../../utils/CONST';

interface Props {
    record: Avatar;
}

const ResolvedIncidentDateField = (props: Props) => {
    const data: TicketingBridgeResponseForwarderMetabase | undefined = useSelector((state: CustomReducerState) =>
        props.record.id in state.ticketing.incidents.metabaseData
            ? state.ticketing.incidents.metabaseData[props.record.id][13]
            : undefined
    );
    const [date, setDate] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (data) {
            if (Array.isArray(data.rows) && data.rows.length > 0) {
                setDate(data.rows[0][Question13.resolutionDate] as string);
            }
        }
    }, [data]);

    return <span>{date ? toFormatDate(date) : '...'}</span>;
};

export default ResolvedIncidentDateField;
