import { takeLatest, put } from 'redux-saga/effects';
import { CLEAR_STATE } from 'react-admin';
import { clearTicketingStateCreator } from '../../actions/ticketingState';

export const clearCustomTicketingState = () =>
    function* () {
        yield put(clearTicketingStateCreator.fn());
    };

const ticketingStateProvider = () =>
    function* () {
        yield takeLatest(CLEAR_STATE, clearCustomTicketingState());
    };

export default ticketingStateProvider;
