import { all, call, spawn } from 'redux-saga/effects';
import { DataProvider } from '../../providers/dataProvider';
import hydraDataProviderSaga from './hydraDataProvider';
import usersSaga from './user';
import avatarsSaga from './avatars';
import fileManager from './fileManager/fileManager';
import medias from './medias';
import ticketingStateProvider from './ticketingState';
import ui from './ui';

export default (dataProvider: DataProvider) => {
    return function* rootSaga() {
        const sagas = [
            hydraDataProviderSaga(dataProvider),
            usersSaga(dataProvider),
            fileManager(),
            avatarsSaga(dataProvider),
            medias(dataProvider),
            ticketingStateProvider(),
            ui(),
        ];

        yield all(
            sagas.map((saga) =>
                spawn(function* () {
                    while (true) {
                        try {
                            yield call(saga);
                            break;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                })
            )
        );
    };
};
