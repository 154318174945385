import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CustomUseQueryOptions, ORDER } from './types';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';

const useGetList = (
    resource: string,
    params: {
        pagination: { page: number; perPage: number };
        sort: { field: string; order: ORDER };
        filter: { [key: string]: any };
    },
    options?: CustomUseQueryOptions
) => {
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const { sort, filter, pagination } = params;

    const keyToFind = options && 'keyToFind' in options ? options.keyToFind : 'keyToFind';

    const result: any = useQuery({
        queryKey: [keyToFind, 'getList', sort, filter, pagination],
        queryFn: () =>
            dataProvider.getList(resource, {
                sort,
                pagination,
                filter,
            }),
        onSuccess: (value: any) => {
            if (options?.onSuccess) {
                options.onSuccess(value);
            }
        },
        onError: (error: any) => {
            console.log('useGetListError:', error);
            notify(options?.errorMessage ? translate(options.errorMessage) : error.message, { type: 'error' });
            if (options?.onError) {
                options.onError(error);
            }
        },
        ...options,
    });

    return useMemo(
        () =>
            result.data
                ? {
                      ...result,
                      data: result.data?.data,
                      total: result.data?.total,
                  }
                : { ...result, data: [], total: 0 },
        [result]
    ) as UseQueryResult & {
        total: number;
        data: any[];
    };
};

export default useGetList;
