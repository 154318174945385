import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Avatar } from '../../../../utils/types';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import { CustomIconButton } from './commons';
import { simulateMainFingerMc } from '../../../../utils/simulateApp';

interface Props {
    record: Avatar;
}

const SeeIncidentButton = (props: Props) => {
    const translate = useTranslate();
    const { record } = props;
    const disabled = record !== null && (record as Avatar).mcs.length < 1;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const simulateApp = (avatar: Avatar) => () => {
        simulateMainFingerMc(avatar, dataProvider)
            .then((result) => {
                result && window.open(result.mcUrl, '_blank', 'width=411px,height=731px');
            })
            .catch(() => {
                notify('app.errors.server', 'error');
            });
    };

    return (
        <Tooltip title={translate('app.incidents.information.seeIncident')}>
            <CustomIconButton onClick={record && !disabled ? simulateApp(record as Avatar) : () => null}>
                <RemoveRedEye color={disabled ? 'disabled' : 'primary'} />
            </CustomIconButton>
        </Tooltip>
    );
};

export default SeeIncidentButton;
