import React, { useEffect, useState } from 'react';
import { GetOneResult, Identifier, ReduxState, useDataProvider, useNotify, useTranslate } from 'react-admin';
import DashboardBox from '../../commons/dashboard-box/dashboard-box';
import DashboardTable from '../../commons/dashboard-table/dashboard-table';
import GetMetabaseUrlOfTheData from '../../commons/get-metabase-url-of-the-data/get-metabase-url-of-the-data';
import { ResponseForwarderToMetabaseType } from '../../utils/ticketingBridgeMetabase/viewsDynamic';
import { Avatar, ChartDashboardComponent, FieldsDashboard } from '../../utils/types';
import ShowResourceAutocompleteDialog from '../statistics/actions/ShowResourceAutocompleteDialog';
import { getNameWithoutPrefixFromJson } from '../../utils/resources/GroupMetadataAvatarType';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalDashboard } from '../../redux/actions/dashboard';
import { CustomReducerState } from '../../redux/reducers';
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';
import { MenuItemType } from '../../commons/menuButtonActions';
import OptionMenu from '../statistics/actions/OptionMenu';
import extractIdFromURI, { getUriFromItem } from '../../utils/id';
import ShowTreeDialog from '../../commons/dialog/ShowTreeDialog';
import { AVATARS } from '../../providers/resources';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { addDays } from '../../utils/date';

const useStyles = makeStyles(() => ({
    boldUppercase: {
        padding: 0,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 'bold',
    },
}));

const RenderData = ({ data, itemField }: { data: ResponseForwarderToMetabaseType; itemField: string }) => {
    const translate = useTranslate();
    const fieldsToShow: FieldsDashboard = {
        ranking: {
            label: translate('app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.fields.ranking'),
        },
        avatar_name: {
            label: translate('app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.fields.avatar', {
                item_field: itemField,
            }),
        },
        event_amount: {
            label: translate(
                'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.fields.numberOfIncidents'
            ),
        },
        event_percent: {
            label: translate(
                'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.fields.incidentPercent'
            ),
            type: 'percent',
        },
    };

    return <DashboardTable data={data} fieldsToShow={fieldsToShow} />;
};

const PLACE = 'place';
const EVENT_RUNNING = 'event_running';
const SUBCATEGORY = 'subcategory';

interface ConfigProp {
    [SUBCATEGORY]?: Identifier | null;
    [EVENT_RUNNING]?: boolean | null;
    [PLACE]?: Identifier | null;
}

interface Props extends ChartDashboardComponent {
    filterToQuery: (data: any) => any;
    reference: string;
    dialogTitle: string;
    filters: any;
    formFieldName: string;
    label: string;
    dashboardTitle: string;
    dialogHelpText: string;
    infoText: string;
    requestFieldName: string;
    requestQuestionId: number;
    menuOptionLabel: {
        [key: string]: string;
    };
}

const TopCategoriesWithIncidentsFilteredByCategoryDashboard = (props: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const notify = useNotify();

    const config: ConfigProp | undefined = useSelector((state: CustomReducerState) => {
        const ownDashboardType = state.ticketing.dashboards.global[props.id];
        const chartRegisterData = ownDashboardType.find((item) => item.instanceId === props.instanceId);
        return chartRegisterData ? chartRegisterData.customFields : undefined;
    });

    const periodConfigs = useSelector((state: CustomReducerState) => state.ticketing.dashboards.globalFilters.period);
    const dashboardVersion: number = useSelector((state: CustomReducerState) => state.ticketing.dashboards.version);

    const isEventRunningEnable: boolean | undefined =
        config && EVENT_RUNNING in config ? (config[EVENT_RUNNING] as boolean) : undefined;

    const [previousEventRunningEnable, setPreviousEventRunningEnable] = useState<boolean | undefined>(false);

    const placeIdSelected: string | undefined = config && PLACE in config ? (config[PLACE] as string) : undefined;
    const [placeSelected, setPlaceSelected] = useState<Avatar | undefined>(undefined);
    const [version, setVersion] = useState(0);
    const [item, setItem] = useState<{ id: Identifier; name: string } | undefined>();

    const [showCategoriesDialog, setShowCategoriesDialog] = useState<boolean>(false);
    const [showTreeDialog, setShowTreeDialog] = useState<boolean>(false);

    const [showPlaceSelectedWarning, setShowSelectedWarning] = useState<boolean>(false);

    useEffect(() => {
        updateConfig({ [EVENT_RUNNING]: true });
        setPreviousEventRunningEnable(true);
    }, []);

    useEffect(() => {
        if (periodConfigs && periodConfigs.enabled && periodConfigs.afterAt && periodConfigs.beforeAt) {
            setVersion(version + 1);
        }
        if (periodConfigs && !periodConfigs.enabled && !periodConfigs.afterAt && !periodConfigs.beforeAt) {
            setVersion(version + 1);
        }
    }, [periodConfigs]);

    const updateConfig = (config: ConfigProp) => {
        dispatch(updateGlobalDashboard.fn(props.id, props.instanceId, config));
    };

    // update version when global dashboard versio change
    useEffect(() => {
        if (dashboardVersion) {
            setVersion(version + 1);
        }
    }, [dashboardVersion]);

    // get place selected and update version
    useEffect(() => {
        if (placeSelected) {
            setVersion(version + 1);
        }
    }, [placeSelected]);

    useEffect(() => {
        if (placeIdSelected) {
            // search place
            dataProvider
                .getOne(
                    AVATARS,
                    { id: placeIdSelected },
                    {
                        onSuccess: (response: GetOneResult) => {
                            if (response && 'data' in response && response.data) {
                                // @ts-ignore
                                setPlaceSelected(response.data as Avatar);
                            }
                        },
                        onFailure: (error: any) => {
                            console.error('ERRROR getting one ', AVATARS, error);
                            setPlaceSelected(undefined);
                        },
                    }
                )
                .catch((e: any) => {
                    console.error('Catch exception getting one ', e);
                    setPlaceSelected(undefined);
                });
        }
    }, [placeIdSelected]);

    useEffect(() => {
        if (placeSelected && item) {
            const placeSelectedMetadataAvatarType = getUriFromItem(placeSelected.metadataAvatarType);
            if (placeSelectedMetadataAvatarType !== item.id) {
                setPlaceSelected(undefined);
                updateConfig({ [PLACE]: null });
            }
        }
    }, [item]);

    useEffect(() => {
        if (config && SUBCATEGORY in config && config[SUBCATEGORY]) {
            dataProvider
                .getOne(
                    props.reference,
                    // @ts-ignore
                    { id: config[SUBCATEGORY] },
                    {
                        onSuccess: (response: GetOneResult) => {
                            if (response && 'data' in response && response.data) {
                                // @ts-ignore
                                setItem(response.data);
                            }
                        },
                        onFailure: (error: any) => {
                            console.error('ERRROR getting one ', props.reference, error);
                        },
                    }
                )
                .catch((e: any) => {
                    console.error('Catch exception getting one ', e);
                    setPlaceSelected(undefined);
                });
            setVersion(version + 1);
        }
        if (config && EVENT_RUNNING in config && config[EVENT_RUNNING] !== previousEventRunningEnable) {
            setVersion(version + 1);
        }
    }, [config]);

    useEffect(() => {
        if (placeSelected) {
            setVersion(version + 1);
        }
    }, [placeSelected]);

    const handleChangeEventRunning = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPreviousEventRunningEnable(isEventRunningEnable);
        updateConfig({ [EVENT_RUNNING]: event.target.checked });
    };

    const validateSelectedPlace = (place?: Avatar) => {
        if (!place) return;
        const placeMetadataAvatarTypeId = getUriFromItem(place.metadataAvatarType);
        if (item && placeMetadataAvatarTypeId === item.id) {
            setShowTreeDialog(false);
            updateConfig({ [PLACE]: place.id });
            setShowSelectedWarning(false);
        } else {
            setShowSelectedWarning(true);
            setTimeout(() => {
                setShowSelectedWarning(false);
            }, 2000);
            notify(
                'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.invalidSubcategoryForPlace',
                'warning',
                { name: place.name }
            );
        }
    };

    const optionMenu: MenuItemType[] = [
        {
            icon: null,
            text: translate('app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.eventRunning'),
            disabled: false,
            renderComponent: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'primary'}
                            checked={isEventRunningEnable}
                            onChange={handleChangeEventRunning}
                            name='eventRunning'
                        />
                    }
                    label={
                        <Typography color={'primary'} variant={'body1'} className={classes.boldUppercase}>
                            {translate(
                                'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.eventRunning'
                            )}
                        </Typography>
                    }
                />
            ),
            roles: [],
        },
        {
            icon: null,
            onClick: () => setShowCategoriesDialog(true),
            text: translate(props.menuOptionLabel[props.formFieldName]),
            disabled: false,
            roles: [],
        },
        {
            icon: null,
            onClick: () => setShowTreeDialog(true),
            text: translate('app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.place'),
            disabled: !item,
            roles: [],
        },
    ];

    return (
        <>
            <DashboardBox
                config={<OptionMenu buttonLabel={'app.dashboards.option'} menuActions={optionMenu} />}
                icon={
                    <Tooltip title={translate(props.infoText, { question: props.requestQuestionId })}>
                        <TableChartIcon />
                    </Tooltip>
                }
                title={translate(props.dashboardTitle, {
                    // @ts-ignore
                    item: item ? getNameWithoutPrefixFromJson(item).toLowerCase() : '...',
                })}
                onClose={props.close}
                subtitle={
                    placeSelected
                        ? translate('app.dashboards.in', {
                              name: (placeSelected as Avatar).name,
                          })
                        : translate('app.dashboards.nonePlace')
                }
            >
                {item && (
                    <GetMetabaseUrlOfTheData
                        version={version}
                        requestBody={{
                            id: props.requestQuestionId,
                            dashboard_type: 'question',
                            opened_incidents: null,
                            [props.requestFieldName]: config ? extractIdFromURI(config[SUBCATEGORY] as string) : null,
                            place_id: placeSelected ? extractIdFromURI(placeSelected.id as string) : null,
                            creation_date_before: periodConfigs.beforeAt ? addDays(periodConfigs.beforeAt) : null,
                            creation_date_after: periodConfigs.afterAt ? periodConfigs.afterAt : null,
                            event_running: isEventRunningEnable ? 'true' : 'false',
                        }}
                    >
                        {(data: any) => (
                            <RenderData
                                data={data}
                                // @ts-ignore
                                itemField={item ? getNameWithoutPrefixFromJson(item).toLowerCase() : '...'}
                            />
                        )}
                    </GetMetabaseUrlOfTheData>
                )}
            </DashboardBox>

            {showCategoriesDialog && (
                <ShowResourceAutocompleteDialog
                    dialogHelpText={''}
                    dialogTitle={props.dialogTitle}
                    filterToQuery={props.filterToQuery}
                    reference={props.reference}
                    filters={props.filters}
                    formFieldName={props.formFieldName}
                    label={props.label}
                    setConfig={(config: ConfigProp) => {
                        updateConfig(config);
                        setShowCategoriesDialog(false);
                    }}
                    showButton={false}
                    onClose={() => setShowCategoriesDialog(false)}
                />
            )}

            {showTreeDialog && item && (
                <ShowTreeDialog
                    dialogTitle={props.dialogTitle}
                    dialogHelpText={
                        !showPlaceSelectedWarning
                            ? props.dialogHelpText
                            : 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.invalidSubcategoryForPlace'
                    }
                    handleValidate={validateSelectedPlace}
                    showButton={false}
                    onClose={() => setShowTreeDialog(false)}
                />
            )}
        </>
    );
};

export default TopCategoriesWithIncidentsFilteredByCategoryDashboard;
