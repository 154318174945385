// @ts-ignore
import { Identifier, Record } from 'react-admin';
import { ReactElement } from 'react';
import { USER_MANAGER_CATEGORY, USER_PROVIDER_CATEGORY, USER_SUB_MANAGER_CATEGORY } from './CONST';

export interface TicketingEventUser extends Record {
    event: string;
    user: string;
    blocked: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface GroupMetadataAvatarType extends Record {
    name: string;
    alias: string;
    customFields: {
        [key: string]: any;
    };
    identifiers: {
        [key: string]: any;
    };
}

export interface Image extends Record {
    name: string;
    company: string;
    source: string;
}

export interface MetadataAvatarType extends Record {
    name: string;
    alphaId: string;
    image?: string;
    images: string[];
    groupMetadataAvatarType: GroupMetadataAvatarType | string;
    company: Company | string;
    customFields: {
        [key: string]: any;
    };
}

export interface MetadataAvatarTypeProperty extends Record {
    name: string;
    definition?: string;
    fieldType: string;
    option: string;
    order: number;
    isEncrypted?: boolean;
    values: any[];
    metadataAvatarType: Identifier;
    languages: (Identifier | undefined)[];
}

export interface MetadataAvatarTypePropertyDatum {
    name: string;
    definition?: string;
    fieldType: string;
    option: string;
    isEncrypted?: boolean;
    values: any[];
    metadataAvatarType: Identifier;
    languages: (Identifier | undefined)[];
    migrateToAvatar?: boolean;
}

export interface AvatarProperty extends Record {
    name: string;
    definition: string;
    fieldType: string;
    option: string;
    order: number;
    isEncrypted: boolean;
    value: any;
    unit: string;
    languages: string[];
    enabled: boolean;
    avatar: string;
}

export interface MC extends Record {
    avatar: string;
    creationDate: string;
    pairedUp: boolean;
    yourls: string;
}

export interface AvatarProperty extends Record {
    id: Identifier;
    name: string;
    definition: string;
    fieldType: string;
    option: string;
    order: number;
    isEncrypted: boolean;
    value: any;
    unit: string;
    languages: string[];
}

export interface Avatar extends Record {
    name: string;
    alphaId: string;
    metadataAvatarType: MetadataAvatarType;
    mcs: string[] | MC[];
    lvl?: number;
    lft?: number;
    rgt?: number;
    creationDate?: string;
    children?: Avatar[];
    parent?: Avatar;
    root?: Avatar | string;
    company: Company | string;
    currentReferencing?: {
        standardReferencing?: {
            id: string;
            runner?: {
                node: Node;
                firstNode: Node;
            };
        };
    };
    identifiers?: string[];
    customFields?: { [key: string]: any };
}

export interface NodeComponentForms {
    id: string;
    name: string;
    formData: any;
    type: string;
    required: boolean;
}

export interface NodeComponentPhotos {
    id: string;
    name: string;
    type: string;
    required: boolean;
}

export interface NodeMedias {
    id: string;
    customFields: [];
}

export interface Node {
    id: Identifier;
    name: string;
    dateOfTakesPlace: string;
    nodeTypes: string[];
    configuration: {
        customFields?: {
            componentForms: {
                data: NodeComponentForms;
            }[];
            componentPhotos: {
                data: NodeComponentPhotos;
            }[];
        };
    };
    extraData: {
        customFields?: {
            forms?: {
                [key: string]: any;
            };
        };
        medias?: NodeMedias[];
    };
}

export interface Yourls extends Record {
    short: string;
    mc: string;
    mcUrl: string;
    finger: string;
    routeParameters: string[];
    creationDate: string;
    isActive: boolean;
}

export interface Company extends Record {
    name: string;
    email: string;
    additionalAddress: string;
    city: string;
    country: string;
    postalCode: string;
    logo:
        | {
              id: string;
              name: string;
              contentUrlTokenAccess: string;
              source: string;
              customFields: any[];
          }
        | string;
}

export interface User extends Record {
    id: Identifier;
    originId?: string;
    firstName: string;
    lastName: string;
    username: string;
    mobile: string;
    company: Company | string;
    email: string;
    image?:
        | {
              id: string;
              name: string;
          }
        | string;
    lastLogin: string;
    tags: string[] | undefined;
    profiles?: string[];
    customFields?: {
        [key: string]: any;
        ticketing?: {
            serviceProvider?: {
                reference?: string;
                serviceType?: string;
            };
        };
    };
}

export interface SubcategoryEventTypeDependencies extends Record {
    id: Identifier;
    eventTypes: MetadataAvatarType[];
    subCategory: MetadataAvatarType;
    place: string;
    customFields?: {
        event_types_order?: {
            [key: string]: number;
        };
    };
}

export interface TicketingUserAllocation extends Record {
    id: Identifier;
    user: string;
    company: string;
    place: Avatar;
    subCategories: string[];
    categories: string[];
}

export interface TicketingProcessCustomizations extends Record {
    id: Identifier;
    createdAt: string;
    company: string;
    place: Avatar;
    addAutomaticallyUserProvider: boolean;
    hasTakeIntoAccountSubProcess: boolean;
    takeIntoAccountAutomaticallyUserUnderContract: boolean;
    runnerTemplate: string;
    finger: string;
}

export interface Language extends Record {
    twoLetters: string;
    value: string;
}

export interface Media extends Record {
    id: Identifier;
    name: string;
    source: string;
    contentUrlTokenAccess: string;
    company: string;
}

export interface CreateMedia {
    resourceSupported: 'user' | 'avatar' | 'company';
    mediaType: 'IMAGE' | 'FILE';
    fileName: string;
    base64data: string;
    resourceId: string;
}

export interface ImageInputType {
    rawFile: File;
}

export interface FileSystemMapper {
    id: string;
    ['@id']: string;
    name: string;
    externalIdentification: string;
    companyId: string;
    createdAt: string;
    metadata: {
        'metadata.cloud': any[];
        'metadata.extra': {
            'coreapi.mediaid': string;
        };
    };
    source: string;
    lvl: number;
    lft: number;
    rgt: number;
    isFolder: boolean;
    contentUrl: string;
    downloadUrl: string;
    tags: string[];
    isStructureFolder: boolean;
}

export interface TicketingBridgeMetabaseViewDynamic {
    iframeURL: string;
    metabase_url: string;
    metabase_token: string;
    get_response_forwarder_to_metabase_api: string;
}

export interface TicketingBridgeResponseForwarderMetabase {
    rows: any[][];
    cols: {
        name: string;
    }[];
}

export enum ChartDashboardId {
    one = 1,
    two,
    three,
    four,
    five,
    six,
}

export interface ChartDashboard {
    id: ChartDashboardId;
    label: string;
    labelShort?: string;
    component: React.FC<{ instanceId: string; id: number; close: () => void }>;
    componentProps?: any;
    roles?: string[];
    maxRepeated: number;
}

export interface ChartDashboardComponent {
    instanceId: string;
    id: number;
    close: () => void;
}

export interface NavigationConfiguration {
    options: {
        enabled: boolean;
    };
}

export interface TicketingUserProviderEventConfiguration extends Record {
    company: string;
    user: string;
    workResolutionDelay: number;
    subCategory: string;
    place: string;
}

export interface TicketingManagerNotification extends Record {
    company: string;
    place: string;
    subCategory: string;
    userManager: string;
    hasNotUserProvider: boolean;
    assignedUnderContract: boolean;
}

export interface TicketingManagerPlaces extends Record {
    company: string;
    placeOrEvent: string;
    userManager: User | string;
}

export interface PlaceOrEventPath {
    id: string;
    name: string;
    lvl: number;
}

export interface PlaceOrEventCount {
    count: number;
    subCategory: string;
}

export interface FieldsDashboard {
    [key: string]: {
        label: string;
        type?: 'time_hours' | 'date' | 'percent';
        width?: number;
    };
}

export enum NavigationItemActionType {
    ADD_ITEM_AS_CHILD = 'ADD_ITEM_AS_CHILD',
    ADD_ITEM_ROOT = 'ADD_ITEM_ROOT',
    EDIT_ITEM = 'EDIT_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
}

export interface ListItemCollapseDatum {
    label: string;
    onClick?: () => void;
    icon?: ReactElement;
    disabled?: boolean;
    roles: string[];
}

export interface ListItemProps {
    icon?: ReactElement;
    label: string;
    onClick?: () => void;
    witCollapse?: boolean;
    collapseData?: ListItemCollapseDatum[];
    openByDefault: boolean;
}

export interface TicketingPlaceOrEventResetEvent {
    event: string;
    nodeId: Identifier;
    extraData: string[] | any[];
    newUserProvider?: Identifier;
    reassignCurrentUserProvider?: boolean;
    sendMail?: boolean;
}

export interface CreateUser {
    email: string;
    company: string;
    username?: string;
    profiles?: string[];
    roles?: string[];
    tags?: string[];
    firstName?: string;
    lastName?: string;
    mobile?: string;
    customFields?: { [key: string]: any };
}

export interface Profile extends Record {
    name: string;
    companies: string[];
    internalName: string;
}

export interface RestartPasswordForgottenResponse {
    isOverRetryTtl: boolean;
}
export const KANBAN_LIST = 'KANBAN_LIST';
export const SIMPLE_LIST = 'SIMPLE_LIST';
export enum IncidentListViewMode {
    KANBAN_LIST = 'KANBAN_LIST',
    SIMPLE_LIST = 'SIMPLE_LIST',
}

export type UserCategory =
    | typeof USER_PROVIDER_CATEGORY
    | typeof USER_MANAGER_CATEGORY
    | typeof USER_SUB_MANAGER_CATEGORY;
