import MUIAvatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import {
    ChipField,
    FunctionField,
    Record,
    ReduxState,
    translate,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { RecordAware } from '../../../components/navigation/aside/itemActions/serviceProvider/incident/SingleFieldList';
import {
    TICKETING_USER_ALLOCATIONS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../providers/resources';
import { removedItemsActionCreator } from '../../../redux/actions/removedItems';
import { APP_ENABLED_COLOR, StyledBadge } from '../../../theme';
import {
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
} from '../../../utils/CONST';
import extractIdFromURI from '../../../utils/id';
import { getNameWithoutPrefixFromJson } from '../../../utils/resources/GroupMetadataAvatarType';
import {
    Avatar,
    MetadataAvatarType,
    TicketingUserAllocation,
    TicketingUserProviderEventConfiguration,
    User,
} from '../../../utils/types';
import ReferenceField from '../../ra/field/ReferenceField';

const CustomChipField = styled(ChipField)({
    margin: 2,
});

const useStyles = makeStyles(() => ({
    badgeRoot: {
        backgroundColor: APP_ENABLED_COLOR,
    },
}));

interface Props {
    ticketingUserAllocation: TicketingUserAllocation;
    place: Avatar;
    subCategory: MetadataAvatarType;
}

/**
 * deprecated
 * @param ticketingUserAllocation
 * @param place
 * @param subCategory
 * @constructor
 */
const TicketingUserAllocationChipField = ({ ticketingUserAllocation, place, subCategory }: Props) => {
    const classes = useStyles();
    const notify = useNotify();
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const translate = useTranslate();

    const checkIfServiceProviderUnderContract = (user: any) => {
        const tags = user.tags;

        if (!Array.isArray(tags)) return false;

        return (
            (tags.includes(TICKETING_USER_SERVICE_PROVIDER_TAG) &&
                tags.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG)) ||
            (tags.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG) &&
                tags.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG))
        );
    };

    return (
        <ReferenceField
            reference={USERS}
            source={'user'}
            linkType={false}
            link={false}
            record={ticketingUserAllocation}
        >
            <FunctionField
                render={(user: Record | undefined) => {
                    const isSamePlace =
                        extractIdFromURI(ticketingUserAllocation.place.id as string) ===
                        extractIdFromURI(place.id as string);
                    const onDeleteProps: { onDelete?: (event?: any) => void } = {};
                    const [update] = useUpdate(TICKETING_USER_ALLOCATIONS);

                    const ticketingUserProviderEventConfig:
                        | TicketingUserProviderEventConfiguration
                        | undefined = useSelector((state: ReduxState) => {
                        return Object.values(
                            state.admin.resources[TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS].data
                        ).find(
                            (item) =>
                                (item as TicketingUserProviderEventConfiguration).place === place.id &&
                                (item as TicketingUserProviderEventConfiguration).subCategory === subCategory.id &&
                                (item as TicketingUserProviderEventConfiguration).user === user?.id &&
                                !Object.keys(
                                    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS in
                                        state.ticketing.removedItems.resources
                                        ? state.ticketing.removedItems.resources[
                                              TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS
                                          ]
                                        : []
                                ).includes((item as TicketingUserProviderEventConfiguration).id as string)
                        ) as TicketingUserProviderEventConfiguration;
                    });

                    onDeleteProps.onDelete = (event: any): void => {
                        //
                    };
                    if (isSamePlace) {
                        const handleOnDelete = (user: User) => () => {
                            update(
                                TICKETING_USER_ALLOCATIONS,
                                `${ticketingUserAllocation.id}/add_or_remove`,
                                {
                                    subCategory: subCategory.id,
                                    add: false,
                                },
                                ticketingUserAllocation,
                                {
                                    onSuccess: () => {
                                        notify(
                                            'app.navigation.configuration.serviceProvider.subCategory.removed.notification',
                                            'info',
                                            {
                                                username: user.username,
                                                incident: getNameWithoutPrefixFromJson(subCategory),
                                            }
                                        );

                                        if (ticketingUserProviderEventConfig) {
                                            dispatch(
                                                removedItemsActionCreator.fn(
                                                    ticketingUserProviderEventConfig.id,
                                                    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS
                                                )
                                            );
                                        }

                                        refresh();
                                    },
                                    onFailure: (error: any) => {
                                        console.error('ERROR updating', TICKETING_USER_ALLOCATIONS, error);
                                        notify(
                                            'app.navigation.configuration.serviceProvider.subCategory.removed.error',
                                            'error'
                                        );
                                    },
                                }
                            );
                        };
                        onDeleteProps.onDelete = handleOnDelete(user as User);
                    }

                    const name =
                        typeof ticketingUserAllocation.place === 'object' ? ticketingUserAllocation.place.name : '';

                    return (
                        <Tooltip
                            placement='top'
                            title={
                                !isSamePlace
                                    ? translate('app.navigation.configuration.serviceProvider.assigned', {
                                          place_name: name.toUpperCase(),
                                      })
                                    : ''
                            }
                        >
                            <span>
                                {/*// @ts-ignore*/}
                                {(ticketingUserAllocation as RecordAware)._metadata_.index !== 0 && (
                                    <CustomChipField
                                        avatar={
                                            <Tooltip
                                                placement='top'
                                                title={translate(
                                                    'app.navigation.configuration.serviceProvider.assigned',
                                                    {
                                                        place_name: name.toUpperCase(),
                                                    }
                                                )}
                                            >
                                                <MUIAvatar>{name.slice(0, 3).toUpperCase()}</MUIAvatar>
                                            </Tooltip>
                                        }
                                        source={'username'}
                                        disabled={!isSamePlace}
                                        {...onDeleteProps}
                                        // deleteIcon={<MenuIcon />}
                                    />
                                )}
                                {/*// @ts-ignore*/}
                                {(ticketingUserAllocation as RecordAware)._metadata_.index === 0 && (
                                    <StyledBadge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        badgeContent=' '
                                        classes={{
                                            badge: classes.badgeRoot,
                                        }}
                                        overlap={'circular'}
                                    >
                                        <CustomChipField
                                            avatar={
                                                <Tooltip
                                                    placement='top'
                                                    title={translate(
                                                        'app.navigation.configuration.incident.eventType.assigned',
                                                        {
                                                            place_name: name.toUpperCase(),
                                                        }
                                                    )}
                                                >
                                                    <MUIAvatar>{name.slice(0, 3).toUpperCase()}</MUIAvatar>
                                                </Tooltip>
                                            }
                                            source={'username'}
                                            disabled={!isSamePlace}
                                            // @ts-ignore
                                            record={{
                                                ...user,
                                                username: `${(user as User).username} ${
                                                    ticketingUserProviderEventConfig &&
                                                    checkIfServiceProviderUnderContract(user)
                                                        ? ticketingUserProviderEventConfig.workResolutionDelay >= 1
                                                            ? `(${ticketingUserProviderEventConfig.workResolutionDelay}h)`
                                                            : `(${
                                                                  ticketingUserProviderEventConfig.workResolutionDelay *
                                                                  100
                                                              }min)`
                                                        : ''
                                                }`,
                                            }}
                                            {...onDeleteProps}
                                        />
                                    </StyledBadge>
                                )}
                            </span>
                        </Tooltip>
                    );
                }}
            />
        </ReferenceField>
    );
};

export default TicketingUserAllocationChipField;
