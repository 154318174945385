import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { AutocompleteInput, required, TextInput, useNotify, useTranslate, useUpdate } from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AVATARS, METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { Avatar, NavigationItemActionType } from '../../../../utils/types';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import { navigation } from '../../../../pages/paths';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../../utils/CONST';
import CreateSubcategoryFromAutocompleteInput from '../../../subCategories/actions/create/CreateFromAutocompleteInput';
import extractIdFromURI from '../../../../utils/id';
import { useQueryClient } from '@tanstack/react-query';

const NAME_FIELD = 'name';
const METADATA_AVATAR_TYPE_FIELD = 'metadataAvatarType';

interface Props {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    item: Avatar;
    notifyAction?: (actionType: NavigationItemActionType) => void;
}

const EditItemDialog = ({ open, onClose, onCancel, item, notifyAction }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [update, updateData] = useUpdate(AVATARS);
    const queryClient = useQueryClient();

    const onSubmit = (data: { [NAME_FIELD]: any; [METADATA_AVATAR_TYPE_FIELD]: any }) => {
        if (item) {
            const dataToSend = {
                name: data[NAME_FIELD],
                metadataAvatarType: data[METADATA_AVATAR_TYPE_FIELD],
            };

            update(AVATARS, item['@id'], dataToSend, item, {
                onSuccess: () => {
                    notify('app.messages.success', 'success');
                    if ('parent' in item && item.parent && 'id' in item.parent) {
                        queryClient.invalidateQueries([
                            `navigationParent_${extractIdFromURI(item.parent.id as string)}`,
                        ]);
                    } else {
                        queryClient.invalidateQueries([`rootNavigationAvatars`]);
                    }
                    onClose();
                },
                onFailure: () => {
                    notify('app.avatar.updated.error', 'error', {
                        value: capitalizeFirstLetter(item.name),
                    });
                },
            });
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.editItem.title')}
            open={open}
            onClose={onClose}
            icon={<EditIcon />}
            maxWidth={'xs'}
        >
            <FinalForm
                initialValues={{
                    name: item ? item.name : '',
                    metadataAvatarType: item ? item.metadataAvatarType : '',
                }}
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, valid, pristine }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <TextInput
                                        variant={'standard'}
                                        source={NAME_FIELD}
                                        label={translate('app.navigation.editItem.name')}
                                        helperText={false}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <ReferenceInput
                                        basePath={navigation}
                                        reference={METADATA_AVATAR_TYPES}
                                        filter={{
                                            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                                            hidden: false,
                                            order__createdAt: 'DESC',
                                        }}
                                        filterToQuery={(search: string) => ({ name: search })}
                                        source={'metadataAvatarType'}
                                        label={translate('app.navigation.addItem.selectSubCategory')}
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput
                                            resettable
                                            filterToQuery={(search: string) => ({ name: search })}
                                            create={<CreateSubcategoryFromAutocompleteInput />}
                                            optionValue={'@id'}
                                            optionText={'name'}
                                            fullWidth
                                            variant={'standard'}
                                        />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>

                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={handleCancel}
                                withEdit
                                isValidForm={!pristine && valid}
                                isSubmitting={updateData.loading}
                                cancelButtonLabel={translate('app.buttons.return')}
                                createButtonLabel={translate('app.buttons.validate')}
                            />
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};

const validate = (values: { [key: string]: string }) => {
    const errors: { [key: string]: string } = {};
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};

export default EditItemDialog;
