const locale = {
    app: {
        name: 'Incident tracking',
        actions: 'Actions',
        add: 'Add',
        api: {
            errors: {
                avatar_property: {
                    not_unique: 'Identifier should not be repeated. Please change it',
                },
            },
        },
        archive: 'Archive',
        avatar: {
            updated: {
                notification: 'Item edited',
                error: 'There was an error while editing the %{value}',
            },
        },
        avatarProperty: {
            addressRoot: 'Address',
            location: 'Localisation',
            created: {
                notification: 'Item added',
                error: 'There was an error while adding the item',
            },
            updated: {
                notification: '%{property_name} edited',
                error: 'There was an error while editing the %{property_name}',
            },
            get: {
                error: 'There was an error while editing the %{property_name}',
            },
        },
        buttons: {
            add: 'Add',
            addIcon: 'Add an icon',
            cancel: 'Cancel',
            cancelClone: 'Cancel',
            cancelMove: 'Cancel',
            cancelReorder: 'Cancel',
            cloneAsRoot: 'Clone as root',
            create: 'Create',
            delete: 'Delete',
            edit: 'Edit',
            iconLibrary: 'icon library',
            next: 'Next',
            no: 'no',
            remove: 'Remove',
            return: 'Return',
            selectIcon: 'Select icon',
            uploadFile: 'upload a file',
            valid: 'Validate',
            validate: 'Validate',
            yes: 'yes',
        },
        cancel: 'Cancel',
        category: {
            name: 'Name',
            title: 'Category',
            categories: 'Categories',
            subcategories: 'Subcategories',
            categoriesAndSubcategories: 'Category and subcategory',
            categoryIncidentType: 'Incident',
            create: {
                title: 'Create a category',
                input: {
                    name: 'Name',
                },
            },
            edit: {
                title: 'Edit category',
                input: {
                    name: 'Name',
                },
            },
            delete: {
                title: 'Remove category',
                subtitle: 'Are you sure you want to delete this category?',
            },
            archive: {
                title: 'Archive category',
                subtitle: 'Are you sure you want to archive %{name}?',
                success: 'Category archived successfully',
            },
            breadcrumbs: {
                1: 'Configuration',
                0: 'My categories',
            },
            created: {
                notification: 'The event category was well created',
                error: 'There was an error creating the event category',
            },
            updated: {
                notification: 'Category edited successfully',
                error: 'There was an error editing the event category',
            },
            event: {
                breadcrumbs: {
                    1: 'Configuration',
                    0: 'my event categories',
                },
            },
        },
        dashboards: {
            filters: {
                period: {
                    lastWeek: 'Last week',
                    lastMonth: 'Last month',
                    lastYear: 'Last years',
                },
                afterAt: 'After at',
                beforeAt: 'Before at',
            },
            option: 'Option',
            in: 'In: %{name}',
            nonePlace: '',
            quantityOfOpenAndContractedIncidentsDashboard: {
                title: 'Number of incidents open and under contract',
                infoText: 'Dashboard number: %{question}',
                helpText: 'Place selected %{name}',
                place: 'Place',
                in: 'In: %{name}',
                nonePlace: '',
            },
            numberOfIncidentsAwaitingAllocationDashboard: {
                title: 'Number Of Incidents Awaiting Allocation',
                infoText: 'Dashboard number: %{question}',
                helpText: 'Place selected %{name}',
                place: 'Place',
                in: 'In: %{name}',
                nonePlace: '',
            },
            topCategoriesWithIncidentsFilteredByCategoryDashboard: {
                title: 'Top 10 %{item} with incidents',
                titleShort: 'Top 10 of categories with incidents',
                selectCategory: 'Select a category',
                selectSubcategory: 'Select a subcategory',
                fields: {
                    ranking: 'ranking',
                    category: '%{item_field}',
                    subcategory: '%{item_field}',
                    avatar: '%{item_field}',
                    numberOfIncidents: 'Number of incidents',
                    incidentPercent: 'Incident Percent',
                },
                helpText: 'Place selected %{name}',
                infoText: 'Dashboard number: %{question}',
                category: 'Category',
                subcategory: 'Subcategory',
                place: 'Place',
                eventRunning: 'Incident in progress',
                invalidSubcategoryForPlace: 'This %{name} does not belong to subcategory selected',
            },
            topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard: {
                title: 'Top 10 %{item} with incidents',
                titleShort: 'Top 10 of subcategories with incidents',
                selectSubcategory: 'Select a subcategory',
                fields: {
                    ranking: 'ranking',
                    category: '%{item_field}',
                    numberOfIncidents: 'Number of incidents',
                    incidentPercent: 'Incident Percent',
                },
                helpText: 'Place selected %{name}',
                infoText: 'Dashboard number: %{question}',
                category: 'Category',
                subcategory: 'Subcategory',
                place: 'Place',
                eventRunning: 'Incident in progress',
                selectCategory: 'Select a category',
                invalidCategoryForPlace: 'This %{name} does not belong to category selected',
            },
            topIncidentsDashboard: {
                title: 'Top 10 most reported incidents',
                fields: {
                    ranking: 'ranking',
                    incidentType: 'Incident Type',
                    numberOfIncidents: 'Number of incidents',
                    incidentPercent: 'Incident Percent',
                },
                infoText: 'Dashboard number: %{question}',
                helpText: 'Place selected %{name}',
                selectIncident: 'Select an incident',
                incident: 'Incident',
                place: 'Place',
                in: 'In: %{name}',
                nonePlace: '',
                eventRunning: 'Incident in progress',
            },
            incidentTimeFromTheBeginningDashboard: {
                title: 'Ongoing unresolved incident',
                fields: {
                    time: 'Time',
                    date: 'Date',
                    user: 'User',
                    navigation: 'Navigation',
                    avatarName: 'Avatar name',
                },
                infoText: 'Dashboard number: %{question}',
                helpText: 'Select a place: %{name}',
                selectIncident: 'Select an incident',
                incident: 'Incident',
                place: 'Place',
                in: 'In: %{name}',
                nonePlace: '',
                eventRunning: 'Incident in progress',
            },
            totalNumberOfIncidentsByCompanyDashboard: {
                title: 'Total number of incidents',
                infoText: 'Dashboard number: %{question}',
                helpText: 'Place selected %{name}',
                place: 'Place',
                in: 'In: %{name}',
                nonePlace: '',
                eventRunning: 'Incident in progress',
            },
            actions: {
                enableDashboards: 'Customize the display',
            },
        },
        delete: 'Delete',
        download: 'Download',
        edit: 'Edit',
        errors: {
            server: 'An server error has occurred. Please contact the administrator',
        },
        filters: {
            startDate: 'Start',
            endDate: 'End',
            takeIntoAccount: 'Take into account',
        },
        headers: {
            incidents: 'My incidents',
            navigation: 'Navigation',
            configuration: 'Configuration',
            operations: 'Operations',
            categoriesLevel: 'My categories',
            categoriesEvent: 'My event categories',
            subCategories: 'My subcategories',
            incidentTypes: 'Incident type',
            serviceProviders: 'ServiceProviders',
            dashboard: 'Dashboard',
            statistics: 'Statistics',
            users: 'Users',
        },
        hours: '%{time} hour(s)',
        incidentTypes: {
            name: 'Name',
            incidents: 'Incidents',
            repeatable: 'Repeatable incident',
            forceImage: 'Image required',
            forceText: 'Comment required',
            repeatableInfo:
                'That means, if a user has declared an incident of this type, another user must be able to declare a new one of this type.\n' +
                'For example, if paper towel is missing, a single declaration is sufficient. On the other hand, for a graffiti, there may be one on day 1 and unfortunately a second a few hours or days later.',
            create: {
                title: 'Create an incident type',
                input: {
                    name: 'Incident type name',
                    image: 'Icon',
                    repeatable: 'Repeatable incident? (activate for unassigned "other" incidents)',
                    titleOverwrite: 'Activate to make the title empty',
                    titleOverwriteInfo:
                        'When an incident is repeatable, the declarant is invited to modify the title which is by default pre-filled with the name of the type of incident. To force the registrant to think about the title, you can activate this option and the title will be empty so the registrant will be forced to fill it in',
                    imageButtons: {
                        load: 'Load an image',
                        applyCrop: 'Apply crop',
                        cancelCrop: 'Cancel crop',
                        zoomCrop: 'Zoom',
                        cropSize: 'Crop size',
                    },
                    selectEvent: 'Event',
                    forceImage: 'Image required',
                    forceText: 'Comment required',
                    iconSource: 'Enable (icon) - Disable (image icon)',
                    noLoggerUserCanSeeHistory: 'Non-logged-in users can view incident history',
                    noLoggerUserCanCreateEvents: 'Non-logged-in users can created incidents',
                    askIncidentCreatorEmail:
                        'Request the user for his email if he wants to be notified of his resolution',
                    askIncidentCodePin: 'Request the pin code of the incident before the intervention',
                },
                notification: 'Incident type created',
                error: 'There was an error when creating the incident type',
            },
            edit: {
                label: 'Edit',
                severalItem: 'Edit incidents type',
                severalItemsEdited: 'Incident types were successfully updated',
                title: 'Edit incident type',
                input: {
                    name: 'Incident type name',
                    image: 'Icon',
                    repeatable: 'Repeatable incident? (activate for unassigned "other" incidents)',
                    titleOverwrite: 'Enable to add a new title to the incident',
                    selectEvent: 'Event',
                    forceImage: 'Image required',
                    forceText: 'Comment required',
                    noLoggerUserCanSeeHistory: 'Non-logged-in users can view incident history',
                    noLoggerUserCanCreateEvents: 'Non-logged-in users can created incidents',
                    askIncidentCreatorEmail:
                        'Request the user for his email if he wants to be notified of the resolution of the incident',
                    askIncidentCodePin: 'Request the pin code of the incident before the intervention',
                },
                error: 'There was an error when editing the incident type',
                success: 'Incident type edited successfully',
            },
            delete: {
                title: 'Remove incident type',
                subtitle: 'Are you sure you want to delete this type of incident?',
            },
            archive: {
                title: 'Archive incident type',
                subtitle: 'Are you sure you want to archive %{name}?',
                error: 'There was an error when archiving the incident type',
                success: 'Incident type archived successfully',
            },
            add: {
                title: 'Write a name of an incident to add',
            },
            deleted: 'The incident type was well deleted',
            archived: 'The incident type was well archived',
            breadcrumbs: {
                1: 'Configuration',
                0: 'Incident type',
            },
            instructions: {
                labelList: 'Instruction',
                dialogTitle: 'Instructions',
                createLabel: 'Select or create a instruction',
                createDialogTitle: 'Create instruction for',
                notifications: {
                    createSuccess: 'The instruction was well created',
                    createError: 'There was an error creating the instruction',
                    editSuccess: 'The instruction was well updated',
                    editError: 'There was an error updating the instruction',
                    deleteSuccess: 'The instruction was well deleted',
                    deleteError: 'There was an error deleting the instruction',
                },
                editDialogTitle: 'Edit instruction',
                addImageDialogTitle: 'Add image',
                addImageInputLabel: 'Image',
                clickImage: 'Click to insert an image',
                tooltipAddImage: 'Click here to add images',
                imageLibrary: 'Image library',
                helpText: 'Deleted images will not be visible in the locker',
            },
            error: 'There was an error retrieving the incident type',
        },
        incidents: {
            name: 'Incident',
            equipment: 'Equipment',
            equipmentsTypes: 'Equipment types',
            incidentsTypes: 'Incident types',
            imageAndComment: 'Image and comment',
            imageAndCommentTitle: 'Image and comment: ',
            delegatedToTitle: 'Incident delegated to:',
            comment: 'Comment of incident: %{eventName}',
            places: 'Place',
            shortAddress: 'Short address',
            building: 'Building',
            serviceProvider: 'Provider',
            serviceProviderTitle: 'Provider :',
            rapportDate: 'Report Date',
            rapportDateTitle: 'Report Date:',
            resolveDate: 'Resolved Date',
            resolveDateTitle: 'Resolved Date:',
            workResolutionDelay: 'Contractual resolution period',
            workResolutionDelayTitle: 'Contractual resolution period:',
            attributionDate: 'Attribution Date',
            state: 'Status',
            stateTitle: 'Status:',
            city: 'City',
            fullAddress: 'Full address',
            delay: 'delay:',
            considerationDeadlines: 'Contractual consideration deadlines',
            resolutionDeadlines: 'Contractual resolution deadlines',
            postalCode: 'Code postal',
            showIncidentResolved: 'Hide resolved incidents',
            showIncidentClosed: 'Hide closed incidents',
            navigationPath: 'Location',
            navigationPathTitle: 'Location :',
            incidentTimeDefined: 'Time since report',
            incidentTimeDefinedTitle: 'Time since report:',
            countdown: 'Remaining time to provider',
            countdownTitle: 'Remaining time to provider:',
            addServiceProvider: {
                title: 'Assign to provider',
                toAssign: 'To assign?',
                name: 'User name',
                added: {
                    ok: 'The incident was correctly assign to provider',
                    ko: 'There was a problem with the attribution of the incident',
                },
                notServiceProvider: 'No service provider',
                forAssigningAServiceProviderInfo:
                    'To assign a service provider, the incident must be in status Awaiting assignment by the manager or restart the incident by assigning a service provider',
            },
            deleted: 'The incident type was well deleted',
            delete: {
                title: 'Remove incident',
                subtitle: 'Are you sure you want to delete this incident?',
            },
            restart: {
                title: 'Restart %{name} ',
                label: 'Restart',
                sendEmail: 'Send notification via email',
                reassignCurrentUserProvider: 'Reassign to current service provider',
                newUserProvider: 'Assign to other service provider',
                fieldRequired: 'Reassign to current or other service provider',
                noCurrentServiceProvider: 'This incident does not have a service provider assigned',
            },
            close: {
                title: 'Close event: %{name} ',
                label: '',
                comment: 'Comment',
                commentSubtitle:
                    'This comment will appear in the email sent to everybody who has declared their interest in being notified by the resolution of this incident.',
            },
            information: {
                title: 'See incident',
                seeIncident: 'See incident',
                state: 'Status',
                closeIncident: 'Close incident',
                close: 'Close',
                incidentClosed: 'Incident closed',
                incidentRestarted: 'Incident restarted',
                incidentRestarting: 'Incident is restarting',
                notClosedCorrectly: 'Incident %{name} was not successfully closed',
                notRestartedCorrectly: 'Incident %{name} was not successfully restarted',
                closedCorrectly: 'Incidents was successfully closed',
                pinCode: 'Entry incident code',
                reDelegateUser: 'Delegate to another submanager',
                delegateUser: 'Delegate to a submanager',
                invoices: 'Invoice',
                quotes: 'Quote',
                invoiceAndQuotes: 'Invoices / Quotes',
            },
            notification: {
                incidentNotClosed: 'There is an error when closing the incident',
                pathError: "There is an error when getting the %{name} incident's location ",
                incidentNotRestart: 'Incident not restarted',
                getServiceProviderError: 'There was an error retrieving the provider',
                getReminderEventError: 'There was an error retrieving the reminder note',
                createReminderEventError: 'There was an error creating the reminder note',
                reminderEventCreated: 'Reminder note created successfully',
                reminderEventEdited: 'Reminder note edited successfully',
                getDelegatedUserError: 'There was an error retrieving the user delegated to %{incidentName} incident',
            },
            filters: {
                serviceProvider: {
                    title: 'Providers',
                    assigned: 'Incident Assignment',
                    withContract: 'With contract',
                    withoutContract: 'Without contract',
                    withoutAndWithContract: 'With and without contract',
                    with: 'With',
                    without: 'Without',
                    notAttributed: 'Not attributed',
                    none: 'All incidents',
                },
                categoriesAndSubcategories: 'Category and subcategory',
                categoriesAndIncidentType: 'Category',
                subcategoriesAndIncidentType: 'Subcategory and incident type',
                subcategoriesAndIncidentTypeSwitch: 'Activate filtering by sub-category or type of incident',
                category: {
                    title: 'Category',
                    categories: 'Categories',
                },
                subcategories: {
                    title: 'Subcategories',
                },
                incidentType: {
                    title: 'Incident type',
                },
                place: {
                    title: 'Location advanced filter',
                    selected: 'Place selected: %{name}',
                    noFilter: 'Cancel filter',
                    label: 'Place',
                },
                status: {
                    title: 'Choose incident status',
                    label: 'Filter by status',
                    noFilter: 'Cancel filter',
                },
                managerSubManager: {
                    title: 'Manager / Submanager',
                },
            },
            eventAssignedDate: 'Assigned Date',
            eventAssignedDateTitle: 'Assigned Date:',
            breadcrumbs: {
                1: 'Operations',
                0: 'Incidents',
            },
            requestWorkResolutionDelay: {
                get: {
                    error: 'There was an error while getting the work resolution delay',
                },
            },
            pinCode: 'Entry incident code',
            pinCodeTitle: 'Entry incident code:',
            status: {
                assignServiceProvider: 'Pending Manager Assignment',
                waitTakeIntoAccount: 'Awarded, pending acceptance by the service provider',
                refusedTakeIntoAccount: 'Declined',
                takenIntoAccount: 'Taken into account',
                pauseCounterTime: 'Suspended',
                repairing: 'Ongoing',
                repaired: 'Ended',
                closed: 'Closed',
                opened: 'Opened',
                startOfIntervention: 'Start of intervention',
            },
            alertConfig: {
                message: 'Reminder note',
                triggerDate: 'Trigger date',
                edit: 'Edit alert',
                create: 'Create alert',
                delete: 'Delete alert',
                removed: 'Alert removed',
                removedError: 'There was an error when removing alert',
                eventNameInfo: 'A reminder has been scheduled about incident: ',
                eventNameValue: '%{eventName}',
                triggerDateInfo: 'for: ',
                triggerDateValue: '%{triggerDate}',
                messageInfo: 'with reminder note: ',
                messageValue: '%{message}',
            },
            delegateDialog: {
                title: 'Delegate to a submanager or a manager',
                user: 'Submanager / Manager',
                update: {
                    success: 'The manager was well delegated to this incident',
                    fail: 'There was an error delegating this incident to the manager',
                },
                create: {
                    success: 'The manager was well delegated to this incident',
                    fail: 'There was an error delegating this incident to the manager',
                },
                delete: {
                    success: 'The incident is no longer delegated to this manager or sub-manager',
                    fail: 'There was an error: Code %{errorCode}',
                },
            },
            reDelegateUser: 'Delegate to another submanager',
            delegateUser: 'Delegate to a submanager',
            delegatedTo: 'Delegated to:',
            edit: {
                label: 'Edit name',
                title: 'Edit incident name',
                name: 'Name',
                success: 'Incident name update successfully',
                fail: 'There was an error when updating',
            },
            seeMore: 'See more',
            view: {
                kanban: 'Kanban (beta)',
                list: 'List',
            },
            empty: 'No incidents yet',
            confirmationAmount: 'How many times the incident has been confirmed',
            showDocs: {
                label: 'Documents',
            },
            files: {
                type: 'Invoices / Quotes',
                date: 'Date',
                owner: 'Username',
            },
            resolutionOpinion: {
                label: 'Opinion on the indent closed',
                seeMore: 'See more',
                title: 'Opinion on the indent management process',
                evaluation: 'Incident management note',
                questions: {
                    label: 'According to the questions asked about: ',
                    qualityIntervention: 'Quality of the intervention?',
                    speedIntervention: 'Speed of intervention?',
                    reportingExperienceIntervention: 'Incident reporting experience?',
                },
                averageEvaluation: 'Average rating : %{average}',
                comment: 'Comment',
            },
        },
        login: {
            usernameOrEmail: 'User name or email',
            password: 'Password',
            label: 'Login',
            passwordForgot: 'Forgot your password ?',
            invalidCredentials: 'Authentication failed, please try again.',
        },
        logout: 'Logout',
        mc: {
            parameter: 'Parameter "MC"',
            information: 'Information',
            name: 'Name',
            changePict: 'Change picture',
            application: 'Redirection service',
            downloadQrcode: 'Download QR Code',
            showQrcode: 'See QR Code',
            exportQrcode: 'Export QR Code',
            editDialogTitle: 'Modify MC',
            general: 'General',
            edit: 'Edit',
            visitApp: 'View the application',
            identification: 'Module of CommunicationIdentification',
            copy: 'Copy URL',
            creationDate: {
                value: 'Creation Date',
                afterFilter: 'After',
                beforeFilter: 'Before',
            },
            yourls: {
                shortUrl: 'short URL',
            },
            enabled: {
                yes: 'Oui',
                no: 'Non',
            },
            mcName: 'Name communicating modules',
            remove: 'Remove',
            add: 'Add',
        },
        media: {
            errorGettingImage: 'There was an error retrieving the image',
            errorGettingFile: 'There was an error retrieving the file',
            addImage: 'Add image',
            imageMaxSizeUpload: 'The file size is too large. Try with a file smaller than 5MB.',
            errorCreatingImage: 'There was an error saving the image',
            errorDeletingImage: 'There was an error deleting the image',
            deleted: 'The file was well deleted',
            notDownloaded: 'There was an error downloading the file. Contact to admin with this code: DOWNLOAD FILE',
        },
        menuActions: {
            title: 'Actions',
            create: 'Create',
            edit: 'Edit',
            delete: 'Delete',
            refresh: 'Refresh',
            simulateApp: 'Simulate App',
            customize: 'Customize',
            addItem: 'Add item',
            editItem: 'Edit item',
            moveTo: 'Move to',
            reorder: 'Reorder',
            cancelMoveTo: 'Cancel move to',
            seePinCode: 'See entry code',
            remove: 'Remove',
            config: 'Config',
            archive: 'Archive',
            changeView: 'View mode',
            clone: 'Clone',
            instructions: 'Instructions',
        },
        messages: {
            selectItemToMove: 'Select a item to move',
            selectItemToClone: 'Select a item to clone',
            selectWhereToMoveTheItem: 'Select where to move the item',
            success: 'Success',
            creationError: 'Creation error',
            imageNotAdded: 'There was an error when adding image',
            imageNotRemoved: 'There was an error when removing image',
            getLanguagesError: 'There is an error when translating %{value} in %{twoLetters}.',
            createLanguagesError: 'There is an error when creating %{value} translation in %{twoLetters}.',
            createForceMetadataAvatarTypePropertyError: 'There is an error configuring the %{propertyName}.',
            updateForceMetadataAvatarTypePropertyError:
                'There was an error updating the configuration of %{propertyName}.',
            getForceMetadataAvatarTypePropertyError:
                'There was an error getting the configuration from the %{propertyName}.',
            getIncidentTypePropertyError:
                'There was an error getting the configuration from the incident type. Code Properties',
            getPropertyError: 'There was an error getting the configuration. Code Properties',
            updateFileSystemMapperEnabledMetadataAvatarTypePropertyError:
                'There was an error getting the configuration from the document management.',
            getSubcategoriesPropertyError:
                'There was an error getting the configuration from the subcategory. Code Properties',
            editMultipleIncidentTypes: 'There were errors while updating incident types. Verify the changed data.',
        },
        minutes: '%{time} minute(s)',
        navigation: {
            tree: {
                root: 'Root',
                loading: 'Loading',
                shortRoot: 'R',
                empty: 'No items',
            },
            list: {
                columns: {
                    name: 'Name',
                    category: 'Category',
                    userApplied: 'User / Service provider by default',
                    pairedUpFromField: 'Marker placed',
                    subCategory: 'Subcategory',
                    subCategoryOrIncidentType: 'Subcategory or incident type',
                    incidentInProgress: 'Incidents in progress',
                    ctn: 'Tag number',
                },
            },
            configuration: {
                title: 'Settings',
                defineRoot: 'Set as root',
                notDefinedRoot: 'Exists already a root at %{place}',
                rootExisted: 'Exists already a root',
                location: {
                    label: 'Location',
                    address: 'Address',
                    postalAddress: 'Postal Address',
                    city: 'City',
                    country: 'Country',
                    postalCode: 'Postal Code',
                },
                serviceProvider: {
                    label: 'Default providers',
                    usageDescription:
                        'This page allows you to define the service providers who will be alerted following the declaration of the incident. Your choice will apply to the unconfigured lower elements.',
                    incidentType: {
                        label: 'Incident Type',
                    },
                    subCategory: {
                        removed: {
                            notification: 'Service provider %{username} is not longer applied to %{incident} incident',
                            error: 'There was an error while removing the service provider from the incident',
                        },
                        updated: {
                            notification: 'Service provider is successfully applied to incident',
                            error: 'There was an error while updating the service provider from the incident',
                        },
                        created: {
                            notification: 'Service provider is successfully applied to incident',
                            error: 'There was an error while updating the service provider from the incident',
                        },
                        added: {
                            notification: 'Service provider is successfully applied to incident',
                            error: 'There was an error while updating the service provider from the incident',
                        },
                    },
                    createDescription: 'Please assign a default incident',
                    title: 'Assign or change a default user in an incident',
                    workResolutionDelay: {
                        label: 'Work resolution delay (in hours)',
                        updated: {
                            notification: '...',
                            error: '... error',
                        },
                        created: {
                            notification: 'Work resolution time has been changed',
                            error: 'There was an error modifying the job resolution deadline',
                        },
                        get: {
                            error: 'There was an error while getting the work resolution delay',
                        },
                        hours: 'hours',
                    },
                    alreadyAssigned: 'Only one user can be assigned by default in an incident.',
                    assigned: 'User assigned to %{place_name}',
                },
                processResolution: 'Incident resolution process',
                incidentByDefault: {
                    label: 'Default Incidents',
                    create: {
                        label: 'Add incidents by default to:',
                    },
                },
                itemName: 'Item name',
                enableSending:
                    'This button enables or disables the sending of information (telephone number, name and/or entry code) by email to the service provider to help resolve the incident. Remember to save each line.',
                itemInformation: {
                    label: 'Item information',
                    contact: 'Contact',
                    contactName: 'Name',
                    contactPhone: 'Phone number',
                    extraInformationLabel: 'Extra information',
                    extraInformation:
                        'In order to facilitate the intervention of the service providers, the fields below allow you to fill in the places and contacts linked to the chosen element. For example, if you add additional information on the contact on site, you could indicate in the Title: On-site contact name, then in Information text: their name; remember to add an additional line for their phone. Remember to save each line.',
                    pinCode: 'Access instructions (digital code, location of the electrical box, water supply, etc.)',
                    add: 'Add an extra information',
                    customInformationFieldID: 'Information title',
                    customInformationFieldValue: 'Information text',
                    privateArea: 'Private part',
                    publicArea: 'Commun area',
                    privacyArea: 'Site accessibility',
                    privateLabel: 'Element Visibility',
                    privateInformation:
                        'Check private for a private accommodation which must not appear from the common areas',
                    fileSystemMapper: {
                        label: 'Document management',
                        enabled: 'Enabled',
                        disabled: 'Disabled',
                    },
                    inMaintenance: 'In maintenance',
                    outOfMaintenance: 'Out of maintenance',
                    maintenanceStatus: 'Service via QRCode',
                },
                incident: {
                    description: 'Choose default assigned incidents applied to this item and lower items.',
                    category: 'Category',
                    assigned: 'Incident assigned by default',
                    subCategory: {
                        title: 'Subcategory',
                        added: {
                            notification: 'Subcategory added',
                            error: 'There was an error while creating the subcategory',
                            TicketingDepsOneAllowed: 'This category already has a place configured',
                        },
                    },
                    eventType: {
                        title: 'Incident to assign',
                        assigned: 'Incident assigned to : %{place_name}',
                        updated: {
                            success: 'Incident edited successfully',
                            notification: 'Incident(s) assigned',
                            error: 'There was an error adding the incident',
                            TicketingDepsOneAllowed: 'This category already has a place configured',
                        },
                        removed: {
                            notification: 'Incident removed',
                            error: 'There was an error when removing the incident',
                        },
                    },
                    action: {
                        remove: {
                            label: 'Delete',
                            title: 'Delete the subcategory?',
                            subtitle: 'Are you sure you want to delete the %{name} subcategory ?',
                            notification: 'The subcategory has been deleted',
                            error: 'There was an error deleting the subcategory',
                        },
                    },
                },
                managerNotification: {
                    label: 'Manager - Notifications',
                    description: {
                        line0: 'If you wish not to receive notifications for certain subcategories, complete the table below.',
                        line1: 'You have 3 choices each time you can choose: ',
                        line2: 'never be notified for this subcategory',
                        line3: 'not to be notified if the incident is configured with a default service provider without a contract',
                        line4: 'or not to be notified if the default provider is under contract',
                    },
                    empty: 'You have not yet configured default incidents on this root',
                    subcategory: 'For the subcategory',
                    assignedUnderContract: 'Default provider under contract',
                    assignedWithoutContract: 'Default provider without contract',
                    never: 'Never',
                    hasNotUserProvider: 'No provider?',
                    user: 'Manager',
                    action: {
                        create: {
                            error: 'There was an error creating the notification',
                            notification: 'The notification was correctly created',
                            title: 'Create a notification',
                        },
                        edit: {
                            label: 'Edit',
                            title: 'Edit the notification',
                            notification: 'The notification was correctly edited',
                            error: 'There was an error editing the notification',
                        },
                        remove: {
                            label: 'Delete',
                            title: 'Delete the notification ?',
                            subtitle: 'Are you sure you want to delete the notification for this manager %{userName} ?',
                            notification: 'The notification was removed',
                        },
                        list: {
                            error: 'There was an error getting notification to the root and incident %{incidentName}',
                        },
                    },
                    inheritedFromParents: 'Inherited from parents',
                },
                managerPlace: {
                    description: {
                        line0: 'Choose the manager of this root, it will be notified at each incident and at each evolution in the resolution process.',
                    },
                    action: {
                        create: {
                            error: 'There was an error setting manager to the place.',
                            notification: 'The manager was correcty setted to the root',
                            title: 'Create a notification',
                        },
                        edit: {
                            label: 'Edit',
                            title: 'Edit the notification',
                            error: 'There was an error editing manager to the root',
                            notification: 'The manager was correctly edited',
                        },
                        delete: {
                            error: 'There was an error removing manager to the place',
                            notification: 'The manager was correctly removed',
                        },
                        list: {
                            error: 'There was an error getting manager to the root',
                        },
                    },
                },
                fileManager: {
                    label: 'Documents',
                },
                subcategory: {
                    selectLabel: 'Select a subcategory',
                },
            },
            removeItem: {
                removed: 'Item removed',
                error: 'There was an error when removing the item',
                helpText: 'Are you sure you want to delete this item?',
                title: 'Remove',
            },
            breadcrumbs: {
                1: 'Operations',
                0: 'Navigation',
            },
            moveDialog: {
                title: 'Move item',
                item: 'Item',
                moveTo: 'Move to',
                twoRootsError: 'In a tree cannot exist two nested roots',
            },
            cloneDialog: {
                title: 'Clone item',
                item: 'Item',
                moveTo: 'Clone to',
            },
            reorderDialog: {
                title: 'To place: %{name}',
                position: {
                    down: 'Below the item: %{name}',
                    up: 'Above the item: %{name}',
                },
            },
            process: {
                name: 'Process name',
                enableServiceProvider:
                    'Enable default providers for incident resolution. When enabled, default service providers are automatically notified when an incident is opened.',
                askConfirmationToServiceProvider:
                    'Ask service providers to confirm that the incident has been taken into account when they receive the open incident alert.',
                takeIntoAccountAutomatically:
                    'Confirmation of automatic taking into account when the service provider is under contract.',
                created: {
                    notification: 'The configuration was created successfully',
                    error: 'There was an error while configuring',
                },
                updated: {
                    notification: 'The configuration has been changed successfully',
                    error: 'There was an error when modifying the configuration',
                },
                service: {
                    name: 'Service name',
                },
                get: {
                    error: 'There was an error when getting the configuration',
                },
            },
            addItem: {
                title: 'add item',
                metadataType: 'Sub categories',
                name: 'Item name',
                selectSubCategory: 'Choose a subcategory',
                asChild: 'As child',
            },
            editItem: {
                title: 'Edit item',
                metadataType: 'Sub categories',
                name: 'Item name',
                selectSubCategory: 'Choose a subcategory',
            },
        },
        next: 'Next',
        no: 'no',
        noElement: 'No element',
        noICON_SVG_COLLECTION_FIELDneItem: 'None item',
        notification: {
            updated: 'Item updated',
            created: 'Item created',
            archived: 'Archived item',
            exported: 'Exported elements',
            failed_created: 'There was a problem creating the element',
            failed_updated: 'There was a problem updating the element',
            failed_simulated: 'There was a problem in the simulation',
            copied: 'Copied successfully',
        },
        objects: {
            addImages: 'Click here to add images',
            tabs: {
                mc: 'STOCK OF UNPAIRED MODULES COMMUNICANTS',
            },
            info: {
                id: 'Id',
                name: 'Name of object',
                type: 'Type of object',
                description: 'Description',
                reference: 'Reference',
                created: 'Date of creation',
                enterprise: 'Enterprise',
                localization: 'Localization',
                latitude: 'Latitude',
                longitude: 'Longitude',
                inputMap: 'Click in the text to display the map',
                altitude: 'Altitude',
                address: 'Address',
                street: 'Street',
                additionalAddress: 'Additional address',
                postalCode: 'Postal code',
                city: 'City',
                country: 'Country',
                images: 'Images',
                image: 'Image',
                showImages: 'Show all images',
                attachImages: 'Attach images',
                attachOneImage: 'Attach an image',
                attachImage: 'Upload an image',
                attachDocuments: 'Attach documents',
                statistics: 'Statistics',
                statistics_redirection: { title: 'Detailed object statistics' },
                extradata: 'Extra Data',
                amountNotes: 'Number of notes',
                lastNote: 'Last Note',
                stateObject: 'Object status',
                enabled: 'Enabled',
                enabledDate: 'Activation date',
                extradata_infos: {
                    dimension: 'Dimension',
                    profession_data: 'Données Métier',
                    empty_:
                        'Open the Finger (WebApp) to check the information or contact the admin to personalise' +
                        ' this information',
                },
                tracking: {
                    title: 'Tracking',
                    goNext: 'Go next',
                    goPrevious: 'Go previous',
                    selectNode: 'Select a node',
                    fullName: 'Full name',
                    currentNode: 'Current node',
                    name_state: 'Status',
                    date_state: 'Status date',
                },
                nature: 'MC type',
                filter: {
                    name: 'Filter',
                    note: 'Notes',
                    traceability: 'Traceability',
                    avatarGroup: 'Avatar Group',
                    history_created_at: {
                        value: 'History date',
                        afterFilter: 'History date - After',
                        beforeFilter: 'History date - Before',
                    },
                    metadataAvatarType: 'Objet Type',
                    company: 'Company',
                    apply: 'Apply as filter',
                },
                redirectTo: {
                    show: {
                        company: 'Display in the company page',
                        metadataAvatarType: 'Display in the object type page',
                    },
                },
                groupAvatar: {
                    name: 'Groups',
                },
                createdAt: 'Created At',
                mcs: 'Communication modules',
                position: 'Object Position',
            },
            nature: {
                nfc: 'NFC',
                st25: 'ST25',
                qrcode: 'QrCode',
            },
            export: {
                historic: {
                    csv: 'History (CSV)',
                    xlsx: 'History (XLSX)',
                },
                objects: {
                    csv: 'Inventory (CSV)',
                    xlsx: 'Inventory (XLSX)',
                },
            },
            deploy: 'Deploy',
            generate: {
                simulate: 'Simulate',
                create_from_file: 'Create Mcs from file',
            },
            description: 'Description',
            information: 'Information',
            historic: 'History',
            objects: 'Objects',
            alphaId: 'Reference',
            object: 'Object',
            typesObjects: 'Object Types',
            objectType: 'Object Type',
            groupObjects: 'Objects Group',
            type: 'Type of Object',
            name: 'Name',
            nameObject: 'Name object',
            photoObject: 'Photo object',
            moduleCommunications: 'Module Communications',
            location: 'Location',
            date: 'Creation Date',
            interfaces: 'Interface',
            finger: 'Finger',
            mc: 'Communication Modules',
            notDefined: 'Not defined',
            documents: 'Documents',
            applications: 'Redirections',
            webServiceDisplay: 'Web service to display',
            quantityMCs: 'Quantity MCs',
            url: 'Url',
            addObject: 'Add an object',
            createModal: {
                p1text1: 'For register or add an object on the platform, you must use the',
                p1text2: 'mobile pairing applicatione',
                p1text3: ' on the',
                p1text4: 'real object',
                p1text5: 'integrating one or more communicating module (s) NFC or Qrcode type.',
                p2text1: 'For more information contact the',
                p2text2: 'suppport',
            },
            deleteModal: 'Are you sure you want to archive the selected item (s)?',
            deleteModalAlways: 'Are you sure you want to delete the selected item (s)?',
            deleteModalNotCancel: 'Are you sure you want to delete the selected item (s) without canceling the form?',
            filters: {
                factory: 'Factory',
                business: 'Business',
                site: 'Construction site',
                shortUrl: 'ShortUrl',
                metadataAvatarType: 'Object Type',
                typeObject: 'Object type name',
                objectName: 'Object name',
                history_name: 'Status Name',
                objectGroup: 'Group Object',
                history_created_at: {
                    value: 'History date',
                    afterFilter: 'History date - After',
                    beforeFilter: 'History date - Before',
                },
            },
            typeObject: {
                applicationDisponibles: 'Available Redirections',
            },
            createObjectMultiple: {
                helpText: {
                    step1Part1:
                        'You are about to create an object manually. There is a more optimized way to create an' +
                        ' object, for that see this page',
                    step1CreateObject: 'How to create an object',
                    step1Part2: 'Choose your object type for pre-configure your new object',
                    step2ValidatePreFillData: 'Validate this pre-filled data',
                    step3Line1: 'To import data from a G-Sheet file, please ensure that the data format is compatible.',
                    step3Line2: 'Please insert the share link of the G-Sheet file.',
                    step3Line3:
                        'You must choose the line number to import, from the first line to the last line separated' +
                        ' by a hyphen, example "2-6"',
                    step3Line4: 'The following lines will be created as an object:',
                    withoutFile: 'Without File',
                    amount: 'Amount of objects to create',
                    withFile: 'Creation with a G-sheet table :',
                },
                errors: {
                    readLine: 'Line numbers to import have no data',
                    nogapi: 'Unable to access google api. Please contact the service administrator.',
                    emptyLine: 'Something went wrong. Some line without value.',
                    spreadsheet: 'Something went wrong when importing the data from the Spreadsheet',
                    notFoundPropertyInTable: '%{property} property is not on %{table} table',
                    notFoundTableInSpreadsheet: '%{table} was not found on spreadsheet',
                    notFoundDataInTableInSpreadsheet: '%{table} dont have data',
                },
                title: 'Multiple creation',
            },
            createObject: {
                finger: {
                    add: 'Add Redirection',
                },
                groupObjects: { add: 'Objects Group' },
                error: {
                    simulateMc: 'The MC simulation was not done',
                    createMc: 'MC could not be created',
                    addToGroup: 'Could not add object to object group',
                },
                notification: {
                    addToGroup: 'The object was added to the group',
                },
                title: 'Create object',
                helpText: {
                    createObject:
                        'You are about to create an object manually. There is a more optimized way to create an object,' +
                        ' for that see this page.',
                    createObjectPage: 'How to create an object',
                    chooseTypeObject: 'Choose your object type for pre configure your new object:',
                },
                advancedForm: 'Advanced Form',
                simplifiedForm: 'Simplified Form',
                // create: "Create",
                validatePrevData: 'Validate the pre-filled data',
            },
            editObject: {
                title: 'Edit object',
                add: {
                    mc: {
                        title: 'Add communication Module to object',
                        error: "Communication Module wasn't added",
                        added: 'Communication Module was added',
                    },
                },
            },
            notSelected: 'There is not an object is selected',
            bulk_action: {
                titleAddToGroup: 'Choose a object group to be changed in avatar selected',
                titleChangeFinger: 'Choose an redirection to be changed in avatar selected',
                titleChangeFingerForMC: 'Choose an redirection for updating a communication module',
                titleAddTraceability: 'Choose an traceability to be changed in avatar selected',
                titleRemoveGroup:
                    'Choose a traceability process to change. Please note, the change only works if the object uses a' +
                    ' web application that uses traceability (ex: MemoTagPro)',
                addToObjectGroup: 'Group',
                removeToObjectGroup: 'Ungroup',
                changeFinger: 'Redirection',
                error: {
                    title: 'Some objects were not updated',
                    titleUpdateMCs: 'Some MCs were not updated',
                    headerTable: 'Error',
                    notAdded: 'Not was added to the group',
                    notChangeApp: 'The redirection service was not changed',
                    notChangeTraceability: 'The traceability was not changed',
                    notRemoveFromGroup: 'No was removed to the group',
                    diferentCompany:
                        'Please verify that all objects belong to the same object group account %{object_group_name}',
                },
                addTraceability: 'Traceability',
            },
            list: {
                actions: {
                    customize_list: 'Customize',
                },
            },
            actions: {
                deleteAction: {
                    name: 'Delete',
                    keepMcsName: 'Delete and keep the Communication module',
                },
                changeWebApp: 'Change Web App',
                simulateWebApp: 'Simulate Web App',
                changeTraceability: 'Change Traceability',
                additionalInformation: 'Additional Information',
            },
            objectMenu: {
                title: 'Select a Object',
            },
            service: 'Service redirection name',
            additionalInformation: {
                title: 'Additional Information',
                name: 'Information title',
                googleSheetURL: 'G-Sheet URL',
                lineNumber: 'Choice number of line',
                value: 'Information Value(data)',
                creator: 'Creator',
                private: 'Display Rule',
                fieldType: 'Information type',
                definition: 'Name definition',
                unit: 'Unit',
                lineToImport: 'Choice number of line (example "1-4")',
                privateOptions: {
                    public:
                        'Public: the information will be visible without needing to identify with an account from' +
                        ' the Web Applications using the additional information of the object.',
                    private:
                        'Private: the information data will be encrypted and can be visible when a user with the rights' +
                        ' is identified.',
                },
                fieldTypeOptions: { string: 'Text', number: 'Integer', boolean: 'Boolean', date: 'Date' },
                addProperty: {
                    helpText:
                        'Define the additional information. The choice of display makes it possible to define whether' +
                        ' the information is accessible without a password from the compatible Mobile Web Applications' +
                        ' (contact us for more information). The definition allows information to be grouped together',
                    finish: 'Finish',
                },
                helpText: {
                    additionalInformation: 'Configure the additional information you want to record about your object',
                    name: 'Define the title additional information',
                    private:
                        'The choice of display defines whether the information is accessible without a password from' +
                        ' the compatible Mobile Web Applications (contact us for more information).',
                    fieldType:
                        'The type of information is a parameter that can be used in third party functionalities or a' +
                        ' compatible Mobile Application (contact us to find out more).',
                    definition: 'The definition allows information to be grouped together.',
                    googleSheet:
                        'To import data from a G-Sheet file, please ensure that the data format is compatible.',
                    googleSheetURL: 'Please insert the G-Sheet file share link.',
                    googleSheetLine: 'You must write the line number to import.',
                    createSpreadsheetsTemplate: 'Configure the properties you want to generate in your G-Sheet file',
                    spreadsheetCreate: 'The G-Sheet template was create',
                },
                error: {
                    spreadsheet: 'Something went wrong when importing the data from the Spreadsheet',
                    spreadsheetURL: 'The URL should be a Spreadsheet',
                    noData: 'No data found.',
                    googleSheetLine: 'Line number must be greater than one',
                    properties: 'You should add at least one property',
                    addSheets: 'Check that the G-Sheet has no data\n',
                    addColumnsData: 'Something went wrong when add columns in sheet SC-DATA',
                    addDataTypes: 'Something went wrong when add fields of properties in sheet SC-DATA-TYPE',
                    noValue: 'Undefined value',
                },
                actions: {
                    addProperty: 'Add Property',
                    editProperty: 'Edit Property',
                    importDataGS: 'Import Data G-Sheet',
                    createGSTemplate: 'Create G-Sheet Template',
                    showPrivate: 'Show Private Properties',
                    hidePrivate: 'Hide Private Properties',
                    importDataTypeObject: 'Import a data of the type of object',
                    addNewName: 'New name',
                    // createProperty: 'Create Property',
                },
                tooltipActionIcons: {
                    edit: 'Edit',
                    remove: 'Remove',
                    private: 'Private Information',
                },
            },
        },
        qrCode: 'QR-Code',
        return: 'Return',
        selectIcon: {
            select: 'Select icon',
        },
        serviceProviders: {
            name: 'Name',
            username: 'Username',
            title: 'Service Provider',
            assigned: 'User assigned',
            withContract: 'With contrat',
            withoutContract: 'Without contrat',
            withoutAndWithContract: 'With et without contrat',
            with: 'With',
            without: 'Without',
            notAttributed: 'Not attributed',
            assignedByPossibleIncidents: "Prestataires attribués / Types d'incidents possibles",
            lastName: 'Last Name',
            company: 'Company',
            address: 'Full Address',
            email: 'Email',
            postalCode: 'Postal code',
            menuActions: {
                incidentAttribution: 'Automatic incident attribution',
            },
            create: {
                title: 'Create a service provider',
                input: {
                    name: 'Name',
                    lastName: 'Last Name',
                    company: 'Company',
                    address: 'Address',
                    postalCode: 'Postal code',
                    city: 'City',
                    country: 'Country',
                    telephone: 'Telephone',
                    email: 'Email',
                },
            },
            edit: {
                title: 'Edit a service provider',
                input: {
                    name: 'Name',
                    lastName: 'Last Name',
                    company: 'Company',
                    address: 'Address',
                    postalCode: 'Postal code',
                    city: 'City',
                    country: 'Country',
                    telephone: 'Telephone',
                    email: 'Email',
                },
            },
            delete: {
                title: 'Remove service provider',
                subtitle: 'Are you sure you want to delete this service provider?',
            },
            addIncidentAttribution: {
                title: 'Automatic incident attribution %{step}',
                addPostalCode:
                    "What is the postal code (separated by a comma if more than one) that comes into account in the service provider's area of intervention ?",
                addBuilding: 'Choose the buildings to assign :',
                addPlaces: 'Choose the places to assign:',
                addEquipment: 'Choose the equipment to assign: ',
                addIncident: 'Choose the type of possible incident:',
            },
            deleted: 'The service provider was well deleted',
            tag: {
                title: 'Tag',
                serviceProvider: 'Service provider',
            },
            enabled: 'Enabled',
            lastLogin: 'Last Login',
            isSemiAnonymous: 'Semi-Anonymous',
            breadcrumbs: {
                1: 'Operations',
                0: 'Service Providers',
            },
        },
        statistics: {
            breadcrumbs: {
                1: 'Dashboard',
                0: 'Dashboard %{company_name}',
            },
        },
        subCategory: {
            breadcrumbs: {
                1: 'Configuration',
                0: 'Subcategories',
            },
            categories: 'Categories',
            category: 'Category',
            fileSystemMapperEnabled: 'Document management',
            create: {
                title: 'Create a subcategory',
                input: {
                    category: 'Select a category',
                    image: 'Icon',
                    name: 'Name',
                    fileSystemMapperEnabled: 'Document management',
                    iconSource: 'Enable (icon) - Disable (image icon)',
                },
                notification: 'The subcategory was well created',
                error: 'There was an error when creating the subcategory',
                fileSystemMapperEnabled: 'Document management',
                errors: {
                    subcategoryNotUpdated: 'There was an error when updating the subcategory',
                    subcategoryNotAdded: 'There was an error when creating the subcategory',
                    iconNotAdded: 'Icon not added',
                    propertyNotCreated: 'Property not created',
                },
            },
            edit: {
                title: 'Edit subcategory',
                input: {
                    category: 'Select a category',
                    image: 'Icon',
                    name: 'Name',
                    categories: 'Choose a category',
                    fileSystemMapperEnabled: 'Document management',
                    iconSource: 'Enable (icon) - Disable (image icon)',
                },
                notification: 'The subcategory was well edited',
                error: 'There was an error when editing the subcategory',
                errors: {
                    iconNotRemoved: 'There was an error when removing the icon',
                    subcategoryNotUpdated: 'There was an error when updating the subcategory',
                    iconNotAdded: 'There was an error when adding the icon',
                },
                fileSystemMapperEnabled: 'Document management',
            },
            delete: {
                title: 'Remove subcategory',
                subtitle: 'Are you sure you want to delete this subcategory?',
            },
            archive: {
                title: 'Archive the subcategory',
                subtitle: 'Are you sure you want to archive %{name}?',
                success: 'The subcategory was well archived',
                error: 'There was an error when archiving the subcategory',
            },
        },
        users: {
            breadcrumbs: {
                0: 'Users',
                1: 'Configuration',
            },
            changePassword: 'Change password',
            passwordForgotten: "Please enter your email or username and we'll email you with how to reset it.",
            create: {
                title: 'Create an user',
                success: 'The user was successfully created',
                fail: 'An error occurred while creating the user',
            },
            edit: {
                title: 'Edit an user',
                success: 'The user was successfully edited',
                fail: 'An error occurred while editing the user',
            },
            delete: {
                title: 'Remove user',
                subtitle: 'Are you sure you want to delete the user: %{username}?',
                success: 'The user was successfully removed',
            },
            serviceProviderOrManager: 'Service provider / Manager',
            serviceProviderPerson: 'Service provider',
            serviceProviderCompany: 'Company service provider',
            companyName: 'Company name',
            type: 'Create a:',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            username: 'Username',
            phone: 'Phone number',
            accessToService: 'Access to service',
            serviceProvider: {
                label: 'Service provider',
                type: 'The service provider is',
                person: 'A person',
                company: 'A company?',
                underContract: 'Under contract',
                serviceType: 'Service type',
                referenceClientOrContract: 'Client/contract reference',
            },
            manager: {
                label: 'Manager',
            },
            subManager: {
                label: 'Submanager',
            },
            resetPassword: {
                title: 'Reset password',
                emailSentMessage: 'An email has just been sent, check your mailbox.',
                sendingEmail: 'Sending email',
                overRetryTtl: 'An email has already been sent. Try again in 10 minutes.',
                emailSent: 'Email sent',
                emailNoSent:
                    "There was a problem sending the email. Try later. If you can't, please, contact the administrator.",
            },
            errors: {
                get: 'There was an error retrieving user info',
                getAccessToService: 'There was an error retrieving access to services',
            },
        },
        valid: 'Validate',
        yes: 'yes',
    },
};

export default locale;
