import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { List } from 'react-admin';
import { subCategories } from '../../../pages/paths';
import { METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import ListActions from '../actions';
import CreateForm from '../actions/create';
import SubCategorySidebar from '../aside';
import SubCategoryDataGrid from '../datagrid';
import './styles.css';

const useStyles = (divHeight: number) =>
    makeStyles({
        content: {
            overflow: 'auto',
        },
        main: {
            height: divHeight,
        },
    });

const SubCategoryList = ({ divHeight }: { divHeight: number }) => {
    const classes = useStyles(divHeight - 40)();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(true);
    const onCloseCreateForm = () => {
        setShowCreateForm(false);
    };

    const listProps = {
        resource: METADATA_AVATAR_TYPES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: subCategories,
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            serialization_groups: ['link', 'read_meta_files'],
            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
            hidden: false,
        },
        classes: {
            main: classes.main,
            content: classes.content,
        },
    };

    return (
        <div className='subCategoryTable' style={{ height: divHeight }}>
            <List
                {...listProps}
                aside={<SubCategorySidebar divHeight={divHeight} />}
                bulkActionButtons={false}
                actions={<ListActions />}
                empty={<CreateForm open={showCreateForm} handleClose={onCloseCreateForm} />}
            >
                <SubCategoryDataGrid />
            </List>
        </div>
    );
};

export default SubCategoryList;
