import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { Fragment, useState } from 'react';

const FILE_SYSTEM_MAPPER_ENTRYPOINT = process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT;

const CustomDiv = styled('div')({
    height: 90,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
    borderRadius: 5,
    border: '2px solid lightgray',
});

const CustomIcon = styled(CancelIcon)({
    fontSize: 30,
    padding: 0,
    position: 'absolute',
    right: 3,
    top: 3,
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const RootDiv = styled('div')({
    position: 'relative',
});

const LoadingDiv = styled('div')({
    height: 90,
    border: '2px solid lightgray',
    borderRadius: 5,
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});

interface Props {
    fileSystemMapperId: any;
    mediaId: string;
    token: string;
    onRemove: any;
    insertImage: any;
}

const ShowImage = ({ fileSystemMapperId, onRemove, token, mediaId, insertImage }: Props) => {
    const url = `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers/${fileSystemMapperId}/download?bearer=${token}`;
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const handleRemove = (e: any) => {
        setShowLoading(true);
        e.preventDefault();
        onRemove(mediaId, () => {
            setShowLoading(false);
        });
    };

    return (
        <Fragment>
            {showLoading ? (
                <LoadingDiv
                    style={{
                        backgroundImage: `url(${url})`,
                    }}
                >
                    <CircularProgress />
                </LoadingDiv>
            ) : (
                <RootDiv>
                    <CustomDiv
                        onClick={() => insertImage(url)}
                        style={{
                            backgroundImage: `url(${url})`,
                        }}
                    />

                    <CustomIcon onClick={handleRemove} color='primary' />
                </RootDiv>
            )}
        </Fragment>
    );
};

export default ShowImage;
