import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { cloneElement, useEffect, useRef, useState } from 'react';
import './qrContainer.css';

interface Props {
    topComponent: any;
    rightComponent: any;
    bottomComponent: any;
    leftComponent: any;
    centerComponent: any;
    size?: any;
    outsideStyle?: any;
}

const QrContainer = ({
    topComponent,
    rightComponent,
    bottomComponent,
    leftComponent,
    centerComponent,
    size,
    outsideStyle,
}: Props) => {
    const squareOutSide = useRef<any>(null);
    const textRightRef = useRef<any>(null);
    const textLeftRef = useRef<any>(null);
    const textTopRef = useRef<any>(null);
    const textBottomRef = useRef<any>(null);
    const [textRightLine, setTextRightLine] = useState(0);
    const [textRightLine2, setTextRightLine2] = useState(0);
    const [textLeftLine, setTextLeftLine] = useState(0);
    const [textLeftLine2, setTextLeftLine2] = useState(0);
    const [textTopLine, setTextTopLine] = useState(0);
    const [textBottomLine, setTextBottomLine] = useState(0);

    useEffect(() => {
        if (textRightRef.current) {
            setTextRightLine(textRightRef.current.clientWidth / 2 - 15);
        }
        if (textLeftRef.current) {
            setTextLeftLine(textLeftRef.current.clientWidth / 2 - 15);
        }
        if (squareOutSide.current && textRightRef.current) {
            setTextRightLine2(squareOutSide.current.clientHeight / 2 - textRightRef.current.clientHeight / 2);
        }
        if (squareOutSide.current && textLeftRef.current) {
            setTextLeftLine2(squareOutSide.current.clientHeight / 2 - textLeftRef.current.clientHeight / 2);
        }
        if (squareOutSide.current && textTopRef.current) {
            setTextTopLine(squareOutSide.current.clientWidth / 2 - textTopRef.current.clientWidth / 2);
        }
        if (squareOutSide.current && textBottomRef.current) {
            setTextBottomLine(squareOutSide.current.clientWidth / 2 - textBottomRef.current.clientWidth / 2);
        }
    }, [textRightRef.current, textLeftRef.current, textTopRef.current, textBottomRef.current]);

    return (
        <div
            className={classnames('outside', {
                ['padding-top']: !!topComponent,
                ['padding-bottom']: !!bottomComponent,
                ['padding-left']: !!leftComponent,
                ['padding-right']: !!rightComponent,
            })}
            // style={{ width: size, height: size }}
            ref={squareOutSide}
            style={{ ...(outsideStyle ? outsideStyle : {}) }}
        >
            {topComponent &&
                cloneElement(topComponent, {
                    ...topComponent.props,
                    ref: textTopRef,
                    className: 'textTop',
                    style: { ...topComponent.props.style, left: textTopLine },
                })}
            {rightComponent &&
                cloneElement(rightComponent, {
                    ...rightComponent.props,
                    ref: textRightRef,
                    className: 'textRight',
                    style: { ...rightComponent.props.style, right: -textRightLine, top: textRightLine2 },
                })}
            {bottomComponent &&
                cloneElement(bottomComponent, {
                    ...bottomComponent.props,
                    ref: textBottomRef,
                    className: 'textBottom',
                    style: { ...bottomComponent.props.style, left: textBottomLine },
                })}
            {leftComponent &&
                cloneElement(leftComponent, {
                    ...leftComponent.props,
                    ref: textLeftRef,
                    className: 'textLeft',
                    style: { ...leftComponent.props.style, left: -textLeftLine, top: textLeftLine2 },
                })}
            {centerComponent && (
                <div className='center' style={{ backgroundColor: 'white' }}>
                    {centerComponent}
                </div>
            )}
        </div>
    );
};

export default QrContainer;
