import React from 'react';
import DialogTemplate from '../../../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { Avatar, MetadataAvatarType, TicketingUserAllocation } from '../../../../../../../utils/types';
import Form from './form';
import { Record, ReduxState, useTranslate } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import { TICKETING_USER_ALLOCATIONS } from '../../../../../../../providers/resources';
import extractIdFromURI from '../../../../../../../utils/id';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ButtonsBlockForm from '../../../../../../forms/buttonsBlock';

interface Props {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    subCategory: MetadataAvatarType;
    place: Avatar;
    isRoot: boolean;
}

const AddServiceProviderFormDialog = ({ open, handleClose, place, subCategory, ...restProps }: Props) => {
    // console.log('AddServiceProviderFormDialog isRoot***', restProps.isRoot);

    const translate = useTranslate();
    const ticketingUserAllocation: Record | undefined = useSelector((state: ReduxState) => {
        const data = state.admin.resources[TICKETING_USER_ALLOCATIONS].data;
        if (data && Object.keys(data).length > 0) {
            return Object.values(data).find(
                (item: Record) =>
                    item &&
                    (item as TicketingUserAllocation).place.id === extractIdFromURI(place.id as string) &&
                    (item as TicketingUserAllocation).subCategories.includes(subCategory.id as string)
            );
        }
        return undefined;
    });

    return (
        <DialogTemplate
            open={open}
            icon={<PersonIcon />}
            onClose={handleClose}
            maxWidth={'xs'}
            title={translate('app.navigation.configuration.serviceProvider.title')}
        >
            {!ticketingUserAllocation && (
                <Form {...restProps} handleCancel={handleClose} place={place} subCategory={subCategory} />
            )}
            {ticketingUserAllocation && (
                <Grid container>
                    <Grid item>
                        <Typography color={'error'} variant={'body1'}>
                            {translate('app.navigation.configuration.serviceProvider.alreadyAssigned')}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent={'flex-end'}>
                        <ButtonsBlockForm
                            labelCancelButton={'app.return'}
                            validButtonIsSubmitType={false}
                            onCancel={handleClose}
                            showValidButton={false}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogTemplate>
    );
};
export default AddServiceProviderFormDialog;
