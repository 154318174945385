import React, { useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import * as _ from 'lodash';
import { GetListResult, TextInput, useDataProvider, useTranslate } from 'react-admin';
import { Avatar, MetadataAvatarType, TicketingUserProviderEventConfiguration } from '../../../../../../../utils/types';
import { TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS } from '../../../../../../../providers/resources';
import extractIdFromURI from '../../../../../../../utils/id';

interface Props {
    subCategory: MetadataAvatarType;
    place: Avatar;
    fieldNameOnChangeSubscription: string;
    fieldNameUserWorkResolutionDelay: string;
    fieldNameUserProviderEventConfig: string;
}

const DefineTimeInput = (props: Props) => {
    const form = useForm();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [
        ticketingUserProviderEventConfiguration,
        setTicketingUserProviderEventConfiguration,
    ] = useState<TicketingUserProviderEventConfiguration | null>(null);

    const userField = useField(props.fieldNameOnChangeSubscription);

    const userProviderEventConfigField = useField(props.fieldNameUserProviderEventConfig);

    const userWorkResolutionDelayField = useField(props.fieldNameUserWorkResolutionDelay);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getTimeDefinerForUser = (userUri: string) => {
        setIsLoading(true);
        dataProvider.getList(
            TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
            {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
                filter: {
                    place: extractIdFromURI(props.place.id as string),
                    subCategory: extractIdFromURI(props.subCategory.id as string),
                    user: extractIdFromURI(userUri),
                },
            },
            {
                onSuccess: (response: GetListResult) => {
                    if (response && 'data' in response && response.data && response.data.length === 1) {
                        setTicketingUserProviderEventConfiguration(
                            response.data[0] as TicketingUserProviderEventConfiguration
                        );
                    } else if (response && 'data' in response && response.data && response.data.length > 1) {
                        console.log('DefineTimeInput more than one tiketing user provider event configuration ****');
                    }
                    setIsLoading(false);
                },
                onFailure: (error: any) => {
                    console.error('Getting', TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, error);
                    setIsLoading(false);
                },
            }
        );
    };

    const [executeGetTimeDefinedForUserWithThrottle] = useState(() =>
        _.throttle((userUri) => getTimeDefinerForUser(userUri), 1000, { leading: false })
    );

    useEffect(() => {
        const userUri = userField.input.value;
        if (
            (userUri && !ticketingUserProviderEventConfiguration) ||
            (ticketingUserProviderEventConfiguration && ticketingUserProviderEventConfiguration.user !== userUri)
        ) {
            !isLoading && executeGetTimeDefinedForUserWithThrottle(userUri);
        }
    }, [userField.input.value]);

    useEffect(() => {
        // set user work resolution delay input value, only if the value is empty, by ticketingUserProviderEventConfiguration.workResolutionDelay
        if (ticketingUserProviderEventConfiguration && userWorkResolutionDelayField.input.value.length === 0) {
            form.change(
                props.fieldNameUserWorkResolutionDelay,
                ticketingUserProviderEventConfiguration.workResolutionDelay
            );
            userProviderEventConfigField.input.onChange(ticketingUserProviderEventConfiguration.id);
        }
    }, [ticketingUserProviderEventConfiguration]);

    useEffect(() => {
        form.change(props.fieldNameUserProviderEventConfig, undefined);
    }, []);

    return (
        <TextInput
            variant={'standard'}
            source={props.fieldNameUserWorkResolutionDelay}
            label={translate('app.navigation.configuration.serviceProvider.workResolutionDelay.label')}
            helperText={false}
            fullWidth
            type={'number'}
        />
    );
};

export default DefineTimeInput;
