import { getTokenData, TokenDataDecoded } from '../../utils/token';
import { GroupMetadataAvatarType, User } from '../../utils/types';
import {
    ACTIONS,
    ResolvedCompanyLogoOnFileSystemMapperAction,
    resolvedCompanyLogoOnFileSystemMapperCreator,
    ResolvedUserConnectedAction,
    resolvedUserConnectedCreator,
    selectUser,
    SelectUserAction,
    TokenFoundAction,
    tokenFoundCreator,
} from '../actions/users';
import { CustomReducerState } from './index';
import { ReduxState } from 'react-admin';
import { GROUP_METADATA_AVATAR_TYPES, USERS } from '../../providers/resources';
import { clearTicketingStateCreator } from '../actions/ticketingState';

interface UserCacheState {
    companyLogoUrl?: string;
}
export interface UsersState {
    userConnected: User | null;
    selected: User | null;
    tokenData: TokenDataDecoded | null;
    cache?: UserCacheState;
}

export const getUserSelected = (state: CustomReducerState & ReduxState): User | undefined => {
    const selected = state.ticketing.user.selected;
    const selectedId = selected ? (selected as User).id : null;
    if (selectedId && state.admin.resources[USERS].list.ids.includes(selectedId)) {
        return state.admin.resources[USERS].data[selectedId] as User;
    }
    return undefined;
};
const InitialState: UsersState = {
    userConnected: null,
    selected: null,
    tokenData: null,
    cache: undefined,
};

const reducer = (state: UsersState = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case tokenFoundCreator.action: {
            const iAction: TokenFoundAction = action as TokenFoundAction;
            const tokenData: TokenDataDecoded | null = getTokenData(iAction.payload.token);
            return {
                ...state,
                tokenData: {
                    ...tokenData,
                    tokenWithRoles: iAction.payload.token,
                    refreshToken: iAction.payload.refreshToken,
                },
            };
        }
        case resolvedUserConnectedCreator.action: {
            const iAction: ResolvedUserConnectedAction = action as ResolvedUserConnectedAction;
            return {
                ...state,
                userConnected: {
                    ...state.userConnected,
                    ...iAction.payload.user,
                },
            };
        }
        case resolvedCompanyLogoOnFileSystemMapperCreator.action: {
            const iAction = action as ResolvedCompanyLogoOnFileSystemMapperAction;
            return {
                ...state,
                cache: {
                    ...state.cache,
                    companyLogoUrl: iAction.payload.companyLogoUrl,
                },
            };
        }
        case selectUser.action: {
            const iAction = action as SelectUserAction;
            return {
                ...state,
                selected: iAction.payload.user,
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
