import { AVATAR_IDENTIFIER_CTN } from './CONST';
import { Avatar } from './types';

const getAvatarCtnIdentifiers = (record: Avatar): string => {
    let ctnIdentifier = '...';
    if (record.identifiers && record.identifiers.length > 0) {
        record.identifiers.forEach((identifier: string) => {
            const splitArray: string[] = identifier.split(':');
            ctnIdentifier =
                splitArray &&
                splitArray.length >= 2 &&
                splitArray[0].toLowerCase() === AVATAR_IDENTIFIER_CTN.toLowerCase()
                    ? splitArray[1]
                    : '...';
        });
        return ctnIdentifier;
    }
    return ctnIdentifier;
};

export { getAvatarCtnIdentifiers };
