import { DialogContentText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Delete, FiberNew, Settings } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';
import React, { cloneElement, ReactElement } from 'react';

const useStyles = makeStyles({
    paper: {
        borderRadius: 0,
    },
    dialogTitle: {
        padding: '15px 15px 5px 15px',
    },
    dialogTitleWithoutBottomPadding: {
        padding: '10px 20px 0 10px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: 13,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
        marginTop: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
        marginBottom: 10,
    },
    icon: {
        fontSize: 20,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
});

interface Props {
    buttons?: ReactElement;
    open: boolean;
    onClose: () => void;
    title?: string;
    icon?: ReactElement;
    children: any;
    helpText?: string;
    maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
    scroll?: 'body' | 'paper';
    withEditIcon?: boolean;
    withCreateIcon?: boolean;
    withDeleteIcon?: boolean;
    overflowHidden?: boolean;
    dataTestId?: string;
    contentStyle?: any;
    setRef?: (ref: any) => void;
    ref?: any;
}

const DialogTemplate = ({
    open,
    onClose,
    icon,
    title,
    children,
    helpText,
    maxWidth = 'sm',
    withCreateIcon,
    withEditIcon,
    withDeleteIcon,
    dataTestId,
    buttons,
    overflowHidden,
    contentStyle,
    scroll = 'paper',
    setRef,
    ref,
}: Props) => {
    const classes = useStyles();

    const editIcon = <Settings data-test-sc='dialogTemplateEditIcon' />;
    const createIcon = <FiberNew data-test-sc='dialogTemplateCreateIcon' />;
    const deleteIcon = <Delete data-test-sc='dialogTemplateDeleteIcon' />;

    const iconToRender = icon
        ? icon
        : withEditIcon
        ? editIcon
        : withCreateIcon
        ? createIcon
        : withDeleteIcon
        ? deleteIcon
        : null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableBackdropClick={true}
            scroll={scroll}
            maxWidth={maxWidth}
            fullWidth={true}
            data-testid={dataTestId ? dataTestId : 'dialogTemplate'}
            onClick={(e) => e.stopPropagation()}
            classes={{
                paper: classes.paper,
            }}
            ref={(element: any) => {
                if (!ref && setRef) setRef(element);
            }}
        >
            {title && (
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <div className={classes.container}>
                        {iconToRender &&
                            cloneElement(iconToRender, {
                                ...iconToRender.props,
                                ...('data-testid' in iconToRender.props
                                    ? { 'data-testid': iconToRender.props['data-testid'] }
                                    : { 'data-testid': 'dialogTemplateIcon' }),
                                className: classes.icon,
                            })}
                        <Typography className={classes.dialogTitleText} data-testid='dialogTemplateTitle'>
                            {title}
                        </Typography>
                    </div>
                </DialogTitle>
            )}

            <DialogContent
                classes={{ root: classes.dialogContent }}
                className={classnames({
                    [classes.overflowHidden]: overflowHidden,
                })}
                {...(contentStyle ? { style: contentStyle } : {})}
            >
                {helpText && (
                    <DialogContentText className={classes.dialogText} data-test-sc='dialogTemplateHelpText'>
                        {helpText}
                    </DialogContentText>
                )}
                {children && children}
            </DialogContent>

            {buttons && cloneElement(buttons, { ...buttons.props })}
        </Dialog>
    );
};

export default DialogTemplate;
