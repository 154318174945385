import React, { useEffect } from 'react';
import { GetListResult, Record, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { AVATARS } from '../../providers/resources';
import { selectTreeLevelItemCreator, selectTreeLevelZeroItemsCreator } from '../../redux/actions/navigation';
import { CustomReducerState } from '../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../utils/CONST';
import { Avatar } from '../../utils/types';
import TreeView from './v1/CustomTreeView';
import FalseShouldUpdate from "../fields/recomposeLike/falseShouldUpdate";

const Tree = () => {
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const treeVersion = useSelector((state: CustomReducerState) => state.ticketing.navigation.tree.version);

    useEffect(() => {
        dataProvider
            .getList(AVATARS, {
                pagination: {
                    page: 1,
                    perPage: 100,
                },
                sort: {
                    field: 'id',
                    order: 'asc',
                },
                filter: {
                    lvl: 0,
                    'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                    serialization_groups: ['read_avatar_root', 'read_meta_files', 'link', 'read_avatar_parent'],
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && 'data' in result) {
                    const data = result.data;
                    if (data.length > 0) {
                        dispatch(selectTreeLevelZeroItemsCreator.fn(data as Avatar[]));
                        dispatch(selectTreeLevelItemCreator.fn((data as Avatar[])[0]['@id']));
                    }
                }
            })
            .catch((e) => {
                console.error('Error geting avatar with read avatar root filter', e);
            });
    }, [treeVersion]);

    return (
        <>
            <FalseShouldUpdate>
                {/*@ts-ignore*/}
                <TreeView updateFilter={() => null} />
            </FalseShouldUpdate>
        </>
    );
};

export default Tree;
