import {
    ACTIONS,
    registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn,
    RegisterTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumnAction,
} from '../actions/ticketingSubcategoryEventTypeDependencies';

export interface MappingItem {
    idShown: string;
    toDelete: boolean;
}

/**
 * mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.key is the ID of the current level for splitting the data by level
 * mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.key.key  is the ID of
 *  TicketingSubcategoryEventTypesDependencies in the list
 * mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.key.idShown is the ID of
 *  the selected TicketingSubcategoryEventTypesDependencies from the component EventTypesField
 * mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.key.toDelete
 * We need that to know the exact ID shown in the component EventTypesField because the button Delete need to remove
 *  the ID shown in that component
 */
export interface TicketingSubcategoryEventTypesDependenciesNavigationState {
    mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn: {
        [key: string]: {
            [key: string]: MappingItem;
        };
    };
}

const InitialState: TicketingSubcategoryEventTypesDependenciesNavigationState = {
    mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn: {},
};

const reducer = (state: TicketingSubcategoryEventTypesDependenciesNavigationState = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.action: {
            const iAction: RegisterTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumnAction = action;
            const currentPlaceIdState =
                iAction.payload.placeId in state.mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn
                    ? state.mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn[
                          iAction.payload.placeId
                      ]
                    : {};

            return {
                ...state,
                mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn: {
                    ...state.mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn,
                    [iAction.payload.placeId]: {
                        ...currentPlaceIdState,
                        [iAction.payload.ticketingSubcategoryEventTypesDepsInList]: {
                            idShown: iAction.payload.ticketingSubcategoryEventTypesDepsIdShownInEachColumn,
                            toDelete: iAction.payload.toDelete,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
