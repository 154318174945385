import { all, takeEvery } from 'redux-saga/effects';
import { publishTokenActionCreator } from '../../actions/fileManager/fileManagerActionCreator';

const publishToken = function* (action: any) {
    const {
        payload: { id, tokenWithRoles },
    } = action;

    fetch(`${process.env.REACT_APP_PS_BACKEND_ENTRYPOINT}/api/publish_async_token`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${tokenWithRoles}`,
            accept: 'application/ld+json',
            'content-type': 'application/ld+json',
        },
        body: JSON.stringify({ asyncTokenId: id }),
    });

    yield null;
};

const fileManager = () =>
    function* () {
        yield all([takeEvery(publishTokenActionCreator.action, publishToken)]);
    };

export default fileManager;
