import React from 'react';
import Statistics from '../components/statistics';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import Authentication from '../commons/auth/Authentication';
import { statisticsNameRoute } from './paths';

const StatisticsPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={statisticsNameRoute}>{userConnected && <Statistics />}</Authentication>;
};

export default StatisticsPage;
