import { useListContext, useTranslate } from 'react-admin';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER,
    NodeTypeFiltersForHiddenClosedIncidents,
    NodeTypeFiltersForHiddenResolvedIncidents,
} from '../../../utils/CONST';
import { useHistory } from 'react-router-dom';
import { incidents } from '../../../pages/paths';

const RESOLVED_INCIDENT = 'resolved_incident';
const CLOSED_INCIDENT = 'closed_incident';

interface Props {
    filters?: any;
    alwaysOn?: boolean;
}

type CurrentNodeTypeFilterType = {
    [RESOLVED_INCIDENT]: { checked: boolean };
    [CLOSED_INCIDENT]: { checked: boolean };
};

const CurrentHistoryIncidentsFilter = (props: Props) => {
    props;

    const translate = useTranslate();
    const { setFilters, filterValues, displayedFilters } = useListContext();

    const [resolvedChecked, setResolvedChecked] = useState(
        !!(
            filterValues &&
            CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER in filterValues &&
            filterValues[CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER] &&
            filterValues[CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER].every((filter: string) =>
                NodeTypeFiltersForHiddenResolvedIncidents.includes(filter)
            )
        )
    );
    const [closedChecked, setClosedChecked] = useState(
        !!(
            filterValues &&
            CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER in filterValues &&
            filterValues[CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER] &&
            filterValues[CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER].every((filter: string) =>
                NodeTypeFiltersForHiddenClosedIncidents.includes(filter)
            )
        )
    );

    const handleListFilters = (_resolvedChecked: boolean, _closedChecked: boolean) => {
        let newFilters = [];

        newFilters = _resolvedChecked
            ? NodeTypeFiltersForHiddenResolvedIncidents
            : _closedChecked
            ? NodeTypeFiltersForHiddenClosedIncidents
            : [];

        if (_resolvedChecked && _closedChecked) {
            newFilters = NodeTypeFiltersForHiddenResolvedIncidents.filter((item) =>
                NodeTypeFiltersForHiddenClosedIncidents.includes(item)
            );
        }

        setFilters(
            {
                ...filterValues,
                [CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER]: newFilters,
            },
            displayedFilters
        );
    };

    const handleChange = (identifier: string) => (event: any) => {
        switch (identifier) {
            case CLOSED_INCIDENT:
                handleListFilters(resolvedChecked, event.target.checked);
                setClosedChecked(event.target.checked);
                break;
            case RESOLVED_INCIDENT:
                handleListFilters(event.target.checked, closedChecked);
                setResolvedChecked(event.target.checked);
                break;
            default:
                break;
        }
    };

    return (
        <Grid container>
            <Grid container>
                <Typography noWrap variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                    {translate('app.incidents.showIncidentClosed')}
                </Typography>

                <Grid item>
                    <Switch
                        onChange={handleChange(CLOSED_INCIDENT)}
                        color={'primary'}
                        checked={closedChecked}
                        inputProps={{ 'aria-label': translate('app.incidents.showIncidentClosed') }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Typography noWrap variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                    {translate('app.incidents.showIncidentResolved')}
                </Typography>

                <Grid item>
                    <Switch
                        onChange={handleChange(RESOLVED_INCIDENT)}
                        color={'primary'}
                        checked={resolvedChecked}
                        inputProps={{ 'aria-label': translate('app.incidents.showIncidentResolved') }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CurrentHistoryIncidentsFilter;
