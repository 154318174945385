import React from 'react';
import { AutocompleteInput, Identifier, useCreate, useNotify, useTranslate } from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import {
    METADATA_AVATAR_TYPES,
    TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
} from '../../../../../../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../../../../../../utils/CONST';
import { Avatar } from '../../../../../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../../../../../utils/validations/parseValidations';
import ReferenceInput from '../../../../../../../../commons/ra/input/ReferenceInput';
import DialogTemplateButtons from '../../../../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';

const SUBCATEGORY_FIELD = 'subcategory';

interface FormProps {
    avatar: Avatar;
    handleSave: () => void;
    handleCancel: () => void;
}

const AvatarForm = ({ avatar, handleCancel, handleSave }: FormProps) => {
    const translate = useTranslate();
    const notify = useNotify();

    const [create] = useCreate(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES);

    const onSubmit = (data: { [key: string]: any }) => {
        const dataToSend: { subCategory: string; place: Identifier } = {
            // eventTypes: [],
            place: avatar.id,
            subCategory: data[SUBCATEGORY_FIELD],
        };
        create(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, dataToSend, {
            onSuccess: () => {
                notify('app.navigation.configuration.incident.subCategory.added.notification', 'success');
                handleSave();
            },
            onFailure: (...args: unknown[]) => {
                const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                if (violations && violations.length > 0) {
                    const violation = violations[0];
                    notify(
                        `app.navigation.configuration.incident.subCategory.added.${getCodeFromViolationMessage(
                            violation?.message
                        )}`,
                        'error'
                    );
                } else {
                    notify('app.navigation.configuration.incident.subCategory.added.error', 'warning');
                }
                handleCancel();
                console.error(`ERRROR creating ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`, ...args);
            },
        });
    };

    const optionRenderer = (choice: any) => {
        return choice ? `${choice.name} (${choice.groupMetadataAvatarType.name})` : '';
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, submitting, valid }) => {
                return (
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <ReferenceInput
                            reference={METADATA_AVATAR_TYPES}
                            filterToQuery={(searchText: string) => ({
                                name: searchText,
                            })}
                            filter={{
                                'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                                order__createdAt: 'DESC',
                                hidden: false,
                                'serialization_groups[0]': 'read_group_restricted',
                            }}
                            source={SUBCATEGORY_FIELD}
                            label={translate('app.navigation.configuration.incident.subCategory.title')}
                            fullWidth
                        >
                            <AutocompleteInput
                                resettable
                                source='subcategory'
                                variant={'standard'}
                                filterToQuery={(searchText: string) => ({
                                    name: searchText,
                                })}
                                optionText={optionRenderer}
                            />
                        </ReferenceInput>

                        <DialogTemplateButtons
                            outside
                            withCancel={true}
                            withCreate
                            cancelButtonLabel={translate('app.cancel')}
                            onCancelClick={handleCancel}
                            isSubmitting={submitting}
                            isValidForm={valid && !pristine}
                            createButtonLabel={translate('app.add')}
                        />
                    </form>
                );
            }}
        />
    );
};

export default AvatarForm;
