import { LinearProgress, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import {
    DateTimeInput,
    GetListResult,
    Identifier,
    Record,
    TextInput,
    useDataProvider,
    useNotify,
    useTranslate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import { Avatar, AvatarProperty } from '../../../../utils/types';
import GlobalForm from '../../../forms';
import { alertReminderConfiguration } from '../../../../utils/event';
import { AVATAR_PROPERTY } from '../../../../providers/resources';
import extractIdFromURI from '../../../../utils/id';
import {
    TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_MESSAGE64,
    TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_SEPARATOR,
    TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_WHEN,
    TICKETING_REMINDER_GOOGLE_CALENDAR_EVENT_PROPERTY,
} from '../../../../utils/CONST';
import moment, { Moment } from 'moment';
import { toFormatDate } from '../../../../utils/date';
import { styled } from '@material-ui/styles';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';

interface AlertConfigFormProps {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    removeAlert: () => void;
    event: Avatar;
    handleSuccessfully?: () => void;
    search?: boolean;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    infoBlock: { border: '1px solid #E4E5EB', padding: 5, marginBottom: 20 },
    marginBottom: {
        marginBottom: 5,
    },
    overline: {
        fontWeight: 700,
    },
});

const CustomDiv = styled('div')({
    alignItems: 'center',
    width: '100%',
});

const MESSAGE_FIELD = 'message';
const DATE_REMINDER_FIELD = 'dateReminder';
const AFTER_IN_HOURS_FIELD = 'alertInHours';

const AlertConfigForm = ({ open, onClose, event, search, onCancel, removeAlert }: AlertConfigFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);
    const userConnectedId: Identifier | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected?.id
    );

    const [googleCalendarReminderProperty, setGoogleCalendarReminderProperty] = useState<AvatarProperty | undefined>(
        undefined
    );
    const [loadingProperty, setLoadingProperty] = useState(false);
    const [loadingCreatingAlert, setLoadingCreatingAlert] = useState(false);

    useEffect(() => {
        if (search && userConnectedId) {
            setLoadingProperty(true);
            dataProvider
                .getList(AVATAR_PROPERTY, {
                    pagination: { page: 1, perPage: 1 },
                    filter: {
                        'avatar.id': extractIdFromURI(event.id as string),
                        name: TICKETING_REMINDER_GOOGLE_CALENDAR_EVENT_PROPERTY,
                        definition: extractIdFromURI(userConnectedId as string),
                    },
                    sort: { field: 'timestamp', order: 'asc' },
                })
                .then((result: GetListResult<Record>) => {
                    setLoadingProperty(false);
                    if (result && 'data' in result && result.data && result.data.length > 0) {
                        setGoogleCalendarReminderProperty(result.data[0] as AvatarProperty);
                    }
                })
                .catch((error: any) => {
                    setLoadingProperty(false);
                    notify('app.incidents.notification.getReminderEventError', error);
                    console.log('ERROR getting AVATAR PROPERTY', error);
                });
        }
    }, []);

    const onSubmit = (values: any) => {
        setLoadingCreatingAlert(true);
        let dateReminder = values[DATE_REMINDER_FIELD];
        if (!dateReminder) return;
        const now = moment();
        dateReminder = moment(dateReminder);
        const alertInHours = dateReminder.diff(now, 'minutes') / 60;

        const message = values[MESSAGE_FIELD];
        const apiRoot = `${process.env.REACT_APP_API_ROOT}`;

        tokenWithRoles &&
            alertReminderConfiguration(event, tokenWithRoles, alertInHours, message, apiRoot)
                .then((response: string | null) => {
                    if (response) {
                        notify(
                            search
                                ? 'app.incidents.notification.reminderEventEdited'
                                : 'app.incidents.notification.reminderEventCreated',
                            'success'
                        );
                        setLoadingCreatingAlert(false);
                        onClose();
                    } else {
                        setLoadingCreatingAlert(false);
                        notify('app.incidents.notification.createReminderEventError', 'error');
                    }
                })
                .catch((error) => {
                    setLoadingCreatingAlert(false);
                    notify('app.incidents.notification.createReminderEventError', 'error');
                    console.error('Error configurating alert reminder', error);
                });
    };

    const renderGoogleCalendarReminderInfo = () => {
        if (!googleCalendarReminderProperty || !search) return <span />;
        const splitArrayPropertyKeyValue: string[] = googleCalendarReminderProperty?.value.split(
            TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_SEPARATOR
        );

        const when64Value = getGoogleCalendarValue(
            TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_WHEN,
            splitArrayPropertyKeyValue
        );
        const message64Value = getGoogleCalendarValue(
            TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_MESSAGE64,
            splitArrayPropertyKeyValue
        );
        let message = '';
        let whenDate: string | Moment = '';

        if (message64Value === btoa(atob(message64Value))) {
            message = atob(message64Value);
        }
        if (when64Value === btoa(atob(when64Value))) {
            whenDate = moment(atob(when64Value)).isValid() ? toFormatDate(atob(when64Value)) : '';
        }

        return (
            <CustomDiv className={classes.infoBlock}>
                <Grid item className={classes.marginBottom}>
                    {`${translate('app.incidents.alertConfig.eventNameInfo')}`}{' '}
                    <strong>{translate('app.incidents.alertConfig.eventNameValue', { eventName: event.name })}</strong>
                </Grid>
                <Grid item className={classes.marginBottom}>
                    {`${translate('app.incidents.alertConfig.triggerDateInfo')}`}
                    <strong>
                        {translate('app.incidents.alertConfig.triggerDateValue', { triggerDate: whenDate })}
                    </strong>
                </Grid>
                <Grid item className={classes.marginBottom}>
                    {`${translate('app.incidents.alertConfig.messageInfo')}`}{' '}
                    <strong>{translate('app.incidents.alertConfig.messageValue', { message })}</strong>
                </Grid>
            </CustomDiv>
        );
    };

    const getGoogleCalendarValue = (name: string, splitArrayPropertyKeyValue: string[]) => {
        const itemStartsWithName = splitArrayPropertyKeyValue.find((item) => item.startsWith(name));
        const itemStartsWithNameSplitArray = itemStartsWithName ? itemStartsWithName.split(':') : '';
        return itemStartsWithNameSplitArray.length > 0 ? itemStartsWithNameSplitArray[1] : '';
    };

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <GlobalForm
                    title={search ? 'app.incidents.alertConfig.edit' : 'app.incidents.alertConfig.create'}
                    titleIcon={<AlarmOnIcon color={'secondary'} />}
                >
                    {loadingProperty && !googleCalendarReminderProperty && <LinearProgress />}
                    {renderGoogleCalendarReminderInfo()}
                    <Form
                        initialValues={{}}
                        onSubmit={onSubmit}
                        render={({ handleSubmit, pristine, submitting }) => {
                            return (
                                <Grid container>
                                    <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                        {/*<Grid item xs>*/}
                                        {/*    <NumberInput*/}
                                        {/*        autoFocus*/}
                                        {/*        variant={'standard'}*/}
                                        {/*        fullWidth*/}
                                        {/*        required*/}
                                        {/*        source={AFTER_IN_HOURS_FIELD}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        <Grid item xs>
                                            <TextInput
                                                autoFocus
                                                variant={'standard'}
                                                required
                                                key={'message'}
                                                fullWidth
                                                multiline
                                                label={translate('app.incidents.alertConfig.message')}
                                                source={MESSAGE_FIELD}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <DateTimeInput
                                                label={translate('app.incidents.alertConfig.triggerDate')}
                                                fullWidth
                                                required
                                                source={DATE_REMINDER_FIELD}
                                                variant={'standard'}
                                            />
                                        </Grid>

                                        <DialogTemplateButtons
                                            buttons={[
                                                {
                                                    label: translate('app.incidents.alertConfig.delete'),
                                                    onClick: () => removeAlert(),
                                                    props: {
                                                        color: 'primary',
                                                        disabled: true,
                                                    },
                                                },
                                            ]}
                                            outside
                                            withCancel
                                            onCancelClick={onCancel}
                                            withEdit
                                            isSubmitting={loadingCreatingAlert}
                                            cancelButtonLabel={translate('app.buttons.return')}
                                            createButtonLabel={translate('app.buttons.validate')}
                                        />
                                    </form>
                                </Grid>
                            );
                        }}
                    />
                </GlobalForm>
            </DialogContent>
        </Dialog>
    );
};
export default AlertConfigForm;
