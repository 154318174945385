import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import {
    CreateResult,
    Record,
    ReduxState,
    UpdateResult,
    useDataProvider,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form } from 'react-final-form';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import UserConfig from '../userConfig';
import { cleanUserDataBeforeSending, resolveCustomFields, resolveTags } from '../../../../utils/user/crud';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMedia, CreateUser, Media, Profile, User } from '../../../../utils/types';
import {
    ACCESS_TO_SERVICE_FIELD,
    FormEditUserData,
    USER_EMAIL_FIELD,
    USER_FIRST_NAME_FIELD,
    USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD,
    USER_LAST_NAME_FIELD,
    USER_MANAGER_FIELD,
    USER_PHONE_FIELD,
    USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD,
    USER_SERVICE_PROVIDER_FIELD,
    USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD,
    USER_SERVICE_PROVIDER_TYPE_FIELD,
    USER_SUB_MANAGER_FIELD,
    USER_TYPE_FIELD,
    USER_USER_NAME_FIELD,
    userTypeInteralNameProfileMapper,
} from '../CONST';
import {
    getTicketingField,
    TICKETING_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../../utils/user/customFields';
import {
    TICKETING_MANAGER_TAG,
    TICKETING_MEMORANDUM_TRUSTEE_INTERNAL_NAME_PROFILE,
    TICKETING_SUB_MANAGER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
} from '../../../../utils/CONST';
import { MEDIA, MEDIAS, PROFILES, USERS } from '../../../../providers/resources';
import { getMediaByImagesById, MediaStateProp } from '../../../../redux/reducers/medias';
import { CustomReducerState } from '../../../../redux/reducers';
import { getDownloadFileSystemMapperUrl } from '../../../../utils/medias';
import extractIdFromURI from '../../../../utils/id';
import { deleteMedia as deleteMediaAction, resolveMedia as resolveMediaAction } from '../../../../redux/actions/medias';

interface CreateFormProps {
    selected: User;
    open: boolean;
    fromExternalInput?: boolean;
    initialName?: string;
    handleClose: () => void;
    handleCreateOnSuccess?: (data: Record) => void;
}

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: '100%',
    },
}));

const POSSIBLES_USER_TAGS_ACCORDING_USER_TYPE = [
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_SUB_MANAGER_TAG,
    TICKETING_MANAGER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
];

interface MapperMediaSrc {
    media: Media | null;
    src: string;
}

const IMAGE_LOADING = `${process.env.REACT_APP_ASSETS_ROOT}/images/default-image-loading.svg`;

const EditForm = ({ selected, open, handleClose, fromExternalInput }: CreateFormProps) => {
    const [loading, isLoading] = useState(false);
    const [profileForCurrentUserTypeSelected, setProfileForCurrentUserTypeSelected] = useState<Profile | undefined>();
    const [initialValues, setInitialValues] = useState<FormEditUserData | undefined>();
    const [accessToService, setAccessToService] = useState<string | undefined>();
    const [accessToServiceSelected, setAccessToServiceSelected] = useState<string | undefined>();
    const [update] = useUpdate(USERS, selected.id);

    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
    const [newImageUserLoaded, setNewImageUserLoaded] = useState<{ name: string; data: string } | null>(null);
    const [imageUserWasChanged, setImageUserWasChanged] = useState<boolean>(false);
    const [currentMapperMediaSrc, setCurrentMapperMediaSrc] = useState<MapperMediaSrc | undefined>();

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const profiles: Record[] | undefined = useSelector((state: ReduxState) =>
        Object.values(state.admin.resources[PROFILES].data)
    );

    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleSubmit = async (formValues: CreateUser & FormEditUserData) => {
        const userTypeValue = USER_TYPE_FIELD in formValues ? formValues[USER_TYPE_FIELD] : '';
        const userServiceProviderTypeValue =
            USER_SERVICE_PROVIDER_TYPE_FIELD in formValues ? formValues[USER_SERVICE_PROVIDER_TYPE_FIELD] : '';

        const userTypeOfSelected = resolveUserType();
        const tagsToKeep = userTypeOfSelected
            ? getTagsToKeepForUserSelected(userTypeValue, userTypeOfSelected)
            : selected?.tags
            ? selected?.tags
            : [];
        const profilesToKeep: string[] = userTypeOfSelected
            ? getProfilesIdToKeepForUserSelected(userTypeValue, userTypeOfSelected)
            : selected?.profiles
            ? selected.profiles
            : [];

        const tags = resolveTags(formValues);
        let dataToSend = {
            ...formValues,
            [USER_LAST_NAME_FIELD]:
            // @ts-ignore
                userServiceProviderTypeValue === USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD
                    ? formValues[USER_FIRST_NAME_FIELD]
                    : formValues[USER_LAST_NAME_FIELD],
            tags: [...tags, ...tagsToKeep],
            image: imageUserWasChanged && selected.image ? null : imageId,
            profiles: [...profilesToKeep],
        };

        if (profileForCurrentUserTypeSelected) {
            const oldProfiles = dataToSend.profiles ? dataToSend.profiles : [];
            dataToSend = {
                ...dataToSend,
                profiles: [...oldProfiles, profileForCurrentUserTypeSelected?.id as string],
            };
        }
        let customFields = userTypeOfSelected
            ? getCustomFieldsToKeepForUserSelected(userTypeValue, userTypeOfSelected)
            : selected.customFields
            ? selected.customFields
            : {};
        // @ts-ignore
        if (userTypeValue === USER_SERVICE_PROVIDER_FIELD) {
            const customFieldsResolved = resolveCustomFields(formValues);
            customFields = { ...customFields, ...customFieldsResolved };
        }

        dataToSend = {
            ...dataToSend,
            customFields: customFields ? customFields : {},
        };

        if (ACCESS_TO_SERVICE_FIELD in dataToSend && dataToSend[ACCESS_TO_SERVICE_FIELD]) {
            const oldProfiles = dataToSend.profiles ? dataToSend.profiles : [];
            dataToSend = {
                ...dataToSend,
                // @ts-ignore
                profiles: [dataToSend[ACCESS_TO_SERVICE_FIELD], ...oldProfiles],
            };
        }

        // @ts-ignore
        const newDataToSend = cleanUserDataBeforeSending(dataToSend);
        isLoading(true);
        await updateUser(newDataToSend, !!selected.image);
    };

    const resolveAccessServiceProfiles = () => {
        dataProvider
            .getList(PROFILES, {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                sort: {
                    order: '',
                    field: '',
                },
                filter: {
                    internalName: TICKETING_MEMORANDUM_TRUSTEE_INTERNAL_NAME_PROFILE,
                },
            })
            .then((result) => {
                const profileFound = result && 'data' in result && result.data.length > 0 ? result.data[0] : null;
                if (profileFound) {
                    setAccessToService(profileFound.id as string);
                }
            })
            .catch((error: any) => {
                console.log('error', error);
                notify('app.users.errors.getAccessToService', 'warning');
            });
    };

    const updateUser = async (data: any, updateImage?: boolean) => {
        update(
            USERS,
            selected.id,
            {
                ...data,
            },
            selected,
            {
                onSuccess: async (response: UpdateResult) => {
                    if (response && 'data' in response) {
                        updateImage === false && (await addImageToUser());
                        updateImage && (await deleteMedia());
                    }
                    isLoading(false);
                    updateImage && notify('app.users.edit.success', 'success');
                    handleClose();
                    refresh();
                },
                onFailure: (error: any) => {
                    console.error(error);
                    notify('app.users.edit.fail', 'error');
                    isLoading(false);
                },
            }
        );
    };

    const addImageToUser = async () => {
        if (newImageUserLoaded && imageUserWasChanged && newImageUserLoaded.data !== currentMapperMediaSrc?.src) {
            const media: Media | null = await addMedia(extractIdFromURI(selected.id as string) as string);
            if (media) {
                await updateUser({ image: media.id as string });
            } else {
                isLoading(false);
                handleClose();
                refresh();
            }
        }
    };

    const deleteMedia = async () => {
        if (selected.image && imageUserWasChanged && imageId) {
            dataProvider
                .delete(MEDIA, { id: imageId })
                .then(async (result) => {
                    imageId && dispatch(deleteMediaAction.fn(imageId as string));
                    if (newImageUserLoaded !== null) {
                        await addImageToUser();
                    }
                    isLoading(false);
                    handleClose();
                    refresh();
                })
                .catch((error: any) => {
                    console.error(error);
                    isLoading(false);
                    handleClose();
                    refresh();
                });
        }
    };

    const addMedia = (resourceId: string): Promise<Media | null> | null => {
        if (!newImageUserLoaded) return null;
        const data: CreateMedia = {
            resourceSupported: 'user',
            mediaType: 'IMAGE',
            fileName: newImageUserLoaded.name,
            base64data: newImageUserLoaded.data,
            resourceId: resourceId.toString(),
        };
        return dataProvider
            .create(MEDIAS, {
                data: JSON.stringify(data),
            })
            .then((result: CreateResult) => {
                if (result && 'data' in result && result.data) {
                    return result.data as Media;
                }
                isLoading(false);
                return null;
            })
            .catch((error) => {
                console.error('error creating media', error);
                notify('app.media.errorGettingImage', 'error');
                isLoading(false);
                return null;
            });
    };

    const onHandleProfile = (profile: Profile | undefined) => {
        setProfileForCurrentUserTypeSelected(profile);
    };

    const resolveUserType = (): string | undefined => {
        const tags = selected.tags;
        if (!tags) return;
        const isUserProvider =
            tags.includes(TICKETING_USER_SERVICE_PROVIDER_TAG) ||
            tags.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG);
        const isUserASubManager = tags.includes(TICKETING_SUB_MANAGER_TAG);
        const isUserAManager = tags.includes(TICKETING_MANAGER_TAG);
        if (isUserProvider) return USER_SERVICE_PROVIDER_FIELD;
        if (isUserASubManager) return USER_SUB_MANAGER_FIELD;
        if (isUserAManager) return USER_MANAGER_FIELD;
    };

    const resolveServiceProviderType = (): string | undefined => {
        const tags = selected.tags;
        if (!tags || tags.length === 0) return USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD;
        const isUserProviderACompany = tags.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG);
        const isUserProviderAPerson = tags.includes(TICKETING_USER_SERVICE_PROVIDER_TAG);
        if (isUserProviderAPerson) return USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD;
        if (isUserProviderACompany) return USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD;
    };

    const getTagsToKeepForUserSelected = (userTypeValue: string, userTypeOfSelected: string): string[] => {
        let tagsToKeep: string[] = [];
        if (userTypeValue !== userTypeOfSelected && selected?.tags) {
            for (let i = 0; i < selected.tags.length; i++) {
                if (!POSSIBLES_USER_TAGS_ACCORDING_USER_TYPE.includes(selected.tags[i])) {
                    tagsToKeep = [...tagsToKeep, selected.tags[i]];
                }
            }
        }
        return tagsToKeep;
    };

    const getCustomFieldsToKeepForUserSelected = (
        userTypeValue: string,
        userTypeOfSelected: string
    ): { [key: string]: any } | undefined => {
        if (
            userTypeValue !== USER_SERVICE_PROVIDER_TYPE_FIELD &&
            selected &&
            selected?.customFields &&
            Object.keys(selected?.customFields).length > 0
        ) {
            const reference = getTicketingField(
                selected,
                `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD}`
            );
            const serviceType = getTicketingField(
                selected,
                `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}`
            );

            if (reference) {
                // @ts-ignore
                delete selected.customFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD][
                    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD
                ];
            }
            if (serviceType) {
                // @ts-ignore
                delete selected.customFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD][
                    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD
                ];
            }

            if (
                selected?.customFields &&
                TICKETING_CUSTOM_FIELD in selected.customFields &&
                // @ts-ignore
                TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD in selected.customFields[TICKETING_CUSTOM_FIELD]
            ) {
                if (
                    // @ts-ignore
                    Object.keys(selected.customFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD])
                        .length === 0
                ) {
                    // @ts-ignore
                    delete selected.customFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD];
                }
            }
        }
        return selected?.customFields ? selected?.customFields : {};
    };

    const getProfilesIdToKeepForUserSelected = (userTypeValue: string, userTypeOfSelected: string): string[] => {
        let profilesIdToKeep: string[] = [];

        if (userTypeValue !== userTypeOfSelected && profileForCurrentUserTypeSelected) {
            const profilesOfSelected:
                | (Record | undefined)[]
                | undefined = selected.profiles?.map((profileIdOfSelected) =>
                profiles.find((profile) => profile.id === profileIdOfSelected)
            );

            const selectedProfileToRemove =
                profilesOfSelected &&
                profilesOfSelected.find(
                    (profile) =>
                        profile &&
                        userTypeOfSelected in userTypeInteralNameProfileMapper &&
                        userTypeInteralNameProfileMapper[userTypeOfSelected] === (profile as Profile).internalName
                );

            profilesIdToKeep =
                selectedProfileToRemove && selected?.profiles
                    ? selected?.profiles?.filter((profileId) => profileId !== selectedProfileToRemove?.id)
                    : [];
        }

        return profilesIdToKeep;
    };

    let imageId =
        'image' in selected && selected.image && typeof selected.image === 'object' && 'id' in selected.image
            ? selected.image.id
            : null;
    //@ts-ignore
    imageId = !imageId && selected.image && typeof selected.image === 'string' ? selected.image : null;

    const currentMediaInStateByImageId: MediaStateProp | undefined = useSelector((state: CustomReducerState) => {
        if (selected && 'image' in selected && selected?.image && imageId) {
            return getMediaByImagesById(state, [imageId]);
        }
        return undefined;
    });

    useEffect(() => {
        const userType = resolveUserType();
        const userServiceProviderType = resolveServiceProviderType();
        resolveAccessServiceProfiles();
        setInitialValues({
            [USER_TYPE_FIELD]: userType ? userType : '',
            [USER_SERVICE_PROVIDER_TYPE_FIELD]: userServiceProviderType ? userServiceProviderType : '',
            [USER_FIRST_NAME_FIELD]: selected.firstName,
            [USER_LAST_NAME_FIELD]: selected.lastName,
            [USER_USER_NAME_FIELD]: selected.username,
            [USER_EMAIL_FIELD]: selected.email,
            [USER_PHONE_FIELD]: selected.mobile,
            [USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD]:
                selected.tags?.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG) &&
                (selected.tags?.includes(TICKETING_USER_SERVICE_PROVIDER_TAG) ||
                    selected.tags?.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG)),
            [TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD]: getTicketingField(
                selected,
                `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD}`
            ),
            [TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD]: getTicketingField(
                selected,
                `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}`
            ),
        });
    }, []);

    useEffect(() => {
        if (accessToService && initialValues && !(ACCESS_TO_SERVICE_FIELD in initialValues)) {
            const accessToServiceProfilesInUserProfiles = selected.profiles?.find(
                (profileId) => profileId === (accessToService as string)
            );
            accessToServiceProfilesInUserProfiles &&
                setInitialValues({
                    ...initialValues,
                    [ACCESS_TO_SERVICE_FIELD]: accessToService,
                });
        }
    }, [accessToService, initialValues]);

    // Load image
    useEffect(() => {
        if (!currentMediaInStateByImageId && selected.image && !isLoadingImage) {
            // init currentMapperMediaSrc with loading image
            setCurrentMapperMediaSrc({
                media: null,
                src: IMAGE_LOADING,
            });
            setIsLoadingImage(true);
            const userId = extractIdFromURI(selected.id as string);
            imageId && userId && dispatch(resolveMediaAction.fn(imageId, userId));
        } else {
            if (
                currentMediaInStateByImageId?.media &&
                currentMediaInStateByImageId.fileSystemMapper &&
                !isLoadingImage
            ) {
                setCurrentMapperMediaSrc({
                    src: getDownloadFileSystemMapperUrl(
                        currentMediaInStateByImageId.media,
                        currentMediaInStateByImageId.fileSystemMapper
                    ),
                    media: currentMediaInStateByImageId.media,
                });
            }
            setIsLoadingImage(false);
        }
    }, []);

    useEffect(() => {
        if (
            currentMediaInStateByImageId &&
            currentMediaInStateByImageId.media &&
            currentMediaInStateByImageId.fileSystemMapper
        ) {
            setCurrentMapperMediaSrc({
                src: getDownloadFileSystemMapperUrl(
                    currentMediaInStateByImageId.media,
                    currentMediaInStateByImageId.fileSystemMapper
                ),
                media: currentMediaInStateByImageId.media,
            });
            isLoadingImage && setIsLoadingImage(false);
        }
    }, [currentMediaInStateByImageId]);

    const handleImageDataUser = (data: { name: string; data: string } | null) => {
        setNewImageUserLoaded(data);
        setImageUserWasChanged(data?.data !== currentMapperMediaSrc?.src);
    };

    return (
        <DialogTemplate
            title={translate('app.users.edit.title')}
            open={open}
            onClose={handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <Form
                // destroyOnUnregister={true}
                onSubmit={handleSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine }) => {
                    return (
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <UserConfig
                                handleImageDataUser={handleImageDataUser}
                                onHandleProfile={onHandleProfile}
                                onHandleAccessService={(accessService) => setAccessToServiceSelected(accessService)}
                                searchProfile={true}
                                initialUser={selected}
                                initialImageData={
                                    currentMapperMediaSrc
                                        ? {
                                              name: currentMapperMediaSrc?.media?.name
                                                  ? currentMapperMediaSrc?.media?.name
                                                  : '',
                                              data: currentMapperMediaSrc?.src,
                                          }
                                        : undefined
                                }
                                loadingImage={isLoadingImage}
                            />
                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={handleClose}
                                withCreate
                                isValidForm={
                                    !(
                                        loading ||
                                        submitting ||
                                        (pristine &&
                                            !(newImageUserLoaded?.data !== currentMapperMediaSrc?.src) &&
                                            !(accessToServiceSelected !== accessToService))
                                    )
                                }
                                isSubmitting={loading}
                                cancelButtonLabel={translate('app.cancel')}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default EditForm;
