import React, { useEffect, useState } from 'react';
import { Avatar } from '../../../utils/types';
import Form from './form';
import { GetListResult, Record, useDataProvider } from 'react-admin';
import { AVATARS } from '../../../providers/resources';

interface UpdateAvatarProps {
    avatar: Avatar;
    label: string;
    disabled: boolean;
    searchItem?: boolean;
    choices?: any[];
}

const UpdateAvatarEnabled = (props: UpdateAvatarProps) => {
    const dataProvider = useDataProvider();
    const [item, setItem] = useState<Avatar>(props.avatar);

    const [forcedRefresh, setForceRefresh] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const forceRefresh = () => setForceRefresh(forcedRefresh + 1);

    useEffect(() => {
        if (props.searchItem) {
            setLoading(true);
            dataProvider
                .getList(AVATARS, {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {
                        id: item.id,
                    },
                })
                .then((response: GetListResult<Record>) => {
                    if (response && 'data' in response && response.data.length > 0) {
                        setItem(response.data[0] as Avatar);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('Getting avatar', item.id);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [forcedRefresh]);

    return (
        <Form
            label={props.label}
            avatar={item}
            disabled={props.disabled}
            forceRefresh={forceRefresh}
            choices={props.choices}
        />
    );
};

export default UpdateAvatarEnabled;
