import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import DialogTemplate from '../../../../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { Avatar } from '../../../../../../../../utils/types';
import SubcategoryEventTypeDependenciesCreateForm from './SubcategoryEventTypeDependenciesCreateForm';
import { useTranslate } from 'react-admin';

interface Props {
    open: boolean;
    handleClose: () => void;
    handleCancel: () => void;
    avatar: Avatar;
}

const CreateSubcategoryEventTypeDependenciesDialog = ({ open, handleClose, avatar, handleCancel }: Props) => {
    const handleSave = () => {
        handleClose();
    };
    const translate = useTranslate();

    return (
        <DialogTemplate
            title={translate('app.navigation.configuration.incidentByDefault.create.label')}
            open={open}
            onClose={handleClose}
            icon={<SettingsIcon />}
            maxWidth={'xs'}
        >
            <SubcategoryEventTypeDependenciesCreateForm
                avatar={avatar}
                handleSave={handleSave}
                handleCancel={handleCancel}
            />
        </DialogTemplate>
    );
};
export default CreateSubcategoryEventTypeDependenciesDialog;
