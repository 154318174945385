import React, { useEffect, useState } from 'react';
import { MANAGER_AND_SUBMANAGER__PROVIDER_TAGS, USER_PROVIDER_TAGS } from '../../../../utils/CONST';
import { Avatar, FileSystemMapper, User } from '../../../../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import {
    GetListResult,
    Record,
    ReduxState,
    useDataProvider,
    useNotify,
    usePermissions,
    useTranslate,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import {
    AVATAR_RESOURCE_SUPPORTED_MEDIA,
    getDownloadFileSystemMapperUrlWithTokenAccess,
    getFileSystemMapperBlob,
    getFileSystemMappersByResource,
} from '../../../../utils/medias';
import extractIdFromURI, { getResourceURIFROMAnotherURI } from '../../../../utils/id';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { toShortFormatDate } from '../../../../utils/date';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import { MEDIA, USERS } from '../../../../providers/resources';
import Button from '@material-ui/core/Button';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { ROLE_FM_CLOUD_READ_DOWNLOAD, ROLE_FM_CLOUD_REMOVE } from '../../../../utils/ROLES';
import { hasPermissions } from '../../../../utils/permissions';
import { a } from 'react-spring';
import { resolverUserTagNameTranslations } from '../../../../utils/user/tags';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cell: {
        fontWeight: 'bold',
    },
});

interface Props {
    handleClose: () => void;
    open: boolean;
    event: Avatar;
}

interface searchDocByProps {
    tags: string[];
}

interface FileNameData {
    userName: string;
    date: moment.Moment;
    type: string;
}

interface MapperFileAndUser {
    [fileId: string]: {
        user: User;
        file: FileSystemMapper;
    };
}

const ShowDocs = (props: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const dataProvider = useDataProvider();

    const { loaded, permissions } = usePermissions();

    const userConnected: User | null = useSelector(
        (state: CustomReducerState & ReduxState) => state.ticketing.user.userConnected as User
    );

    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );

    const [searchDocBy, setSearchDocBy] = useState<searchDocByProps | null>(null);
    const [files, setFiles] = useState<FileSystemMapper[] | null>(null);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
    const [loadingDownloadFile, setLoadingDownloadFile] = useState<boolean>(false);
    const [loadingDeleteFile, setLoadingDeleteFile] = useState<boolean>(false);
    const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false);
    const [mapperFileAndUser, setMapperFileAndUser] = useState<MapperFileAndUser>({});

    const resolveFilterForGettingDoc = () => {
        const userConnectedTags =
            userConnected && userConnected.tags && userConnected.tags?.length > 0 ? userConnected.tags : [];
        if (
            userConnectedTags &&
            userConnectedTags.find((tag: string) => MANAGER_AND_SUBMANAGER__PROVIDER_TAGS.includes(tag))
        ) {
            setSearchDocBy({
                tags: ['finance'],
            });
        } else if (userConnectedTags && userConnectedTags.find((tag: string) => USER_PROVIDER_TAGS.includes(tag))) {
            setSearchDocBy({
                tags: [`finance:username:${userConnected.username}`],
            });
        }
    };

    useEffect(() => {
        resolveFilterForGettingDoc();
    }, [userConnected]);

    const resolveFilesToShow = async () => {
        setLoadingFiles(true);
        const files: FileSystemMapper[] | null = await getFileSystemMappersByResource(
            AVATAR_RESOURCE_SUPPORTED_MEDIA,
            extractIdFromURI(props.event?.id as string) as string,
            searchDocBy?.tags ? searchDocBy.tags : [],
            tokenWithRoles as string
        );
        files && setFiles(files);
    };

    useEffect(() => {
        if (!tokenWithRoles || !props.event?.id) return;
        if (!files && !loadingFiles && searchDocBy && searchDocBy?.tags?.length > 0) {
            resolveFilesToShow()
                .then(() => setLoadingFiles(false))
                .catch((error) => {
                    console.error('Error getting files', error);
                    notify('app.media.errorGettingFile');
                    setLoadingFiles(false);
                });
        }
    }, [props.event, searchDocBy, tokenWithRoles]);

    // useEffect(() => {
    //     if (Object.values(mapperFileAndUser).length === 0) {
    //         let data: MapperFileAndUser = {};
    //         files?.forEach((file: FileSystemMapper) => {
    //             const userName = getUserNameFromFileName(file);
    //             const userData =
    //                 userName &&
    //                 getUserInformation(userName, file).then((response) => {
    //                     data = {
    //                         ...mapperFileAndUser,
    //                         ...data,
    //                         ...response,
    //                     };
    //                     setMapperFileAndUser(data);
    //                 });
    //         });
    //     }
    // }, [files]);

    const resolveDataOfFile = (file?: FileSystemMapper): FileNameData | null => {
        if (!file) return null;
        const regexp = /(.+)\*_\*(\d+)_([a-zA-Z]+)_(.+)_(.+)/gm;
        // name ex: "73077004e8989dd4969ac018711437e5*_*20221202_quote_user-system.evolistestbya.TICKETING-SERVICE.1_32159"
        // name ex format: YYYYMMDD_<type>_<user’s name>_<event’s code>
        // @ts-ignore
        const fileNameArray = [...file.name.matchAll(regexp)];
        if (fileNameArray.length > 0) {
            if (fileNameArray[0].length > 4) {
                // const fileDate = fileNameArray[1];
                const fileType = fileNameArray[0][3];
                const userNameFile = fileNameArray[0][4];
                return {
                    userName: userNameFile,
                    type: fileType,
                    date: moment(parseInt(file.createdAt) * 1000),
                };
            }
        }
        return null;
    };

    // const getUserNameFromFileName = (file: FileSystemMapper) => {
    //     if (!file) return null;
    //     // name ex: "73077004e8989dd4969ac018711437e5*_*20221202_quote_user-system.evolistestbya.TICKETING-SERVICE.1_32159"
    //     // name ex format: YYYYMMDD_<type>_<user’s name>_<event’s code>
    //     const fileNameArray = file.name.split('_');
    //     if (fileNameArray.length >= 4) {
    //         return fileNameArray[3];
    //     }
    //     return null;
    // };
    //
    // const updateMapperUserAndFileAfterDeletingFile = (fileId: string) => {
    //     if (fileId in mapperFileAndUser) {
    //         delete mapperFileAndUser[fileId];
    //         setMapperFileAndUser({ ...mapperFileAndUser });
    //     }
    // };

    const removeFile = (file: FileSystemMapper) => {
        if (loadingDeleteFile) return;
        const mediaId = file.metadata['metadata.extra']['coreapi.mediaid'];
        const mediaUri = getResourceURIFROMAnotherURI(props.event.id as string, mediaId, MEDIA);
        if (!mediaUri) return;
        setLoadingDeleteFile(true);
        dataProvider
            .delete(MEDIA, {
                id: mediaUri as string,
            })
            .then(() => {
                const newFiles = files;
                const positionFound = newFiles ? newFiles?.findIndex((_file) => file.id === _file.id) : -1;
                console.log('files---positionFound ', positionFound);
                if (newFiles && positionFound >= 0) {
                    delete newFiles[positionFound];
                    // updateMapperUserAndFileAfterDeletingFile(file.id);
                    setFiles([...newFiles]);
                }

                setLoadingDeleteFile(false);
            })
            .catch((error: any) => {
                console.error('Error deleting media ', error);
                notify('app.media.deleted');
                setLoadingDeleteFile(false);
            });
    };

    const renderFileTypeName = (type: string) => {
        switch (type) {
            case 'quote': {
                return translate('app.incidents.information.quote');
            }
            case 'invoice': {
                return translate('app.incidents.information.invoice');
            }
        }
    };

    const downloadFile = (file: FileSystemMapper) => {
        setLoadingDownloadFile(true);
        getFileSystemMapperBlob(
            tokenWithRoles as string,
            file.id,
            (data: Blob) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                if (link) {
                    link.href = url;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    link.click();
                    setLoadingDownloadFile(false);
                } else {
                    setLoadingDownloadFile(false);
                }
            },
            (error: any) => {
                notify('app.media.notDownloaded');
            }
        ).catch((error: any) => {
            console.error(error);
        });
    };

    // const getUserInformation = async (username: string, file: FileSystemMapper): Promise<MapperFileAndUser | null> => {
    //     setLoadingUserInfo(true);
    //     return await dataProvider
    //         .getList(USERS, {
    //             filter: {
    //                 username,
    //             },
    //             pagination: {
    //                 page: 1,
    //                 perPage: 1,
    //             },
    //             sort: {
    //                 order: 'DESC',
    //                 field: 'id',
    //             },
    //         })
    //         .then((response: GetListResult) => {
    //             setLoadingUserInfo(false);
    //             if (response.data && response.data.length == 0) return null;
    //             return {
    //                 [file.id]: { user: response.data[0] as User, file },
    //             };
    //         })
    //         .catch((error: any) => {
    //             notify('app.users.errors.get');
    //             setLoadingUserInfo(false);
    //             console.error(error);
    //             return null;
    //         });
    // };

    // const renderUserTag = (record: Record | undefined) => {
    //     if (!record) return '';
    //     if ((record as User)?.tags) {
    //         //@ts-ignore
    //         const tagNameTranslated = resolverUserTagNameTranslations((record as User).tags);
    //         return <span>{tagNameTranslated ? translate(tagNameTranslated) : ''}</span>;
    //     }
    // };

    return (
        <DialogTemplate
            title={translate('app.incidents.information.invoiceAndQuotes')}
            open={props.open}
            onClose={props.handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <>
                {loadingFiles ? (
                    <LinearProgress color={'primary'} />
                ) : (
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell} align='left'>
                                        {translate('app.download')}
                                    </TableCell>
                                    <TableCell className={classes.cell} align='left'>
                                        {translate('app.delete')}
                                    </TableCell>
                                    <TableCell className={classes.cell} align='left'>
                                        {translate('app.incidents.files.date')}
                                    </TableCell>
                                    <TableCell className={classes.cell} align='left'>
                                        {translate('app.incidents.files.type')}
                                    </TableCell>
                                    <TableCell className={classes.cell} align='left'>
                                        {translate('app.users.username')}
                                    </TableCell>
                                    {/*<TableCell className={classes.cell} align='left'>*/}
                                    {/*    {translate('app.users.lastName')}*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loadingFiles &&
                                    files &&
                                    files.map((file) => {
                                        if (!file) return <span />;
                                        const fileDataFromName: FileNameData | null = resolveDataOfFile(file);
                                        // const userData: User | null =
                                        //     mapperFileAndUser && file.id in mapperFileAndUser
                                        //         ? mapperFileAndUser[file.id].user
                                        //         : null;
                                        return (
                                            <TableRow key={file.id}>
                                                <TableCell align='left' component='th' scope='row'>
                                                    <Button disabled={loadingDownloadFile}>
                                                        {!loadingDownloadFile ? (
                                                            <DownloadIcon
                                                                color={'primary'}
                                                                onClick={() => downloadFile(file)}
                                                            />
                                                        ) : (
                                                            <CircularProgress size={20} />
                                                        )}
                                                    </Button>
                                                </TableCell>
                                                <TableCell align='left' component='th' scope='row'>
                                                    <Button
                                                        disabled={
                                                            loadingDeleteFile &&
                                                            !hasPermissions([ROLE_FM_CLOUD_REMOVE], loaded, permissions)
                                                        }
                                                    >
                                                        {!loadingDeleteFile ? (
                                                            <DeleteIcon
                                                                color={'primary'}
                                                                onClick={() => removeFile(file)}
                                                            />
                                                        ) : (
                                                            <CircularProgress size={20} />
                                                        )}
                                                    </Button>
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {fileDataFromName
                                                        ? toShortFormatDate(fileDataFromName.date.toString())
                                                        : ''}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {fileDataFromName ? renderFileTypeName(fileDataFromName.type) : ''}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {fileDataFromName ? fileDataFromName.userName : '...'}
                                                </TableCell>
                                                {/*<TableCell align='left'>*/}
                                                {/*    {userData ? `${userData.firstName} ${userData.lastName}` : '...'}*/}
                                                {/*</TableCell>*/}

                                                {/*<TableCell align='left'>*/}
                                                {/*    /!*{fileDataFromName ? fileDataFromName.userName : ''}*!/*/}
                                                {/*    {userData ? renderUserTag(userData) : '...'}*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <DialogTemplateButtons
                    outside
                    withCancel
                    onCancelClick={props.handleClose}
                    cancelButtonLabel={translate('app.cancel')}
                />
            </>
        </DialogTemplate>
    );
};

export default ShowDocs;
