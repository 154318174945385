import React, { useEffect, useState } from 'react';
import { ReduxState, useDataProvider, usePermissions } from 'react-admin';
import { METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { FileSystemMapper, Image, MetadataAvatarType } from '../../../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import MediaField from '../media';
import { TAG_TICKETING_LOGO } from '../../../utils/CONST';
import { makeStyles } from '@material-ui/core/styles';
import { ImageStyles } from '../../../theme';
import {
    getFileSystemMappersByResource,
    imagesExistInFileSystemMapper,
    METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
} from '../../../utils/medias';
import extractIdFromURI, { getUriFromItems } from '../../../utils/id';
import { hasPermissions } from '../../../utils/permissions';
import { ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../../utils/ROLES';

interface Props {
    itemId: string;
    rootClassName?: string;
    tags?: string[];
}

const useStyles = makeStyles({
    image: {
        ...ImageStyles,
    },
});
const MetadataAvatarTypeImageField = ({ itemId, rootClassName, tags = [TAG_TICKETING_LOGO] }: Props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );

    const [imageIdFoundInFileSystemMapper, setImageIdFoundInFileSystemMapper] = useState<string | null>(null);

    const metadataAvatarTypeInState:
        | MetadataAvatarType
        | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        itemId in state.admin.resources[METADATA_AVATAR_TYPES].data
            ? (state.admin.resources[METADATA_AVATAR_TYPES].data[itemId] as MetadataAvatarType)
            : undefined
    );

    const resolveMetadataAvatarType = () => {
        dataProvider.getList(
            METADATA_AVATAR_TYPES,
            {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'DESC' },
                filter: { serialization_groups: ['read_meta_files'], id: itemId as string },
            },
            {
                onFailure: (error: any) => {
                    console.error('ERROR getting metadataAvatarType ', itemId, ' error ', error);
                },
            }
        );
    };

    useEffect(() => {
        if (!metadataAvatarTypeInState) {
            resolveMetadataAvatarType();
        }
    }, []);

    useEffect(() => {
        if (metadataAvatarTypeInState) {
            getImagesUriTicketingLogoExisted(
                metadataAvatarTypeInState.images,
                extractIdFromURI(metadataAvatarTypeInState.id as string)
            )
                .then((imagesFound) => {
                    if (imagesFound.length > 0) {
                        setImageIdFoundInFileSystemMapper(imagesFound[0]);
                    }
                })
                .catch((error: any) => {
                    console.error('ERROR getImagesUriTicketingLogoExisted', error);
                });
        }
    }, [metadataAvatarTypeInState, loadedPermissions, permissions]);

    const getImagesUriTicketingLogoExisted = async (
        images: string[] | Image[],
        resourceRecordId: string | null
    ): Promise<string[] | []> => {
        if (!resourceRecordId) return [];
        let fileSystemMappers: FileSystemMapper[] | null = null;
        if (!hasPermissions([ROLE_FM_CLOUD_READ], loadedPermissions, permissions)) return [];

        fileSystemMappers = await getFileSystemMappersByResource(
            METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
            resourceRecordId,
            tags,
            tokenWithRoles as string
        );
        return fileSystemMappers ? imagesExistInFileSystemMapper(getUriFromItems(images), fileSystemMappers) : [];
    };

    return (
        <div className={rootClassName ? rootClassName : ''}>
            {metadataAvatarTypeInState &&
            imageIdFoundInFileSystemMapper &&
            hasPermissions([ROLE_FM_CLOUD_READ_DOWNLOAD], loadedPermissions, permissions) ? (
                <MediaField
                    imageId={imageIdFoundInFileSystemMapper}
                    resourceId={metadataAvatarTypeInState.id}
                    tags={tags}
                />
            ) : (
                <img src={'/tmp-image.png'} className={classes.image} alt='' />
            )}
        </div>
    );
};

export default MetadataAvatarTypeImageField;
