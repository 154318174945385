import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AVATARS_CLONE } from '../../../../providers/resources';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';
import { useActionsContext } from '../../../../commons/tree/useActionsContext';

interface Props {
    onClose: () => void;
    onCancel: () => void;
}

const CloneItemDialog = ({ onClose, onCancel }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const { cloneTo, itemToClone } = useActionsContext();

    const [loadingMove, setLoadingMove] = useState(false);

    const dataProvider = useDataProvider();

    const handleOkClick = () => {
        if (cloneTo && itemToClone) {
            setLoadingMove(true);

            const dataToSend = {
                clonePlace: itemToClone.id,
                ...(itemToClone && cloneTo && itemToClone['@id'] !== cloneTo['@id'] ? { intoPlace: cloneTo.id } : {}),
            };

            dataProvider
                .create(AVATARS_CLONE, { data: JSON.stringify(dataToSend) })
                .then(() => {
                    setLoadingMove(false);

                    if (itemToClone && cloneTo && itemToClone['@id'] !== cloneTo['@id']) {
                        queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(cloneTo.id as string)}`]);
                    } else {
                        queryClient.invalidateQueries([`rootNavigationAvatars`]);
                    }

                    onClose();
                })
                .catch((error: any) => {
                    setLoadingMove(false);
                    notify('app.avatar.updated.error', 'error');
                    onClose();
                });
        }
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.cloneDialog.title')}
            open={true}
            onClose={() => null}
            icon={<SettingsIcon />}
            maxWidth={'xs'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onCancel}
                    withDelete
                    onDeleteClick={handleOkClick}
                    deleteButtonLabel={translate('app.buttons.validate')}
                    isValidForm={true}
                    isSubmitting={loadingMove}
                />
            }
        >
            <Grid container direction={'column'}>
                <Grid item>
                    <Typography>
                        {translate('app.navigation.cloneDialog.item')}: <b>{itemToClone ? itemToClone.name : '...'}</b>
                    </Typography>
                </Grid>
                {itemToClone && cloneTo && itemToClone['@id'] !== cloneTo['@id'] && (
                    <Grid item>
                        <Typography>
                            {translate('app.navigation.cloneDialog.moveTo')}: <b>{cloneTo ? cloneTo.name : '...'}</b>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </DialogTemplate>
    );
};

export default CloneItemDialog;
