import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC } from 'react';
import { useLoading } from 'react-admin';
import AppBar from '../header/appBar';

const useStyles = makeStyles(() => ({
    main: {
        padding: '75px 10px 0 10px',
    },
    loader: {
        position: 'fixed',
        top: '2px',
        right: '0',
        margin: 16,
        marginRight: '3px',
        zIndex: 1200,
    },
}));
const LayoutHeader: FC = (props) => {
    const classes = useStyles();
    const loading = useLoading();

    return (
        <div className={classes.main}>
            <AppBar />
            {props.children}
            {loading && (
                <CircularProgress style={{ color: 'white' }} size={30} thickness={2} className={classes.loader} />
            )}
        </div>
    );
};

export default LayoutHeader;
