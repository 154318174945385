import React from 'react';
import { Datagrid, FunctionField, Identifier, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import { rowStyle } from '../../../utils/rowListStyles';
import { GroupMetadataAvatarType, MetadataAvatarType } from '../../../utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { getNameWithoutPrefixFromJson } from '../../../utils/resources/GroupMetadataAvatarType';
import makeStyles from '@material-ui/styles/makeStyles';
import { FieldCapitalizeListStyles, FieldHeaderListStyles, ImageStyles } from '../../../theme';
import { getSubCategorySelected } from '../../../redux/reducers/subCategories';
import { selectSubCategory } from '../../../redux/actions/subCategories';
import ReferenceField from '../../../commons/ra/field/ReferenceField';
import { ROLE_READ_GROUP_OBJECT_TYPE } from '../../../utils/ROLES';
import { hasPermissions } from '../../../utils/permissions';
import ForceMetadataAvatarTypePropertiesField from '../../incidentTypes/field/ForceMetadataAvatarTypePropertiesField';
import Typography from '@material-ui/core/Typography';
import { TICKETING_FILESYSTEM_MAPPER_ENABLED } from '../../../utils/metadataAvatarType';
import ShowMediaResolver from '../../../commons/fields/showMediaResolver/showMediaResolver';

const useStyles = makeStyles({
    // @ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
        ...FieldCapitalizeListStyles,
    },
    image: {
        ...ImageStyles,
    },
});
const IncidentTypesDataGrip = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();

    const { loaded: loadedPermissions, permissions } = usePermissions();

    const selected: MetadataAvatarType | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getSubCategorySelected(state)
    );

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectSubCategory.fn(record as MetadataAvatarType));
        // @ts-ignore
        return Promise.resolve(undefined);
    };

    return (
        <Datagrid rowClick={onClick} rowStyle={(record, index) => rowStyle(record, index, selected)}>
            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.incidentTypes.name'}
                render={(record: Record | undefined) => {
                    return (
                        <ShowMediaResolver
                            itemId={record ? record['@id'] : ''}
                            defaultIcon={
                                <img
                                    src={'/tmp-image.png'}
                                    className={classes.image}
                                    alt=''
                                    style={{ margin: '0 0 -5px 0', height: 25, width: 25 }}
                                />
                            }
                        />
                    );
                }}
            />

            {hasPermissions([ROLE_READ_GROUP_OBJECT_TYPE], loadedPermissions, permissions) && (
                <ReferenceField
                    headerClassName={classes.fieldHeader}
                    label={translate('app.subCategory.category')}
                    reference={GROUP_METADATA_AVATAR_TYPES}
                    source='groupMetadataAvatarType'
                    // linkType={false}
                    sortable={false}
                    link={false}
                >
                    <FunctionField
                        render={(record: Record | undefined) =>
                            record
                                ? capitalizeFirstLetter(getNameWithoutPrefixFromJson(record as GroupMetadataAvatarType))
                                : ''
                        }
                    />
                </ReferenceField>
            )}

            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.subCategory.fileSystemMapperEnabled'}
                render={(record: Record | undefined) => {
                    const incidentType = record ? (record as MetadataAvatarType) : null;
                    return incidentType ? (
                        <ForceMetadataAvatarTypePropertiesField
                            metadataAvatarType={incidentType}
                            propertyName={TICKETING_FILESYSTEM_MAPPER_ENABLED}
                        />
                    ) : (
                        <Typography>{'...'}</Typography>
                    );
                }}
            />
        </Datagrid>
    );
};

export default IncidentTypesDataGrip;
