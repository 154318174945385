import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment/moment';
import React, { useState } from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    CreateResult,
    Record,
    ReduxState,
    required,
    TextInput,
    useCreate,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import useCreateLanguage from '../../../../hooks/useCreateLanguages';
import {
    GROUP_METADATA_AVATAR_TYPES,
    IMAGES,
    LANGUAGES,
    MEDIA_LINKS_FOR_CREATE,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
} from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL, TAG_TICKETING_LOGO } from '../../../../utils/CONST';
import extractIdFromURI, { getURIFROMAnotherURI, getUriFromItems } from '../../../../utils/id';

import { saveMedia } from '../../../../utils/medias';
import {
    CATEGORY_FIELD,
    CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER,
    CUSTOM_FIELDS_ICON_SVG_COLLECTION,
    ICON_FIELD,
    ICON_SVG_COLLECTION_FIELD,
    IMAGE_FIELD,
    NAME_FIELD,
    SVG_ICON_SYSTEM,
    TICKETING_FILESYSTEM_MAPPER_ENABLED,
    TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD,
} from '../../../../utils/metadataAvatarType';
import {
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE,
    eventTypeCustomProcessFileSystemMapperEnabledLanguages,
} from '../../../../utils/properties/metadataAvatarTypeProperties';
import { Language, Media, MetadataAvatarType, MetadataAvatarTypePropertyDatum, User } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import CreateCategoryFromAutocompleteInput from '../../../categoriesLevel/actions/create/CreateFromAutoCompleteInput';
import ImageIconSelect from '../../../image-icon-select/image-icon-select';

const collection = process.env.REACT_APP_ICONS_COLLECTION;

interface CreateFormProps {
    open: boolean;
    fromExternalInput?: boolean;
    handleClose: () => void;
    handleCreateOnSuccess?: (data: Record) => void;
    initialName?: string;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const CreateForm = ({ open, handleClose, fromExternalInput, handleCreateOnSuccess, initialName }: CreateFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [create, { loading: loadingCreate }] = useCreate();
    const [update, { loading: loadingUpdate }] = useUpdate();
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );

    const [createMetadataAvatarType, { loading }] = useMutation({
        resource: METADATA_AVATAR_TYPES,
        type: 'create',
        payload: { data: {} },
    });

    const [updateMetadataAvatarType, { loading: updatingMetadataAvatarType }] = useUpdate(METADATA_AVATAR_TYPES);
    const [createMetadataAvatarTypeProperty, { loading: loadingCreateMetadataAvatarTypeProperty }] = useMutation({
        resource: METADATA_AVATAR_TYPE_PROPERTIES,
        type: 'create',
        payload: { data: {} },
    });
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const [savingMedia, setSavingMedia] = useState(false);

    /**Create and get languages*/
    for (const key of Object.keys(eventTypeCustomProcessFileSystemMapperEnabledLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessFileSystemMapperEnabledLanguages[key]);
    }

    /**Get properties from state*/
    const getLanguagesFromState = (propertyLanguages: { en: string; fr: string; es: string }) => {
        const languagesData = useSelector((state: ReduxState) => state.admin.resources[LANGUAGES].data);
        return Object.values(languagesData)
            .map((item: Record) => {
                for (const key of Object.keys(propertyLanguages)) {
                    if (
                        (item as Language).twoLetters.toLowerCase().trim() === key.toLowerCase().trim() &&
                        (item as Language).value.toLowerCase().trim() ===
                            // @ts-ignore
                            propertyLanguages[key].toLowerCase().trim()
                    ) {
                        return (item as Language).id;
                    }
                }
            })
            .filter((item) => item !== undefined);
    };

    const eventTypeCustomProcessFileSystemMapperEnabledLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessFileSystemMapperEnabledLanguages
    );

    const resolveImageAndUpdateResource = async (
        fileData: string,
        fileName: string,
        metadataAvatarType: MetadataAvatarType
    ) => {
        if (!tokenWithRoles || !metadataAvatarType) return;
        setSavingMedia(true);

        const metadataAvatarTypeCompanyId =
            typeof metadataAvatarType.company === 'object' ? metadataAvatarType.company.id : metadataAvatarType.company;
        const resourceId = extractIdFromURI(metadataAvatarType.id as string);
        const imageAdded: Media | undefined = resourceId
            ? await saveMedia(fileData, fileName, metadataAvatarTypeCompanyId as string, resourceId, tokenWithRoles, [
                  TAG_TICKETING_LOGO,
              ])
            : undefined;

        setSavingMedia(false);

        if (imageAdded && imageAdded?.id) {
            const imageUri = `${getURIFROMAnotherURI(metadataAvatarType.id as string)}/${IMAGES}/${imageAdded.id}`;
            updateMetadataAvatarType(
                METADATA_AVATAR_TYPES,
                metadataAvatarType.id as string,
                {
                    images: [...getUriFromItems(metadataAvatarType.images), imageUri],
                },
                metadataAvatarType,
                {
                    onSuccess: () => {
                        // if (!fromExternalInput) {
                        //     finishSubmit();
                        // }
                    },
                    onFailure: () => {
                        notify('app.subCategory.edit.error', 'warning');
                        console.error('ERROR editing', METADATA_AVATAR_TYPES, 'adding media ', imageAdded?.id);
                    },
                }
            );
        } else {
            console.error('The image can not be added, check the token send in the request');
            notify('app.messages.imageNotAdded');
        }
    };

    const finishSubmit = () => {
        handleClose();
        refresh();
    };

    const handleCreateIconMedia = (data: any, onSuccessCallback: any) => {
        create(MEDIA_LINKS_FOR_CREATE, data, {
            onSuccess: ({ data }: CreateResult) => {
                onSuccessCallback(data);
            },
            onFailure: (error) => {
                console.log('error', error);
                notify('app.subCategory.create.errors.iconNotAdded', 'error');
            },
        });
    };

    const addMetadataAvatarTypeProperty = (data: MetadataAvatarTypePropertyDatum) => {
        createMetadataAvatarTypeProperty(
            {
                payload: {
                    data: {
                        metadataAvatarType: data.metadataAvatarType,
                        name: data.name,
                        values: data.values,
                        fieldType: data.fieldType,
                        languages: data.languages,
                        private: false,
                        option: data.option,
                        migrateToAvatar: data.migrateToAvatar,
                    },
                },
            },
            {
                onFailure: (error: any) => {
                    console.error('CREATING METADATA AVATAR TYPE PROPERTY', error);
                    notify('app.messages.createForceMetadataAvatarTypePropertyError', 'error', {
                        propertyName:
                            data.name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                                ? translate('app.incidentTypes.create.input.forceImage')
                                : translate('app.incidentTypes.create.input.forceText'),
                    });
                },
            }
        );
    };

    const handleUpdateMetadataAvatarType = (
        metadataAvatarTypeId: string,
        data: any,
        previousData: MetadataAvatarType,
        onSuccessCallback: any
    ) => {
        update(
            METADATA_AVATAR_TYPES,
            metadataAvatarTypeId,
            data,
            {},
            {
                onSuccess: () => {
                    onSuccessCallback();
                },
                onFailure: (error) => {
                    console.log('error', error);
                    notify('app.subCategory.create.errors.subcategoryNotUpdated', 'error');
                },
            }
        );
    };

    const handleSubmit = (formValues: {
        [NAME_FIELD]: string;
        [CATEGORY_FIELD]: string;
        [IMAGE_FIELD]: { fileName: string; fileData: string; cropping?: boolean };
        [TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD]: boolean;
        [ICON_SVG_COLLECTION_FIELD]: boolean;
        [ICON_FIELD]: string;
    }) => {
        const currentIconNameValue = ICON_FIELD in formValues ? formValues[ICON_FIELD] : null;

        createMetadataAvatarType(
            {
                payload: {
                    data: {
                        name: formValues[NAME_FIELD],
                        groupMetadataAvatarType: formValues[CATEGORY_FIELD],
                        alphaId: moment().toString(),
                        customFields: {
                            [CUSTOM_FIELDS_ICON_SVG_COLLECTION]: formValues[ICON_SVG_COLLECTION_FIELD],
                            [CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER]: !formValues[ICON_SVG_COLLECTION_FIELD],
                        },
                    },
                },
            },
            {
                onSuccess: async (response: CreateResult<Record>) => {
                    const data: any = response && 'data' in response ? response.data : undefined;
                    data && handleCreateOnSuccess && handleCreateOnSuccess(data);

                    notify('app.subCategory.create.notification', 'success');

                    addMetadataAvatarTypeProperty({
                        name: TICKETING_FILESYSTEM_MAPPER_ENABLED,
                        metadataAvatarType: (data as MetadataAvatarType).id as string,
                        values: [formValues[TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD] ? 'true' : 'false'],
                        fieldType: 'boolean',
                        option: 'value',
                        languages: eventTypeCustomProcessFileSystemMapperEnabledLanguagesInState,
                        migrateToAvatar: true,
                    });

                    if (formValues[ICON_SVG_COLLECTION_FIELD]) {
                        if (currentIconNameValue) {
                            handleCreateIconMedia(
                                JSON.stringify({
                                    fileType: 'svg',
                                    name: currentIconNameValue.split('.')[0],
                                    customFields: {
                                        collection,
                                        type: TAG_TICKETING_LOGO,
                                    },
                                    destinationCompany: userConnected?.company['@id'],
                                    source: SVG_ICON_SYSTEM,
                                }),
                                (createdIcon: any) => {
                                    handleUpdateMetadataAvatarType(
                                        data.id,
                                        {
                                            medias: [...getUriFromItems(data.medias), createdIcon['@id']],
                                        },
                                        data,
                                        () => {
                                            if (!fromExternalInput) {
                                                finishSubmit();
                                            }
                                        }
                                    );
                                }
                            );
                        } else {
                            if (!fromExternalInput) {
                                finishSubmit();
                            }
                        }
                    } else {
                        if (!formValues[IMAGE_FIELD]) {
                            if (!fromExternalInput) {
                                finishSubmit();
                            }
                        } else {
                            data &&
                                (await resolveImageAndUpdateResource(
                                    formValues[IMAGE_FIELD].fileData,
                                    formValues[IMAGE_FIELD].fileName,
                                    data as MetadataAvatarType
                                ));
                            finishSubmit();
                        }
                    }
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.subCategory.create.error', 'warning');
                    }
                },
            }
        );
    };

    return (
        <DialogTemplate
            title={translate('app.subCategory.create.title')}
            open={open}
            onClose={handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <Form
                onSubmit={handleSubmit}
                initialValues={{
                    [NAME_FIELD]: fromExternalInput && initialName ? initialName : '',
                    [ICON_SVG_COLLECTION_FIELD]: true,
                    [TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD]: true,
                }}
                render={({ handleSubmit, submitting, pristine, valid, values, form: { change } }) => {
                    return (
                        <Grid container>
                            <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                <Grid item xs>
                                    <TextInput
                                        autoFocus
                                        variant={'standard'}
                                        required
                                        key={NAME_FIELD}
                                        fullWidth // create={root ? <CreateCategoryFromAutocompleteInput /> : undefined}
                                        type={'text'}
                                        label={translate('app.subCategory.create.input.name')}
                                        source={NAME_FIELD}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <ReferenceInput
                                        isRequired={true}
                                        validate={[required()]}
                                        variant={'standard'}
                                        label={translate('app.subCategory.categories')}
                                        source={CATEGORY_FIELD}
                                        reference={GROUP_METADATA_AVATAR_TYPES}
                                        filterToQuery={(searchText: string) => ({
                                            name: searchText,
                                        })}
                                        filter={{
                                            alias: GROUP_METADATA_AVATAR_TYPE_LEVEL,
                                            hidden: false,
                                            order__createdAt: 'DESC',
                                        }}
                                    >
                                        <AutocompleteInput
                                            resettable
                                            create={<CreateCategoryFromAutocompleteInput />}
                                            translateChoice={false}
                                            filterToQuery={(searchText: string) => ({
                                                name: searchText,
                                            })}
                                            fullWidth
                                            clearAlwaysVisible
                                            optionText={'name'}
                                            optionValue='@id'
                                        />
                                    </ReferenceInput>
                                </Grid>

                                <Grid item>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.subCategory.create.input.fileSystemMapperEnabled')}
                                            </Typography>
                                        }
                                        source={TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD}
                                    />
                                </Grid>

                                <ImageIconSelect
                                    values={values}
                                    iconOrImageActive={values[ICON_SVG_COLLECTION_FIELD]}
                                    changeIconType={change}
                                    name={IMAGE_FIELD}
                                    accept='image/*'
                                />

                                {/*<Grid item>*/}
                                {/*    <IconsSelectDialog open={true} onClose={handleIconsSelectClose} />*/}
                                {/*</Grid>*/}

                                <DialogTemplateButtons
                                    outside
                                    withCancel
                                    onCancelClick={handleClose}
                                    withCreate
                                    isSubmitting={
                                        loadingUpdate ||
                                        loadingCreate ||
                                        loading ||
                                        savingMedia ||
                                        updatingMetadataAvatarType
                                    }
                                    isValidForm={valid && !pristine && !values[IMAGE_FIELD]?.cropping && !submitting}
                                    cancelButtonLabel={translate('app.cancel')}
                                    createButtonLabel={translate('app.valid')}
                                />
                            </form>
                        </Grid>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default CreateForm;
