import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { index, login } from './paths';
import { useTranslate } from 'react-admin';
import RootPage from './RootPage';
import LoginPage from './LoginPage';
import useApiSchema from '../hooks/useApiSchema';
// import '../styles.css';

const MainPage = () => {
    const translate = useTranslate();

    const isApiSchemaLoading = useApiSchema();

    useEffect(() => {
        document.title = translate('app.name');
    }, []);

    return (
        <>
            {!isApiSchemaLoading && (
                <Switch>
                    <Route
                        exact
                        path='/'
                        render={() => (
                            <Redirect
                                to={{
                                    pathname: `/${login}`,
                                }}
                            />
                        )}
                    />

                    <Route exact path={`/${login}`} render={(props: any) => <LoginPage {...props} />} />
                    <Route path={'/'} render={() => <RootPage />} />
                </Switch>
            )}
        </>
    );
};

export default MainPage;
