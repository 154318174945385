import { Record } from 'react-admin';
import { GroupMetadataAvatarType } from '../../../utils/types';

export interface SelectCategoriesAction {
    type: typeof selectCategory.action;
    payload: {
        record: Record | GroupMetadataAvatarType | null;
    };
}

export const selectCategory = {
    action: '360SC_TICKETING/SELECT_CATEGORY',
    fn: (record: Record | null): SelectCategoriesAction => ({
        type: selectCategory.action,
        payload: {
            record: record,
        },
    }),
};
