import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { cloneElement, ReactElement, useEffect, useState } from 'react';
import { Button, usePermissions, useTranslate } from 'react-admin';
import { ButtonFontWeightBoldStyles } from '../../theme';

const CustomIconButton = styled(IconButton)({
    padding: 0,
});

const useStyles = makeStyles(() => ({
    fontWeightBold: {
        fontWeight: 700,
    },
    listIconItem: {
        minWidth: 30,
    },
    icons: {
        marginRight: 5,
        fontSize: 16,
    },
    cardActions: {
        width: '100%',
        marginLeft: -20,
        marginTop: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        zIndex: 1,
    },
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        // marginRight: 5,
        minWidth: '25px !important',
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
    },
    menuText: {
        fontSize: 13,
        fontWeight: 'bold',
    },
    multiSelectLabel: {
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
        fontSize: 13,
    },
    buttonFontWeightBold: {
        ...ButtonFontWeightBoldStyles,
    },
}));

export interface MenuItemType {
    icon: any;
    text: string;
    onClick?: (e: any) => void;
    disabled?: boolean | (() => boolean);
    renderComponent?: () => any;
    dataTestSC?: string;
    roles: string[];
}
interface MenuButtonActionsProps {
    disabled?: boolean;
    onButtonClick: () => void;
    menuState: boolean;
    buttonLabel: string;
    overwriteButtonLabel?: boolean;
    menuActions: MenuItemType[];
    buttonProps?: any;
    startIcon?: ReactElement;
    justIcon?: boolean;
    popperZIndexOverride?: number;
}
const MenuButtonActions = ({
    onButtonClick,
    menuState,
    menuActions,
    startIcon,
    justIcon,
    buttonProps,
    popperZIndexOverride,
    buttonLabel = 'app.menuActions.title',
}: MenuButtonActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const translate = useTranslate();
    const [menuActionsAuthorized, setMenuActionsAuthorized] = useState<MenuItemType[]>([]);
    const { loaded, permissions } = usePermissions();

    useEffect(() => {
        if (loaded && permissions && menuActions && Array.isArray(permissions)) {
            setMenuActionsAuthorized([
                ...menuActions.filter((menuAction) => {
                    return menuAction.roles.every((role) => permissions.includes(role));
                }),
            ]);
        }
    }, [loaded, permissions, menuActions]);

    return (
        <>
            {justIcon ? (
                <CustomIconButton
                    color={'primary'}
                    onClick={(event) => {
                        event.preventDefault();
                        const { currentTarget } = event;
                        onButtonClick();
                        // @ts-ignore
                        setAnchorEl(currentTarget);
                    }}
                    {...buttonProps}
                >
                    {startIcon}
                </CustomIconButton>
            ) : (
                <Button
                    className={classes.buttonFontWeightBold}
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={(event) => {
                        event.preventDefault();
                        const { currentTarget } = event;
                        onButtonClick();
                        // @ts-ignore
                        setAnchorEl(currentTarget);
                    }}
                    {...buttonProps}
                    label={translate(buttonLabel)}
                    startIcon={startIcon ? startIcon : <MoreVertIcon />}
                />
            )}

            <Popper
                open={menuState}
                anchorEl={anchorEl}
                transition
                placement={'bottom-start'}
                style={popperZIndexOverride ? { zIndex: popperZIndexOverride } : { zIndex: 10 }}
                data-test-sc='menuButtonActionsPopper'
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener
                                onClickAway={() => {
                                    onButtonClick();
                                }}
                            >
                                <MenuList
                                    className={classes.menuListRoot}
                                    data-test-sc='menuButtonActionsMenuList'
                                    data-testid='menuButtonActionsMenuList'
                                >
                                    {menuActionsAuthorized.map((item: MenuItemType, index) =>
                                        !('renderComponent' in item) ? (
                                            <MenuItem
                                                key={index}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    item.onClick && item.onClick(e);
                                                    onButtonClick();
                                                }}
                                                className={classes.menuRoot}
                                                disabled={
                                                    typeof item.disabled === 'function'
                                                        ? item.disabled()
                                                        : item.disabled
                                                }
                                                data-test-sc={item?.dataTestSC ? item.dataTestSC : 'menuItem'}
                                            >
                                                {item?.icon && (
                                                    <ListItemIcon className={classes.menuListItemRoot}>
                                                        {cloneElement(item.icon, {
                                                            ...item.icon.props,
                                                            color: 'primary',
                                                            className: classes.menuIcon,
                                                        })}
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText
                                                    primary={item.text}
                                                    className={classes.menuTextRoot}
                                                    primaryTypographyProps={{
                                                        className: classes.menuText,
                                                        color: 'primary',
                                                    }}
                                                    data-test-sc={
                                                        item?.dataTestSC ? `${item.dataTestSC}Text` : 'menuItemText'
                                                    }
                                                />
                                            </MenuItem>
                                        ) : item?.renderComponent ? (
                                            item.renderComponent()
                                        ) : (
                                            <span />
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default MenuButtonActions;
