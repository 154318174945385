import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutHeader from '../commons/layout/layoutHeader';
import CategoriesLevelPage from './CategoriesLevelPage';
import IncidentPage from './IncidentsPage';
import IncidentTypesPage from './IncidentTypesPage';
import NavigationPage from './NavigationPage';
import {
    categoriesEvent,
    categoriesLevel,
    incidents,
    incidentTypes,
    index,
    navigation,
    statistics,
    subCategories,
    users,
} from './paths';
import RefreshTokenPage from './RefreshTokenPage';
import StatisticsPage from './StatisticsPage';
import SubCategoryPage from './SubCategoryPage';
import useApiSchema from '../hooks/useApiSchema';
import CategoriesEventPage from './CategoriesEventPage';
import UserPage from './UserPage';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import { Company, User } from '../utils/types';
// import { isUserAServiceProvider } from '../utils/user/tags';

const RootPage = () => {
    const isApiSchemaLoading = useApiSchema();

    return (
        <LayoutHeader>
            {(!isApiSchemaLoading && (
                <Switch>
                    <Route exact path={index} component={(props: any) => <RefreshTokenPage {...props} />} />

                    <Route exact path={navigation} component={(props: any) => <NavigationPage {...props} />} />

                    <Route exact path={incidentTypes} component={(props: any) => <IncidentTypesPage {...props} />} />

                    <Route
                        exact
                        path={categoriesLevel}
                        component={(props: any) => <CategoriesLevelPage {...props} />}
                    />

                    <Route
                        exact
                        path={categoriesEvent}
                        component={(props: any) => <CategoriesEventPage {...props} />}
                    />

                    <Route exact path={subCategories} component={(props: any) => <SubCategoryPage {...props} />} />

                    <Route exact path={statistics} component={(props: any) => <StatisticsPage {...props} />} />

                    <Route exact path={users} component={(props: any) => <UserPage {...props} />} />

                    <Route exact path={incidents} component={(props: any) => <IncidentPage {...props} />} />
                </Switch>
            )) || <></>}
        </LayoutHeader>
    );
};

export default RootPage;
