import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import DialogTemplate from '../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ShowIconContainer from './show-icon-container';
import { useField } from 'react-final-form';

const iconsOriginMapper = `${process.env.REACT_APP_ICONS_URL}/json/mapper.json`;
const collection = process.env.REACT_APP_ICONS_COLLECTION;

const RootGrid = styled(Grid)({
    position: 'relative',
    width: '100%',
});

const ButtonsDiv = styled('div')({
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'flex',
});

interface Props {
    onClose?: any;
    iconNameValue: any;
    changeIconType?: any;
    setIconOpenAction: any;
}

const IconsSelectDialog = ({ onClose, iconNameValue, setIconOpenAction }: Props) => {
    const [icons, setIcons] = useState([]);
    const [selectedIconName, setSelectedIconName] = useState<null | string>(null);
    const [dialogState, setDialogState] = useState(false);
    const translate = useTranslate();
    const { input } = useField('icon');

    const initFn = () => () => {
        handleDialogOpen();
    };

    useEffect(() => {
        setIconOpenAction(initFn);
    }, []);

    const getIconsMapper = () => {
        axios.get(iconsOriginMapper).then(({ data }) => {
            const index = data.collections.findIndex(
                (item: { collection: string; icons: string[] }) => item.collection === collection
            );
            if (index !== -1) {
                setIcons(data.collections[index].icons);
            }
        });
    };

    const handleDialogOpen = () => {
        setDialogState(true);
    };

    const handleDialogClose = () => {
        setDialogState(false);
    };

    useEffect(() => {
        getIconsMapper();
    }, []);

    const handleClose = () => {
        handleDialogClose();
    };

    const handleSelect = (iconName: string) => {
        setSelectedIconName(iconName);
    };

    const handleOkClick = () => {
        input.onChange(selectedIconName);
        handleDialogClose();
    };

    const handleDeleteIcon = (e: any) => {
        e.stopPropagation();
        input.onChange(undefined);
    };

    return (
        <Fragment>
            <RootGrid container alignItems={'center'}>
                <ShowIconContainer iconName={iconNameValue} onlyShow onClick={handleDialogOpen} />

                <ButtonsDiv>
                    {iconNameValue && iconNameValue !== '' && (
                        <Fragment>
                            <IconButton color={'primary'} onClick={handleDialogOpen}>
                                <EditIcon />
                            </IconButton>

                            <IconButton color={'primary'} onClick={handleDeleteIcon}>
                                <DeleteIcon />
                            </IconButton>
                        </Fragment>
                    )}
                </ButtonsDiv>
            </RootGrid>

            <DialogTemplate
                title={translate('app.selectIcon.select')}
                open={dialogState}
                onClose={onClose}
                maxWidth={'md'}
            >
                <Grid container spacing={1}>
                    {icons.map((iconName) => (
                        <Grid item key={iconName}>
                            <ShowIconContainer
                                iconName={iconName}
                                selectedIconName={selectedIconName}
                                onSelect={handleSelect}
                            />
                        </Grid>
                    ))}
                </Grid>

                <DialogTemplateButtons
                    outside
                    withCancel
                    onCancelClick={handleClose}
                    withDelete
                    onDeleteClick={handleOkClick}
                    deleteButtonLabel={translate('app.buttons.valid')}
                    isValidForm={!!selectedIconName}
                />
            </DialogTemplate>
        </Fragment>
    );
};

export default IconsSelectDialog;
