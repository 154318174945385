import { CreateUser } from '../types';
import {
    ACCESS_TO_SERVICE_FIELD,
    FormCreateUserData,
    FormEditUserData,
    USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD,
    USER_MANAGER_FIELD,
    USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD,
    USER_SERVICE_PROVIDER_FIELD,
    USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD,
    USER_SERVICE_PROVIDER_TYPE_FIELD,
    USER_SUB_MANAGER_FIELD,
    USER_TYPE_FIELD,
} from '../../components/users/actions/CONST';
import {
    TICKETING_MANAGER_TAG,
    TICKETING_SUB_MANAGER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
} from '../CONST';

import {
    TICKETING_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from './customFields';

export const createUser = async (token: string | undefined, userData: CreateUser): Promise<any | null> => {
    if (!token) return null;
    const url = `${process.env.REACT_APP_API_ENTRYPOINT}/v2/users`;
    const body = {
        ...userData,
    };

    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((response: Response) => {
        if (response.ok && response.status === 201) {
            return response.json();
        }
        return null;
    });
};

export const resolveTags = (formValues: CreateUser & (FormCreateUserData | FormEditUserData)) => {
    const userTypeValue = USER_TYPE_FIELD in formValues ? formValues[USER_TYPE_FIELD] : null;
    const userServiceProviderTypeValue =
        USER_SERVICE_PROVIDER_TYPE_FIELD in formValues ? formValues[USER_SERVICE_PROVIDER_TYPE_FIELD] : null;
    const userIsUnderContract =
        USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD in formValues
            ? formValues[USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD]
            : null;
    let tags: string[] = [];

    if (userTypeValue === USER_SERVICE_PROVIDER_FIELD) {
        if (userServiceProviderTypeValue === USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD) {
            tags = [TICKETING_USER_SERVICE_PROVIDER_TAG];
        }
        if (userServiceProviderTypeValue === USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD) {
            tags = [TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG];
        }
        if (userIsUnderContract) {
            tags = [...tags, TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG];
        }
    } else if (userTypeValue === USER_MANAGER_FIELD) {
        tags = [TICKETING_MANAGER_TAG];
    } else if (userTypeValue === USER_SUB_MANAGER_FIELD) {
        tags = [TICKETING_SUB_MANAGER_TAG];
    }
    return tags;
};

export const resolveCustomFields = (formValues: CreateUser & (FormCreateUserData | FormEditUserData)) => {
    const ticketingCustomFields = {
        [TICKETING_CUSTOM_FIELD]: {
            [TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD]: {},
        },
    };

    if (TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD in formValues) {
        // @ts-ignore
        ticketingCustomFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD] = {
            [TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD]:
                formValues[TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD],
        };
    }
    if (TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD in formValues) {
        // @ts-ignore
        ticketingCustomFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD] = {
            ...ticketingCustomFields[TICKETING_CUSTOM_FIELD][TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD],
            [TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD]:
                formValues[TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD],
        };
    }
    return ticketingCustomFields;
};

export const cleanUserDataBeforeSending = (dataToSend: CreateUser & FormCreateUserData) => {
    const newDataToSend = dataToSend;
    const fieldsNameToRemove = [
        USER_TYPE_FIELD,
        USER_SERVICE_PROVIDER_TYPE_FIELD,
        USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD,
        TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
        TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
        ACCESS_TO_SERVICE_FIELD,
    ];
    fieldsNameToRemove.forEach((fieldName) => {
        if (fieldName in newDataToSend) {
            // @ts-ignore
            delete newDataToSend[fieldName];
        }
    });
    return newDataToSend;
};
