import Grid from '@material-ui/core/Grid';
import React from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import BackDiv from '../../commons/BackDiv/BackDiv';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import Data from './data/index';
import './styles.css';
import makeStyles from '@material-ui/styles/makeStyles';
import { ButtonFontWeightBoldStyles } from '../../theme';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import { Company } from '../../utils/types';
import { hasPermissions } from '../../utils/permissions';
import { ROLE_VIEW_GENERAL } from '../../utils/ROLES';

const useStyles = makeStyles({
    button: {
        paddingLeft: 10,
        paddingRight: 10,
        ...ButtonFontWeightBoldStyles,
    },
    backDiv: {
        borderTop: '2px solid #C4D600',
        overflow: 'auto',
        backgroundColor: 'white',
        padding: '0px 10px 10px 10px',
    },
});

const StatisticsPage = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const companyUserConnected: Company | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected?.company as Company
    );

    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.statistics.breadcrumbs.1')]}
                    lastItem={translate('app.statistics.breadcrumbs.0', {
                        company_name: companyUserConnected
                            ? companyUserConnected.name.charAt(0).toUpperCase() + companyUserConnected.name.slice(1)
                            : '',
                    })}
                />
            </Grid>

            <Grid item>
                <BackDiv overrideClasses={classes.backDiv}>
                    {({ divHeight }: { divHeight: number }) => (
                        <div className='statisticsData' style={{ height: divHeight }}>
                            <Data divHeight={divHeight} />
                        </div>
                    )}
                </BackDiv>
            </Grid>
        </Grid>
    );
};

export default StatisticsPage;
