import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Avatar } from '../../../utils/types';
import { RadioButtonGroupInput, TextInput, useNotify, useUpdate } from 'react-admin';
import CustomSubmitButton from '../../inputs/CustomSubmitButton';
import { AVATARS } from '../../../providers/resources';

interface FormProps {
    avatar: Avatar;
    itemKey: string;
    label: string;
    disabled: boolean;
    forceRefresh: () => void;
    type?: string;
    choices?: any[];
}

const AvatarForm = (props: FormProps) => {
    const notify = useNotify();
    const [update] = useUpdate();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onSubmit = (data: { [key: string]: any }) => {
        updateAvatar(data, props.avatar);
    };

    const updateAvatar = (data: { [key: string]: any }, item: Avatar) => {
        const dataToSend = {
            [props.itemKey]: data[props.itemKey],
        };
        update(AVATARS, item.id, dataToSend, props.avatar, {
            onSuccess: () => {
                props.forceRefresh();
                notify('app.avatar.updated.notification', 'success', {
                    value: props.label,
                });
            },
            onFailure: (error: any) => {
                console.error('Error updating avatar property', error);
                notify('app.avatar.updated.error', 'warning', {
                    value: props.label,
                });
            },
        });
    };

    return (
        <FinalForm
            initialValues={{
                [props.itemKey]: props.avatar[props.itemKey],
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
                        {(!props.type || props.type === 'text-input') && (
                            <TextInput
                                disabled={props.disabled}
                                variant={'standard'}
                                source={props.itemKey}
                                label={props.label}
                                helperText={false}
                                fullWidth
                            />
                        )}
                        {props.type === 'radio-input' && (
                            <RadioButtonGroupInput
                                disabled={props.disabled}
                                variant={'standard'}
                                source={props.itemKey}
                                label={props.label}
                                helperText={false}
                                fullWidth
                                choices={props.choices}
                            />
                        )}
                        <CustomSubmitButton fieldKeys={[props.itemKey]} handleSubmit={handleSubmit} />
                    </form>
                );
            }}
        />
    );
};

export default AvatarForm;
