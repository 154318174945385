import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { useListContext, useMutation, useNotify, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { AVATARS } from '../../../../providers/resources';
import { selectIncident } from '../../../../redux/actions/incidents';
import { Avatar } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';

interface DeleteFormProps {
    open: boolean;
    handleClose: () => void;
    handleCancel: () => void;
    selected: Avatar | undefined;
}

const DeleteForm = ({ open, handleClose, selected, handleCancel }: DeleteFormProps) => {
    const [_open, setOpen] = useState<boolean>(open);
    const dispatch = useDispatch();
    const notify = useNotify();
    // const refresh = useRefresh();

    const { onUnselectItems, selectedIds } = useListContext();

    const _handleClose = () => {
        setOpen(false);
        handleClose();
    };
    const [removeAvatar, { loading }] = useMutation({
        type: 'delete',
        resource: AVATARS,
    });

    const updateView = () => {
        if (selectedIds.length > 0) {
            onUnselectItems();
        }
    };

    const deleteFn = () => {
        removeAvatar(
            {
                payload: {
                    id: selected?.id,
                },
            },
            {
                onSuccess: () => {
                    dispatch(selectIncident.fn(null));
                    notify('app.incidents.deleted', 'info');
                    updateView();
                    _handleClose();
                    // refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);

                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                    _handleClose();
                },
            }
        );
    };

    return (
        <Dialog open={_open} onClose={_handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <GlobalForm
                    title={'app.incidents.delete.title'}
                    subtitle={'app.incidents.delete.subtitle'}
                    titleIcon={<DeleteIcon color={'secondary'} />}
                />
                <ButtonsBlockForm
                    disableValidButton={loading || !selected}
                    onCancel={handleCancel}
                    validButtonIsSubmitType={false}
                    onValid={deleteFn}
                    labelValidButton={'app.delete'}
                />
            </DialogContent>
        </Dialog>
    );
};
export default DeleteForm;
