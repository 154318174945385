import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form as FinalForm } from 'react-final-form';
import { DateInput, useTranslate } from 'react-admin';

const ExecuteSubmit = ({ handleSubmit, values }: { handleSubmit: (data: any) => void; values: any }) => {
    const [_values, setValues] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (_values) {
            handleSubmit(_values);
        }
    }, [_values]);

    useEffect(() => {
        setValues(values);
    }, [values]);

    return <span />;
};

const DatePeriodFilter = ({
    handleChange,
    beforeAt,
    afterAt,
    enabled,
}: {
    handleChange: (data: any) => void;
    beforeAt?: string;
    afterAt?: string;
    enabled: boolean;
}) => {
    const onSubmit = (data: any) => {
        handleChange(data);
    };
    const translate = useTranslate();

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                afterAt,
                beforeAt,
            }}
            render={({ handleSubmit, pristine, submitting, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item>
                                <DateInput
                                    label={translate('app.dashboards.filters.afterAt')}
                                    disabled={!enabled}
                                    source={'afterAt'}
                                    variant={'standard'}
                                />
                            </Grid>
                            <Grid item>
                                <DateInput
                                    label={translate('app.dashboards.filters.beforeAt')}
                                    disabled={!enabled}
                                    source={'beforeAt'}
                                    variant={'standard'}
                                />
                            </Grid>
                        </Grid>
                        <ExecuteSubmit handleSubmit={handleSubmit} values={values} />
                    </form>
                );
            }}
        />
    );
};
export default DatePeriodFilter;
