import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { FunctionField, Record, useNotify, usePermissions } from 'react-admin';
import { FieldHeaderListStyles, ImageStyles } from '../../../../theme';
import { TAG_TICKETING_LOGO } from '../../../../utils/CONST';
import MediaField from '../../media';
import {
    getFileSystemMappersByResource,
    imagesExistInFileSystemMapper,
    METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
} from '../../../../utils/medias';
import { FileSystemMapper, Image } from '../../../../utils/types';
import extractIdFromURI, { getUriFromItems } from '../../../../utils/id';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import { hasPermissions } from '../../../../utils/permissions';
import { ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../../../utils/ROLES';

const useStyles = makeStyles({
    field: {
        ...FieldHeaderListStyles,
    },
    gridIconRightMargin: {
        marginRight: 5,
    },
    image: {
        ...ImageStyles,
    },
});

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
});

interface Props {
    record?: Record;
    label: string;
    findImageInAnotherResource: boolean;
    keyForFindImageInAnotherResource?: string;
    imagesKey?: string;
    tags?: string[];
    headerClassName?: string;
    resourceSupported?: string;
}

const FunctionMediaField = ({
    record,
    label,
    findImageInAnotherResource = false,
    keyForFindImageInAnotherResource,
    tags = [TAG_TICKETING_LOGO],
    headerClassName,
    imagesKey = 'images',
    resourceSupported = METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
}: Props) => {
    const classes = useStyles();
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const [imageIdFoundInFileSystemMapper, setImageIdFoundInFileSystemMapper] = useState<string | null>(null);
    const notify = useNotify();

    useEffect(() => {
        if (record) {
            const keyForFindImageInAnotherResourceIsValid =
                keyForFindImageInAnotherResource && keyForFindImageInAnotherResource in record;
            const resourceId = extractIdFromURI(record.id as string);
            if (
                findImageInAnotherResource &&
                keyForFindImageInAnotherResourceIsValid &&
                typeof record[keyForFindImageInAnotherResource] === 'object' &&
                imagesKey in record[keyForFindImageInAnotherResource] &&
                record[keyForFindImageInAnotherResource][imagesKey]
            ) {
                const images = record[keyForFindImageInAnotherResource][imagesKey];
                if (
                    images &&
                    Array.isArray(images) &&
                    images.length > 0 &&
                    'id' in record[keyForFindImageInAnotherResource]
                ) {
                    getImagesUriTicketingLogoExisted(
                        images,
                        extractIdFromURI(record[keyForFindImageInAnotherResource].id as string)
                    )
                        .then((imagesFound) => {
                            if (imagesFound.length > 0) {
                                setImageIdFoundInFileSystemMapper(imagesFound[0]);
                            }
                        })
                        .catch((error: any) => {
                            console.error('ERROR getImagesUriTicketingLogoExisted', error);
                            notify('app.media.errorGettingImage');
                        });
                }
            } else if (typeof record === 'object' && imagesKey && record && record[imagesKey]) {
                const images = record[imagesKey];
                if (images && Array.isArray(images) && images.length > 0 && resourceId) {
                    getImagesUriTicketingLogoExisted(images, resourceId)
                        .then((imagesFound) => {
                            if (imagesFound.length > 0) {
                                setImageIdFoundInFileSystemMapper(imagesFound[0]);
                            }
                        })
                        .catch((error: any) => {
                            notify('app.media.errorGettingImage');
                            console.error('ERROR getImagesUriTicketingLogoExisted', error);
                        });
                }
            }
        }
    }, [record]);

    const getImagesUriTicketingLogoExisted = async (
        images: string[] | Image[],
        resourceRecordId: string | null
    ): Promise<string[] | []> => {
        if (!resourceRecordId) return [];

        let fileSystemMappers: FileSystemMapper[] | null = null;

        fileSystemMappers = await getFileSystemMappersByResource(
            resourceSupported,
            resourceRecordId,
            tags,
            tokenWithRoles as string
        );

        return fileSystemMappers ? imagesExistInFileSystemMapper(getUriFromItems(images), fileSystemMappers) : [];
    };

    return (
        <FunctionField
            sortable={false}
            headerClassName={headerClassName}
            source='name'
            label={label}
            render={(resourceRecord: Record | undefined) => {
                return resourceRecord ? (
                    <CustomDiv>
                        {imageIdFoundInFileSystemMapper && !findImageInAnotherResource && (
                            <MediaField
                                key={resourceRecord.id as string}
                                imageId={imageIdFoundInFileSystemMapper}
                                resourceId={resourceRecord.id}
                                tags={tags}
                            />
                        )}
                        {imageIdFoundInFileSystemMapper &&
                            findImageInAnotherResource &&
                            keyForFindImageInAnotherResource && (
                                <MediaField
                                    key={resourceRecord.id as string}
                                    imageId={imageIdFoundInFileSystemMapper}
                                    resourceId={resourceRecord[keyForFindImageInAnotherResource].id}
                                    tags={tags}
                                />
                            )}

                        {!imageIdFoundInFileSystemMapper && (
                            <img src={'/tmp-image.png'} className={classes.image} alt='' />
                        )}

                        <Typography noWrap={true} variant={'body2'}>
                            {resourceRecord ? resourceRecord.name : ''}
                        </Typography>
                    </CustomDiv>
                ) : (
                    <img src={'/tmp-image.png'} className={classes.image} alt='' />
                );
            }}
        />
    );
};

export default FunctionMediaField;
