import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { GroupMetadataAvatarType } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';

interface DeleteFormProps {
    open: boolean;
    handleClose: () => void;
    selected: GroupMetadataAvatarType | undefined;
}

const DeleteForm = ({ open, handleClose, selected }: DeleteFormProps) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const [update, { loading }] = useMutation({
        type: 'update',
        resource: GROUP_METADATA_AVATAR_TYPES,
    });

    const deleteFn = () => {
        update(
            {
                payload: {
                    data: {
                        hidden: true,
                        customFields: selected && !selected.customFields ? {} : selected?.customFields,
                        identifiers: selected && !selected.identifiers ? {} : selected?.identifiers,
                    },
                    id: selected?.id,
                },
            },
            {
                onSuccess: () => {
                    // dispatch(selectEquipmentType.fn(null));
                    notify('app.category.archive.success', 'success');
                    handleClose();
                    refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);

                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                    handleClose();
                },
            }
        );
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <GlobalForm
                    title={'app.category.archive.title'}
                    subtitle={'app.category.archive.subtitle'}
                    titleIcon={<DeleteIcon color={'secondary'} />}
                    subtitleTranslateOption={{ name: selected?.name }}
                />
                <ButtonsBlockForm
                    disableValidButton={loading || !selected}
                    onCancel={handleClose}
                    onValid={deleteFn}
                    validButtonIsSubmitType={false}
                    labelValidButton={'app.archive'}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DeleteForm;
