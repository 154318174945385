import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslate } from 'react-admin';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import BackDiv from '../../commons/BackDiv/BackDiv';
import UserList from './list';
import IncidentsTypesList from '../incidentTypes/list';

const Users = () => {
    const translate = useTranslate();
    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.users.breadcrumbs.1')]}
                    lastItem={translate('app.users.breadcrumbs.0')}
                />
            </Grid>

            <Grid item>
                <BackDiv>
                    {({ divHeight }: { divHeight: number }) => (
                        <div className='usersTable' style={{ height: divHeight }}>
                            <UserList divHeight={divHeight} />
                        </div>
                    )}
                </BackDiv>
            </Grid>
        </Grid>
    );
};

export default Users;
