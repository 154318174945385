import React, { useEffect, useState } from 'react';
import { MetadataAvatarType, MetadataAvatarTypeProperty } from '../../../utils/types';
import { useSelector } from 'react-redux';
import { CreateResult, Record, ReduxState, useDataProvider, useNotify, useTranslate } from 'react-admin';
import { METADATA_AVATAR_TYPE_PROPERTIES } from '../../../providers/resources';
import Typography from '@material-ui/core/Typography';
import { capitalizeFirstLetter } from '../../../utils/strings';

interface ForceMetadataAvatarTypePropertiesProps {
    metadataAvatarType: MetadataAvatarType;
    propertyName: string;
}

const ForceMetadataAvatarTypeProperties = (props: ForceMetadataAvatarTypePropertiesProps) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();

    const propertiesDataInState = useSelector(
        (state: ReduxState) => state.admin.resources[METADATA_AVATAR_TYPE_PROPERTIES].data
    );
    const propertyInState: Record | undefined = Object.values(propertiesDataInState).find(
        (property) =>
            (property as MetadataAvatarTypeProperty).metadataAvatarType === props.metadataAvatarType.id &&
            (property as MetadataAvatarTypeProperty).name.toLowerCase() === props.propertyName.toLowerCase()
    );

    const [propertyValue, setPropertyValue] = useState<boolean | undefined>(
        propertyInState ? JSON.parse((propertyInState as MetadataAvatarTypeProperty).values[0]) : undefined
    );

    useEffect(() => {
        if (propertyValue === undefined && propertyInState === undefined) {
            getProperties(props.metadataAvatarType.id as string, props.propertyName);
        }
        if (
            propertyValue !== undefined &&
            propertyInState !== undefined &&
            JSON.parse((propertyInState as MetadataAvatarTypeProperty).values[0]) !== propertyValue
        ) {
            const newPropertyValueInState = JSON.parse((propertyInState as MetadataAvatarTypeProperty).values[0]);
            setPropertyValue(newPropertyValueInState);
        }
    }, [propertyValue, propertyInState]);

    const getProperties = (metadataAvatarTypeId: string, name: string) => {
        dataProvider
            .getList(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {
                        metadataAvatarType: metadataAvatarTypeId,
                        name,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (
                            response &&
                            'data' in response &&
                            response.data &&
                            Array.isArray(response.data) &&
                            response.data.length > 0
                        ) {
                            if ((response.data[0] as MetadataAvatarTypeProperty).values.length === 0) return;
                            if (
                                (response.data[0] as MetadataAvatarTypeProperty).values[0] === 'true' ||
                                (response.data[0] as MetadataAvatarTypeProperty).values[0] === 'false'
                            ) {
                                setPropertyValue(
                                    JSON.parse((response.data[0] as MetadataAvatarTypeProperty).values[0])
                                );
                            } else {
                                setPropertyValue((response.data[0] as MetadataAvatarTypeProperty).values[0]);
                            }
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getPropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => console.error(error));
    };

    return (
        <Typography>
            {propertyValue ? capitalizeFirstLetter(translate('app.yes')) : capitalizeFirstLetter(translate('app.no'))}
        </Typography>
    );
};

export default ForceMetadataAvatarTypeProperties;
