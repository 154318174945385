//@ts-ignore
import isPlainObject from 'lodash.isplainobject';
import { ValidationJsonError } from '../../utils/validations/parseValidations';

export class ResponseError extends Error {
    public responseJson: any;

    constructor(responseJson: any, ...args: any) {
        super(...args);
        this.responseJson = responseJson;
    }
}

export class JsonResponseError extends Error {
    public json: ValidationJsonError;

    constructor(json: ValidationJsonError, ...args: any) {
        super(...args);
        this.json = json;
    }
}

export class ReactAdminDocument {
    constructor(obj: any) {
        Object.assign(this, obj, {
            originId: obj.id,
            id: obj['@id'],
        });
    }

    /**
     * @return {string}
     */
    toString() {
        //@ts-ignore
        return `[object ${this.id}]`;
    }
}

/**
 * Local cache containing embedded documents.
 * It will be used to prevent useless extra HTTP query if the relation is displayed.
 *
 * @type {Map}
 */
const reactAdminDocumentsCache = new Map();

/**
 * Transforms a JSON-LD document to a react-admin compatible document.
 *
 * @param {Object} document
 * @param {bool} clone
 * @param {bool} addToCache
 * @param {bool} useEmbedded
 *
 * @return {ReactAdminDocument}
 */
export const transformJsonLdDocumentToReactAdminDocument = (
    document: any,
    clone = true,
    addToCache = true,
    useEmbedded = false
) => {
    if (clone) {
        // deep clone documents
        document = JSON.parse(JSON.stringify(document));
    }

    // The main document is a JSON-LD document, convert it and store it in the cache
    if (document['@id']) {
        document = new ReactAdminDocument(document);
    }

    // Replace embedded objects by their IRIs, and store the object itself in the cache to reuse without issuing new HTTP requests.
    Object.keys(document).forEach((key) => {
        // to-one
        if (isPlainObject(document[key]) && document[key]['@id']) {
            if (addToCache) {
                //@ts-ignore
                reactAdminDocumentsCache[document[key]['@id']] = transformJsonLdDocumentToReactAdminDocument(
                    document[key],
                    false,
                    false
                );
            }
            document[key] = useEmbedded ? document[key] : document[key]['@id'];

            return;
        }

        // to-many
        if (
            Array.isArray(document[key]) &&
            document[key].length &&
            isPlainObject(document[key][0]) &&
            document[key][0]['@id']
        ) {
            document[key] = document[key].map((obj: any) => {
                if (addToCache) {
                    //@ts-ignore
                    reactAdminDocumentsCache[obj['@id']] = transformJsonLdDocumentToReactAdminDocument(
                        obj,
                        false,
                        false
                    );
                }

                return useEmbedded ? obj : obj['@id'];
            });
        }
    });

    return document;
};

/**
 * __ is transformed into []
 * ___ is transformed into .
 * @param params
 */
export const uniformParams = (params: any): any => {
    Object.keys(params).forEach((key: string) => {
        if (key.search('___') !== -1) {
            const _newKey = key.replaceAll('___', '.');
            params[_newKey] = params[key];
            delete params[key];
        } else if (key.search('__') !== -1) {
            const [rootVar, innerVar] = key.split('__');
            params[`${rootVar}[${innerVar}]`] = params[key];
            delete params[key];
        }
    });

    return params;
};
