import { parseHydraDocumentation as baseParseHydraDocumentation } from '@api-platform/api-doc-parser';
import { DataProvider as RADataProvider, GetListParams, GetListResult, Record } from 'react-admin';

import { uniformParams } from './dataProviders/utils';
import customFetchHydra from './api-platform/fetchHydra';
import customHydraDataProvider from './api-platform/hydraDataProvider';
import { IncidentStatus } from '../utils/CONST';
import { AVATARS } from './resources';
const statusNames = Object.keys(IncidentStatus);

const hydraDataProvider = customHydraDataProvider;

const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;

const getToken = (): string => {
    // @ts-ignore
    const token: string = localStorage.getItem(
        // @ts-ignore
        process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE
    );

    return token;
};

const fetchHydra = (url: string, options: { [key: string]: any } = {}) => {
    return customFetchHydra(url, {
        ...options,
        headers: {
            ...('headers' in options ? options.headers : {}),
            Authorization: `Bearer ${getToken()}`,
        },
    });
};

const parseHydraDocumentation = (url: string, options: RequestInit = {}) => {
    return baseParseHydraDocumentation(url, {
        ...options,
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};

const dataProvider = hydraDataProvider(
    entrypoint,
    fetchHydra,
    // @ts-ignore
    parseHydraDocumentation,
    true // useEmbedded parameter
);

// const addMethodToDataProvider = (
//     dataProvider: any,
//     methodName: string,
//     wrapperInnerDataProvider: (fetchHydra: any, entrypoint: string) => any
// ) => {
//     if (methodName in dataProvider) throw new Error(`Method "${methodName}" already exists in data provider`);
//
//     return {
//         ...dataProvider,
//         [methodName]: wrapperInnerDataProvider(fetchHydra, entrypoint),
//     };
// };

const PatchGetListDataProvider: DataProvider = {
    ...dataProvider,
    // @ts-ignore
    getList: (resource: string, params: GetListParams) => {
        const customFilters = uniformParams(params.filter ? params.filter : {});

        return dataProvider
            .getList(resource, {
                ...params,
                filter: {
                    ...customFilters,
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && 'data' in result) {
                    return result;
                }

                throw result;
            });
    },
};

const statusNameRegExpContent = statusNames
    .map((status) => `${AVATARS}-${status}`)
    .reduce((acc, item, index) => {
        return index === 0 ? item : `${acc}|${item}`;
    }, '');
const statusNameRegExp = new RegExp(statusNameRegExpContent, 'g');

const StatusNameToResourceDataProvider: DataProvider = {
    ...PatchGetListDataProvider,
    getList: (resource: string, params: GetListParams) => {
        // @ts-ignore
        const resultRegExp = [...resource.matchAll(statusNameRegExp)];

        if (Array.isArray(resultRegExp) && resultRegExp.length > 0 && resultRegExp[0].length > 0) {
            return PatchGetListDataProvider.getList(AVATARS, params);
        }

        return PatchGetListDataProvider.getList(resource, params);
    },
};

export default StatusNameToResourceDataProvider;

export type DataProvider = RADataProvider & {
    introspect: () => Promise<{ data: any }>;
};
