import { PlaceOrEventCount, PlaceOrEventPath } from '../../../utils/types';
import { Identifier } from 'react-admin';

export interface RegisterPlacesOrEventsPath {
    type: typeof registerPlacesOrEventsPlacePath.action;
    payload: {
        path: PlaceOrEventPath[];
        eventOrPlaceId: Identifier;
    };
}

export const registerPlacesOrEventsPlacePath = {
    action: '360SC_TICKETING/REGISTER_PLACES_OR_EVENTS_PATH',
    fn: (path: PlaceOrEventPath[], eventOrPlaceId: Identifier): RegisterPlacesOrEventsPath => ({
        type: registerPlacesOrEventsPlacePath.action,
        payload: {
            path,
            eventOrPlaceId,
        },
    }),
};

export interface RegisterPlacesOrEventsCount {
    type: typeof registerPlacesOrEventsPlaceCount.action;
    payload: {
        placeEventCount: PlaceOrEventCount;
        eventOrPlaceId: Identifier;
    };
}

export const registerPlacesOrEventsPlaceCount = {
    action: '360SC_TICKETING/REGISTER_PLACES_OR_EVENTS_COUNT',
    fn: (placeEventCount: PlaceOrEventCount, eventOrPlaceId: Identifier): RegisterPlacesOrEventsCount => ({
        type: registerPlacesOrEventsPlaceCount.action,
        payload: {
            placeEventCount,
            eventOrPlaceId,
        },
    }),
};

export type ACTIONS = RegisterPlacesOrEventsPath | RegisterPlacesOrEventsCount;
