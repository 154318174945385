import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import DialogMediaAndComment from '../../../commons/Dialog/DialogMediaAndComment';
import MediaField from '../../../commons/fields/media';
import { MEDIA } from '../../../providers/resources';
import { TICKETING_OPENED_NODE_TYPE } from '../../../utils/CONST';
import { findNodeByNodeType, getPathProcess } from '../../../utils/getListOfNodesFromRunnerTempates';
import extractIdFromURI, { getResourceURIFROMAnotherURI } from '../../../utils/id';
import { Avatar, Node, NodeMedias } from '../../../utils/types';

interface EventStateInfoProps {
    event: Avatar;
}

interface OpenedStateInfoProps {
    medias: NodeMedias[] | undefined;
    comment: string;
}

const useStyles = makeStyles({
    imageField: {
        height: 60,
        width: 60,
        margin: '0 0 -5px 0',
    },
    displayGridCenter: {
        display: 'grid',
        justifySelf: 'center',
    },
});

const EventOpenedStateInfo = (props: EventStateInfoProps) => {
    const classes = useStyles();
    const [infoToShow, setInfoToShow] = useState<OpenedStateInfoProps>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const resolveOpenedData = () => {
        const nodes = getPathProcess(props.event.currentReferencing?.standardReferencing?.runner?.firstNode);
        const openedNode: Node | undefined = findNodeByNodeType(nodes, TICKETING_OPENED_NODE_TYPE);

        const forms =
            openedNode && (openedNode as Node).extraData?.customFields?.forms
                ? (openedNode as Node).extraData?.customFields?.forms
                : null;
        let formData = forms && 'data' in forms ? forms.data : null;
        formData = !formData && forms && '_form1' in forms ? forms['_form1'] : formData;

        const medias = openedNode ? (openedNode as Node).extraData?.medias : [];

        setInfoToShow({
            comment: formData && 'comment' in formData ? formData.comment : '',
            medias,
        });
    };

    const onClickHandleShowInfo = () => {
        setOpenDialog(true);
    };

    useEffect(resolveOpenedData, []);

    const mediasToShow = infoToShow && infoToShow?.medias && infoToShow?.medias.length > 0 ? infoToShow?.medias : null;

    const imageId = mediasToShow
        ? getResourceURIFROMAnotherURI(props.event.id as string, mediasToShow[0].id, MEDIA)
        : null;

    return (
        <div className={classes.displayGridCenter} onClick={onClickHandleShowInfo}>
            <span>
                {mediasToShow && imageId && (
                    <MediaField
                        imageFieldClassOverride={classes.imageField}
                        key={`media_field`}
                        imageId={imageId}
                        resourceId={props.event.id}
                        tags={[]}
                    />
                )}
            </span>

            <span>{infoToShow?.comment.slice(0, 30)}</span>

            {openDialog && infoToShow && (
                <DialogMediaAndComment
                    //@ts-ignore
                    imagesIds={
                        mediasToShow
                            ? mediasToShow
                                  .map((media) => {
                                      const mediaUri = getResourceURIFROMAnotherURI(
                                          props.event.id as string,
                                          media.id,
                                          MEDIA
                                      );
                                      return mediaUri ? mediaUri : null;
                                  })
                                  .filter((e) => e !== null)
                            : undefined
                    }
                    event={props.event}
                    comment={infoToShow?.comment}
                    open={true}
                    onClose={() => setOpenDialog(false)}
                    recordId={extractIdFromURI(props.event.id as string)}
                />
            )}
        </div>
    );
};

export default EventOpenedStateInfo;
