import { AuthProvider as RAAuthProvider } from 'react-admin';
import { getTokenData } from '../utils/token';

interface AuthProviderLocalState {
    user: {
        data: any;
        refreshToken: string | null;
        loggedIn: boolean;
    };
}

export const localState: AuthProviderLocalState = {
    user: {
        data: null,
        refreshToken: null,
        loggedIn: false,
    },
};

export type AuthProvider = RAAuthProvider & {
    refreshToken: (params: { refreshToken: string }) => Promise<{ token: string; refreshToken: string } | undefined>;
    login: (params: {
        username: string;
        password: string;
    }) => Promise<{ token: string; refreshToken: string } | undefined>;
    logout: () => Promise<void | string>;
};

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const authProvider: AuthProvider = {
    login: (params: {
        username: string;
        password: string;
    }): Promise<{ token: string; refreshToken: string } | undefined> => {
        const formData = new FormData();
        formData.set('_username', params.username);
        formData.set('_password', params.password);
        return fetch(
            new Request(`${entrypoint}/optimized_token_login_check`, {
                method: 'POST',
                body: formData,
            })
        )
            .catch((error: any) => console.error(error))
            .then((response: any) => {
                if ('status' in response && response.status == 404) return undefined;
                if ('json' in response) {
                    return response.json();
                }
                return undefined;
            })
            .then((data) => {
                if (data) {
                    const { token, refresh_token } = data;
                    if (token && refresh_token) {
                        // @ts-ignore
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE, token);
                        // @ts-ignore
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE, refresh_token);

                        return Promise.resolve({ refreshToken: refresh_token, token });
                    } else {
                        return undefined;
                    }
                }

                return undefined;
            });
    },
    refreshToken: (params: { refreshToken: string }): Promise<{ token: string; refreshToken: string } | undefined> => {
        return fetch(
            new Request(`${entrypoint}/token/refresh`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify({ refresh_token: params.refreshToken }),
            })
        )
            .then((response: any) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    const { token, refresh_token } = data;

                    if (token && refresh_token) {
                        // @ts-ignore
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE, token);
                        // @ts-ignore
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE, refresh_token);

                        return Promise.resolve({ refreshToken: refresh_token, token });
                    } else {
                        return undefined;
                    }
                }

                return undefined;
            });
    },
    logout: (): Promise<void | string> => {
        console.log('logout ----');
        // @ts-ignore
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        // @ts-ignore
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE);
        return Promise.resolve('/login');
    },

    checkAuth: async (): Promise<void> => {
        // @ts-ignore
        // const tokenVariable = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        // if (tokenVariable) {
        //     const decodedToken = decodeJwt(tokenVariable);
        //     console.log("tokenVariable", tokenVariable);
        // }

        // @ts-ignore
        // return localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE) ? Promise.resolve() : Promise.reject();
        return Promise.resolve();
    },
    checkError: (error: any): Promise<void> => {
        const status =
            'status' in error
                ? error.status
                : 'response' in error && 'status' in error.response
                ? error.response.status
                : null;
        if (status) {
            if (status === 401 || status === 403) {
                localState.user.loggedIn = false;

                return Promise.reject({ redirectTo: '/' });
            }
        }

        return Promise.resolve();
    },
    getPermissions: async (): Promise<string[]> => {
        const token = localStorage.getItem(
            // @ts-ignore
            process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE
        );

        const promise = new Promise<string[]>((resolve) => {
            const interval = setInterval(() => {
                const tokenData = token ? getTokenData(token) : null;
                tokenData ? Object.values(tokenData.roles) : null;

                if (tokenData) {
                    clearInterval(interval);
                    resolve(tokenData.roles);
                }
            }, 500);
        });

        const roles = await promise;

        return Object.values(roles);
    },
    // getIdentity: () => {
    //     return getUserConnected().then((user: DUser) => user);
    // }
};

export default authProvider;
