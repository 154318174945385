import { combineReducers } from 'redux';
import hydraDataProviderReducer, { HydraDataProviderState } from './hydraDataProvider';
import incidentReducer, { IncidentsState } from './incidents';
import incidentTypesReducer, { IncidentTypeState } from './incidentTypes';
import userReducer, { UsersState } from './user';
import categoryReducer, { CategoriesState } from './categories';
import subCategoryReducer, { SubCategoryState } from './subCategories';
import navigationReducer, { NavigationState } from './navigation';
import mediaReducer, { MediasState } from './medias';
import dashboardReducer, { DashboardState } from './dashboards';
import removedItemsReducer, { RemovedItemsState } from './removedItems';
import placesOrEventsPathReducer, { PlacesOrEventsPathState } from './placesOrEventsPath';
import placesOrEventsCountReducer, { PlacesOrEventsCountState } from './placesOrEventsCount';
import ticketingSubcategoryEventTypesDependenciesNavigationReducer, {
    TicketingSubcategoryEventTypesDependenciesNavigationState,
} from './ticketingSubcategoryEventTypesDependenciesNavigation';
import dnd, { DndState } from './dnd';

export interface State {
    hydraDataProvider: HydraDataProviderState;
    categories: CategoriesState;
    subCategories: SubCategoryState;
    incidents: IncidentsState;
    incidentTypes: IncidentTypeState;
    medias: MediasState;
    navigation: NavigationState;
    ticketingSubcategoryEventTypesDependenciesInNavigation: TicketingSubcategoryEventTypesDependenciesNavigationState;
    user: UsersState;
    dashboards: DashboardState;
    removedItems: RemovedItemsState;
    placesOrEventsPath: PlacesOrEventsPathState;
    placesOrEventsCount: PlacesOrEventsCountState;
    dnd: DndState;
}

export interface CustomReducerState {
    ticketing: State;
}

export default combineReducers({
    hydraDataProvider: hydraDataProviderReducer,
    categories: categoryReducer,
    incidents: incidentReducer,
    incidentTypes: incidentTypesReducer,
    navigation: navigationReducer,
    ticketingSubcategoryEventTypesDependenciesInNavigation: ticketingSubcategoryEventTypesDependenciesNavigationReducer,
    user: userReducer,
    medias: mediaReducer,
    subCategories: subCategoryReducer,
    dashboards: dashboardReducer,
    removedItems: removedItemsReducer,
    placesOrEventsPath: placesOrEventsPathReducer,
    placesOrEventsCount: placesOrEventsCountReducer,
    dnd,
});
