import { styled } from '@material-ui/styles';
import React from 'react';

const CustomDiv = styled('div')({
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 10,
});

interface Props {
    items: any;
    children: any;
}

const DashboardsList = ({ items = [], children }: Props) => (
    <CustomDiv>
        {items.map((item: any) => item)}
        {children}
    </CustomDiv>
);

export default DashboardsList;
