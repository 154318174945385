import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
    END_NODE_TYPE,
    NODE_TYPE_END_BACKGROUND_COLOR,
    NODE_TYPE_END_COLOR,
    NODE_TYPE_PAUSE_COUNTER_TIME_BACKGROUND_COLOR,
    NODE_TYPE_PAUSE_COUNTER_TIME_COLOR,
    NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_COLOR,
    NODE_TYPE_TICKETING_OPENED_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_OPENED_COLOR,
    NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_COLOR,
    NODE_TYPE_TICKETING_REPAIRED_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_REPAIRED_COLOR,
    NODE_TYPE_TICKETING_REPAIRING_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_REPAIRING_COLOR,
    NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR,
    NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_COLOR,
    NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_BACKGROUND_COLOR,
    NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_COLOR,
    TICKETING_COUNTER_TIME_NODE_TYPE,
    TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE,
    TICKETING_OPENED_NODE_TYPE,
    TICKETING_REFUSED_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_REPAIRED_NODE_TYPE,
    TICKETING_REPAIRING_NODE_TYPE,
    TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE,
    TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE,
} from '../../utils/CONST';
import ResolutionIncidentOpinionField from '../../components/incidents/fields/ResolutionIncidentOpinionField';
import { Avatar } from '../../utils/types';

const RootDiv: any = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
}));

const InDiv: any = styled('div')(({ theme }) => ({
    borderRadius: 5,
    padding: '3px 7px',
    marginBottom: 5,
}));

const StateTypography: any = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'uppercase',
}));

interface Props {
    name: string;
    event: Avatar;
    nodeTypes: string[];
}

const NodeStateName = ({ name, nodeTypes, event }: Props) => {
    const selectBackgroundColor = (nodeTypes: string[]) => {
        if (nodeTypes.includes(TICKETING_OPENED_NODE_TYPE)) return NODE_TYPE_TICKETING_OPENED_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE))
            return NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE))
            return NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE))
            return NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_REPAIRING_NODE_TYPE)) return NODE_TYPE_TICKETING_REPAIRING_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_COUNTER_TIME_NODE_TYPE)) return NODE_TYPE_PAUSE_COUNTER_TIME_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_REFUSED_TAKE_INTO_ACCOUNT_NODE_TYPE))
            return NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR;
        if (nodeTypes.includes(TICKETING_REPAIRED_NODE_TYPE)) return NODE_TYPE_TICKETING_REPAIRED_BACKGROUND_COLOR;
        if (nodeTypes.includes(END_NODE_TYPE)) return NODE_TYPE_END_BACKGROUND_COLOR;
        return 'white';
    };

    const selectColor = (nodeTypes: string[]) => {
        if (nodeTypes.includes(TICKETING_OPENED_NODE_TYPE)) return NODE_TYPE_TICKETING_OPENED_COLOR;
        if (nodeTypes.includes(TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE))
            return NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_COLOR;
        if (nodeTypes.includes(TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE))
            return NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_COLOR;
        if (nodeTypes.includes(TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE))
            return NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_COLOR;
        if (nodeTypes.includes(TICKETING_REPAIRING_NODE_TYPE)) return NODE_TYPE_TICKETING_REPAIRING_COLOR;
        if (nodeTypes.includes(TICKETING_COUNTER_TIME_NODE_TYPE)) return NODE_TYPE_PAUSE_COUNTER_TIME_COLOR;
        if (nodeTypes.includes(TICKETING_REFUSED_TAKE_INTO_ACCOUNT_NODE_TYPE))
            return NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_COLOR;
        if (nodeTypes.includes(TICKETING_REPAIRED_NODE_TYPE)) return NODE_TYPE_TICKETING_REPAIRED_COLOR;
        if (nodeTypes.includes(END_NODE_TYPE)) return NODE_TYPE_END_COLOR;
        return 'black';
    };

    return (
        <RootDiv>
            <InDiv style={{ backgroundColor: selectBackgroundColor(nodeTypes) }}>
                <StateTypography style={{ color: selectColor(nodeTypes) }}>{name}</StateTypography>
            </InDiv>
        </RootDiv>
    );
};

export default NodeStateName;
