import React from 'react';
import DialogTemplate from '../../../../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { Avatar, SubcategoryEventTypeDependencies } from '../../../../../../../../utils/types';
import Form from './form';

interface Props {
    open: boolean;
    handleClose: () => void;
    handleCancel: () => void;
    item: SubcategoryEventTypeDependencies;
    place: Avatar;
}

const AddIncidentTypeDialog = ({ open, handleClose, item, handleCancel, place }: Props) => {
    const handleSave = () => {
        handleClose();
    };

    return (
        <DialogTemplate open={open} onClose={handleClose} maxWidth={'xs'}>
            <Form place={place} item={item} handleSave={handleSave} handleCancel={handleCancel} />
        </DialogTemplate>
    );
};
export default AddIncidentTypeDialog;
