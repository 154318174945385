import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import AvatarPropertiesCreateOrUpdateForm, {
    CreateOrUpdateDynamicsKeysAndOneField,
    CreateOrUpdateDynamicsKeysAndOneFieldAdvertiseCreate,
} from '../../../../../commons/avatarProperties/avatarPropertiesDynamicKey/createOrUpdate';
import AvatarPropertyCreateOrUpdateForm from '../../../../../commons/avatarProperties/avatarPropertyFixedKey/createOrUpdate';
import HeaderBlock from '../../../../../commons/HeaderBlock/HeaderBlock';
import { DIVIDER_COLOR } from '../../../../../theme';
import {
    ITEM_CODE_PIN_EXTRA_INFORMATION_DEFINITION,
    ITEM_EXTRA_INFORMATION_DEFINITION,
} from '../../../../../utils/CONST';
import { Avatar } from '../../../../../utils/types';

const useStyles = makeStyles({
    root: {
        border: `1px solid ${DIVIDER_COLOR}`,
    },
    addButtonRootText: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 20,
    },
    addIcon: {
        fontWeight: 500,
    },
});

interface Props {
    item: Avatar;
    disabled: boolean;
}

const ExtraInformation: FC<Props> = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [addFieldsCount, setAddFieldsCount] = useState<number[]>([]);
    const [forceRefresh, setForceRefresh] = useState<number>(0);

    const incrementAddFields = () => setAddFieldsCount([...addFieldsCount, addFieldsCount.length + 1]);
    const decrementAddFields = (indexToRemove: number) => (calledType: string) => {
        if (calledType === CreateOrUpdateDynamicsKeysAndOneFieldAdvertiseCreate) {
            setAddFieldsCount(addFieldsCount.filter((item) => item !== indexToRemove));
            setForceRefresh(forceRefresh + 1);
        }
    };

    return (
        <HeaderBlock
            icon={<InfoIcon />}
            iconTooltipMessage={translate('app.navigation.configuration.itemInformation.extraInformation')}
            label={translate('app.navigation.configuration.itemInformation.extraInformationLabel')}
        >
            <Grid container direction={'column'}>
                <Grid item>
                    <AvatarPropertyCreateOrUpdateForm
                        disabled={props.disabled}
                        icon={<MailOutlineIcon />}
                        avatar={props.item}
                        itemKey={ITEM_CODE_PIN_EXTRA_INFORMATION_DEFINITION}
                        itemValue=''
                        fieldType={'string'}
                        definition={ITEM_EXTRA_INFORMATION_DEFINITION}
                        searchItem={true}
                        label={translate('app.navigation.configuration.itemInformation.pinCode')}
                    />
                </Grid>

                <Grid item>
                    <Button
                        disabled={props.disabled}
                        onClick={incrementAddFields}
                        size={'medium'}
                        className={classes.addButtonRootText}
                        color='primary'
                        startIcon={<AddIcon className={classes.addIcon} />}
                    >
                        {translate('app.navigation.configuration.itemInformation.add')}
                    </Button>
                </Grid>

                <Grid item>
                    <AvatarPropertiesCreateOrUpdateForm
                        disabled={props.disabled}
                        icon={<MailOutlineIcon />}
                        avatar={props.item}
                        excludesNames={[ITEM_CODE_PIN_EXTRA_INFORMATION_DEFINITION]}
                        propertyDefinition={ITEM_EXTRA_INFORMATION_DEFINITION}
                        labelId={translate('app.navigation.configuration.itemInformation.customInformationFieldID')}
                        labelValue={translate(
                            'app.navigation.configuration.itemInformation.customInformationFieldValue'
                        )}
                        forceRefresh={forceRefresh}
                    />
                </Grid>

                {addFieldsCount.map((addFieldCount) => (
                    <Grid item key={`addFieldsCount-CreateOrUpdateDynamicsKeysAndOneField-${addFieldCount}`}>
                        <CreateOrUpdateDynamicsKeysAndOneField
                            disabled={props.disabled}
                            item={null}
                            labelId={translate('app.navigation.configuration.itemInformation.customInformationFieldID')}
                            labelValue={translate(
                                'app.navigation.configuration.itemInformation.customInformationFieldValue'
                            )}
                            advertise={decrementAddFields(addFieldCount)}
                            avatar={props.item}
                            propertyDefinition={ITEM_EXTRA_INFORMATION_DEFINITION}
                            icon={<MailOutlineIcon />}
                        />
                    </Grid>
                ))}
            </Grid>
        </HeaderBlock>
    );
};

export default ExtraInformation;
