import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useDelete, useNotify, useTranslate } from 'react-admin';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { Avatar, NavigationItemActionType } from '../../../../utils/types';
import { AVATARS } from '../../../../providers/resources';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';

interface Props {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    item: Avatar;
    notifyAction?: (actionType: NavigationItemActionType) => void;
}

const DeleteItemDialog = ({ open, onClose, onCancel, item, notifyAction }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const queryClient = useQueryClient();

    const handleCancel = () => {
        onCancel();
    };

    const [deleteOneAvatarItem, { loading: isDeleting, error: errorDelete }] = useDelete(AVATARS, item.id, item, {
        onSuccess: () => {
            notify('app.navigation.removeItem.removed', 'success');
            if ('parent' in item && item.parent && 'id' in item.parent) {
                queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(item.parent.id as string)}`]);
            } else {
                queryClient.invalidateQueries([`rootNavigationAvatars`]);
            }
            handleCancel();
        },
        onFailure: (error) => {
            notify('app.navigation.removeItem.error', 'error');
            console.error('Error when deleting avatar - error message ', error);
        },
    });

    const handleDelete = () => {
        deleteOneAvatarItem();
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.removeItem.title')}
            helpText={translate('app.navigation.removeItem.helpText')}
            open={open}
            onClose={onClose}
            icon={<DeleteIcon />}
            maxWidth={'xs'}
        >
            <DialogTemplateButtons
                isSubmitting={isDeleting}
                outside
                withCancel
                onDeleteClick={handleDelete}
                onCancelClick={handleCancel}
                withDelete={true}
                deleteButtonLabel={translate('app.delete')}
                cancelButtonLabel={translate('app.cancel')}
            />
        </DialogTemplate>
    );
};

export default DeleteItemDialog;
