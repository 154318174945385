import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { TextInput, useNotify, useTranslate, useUpdate } from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { AVATARS } from '../../../../providers/resources';
import { Avatar } from '../../../../utils/types';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import BorderColorIcon from '@material-ui/icons/BorderColor';

const NAME_FIELD = 'name';

interface Props {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    avatar: Avatar;
}

const ChangeNameDialogForm = ({ open, onClose, avatar, onCancel }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [update] = useUpdate();
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const onSubmit = async (data: { [key: string]: any }) => {
        setLoadingState(true);
        updateAvatarName(
            avatar,
            data[NAME_FIELD],
            () => {
                setLoadingState(false);
                notify('app.incidents.edit.success', 'success');
                onClose();
            },
            (error: any) => {
                console.error('error', error);
                notify('app.incidents.edit.fail', 'error');
                setLoadingState(false);
            }
        );
    };

    const updateAvatarName = (avatar: Avatar, newName: string, successCallback: any, failureCallback: any) => {
        update(AVATARS, avatar.id, { name: newName }, avatar, {
            onSuccess: () => {
                successCallback();
            },
            onFailure: (error: any) => {
                failureCallback(error);
            },
        });
    };

    return (
        <DialogTemplate
            open={open}
            icon={<BorderColorIcon />}
            onClose={onCancel}
            maxWidth={'xs'}
            title={translate('app.incidents.edit.title')}
        >
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{ [NAME_FIELD]: avatar.name }}
                render={({ handleSubmit, pristine, submitting }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <TextInput
                                        variant={'standard'}
                                        source={NAME_FIELD}
                                        label={translate('app.incidents.edit.name')}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <ButtonsBlockForm
                                    disableValidButton={pristine || submitting || loadingState}
                                    validButtonIsSubmitType={true}
                                    labelValidButton={'app.valid'}
                                    onCancel={onCancel}
                                    loading={loadingState}
                                />
                            </Grid>
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default ChangeNameDialogForm;
