import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import {
    Identifier,
    Record,
    ReduxState,
    UpdateResult,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { findNodeByNodeType, getPathProcess, isNodeType } from '../../utils/getListOfNodesFromRunnerTempates';
import { END_NODE_TYPE, USER_PROVIDER_CATEGORY } from '../../utils/CONST';
import { Avatar, Node, UserCategory } from '../../utils/types';
import { useSelector } from 'react-redux';
import { AVATAR_TRACEABILITY, AVATARS, TRACEABILITY_TRACKING } from '../../providers/resources';
import { getResourceURIFROMAnotherURI } from '../../utils/id';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { TopToolbarButtonStyled } from '../../theme';
import { CustomReducerState } from '../../redux/reducers';
import { getUserCategory } from '../../utils/user/tags';

const IncidentBulkActionButtons = (props: {
    resource?: string;
    basePath?: string;
    filterValues?: { [key: string]: any };
    selectedIds?: Identifier[];
}) => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const [update] = useUpdate(AVATAR_TRACEABILITY);
    const { onUnselectItems } = useListContext();
    const notify = useNotify();
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    const userCategory: UserCategory = getUserCategory(userConnected) as UserCategory;

    const [loading, setLoading] = useState<boolean>(false);

    // const [avatarSelectedFound, setAvatarSelectedFound] = useState<Avatar[]>([]);
    const [avatarSelectedFound, setAvatarSelectedFound] = useState<{ [key: Identifier]: Avatar }>({});
    // const [avatarsNotClosed, setAvatarsNotClosed] = useState<Avatar[]>([]);
    // const [setShowAvatarNotClosedInformation] = useState<boolean>(false);

    const avatarData = useSelector((state: ReduxState) => state.admin.resources[AVATARS].data);
    const avatarListIds = useSelector((state: ReduxState) => state.admin.resources[AVATARS].list.ids);

    // const [progress, setProgress] = useState<number>(0);

    let countAvatarsNotClosed = 0;
    let countAvatarsClosed = 0;

    useEffect(() => {
        if (Object.keys(avatarData).length > 0 && Object.values(avatarListIds).length > 0) {
            const avatarDataFound: { [key: Identifier]: Avatar } = {};
            if (props.selectedIds) {
                for (let i = 0; i < props.selectedIds.length; i++) {
                    const selectedId = props.selectedIds[i] ? props.selectedIds[i] : null;
                    const selectedIdFound = Object.values(avatarListIds).find((id: Identifier) =>
                        id ? selectedId === id : undefined
                    );
                    if (selectedIdFound) {
                        const dataFound = Object.values(avatarData).find((item: Record) =>
                            selectedId ? item.id === selectedId : undefined
                        );
                        const isNodeTypeEnd: boolean = dataFound
                            ? isNodeType(dataFound as Avatar, END_NODE_TYPE)
                            : false;
                        const found = !isNodeTypeEnd ? (dataFound as Avatar) : undefined;
                        if (found) {
                            avatarDataFound[found.id] = found;
                        }
                    }
                }
                setAvatarSelectedFound(avatarDataFound);
            }
        }
    }, [props.selectedIds]);

    // console.log('avatarSelectedFound found', avatarSelectedFound);

    const changeNode = (record: Avatar, successMessage: string, failureMessage: string, nodeToChange: Node) => {
        update(
            AVATAR_TRACEABILITY,
            `${getResourceURIFROMAnotherURI(
                record['@id'],
                // @ts-ignore
                record.currentReferencing?.standardReferencing?.id,
                TRACEABILITY_TRACKING
            )}/changenode`,
            {
                next: true,
                node: {
                    // @ts-ignore
                    id: nodeToChange.id,
                },
            },
            record,
            {
                onSuccess: (result: UpdateResult) => {
                    if (result && 'data' in result && result.data) {
                        countAvatarsClosed += 1;
                    }
                    stopLoading(record);
                },
                onFailure: (error: any) => {
                    console.error(
                        'changing node standard referencing id ',
                        record.currentReferencing?.standardReferencing?.id,
                        'error : ',
                        error
                    );
                    countAvatarsNotClosed += 1;
                    // setAvatarsNotClosed([record, ...avatarsNotClosed]);
                    stopLoading(record);
                },
            }
        );
    };

    const stopLoading = (record?: Avatar) => {
        if (countAvatarsClosed === Object.keys(avatarSelectedFound).length || countAvatarsNotClosed === 0) {
            setLoading(false);
            onUnselectItems();
            refresh();
        } else if (countAvatarsNotClosed > 0) {
            setLoading(false);
            // setProgress(0);
            // setShowAvatarNotClosedInformation(true);
            record && notify('app.incidents.information.notClosedCorrectly', 'error', { name: record.name });
        }
        if (countAvatarsClosed === Object.keys(avatarSelectedFound).length) {
            notify('app.incidents.information.closedCorrectly', 'success');
        }
    };

    const handleCloseIncident = () => {
        setLoading(true);
        if (Object.keys(avatarSelectedFound).length === 0) {
            setLoading(false);
        }
        // console.log('avatarSelectedFound', Object.keys(avatarSelectedFound).length);
        Object.values(avatarSelectedFound).forEach((avatar: Record) => {
            if (avatar && !loading) {
                const firstNode = (avatar as Avatar).currentReferencing?.standardReferencing?.runner?.firstNode;
                const nodes = getPathProcess(firstNode);
                const nodeToChange = findNodeByNodeType(nodes, END_NODE_TYPE);
                if (nodeToChange && (avatar as Avatar).currentReferencing?.standardReferencing?.id) {
                    changeNode(
                        avatar as Avatar,
                        'app.incidents.information.incidentClosed',
                        'app.incidents.notification.incidentNotClosed',
                        nodeToChange
                    );
                }
            }
        });
    };

    return (
        <Fragment>
            {loading ? (
                <Grid>
                    <CircularProgress />
                    {/*<CircularProgressWithLabel value={progress} />*/}
                </Grid>
            ) : (
                <>
                    {userCategory !== USER_PROVIDER_CATEGORY && (
                        <TopToolbarButtonStyled disabled={loading} onClick={handleCloseIncident}>
                            <Typography variant={'button'}> {translate('app.incidents.information.close')}</Typography>
                        </TopToolbarButtonStyled>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default IncidentBulkActionButtons;
