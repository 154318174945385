import { TICKETING_PLACE_OR_EVENT } from '../../providers/resources';
import { NodeTypeFiltersForIncidentsInProgress } from '../CONST';

const getPlaceOrEventCount = async (
    onSuccess: (response: any) => void,
    onFailure: (error: any) => void,
    resourceId?: string | null,
    token?: string,
    onlyInProgress?: boolean
) => {
    if (!resourceId || !token) return;
    const initialUrl = `${process.env.REACT_APP_API_ENTRYPOINT}/${TICKETING_PLACE_OR_EVENT}/${resourceId}/v2/event/count?total_indirect_children=true`;

    const url = new URL(initialUrl);
    if (onlyInProgress) {
        NodeTypeFiltersForIncidentsInProgress.forEach((filter) => url.searchParams.append('node_types[]', filter));
    }

    await fetch(url.toString(), {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/ld+json',
            'content-type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((dataJson) => {
            const data = dataJson && 'hydra:member' in dataJson ? dataJson['hydra:member'] : [];
            if (data && Array.isArray(data) && data.length >= 0) {
                onSuccess(data);
            }
        })
        .catch((e) => {
            onFailure(e);
        });
};

const getPlaceOrEventPath = async (
    onSuccess: (response: any) => void,
    onFailure: (error: any) => void,
    resourceId?: string | null,
    token?: string
) => {
    if (!resourceId || !token) return;
    const url = `${process.env.REACT_APP_API_ENTRYPOINT}/${TICKETING_PLACE_OR_EVENT}/${resourceId}/path`;
    await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/ld+json',
            'content-type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((dataJson) => {
            if (
                dataJson &&
                'hydra:member' in dataJson &&
                Array.isArray(dataJson['hydra:member']) &&
                dataJson['hydra:member'].length > 0
            ) {
                onSuccess(dataJson['hydra:member']);
            }
        })
        .catch((e) => {
            onFailure(e);
        });
};

export { getPlaceOrEventCount, getPlaceOrEventPath };
