import { LinearProgress, Typography } from '@material-ui/core';
import MUIAvatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import * as _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { GetListResult, useDataProvider, useTranslate } from 'react-admin';
import { TICKETING_MANAGER_PLACES } from '../../../providers/resources';
import { Avatar, TicketingManagerPlaces, User } from '../../../utils/types';
import UserLogoAvatar from '../../../commons/fields/user/userLogoAvatar';

const LIMIT_MANAGER_TO_SHOW = 2;

const CustomMUIAvatar = styled(MUIAvatar)({
    height: 20,
    width: 20,
});

const CustomMUIAvatarTypography = styled(Typography)({
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
});

const ManagerAndDelegatedSubManager = ({ record }: { record: Avatar }) => {
    const avatar = record;
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleDotsClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const [delegatedManager, setDelegatedManager] = useState<User | undefined>();
    const [managers, setManagers] = useState<User[]>([]);
    const [userDelegatedLoaded, setUserDelegatedLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!userDelegatedLoaded) {
            findSubManagerDelegated(avatar).then(() => {
                findManagerInBranch(avatar).then(() => {
                    // setUserDelegatedLoaded(true);
                });
            });
        }
    }, [avatar]);

    const findManagerInBranch = (avatar: Avatar) => {
        return dataProvider
            .getList(TICKETING_MANAGER_PLACES, {
                filter: {
                    find_manager_in_branch: avatar.id,
                    serialization_groups: ['read_user'],
                },
                pagination: {
                    page: 1,
                    perPage: 10,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
            })
            .then((result: GetListResult) => {
                if (result && 'data' in result && result.data.length > 0) {
                    const userManagerPlaces: User[] = (result.data as TicketingManagerPlaces[]).map(
                        (managerPlace) => managerPlace.userManager as User
                    );
                    setManagers(userManagerPlaces);
                }
            })
            .catch((error) => {
                console.error('findManagerInBranch error', error);
            });
    };

    const findSubManagerDelegated = (avatar: Avatar) => {
        return dataProvider
            .getList(TICKETING_MANAGER_PLACES, {
                filter: {
                    placeOrEvent: avatar.id,
                    serialization_groups: ['read_user'],
                },
                pagination: {
                    page: 1,
                    perPage: 10,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
            })
            .then((result: GetListResult) => {
                if (result && 'data' in result && result.data.length > 0) {
                    const subManagerDelegated: User | string = (result.data[0] as TicketingManagerPlaces).userManager;
                    setDelegatedManager(subManagerDelegated as User);
                }
            })
            .catch((error) => {
                console.error('findSubManagerDelegated error', error);
            });
    };

    return (
        <Grid container direction={'column'} spacing={1}>
            {managers.length > 0 && !delegatedManager && (
                <Grid item>
                    <div style={{ display: 'grid', gridTemplateColumns: '30px 30px 30px' }}>
                        {_.slice(managers, 0, LIMIT_MANAGER_TO_SHOW).map((manager, index) => (
                            <Tooltip
                                title={`${manager.firstName} ${manager.lastName}`}
                                key={`-grid-manager-info-${manager.id}-${index}`}
                            >
                                <span>
                                    <UserLogoAvatar user={manager} />
                                </span>
                            </Tooltip>
                        ))}

                        {managers.length > LIMIT_MANAGER_TO_SHOW && (
                            <Fragment>
                                <Tooltip title={translate('app.incidents.seeMore')}>
                                    <CustomMUIAvatar
                                        aria-controls='simple-menu'
                                        aria-haspopup='true'
                                        // @ts-ignore
                                        onClick={handleDotsClick}
                                    >
                                        <CustomMUIAvatarTypography>+{managers.length - 2}</CustomMUIAvatarTypography>
                                    </CustomMUIAvatar>
                                </Tooltip>

                                <Menu
                                    id='simple-menu'
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleDotsClose}
                                >
                                    <Grid
                                        container
                                        direction={'column'}
                                        spacing={2}
                                        style={{ marginLeft: 0, marginRight: 0 }}
                                    >
                                        {_.slice(managers, LIMIT_MANAGER_TO_SHOW, managers.length).map((manager) => (
                                            <Grid item key={manager.id}>
                                                <Grid container spacing={1} alignItems={'center'}>
                                                    <Grid item>
                                                        <UserLogoAvatar user={manager} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>
                                                            {manager.firstName} {manager.lastName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Menu>
                            </Fragment>
                        )}
                    </div>
                </Grid>
            )}

            {delegatedManager && !userDelegatedLoaded && (
                <Grid item>
                    <Tooltip title={`${delegatedManager.firstName} ${delegatedManager.lastName}`}>
                        <span>
                            <UserLogoAvatar user={delegatedManager} />
                        </span>
                    </Tooltip>
                </Grid>
            )}
            {userDelegatedLoaded && (
                <Grid item>
                    <Divider />
                    <LinearProgress />
                </Grid>
            )}
        </Grid>
    );
};

export default ManagerAndDelegatedSubManager;
