import defaultMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
//@ts-ignore
import { resolveBrowserLocale } from 'react-admin';
import customI18nMessages from '../i18n';

import moment from 'moment';

import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/es';

export const DEFAULT_BROWSER_LOCALE = 'fr';
export const DEFAULT_COMPOSED_BROWSER_LOCALE = 'fr-FR';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const i18nProvider = polyglotI18nProvider((locale) => {
    const messages = {
        fr: {
            ...frenchMessages,
            ...customI18nMessages.fr,
        },
        en: {
            ...defaultMessages,
            ...customI18nMessages.en,
        },
        es: {
            ...defaultMessages,
        },
    };

    const finalLocale = locale in messages ? locale : 'fr';
    // const finalLocale = 'fr';

    moment.locale(finalLocale);

    // @ts-ignore
    return messages[finalLocale];
}, resolveBrowserLocale(DEFAULT_BROWSER_LOCALE));

export default i18nProvider;
