import Button from '@material-ui/core/Button';
import MuiCard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';
import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';

// import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import CloneItemDialog from '../../../components/navigation/actions/CloneItemDialog/CloneItemDialog';
import MoveItemDialog from '../../../components/navigation/actions/MoveItemDialog/MoveItemDialog';
import ReorderItemDialog from '../../../components/navigation/actions/ReorderItemDialog/ReorderItemDialog';
import { selectItemListActionCreator, selectTreeLevelItemCreator } from '../../../redux/actions/navigation';
import { sideBarMinWidth } from '../../../theme';
// import { getRandomArbitrary } from '../../../utils/random';
import { AVATARS } from '../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import TreeList from '../TreeList/TreeList';
import useGetList from '../../react-query-hooks/useGetList';
import { useActionsContext } from '../useActionsContext';

const FilterCardSideBarStyled = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        order: -1, // display on the left rather than on the right of the list
        minWidth: sideBarMinWidth,
        borderRadius: '5px !important',
        boxShadow: 'none',
        border: '1px solid #E4E5EB',
        padding: '7px 7px 7px 3px',
        overflow: 'scroll',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CustomButton = styled(Button)({
    margin: '2px 0 10px 7px',
});

interface Props {
    divHeight: number;
}

const CustomTreeView = ({ divHeight }: Props) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const {
        setItemCloneToAction,
        reorderActionState,
        cloneTo,
        moveTo,
        handleCancelMoveTo,
        handleCancelReorder,
        handleCancelClone,
        itemToClone,
        itemToMove,
        moveActionState,
        reorderTo,
        itemToReorder,
        cloneActionState,
    } = useActionsContext();

    const { data, isInitialLoading } = useGetList(
        AVATARS,
        {
            pagination: {
                page: 1,
                perPage: 100,
            },
            sort: {
                field: 'id',
                order: 'ASC',
            },
            filter: {
                lvl: 0,
                'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                serialization_groups: ['read_avatar_parent', 'read_avatar_root'],
            },
        },
        {
            keyToFind: 'rootNavigationAvatars',
        }
    );

    const onNodeSelect = (event: any, value: string) => {
        if (!event.defaultPrevented && value && value.length > 0 && value[0] === '/') {
            // if (value && value.length > 0 && value[0] === '/') {
            dispatch(selectTreeLevelItemCreator.fn(value));
            dispatch(selectItemListActionCreator.fn(null));
        }
    };

    return (
        <FilterCardSideBarStyled key={'filter-card-sidebar-styled'} style={{ height: divHeight - 56 }}>
            {moveActionState && (
                <CustomButton variant={'contained'} color={'primary'} onClick={handleCancelMoveTo}>
                    {translate('app.buttons.cancelMove')}
                </CustomButton>
            )}

            {reorderActionState && (
                <CustomButton variant={'contained'} color={'primary'} onClick={handleCancelReorder}>
                    {translate('app.buttons.cancelReorder')}
                </CustomButton>
            )}

            {cloneActionState && (
                <Fragment>
                    <CustomButton variant={'contained'} color={'primary'} onClick={handleCancelClone}>
                        {translate('app.buttons.cancelClone')}
                    </CustomButton>
                    <CustomButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => setItemCloneToAction(itemToClone)}
                    >
                        {translate('app.buttons.cloneAsRoot')}
                    </CustomButton>
                </Fragment>
            )}

            <Grid container direction={'column'}>
                <Grid item>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
                        defaultExpandIcon={<ChevronRightIcon style={{ fontSize: 20 }} />}
                        onNodeSelect={onNodeSelect}
                    >
                        {data && data.length > 0 ? (
                            data.map((item) => <TreeList key={item['@id']} parent={item} />)
                        ) : (
                            <Fragment>
                                {isInitialLoading ? (
                                    <LinearProgress style={{ marginRight: 10, marginTop: 10, marginBottom: 10 }} />
                                ) : (
                                    <Box sx={{ width: '100%' }}>
                                        <span>{translate('app.navigation.tree.empty')}</span>
                                    </Box>
                                )}
                            </Fragment>
                        )}
                    </TreeView>
                </Grid>
            </Grid>

            {moveActionState && moveTo !== null && itemToMove !== null && (
                <MoveItemDialog onClose={handleCancelMoveTo} onCancel={handleCancelMoveTo} />
            )}

            {cloneActionState && cloneTo !== null && itemToClone !== null && (
                <CloneItemDialog onClose={handleCancelClone} onCancel={handleCancelClone} />
            )}

            {reorderActionState && reorderTo !== null && itemToReorder !== null && (
                <ReorderItemDialog onClose={handleCancelReorder} onCancel={handleCancelReorder} />
            )}
        </FilterCardSideBarStyled>
    );
};

export default CustomTreeView;
