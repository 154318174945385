import React, { useEffect, useState } from 'react';
import { GetListResult, Record, ReduxState, useDataProvider, useNotify } from 'react-admin';
import {
    Avatar,
    TicketingBridgeResponseForwarderMetabase,
    TicketingEventUser,
    TicketingUserProviderEventConfiguration,
    User,
} from '../../../utils/types';
import { useSelector } from 'react-redux';
import {
    TICKETING_EVENT_USERS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../providers/resources';
import { getTicketingUserProviderEventConfig } from '../../../utils/user/resolutionDeadline';
import {
    getRowDataInResponseForwarderToMetabaseByDisplayName,
    ResponseForwarderToMetabaseType,
} from '../../../utils/ticketingBridgeMetabase/viewsDynamic';

import WarningIcon from '@material-ui/icons/Warning';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { CustomReducerState } from '../../../redux/reducers';
import { Question13 } from '../../../utils/CONST';

interface Props {
    record: Avatar | undefined;
}

const IncidentTimeFromTheBeginningField = (props: Props) => {
    // const data: TicketingBridgeResponseForwarderMetabase | undefined = useSelector((state: CustomReducerState) =>
    //     props.record && props.record.id in state.ticketing.incidents.metabaseData
    //         ? state.ticketing.incidents.metabaseData[props.record.id][13]
    //         : undefined
    // );
    //
    // const [timeFromTheBeginning, setTime] = useState<number>(0);
    //
    // useEffect(() => {
    //     if (data) {
    //         if (Array.isArray(data.rows) && data.rows.length > 0) {
    //             setTime(data.rows[0][Question13.timeInHourFromBeginning]);
    //         }
    //     }
    // }, [data]);

    return (
        <span>
            <Typography variant={'body2'}>
                {/*{timeFromTheBeginning*/}
                {/*    ? moment(props.record?.creationDate)*/}
                {/*          .add(timeFromTheBeginning, 'hours')*/}
                {/*          .to(moment(props.record?.creationDate))*/}
                {/*    : '...'}*/}
                {moment(props.record?.creationDate).from(moment())}
            </Typography>{' '}
        </span>
    );
};

export default IncidentTimeFromTheBeginningField;
