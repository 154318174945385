import { styled } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { TextInput, useCreate, useDelete, useNotify, useUpdate } from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import { AVATAR_PROPERTY } from '../../../providers/resources';
import { Avatar, AvatarProperty } from '../../../utils/types';
import CustomSubmitButton from '../../inputs/CustomSubmitButton';
import CustomSwitchInput from '../../inputs/CustomSwitchInput';
import SwitchIconChildrenContainer from '../../SwitchIconChildrenContainer/SwitchIconChildrenContainer';
import { getViolations, ValidationError } from '../../../utils/validations/parseValidations';

const KEY = '_key';
const VALUE = '_value';

const PROPERTY_ENABLED = 'enabled';

export const CREATE_CALLED = 'create-called';
export const UPDATE_CALLED = 'update-called';
export const DELETE_CALLED = 'delete-called';

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 15px auto',
    width: '100%',
});

interface FormProps {
    item: AvatarProperty | null;
    avatar: Avatar;
    propertyDefinition: string;
    icon: ReactElement;
    labelId: string;
    labelValue: string;
    advertise?: (called: string) => void;
    disabled: boolean;
}

const Form = (props: FormProps) => {
    const [create] = useCreate();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();
    const notify = useNotify();

    const onSubmit = (data: { [KEY]: string; [VALUE]: string; [PROPERTY_ENABLED]: boolean }) => {
        if (!props.item) {
            createAvatarProperty(data);
        } else {
            updateAvatarProperty(data);
        }
    };

    const createAvatarProperty = (data: { [KEY]: string; [VALUE]: string; [PROPERTY_ENABLED]: boolean }) => {
        create(
            AVATAR_PROPERTY,
            {
                name: data[KEY],
                value: data[VALUE],
                avatar: props.avatar.id,
                definition: props.propertyDefinition,
                private: false,
                enabled: data[PROPERTY_ENABLED],
            },
            {
                onSuccess: () => {
                    if (props.advertise) {
                        props.advertise(CREATE_CALLED);
                    }
                },
                onFailure: (errorResponse: ValidationError) => {
                    if (errorResponse.status === 400) {
                        const violations = getViolations(errorResponse);
                        notify(violations.length > 0 ? violations[0].message : 'app.errors.server', 'error');
                    } else {
                        notify('app.errors.server', 'error');
                    }
                },
            }
        );
    };

    const updateAvatarProperty = (data: { [KEY]: string; [VALUE]: string; [PROPERTY_ENABLED]: boolean }) => {
        update(AVATAR_PROPERTY, props.item?.id, data, props.item, {
            onSuccess: () => {
                if (props.advertise) {
                    props.advertise(CREATE_CALLED);
                }
            },
            onFailure: (errorResponse: ValidationError) => {
                if (errorResponse.status === 400) {
                    const violations = getViolations(errorResponse);
                    notify(violations.length > 0 ? violations[0].message : 'app.errors.server', 'error');
                } else {
                    notify('app.errors.server', 'error');
                }
            },
        });
    };

    const deleteAvatarProperty = () => {
        if (props.item) {
            deleteOne(AVATAR_PROPERTY, props.item.id, props.item, {
                onSuccess: () => {
                    if (props.advertise) {
                        props.advertise(DELETE_CALLED);
                    }
                },
                onFailure: () => {
                    if (props.advertise) {
                        props.advertise(DELETE_CALLED);
                    }
                },
            });
        }
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                [KEY]: props.item?.name,
                [VALUE]: props.item?.value,
                [PROPERTY_ENABLED]: props.item ? props.item.enabled : false,
            }}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <SwitchIconChildrenContainer
                            switchC={<CustomSwitchInput source={PROPERTY_ENABLED} disabled={props.disabled} />}
                            icon={props.icon}
                        >
                            <CustomDiv>
                                <TextInput
                                    variant={'standard'}
                                    source={KEY}
                                    label={props.labelId}
                                    helperText={false}
                                    fullWidth
                                    disabled={props.disabled}
                                />

                                <div />

                                <TextInput
                                    variant={'standard'}
                                    source={VALUE}
                                    label={props.labelValue}
                                    helperText={false}
                                    fullWidth
                                    disabled={props.disabled}
                                />
                            </CustomDiv>

                            <CustomSubmitButton
                                handleDelete={props.item ? deleteAvatarProperty : undefined}
                                handleSubmit={handleSubmit}
                                fieldKeys={[KEY, VALUE, PROPERTY_ENABLED]}
                            />
                        </SwitchIconChildrenContainer>
                    </form>
                );
            }}
        />
    );
};

export default Form;
