const extractIdFromURI = (URI: string) => {
    if (!URI) return null;

    const mat = URI.split('/');

    return mat[mat.length - 1];
};

const extractResourceFromURI = (URI: string, order = 1) => {
    const mat = URI.split('/');

    return mat[mat.length - 1 - order];
};

/**
 * Get uri from one item
 * @param item
 * @returns {*}
 */
const getUriFromItem = (item: any) => {
    return item && typeof item === 'object' && '@id' in item ? item['@id'] : item;
};

/**
 * Get uri from items
 * @param items
 * @returns {*}
 */
const getUriFromItems = (items: any[]) => {
    return items && Array.isArray(items) ? items.map((item) => getUriFromItem(item)) : [];
};

const getResourceURIFROMAnotherURI = (anotherURI: string, id: string, resource: string): string | null => {
    const anotherURIMatched = anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)');
    return anotherURIMatched && anotherURIMatched.length > 1 ? `${anotherURIMatched[1]}/${resource}/${id}` : null;
};

const getURIFROMAnotherURI = (anotherURI: string): string | null => {
    const anotherURIMatched = anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)');
    return anotherURIMatched && anotherURIMatched.length > 1 ? `${anotherURIMatched[1]}` : null;
};

const getResourceURIFromMAnotherURIWithoutId = (anotherURI: string, resource: string) =>
    // @ts-ignore
    `${anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)')[1]}/${resource}`;

export default extractIdFromURI;
export {
    extractResourceFromURI,
    getUriFromItem,
    getUriFromItems,
    getResourceURIFROMAnotherURI,
    getURIFROMAnotherURI,
    getResourceURIFromMAnotherURIWithoutId,
};
