import {
    ACTIONS,
    registerGlobalDashboard,
    RegisterGlobalDashboardAction,
    UpdateGlobalDashboardAction,
    updateGlobalDashboard,
    updateGlobalFilter,
    updateDashboardVersion,
    UpdateDashboardVersionAction,
} from '../actions/dashboard';
import { ChartDashboardId } from '../../utils/types';
import { clearTicketingStateCreator } from '../actions/ticketingState';

interface ChartRegisterData {
    instanceId: string;
    chartDashboardId: ChartDashboardId;
    customFields: any;
}

export interface DashboardGlobalState {
    // key: ChartDashboardId
    [key: number]: ChartRegisterData[];
}

export interface DashboardState {
    global: DashboardGlobalState;
    globalFilters: {
        period: {
            beforeAt?: string;
            afterAt?: string;
            selectedOption?: string;
            enabled?: boolean;
        };
    };
    loadedLocalstorage: boolean;
    version: number;
}

const InitialState: DashboardState = {
    global: {},
    globalFilters: {
        period: {},
    },
    loadedLocalstorage: false,
    version: 0,
};

const GLOBAL_KEY_LS = 'dashboard.global';
const GLOBAL_FILTER_KEY_LS = 'dashboard.global.filter';

const saveToLocalstorage = (key: string, state: DashboardGlobalState) => {
    localStorage.setItem(key, JSON.stringify(state));
};

const loadFromLocalstorage = (key: string) => {
    const rawState = localStorage.getItem(key);

    if (rawState) {
        return JSON.parse(rawState);
    }

    return null;
};

const reducer = (state: DashboardState = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case registerGlobalDashboard.action: {
            const payload = (action as RegisterGlobalDashboardAction).payload;

            const chartDashboardIdState = Array.isArray(state.global[payload.chartDashboardId])
                ? state.global[payload.chartDashboardId]
                : [];

            if (payload.add) {
                const globalState = {
                    ...state.global,
                    [payload.chartDashboardId]: [
                        ...chartDashboardIdState,
                        {
                            instanceId: payload.instanceId,
                            chartDashboardId: payload.chartDashboardId,
                            customFields: payload.customFields ? payload.customFields : {},
                        },
                    ],
                };

                saveToLocalstorage(GLOBAL_KEY_LS, globalState);

                return {
                    ...state,
                    global: globalState,
                };
            }

            const list = payload.chartDashboardId in state.global ? state.global[payload.chartDashboardId] : [];

            const globalState = {
                ...state.global,
                [payload.chartDashboardId]: [...list.filter((item) => item.instanceId !== payload.instanceId)],
            };

            saveToLocalstorage(GLOBAL_KEY_LS, globalState);

            return {
                ...state,
                global: globalState,
            };
        }
        case updateGlobalDashboard.action: {
            const payload = (action as UpdateGlobalDashboardAction).payload;

            if (!(payload.chartDashboardId in state.global)) {
                return state;
            }

            const chartDashboardConfigs = state.global[payload.chartDashboardId];
            const index = chartDashboardConfigs.findIndex((item) => item.instanceId === payload.instanceId);
            if (index !== -1) {
                chartDashboardConfigs[index] = {
                    ...chartDashboardConfigs[index],
                    customFields: {
                        ...chartDashboardConfigs[index].customFields,
                        ...payload.customFields,
                    },
                };

                const globalState = {
                    ...state.global,
                    [payload.chartDashboardId]: [...chartDashboardConfigs],
                };
                saveToLocalstorage(GLOBAL_KEY_LS, globalState);

                return {
                    ...state,
                    global: globalState,
                };
            }

            return state;
        }
        case updateGlobalFilter.action: {
            const globalFiltersState = {
                period: {
                    ...action.payload,
                },
            };

            saveToLocalstorage(GLOBAL_FILTER_KEY_LS, globalFiltersState);

            return {
                ...state,
                globalFilters: globalFiltersState,
            };
        }
        case updateDashboardVersion.action: {
            return {
                ...state,
                version: (action as UpdateDashboardVersionAction).payload.version,
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default: {
            if (!state.loadedLocalstorage) {
                const globalState = loadFromLocalstorage(GLOBAL_KEY_LS);
                const globalFilterState = loadFromLocalstorage(GLOBAL_FILTER_KEY_LS);

                return {
                    ...state,
                    global: globalState ? globalState : {},
                    globalFilters: globalFilterState ? globalFilterState : { period: {} },
                    loadedLocalstorage: true,
                };
            }

            return state;
        }
    }
};

export default reducer;
