import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AVATARS_MOVE_TO } from '../../../../providers/resources';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';
import { useActionsContext } from '../../../../commons/tree/useActionsContext';

interface Props {
    onClose: () => void;
    onCancel: () => void;
}

const MoveItemDialog = ({ onClose, onCancel }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const { moveTo, itemToMove } = useActionsContext();

    const [loadingMove, setLoadingMove] = useState(false);

    const dataProvider = useDataProvider();

    const handleOkClick = () => {
        if (moveTo && itemToMove) {
            setLoadingMove(true);

            const dataToSend = { child: itemToMove.id, moveTo: moveTo.id };

            dataProvider
                .create(AVATARS_MOVE_TO, { data: JSON.stringify(dataToSend) })
                .then(() => {
                    setLoadingMove(false);

                    if ('parent' in itemToMove && itemToMove.parent && 'id' in itemToMove.parent) {
                        queryClient.invalidateQueries([
                            `navigationParent_${extractIdFromURI(itemToMove.parent.id as string)}`,
                        ]);
                    } else {
                        queryClient.invalidateQueries([`rootNavigationAvatars`]);
                    }

                    queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(moveTo.id as string)}`]);

                    onClose();
                })
                .catch((error: any) => {
                    if (error.status === 400) {
                        setLoadingMove(false);
                        notify('app.navigation.moveDialog.twoRootsError', 'error');
                        onClose();
                    }
                });
        }
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.moveDialog.title')}
            open={true}
            onClose={() => null}
            icon={<SettingsIcon />}
            maxWidth={'xs'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onCancel}
                    withDelete
                    onDeleteClick={handleOkClick}
                    deleteButtonLabel={translate('app.buttons.validate')}
                    isValidForm={true}
                    isSubmitting={loadingMove}
                />
            }
        >
            <Grid container direction={'column'}>
                <Grid item>
                    <Typography>
                        {translate('app.navigation.moveDialog.item')}: <b>{itemToMove ? itemToMove.name : '...'}</b>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {translate('app.navigation.moveDialog.moveTo')}: <b>{moveTo ? moveTo.name : '...'}</b>
                    </Typography>
                </Grid>
            </Grid>
        </DialogTemplate>
    );
};

export default MoveItemDialog;
