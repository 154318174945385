export const GROUP_METADATA_AVATAR_TYPE_EVENT = 'ticketing.type.event';
export const GROUP_METADATA_AVATAR_TYPE_LEVEL = 'ticketing.type.level';

export const AVATAR_IDENTIFIER_CTN = 'ctn';
export const AVATAR_IDENTIFIER_ACCESS_CODE = 'ticketing_access_code';
export const AVATAR_IDENTIFIER_SEPARATOR = '::';
export const AVATAR_IDENTIFIER_REMINDER_TICKETING_GOOGLE_CALENDAR_EVENT = 'ticketing_reminder_google_calendar_event';
export const AVATAR_IDENTIFIER_TICKETING_EVENT_DELEGATED = 'ticketing_event_delegated';
export const AVATAR_TICKETING_USER_CONFIRMATION_AMOUNT = 'user_confirmation_amount';
export const AVATAR_TICKETING_CUSTOM_FIELDS = 'ticketing';

export const TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD = 'ticketing_allowed_multiple_events';
export const TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD =
    'ticketing_when_multiple_events_title_should_be_overwrite';

export const IMAGE_MAX_SIZE = 9000000;

//user providers tags
export const USER_UNDER_CONTRACT_FILTER = 'ticketing_user_provider__under_contract';
export const TICKETING_EVENT_WITH_USER_FILTER = 'ticketing_user_provider__has_user';
export const TICKETING_USER_SERVICE_PROVIDER_TAG = 'ticketing_service_provider';
export const TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG = 'ticketing_service_provider_company';
export const TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG = 'ticketing_service_provider_under_contract';
export const TICKETING_SUB_MANAGER_TAG = 'ticketing_sub_manager';
export const TICKETING_MANAGER_TAG = 'ticketing_manager';
export const USER_PROVIDER_CATEGORY = 'Provider';
export const USER_SUB_MANAGER_CATEGORY = 'SubManager';
export const USER_MANAGER_CATEGORY = 'Manager';

export const USER_PROVIDER_TAGS = [TICKETING_USER_SERVICE_PROVIDER_TAG, TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG];
export const MANAGER_AND_SUBMANAGER__PROVIDER_TAGS = [TICKETING_SUB_MANAGER_TAG, TICKETING_MANAGER_TAG];

//Medias Logo
export const TAG_TICKETING_LOGO = 'ticketing_logo';
export const TAG_INSTRUCTION_HELP = 'instruction_help';

// Avatar Property Contact
export const ITEM_NAME_CONTACT_FIELD = 'name';
export const ITEM_PHONE_CONTACT_FIELD = 'phone';
export const ITEM_CONTACT_DEFINITION = 'contact_to';
export const TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD = 'ticketing.file_system.enabled';

// Avatar Property Extra information
export const ITEM_EXTRA_INFORMATION_DEFINITION = 'extra_information';
export const ITEM_CODE_PIN_EXTRA_INFORMATION_DEFINITION = 'code-pin';

// Avatar Property Place as Root
export const PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION = 'ticketing.system_configuration';
export const PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION = 'address_root';

// Avatar Property
export const TICKETING_LOCATION_OVERRIDDEN_PROPERTY = 'ticketing.location_overridden';
export const TICKETING_REMINDER_GOOGLE_CALENDAR_EVENT_PROPERTY = 'ticketing_reminder_google_calendar_event';
export const TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_ALERT_IN_HOURS = 'alertInHours';
export const TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_WHEN = 'when';
export const TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_MESSAGE64 = 'message64';

//ticketing google calendar reminder property value has the format: alertInHours:<alertInHoursValue>;;when:<whenValue>;;message64:<message64Value>
export const TICKETING_GOOGLE_CALENDAR_REMINDER_PROPERTY_SEPARATOR = ';;';

// Avatar Property for notifing user of new incident
const PLACE_PROPERTY_TICKETING_NOTIFY_EVENT_CREATED_USER_ID = 'ticketing.notify.event_created.user_id';
const EMAIL_AVATAR_PROPERTY_NAME = 'email_';

// Avatar Property Definition Reporter Evaluation - property created in finger when incident creator let the opinion about intervention
export const TICKETING_REPORTER_EVALUATION_AVATAR_PROPERTY_DEFINITION = 'ticketing_reporter_evaluation';
export const TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY =
    'ticketing_quality_of_intervention_evaluation';
export const TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY = 'ticketing_speed_of_intervention_evaluation';
export const TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY =
    'ticketing_experience_of_intervention_evaluation';
export const TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY =
    'ticketing_comment_of_intervention_evaluation';

// NODES
export const END_NODE_TYPE = 'NODE_TYPE_END';
export const START_NODE_TYPE = 'NODE_TYPE_START';
export const TICKETING_OPENED_NODE_TYPE = 'NODE_TYPE_TICKETING_OPENED';
export const TICKETING_REPAIRING_NODE_TYPE = 'NODE_TYPE_TICKETING_REPAIRING';
export const TICKETING_REPAIRED_NODE_TYPE = 'NODE_TYPE_TICKETING_REPAIRED';
// export const TICKETING_PAUSE_NODE_TYPE = 'NODE_TYPE_TICKETING_PAUSE';
export const TICKETING_COUNTER_TIME_NODE_TYPE = 'NODE_TYPE_PAUSE_COUNTER_TIME';
export const TICKETING_TOOK_INTO_ACCOUNT_NODE_TYPE = 'NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT';
export const TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE = 'NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING';
export const TICKETING_TAKE_INTO_ACCOUNT_NODE_TYPE = 'NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT';
export const TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE = 'NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT';
export const TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE = 'NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER';
export const TICKETING_BEFORE_OPENED_NODE_TYPE = 'NODE_TYPE_TICKETING_BEFORE_OPENED';
export const TICKETING_REFUSED_TAKE_INTO_ACCOUNT_NODE_TYPE = 'NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT';

// NODES COLORS
export const NODE_TYPE_TICKETING_OPENED_BACKGROUND_COLOR = '#F4A2A2';
export const NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_BACKGROUND_COLOR = '#F4A2A2';
export const NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR = '#F4D8A2';
export const NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_BACKGROUND_COLOR = '#F3F4A2';
export const NODE_TYPE_TICKETING_REPAIRING_BACKGROUND_COLOR = '#A2D7F4';
// export const NODE_TYPE_PAUSE_COUNTER_TIME_BACKGROUND_COLOR = '#D0F4A2';
export const NODE_TYPE_PAUSE_COUNTER_TIME_BACKGROUND_COLOR = '#F4A2A2';
export const NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR = '#F4A2A2';
export const NODE_TYPE_TICKETING_REPAIRED_BACKGROUND_COLOR = '#D0F4A2';
export const NODE_TYPE_END_BACKGROUND_COLOR = '#D0F4A2';

export const NODE_TYPE_TICKETING_OPENED_COLOR = '#754343';
export const NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_COLOR = '#754343';
export const NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_COLOR = '#65523a';
export const NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_COLOR = '#66693d';
export const NODE_TYPE_TICKETING_REPAIRING_COLOR = '#425f70';
// export const NODE_TYPE_PAUSE_COUNTER_TIME_COLOR = '#5b7442';
export const NODE_TYPE_PAUSE_COUNTER_TIME_COLOR = '#754343';
export const NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_COLOR = '#754343';
export const NODE_TYPE_TICKETING_REPAIRED_COLOR = '#5b7442';
export const NODE_TYPE_END_COLOR = '#5b7442';

export const NodeTypeFiltersForHiddenClosedIncidents = [
    TICKETING_OPENED_NODE_TYPE,
    TICKETING_REPAIRING_NODE_TYPE,
    TICKETING_REPAIRED_NODE_TYPE,
    TICKETING_COUNTER_TIME_NODE_TYPE,
    TICKETING_TOOK_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE,
    TICKETING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE,
];

export const NodeTypeFiltersForHiddenResolvedIncidents = [
    END_NODE_TYPE,
    TICKETING_OPENED_NODE_TYPE,
    TICKETING_REPAIRING_NODE_TYPE,
    TICKETING_COUNTER_TIME_NODE_TYPE,
    TICKETING_TOOK_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE,
    TICKETING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE,
];

export const NodeTypeFiltersForIncidentsInProgress = [
    TICKETING_OPENED_NODE_TYPE,
    TICKETING_REPAIRING_NODE_TYPE,
    TICKETING_COUNTER_TIME_NODE_TYPE,
    TICKETING_TOOK_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_JUST_BEFORE_START_REPAIRING_NODE_TYPE,
    TICKETING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_TAKE_INTO_ACCOUNT_NODE_TYPE,
    TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE,
];

// key => status's name to filter
// value => translations
export const IncidentStatus = {
    Ouvert: 'app.incidents.status.opened',
    "En attente d'attribution par le gestionnaire": 'app.incidents.status.assignServiceProvider',
    "Attribué, en attente de prise en charge par l'intervenant": 'app.incidents.status.waitTakeIntoAccount',
    'Prise en compte': 'app.incidents.status.takenIntoAccount',
    Refusé: 'app.incidents.status.refusedTakeIntoAccount',
    "Début de l'intervention": 'app.incidents.status.startOfIntervention',
    'Intervention en cours': 'app.incidents.status.repairing',
    'Intervention suspendue': 'app.incidents.status.pauseCounterTime',
    "Fin d'intervention": 'app.incidents.status.repaired',
    Clôturé: 'app.incidents.status.closed',
};

const incidentStatusKeys = Object.keys(IncidentStatus);

// key => status's name to filter
// value => translations
export const IncidentNameAndColorStyleStatus: { [key: string]: { color: string; backgroundColor: string } } = {
    [incidentStatusKeys[0]]: {
        backgroundColor: NODE_TYPE_TICKETING_OPENED_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_OPENED_COLOR,
    },
    [incidentStatusKeys[1]]: {
        backgroundColor: NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_BACKGROUND_COLOR,
        color: NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER_COLOR,
    },
    [incidentStatusKeys[2]]: {
        backgroundColor: NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT_COLOR,
    },
    [incidentStatusKeys[3]]: {
        backgroundColor: NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING_COLOR,
    },
    [incidentStatusKeys[4]]: {
        backgroundColor: NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT_COLOR,
    },
    [incidentStatusKeys[5]]: {
        backgroundColor: NODE_TYPE_TICKETING_REPAIRING_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_REPAIRING_COLOR,
    },
    [incidentStatusKeys[6]]: {
        backgroundColor: NODE_TYPE_TICKETING_REPAIRING_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_REPAIRING_COLOR,
    },
    [incidentStatusKeys[7]]: {
        backgroundColor: NODE_TYPE_PAUSE_COUNTER_TIME_BACKGROUND_COLOR,
        color: NODE_TYPE_PAUSE_COUNTER_TIME_COLOR,
    },
    [incidentStatusKeys[8]]: {
        backgroundColor: NODE_TYPE_TICKETING_REPAIRED_BACKGROUND_COLOR,
        color: NODE_TYPE_TICKETING_REPAIRED_COLOR,
    },
    [incidentStatusKeys[9]]: {
        backgroundColor: NODE_TYPE_END_BACKGROUND_COLOR,
        color: NODE_TYPE_END_COLOR,
    },
};

export const CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER = 'current_history_note_types[one]';

// Stats
export const Question2 = {
    ranking: 0,
    avatarId: 1,
    name: 2,
    groupMetadataAvatarType: 3,
    rootId: 4,
    date: 5,
    amountOfIncidents: 6,
    percentOfIncidents: 7,
};

export const Question13 = {
    avatarId: 0,
    navigation: 1,
    user: 2,
    userId: 3,
    companyId: 4,
    signalingDate: 5,
    avatarName: 6,
    timeInHourFromBeginning: 7,
    resolutionDate: 8,
    workResolutionDelay: 9,
};

export const RICH_TEXT_SEND_DATA_VERSION_KEY = 'version_0.content';

// docs to show in incident view
export const INVOICE_DOC = 'invoice';
export const QUOTE_DOC = 'quote';
export const FINANCE_INVOICE_FILE_TAG = 'finance:invoice';
export const FINANCE_QUOTE_FILE_TAG = 'finance:quote';
export const FINANCE_FILE_TAG = 'finance';

// Access to service - internal name of profiles
export const TICKETING_MEMORANDUM_TRUSTEE_INTERNAL_NAME_PROFILE = 'TICKETING_MEMORANDUM_TRUSTEE';
