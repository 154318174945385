import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { TopToolbar, useRefresh, useTranslate } from 'react-admin';
import { ButtonFontWeightBoldStyles, TopToolbarButtonStyled, TopToolbarStyles } from '../../../theme';
import SimpleActionMenu from './menuActions';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles({
    // @ts-ignore
    topToolbar: {
        ...TopToolbarStyles,
    },
    buttonFontWeightBold: {
        ...ButtonFontWeightBoldStyles,
    },
});

const NavigationListActions = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const refreshView: () => void = useRefresh();
    const queryClient = useQueryClient();

    const fullRefresh = () => {
        refreshView();
        queryClient.invalidateQueries([`rootNavigationAvatars`]);
        queryClient.invalidateQueries({
            predicate: (query: any) => query.queryKey[0].includes('navigationParent_'),
        });
    };

    return (
        <TopToolbar className={classes.topToolbar}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                    <SimpleActionMenu />
                </Grid>
                <Grid item>
                    <TopToolbarButtonStyled
                        className={classes.buttonFontWeightBold}
                        startIcon={<RefreshIcon />}
                        onClick={fullRefresh}
                    >
                        {translate('app.menuActions.refresh')}
                    </TopToolbarButtonStyled>
                </Grid>
            </Grid>
        </TopToolbar>
    );
};

export default NavigationListActions;
