import React, { FC } from 'react';
import { BooleanInput } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import makeStyles from '@material-ui/styles/makeStyles';
import { FieldHeaderListStyles } from '../../../../../../theme';

const useStyles = makeStyles(() => ({
    input: {
        flexDirection: 'row',
    },
}));

interface Props {
    source: string;
    label: string;
}

const CustomBooleanInput: FC<Props> = (props: Props) => {
    const field = useField(props.source);
    const form = useForm();
    const classes = useStyles();

    const onClick = () => {
        form.change(props.source, !field.input.value);
        form.submit();
    };

    return <BooleanInput onClick={onClick} source={props.source} label={props.label} className={classes.input} />;
};

export default CustomBooleanInput;
