import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import { CreateResult, Record, UpdateResult, useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import UserConfig from '../userConfig';
import { cleanUserDataBeforeSending, createUser, resolveCustomFields, resolveTags } from '../../../../utils/user/crud';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import { Company, CreateMedia, CreateUser, Media, Profile, User } from '../../../../utils/types';
import {
    ACCESS_TO_SERVICE_FIELD,
    USER_FIRST_NAME_FIELD,
    USER_LAST_NAME_FIELD,
    USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD,
    USER_SERVICE_PROVIDER_FIELD,
    USER_SERVICE_PROVIDER_TYPE_FIELD,
    USER_TYPE_FIELD,
    USER_USER_NAME_FIELD,
    FormCreateUserData,
} from '../CONST';
import {
    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../../utils/user/customFields';
import { MEDIAS, USERS } from '../../../../providers/resources';

interface CreateFormProps {
    open: boolean;
    fromExternalInput?: boolean;
    initialName?: string;
    handleClose: () => void;
    handleCreateOnSuccess?: (data: Record) => void;
}

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: '100%',
    },
}));

const CreateForm = ({ open, handleClose, fromExternalInput }: CreateFormProps) => {
    const [loading, isLoading] = useState(false);
    const [profileForCurrentUserTypeSelected, setProfileForCurrentUserTypeSelected] = useState<Profile | undefined>();
    const [image, setImage] = useState<{ name: string; data: string } | null>();

    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    const companyUserConnected: Company | undefined | string = useSelector(
        (state: CustomReducerState) => userConnected?.company
    );

    const handleSubmit = (formValues: CreateUser & FormCreateUserData) => {
        let companyId: string | null = null;
        if (!tokenWithRoles || !companyUserConnected) return;

        if (companyUserConnected) {
            if (typeof companyUserConnected === 'string') {
                companyId = companyUserConnected;
            } else if (companyUserConnected && typeof companyUserConnected === 'object') {
                companyId = companyUserConnected['@id'] as string;
            }
        }
        if (!companyId) return;

        // @ts-ignore
        const tags = resolveTags(formValues);
        const userServiceProviderTypeValue =
            USER_SERVICE_PROVIDER_TYPE_FIELD in formValues ? formValues[USER_SERVICE_PROVIDER_TYPE_FIELD] : '';

        let dataToSend = {
            ...formValues,
            [USER_LAST_NAME_FIELD]:
                userServiceProviderTypeValue === USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD
                    ? formValues[USER_FIRST_NAME_FIELD]
                    : formValues[USER_LAST_NAME_FIELD],
            company: companyId,
            tags,
        };

        if (userServiceProviderTypeValue === USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD) {
            dataToSend = {
                ...dataToSend,
                [USER_USER_NAME_FIELD]: formValues[USER_FIRST_NAME_FIELD],
            };
        }

        if (ACCESS_TO_SERVICE_FIELD in dataToSend && dataToSend[ACCESS_TO_SERVICE_FIELD]) {
            dataToSend = {
                ...dataToSend,
                // @ts-ignore
                profiles: [formValues[ACCESS_TO_SERVICE_FIELD]],
            };
        }

        // @ts-ignore
        let newDataToSend = cleanUserDataBeforeSending(dataToSend);

        const userTypeValue = USER_TYPE_FIELD in formValues ? formValues[USER_TYPE_FIELD] : '';
        if (userTypeValue === USER_SERVICE_PROVIDER_FIELD) {
            // @ts-ignore
            const customFields = resolveCustomFields(formValues);
            newDataToSend = {
                ...newDataToSend,
                customFields,
            };
        }
        if (profileForCurrentUserTypeSelected) {
            const newProfiles = newDataToSend?.profiles
                ? [...newDataToSend.profiles, profileForCurrentUserTypeSelected?.id as string]
                : [profileForCurrentUserTypeSelected?.id as string];
            newDataToSend = {
                ...newDataToSend,
                profiles: [...newProfiles],
            };
        }

        isLoading(true);

        createUser(tokenWithRoles, newDataToSend)
            .then(async (user: User) => {
                if (!user) {
                    isLoading(false);
                    notify('app.users.create.fail', 'error');
                } else {
                    if (image) {
                        const media: Media | null = await addMedia(user.id as string);
                        if (media) {
                            const userUpdated = await updateImageUser(user, { image: media.id as string });
                            if (userUpdated) {
                                isLoading(false);
                                notify('app.users.create.success', 'success');
                                handleClose();
                                refresh();
                            }
                        } else {
                            isLoading(false);
                            handleClose();
                            refresh();
                        }
                    } else {
                        isLoading(false);
                        notify('app.users.create.success', 'success');
                        handleClose();
                        refresh();
                    }
                }
            })
            .catch((error: any) => {
                isLoading(false);
                console.error('Error creating users', error);
                notify('app.users.create.fail', 'error');
            });
    };

    const updateImageUser = (user: User, data: any): Promise<User | null | undefined> | null => {
        const uri = '@id' in user ? user['@id'] : null;
        if (!uri) return null;
        return dataProvider
            .update(USERS, {
                id: uri,
                previousData: { ...user },
                data,
            })
            .then((result: UpdateResult) => {
                if (result && 'data' in result && result.data) {
                    return result.data as User;
                }
            })
            .catch((error) => {
                console.error('error creating media', error);
                notify('app.users.create.fail', 'error');
                isLoading(false);
                return null;
            });
    };

    const addMedia = (resourceId: string): Promise<Media | null> | null => {
        if (!image) return null;
        const data: CreateMedia = {
            resourceSupported: 'user',
            mediaType: 'IMAGE',
            fileName: image.name,
            base64data: image.data,
            resourceId: resourceId.toString(),
        };
        return dataProvider
            .create(MEDIAS, {
                data: JSON.stringify(data),
            })
            .then((result: CreateResult) => {
                if (result && 'data' in result && result.data) {
                    return result.data as Media;
                }
                isLoading(false);
                return null;
            })
            .catch((error) => {
                console.error('error creating media', error);
                notify('app.users.edit.fail', 'error');
                isLoading(false);
                return null;
            });
    };

    const onHandleProfile = (profile: Profile | undefined) => {
        setProfileForCurrentUserTypeSelected(profile);
    };

    const handleImageDataUser = (data: { name: string; data: string } | null) => {
        setImage(data);
    };

    return (
        <DialogTemplate
            title={translate('app.users.create.title')}
            open={open}
            onClose={handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, submitting, pristine }) => {
                    return (
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <UserConfig
                                onHandleProfile={onHandleProfile}
                                searchProfile={true}
                                initializeUserType={true}
                                initializeUserServiceProviderType={true}
                                handleImageDataUser={handleImageDataUser}
                            />
                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={handleClose}
                                withCreate
                                isValidForm={!(loading || submitting || pristine)}
                                isSubmitting={loading}
                                cancelButtonLabel={translate('app.cancel')}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default CreateForm;
