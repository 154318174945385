import { GetOneResult } from 'react-admin';
import { takeEvery, put } from 'redux-saga/effects';
import { DataProvider } from '../../../providers/dataProvider';
import { MEDIAS } from '../../../providers/resources';
import { Media } from '../../../utils/types';
import { registerMedia, resolveMedia as resolveMediaAction, ResolveMediaAction } from '../../actions/medias';
import extractIdFromURI from '../../../utils/id';
import { getFileSystemMapperMedia } from '../../../utils/medias';

export const gettingMedias = (hydraDataProvider: DataProvider) =>
    function* (action: ResolveMediaAction) {
        const uriMedias = `/api/media/${extractIdFromURI(action.payload.imageId as string)}`;
        //@ts-ignore
        const media: GetOneResult = yield hydraDataProvider.getOne(MEDIAS, { id: uriMedias });
        if (media && 'data' in media && media.data) {
            const recordId = action.payload.recordId;
            if ('id' in media.data && media.data.id && recordId) {
                //@ts-ignore
                const fileSystemMapperMedia = yield getFileSystemMapperMedia(media.data as Media, recordId, []);
                yield put(
                    registerMedia.fn(
                        action.payload.imageId,
                        fileSystemMapperMedia.media,
                        fileSystemMapperMedia.fileSystemMapper
                    )
                );
            }
        }
    };

const mediaProvider = (hydraDataProvider: DataProvider) =>
    function* () {
        yield takeEvery(resolveMediaAction.action, gettingMedias(hydraDataProvider));
    };

export default mediaProvider;
