import { Identifier } from 'react-admin';
import { Avatar, NavigationConfiguration } from '../../../utils/types';

export const updateTreeVersionCreator = {
    action: '360sc_TICKETING/UPDATE_VERSION',
    fn: () => ({
        type: updateTreeVersionCreator.action,
        payload: {},
    }),
};

export interface SelectTreeLevelItemCreator {
    action: string;
    fn: (levelIdentifier: Identifier | null, clear?: boolean) => SelectTreeLevelItemAction;
}

export interface SelectTreeLevelItemAction {
    type: typeof selectTreeLevelItemCreator.action;
    payload: {
        levelIdentifier: Identifier | null;
        clear: boolean;
    };
}

export const selectTreeLevelItemCreator: SelectTreeLevelItemCreator = {
    action: '360SC_TICKETING/SELECT_TREE_LEVEL_ITEM',
    fn: (levelIdentifier: Identifier | null, clear = false): SelectTreeLevelItemAction => ({
        type: selectTreeLevelItemCreator.action,
        payload: {
            levelIdentifier: levelIdentifier,
            clear,
        },
    }),
};

export interface SetConfigurationCreator {
    action: string;
    fn: (configuration: NavigationConfiguration | undefined, clear: false) => SetConfigurationAction;
}

export interface SetConfigurationAction {
    type: typeof setConfigurationCreator.action;
    payload: {
        configuration: NavigationConfiguration | undefined;
        clear: boolean;
    };
}

export const setConfigurationCreator: SetConfigurationCreator = {
    action: '360SC_TICKETING/SET_CONFIGURATION',
    fn: (configuration: NavigationConfiguration | undefined, clear: false): SetConfigurationAction => ({
        type: setConfigurationCreator.action,
        payload: {
            configuration,
            clear,
        },
    }),
};

export interface SelectTreeLevelZeroItemsCreator {
    action: string;
    fn: (items: Avatar[]) => SelectTreeLevelZeroItemsAction;
}

export interface SelectTreeLevelZeroItemsAction {
    type: typeof selectTreeLevelItemCreator.action;
    payload: {
        items: Avatar[];
    };
}

export const selectTreeLevelZeroItemsCreator: SelectTreeLevelZeroItemsCreator = {
    action: '360SC_TICKETING/SELECT_TREE_LEVEL_ZERO_ITEMS',
    fn: (items: Avatar[]): SelectTreeLevelZeroItemsAction => ({
        type: selectTreeLevelZeroItemsCreator.action,
        payload: {
            items: items,
        },
    }),
};

export interface SetLoadedLevelAction {
    type: typeof setLoadedLevelCreator.action;
    payload: {
        identifier: Identifier;
        loaded: boolean;
    };
}

export interface SetLoadedLevelCreator {
    action: string;
    fn: (identifier: Identifier, loaded: boolean) => SetLoadedLevelAction;
}

export const setLoadedLevelCreator: SetLoadedLevelCreator = {
    action: '360SC_TICKETING/SET_LOADED_LEVEL',
    fn: (identifier: Identifier, loaded: boolean): SetLoadedLevelAction => ({
        type: setLoadedLevelCreator.action,
        payload: {
            identifier,
            loaded,
        },
    }),
};

//----
export interface SetLoadedLevelItemAction {
    type: typeof setLoadedLevelItemCreator.action;
    payload: {
        identifier: Identifier;
        children: Avatar[];
    };
}

export interface SetLoadedLevelItemCreator {
    action: string;
    fn: (identifier: Identifier, children: Avatar[]) => SetLoadedLevelItemAction;
}

export const setLoadedLevelItemCreator: SetLoadedLevelItemCreator = {
    action: '360SC_TICKETING/SET_LOADED_ITEM_LEVEL',
    fn: (identifier: Identifier, children: Avatar[]): SetLoadedLevelItemAction => ({
        type: setLoadedLevelItemCreator.action,
        payload: {
            identifier,
            children,
        },
    }),
};

export interface SetMoveActionToStateAction {
    type: typeof setMoveActionToStateActionCreator.action;
    payload: {
        toState: boolean;
    };
}

export interface SetMoveActionToStateActionCreator {
    action: string;
    fn: (toState: boolean) => SetMoveActionToStateAction;
}

export const setMoveActionToStateActionCreator: SetMoveActionToStateActionCreator = {
    action: '360SC_TICKETING/SET_MOVE_ACTION',
    fn: (toState): SetMoveActionToStateAction => ({
        type: setMoveActionToStateActionCreator.action,
        payload: {
            toState,
        },
    }),
};

export interface SetItemToMoveAction {
    type: typeof setItemToMoveActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetItemToMoveActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetItemToMoveAction;
}

export const setItemToMoveActionCreator: SetItemToMoveActionCreator = {
    action: '360SC_TICKETING/SET_ITEM_TO_MOVE',
    fn: (item): SetItemToMoveAction => ({
        type: setItemToMoveActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface SetMoveItemToAction {
    type: typeof setMoveItemToActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetMoveItemToActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetItemToMoveAction;
}

export const setMoveItemToActionCreator: SetMoveItemToActionCreator = {
    action: '360SC_TICKETING/SET_MOVE_ITEM_TO',
    fn: (item): SetMoveItemToAction => ({
        type: setMoveItemToActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface SelectItemListAction {
    type: typeof selectItemListActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SelectItemListCreator {
    action: string;
    fn: (item: Avatar | null) => SelectItemListAction;
}

export const selectItemListActionCreator: SelectItemListCreator = {
    action: '360SC_TICKETING/SELECT_ITEM_LIST',
    fn: (item: Avatar | null): SelectItemListAction => ({
        type: selectItemListActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface GetTreeItemListAction {
    type: typeof getTreeListActionCreator.action;
    payload: {
        parentId: Identifier;
    };
}

export interface GetTreeItemListCreator {
    action: string;
    fn: (parentId: Identifier) => GetTreeItemListAction;
}

export const getTreeListActionCreator: GetTreeItemListCreator = {
    action: '360SC_TICKETING/GET_TREE_ITEM_LIST',
    fn: (parentId: Identifier): GetTreeItemListAction => ({
        type: getTreeListActionCreator.action,
        payload: {
            parentId,
        },
    }),
};

export interface SetReorderActionToStateAction {
    type: typeof setMoveActionToStateActionCreator.action;
    payload: {
        toState: boolean;
    };
}

export interface SetReorderActionToStateActionCreator {
    action: string;
    fn: (toState: boolean) => SetReorderActionToStateAction;
}

export const setReorderActionToStateActionCreator: SetReorderActionToStateActionCreator = {
    action: '360SC_TICKETING/SET_REORDER_ACTION',
    fn: (toState): SetReorderActionToStateAction => ({
        type: setReorderActionToStateActionCreator.action,
        payload: {
            toState,
        },
    }),
};

export interface SetItemToReorderAction {
    type: typeof setItemToReorderActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetItemToReorderActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetItemToReorderAction;
}

export const setItemToReorderActionCreator: SetItemToReorderActionCreator = {
    action: '360SC_TICKETING/SET_ITEM_TO_REORDER',
    fn: (item): SetItemToReorderAction => ({
        type: setItemToReorderActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface SetReorderItemToAction {
    type: typeof setReorderItemToActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetReorderItemToActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetReorderItemToAction;
}

export const setReorderItemToActionCreator: SetReorderItemToActionCreator = {
    action: '360SC_TICKETING/SET_REORDER_ITEM_TO',
    fn: (item): SetReorderItemToAction => ({
        type: setReorderItemToActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface SetCloneActionToStateAction {
    type: typeof setCloneActionToStateActionCreator.action;
    payload: {
        toState: boolean;
    };
}

export interface SetCloneActionToStateActionCreator {
    action: string;
    fn: (toState: boolean) => SetCloneActionToStateAction;
}

export const setCloneActionToStateActionCreator: SetCloneActionToStateActionCreator = {
    action: '360SC_TICKETING/SET_CLONE_ACTION',
    fn: (toState): SetCloneActionToStateAction => ({
        type: setCloneActionToStateActionCreator.action,
        payload: {
            toState,
        },
    }),
};

export interface SetItemToCloneAction {
    type: typeof setItemToCloneActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetItemToCloneActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetItemToCloneAction;
}

export const setItemToCloneActionCreator: SetItemToCloneActionCreator = {
    action: '360SC_TICKETING/SET_ITEM_TO_CLONE',
    fn: (item): SetItemToCloneAction => ({
        type: setItemToCloneActionCreator.action,
        payload: {
            item,
        },
    }),
};

export interface SetCloneItemToAction {
    type: typeof setCloneItemToActionCreator.action;
    payload: {
        item: Avatar | null;
    };
}

export interface SetCloneItemToActionCreator {
    action: string;
    fn: (item: Avatar | null) => SetCloneItemToAction;
}

export const setCloneItemToActionCreator: SetCloneItemToActionCreator = {
    action: '360SC_TICKETING/SET_CLONE_ITEM_TO',
    fn: (item): SetCloneItemToAction => ({
        type: setCloneItemToActionCreator.action,
        payload: {
            item,
        },
    }),
};

export type ACTIONS =
    | SelectTreeLevelItemAction
    | SelectTreeLevelZeroItemsAction
    | SetLoadedLevelAction
    | SetLoadedLevelItemAction
    | SetMoveItemToAction
    | SetItemToMoveAction
    | SetMoveActionToStateAction
    | SetReorderActionToStateAction
    | SelectItemListAction
    | SetConfigurationAction
    | GetTreeItemListAction
    | SetCloneActionToStateAction;
