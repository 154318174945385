import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import React, { useState } from 'react';
import { ReduxState, useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';
import { CustomReducerState } from '../../../redux/reducers';
import { getIncidentSelected } from '../../../redux/reducers/incidents';
import { Avatar } from '../../../utils/types';
import AssignProviderForm from './asignProvider';
import DeleteForm from './delete';
import EditForm from './edit';
import { simulateMainFingerMc } from '../../../utils/simulateApp';
import {
    ROLE_CREATE_TICKETING_EVENT_USER,
    ROLE_DELETE_AVATAR,
    ROLE_FM_CLOUD_READ,
    ROLE_FM_CLOUD_READ_DOWNLOAD,
    ROLE_TICKETING_VIEW_EVENT_APPLY_USER,
} from '../../../utils/ROLES';
import { TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE } from '../../../utils/CONST';
import { isNodeType } from '../../../utils/getListOfNodesFromRunnerTempates';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import ShowDocs from './showDocs';

const SimpleActionMenu = () => {
    const translate = useTranslate();

    const [showAssignProviderForm, setShowAssignProviderForm] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);
    const [menuState, setMenuState] = useState(false);
    const [docToShow, setDocToShow] = useState<boolean>(false);

    const refresh = useRefresh();
    const handleToggle = () => {
        setMenuState(!menuState);
    };

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const showAssignProviderFormFn = () => {
        setShowAssignProviderForm(true);
        handleToggle();
    };

    const showDeleteFormFn = () => {
        setShowDeleteForm(true);
        handleToggle();
    };

    const selected: Avatar | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getIncidentSelected(state)
    );

    const isWaitingAssignServiceProvider = selected
        ? isNodeType(selected, TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE)
        : false;

    const simulateApp = (avatar: Avatar) => {
        simulateMainFingerMc(avatar, dataProvider)
            .then((result) => {
                result && window.open(result.mcUrl, '_blank', 'width=411px,height=731px');
            })
            .catch(() => {
                notify(translate('app.errors.sever', 'error'));
            });
    };

    const onCloseAssignProviderForm = () => {
        setShowAssignProviderForm(false);
        refresh();
    };

    const onCloseEditForm = () => {
        setShowEditForm(false);
        refresh();
    };

    const onCancelEditForm = () => {
        setShowEditForm(false);
    };

    const onCloseDeleteForm = () => {
        setShowDeleteForm(false);
        refresh();
    };

    const onCancelDeleteForm = () => {
        setShowDeleteForm(false);
    };

    const handleSuccessfullyAssignProvider = () => {
        refresh();
    };

    const dismissManagerDocDialog = () => {
        setDocToShow(false);
    };

    const menuActions: MenuItemType[] = [
        {
            icon: <PhoneIcon />,
            text: translate('app.menuActions.simulateApp'),
            onClick: () => (selected ? simulateApp(selected) : {}),
            disabled: !(selected && selected.mcs.length >= 1),
            roles: [],
        },
        {
            icon: <RemoveRedEye />,
            text: translate('app.incidents.information.seeIncident'),
            onClick: () => (selected ? simulateApp(selected) : {}),
            disabled: !(selected && selected.mcs.length >= 1),
            roles: [],
        },
        {
            icon: <DeleteIcon />,
            text: translate('app.menuActions.delete'),
            onClick: showDeleteFormFn,
            disabled: !selected,
            roles: [ROLE_DELETE_AVATAR],
        },
        {
            icon: <PersonIcon />,
            text: translate('app.incidents.addServiceProvider.title'),
            onClick: showAssignProviderFormFn,
            disabled: !(selected && isWaitingAssignServiceProvider),
            roles: [ROLE_TICKETING_VIEW_EVENT_APPLY_USER, ROLE_CREATE_TICKETING_EVENT_USER],
        },
        {
            icon: <PhoneIcon />,
            text: translate('app.incidents.information.invoiceAndQuotes'),
            onClick: () => (selected ? setDocToShow(true) : {}),
            disabled: !selected,
            roles: [ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD],
        },
    ];

    return (
        <div>
            <MenuButtonActions
                buttonLabel={'app.menuActions.title'}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />
            {showAssignProviderForm && (
                <AssignProviderForm
                    open={true}
                    handleClose={onCloseAssignProviderForm}
                    handleSuccessfully={handleSuccessfullyAssignProvider}
                />
            )}

            {showEditForm && (
                <EditForm
                    selected={selected}
                    open={true}
                    handleClose={onCloseEditForm}
                    handleCancel={onCancelEditForm}
                />
            )}

            {showDeleteForm && (
                <DeleteForm
                    selected={selected}
                    open={true}
                    handleClose={onCloseDeleteForm}
                    handleCancel={onCancelDeleteForm}
                />
            )}

            {docToShow && selected && <ShowDocs open={true} handleClose={dismissManagerDocDialog} event={selected} />}
        </div>
    );
};

export default SimpleActionMenu;
