// export const ROLE_360SC = 'ROLE_360SC';
// export const ROLE_API = 'ROLE_API';

// Avatar Roles
import { ExplicitOutlined } from '@material-ui/icons';

export const ROLE_READ_AVATAR = 'ROLE_READ_AVATAR';
export const ROLE_CREATE_AVATAR = 'ROLE_CREATE_AVATAR';
export const ROLE_UPDATE_AVATAR = 'ROLE_UPDATE_AVATAR';
export const ROLE_DELETE_AVATAR = 'ROLE_DELETE_AVATAR';
export const ROLE_VIEW_OBJECTS = 'ROLE_VIEW_OBJECTS';

// User Roles
export const ROLE_READ_USER = 'ROLE_READ_USER';
export const ROLE_VIEW_USERS = 'ROLE_VIEW_USERS';
export const ROLE_CREATE_USER = 'ROLE_CREATE_USER';
export const ROLE_UPDATE_USER = 'ROLE_UPDATE_USER';
export const ROLE_DELETE_USER = 'ROLE_DELETE_USER';

// Profiles
export const ROLE_READ_PROFILE = 'ROLE_READ_PROFILE';

// Avatar Property
export const ROLE_READ_AVATAR_PROPERTY = 'ROLE_READ_AVATAR_PROPERTY';
export const ROLE_UPDATE_AVATAR_PROPERTY = 'ROLE_UPDATE_AVATAR_PROPERTY';
export const ROLE_CREATE_AVATAR_PROPERTY = 'ROLE_CREATE_AVATAR_PROPERTY';

// Metadata Avatar Type
export const ROLE_READ_OBJECT_TYPE = 'ROLE_READ_OBJECT_TYPE';
export const ROLE_VIEW_OBJECT_TYPES = 'ROLE_VIEW_OBJECT_TYPES';
export const ROLE_CREATE_OBJECT_TYPE = 'ROLE_CREATE_OBJECT_TYPE';
export const ROLE_UPDATE_OBJECT_TYPE = 'ROLE_UPDATE_OBJECT_TYPE';
export const ROLE_DELETE_OBJECT_TYPE = 'ROLE_DELETE_OBJECT_TYPE';

// Metadata Avatar Type Property
export const ROLE_READ_OBJECT_TYPE_PROPERTIES = 'ROLE_READ_OBJECT_TYPE_PROPERTIES';
export const ROLE_CREATE_OBJECT_TYPE_PROPERTIES = 'ROLE_CREATE_OBJECT_TYPE_PROPERTIES';
export const ROLE_UPDATE_OBJECT_TYPE_PROPERTIES = 'ROLE_UPDATE_OBJECT_TYPE_PROPERTIES';
export const ROLE_DELETE_OBJECT_TYPE_PROPERTIES = 'ROLE_DELETE_OBJECT_TYPE_PROPERTIES';

// Group metadata avatar type
export const ROLE_VIEW_GROUP_OBJECT_TYPES = 'ROLE_VIEW_GROUP_OBJECT_TYPES';
export const ROLE_READ_GROUP_OBJECT_TYPE = 'ROLE_READ_GROUP_OBJECT_TYPE';
export const ROLE_CREATE_GROUP_OBJECT_TYPE = 'ROLE_CREATE_GROUP_OBJECT_TYPE';
export const ROLE_UPDATE_GROUP_OBJECT_TYPE = 'ROLE_UPDATE_GROUP_OBJECT_TYPE';
export const ROLE_DELETE_GROUP_OBJECT_TYPE = 'ROLE_DELETE_GROUP_OBJECT_TYPE';

// Dashboard
export const ROLE_VIEW_GENERAL = 'ROLE_VIEW_GENERAL';

// FILE_SYSTEM_MAPPER
export const ROLE_FM_CLOUD_READ_DOWNLOAD = 'ROLE_FM_CLOUD_READ_DOWNLOAD';
export const ROLE_FM_CLOUD_READ = 'ROLE_FM_CLOUD_READ';
export const ROLE_FM_CLOUD_REMOVE = 'ROLE_FM_CLOUD_REMOVE';

// Navigation View
export const ROLE_TICKETING_VIEW_EVENT_NAVIGATION = 'ROLE_TICKETING_VIEW_EVENT_NAVIGATION';

// TICKETING EVENT ALLOWED IN PLACE
export const ROLE_READ_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE = 'ROLE_READ_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE';
export const ROLE_CREATE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE =
    'ROLE_CREATE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE';
export const ROLE_UPDATE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE =
    'ROLE_UPDATE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE';
export const ROLE_DELETE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE =
    'ROLE_DELETE_TICKETING_SYSTEM_EVENT_ALLOWED_IN_PLACE';

// TICKETING EVENT USER
export const ROLE_READ_TICKETING_EVENT_USER = 'ROLE_READ_TICKETING_EVENT_USER';
export const ROLE_CREATE_TICKETING_EVENT_USER = 'ROLE_CREATE_TICKETING_EVENT_USER';
export const ROLE_TICKETING_VIEW_EVENT_APPLY_USER = 'ROLE_TICKETING_VIEW_EVENT_APPLY_USER';
export const ROLE_UPDATE_TICKETING_EVENT_USER = 'ROLE_UPDATE_TICKETING_EVENT_USER';
export const ROLE_DELETE_TICKETING_EVENT_USER = 'ROLE_DELETE_TICKETING_EVENT_USER';

// TICKETING PROCESS CUSTOMIZATION
export const ROLE_READ_TICKETING_PROCESS_CUSTOMIZATION = 'ROLE_READ_TICKETING_PROCESS_CUSTOMIZATION';
export const ROLE_CREATE_TICKETING_PROCESS_CUSTOMIZATION = 'ROLE_CREATE_TICKETING_PROCESS_CUSTOMIZATION';
export const ROLE_UPDATE_TICKETING_PROCESS_CUSTOMIZATION = 'ROLE_UPDATE_TICKETING_PROCESS_CUSTOMIZATION';
export const ROLE_DELETE_TICKETING_PROCESS_CUSTOMIZATION = 'ROLE_DELETE_TICKETING_PROCESS_CUSTOMIZATION';
export const ROLE_READ_TRACEABILITY_RUNNER_TEMPLATE = 'ROLE_READ_TRACEABILITY_RUNNER_TEMPLATE';
export const ROLE_READ_FINGER = 'ROLE_READ_FINGER';

// TICKETING SUB_CATEGORY EVENT TYPES DEPENDENCIES
export const ROLE_READ_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES =
    'ROLE_READ_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES';
export const ROLE_CREATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES =
    'ROLE_CREATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES';
export const ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES =
    'ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES';
export const ROLE_DELETE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES =
    'ROLE_DELETE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES';

// TICKETING USER ALLOCATION
export const ROLE_READ_TICKETING_USER_ALLOCATION = 'ROLE_READ_TICKETING_USER_ALLOCATION';
export const ROLE_CREATE_TICKETING_USER_ALLOCATION = 'ROLE_CREATE_TICKETING_USER_ALLOCATION';
export const ROLE_UPDATE_TICKETING_USER_ALLOCATION = 'ROLE_UPDATE_TICKETING_USER_ALLOCATION';
export const ROLE_DELETE_TICKETING_USER_ALLOCATION = 'ROLE_DELETE_TICKETING_USER_ALLOCATION';

// TICKETING CREATE PLACE OR EVENT
export const ROLE_TICKETING_CREATE_ROOT_PLACE_OR_EVENT = 'ROLE_TICKETING_CREATE_ROOT_PLACE_OR_EVENT';
export const ROLE_TICKETING_CREATE_PLACE_OR_EVENT = 'ROLE_TICKETING_CREATE_PLACE_OR_EVENT';

// TICKETING USER PROVIDER EVENT CONFIGURATION
export const ROLE_READ_TICKETING_USER_PROVIDER_EVENT_CONFIGURATION =
    'ROLE_READ_TICKETING_USER_PROVIDER_EVENT_CONFIGURATION';

// TRACEABILITY
export const ROLE_UPDATE_TRACEABILITY_STATE = 'ROLE_UPDATE_TRACEABILITY_STATE';
export const ROLE_TICKETING_VIEW_RESTART_EVENT = 'ROLE_TICKETING_VIEW_RESTART_EVENT';
export const ROLE_TICKETING_VIEW_CLOSE_EVENT = 'ROLE_TICKETING_VIEW_CLOSE_EVENT';

// TICKETING MANAGER NOTIFICATION
export const ROLE_READ_TICKETING_MANAGER_NOTIFICATION = 'ROLE_READ_TICKETING_MANAGER_NOTIFICATION';
export const ROLE_CREATE_TICKETING_MANAGER_NOTIFICATION = 'ROLE_CREATE_TICKETING_MANAGER_NOTIFICATION';
export const ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION = 'ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION';
export const ROLE_DELETE_TICKETING_MANAGER_NOTIFICATION = 'ROLE_DELETE_TICKETING_MANAGER_NOTIFICATION';

// EDIT STRUCTURE
export const ROLE_TICKETING_VIEW_EDIT_STRUCTURE = 'ROLE_TICKETING_VIEW_EDIT_STRUCTURE';
