import { Identifier } from 'react-admin';

export const removedItemsActionCreator = {
    type: '360sc_TICKETING/REMOVED_ITEMS',
    fn: (id: Identifier, resource: string) => ({
        type: removedItemsActionCreator.type,
        payload: {
            id,
            resource,
        },
    }),
};
