import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useField } from 'react-final-form';
import makeStyles from '@material-ui/styles/makeStyles';
import { FieldHeaderListStyles } from '../../theme';

const useStyles = makeStyles(() => ({
    iconButton: {
        // paddingBottom: 5,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const CustomSubmitButton = ({
    fieldKeys,
    handleSubmit,
    handleDelete,
}: {
    fieldKeys: string[];
    handleSubmit: () => void;
    handleDelete?: () => void;
}) => {
    const fields = fieldKeys.map((fieldKey) => useField(fieldKey));
    const classes = useStyles();
    // @ts-ignore
    const isDisabled: boolean = fields.reduce((acc, field) => {
        return acc && field.meta.pristine;
    }, true);

    return (
        <>
            {handleSubmit && (
                <IconButton onClick={handleSubmit} disabled={isDisabled} className={classes.iconButton}>
                    <SaveIcon style={{ color: !isDisabled ? 'black' : 'grey' }} />
                </IconButton>
            )}
            {handleDelete && (
                <IconButton onClick={handleDelete} className={classes.iconButton}>
                    <DeleteIcon style={{ color: 'black' }} />
                </IconButton>
            )}
        </>
    );
};

export default CustomSubmitButton;
