import { makeStyles } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import { Filter, SearchInput } from 'react-admin';
import { sideBarMinWidth } from '../../../theme';

const useStyles = makeStyles({
    filterForm: {
        marginLeft: 12,
    },
});

const FilterCardSideBarStyled = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        order: -1, // display on the left rather than on the right of the list
        minWidth: sideBarMinWidth,
        border: '1px solid #E6E6E6',
        borderRadius: 5,
        marginRight: 15,
        padding: 10,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

interface Props {
    divHeight: number;
    [key: string]: any;
}

const FilterSidebar = ({ divHeight, ...restProps }: Props) => {
    const classes = useStyles();
    return (
        <FilterCardSideBarStyled style={{ height: divHeight - 61 }}>
            <Filter {...restProps} classes={{ form: classes.filterForm }}>
                <SearchInput source='name' alwaysOn variant={'standard'} />
            </Filter>
        </FilterCardSideBarStyled>
    );
};

export default FilterSidebar;
