import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { Datagrid, FunctionField, List as RAList, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import {
    Avatar,
    MetadataAvatarType,
    TicketingManagerNotification,
    TicketingManagerPlaces,
} from '../../../../../utils/types';
import { METADATA_AVATAR_TYPES, TICKETING_MANAGER_PLACES } from '../../../../../providers/resources';
import { capitalizeFirstLetter } from '../../../../../utils/strings';
import { getNameWithoutPrefixFromJson } from '../../../../../utils/resources/GroupMetadataAvatarType';
import { FieldHeaderListStyles } from '../../../../../theme';
import makeStyles from '@material-ui/styles/makeStyles';
import UserManageForm from './MultipleUserManagerForm';
import extractIdFromURI from '../../../../../utils/id';
import BooleanForm from './booleanForm';
import './styles.css';
import {
    ROLE_CREATE_TICKETING_MANAGER_NOTIFICATION,
    ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION,
} from '../../../../../utils/ROLES';
import { useSelector } from 'react-redux';

const CustomTypography = styled(Typography)({
    border: '1px solid #E4E5EB',
    padding: 10,
});

interface Props {
    place: Avatar;
}

const useStyles = makeStyles(() => ({
    field: {
        ...FieldHeaderListStyles,
    },
    // @ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
        // ...FieldCapitalizeListStyles,
    },
    gridEmpty: {
        marginTop: '24px',
    },
    listToolbar: {
        height: '24px !important',
        maxHeight: '24px !important',
    },
}));

const NotifyIncidentsToUsers: FC<Props> = ({ place }: Props) => {
    const translate = useTranslate();
    const classes = useStyles();

    const { loaded, permissions } = usePermissions();

    const canCreateOrUpdateTicketingManagerNotification =
        loaded &&
        permissions &&
        Array.isArray(permissions) &&
        permissions.includes(ROLE_CREATE_TICKETING_MANAGER_NOTIFICATION) &&
        permissions.includes(ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION);
    // &&
    // permissions.includes(ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION);

    const renderEmpty = () => {
        return (
            <Grid item className={classes.gridEmpty}>
                <CustomTypography variant={'body2'}>
                    {translate('app.navigation.configuration.managerNotification.empty')}
                </CustomTypography>
            </Grid>
        );
    };

    const listProps = {
        resource: METADATA_AVATAR_TYPES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: '',
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            'ticketing_available_event_types[at_and_under_place_id]': extractIdFromURI(place.id as string),
        },
    };

    const ticketingManagersPlaceInState: (TicketingManagerPlaces | null)[] = useSelector((state: ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_MANAGER_PLACES].data).map((item) =>
            (item as TicketingManagerNotification).place === place?.id ? (item as TicketingManagerPlaces) : null
        );
    });

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <CustomTypography variant={'body1'}>
                    {translate('app.navigation.configuration.managerPlace.description.line0')} <br />
                </CustomTypography>
            </Grid>

            <UserManageForm place={place} />

            {/*{canCreateOrUpdateTicketingManagerNotification && (*/}
            {/*    <Grid item>*/}
            {/*        <UserManageForm place={place} />*/}
            {/*<UserManageForm*/}
            {/*    place={place}*/}
            {/*    initialManagerPlace={*/}
            {/*        ticketingManagersPlaceInState?.length > 1 ? ticketingManagersPlaceInState[1] : undefined*/}
            {/*    }*/}
            {/*    source={'managerPlace1'}*/}
            {/*/>*/}
            {/*<UserManageForm*/}
            {/*    place={place}*/}
            {/*    initialManagerPlace={*/}
            {/*        ticketingManagersPlaceInState?.length > 2 ? ticketingManagersPlaceInState[2] : undefined*/}
            {/*    }*/}
            {/*    source={'managerPlace2'}*/}
            {/*/>*/}
            {/*    </Grid>*/}
            {/*)}*/}

            <Grid item>
                <CustomTypography variant={'body1'}>
                    {translate('app.navigation.configuration.managerNotification.description.line0')} <br />
                    <br />
                    {translate('app.navigation.configuration.managerNotification.description.line1')} <br />
                    <ul>
                        <li>{translate('app.navigation.configuration.managerNotification.description.line2')}</li>
                        <li>{translate('app.navigation.configuration.managerNotification.description.line3')}</li>
                        <li>{translate('app.navigation.configuration.managerNotification.description.line4')}</li>
                    </ul>
                </CustomTypography>
            </Grid>

            <RAList
                className='notifyIncidents_metadataAvatarType'
                {...listProps}
                exporter={false}
                bulkActionButtons={false}
                empty={renderEmpty()}
                classes={{ toolbar: classes.listToolbar }}
                // actions={renderActions()}
            >
                <Datagrid>
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.managerNotification.subcategory')}
                        render={(record: Record | undefined) => (
                            <Typography>
                                {capitalizeFirstLetter(getNameWithoutPrefixFromJson(record as MetadataAvatarType))}
                            </Typography>
                        )}
                    />

                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.managerNotification.assignedUnderContract')}
                        render={(record: Record | undefined) => (
                            <BooleanForm
                                place={place}
                                source={'assignedWithContract'}
                                label={''}
                                subcategory={record as MetadataAvatarType}
                            />
                        )}
                    />

                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.managerNotification.assignedWithoutContract')}
                        render={(record: Record | undefined) => (
                            <BooleanForm
                                place={place}
                                source={'assignedWithoutContract'}
                                label={''}
                                subcategory={record as MetadataAvatarType}
                            />
                        )}
                    />

                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.managerNotification.never')}
                        render={(record: Record | undefined) => (
                            <BooleanForm
                                place={place}
                                source={'never'}
                                label={''}
                                subcategory={record as MetadataAvatarType}
                            />
                        )}
                    />
                </Datagrid>
            </RAList>
        </Grid>
    );
};

export default NotifyIncidentsToUsers;
