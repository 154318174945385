import { Card as MuiCard, createTheme, withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/styles';
import AvatarM from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';

export const APP_COLOR_PRIMARY = '#3F51B5';
export const APP_COLOR_SECONDARY = '#22263D';
export const APP_COLOR_DISABLED = 'rgba(61,60,60,0.17)';
export const APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST = '#e0e0e0';
export const APP_BAR_MAX_HEIGHT = 64;
export const APP_BAR_BACKGROUND_COLOR = '#22263D';
export const DIVIDER_COLOR = '#0000001f';
export const APP_ENABLED_COLOR = '#00A400';

export const AvatarStyled = withStyles({
    colorDefault: {
        backgroundColor: APP_COLOR_PRIMARY,
    },
    root: {
        width: 30,
        height: 30,
    },
})(Avatar);

export const sideBarMinWidth = 240;

export const FieldHeaderListStyles = {
    fontWeight: 700,
};

export const ImageStyles = {
    marginRight: 0,
    height: 25,
    width: 25,
};

export const CustomPointerAvatar = styled(AvatarM)({
    width: 30,
    height: 30,
    backgroundColor: APP_COLOR_PRIMARY,
    margin: 2,
    cursor: 'pointer',
});

export const CustomIconButton = styled(IconButton)({
    width: 30,
    height: 30,
    backgroundColor: APP_COLOR_PRIMARY,
    padding: 0,
    margin: 2,
    color: 'white',
    '&:hover': {
        backgroundColor: APP_COLOR_PRIMARY,
        color: 'white',
    },
});

export const ImageFieldStyles = {
    marginRight: 5,
    height: 20,
    width: 20,
};

export const FieldCapitalizeListStyles = {
    textTransform: 'capitalize',
};

export const ButtonFontWeightBoldStyles = {
    fontWeight: 700,
};

export const TopToolbarStyles = {
    marginLeft: '0',
    marginRight: 'auto',
    // paddingTop: "8px",
    paddingBottom: '8px',
    flexDirection: 'row-reverse',
    width: '100%',
};

export const TopToolbarButtonStyled = withStyles({
    root: {
        fontWeight: 700,
        color: APP_COLOR_PRIMARY,
        padding: '4px 5px',
        fontSize: '0.8125rem',
    },
})(Button);

export const ButtonSubmitStyled = withStyles({
    root: {
        fontWeight: 700,
        color: 'white',
        backgroundColor: APP_COLOR_PRIMARY,
        '&:hover': {
            color: 'white',
            backgroundColor: APP_COLOR_PRIMARY,
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: APP_COLOR_DISABLED,
        },
    },
})(Button);

export const FilterCardSideBarStyled = withStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

export const GridTitleFilterListStyled = withStyles({
    root: {
        // paddingTop: 30,
        // marginLeft: 12,
        // marginRight: 12,
    },
})(Grid);

export const ContentContainer = () => ({
    root: {
        margin: '0',
        WebkitFlexGrow: 1,
        flexGrow: 1,
    },
    rootOverflow: {
        overflowX: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
    },
    gridItemWithSize: {
        paddingTop: '0px !important',
        display: 'flow-root',
        overflowX: 'hidden',
    },
});
export const ActionFormButtonStyles = {
    root: {
        backgroundColor: 'white',
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: 'none',
    },
    text: {
        color: APP_COLOR_PRIMARY,
        fontWeight: 'bold',
    },
};

export const tableStyles = () => ({
    tableRowSelected: {
        backgroundColor: '#aaaaaaa6 !important',
    },
    cardList: {
        backgroundColor: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        minHeight: 400,
    },
    list: {
        height: 35,
    },
    checkbox: {
        padding: '0px 0px 0px 12px',
    },
    backDiv: {
        borderTop: '2px solid #C4D600',
        backgroundColor: 'white',
        height: '80vh',
        overflowY: 'scroll',
    },
});

export const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '60px 40px auto',
    alignItems: 'center',
});

export const TopDiv = styled('div')({
    display: 'grid',
    alignItems: 'center',
    border: `1px solid ${DIVIDER_COLOR}`,
    padding: 5,
    marginBottom: 20,
});

export const StyledBadge = withStyles((theme) => ({
    badge: {
        // left: 33,
        // top: 4,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 2px',
        // backgroundColor: 'red',
        minWidth: 10,
        height: 10,
    },
}))(Badge);

export default createTheme({
    palette: {
        primary: {
            main: APP_COLOR_PRIMARY,
        },
        secondary: {
            main: APP_COLOR_SECONDARY,
        },
    },
});
