import { makeStyles, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { capitalizeFirstLetter } from '../../utils/strings';

const defaultSymbol = '/';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 9,
        columnGap: 7,
    },
    item: {
        fontSize: 14,
        fontWeight: 400,
    },
});

interface Props {
    items: string[];
    lastItem: string;
    symbol?: string;
}

const Breadcrumbs = ({ items = [], lastItem, symbol }: Props) => {
    const classes = useStyles();

    const renderItem = (item: string) => (
        <Typography className={classes.item} noWrap>
            {capitalizeFirstLetter(item)}
        </Typography>
    );

    const renderSymbol = () => (
        <Typography className={classes.item} noWrap>
            {symbol ? symbol : defaultSymbol}
        </Typography>
    );

    return (
        <div className={classes.root} data-test-sc={'breadCrumbs'}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    {renderItem(item)}
                    {index + 1 !== items.length && renderSymbol()}
                </Fragment>
            ))}
            {lastItem && (
                <Fragment>
                    {renderSymbol()}
                    {renderItem(lastItem)}
                </Fragment>
            )}
        </div>
    );
};

export default Breadcrumbs;
