import { ButtonGroup, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useDelete, useListContext, useNotify, useRefresh, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { TICKETING_EVENT_INSTRUCTION_HELPS } from '../../../../../providers/resources';
import { CustomReducerState } from '../../../../../redux/reducers';
import { RICH_TEXT_SEND_DATA_VERSION_KEY } from '../../../../../utils/CONST';
import { MetadataAvatarType } from '../../../../../utils/types';
import RichTextEdit from '../rich-text-edit/rich-text-edit';
import './rich-text-show.css';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonFontWeightBoldStyles } from '../../../../../theme';

const LoadingContainer = styled('div')({
    height: 200,
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
});

const ShowContainer = styled('div')({
    border: '1px solid #ccc',
    padding: '0 10px 0 10px',
    marginTop: 15,
});

const ButtonFontWeightBold = styled(Button)({
    ...ButtonFontWeightBoldStyles,
});

interface Props {
    subCategory: MetadataAvatarType;
    selectedInstructionId: any;
    setSelectedInstructionId: any;
}

const RichTextShow = ({ subCategory, selectedInstructionId, setSelectedInstructionId }: Props) => {
    const { data } = useListContext();
    const [deleteOne, { loading: loadingDelete }] = useDelete();
    const [richTextEditDialogState, setRichTextEditDialogState] = useState<boolean>(false);
    const notify = useNotify();
    const translate = useTranslate();
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [html, setHtml] = useState<string>('');
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const refresh = useRefresh();

    const propHtml = data[selectedInstructionId].customFields[RICH_TEXT_SEND_DATA_VERSION_KEY].html;

    const deleteInstruction = (instructionId: string) => {
        const instruction = data[instructionId];
        deleteOne(TICKETING_EVENT_INSTRUCTION_HELPS, instruction['@id'], instruction, {
            onSuccess: () => {
                setSelectedInstructionId(null);
                notify('app.incidentTypes.instructions.notifications.deleteSuccess', 'success');
                refresh();
            },
            onFailure: (error) => {
                console.log('deleteInstruction', error);
                notify('app.incidentTypes.instructions.notifications.deleteError', 'error');
            },
        });
    };

    useEffect(() => {
        setLoadingState(true);
        const regex = /bearer=[\w.-]*">/g;
        setHtml(propHtml.replaceAll(regex, `bearer=${tokenWithRoles}">`));
        setLoadingState(false);
    }, [propHtml]);

    return (
        <Fragment>
            <Grid container direction={'column'}>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <ButtonGroup color='primary' size={'small'}>
                                <ButtonFontWeightBold
                                    variant={'text'}
                                    onClick={() => setRichTextEditDialogState(true)}
                                    disabled={loadingDelete}
                                    color={'primary'}
                                    startIcon={<EditIcon />}
                                >
                                    {translate('app.buttons.edit')}
                                </ButtonFontWeightBold>
                                <ButtonFontWeightBold
                                    variant={'text'}
                                    startIcon={<DeleteIcon />}
                                    onClick={() => deleteInstruction(selectedInstructionId)}
                                    disabled={loadingDelete}
                                >
                                    {loadingDelete ? <CircularProgress size={15} /> : translate('app.buttons.delete')}
                                </ButtonFontWeightBold>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>

                {!loadingState && (
                    <Grid item>
                        <ShowContainer
                            id='rich-text-show'
                            dangerouslySetInnerHTML={{ __html: html }}
                            style={{ fontFamily: 'Roboto' }}
                        />
                    </Grid>
                )}

                {loadingState && (
                    <Grid item>
                        <LoadingContainer>
                            <CircularProgress />
                        </LoadingContainer>
                    </Grid>
                )}
            </Grid>

            {richTextEditDialogState && (
                <RichTextEdit
                    subCategory={subCategory}
                    open={richTextEditDialogState}
                    onClose={() => setRichTextEditDialogState(false)}
                    selectedInstructionId={selectedInstructionId}
                />
            )}
        </Fragment>
    );
};

export default RichTextShow;
