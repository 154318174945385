import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { styled } from '@material-ui/styles';

import makeStyles from '@material-ui/styles/makeStyles';
import React, { FC, useEffect, useState } from 'react';
import {
    AutocompleteArrayInput,
    Identifier,
    useCreate,
    useDataProvider,
    useDelete,
    useNotify,
    useTranslate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import ReferenceArrayInput from '../../../../../commons/ra/input/ReferenceArrayInput';
import { TICKETING_MANAGER_PLACES, USERS } from '../../../../../providers/resources';
import { FieldHeaderListStyles } from '../../../../../theme';
import extractIdFromURI from '../../../../../utils/id';
import { Avatar, TicketingManagerPlaces, User } from '../../../../../utils/types';
import { TICKETING_MANAGER_TAG, TICKETING_SUB_MANAGER_TAG } from '../../../../../utils/CONST';

const useStyles = makeStyles(() => ({
    field: {
        ...FieldHeaderListStyles,
    },
    gridAlignCenter: {
        alignSelf: 'center',
    },
    form: {
        display: 'flex',
        marginTop: '0px !important',
        marginBottom: '0px !important',
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

interface Props {
    handleCancel?: () => void;
    place: Avatar;
}

const ChipsContainer = styled(Grid)({
    marginTop: 20,
    marginBottom: 15,
});

const CustomUl = styled('ul')({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 1,
    margin: 0,
});

const CustomChip = styled(Chip)({
    margin: '4px 4px 4px 0px',
});

const USER_MANAGER_FIELD = 'userManagers';
const ITEM_PER_PAGE = 3;

const MultipleUserManageForm: FC<Props> = ({ place }: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [create] = useCreate(TICKETING_MANAGER_PLACES);
    const [deleteManagerPlace] = useDelete(TICKETING_MANAGER_PLACES);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const users = useSelector((state: any) => state.admin.resources.users.data);
    const [resultListManagerPlace, setResultListManagerPlace] = useState<TicketingManagerPlaces[]>([]);
    const [resultListManagerPlaceParents, setResultListManagerPlaceParents] = useState<TicketingManagerPlaces[]>([]);
    const [initialUserManagers, setInitialUserManager] = useState<string[]>([]);
    const [forceRefresh, setForceRefresh] = useState<number>(0);
    const [getListLoading, isGetListLoading] = useState<boolean>(false);

    useEffect(() => {
        if (getListLoading) return;
        getManagerPlace();
    }, [place, forceRefresh]);

    const getManagerPlace = () => {
        isGetListLoading(true);
        dataProvider
            .getList(TICKETING_MANAGER_PLACES, {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'id', order: 'DESC' },
                filter: {
                    placeOrEvent: extractIdFromURI(place.id as string),
                },
            })
            .then((result) => {
                isGetListLoading(false);
                if (result && 'data' in result && result.data.length > 0) {
                    setResultListManagerPlace(result.data as TicketingManagerPlaces[]);
                } else {
                    setResultListManagerPlace([]);
                    if (place && 'parent' in place && place.parent) getManagerPlaceFromParents();
                }
            })
            .catch((error) => {
                console.error('get List', TICKETING_MANAGER_PLACES, error);
                notify('app.navigation.configuration.managerPlace.action.list.error', 'error');
                isGetListLoading(false);
            });
    };

    const getManagerPlaceFromParents = () => {
        isGetListLoading(true);
        dataProvider
            .getList(TICKETING_MANAGER_PLACES, {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'id', order: 'DESC' },
                filter: {
                    find_manager_in_branch:
                        place && 'parent' in place && place.parent ? extractIdFromURI(place.parent['@id']) : null,
                },
            })
            .then((result) => {
                isGetListLoading(false);
                if (result && 'data' in result && result.data.length > 0) {
                    setResultListManagerPlaceParents(result.data as TicketingManagerPlaces[]);
                } else {
                    setResultListManagerPlaceParents([]);
                }
            })
            .catch((error) => {
                console.error('get List', TICKETING_MANAGER_PLACES, error);
                notify('app.navigation.configuration.managerPlace.action.list.error', 'error');
                isGetListLoading(false);
            });
    };

    useEffect(() => {
        setInitialUserManager(
            // @ts-ignore
            resultListManagerPlace && resultListManagerPlace?.length > 0
                ? resultListManagerPlace?.map((item) => item?.userManager).filter((item) => typeof item === 'string')
                : []
        );
    }, [resultListManagerPlace]);

    const optionRenderer = (choice: User | undefined) => `${choice?.firstName} ${choice?.lastName}`;

    const validate = (values: { [key: string]: string }) => {
        const errors: { [key: string]: string } = {};
        return errors;
    };

    const onSubmit = (data: { [USER_MANAGER_FIELD]: Identifier[] }) => {
        data[USER_MANAGER_FIELD] = Array.isArray(data[USER_MANAGER_FIELD]) ? data[USER_MANAGER_FIELD] : [];

        const managerPlacesToDelete:
            | (TicketingManagerPlaces | null | undefined)[]
            | undefined = resultListManagerPlace?.filter(
            (item) => -1 === data[USER_MANAGER_FIELD].findIndex((i) => i === item?.userManager)
        );
        const userManagersAddToManagerPlace =
            resultListManagerPlace && resultListManagerPlace?.length > 0
                ? data[USER_MANAGER_FIELD].filter(
                      (item) => -1 === resultListManagerPlace?.findIndex((i) => i?.userManager === item)
                  )
                : data[USER_MANAGER_FIELD];

        if (userManagersAddToManagerPlace && userManagersAddToManagerPlace.length > 0) {
            let totalUserManagerAdded = 0;
            userManagersAddToManagerPlace.forEach(async (userManagerId: Identifier) => {
                await createManagerPlace(userManagerId).catch((e) => console.error(e));
                totalUserManagerAdded++;
                if (totalUserManagerAdded === userManagersAddToManagerPlace.length) {
                    notify('app.navigation.configuration.managerPlace.action.create.notification', 'success');
                }
            });
        }

        if (managerPlacesToDelete && managerPlacesToDelete.length > 0) {
            let totalUserManagerToRemove = 0;
            managerPlacesToDelete.forEach(async (managerPlace: TicketingManagerPlaces | null | undefined) => {
                await removeManagerPlace(managerPlace);
                totalUserManagerToRemove++;
                if (totalUserManagerToRemove === managerPlacesToDelete.length) {
                    notify('app.navigation.configuration.managerPlace.action.delete.notification', 'success');
                }
            });
        }
    };

    const createManagerPlace = async (userManagerId: Identifier) => {
        if (!userManagerId) return;
        await create(
            TICKETING_MANAGER_PLACES,
            {
                company: place.company,
                placeOrEvent: place.id,
                userManager: userManagerId as string,
            },
            {
                onSuccess: () => {
                    const fr = forceRefresh + 1;
                    setForceRefresh(fr);
                },
                onFailure: (error: any) => {
                    notify('app.navigation.configuration.managerPlace.action.create.error', 'error');
                    console.error(error);
                },
            }
        );
    };

    const removeManagerPlace = async (managerPlace: TicketingManagerPlaces | null | undefined) => {
        if (!managerPlace) return;
        //@ts-ignore
        await deleteManagerPlace(TICKETING_MANAGER_PLACES, managerPlace.id, managerPlace, {
            onSuccess: () => {
                const fr = forceRefresh + 1;
                setForceRefresh(fr);
            },
            onFailure: (error: any) => {
                console.error(error);
                notify('app.navigation.configuration.managerPlace.action.delete.error', 'error');
            },
        });
    };

    const chipLabel = (mPlace: TicketingManagerPlaces) => {
        if (typeof mPlace.userManager === 'string') {
            return mPlace.userManager in users ? optionRenderer(users[mPlace.userManager]) : '...';
        } else {
            if (mPlace.userManager.id) {
                return mPlace.userManager.id in users ? optionRenderer(users[mPlace.userManager.id]) : '...';
            }
        }
        return '...';
    };

    return (
        <Grid container direction={'column'}>
            {resultListManagerPlaceParents.length > 0 && resultListManagerPlace.length === 0 && (
                <Grid item>
                    <ChipsContainer container direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography>
                                {translate('app.navigation.configuration.managerNotification.inheritedFromParents')}:
                                {place && place.parent ? <b> {place.parent.name}</b> : ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CustomUl>
                                {resultListManagerPlaceParents.map((mPlace) => (
                                    <li key={`li_user_manager_${mPlace.id}`}>
                                        <CustomChip label={chipLabel(mPlace)} />
                                    </li>
                                ))}
                            </CustomUl>
                        </Grid>
                    </ChipsContainer>
                </Grid>
            )}
            <Grid item>
                <FinalForm
                    initialValues={{
                        [USER_MANAGER_FIELD]: initialUserManagers,
                    }}
                    onSubmit={onSubmit}
                    // @ts-ignore
                    validate={validate}
                    render={({ handleSubmit, pristine, valid }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.form} key={1}>
                                <ReferenceArrayInput
                                    fullWidth
                                    required
                                    reference={USERS}
                                    filterToQuery={(searchText: string) => ({
                                        lastName: searchText,
                                    })}
                                    filter={{
                                        'tags[one]': [TICKETING_MANAGER_TAG],
                                    }}
                                    source={USER_MANAGER_FIELD}
                                    label={translate('app.navigation.configuration.managerNotification.user')}
                                >
                                    <AutocompleteArrayInput
                                        source={USER_MANAGER_FIELD}
                                        variant={'standard'}
                                        fullWidth
                                        optionText={optionRenderer}
                                        clearAlwaysVisible={true}
                                        // resettable={true}
                                        filterToQuery={(searchText: string) => ({
                                            lastName: searchText,
                                        })}
                                    />
                                </ReferenceArrayInput>
                                <IconButton
                                    type={'submit'}
                                    onClick={handleSubmit}
                                    disabled={pristine && valid}
                                    className={classes.iconButton}
                                >
                                    <SaveIcon style={{ color: !pristine && valid ? 'black' : 'grey' }} />
                                </IconButton>
                            </form>
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MultipleUserManageForm;
