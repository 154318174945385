import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import React, { useEffect, useState } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { MetadataAvatarType } from '../../../../utils/types';
import RichTextCreate from './rich-text-create/rich-text-create';
import RichTextShow from './rich-text-show/rich-text-show';

const RootDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '200px auto',
    columnGap: 30,
});

const CustomCircularProgress = styled(CircularProgress)({});

const ListRootGrid = styled(Grid)({
    position: 'relative',
    minHeight: 200,
});

const LoadingListDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.05)',
});

interface Props {
    open: boolean;
    onClose: any;
    subCategory: MetadataAvatarType;
}

const InstructionsDialog = ({ open, onClose, subCategory }: Props) => {
    const [richTextCreateDialogState, setRichTextCreateDialogState] = useState<boolean>(false);
    const { loading: loadingStatus, data } = useListContext();
    const [selectedInstructionId, setSelectedInstructionId] = useState<any>(null);
    const instructions = Object.values(data).filter((item) => item.subCategory === subCategory['@id']);
    const translate = useTranslate();
    const [firstLoad, setFirstLoad] = useState<boolean>(false);

    useEffect(() => {
        if (!loadingStatus && !firstLoad) {
            setFirstLoad(true);
            if (instructions.length === 0) setRichTextCreateDialogState(true);
        }
    }, [loadingStatus]);

    return (
        <DialogTemplate
            title={translate('app.incidentTypes.instructions.dialogTitle')}
            open={open}
            onClose={onClose}
            icon={<FontDownloadIcon />}
            maxWidth={'md'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onClose}
                    cancelButtonLabel={translate('app.buttons.return')}
                />
            }
        >
            <RootDiv>
                <ListRootGrid container direction={'column'}>
                    <Grid item>
                        <List component='nav'>
                            {instructions.map((item, index) => (
                                <ListItem
                                    button
                                    key={item['@id']}
                                    onClick={() => setSelectedInstructionId(item['@id'])}
                                    selected={selectedInstructionId === item['@id']}
                                    disabled={loadingStatus}
                                    style={{ textAlign: 'center' }}
                                >
                                    <ListItemText
                                        primary={`${translate('app.incidentTypes.instructions.labelList')} ${
                                            index + 1
                                        }`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    {!loadingStatus && (
                        <Grid item>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                    <IconButton onClick={() => setRichTextCreateDialogState(true)} color={'primary'}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {loadingStatus && (
                        <LoadingListDiv>
                            <CustomCircularProgress />
                        </LoadingListDiv>
                    )}
                </ListRootGrid>

                <div>
                    {selectedInstructionId && selectedInstructionId in data && (
                        <RichTextShow
                            subCategory={subCategory}
                            selectedInstructionId={selectedInstructionId}
                            setSelectedInstructionId={setSelectedInstructionId}
                        />
                    )}

                    {selectedInstructionId === null && (
                        <Grid container justifyContent={'center'}>
                            <Grid item>
                                <Typography>{translate('app.incidentTypes.instructions.createLabel')}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </RootDiv>

            {richTextCreateDialogState && (
                <RichTextCreate
                    subCategory={subCategory}
                    open={richTextCreateDialogState}
                    onClose={() => {
                        setRichTextCreateDialogState(false);
                        if (instructions.length === 0) onClose();
                    }}
                    setSelectedInstructionId={setSelectedInstructionId}
                />
            )}
        </DialogTemplate>
    );
};

export default InstructionsDialog;
