import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Datagrid, FunctionField, Identifier, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import FunctionIconField from '../../../commons/fields/function/functionIconField';

import FunctionMediaField from '../../../commons/fields/function/functionMediaField';
import IncidentInProgressField from '../../../commons/fields/incidentInProgress';
import ReferenceField from '../../../commons/ra/field/ReferenceField';
import ShowMcInfo from '../../../commons/SideBarMenu/show-mc-info';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { selectItemListActionCreator } from '../../../redux/actions/navigation';
import { CustomReducerState } from '../../../redux/reducers';
import { getItemListSelected } from '../../../redux/reducers/navigation';
import { FieldHeaderListStyles, ImageStyles } from '../../../theme';
import { getAvatarCtnIdentifiers } from '../../../utils/identifier';
import { CUSTOM_FIELDS_ICON_SVG_COLLECTION } from '../../../utils/metadataAvatarType';
import { hasPermissions } from '../../../utils/permissions';
import { getNameWithoutPrefixFromJson } from '../../../utils/resources/GroupMetadataAvatarType';
import {
    ROLE_FM_CLOUD_READ,
    ROLE_FM_CLOUD_READ_DOWNLOAD,
    ROLE_READ_AVATAR,
    ROLE_READ_GROUP_OBJECT_TYPE,
} from '../../../utils/ROLES';
import { rowStyle } from '../../../utils/rowListStyles';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { Avatar, GroupMetadataAvatarType } from '../../../utils/types';
import './styles.css';

const useStyles = makeStyles({
    //@ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
    },
    gridItemAlignCenter: {
        alignSelf: 'center',
    },
    root: {
        backgroundColor: 'red',
    },
    image: {
        ...ImageStyles,
    },
});

const NavigationDatagrid = (props: any = {}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();

    const { loaded: loadedPermissions, permissions } = usePermissions();

    const selectedItemList: Avatar | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getItemListSelected(state)
    );

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectItemListActionCreator.fn(record as Avatar));
        // @ts-ignore
        return Promise.resolve(undefined);
    };

    return (
        <div className='right-block'>
            <Datagrid
                {...props}
                rowClick={onClick}
                rowStyle={(record, index) => rowStyle(record, index, selectedItemList)}
            >
                <FunctionField
                    headerClassName={classes.fieldHeader}
                    label={'app.incidentTypes.name'}
                    render={(record: Record | undefined) => {
                        if (
                            record &&
                            'metadataAvatarType' in record &&
                            record.metadataAvatarType &&
                            typeof record.metadataAvatarType === 'object' &&
                            'customFields' in record.metadataAvatarType &&
                            record.metadataAvatarType.customFields &&
                            CUSTOM_FIELDS_ICON_SVG_COLLECTION in record.metadataAvatarType.customFields &&
                            record.metadataAvatarType.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                        ) {
                            return (
                                <FunctionIconField
                                    headerClassName={classes.fieldHeader}
                                    findImageInAnotherResource={false}
                                    label={translate('app.incidentTypes.name')}
                                    record={record.metadataAvatarType}
                                />
                            );
                        } else {
                            return (
                                <Fragment>
                                    {hasPermissions(
                                        [ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD],
                                        loadedPermissions,
                                        permissions
                                    ) ? (
                                        <FunctionMediaField
                                            headerClassName={classes.fieldHeader}
                                            findImageInAnotherResource={true}
                                            keyForFindImageInAnotherResource={'metadataAvatarType'}
                                            label={translate('app.navigation.list.columns.name')}
                                            record={record}
                                        />
                                    ) : (
                                        <FunctionField
                                            headerClassName={classes.fieldHeader}
                                            label={'app.navigation.list.columns.name'}
                                            render={() => {
                                                return <img src={'/tmp-image.png'} className={classes.image} alt='' />;
                                            }}
                                            record={record}
                                        />
                                    )}
                                </Fragment>
                            );
                        }
                    }}
                />

                {hasPermissions([ROLE_READ_AVATAR], loadedPermissions, permissions) && (
                    <FunctionField
                        sortable={false}
                        headerClassName={classes.fieldHeader}
                        label={translate('app.navigation.list.columns.incidentInProgress')}
                        render={(record: Record | undefined) => (
                            <IncidentInProgressField
                                label={''}
                                overwriteHeaderClassName={classes.fieldHeader}
                                record={record as Avatar}
                            />
                        )}
                    />
                )}
                {/*{hasUsersPermissions && (*/}
                {/*    <ServiceProviderField*/}
                {/*        headerClassName={classes.fieldHeader}*/}
                {/*        sortable={false}*/}
                {/*        label={translate('app.navigation.list.columns.userApplied')}*/}
                {/*    />*/}
                {/*)}*/}

                <FunctionField
                    headerClassName={classes.fieldHeader}
                    label={translate('app.navigation.list.columns.subCategoryOrIncidentType')}
                    // @ts-ignore
                    render={(record: Avatar) => {
                        return capitalizeFirstLetter(getNameWithoutPrefixFromJson(record.metadataAvatarType));
                    }}
                />

                {loadedPermissions && hasPermissions([ROLE_READ_GROUP_OBJECT_TYPE], loadedPermissions, permissions) && (
                    <ReferenceField
                        link={false}
                        sortable={false}
                        headerClassName={classes.fieldHeader}
                        reference={GROUP_METADATA_AVATAR_TYPES}
                        source={'metadataAvatarType.groupMetadataAvatarType'}
                        label={translate('app.navigation.list.columns.category')}
                    >
                        <FunctionField
                            render={(record: Record | undefined) =>
                                record
                                    ? capitalizeFirstLetter(
                                          getNameWithoutPrefixFromJson(record as GroupMetadataAvatarType)
                                      )
                                    : '...'
                            }
                        />
                    </ReferenceField>
                )}

                <FunctionField
                    headerClassName={classes.fieldHeader}
                    sortable={false}
                    label={translate('app.navigation.list.columns.ctn')}
                    // @ts-ignore
                    render={(record: Avatar) => {
                        return getAvatarCtnIdentifiers(record);
                    }}
                />

                <FunctionField
                    headerClassName={classes.fieldHeader}
                    sortable={false}
                    label={translate('app.navigation.list.columns.pairedUpFromField')}
                    // @ts-ignore
                    render={(record: Avatar) => {
                        return record.mcs.length > 1
                            ? capitalizeFirstLetter(translate('app.yes'))
                            : capitalizeFirstLetter(translate('app.no'));
                    }}
                />

                <FunctionField
                    headerClassName={classes.fieldHeader}
                    sortable={false}
                    label={translate('app.actions')}
                    // @ts-ignore
                    render={(record: Avatar) => <ShowMcInfo avatar={record} />}
                />
            </Datagrid>
        </div>
    );
};

export default NavigationDatagrid;
