import { MetadataAvatarType } from '../../../utils/types';

export interface SelectSubCategoryAction {
    type: typeof selectSubCategory.action;
    payload: {
        record: MetadataAvatarType | null;
    };
}

export const selectSubCategory = {
    action: '360SC_TICKETING/SELECT_SUBCATEGORY',
    fn: (record: MetadataAvatarType | null): SelectSubCategoryAction => ({
        type: selectSubCategory.action,
        payload: {
            record: record,
        },
    }),
};
