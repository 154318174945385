import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ApplyIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';
import { styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const RootGrid = styled(Grid)({
    position: 'relative',
    width: '100%',
});

const ButtonsDiv = styled('div')({
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'flex',
});

const CustomIconButton = styled(IconButton)({
    color: '#D1D3DA',
    padding: 0,
});

const CustomAddIcon = styled(AddIcon)({
    width: 150,
    height: 150,
});

const CustomMenu = styled(Menu)({
    '& .MuiMenu-list': {
        padding: 0,
    },
});

const CustomListItemIcon = styled(ListItemIcon)({
    minWidth: '25px !important',
});

const CustomMenuItem = styled(MenuItem)({
    padding: '2px 10px 2px 5px',
});

const CustomListItemText = styled(ListItemText)({
    '& .MuiListItemText-root': {
        padding: 0,
    },
    '& .MuiListItemText-primary': {
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 'bold',
    },
});

type ImageCropFieldProps = {
    name: string;
    accept: string;
    inputLabel?: string;
    changeIconType?: any;
    setImageOpenAction?: any;
};

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

const getCroppedImg = async (imageSrc: string, pixelCrop: Area): Promise<string | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    return canvas.toDataURL('image/jpeg');
};

const ImageCropField = ({ name, accept, setImageOpenAction }: ImageCropFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const { input } = useField(name);
    const [imageLoaded, setImageLoaded] = useState(false);
    const notify = useNotify();
    const [cropProps, setCropProps] = useState<{
        imageUrl: string;
        crop: Point;
        zoom: number;
        croppedArea: Area | undefined;
        cropSize: number;
    }>({
        imageUrl: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedArea: undefined,
        cropSize: 100,
    });
    const [croppedImage, setCroppedImage] = useState('');
    const [cropping, setCropping] = useState(false);
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const clearCrops = () =>
        setCropProps({
            crop: { x: 0, y: 0 },
            croppedArea: undefined,
            zoom: 1,
            imageUrl: '',
            cropSize: 200,
        });
    const imageContainerRef = useRef(null);

    const initFn = () => () => {
        clickFileInput();
    };

    useEffect(() => {
        if (setImageOpenAction) {
            setImageOpenAction(initFn);
        }
    }, []);

    const updateFile = async ({ target: { files } }: ChangeEvent<HTMLInputElement> | { target: { files: File[] } }) => {
        if (files && files.length > 0) {
            if (files[0].size / 1024 / 1024 > 5) {
                notify(translate('app.media.imageMaxSizeUpload'), 'error');
            } else {
                const readFile = (file: File): Promise<string> => {
                    return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', () => resolve(reader.result as string), false);
                        reader.readAsDataURL(file);
                    });
                };

                const imgUrl = await readFile(files[0]);
                if (imgUrl) {
                    setCropProps({
                        crop: { x: 0, y: 0 },
                        croppedArea: undefined,
                        zoom: 1,
                        imageUrl: imgUrl,
                        cropSize: 200,
                    });
                    setCroppedImage('');
                    if (files instanceof FileList) {
                        input.onChange({ fileName: files[0].name, fileData: imgUrl });
                    }
                }
            }
        }
    };
    const clickFileInput = () => {
        inputRef.current && inputRef.current.click();
    };
    const startCropping = () => {
        setCropping(true);
        input.onChange({ ...input.value, cropping: true });
    };
    const updateCrop = (cropVal: { x: number; y: number }) =>
        setCropProps((prevState) => ({
            ...prevState,
            crop: cropVal,
        }));
    const updateZoom = (zoomVal: number) => setCropProps((prevState) => ({ ...prevState, zoom: zoomVal }));
    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCropProps((prevState) => ({ ...prevState, croppedArea: croppedAreaPixels }));
    }, []);
    const applyCrop = useCallback(async () => {
        try {
            const result = await getCroppedImg(cropProps.imageUrl, cropProps.croppedArea as Area);
            if (result) {
                setCroppedImage(result);
                setCropProps((prevState) => ({ ...prevState, imageUrl: result }));
                input.onChange({ fileData: result, fileName: input.value.fileName });
                setCropping(false);
            }
        } catch (e) {
            console.error(e);
        }
    }, [cropProps.imageUrl, cropProps.croppedArea]);
    const cancelCrop = () => {
        setCropping(false);
        const { cropping, ...removeCropping } = input.value;
        // noinspection BadExpressionStatementJS
        cropping;
        input.onChange(removeCropping);
    };
    const clearAll = () => {
        clearCrops();
        setCroppedImage('');
        input.onChange(undefined);
        setCropping(false);
    };
    const setPlaceholderImage = () => {
        imgRef.current && (imgRef.current.src = 'placeholder-320x240.jpg');
    };
    const getRemoteImage = () => {
        setLoading(true);
        const f = async () => {
            if (input.value.url) {
                const img = await fetch(input.value.url)
                    .then((result) => result.blob())
                    .then((blob) => new File([blob], input.value.fileName || 'unknown.jpg', { type: 'image/jpg' }));
                await updateFile({ target: { files: [img] } });
            }
        };
        f().finally(() => setLoading(false));
    };
    useEffect(() => {
        if (!imageLoaded) {
            if (input.value !== '') setImageLoaded(true);
            getRemoteImage();
        }
    }, [input]);

    return (
        <Fragment>
            <Grid
                container
                spacing={2}
                direction='column'
                style={
                    {
                        // backgroundColor: '#E4E5EB',
                    }
                }
                ref={imageContainerRef}
            >
                <Grid item>
                    <div
                        style={{
                            width: '100%',
                            height: 350,
                            backgroundColor: '#E4E5EB',
                            display: 'grid',
                            gridTemplateColumns: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <ButtonsDiv>
                            {!(!cropProps.imageUrl || cropping) && (
                                <Fragment>
                                    <IconButton
                                        color={'primary'}
                                        title='Crop image'
                                        onClick={startCropping}
                                        disabled={!cropProps.imageUrl || cropping}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color={'primary'}
                                        title='Delete image'
                                        disabled={!cropProps.imageUrl || cropping}
                                        onClick={clearAll}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Fragment>
                            )}

                            <input type='file' hidden accept={accept} ref={inputRef} onChange={updateFile} />
                        </ButtonsDiv>

                        {!(croppedImage || cropProps.imageUrl) ? (
                            <CustomIconButton onClick={clickFileInput}>
                                <CustomAddIcon />
                            </CustomIconButton>
                        ) : (
                            <Fragment>
                                {!cropping && (
                                    <img
                                        ref={imgRef}
                                        src={croppedImage || cropProps.imageUrl}
                                        onError={setPlaceholderImage}
                                        alt=''
                                        style={{
                                            width: '100%',
                                            maxHeight: 400,
                                        }}
                                    />
                                )}
                                {cropping && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <Cropper
                                            style={{
                                                containerStyle: {
                                                    width:
                                                        imageContainerRef &&
                                                        imageContainerRef.current &&
                                                        // @ts-ignore
                                                        imageContainerRef.current.clientWidth
                                                            ? // @ts-ignore
                                                              imageContainerRef.current.clientWidth - 20
                                                            : 400,
                                                    height: 350,
                                                    position: 'relative',
                                                },
                                                mediaStyle: { position: 'relative' },
                                            }}
                                            image={cropProps.imageUrl}
                                            crop={cropProps.crop}
                                            zoom={cropProps.zoom}
                                            aspect={1}
                                            cropShape='rect'
                                            showGrid={false}
                                            onCropChange={updateCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={updateZoom}
                                            cropSize={{ width: 350, height: 350 }}
                                            restrictPosition={false}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                </Grid>

                {cropping && (
                    <Grid item style={{ margin: '-15px 0 0 0' }}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item>
                                <Slider
                                    style={{
                                        width:
                                            imageContainerRef &&
                                            imageContainerRef.current &&
                                            // @ts-ignore
                                            imageContainerRef.current.clientWidth
                                                ? // @ts-ignore
                                                  imageContainerRef.current.clientWidth - 300
                                                : 400,
                                        marginRight: 20,
                                    }}
                                    value={cropProps.zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby='Zoom'
                                    onChange={(e, zoom) =>
                                        setCropProps((prevState) => ({
                                            ...prevState,
                                            zoom: zoom as number,
                                        }))
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <IconButton title='Cancel crop' onClick={applyCrop}>
                                    <ApplyIcon />
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton title='Cancel crop' onClick={cancelCrop}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default ImageCropField;
