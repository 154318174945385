import { styled } from '@material-ui/core/styles';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CreateResult, Record, useListContext, useNotify, useTranslate, useUpdate } from 'react-admin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import DialogTemplate from '../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { TICKETING_EVENT_INSTRUCTION_HELPS } from '../../../../../providers/resources';
import { CustomReducerState } from '../../../../../redux/reducers';
import { RICH_TEXT_SEND_DATA_VERSION_KEY } from '../../../../../utils/CONST';
import { MetadataAvatarType, User } from '../../../../../utils/types';
import Gallery from '../gallery/gallery';
import './rich-text-edit.css';

const RootDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 300px',
    columnGap: 30,
    alignItems: 'start',
});

interface Props {
    open: boolean;
    onClose: () => void;
    subCategory: MetadataAvatarType;
    selectedInstructionId: string;
}

const RichTextEdit = ({ subCategory, open, onClose, selectedInstructionId }: Props) => {
    const { data } = useListContext();
    const [editorValue, setEditorValue] = useState<string>('');
    const ref: any = useRef(null);
    const translate = useTranslate();
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );

    const [listIndex, setListIndex] = useState<any>({});

    useEffect(() => {
        const keys = Object.keys(data);
        const newListIndex: any = {};
        keys.map((key, index) => (newListIndex[key] = index));
        setListIndex(newListIndex);
    }, [data]);

    const [update, { loading: loadingCreate }] = useUpdate();
    const notify = useNotify();
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );

    const updateRichTextRequest = (instruction: any, data: any, onSuccessCallback: any) => {
        update(TICKETING_EVENT_INSTRUCTION_HELPS, instruction['@id'], data, instruction, {
            onSuccess: ({ data }: CreateResult) => {
                onSuccessCallback(data);
                notify('app.incidentTypes.instructions.notifications.editSuccess', 'success');
            },
            onFailure: (error) => {
                console.error('updateRichTextRequest', error);
                notify('app.incidentTypes.instructions.notifications.editError', 'error');
            },
        });
    };

    useEffect(() => {
        const html = data[selectedInstructionId].customFields[RICH_TEXT_SEND_DATA_VERSION_KEY].html;
        const regex = /bearer=[\w.-]*">/g;
        setEditorValue(html.replaceAll(regex, `bearer=${tokenWithRoles}">`));
    }, [data]);

    const handleUpdateRichText = (html: string, instructionId: string) => {
        const instruction = data[instructionId];
        updateRichTextRequest(
            instruction,
            {
                subCategory: subCategory['@id'],
                customFields: {
                    'version_0.content': {
                        html,
                    },
                },
                company: userConnected?.company['@id'],
            },
            () => {
                onClose();
            }
        );
    };

    const insertImage = (url: string) => {
        const editor = ref?.current?.getEditor();
        const range = editor.getSelection(true);
        editor.insertEmbed(range.index, 'image', url);
    };

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, false] }],
                ['bold', 'italic', 'underline'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
            ],
        }),
        []
    );

    return (
        <DialogTemplate
            title={`${translate('app.incidentTypes.instructions.editDialogTitle')} ${
                listIndex[selectedInstructionId] + 1
            }`}
            open={open}
            onClose={onClose}
            icon={<FontDownloadIcon />}
            maxWidth={'md'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onClose}
                    withDelete
                    deleteButtonLabel={translate('app.buttons.valid')}
                    isSubmitting={loadingCreate}
                    isValidForm={editorValue !== '' && editorValue !== '<p><br></p>'}
                    onDeleteClick={() => handleUpdateRichText(editorValue, selectedInstructionId)}
                />
            }
        >
            <RootDiv>
                <ReactQuill ref={ref} theme='snow' value={editorValue} onChange={setEditorValue} modules={modules} />
                <Gallery subCategory={subCategory} insertImage={insertImage} />
            </RootDiv>
        </DialogTemplate>
    );
};

export default RichTextEdit;
