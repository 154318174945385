import { Identifier } from 'react-admin';
import { PlaceOrEventCount } from '../../utils/types';
import { ACTIONS, RegisterPlacesOrEventsCount, registerPlacesOrEventsPlaceCount } from '../actions/placesOrEvents';
import { clearTicketingStateCreator } from '../actions/ticketingState';

/**
 * key is places or incident id
 */
export interface PlacesOrEventsCountState {
    [key: Identifier]: PlaceOrEventCount;
}

const InitialState: PlacesOrEventsCountState = {};

const reducer = (state = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case registerPlacesOrEventsPlaceCount.action:
            return {
                ...state,
                [(action as RegisterPlacesOrEventsCount).payload.eventOrPlaceId]: {
                    ...(action as RegisterPlacesOrEventsCount).payload.placeEventCount,
                },
            };
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
