import { styled } from '@material-ui/core/styles';
import * as React from 'react';

interface ProgressBarProps {
    value: number;
}

const Root = styled('div')({
    width: '100%',
    borderRadius: 2,
    display: 'grid',
    gridTemplateColumns: '40px auto',
    columnGap: 3,
});

const Value = styled('div')({
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
});

const ContainerBar = styled('div')({
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: '#BFC0C5',
});

const Bar = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    backgroundColor: '#3F51B5',
}));

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
    const { value } = props;
    const valueInPercent = value;

    return (
        <Root>
            <Value>{`${valueInPercent.toLocaleString()} %`}</Value>
            <ContainerBar>
                <Bar style={{ maxWidth: `${valueInPercent}%` }} />
            </ContainerBar>
        </Root>
    );
});

export const renderProgress = (params: any) => {
    return <ProgressBar value={params.value} />;
};
