import React, { useState } from 'react';
import { Avatar } from '../../../../utils/types';
import { hasPermissions } from '../../../../utils/permissions';
import { ROLE_TICKETING_VIEW_RESTART_EVENT, ROLE_UPDATE_TRACEABILITY_STATE } from '../../../../utils/ROLES';
import Tooltip from '@material-ui/core/Tooltip';
import RestartAltIcon from '@material-ui/icons/RotateLeft';
import { usePermissions, useTranslate } from 'react-admin';
import { isNodeType } from '../../../../utils/getListOfNodesFromRunnerTempates';
import { TICKETING_OPENED_NODE_TYPE } from '../../../../utils/CONST';
import { CustomCircularProgress, CustomIconButton } from './commons';
import RestartEventFormDialog from '../restartEvent';

interface Props {
    record: Avatar;
}

const RestartButton = (props: Props) => {
    const { record } = props;
    const translate = useTranslate();
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const [showRestartEventDialog, setShowRestartEventDialog] = useState<boolean>(false);
    const [currentRecordToRestart, setCurrentRecordToRestart] = useState<Avatar | null>(null);

    const isNodeTypeTicketingOpened = record ? isNodeType(record, TICKETING_OPENED_NODE_TYPE) : false;
    const hasAvatarProcess = !!(record && (record as Avatar).currentReferencing);

    const handleRestartEventProcess = (record: Avatar) => () => {
        setCurrentRecordToRestart(record);
        setShowRestartEventDialog(true);
    };

    const onCloseRestartEventDialog = () => {
        setShowRestartEventDialog(false);
    };

    return (
        <>
            {hasPermissions(
                [ROLE_UPDATE_TRACEABILITY_STATE, ROLE_TICKETING_VIEW_RESTART_EVENT],
                loadedPermissions,
                permissions
            ) &&
                !showRestartEventDialog && (
                    <div>
                        <Tooltip
                            title={
                                !isNodeTypeTicketingOpened
                                    ? translate('app.incidents.restart.label')
                                    : translate('app.incidents.information.incidentRestarted')
                            }
                        >
                            <CustomIconButton
                                onClick={
                                    !isNodeTypeTicketingOpened && hasAvatarProcess && record
                                        ? handleRestartEventProcess(record as Avatar)
                                        : () => null
                                }
                            >
                                <RestartAltIcon
                                    color={isNodeTypeTicketingOpened || !hasAvatarProcess ? 'disabled' : 'primary'}
                                />
                            </CustomIconButton>
                        </Tooltip>
                    </div>
                )}
            {showRestartEventDialog && <CustomCircularProgress size={18} />}
            {showRestartEventDialog && (
                <RestartEventFormDialog event={record} open={true} handleClose={onCloseRestartEventDialog} />
            )}
        </>
    );
};
export default RestartButton;
