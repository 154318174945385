import { useListContext, useTranslate } from 'react-admin';
import { AutocompleteInput } from '../../ra/input/AutocompleteInput';
import ReferenceInput from '../../ra/input/ReferenceInput';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GROUP_METADATA_AVATAR_TYPE_EVENT, GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import { METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { MetadataAvatarType } from '../../../utils/types';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { getNameWithoutPrefixFromJson } from '../../../utils/resources/GroupMetadataAvatarType';

interface Props {
    label: string;
    alwaysOn: boolean;
    disabled?: boolean;
    emptyText?: string;
}

const SUBCATEGORY = 'subcategory';
const INCIDENT_TYPE = 'incident_type';

// @ts-ignore
const SubcategoryAndIncidentTypeSwitchFilter = (props: Props) => {
    const translate = useTranslate();
    const { setFilters, displayedFilters, filterValues } = useListContext();

    const resolveCurrentMetadataAvatarTypeToShow = () => {
        if ('parent' in filterValues && filterValues?.['parent']['metadataAvatarType']) {
            return SUBCATEGORY;
        } else if ('metadataAvatarType' in filterValues && filterValues['metadataAvatarType']) {
            return INCIDENT_TYPE;
        }
        return SUBCATEGORY;
    };

    const [currentChecked, setCurrentChecked] = useState<boolean>(true);
    const [currentMetadataAvatarTypeShown, setCurrentMetadataAvatarTypeShown] = useState<string>(SUBCATEGORY);

    useEffect(() => {
        console.log(
            'resolveCurrentMetadataAvatarTypeToShow',
            resolveCurrentMetadataAvatarTypeToShow(),
            'parent' in filterValues && filterValues?.['parent']['metadataAvatarType']
        );
        const _currentMetadataAvatarTypeToShow = resolveCurrentMetadataAvatarTypeToShow();
        if (_currentMetadataAvatarTypeToShow !== currentMetadataAvatarTypeShown) {
            setCurrentMetadataAvatarTypeShown(_currentMetadataAvatarTypeToShow);
        }
        if (_currentMetadataAvatarTypeToShow === SUBCATEGORY) {
            setCurrentChecked(true);
        } else {
            setCurrentChecked(false);
        }
    }, []);

    const handleChange = (event: any) => {
        if (event.target.checked) {
            removeFilters(SUBCATEGORY);
            setCurrentMetadataAvatarTypeShown(SUBCATEGORY);
        } else {
            removeFilters(INCIDENT_TYPE);
            setCurrentMetadataAvatarTypeShown(INCIDENT_TYPE);
        }
        setCurrentChecked(event.target.checked);
    };

    const removeFilters = (currentMetadataAvatarTypeToShow: string) => {
        switch (currentMetadataAvatarTypeToShow) {
            case SUBCATEGORY: {
                if (filterValues && 'metadataAvatarType' in filterValues && filterValues['metadataAvatarType']) {
                    delete filterValues['metadataAvatarType'];
                    setFilters(
                        {
                            ...filterValues,
                        },
                        displayedFilters
                    );
                }
                break;
            }
            case INCIDENT_TYPE: {
                if (filterValues && 'parent' in filterValues && filterValues?.['parent']['metadataAvatarType']) {
                    delete filterValues['parent']['metadataAvatarType'];
                    setFilters(
                        {
                            ...filterValues,
                        },
                        displayedFilters
                    );
                }
                break;
            }
        }
    };

    const optionRendered = (choice: MetadataAvatarType | undefined) => {
        return choice ? `${capitalizeFirstLetter(getNameWithoutPrefixFromJson(choice))}` : '';
    };

    return (
        <Grid container>
            <Grid container>
                <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                    {translate('app.incidents.filters.subcategoriesAndIncidentTypeSwitch')}
                </Typography>

                <Grid item>
                    <Switch onChange={handleChange} color={'primary'} checked={currentChecked} />
                </Grid>

                {currentMetadataAvatarTypeShown === SUBCATEGORY && (
                    <ReferenceInput
                        disabled={props.disabled}
                        alwaysOn={props.alwaysOn}
                        variant={'standard'}
                        label={'app.incidents.filters.subcategories.title'}
                        filterToQuery={(searchText: string) => ({
                            name: searchText,
                        })}
                        reference={METADATA_AVATAR_TYPES}
                        filter={{
                            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                            hidden: false,
                        }}
                        allowEmpty
                        source={'parent___metadataAvatarType'}
                    >
                        <AutocompleteInput
                            translateChoice={false}
                            fullWidth
                            resettable
                            allowEmpty
                            emptyValue={null}
                            emptyText={props.emptyText}
                            optionText={optionRendered}
                            optionValue='@id'
                            filterToQuery={(searchText: string) => ({
                                name: searchText,
                            })}
                        />
                    </ReferenceInput>
                )}

                {currentMetadataAvatarTypeShown === INCIDENT_TYPE && (
                    <ReferenceInput
                        disabled={props.disabled}
                        alwaysOn={props.alwaysOn}
                        variant={'standard'}
                        label={'app.incidents.filters.incidentType.title'}
                        filterToQuery={(searchText: string) => ({
                            name: searchText,
                        })}
                        reference={METADATA_AVATAR_TYPES}
                        filter={{
                            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_EVENT,
                            hidden: false,
                        }}
                        allowEmpty
                        source={'metadataAvatarType'}
                    >
                        <AutocompleteInput
                            translateChoice={false}
                            fullWidth
                            resettable
                            allowEmpty
                            emptyValue={null}
                            emptyText={props.emptyText}
                            optionText={optionRendered}
                            optionValue='@id'
                            filterToQuery={(searchText: string) => ({
                                name: searchText,
                            })}
                        />
                    </ReferenceInput>
                )}
            </Grid>
        </Grid>
    );
};

export default SubcategoryAndIncidentTypeSwitchFilter;
