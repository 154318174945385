import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { DataProviderContext, GetListResult, Record, useNotify, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import BackDiv from '../../commons/BackDiv/BackDiv';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import { AVATARS } from '../../providers/resources';
import { selectTreeLevelItemCreator, selectTreeLevelZeroItemsCreator } from '../../redux/actions/navigation';
import { CustomReducerState } from '../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../utils/CONST';
import { Avatar } from '../../utils/types';
import NavigationList from './list/list';

const NavigationPageIndex = () => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const notify = useNotify();

    const dataProvider = useContext(DataProviderContext);
    const [isConfiguring, setIsConfiguring] = useState<boolean>(true);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const treeLevelSelected = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.tree.levelItemSelected
    );
    const treeVersion = useSelector((state: CustomReducerState) => state.ticketing.navigation.tree.version);

    useEffect(() => {
        dataProvider
            .getList(AVATARS, {
                pagination: {
                    page: 1,
                    perPage: 100,
                },
                sort: {
                    field: 'lft',
                    order: 'ASC',
                },
                filter: {
                    lvl: 0,
                    'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                    serialization_groups: ['read_avatar_root', 'read_avatar_parent'],
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && 'data' in result) {
                    const data = result.data;
                    if (data.length > 0) {
                        dispatch(selectTreeLevelZeroItemsCreator.fn(data as Avatar[]));
                        dispatch(selectTreeLevelItemCreator.fn((data as Avatar[])[0]['@id']));
                    } else {
                        setIsEmpty(true);
                    }
                    setIsConfiguring(false);
                }
            })
            .catch((e) => {
                notify('app.errors.server', 'warning');
                console.error('ERROR getting', AVATARS, ' error  message', e);
            });
    }, []);

    return !isConfiguring && (treeLevelSelected || isEmpty) ? (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.navigation.breadcrumbs.1')]}
                    lastItem={translate('app.navigation.breadcrumbs.0')}
                />
            </Grid>

            <Grid item>
                <BackDiv>
                    {({ divHeight }: { divHeight: number }) => (
                        <NavigationList isEmpty={isEmpty} divHeight={divHeight} />
                    )}
                </BackDiv>
            </Grid>
        </Grid>
    ) : null;
};

export default NavigationPageIndex;
