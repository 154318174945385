import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
import React from 'react';
import { useTranslate } from 'react-admin';
import { TopToolbarButtonStyled } from '../../../theme';
import { IncidentStatus } from '../../../utils/CONST';
import { registerCustomizeColumnsIncidentsInKanbanListView } from '../../../redux/actions/incidents';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';

const useStyles = makeStyles({
    topToolbar: {
        marginLeft: '0',
        marginRight: 'auto',
        // paddingTop: "8px",
        paddingBottom: '8px',
        flexDirection: 'row-reverse',
    },
    buttonFontWeightBold: {
        fontWeight: 'bold',
    },
    fontWeightBold: {
        fontWeight: 'bold',
    },
});

const DynamicOrderKanbanColumns = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const enabledColumns = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.customizedColumns.KANBAN_LIST
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleColumn = (column: string, isEnabled: boolean) => {
        const newColumnsEnabled = {
            ...enabledColumns,
            [column]: isEnabled,
        };

        dispatch(registerCustomizeColumnsIncidentsInKanbanListView.fn(newColumnsEnabled));
    };

    const onMenuClick = (column: string) => () => {
        const isEnabled = column in enabledColumns ? enabledColumns[column] : null;
        if (isEnabled === null) {
            console.error(`Column ${column} is not registered as a List's column`);
            alert(`Column ${column} is not registered as a List's column`);
        }

        toggleColumn(column, !isEnabled);
        // handleClose();
    };

    return (
        <Grid item>
            <TopToolbarButtonStyled
                size={'small'}
                aria-controls='simple-menu'
                aria-haspopup='true'
                className={classes.buttonFontWeightBold}
                startIcon={<ListIcon />}
                onClick={handleClick}
            >
                {translate('app.menuActions.customize')}
            </TopToolbarButtonStyled>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {Object.keys(enabledColumns).map((stateName) =>
                    stateName in IncidentStatus ? (
                        <MenuItem onClick={onMenuClick(stateName)} key={`menu_item_${stateName}`}>
                            <FormControlLabel
                                className={classes.fontWeightBold}
                                control={
                                    <Checkbox
                                        size={'small'}
                                        checked={enabledColumns[stateName]}
                                        color='primary'
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        // @ts-ignore
                                        name={translate(IncidentStatus[stateName])}
                                    />
                                }
                                label={
                                    <Typography
                                        noWrap
                                        variant={'button'}
                                        color={'primary'}
                                        className={classes.fontWeightBold}
                                    >
                                        {' '}
                                        {/*// @ts-ignore*/}
                                        {translate(IncidentStatus[stateName])}
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    ) : (
                        <span />
                    )
                )}
            </Menu>
        </Grid>
    );
};

export default DynamicOrderKanbanColumns;
