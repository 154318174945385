import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { List } from 'react-admin';
import { incidentTypes } from '../../../pages/paths';
import { METADATA_AVATAR_TYPES } from '../../../providers/resources';
import IncidentTypeListActions from '../actions';
import IncidentTypesDatagrid from '../datagrid';
import IncidentTypesSidebar from '../aside';
import { GROUP_METADATA_AVATAR_TYPE_EVENT } from '../../../utils/CONST';
import CreatePlaceTypeForm from '../actions/create';
import IncidentBulkActionButtons from '../../incidents/IncidentBulkActionButtons';
import IncidentTypeBulkActionButtons from '../bulkActionButtons';

const useStyles = (divHeight: number) =>
    makeStyles({
        content: {
            overflow: 'auto',
        },
        main: {
            height: divHeight,
        },
        bulkActionsDisplayed: {
            marginTop: '0px !important',
        },
    });

const IncidentsTypesList = ({ divHeight }: { divHeight: number }) => {
    const classes = useStyles(divHeight - 40)();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(true);
    const onCloseCreateForm = () => {
        setShowCreateForm(false);
    };

    const listProps = {
        resource: METADATA_AVATAR_TYPES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: incidentTypes,
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_EVENT,
            'serialization_groups[0]': 'read_meta_files',
            'serialization_groups[1]': 'link',
            hidden: false,
        },
        classes: {
            main: classes.main,
            content: classes.content,
            bulkActionsDisplayed: classes.bulkActionsDisplayed,
        },
    };

    return (
        <List
            {...listProps}
            exporter={false}
            aside={<IncidentTypesSidebar divHeight={divHeight} />}
            actions={<IncidentTypeListActions />}
            bulkActionButtons={<IncidentTypeBulkActionButtons />}
            empty={<CreatePlaceTypeForm open={showCreateForm} handleClose={onCloseCreateForm} />}
        >
            <IncidentTypesDatagrid />
        </List>
    );
};

export default IncidentsTypesList;
