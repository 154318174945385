import { Identifier, ReduxState } from 'react-admin';
import { GroupMetadataAvatarType } from '../../utils/types';
import { SelectCategoriesAction, selectCategory } from '../actions/categories';
import { CustomReducerState } from './index';
import { GROUP_METADATA_AVATAR_TYPES } from '../../providers/resources';
import { clearTicketingStateCreator } from '../actions/ticketingState';

export interface CategoriesState {
    selected: {
        [key: Identifier]: GroupMetadataAvatarType;
    };
}

const InitialState: CategoriesState = {
    selected: {},
};

export const getCategorySelected = (state: CustomReducerState & ReduxState): GroupMetadataAvatarType | undefined => {
    const selected = state.ticketing.categories.selected;
    const selectedUriKey = selected ? Object.keys(selected) : [];
    if (
        selected &&
        selectedUriKey.length > 0 &&
        state.admin.resources[GROUP_METADATA_AVATAR_TYPES].list.ids.includes(selectedUriKey[0])
    ) {
        return state.admin.resources[GROUP_METADATA_AVATAR_TYPES].data[selectedUriKey[0]] as GroupMetadataAvatarType;
    }
    return undefined;
};

const reducer = (state: CategoriesState = InitialState, action: any) => {
    switch (action.type) {
        case selectCategory.action: {
            const iAction: SelectCategoriesAction = action;
            const newRecord = iAction.payload.record
                ? {
                      // @ts-ignore
                      [iAction.payload.record.id]: {
                          ...iAction.payload.record,
                      },
                  }
                : {};

            return {
                ...state,
                selected: {
                    ...newRecord,
                },
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
