import { DataProviderProxy, Identifier } from 'react-admin';
import { MEDIAS } from '../../providers/resources';
import { FileSystemMapper, Media } from '../types';
import { getDataJson } from '../data';
import extractIdFromURI from '../id';
import axios from 'axios';

export const METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA = 'metadata_avatar_type';
export const AVATAR_RESOURCE_SUPPORTED_MEDIA = 'avatar';

export interface GetFileSystemMapperMediaProps {
    fileSystemMapper: FileSystemMapper;
    media: Media;
}

export const FILE_SYSTEM_MAPPER_SOURCE = 'FILE_SYSTEM_MAPPER';
//@ts -ignore
export const getFileSystemMapperMedia = async (
    media: Media,
    resourceId: string,
    tags: string[] = [],
    lvl = 5
): Promise<GetFileSystemMapperMediaProps | null | void> => {
    return await getFileSystemMapper(media, resourceId, tags, lvl)
        .then((fileSystemMapper: FileSystemMapper | null) => {
            if (fileSystemMapper && '@id' in fileSystemMapper) {
                return {
                    fileSystemMapper,
                    media,
                };
            }
            return null;
        })
        .catch((error) => console.error(error));
};

export const getDownloadFileSystemMapperUrl = (media: Media, fileSystemMapper: FileSystemMapper) => {
    const entrypointUri = process.env.REACT_APP_FILE_SYSTEM_MAPPER_URI_ENTRYPOINT;
    const tokenAccess = media.contentUrlTokenAccess;
    return `${entrypointUri}${fileSystemMapper['@id']}/download?bearer=${tokenAccess}`;
};

export const getDownloadFileSystemMapperUrlWithTokenAccess = (
    tokenAccess: string,
    fileSystemMapper: FileSystemMapper
) => {
    const entrypointUri = process.env.REACT_APP_FILE_SYSTEM_MAPPER_URI_ENTRYPOINT;
    return `${entrypointUri}${fileSystemMapper['@id']}/download?bearer=${tokenAccess}`;
};

export const getFileSystemMapper = async (
    media: Media,
    resourceId: string,
    tags: string[],
    lvl = 5
): Promise<FileSystemMapper | null> => {
    const parentName = `sc:folder-${media.customFields.resourceSupported}:${resourceId}`;
    const entrypoint = process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT;

    let tagFilters = '';
    tags.forEach((tagName) => {
        tagFilters = `${tagFilters}&tags.name[]=${tagName}`;
    });

    const paramsUrl = `parent.parent.name=${parentName}&lvl=${lvl}&name=${media.name}${tagFilters}`;
    let url = `${entrypoint}/smartConnectView/file_system_mappers`;
    const tokenAccess = media.contentUrlTokenAccess;
    url = `${url}?bearer=${tokenAccess}&${paramsUrl}`;

    const fileSystemMapperDataResult = await getDataJson(url, false, undefined).catch((error) => console.error(error));
    const hydraMember =
        fileSystemMapperDataResult &&
        'hydra:member' in fileSystemMapperDataResult &&
        Array.isArray(fileSystemMapperDataResult['hydra:member'])
            ? fileSystemMapperDataResult['hydra:member']
            : [];
    return hydraMember.length > 0 ? hydraMember[0] : null;
};

export const getFileSystemMappersByResource = async (
    resourceSupported: string | undefined,
    resourceId: string | undefined,
    tags: string[],
    tokenAccess?: string,
    url?: string,
    lvl = 5
): Promise<FileSystemMapper[] | null> => {
    if (!tokenAccess || !resourceId || !resourceSupported) return null;

    const parentName = `sc:folder-${resourceSupported}:${resourceId}`;
    const entrypoint = process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT;

    let tagFilters = '';
    tags.forEach((tagName) => {
        tagFilters = `${tagFilters}&tags.name[]=${tagName}`;
    });

    const paramsUrl = `parent.parent.name=${parentName}&lvl=${lvl}${tagFilters}`;
    let urlP = url ? url : `${entrypoint}/smartConnectView/file_system_mappers`;
    urlP = `${urlP}?bearer=${tokenAccess}&${paramsUrl}`;

    const fileSystemMapperDataResult = await getDataJson(urlP, false, undefined).catch((error: any) =>
        console.error(error)
    );
    const hydraMembers =
        fileSystemMapperDataResult &&
        'hydra:member' in fileSystemMapperDataResult &&
        Array.isArray(fileSystemMapperDataResult['hydra:member'])
            ? fileSystemMapperDataResult['hydra:member']
            : [];
    return hydraMembers.length > 0 ? hydraMembers : null;
};

export const getFileSystemMapperBlob = (
    tokenWithRoles: string,
    id: string,
    successCallback: (data: Blob) => void,
    failureCallback: (error: any) => void
) => {
    const entrypoint = process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT;
    return axios
        .get(`${entrypoint}/smartConnectView/file_system_mappers/${id}/download`, {
            headers: {
                Authorization: `Bearer ${tokenWithRoles}`,
            },
            responseType: 'blob',
        })
        .then(({ data }) => {
            successCallback(data);
        })
        .catch((error: any) => {
            failureCallback(error);
        });
};

export const resolveMedia = (
    mediaUri: Identifier,
    dataProvider: DataProviderProxy
    // callbackSuccess: (response: any) => void,
    // callbackFail: (response: any) => void
): Promise<any> =>
    dataProvider.getOne(MEDIAS, { id: mediaUri }).catch(() => ({
        data: {
            id: null,
        },
    }));

export const mediaCameFromToFileSystemMapperService = (media: Media) => {
    if (!media) return false;
    return (
        'source' in media &&
        media.source === FILE_SYSTEM_MAPPER_SOURCE &&
        'contentUrlTokenAccess' in media &&
        media.contentUrlTokenAccess
    );
};

export const postUploadMedia = (
    base64: Blob | ArrayBuffer | string,
    fileName: string,
    resourceSupported: 'avatar' | 'mc' | 'company' | 'metadata_avatar_type' | 'group_metadata_avatar_type',
    resourceId: string,
    token: string,
    mediaType: 'IMAGE' | 'FILE' | 'AUDIO' = 'FILE',
    tags: string[],
    destinationUriCompany: string | undefined = undefined
) => {
    if (!resourceSupported) throw new Error('You must specify a resource');
    if (!fileName) throw new Error('You must specify a file name');
    if (!base64) throw new Error('The file in base64 format is not present');
    if (!resourceId) throw new Error('You must specify the resource id');

    const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
    const urlToPost = `${entrypoint}/medias`;

    const body = {
        mediaType,
        resourceSupported,
        resourceId,
        fileName,
        base64data: base64,
        tags,
    };

    destinationUriCompany && Object.assign(body, { destinationCompany: destinationUriCompany });

    return postDataJsonLd(urlToPost, body, token).then((response) => response.json());
};

const postDataJsonLd = (urlToPost: string, body: any = {}, token: string) => {
    return fetch(urlToPost, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/ld+json',
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const saveMedia = async (
    fileData: string,
    fileName: string,
    destinationCompanyId: string,
    resourceId: string,
    tokenWithRoles: string,
    tags: string[]
): Promise<Media | undefined> => {
    if (tokenWithRoles) {
        return await postUploadMedia(
            fileData,
            fileName,
            'metadata_avatar_type',
            resourceId,
            tokenWithRoles,
            'IMAGE',
            tags,
            destinationCompanyId
        ).catch((error: any) => console.error(error));
    } else {
        return undefined;
    }
};

export const imagesExistInFileSystemMapper = (
    imagesUri: string[],
    fileSystemMappers: FileSystemMapper[] | undefined
): string[] | [] => {
    if (!fileSystemMappers) return [];
    return imagesUri.filter((imageUri) =>
        fileSystemMappers.find(
            (fileSystemMapper) =>
                fileSystemMapper.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(imageUri)
        )
    );
};
