import { FileSystemMapper, Media } from '../../../utils/types';
import { Identifier } from 'react-admin';

export interface RegisterMediaAction {
    type: typeof registerMedia.action;
    payload: {
        id: Identifier;
        media: Media;
        fileSystemMapper: FileSystemMapper;
    };
}

export const registerMedia = {
    action: '360SC_TICKETING/REGISTER_MEDIA',
    fn: (id: Identifier, media: Media, fileSystemMapper: FileSystemMapper): RegisterMediaAction => ({
        type: registerMedia.action,
        payload: {
            id,
            media,
            fileSystemMapper,
        },
    }),
};

export interface ResolveMediaAction {
    type: typeof resolveMedia.action;
    payload: {
        imageId: Identifier;
        recordId: Identifier;
    };
}

export const resolveMedia = {
    action: '360SC_TICKETING/RESOLVE_MEDIA',
    fn: (imageId: Identifier, recordId: Identifier): ResolveMediaAction => ({
        type: resolveMedia.action,
        payload: {
            imageId,
            recordId,
        },
    }),
};

export interface DeleteMediaAction {
    type: typeof deleteMedia.action;
    payload: {
        id: Identifier;
    };
}

export const deleteMedia = {
    action: '360SC_TICKETING/DELETE_MEDIA',
    fn: (id: Identifier): DeleteMediaAction => ({
        type: deleteMedia.action,
        payload: {
            id,
        },
    }),
};

export type ACTIONS = RegisterMediaAction | DeleteMediaAction | ResolveMediaAction;
