import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppIcon from '@material-ui/icons/Apps';
import HelpIcon from '@material-ui/icons/Help';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Identifier, Record, useDataProvider, usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    categoriesEvent,
    categoriesLevel,
    incidents,
    incidentTypes,
    navigation,
    statistics,
    subCategories,
    users,
} from '../../pages/paths';
import { resolvedCompanyLogoOnFileSystemMapperCreator } from '../../redux/actions/users';
import { CustomReducerState } from '../../redux/reducers';
import { APP_BAR_BACKGROUND_COLOR, APP_BAR_MAX_HEIGHT, APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST } from '../../theme';

import ScIconText from '../../theme/360scIconsvg';
import {
    getDownloadFileSystemMapperUrl,
    getFileSystemMapperMedia,
    mediaCameFromToFileSystemMapperService,
    resolveMedia,
} from '../../utils/medias';
import { ListItemProps, User, UserCategory } from '../../utils/types';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import UserInformation from './userInformation';
import {
    ROLE_FM_CLOUD_READ,
    ROLE_FM_CLOUD_READ_DOWNLOAD,
    ROLE_READ_AVATAR,
    ROLE_READ_GROUP_OBJECT_TYPE,
    ROLE_READ_OBJECT_TYPE,
    ROLE_READ_OBJECT_TYPE_PROPERTIES,
    ROLE_READ_USER,
    ROLE_TICKETING_VIEW_EVENT_NAVIGATION,
    ROLE_VIEW_GENERAL,
    ROLE_VIEW_GROUP_OBJECT_TYPES,
    ROLE_VIEW_OBJECT_TYPES,
    ROLE_VIEW_OBJECTS,
    ROLE_VIEW_USERS,
} from '../../utils/ROLES';
import { redirectToUserPage } from '../../utils/redirectTo360scBackoffice';
import extractIdFromURI, { getUriFromItem } from '../../utils/id';
import { hasPermissions } from '../../utils/permissions';
import { getUserCategory } from '../../utils/user/tags';
import { USER_PROVIDER_CATEGORY } from '../../utils/CONST';

const useStyles = makeStyles(() => ({
    sideBarRoot: {
        height: '100vh',
        overflowY: 'hidden',
        width: 275,
        backgroundColor: '#E4E5EB',
    },
    containerImage: {
        height: '40px',
        width: '40px',
        marginLeft: 30,
        marginRight: 30,
    },
    imageRounded: {
        borderRadius: '50%',
        border: '1px solid white',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    imageIcon: {
        height: '100%',
    },
    iconRoot: {
        textAlign: 'center',
    },
    main: {
        flexGrow: 1,
        backgroundColor: APP_BAR_BACKGROUND_COLOR,
        boxShadow: 'none',
        maxHeight: APP_BAR_MAX_HEIGHT,
    },
    divMain: {
        height: APP_BAR_MAX_HEIGHT,
    },
    title: {
        marginLeft: '150px',
        flex: 1,
    },
    badgeRoot: {
        position: 'unset',
    },
    badge: {
        top: 'unset',
        right: 'unset',
        left: '5px',
    },
    notificationButton: {
        color: 'white',
        textTransform: 'capitalize',
    },
    divBackButton: {
        backgroundColor: '#424242',
    },
    backArrow: {
        color: 'white',
    },
    iconMenu: {
        color: 'whitesmoke !important',
        marginRight: '14px',
    },
    rootMenuItem: {
        height: '35px',
        paddingLeft: '8px',
        '&$selectedMenuItem': {
            backgroundColor: APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST,
        },
        display: 'flex',
        justifyContent: 'flex-start',
    },
    rootListItemIcon: {
        // color: theme.palette.primary.main,
        padding: 0,
        fontWeight: 800,
        display: 'inline-flex',
        minWidth: 'unset',
        marginRight: '8px',
    },
    body2Typography: {
        fontWeight: 400,
        maxWidth: '190px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    iconHelp: {
        marginRight: 8,
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        width: 'auto',
    },
}));

const PlatformAppBar = () => {
    const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
    const schemaAndHostUrlRootServer: string | undefined = process.env.REACT_APP_ASSETS_ROOT;
    const companyLogoSrc360 = `images/logo_360sc.svg`;

    const { push } = useHistory();

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const companyLogoUrlFromUserState = useSelector(
        (state: CustomReducerState) => state.ticketing.user.cache?.companyLogoUrl
    );

    const [companyLogoName, setCompanyLogoName] = useState<string | null>(null);
    const [listMenuItem, setListMenuItem] = useState<ListItemProps[] | null>(null);
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [companyLogoUrlInFileSystemMapper, setCompanyLogoUrlInFileSystemMapper] = useState<string | null>();
    const [hasFileSystemMapperPermissions, setFileSystemMapperPermissions] = useState<boolean | null>();

    const [userName, setUserName] = useState<string | null>(null);
    const [userImageSrc, setImageSrc] = useState<string | null>(
        `${schemaAndHostUrlRootServer}/images/default-user.svg`
    );

    const classes = useStyles();

    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );

    const { loaded, permissions } = usePermissions();

    const incidentListItem = {
        icon: <AppIcon />,
        label: translate('app.headers.operations'),
        witCollapse: true,
        openByDefault: true,
        collapseData: [
            {
                label: translate('app.headers.incidents'),
                onClick: () => changeView(incidents),
                roles: [ROLE_VIEW_OBJECTS, ROLE_READ_AVATAR],
            },
        ],
    };

    const resolveLogo = async (logoUri: Identifier) => {
        const response = await resolveMedia(logoUri, dataProvider);
        const data = response && 'data' in response ? response.data : null;

        if (
            hasFileSystemMapperPermissions &&
            data &&
            data.id &&
            mediaCameFromToFileSystemMapperService(data) &&
            !companyLogoUrlFromUserState
        ) {
            const result = await getFileSystemMapperMedia(data, userConnected?.company.id);

            result &&
                dispatch(
                    resolvedCompanyLogoOnFileSystemMapperCreator.fn(
                        getDownloadFileSystemMapperUrl(result.media, result.fileSystemMapper)
                    )
                );
        } else {
            setCompanyLogoName(userConnected?.company.logo.name);
        }
    };

    const loadLogoToDisplay = () => {
        if (userConnected) {
            setCompanyName(userConnected.company.name);
            setUserName(`${userConnected.firstName} ${userConnected.lastName}`);
            if (userConnected?.image?.name) {
                setImageSrc(`${schemaAndHostUrlRootServer}/files/${userConnected?.image?.name}`);
            }
            if (userConnected?.company?.logo && '@id' in userConnected?.company?.logo) {
                resolveLogo(userConnected?.company.logo['@id']).finally();
            }
        }
    };

    const updateCompanyLogo = () => {
        if (companyLogoUrlFromUserState && !companyLogoUrlInFileSystemMapper) {
            setCompanyLogoUrlInFileSystemMapper(companyLogoUrlFromUserState);
        }
    };

    useEffect(() => {
        if ((!listMenuItem || listMenuItem?.length === 0) && userConnected) {
            let list: ListItemProps[] = [];
            const userCategory = getUserCategory(userConnected as User) as UserCategory;
            if (userCategory === USER_PROVIDER_CATEGORY) {
                list.push(incidentListItem);
            } else {
                list = [
                    {
                        icon: <AppIcon />,
                        label: translate('app.headers.dashboard'),
                        witCollapse: true,
                        openByDefault: true,
                        collapseData: [
                            {
                                label: translate('app.headers.statistics'),
                                onClick: () => changeView(statistics),
                                roles: [ROLE_VIEW_GENERAL],
                            },
                        ],
                    },
                    incidentListItem,
                    {
                        icon: <AppIcon />,
                        label: translate('app.headers.configuration'),
                        witCollapse: true,
                        openByDefault: true,
                        collapseData: [
                            {
                                label: translate('app.headers.navigation'),
                                onClick: () => changeView(navigation),
                                roles: [ROLE_VIEW_OBJECTS, ROLE_READ_AVATAR, ROLE_TICKETING_VIEW_EVENT_NAVIGATION],
                            },
                            {
                                label: translate('app.headers.categoriesLevel'),
                                onClick: () => changeView(categoriesLevel),
                                roles: [ROLE_VIEW_GROUP_OBJECT_TYPES, ROLE_READ_GROUP_OBJECT_TYPE],
                            },
                            {
                                label: translate('app.headers.subCategories'),
                                onClick: () => changeView(subCategories),
                                roles: [
                                    ROLE_VIEW_OBJECT_TYPES,
                                    ROLE_READ_OBJECT_TYPE,
                                    ROLE_READ_OBJECT_TYPE_PROPERTIES,
                                ],
                            },
                            {
                                label: translate('app.headers.categoriesEvent'),
                                onClick: () => changeView(categoriesEvent),
                                roles: [ROLE_VIEW_GROUP_OBJECT_TYPES, ROLE_READ_GROUP_OBJECT_TYPE],
                            },
                            {
                                label: translate('app.headers.incidentTypes'),
                                onClick: () => changeView(incidentTypes),
                                roles: [
                                    ROLE_VIEW_OBJECT_TYPES,
                                    ROLE_READ_OBJECT_TYPE,
                                    ROLE_READ_OBJECT_TYPE_PROPERTIES,
                                ],
                            },
                            {
                                label: translate('app.headers.users'),
                                onClick: () => changeView(users),
                                roles: [ROLE_READ_USER, ROLE_VIEW_USERS],
                            },
                        ],
                    },
                ];
            }
            setListMenuItem(list);
        }
    }, [userConnected]);

    useEffect(() => {
        if (loaded && permissions && userConnected) {
            setFileSystemMapperPermissions(
                hasPermissions([ROLE_FM_CLOUD_READ_DOWNLOAD, ROLE_FM_CLOUD_READ], loaded, permissions)
            );
        }
    }, [userConnected, loaded, permissions]);

    useEffect(loadLogoToDisplay, [userConnected, schemaAndHostUrlRootServer, loaded, hasFileSystemMapperPermissions]);

    useEffect(updateCompanyLogo, [companyLogoUrlFromUserState, companyLogoUrlInFileSystemMapper]);

    const companyLogoSrc = companyLogoName
        ? `${schemaAndHostUrlRootServer}/files/${companyLogoName}`
        : `${schemaAndHostUrlRootServer}/${companyLogoSrc360}`;

    const MyLogoSvgImage = new Image();
    MyLogoSvgImage.src = 'data:image/svg+xml,' + escape(ScIconText);
    const toggleSideBar = () => setSideBarIsOpen(!sideBarIsOpen);

    const changeView = (toPage: string) => {
        push(toPage);
        toggleSideBar();
    };

    const openHelpPage = () => {
        window.open(process.env.REACT_APP_HELP_PAGE_URL, '_blank');
    };

    return (
        <>
            <AppBar style={{ zIndex: 0 }} className={classes.main} data-testid='app-bar'>
                <Toolbar>
                    <IconButton
                        edge='start'
                        aria-label='menu'
                        id='left-icon'
                        className={classes.iconMenu}
                        onClick={toggleSideBar}
                        data-testid='icon-button-menu'
                    >
                        <MenuIcon />
                    </IconButton>

                    <img
                        alt={''}
                        height='50px'
                        src={MyLogoSvgImage.src}
                        // style={{ marginRight: 5 }}
                        style={{
                            height: '35px',
                            width: '77px',
                            position: 'absolute',
                            right: 45,
                        }}
                    />

                    <img
                        alt={''}
                        style={{
                            height: 35,
                            width: 'auto',
                            maxHeight: 35,
                            maxWidth: 70,
                        }}
                        src={!companyLogoUrlInFileSystemMapper ? companyLogoSrc : companyLogoUrlInFileSystemMapper}
                    />
                    <HelpIcon id='left-help-icon' className={classes.iconHelp} onClick={openHelpPage} />
                </Toolbar>
            </AppBar>

            <Drawer open={sideBarIsOpen} id='left-drawer' onClose={toggleSideBar} data-testid='drawer-menu'>
                <div className={classes.divBackButton}>
                    <IconButton onClick={toggleSideBar}>
                        <ArrowBackIcon className={classes.backArrow} />
                    </IconButton>
                </div>
                <div className={classes.sideBarRoot}>
                    <UserInformation companyName={companyName} userName={userName} userImageSrc={userImageSrc} />
                    {listMenuItem && <SideBarMenu list={listMenuItem} />}
                    {/*<SideBarMenu list={list} />*/}
                </div>
            </Drawer>
        </>
    );
};

export default PlatformAppBar;
