import { styled } from '@material-ui/core/styles';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import React, { useMemo, useRef, useState } from 'react';
import { CreateResult, Record, useCreate, useNotify, useRefresh, useTranslate } from 'react-admin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import DialogTemplate from '../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { TICKETING_EVENT_INSTRUCTION_HELPS } from '../../../../../providers/resources';
import { CustomReducerState } from '../../../../../redux/reducers';
import { MetadataAvatarType, User } from '../../../../../utils/types';
import Gallery from '../gallery/gallery';
import './rich-text-create.css';

const RootDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 300px',
    columnGap: 30,
    alignItems: 'start',
});

interface Props {
    open: boolean;
    onClose: () => void;
    subCategory: MetadataAvatarType;
    setSelectedInstructionId: any;
}

const RichTextCreate = ({ subCategory, open, onClose, setSelectedInstructionId }: Props) => {
    const [create, { loading: loadingCreate }] = useCreate();
    const notify = useNotify();
    const translate = useTranslate();
    const [editorValue, setEditorValue] = useState<string>('');
    const ref: any = useRef(null);
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );
    const refresh = useRefresh();

    const saveRichTextRequest = (data: any, onSuccessCallback: any) => {
        create(TICKETING_EVENT_INSTRUCTION_HELPS, data, {
            onSuccess: ({ data }: CreateResult) => {
                onSuccessCallback(data);
                notify('app.incidentTypes.instructions.notifications.createSuccess', 'success');
            },
            onFailure: (error) => {
                console.error('saveRichTextRequest', error);
                notify('app.incidentTypes.instructions.notifications.createError', 'error');
            },
        });
    };

    const handleSaveRichText = (html: string) => {
        saveRichTextRequest(
            {
                subCategory: subCategory['@id'],
                customFields: {
                    'version_0.content': {
                        html,
                    },
                },
                company: userConnected?.company['@id'],
            },
            (data: any) => {
                setSelectedInstructionId(data['@id']);
                refresh();
                onClose();
            }
        );
    };

    const insertImage = (url: string) => {
        const editor = ref?.current?.getEditor();
        const range = editor.getSelection(true);
        editor.insertEmbed(range.index, 'image', url);
    };

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, false] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['link'],
            ],
        }),
        []
    );

    return (
        <DialogTemplate
            title={`${translate('app.incidentTypes.instructions.createDialogTitle')} ${subCategory.name}`}
            open={open}
            onClose={onClose}
            icon={<FontDownloadIcon />}
            maxWidth={'md'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onClose}
                    withDelete
                    deleteButtonLabel={translate('app.buttons.valid')}
                    isSubmitting={loadingCreate}
                    isValidForm={editorValue !== '' && editorValue !== '<p><br></p>'}
                    onDeleteClick={() => handleSaveRichText(editorValue)}
                />
            }
        >
            <RootDiv>
                <div>
                    <ReactQuill
                        ref={ref}
                        theme='snow'
                        value={editorValue}
                        onChange={setEditorValue}
                        modules={modules}
                    />
                </div>
                <Gallery subCategory={subCategory} insertImage={insertImage} />
            </RootDiv>
        </DialogTemplate>
    );
};

export default RichTextCreate;
