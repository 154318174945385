import React, { useState } from 'react';
import { Avatar } from '../../../../utils/types';
import { hasPermissions } from '../../../../utils/permissions';
import { ROLE_TICKETING_VIEW_CLOSE_EVENT, ROLE_UPDATE_TRACEABILITY_STATE } from '../../../../utils/ROLES';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import { usePermissions, useRefresh, useTranslate } from 'react-admin';
import { isNodeType } from '../../../../utils/getListOfNodesFromRunnerTempates';
import { END_NODE_TYPE } from '../../../../utils/CONST';
import { CustomCircularProgress, CustomIconButton } from './commons';
import CloseEvent from '../closeEvent';

interface Props {
    record: Avatar;
}
const CloseButton = (props: Props) => {
    const { record } = props;
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const translate = useTranslate();

    const [showCloseEventDialog, setShowCloseEventDialog] = useState<boolean>(false);
    const [currentRecordToClose, setCurrentRecordToClose] = useState<Avatar | null>(null);

    const isNodeTypeEnd = record ? isNodeType(record, END_NODE_TYPE) : false;
    const hasAvatarProcess = !!(record && record.currentReferencing);

    const handleCloseIncident = (record: Avatar) => () => {
        setShowCloseEventDialog(true);
        setCurrentRecordToClose(record);
    };
    return (
        <>
            {hasPermissions(
                [ROLE_UPDATE_TRACEABILITY_STATE, ROLE_TICKETING_VIEW_CLOSE_EVENT],
                loadedPermissions,
                permissions
            ) &&
                !showCloseEventDialog && (
                    <div>
                        <Tooltip
                            title={
                                !isNodeTypeEnd
                                    ? translate('app.incidents.information.closeIncident')
                                    : translate('app.incidents.information.incidentClosed')
                            }
                        >
                            <CustomIconButton
                                onClick={
                                    hasAvatarProcess && !isNodeTypeEnd && record
                                        ? handleCloseIncident(record as Avatar)
                                        : () => null
                                }
                            >
                                <ClearIcon color={isNodeTypeEnd || !hasAvatarProcess ? 'disabled' : 'primary'} />
                            </CustomIconButton>
                        </Tooltip>
                    </div>
                )}
            {showCloseEventDialog && <CustomCircularProgress size={18} />}
            {showCloseEventDialog && (
                <CloseEvent
                    event={record}
                    open={showCloseEventDialog}
                    handleClose={() => setShowCloseEventDialog(false)}
                />
            )}
        </>
    );
};
export default CloseButton;
