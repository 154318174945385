import { setDndId, setDndList, setDndState } from '../actions/dnd';
import { clearTicketingStateCreator } from '../actions/ticketingState';

export interface DndState {
    active: boolean;
    id: string | null;
    list: any[];
}

const InitialState: DndState = {
    active: false,
    id: null,
    list: [],
};

const reducer = (state: DndState = InitialState, action: any) => {
    switch (action.type) {
        case setDndState.action: {
            return {
                ...state,
                active: action.payload.state,
            };
        }
        case setDndId.action: {
            return {
                ...state,
                id: action.payload.id,
            };
        }
        case setDndList.action: {
            return {
                ...state,
                list: action.payload.list,
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
