import { CircularProgress, PropTypes } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    button: {
        // marginLeft: 10,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: -12,
        marginTop: -12,
    },
    wrapper: {
        position: 'relative',
    },
});

interface ButtonProps {
    color?: PropTypes.Color;
    variant?: string;
    disabled?: boolean;
    type?: string;
    withLoading?: boolean;
    'data-testid'?: string;
}

interface Props {
    buttons?: {
        onClick: (() => void) | undefined | null;
        label: string;
        props?: ButtonProps;
    }[];
    withCancel?: boolean;
    withEdit?: boolean;
    withCreate?: boolean;
    isSubmitting?: boolean;
    isValidForm?: boolean;
    onCancelClick?: () => void;
    onDeleteClick?: () => void;
    withDelete?: boolean;
    createButtonLabel?: string;
    editButtonLabel?: string;
    deleteButtonLabel?: string;
    cancelButtonLabel?: string;
    outside?: boolean;
}

const DialogTemplateButtons = ({
    buttons = [],
    withCancel,
    withEdit,
    withCreate,
    isSubmitting = false,
    isValidForm = true,
    onCancelClick,
    onDeleteClick,
    withDelete,
    createButtonLabel,
    editButtonLabel,
    deleteButtonLabel,
    cancelButtonLabel,
    outside = false,
}: Props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const renderButton = (
        onClick: (() => void) | undefined | null,
        label: string,
        props: ButtonProps,
        index?: number
    ) => (
        <div className={classes.wrapper} {...(index !== undefined ? { key: index } : {})}>
            {/*@ts-ignore*/}
            <Button onClick={onClick ? onClick : () => null} className={classes.button} {...props}>
                {/* eslint-disable-next-line react/prop-types */}
                {'disabled' in props && props.disabled && 'withLoading' in props && props.withLoading ? (
                    <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                    label
                )}
            </Button>
        </div>
    );

    const submitProps: ButtonProps = {
        color: 'primary',
        variant: 'contained',
        disabled: isSubmitting || !isValidForm,
        type: 'submit',
        withLoading: isSubmitting,
    };

    const disabledProps: ButtonProps = {
        disabled: isSubmitting,
    };

    const normalProps: ButtonProps = {
        color: 'primary',
        variant: 'contained',
        disabled: isSubmitting || !isValidForm,
        withLoading: isSubmitting,
    };

    const renderCancelButton = () =>
        renderButton(onCancelClick, cancelButtonLabel ? cancelButtonLabel : translate('app.buttons.cancel'), {
            color: 'primary',
            ...disabledProps,
            'data-testid': 'dialogTemplateButtonsCancel',
        });

    const renderDeleteButton = () =>
        renderButton(onDeleteClick, deleteButtonLabel ? deleteButtonLabel : translate('app.buttons.delete'), {
            ...normalProps,
            'data-testid': 'dialogTemplateButtonsDelete',
        });

    const renderEditButton = () =>
        renderButton(null, editButtonLabel ? editButtonLabel : translate('app.buttons.validate'), {
            ...submitProps,
            'data-testid': 'dialogTemplateButtonsEdit',
        });

    const renderCreateButton = () =>
        renderButton(null, createButtonLabel ? createButtonLabel : translate('app.buttons.add'), {
            ...submitProps,
            'data-testid': 'dialogTemplateButtonsCreate',
        });

    return (
        <DialogActions style={{ ...(outside ? { padding: '15px 0 0 0' } : { padding: 15 }) }}>
            {withCancel && onCancelClick && renderCancelButton()}

            {buttons.map((item, index) => renderButton(item.onClick, item.label, item.props ? item.props : {}, index))}

            {withDelete && onDeleteClick && renderDeleteButton()}

            {withEdit && renderEditButton()}

            {withCreate && renderCreateButton()}
        </DialogActions>
    );
};

export default DialogTemplateButtons;
