import { getTokenData } from '../token';

export const getKanbanViewListOrderedByUserIdConnectedFromLocalStorage = (): string | null => {
    //@ts-ignore
    const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
    const tokenDecoded = token ? getTokenData(token) : null;

    if (tokenDecoded?.user_id) {
        const key = `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_KANBAN_VIEW_LIST_ORDER}_${tokenDecoded?.user_id}`;
        //@ts-ignore
        return localStorage.getItem(key);
    }
    return null;
};

export const getViewModeListByUserIdConnectedFromLocalStorage = (): string => {
    //@ts-ignore
    const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
    const tokenDecoded = token ? getTokenData(token) : null;

    if (tokenDecoded?.user_id) {
        const key = `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_VIEW_LIST}_${tokenDecoded?.user_id}`;
        //@ts-ignore
        return localStorage.getItem(key);
    }
    return '';
};

export const getCustomizeColumnsEnabledInListViewModeByUserIdConnectedFromLocalStorage = (): string => {
    //@ts-ignore
    const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
    const tokenDecoded = token ? getTokenData(token) : null;

    if (tokenDecoded?.user_id) {
        const key = `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_LIST_VIEW_COLUMNS_ENABLED}_${tokenDecoded?.user_id}`;
        //@ts-ignore
        return localStorage.getItem(key);
    }
    return '';
};

export const getCustomizeColumnsEnabledInKanbanViewModeByUserIdConnectedFromLocalStorage = (): string => {
    //@ts-ignore
    const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
    const tokenDecoded = token ? getTokenData(token) : null;

    if (tokenDecoded?.user_id) {
        const key = `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_KANBAN_VIEW_COLUMNS_ENABLED}_${tokenDecoded?.user_id}`;
        //@ts-ignore
        return localStorage.getItem(key);
    }
    return '';
};
