import React from 'react';
import { Avatar } from '../../../utils/types';
import useGetList from '../../react-query-hooks/useGetList';
import { AVATARS } from '../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import TreeItem from '@material-ui/lab/TreeItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Item from '../v1/Item';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import extractIdFromURI from '../../../utils/id';

const styles = makeStyles({
    label: {
        paddingLeft: 0,
    },
    root: {
        marginLeft: 5,
    },
});

interface Props {
    parent: Avatar;
}

const TreeList = ({ parent }: Props) => {
    const classes = styles();

    const { data, isInitialLoading } = useGetList(
        AVATARS,
        {
            pagination: {
                page: 1,
                perPage: 100,
            },
            sort: {
                field: 'lft',
                order: 'ASC',
            },
            filter: {
                parent: extractIdFromURI(parent.id as string),
                'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                serialization_groups: ['read_avatar_parent', 'read_avatar_root'],
            },
        },
        {
            keyToFind: `navigationParent_${extractIdFromURI(parent.id as string)}`,
        }
    );

    const notifyAction = () => {
        console.log('test');
    };

    return (
        <TreeItem
            nodeId={parent ? parent['@id'] : ''}
            label={<Item label={parent.name} icon={MailIcon} item={parent} notifyAction={notifyAction} />}
            classes={{
                label: classes.label,
                root: classes.root,
            }}
        >
            {isInitialLoading && <LinearProgress style={{ marginRight: 10, marginTop: 10, marginBottom: 10 }} />}

            {!isInitialLoading && data && data.map((item: Avatar) => <TreeList key={item.id} parent={item} />)}
        </TreeItem>
    );
};

export default TreeList;
