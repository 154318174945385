import { createContext } from 'react';
import { Avatar } from '../../utils/types';

export interface ActionsContextValue {
    moveActionState: boolean;
    itemToMove: Avatar | null;
    moveTo: Avatar | null;
    cloneActionState: boolean;
    itemToClone: Avatar | null;
    cloneTo: Avatar | null;
    reorderActionState: boolean;
    reorderTo: Avatar | null;
    itemToReorder: Avatar | null;
    handleCancelMoveTo: any;
    handleCancelClone: any;
    handleCancelReorder: any;
    setMoveActionState: any;
    setItemToMoveAction: any;
    setItemMoveToAction: any;
    setCloneActionState: any;
    setItemToCloneAction: any;
    setItemCloneToAction: any;
    setReorderActionState: any;
    setItemToReorderAction: any;
    setItemReorderToAction: any;
}

export const ActionsContext = createContext<ActionsContextValue>({
    moveActionState: false,
    itemToMove: null,
    moveTo: null,
    cloneActionState: false,
    itemToClone: null,
    cloneTo: null,
    reorderActionState: false,
    reorderTo: null,
    itemToReorder: null,
    handleCancelMoveTo: undefined,
    handleCancelClone: undefined,
    handleCancelReorder: undefined,
    setMoveActionState: undefined,
    setItemToMoveAction: undefined,
    setItemMoveToAction: undefined,
    setCloneActionState: undefined,
    setItemToCloneAction: undefined,
    setItemCloneToAction: undefined,
    setReorderActionState: undefined,
    setItemToReorderAction: undefined,
    setItemReorderToAction: undefined,
});
