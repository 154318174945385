import MuiCard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import { Filter, SearchInput } from 'react-admin';
import { sideBarMinWidth } from '../../../theme';
import './styles.css';

const FilterCardSideBarStyled = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        order: -1, // display on the left rather than on the right of the list
        minWidth: sideBarMinWidth,
        border: '1px solid #E6E6E6',
        borderRadius: 5,
        marginRight: 15,
        padding: 10,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

interface Props {
    divHeight: number;
    [key: string]: any;
}

const SubCategorySidebar = ({ divHeight, ...restProps }: Props) => {
    return (
        <FilterCardSideBarStyled style={{ height: divHeight - 61 }}>
            <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Grid item>
                    <Filter {...restProps}>
                        <SearchInput source='name' alwaysOn variant={'standard'} />
                    </Filter>
                </Grid>
            </Grid>
        </FilterCardSideBarStyled>
    );
};

export default SubCategorySidebar;
