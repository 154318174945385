import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { TopDiv } from '../../../../../theme';
import { Avatar } from '../../../../../utils/types';
import IncidentTypesList from './incident';

interface Props {
    item: Avatar;
    isRoot: boolean;
}

const ServiceProviderConfiguration: FC<Props> = ({ item, isRoot }: Props) => {
    const translate = useTranslate();

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <TopDiv>
                    <Typography variant={'body2'}>
                        {translate('app.navigation.configuration.serviceProvider.usageDescription')}
                    </Typography>
                </TopDiv>
                <IncidentTypesList avatar={item} isRoot={isRoot} />
            </Grid>
        </Grid>
    );
};

export default ServiceProviderConfiguration;
