import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { ImageStyles } from '../../../theme';

const iconsUrl = process.env.REACT_APP_ICONS_URL;

interface Props {
    iconData: any;
}

const useStyles = makeStyles({
    image: {
        ...ImageStyles,
    },
});

const IconField = ({ iconData }: Props) => {
    const classes = useStyles();

    const [iconUrl, setIconUrl] = useState<any>(null);

    useEffect(() => {
        const { collection, fileType } = iconData.customFields;
        const { name } = iconData;

        setIconUrl(`${iconsUrl}/collections/${collection}/svg/${name}.${fileType}`);
    }, [iconData]);

    return iconUrl ? (
        <img src={iconUrl} className={classes.image} alt='' />
    ) : (
        <img src={'/tmp-image.png'} className={classes.image} alt='' />
    );
};

export default IconField;
