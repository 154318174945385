import React from 'react';
import CreateFromAutocompleteInput from '../../../categoriesEvent/actions/create/CreateFromAutoCompleteInput';
import { AutocompleteInput } from 'react-admin';

const AutoCompleteInputEvent = (props: any) => {
    const { choices } = props;
    // console.log('AutoCompleteInputEvent', choices);
    //
    // const defaultChoice = choices && choices.length >= 1 ? choices[0] : { '@id': '' };
    //
    // console.log('AutoCompleteInputEvent defaultChoice', defaultChoice);

    return (
        <AutocompleteInput
            {...props}
            // defaultValue={defaultChoice['@id']}
            create={<CreateFromAutocompleteInput />}
            filterToQuery={(searchText: string) => ({
                name: searchText,
            })}
            optionValue={'@id'}
            optionText={'name'}
            fullWidth
            variant={'standard'}
        />
    );
};
export default AutoCompleteInputEvent;
