import { Avatar, TicketingEventUser } from '../types';
import extractIdFromURI from '../id';

const alertReminderConfiguration = async (
    event: Avatar,
    token: string,
    alertInHours: number,
    message: string,
    api: string,
    afterAlertRepeat?: number,
    onSuccess?: (response: TicketingEventUser) => void,
    onFailure?: (error: any) => void
): Promise<any> => {
    const urlService = `${process.env.REACT_APP_CONFIG_REMINDER_EVENT_ALERT_API}`;
    const url = `${process.env.REACT_APP_API_ENTRYPOINT}/proxy_url`;

    const bodyData = {
        url: urlService,
        body: {
            event_id: extractIdFromURI(event.id as string),
            alertInHours,
            message,
            token,
            api,
        },
    };

    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/ld+json',
            'content-type': 'application/json',
        },
        body: JSON.stringify(bodyData),
    }).then((response) => {
        return response.status === 200;
    });
};

export { alertReminderConfiguration };
