import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { TextInput, useDataProvider, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from 'react-final-form';
import GlobalForm from '../../../forms';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import Dialog from '@material-ui/core/Dialog';
import { Avatar, Node } from '../../../../utils/types';
import { findNodeByNodeType, getPathProcess } from '../../../../utils/getListOfNodesFromRunnerTempates';
import { END_NODE_TYPE } from '../../../../utils/CONST';
import { AVATAR_TRACEABILITY, TRACEABILITY_TRACKING } from '../../../../providers/resources';
import { getResourceURIFROMAnotherURI } from '../../../../utils/id';

interface CloseEventProps {
    event: Avatar;
    open: boolean;
    handleClose?: () => void;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const CloseEvent = (props: CloseEventProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const [update, { loading: loadingChangeAvatarTraceability }] = useUpdate(AVATAR_TRACEABILITY);

    const [_open, setOpen] = useState<boolean>(props.open);
    const [loading, setLoading] = useState<boolean>(false);

    const _handleClose = () => {
        setOpen(false);
        props.handleClose && props.handleClose();
    };

    const handleSubmit = (data: { comment: string }) => {
        setLoading(true);
        const runnerNode = props.event.currentReferencing?.standardReferencing?.runner?.node;
        const nodes = getPathProcess(runnerNode);
        const nodeToChange = findNodeByNodeType(nodes, END_NODE_TYPE);

        const componentForms = nodeToChange ? (nodeToChange as Node).configuration?.customFields?.componentForms : [];
        const firstFormName: string | null =
            componentForms && componentForms?.length > 0 ? componentForms[0].data.name : null;

        if (nodeToChange && props.event.currentReferencing?.standardReferencing?.id) {
            changeNode(
                props.event,
                'app.incidents.information.incidentClosed',
                'app.incidents.notification.incidentNotClosed',
                nodeToChange,
                firstFormName && data.comment
                    ? {
                          customFields: {
                              forms: {
                                  [firstFormName]: {
                                      comment: data.comment,
                                  },
                              },
                          },
                      }
                    : {},
                () => {
                    setLoading(false);
                    props.handleClose && props.handleClose();
                },
                () => {
                    setLoading(false);
                    props.handleClose && props.handleClose();
                }
            );
        }
    };

    const changeNode = (
        record: Avatar,
        successMessage: string,
        failureMessage: string,
        nodeToChange: Node,
        extraData: any,
        callbackSuccess: () => void,
        callbackFailure: () => void
    ) => {
        update(
            AVATAR_TRACEABILITY,
            `${getResourceURIFROMAnotherURI(
                record['@id'],
                // @ts-ignore
                record.currentReferencing?.standardReferencing?.id,
                TRACEABILITY_TRACKING
            )}/changenode`,
            {
                next: true,
                node: {
                    // @ts-ignore
                    id: nodeToChange.id,
                    extraData,
                },
            },
            record,
            {
                onSuccess: () => {
                    notify(successMessage, 'success');
                    callbackSuccess();
                    refresh();
                },
                onFailure: (error: any) => {
                    callbackFailure();
                    console.error(
                        'ERRROR changing node standard referencing id ',
                        record.currentReferencing?.standardReferencing?.id,
                        'error : ',
                        error
                    );
                    notify(failureMessage, 'error');
                },
            }
        );
    };

    return (
        <Dialog open={_open} onClose={_handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <Form
                    onSubmit={handleSubmit}
                    render={({ handleSubmit, pristine, valid }) => {
                        return (
                            <Grid container>
                                <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                    <GlobalForm
                                        titleTranslateOption={{ name: props.event.name }}
                                        title={'app.incidents.close.title'}
                                        titleIcon={<BorderColorIcon color={'secondary'} />}
                                    >
                                        <Grid item xs>
                                            <TextInput
                                                helperText={translate('app.incidents.close.commentSubtitle')}
                                                autoFocus
                                                variant={'standard'}
                                                key={'comment'}
                                                fullWidth
                                                multiline
                                                label={translate('app.incidents.close.comment')}
                                                source={'comment'}
                                            />

                                            <ButtonsBlockForm
                                                disableValidButton={loading || !valid}
                                                onCancel={_handleClose}
                                                validButtonIsSubmitType={true}
                                            />
                                        </Grid>
                                    </GlobalForm>
                                </form>
                            </Grid>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default CloseEvent;
