import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TypographyClassKey } from '@material-ui/core/Typography/Typography';

const CustomDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    marginBottom: 10,
});

const CustomTypography = styled(Typography)({
    fontSize: 13,
    fontWeight: 700,
});

interface Props {
    icon?: ReactElement;
    label: string;
    children: any;
    withoutMarginTop?: boolean;
    iconTooltipMessage?: string;
    variantTypography?: TypographyClassKey;
}

const HeaderBlock = ({
    icon,
    label,
    children,
    withoutMarginTop = false,
    iconTooltipMessage,
    variantTypography,
}: Props) => {
    return (
        <Grid container direction={'column'}>
            <Grid item>
                <CustomDiv style={{ ...(withoutMarginTop ? {} : { marginTop: 30 }) }}>
                    {/*// @ts-ignore*/}
                    <CustomTypography variant={variantTypography ? (variantTypography as TypographyClassKey) : 'body2'}>
                        {label}
                    </CustomTypography>
                    {/*{icon}*/}
                    {iconTooltipMessage && icon && (
                        <Tooltip placement={'top'} title={iconTooltipMessage}>
                            {icon}
                        </Tooltip>
                    )}
                    {icon && !iconTooltipMessage && icon}
                </CustomDiv>
            </Grid>
            <Grid item>{children}</Grid>
        </Grid>
    );
};

export default HeaderBlock;
