// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { ResponseForwarderToMetabaseType } from '../../../../utils/ticketingBridgeMetabase/viewsDynamic';
import GetMetabaseUrlOfTheData from '../../../../commons/get-metabase-url-of-the-data/get-metabase-url-of-the-data';
import { useDispatch } from 'react-redux';
import { Identifier } from 'react-admin';
import { registerMetabaseData } from '../../../../redux/actions/incidents';

interface Props {
    requestBody: {
        id: number;
        dashboard_type: string;
        creation_stricty_date_before?: string | null;
        creation_stricty_date_after?: string | null;
        opened_incidents?: boolean | null;
        place_id?: string | null;
        metadata_avatar_type_id?: string | null;
        event_id?: string | null;
    };
    eventId: Identifier;
    question: number;
    children: any;
}

const RecoverMetabaseData = ({ requestBody, eventId, question, children }: Props) => {
    const dispatch = useDispatch();
    const [metabaseData, setMetabaseData] = useState<ResponseForwarderToMetabaseType | undefined>(undefined);

    useEffect(() => {
        if (metabaseData) {
            dispatch(registerMetabaseData.fn(eventId, question, metabaseData));
        }
    }, [metabaseData]);

    useEffect(() => {
        setMetabaseData(undefined);
    }, [eventId]);

    return (
        <GetMetabaseUrlOfTheData version={0} requestBody={requestBody} passLoadingToChildren={true}>
            {({ data, loading }: { data: ResponseForwarderToMetabaseType; loading: boolean }) => {
                if (!metabaseData && !loading) {
                    setMetabaseData(data);
                }

                return <>{children({ loading: loading, data })}</>;
            }}
        </GetMetabaseUrlOfTheData>
    );
};

export default RecoverMetabaseData;
