import Grid from '@material-ui/core/Grid';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import makeStyles from '@material-ui/styles/makeStyles';
import { string } from 'prop-types';
import React from 'react';
import { GetOneResult, TextInput, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { selectCategory } from '../../../../redux/actions/categories';
import { GroupMetadataAvatarType } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';

interface EditFormProps {
    open: boolean;
    handleClose: () => void;
    selected: GroupMetadataAvatarType;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const NAME_FIELD = 'name';

const EditForm = ({ open, handleClose, selected }: EditFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const [update, { loading }] = useMutation({
        resource: GROUP_METADATA_AVATAR_TYPES,
        type: 'update',
        payload: { data: {}, id: string },
    });

    const refreshSelectedItem = (selectedItemUpdated: GroupMetadataAvatarType) => {
        dispatch(selectCategory.fn(selectedItemUpdated));
    };
    const handleSubmit = (formValues: { [NAME_FIELD]: string }) => {
        update(
            {
                payload: {
                    data: {
                        name: formValues[NAME_FIELD],
                        customFields: !selected.customFields ? {} : selected.customFields,
                    },
                    id: selected?.id,
                },
            },
            {
                onSuccess: ({ data }: GetOneResult) => {
                    if (data) {
                        refreshSelectedItem(data as GroupMetadataAvatarType);
                    }
                    notify('app.category.updated.notification', 'success');
                    handleClose();
                    refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                },
            }
        );
    };

    return (
        <DialogTemplate
            title={translate('app.category.edit.title')}
            open={open}
            onClose={handleClose}
            icon={<BorderColorIcon />}
            maxWidth={'md'}
        >
            <Form
                initialValues={{ ...selected }}
                onSubmit={handleSubmit}
                render={({ handleSubmit, pristine }) => {
                    return (
                        <Grid container>
                            <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                <Grid item xs>
                                    <TextInput
                                        autoFocus
                                        variant={'standard'}
                                        required
                                        key={NAME_FIELD}
                                        fullWidth
                                        type={'text'}
                                        label={translate('app.category.edit.input.name')}
                                        source={NAME_FIELD}
                                    />
                                </Grid>

                                <DialogTemplateButtons
                                    outside
                                    withCancel
                                    onCancelClick={handleClose}
                                    withCreate
                                    isValidForm={!(loading || pristine)}
                                    isSubmitting={loading}
                                    cancelButtonLabel={translate('app.cancel')}
                                    createButtonLabel={translate('app.valid')}
                                />
                            </form>
                        </Grid>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default EditForm;
