import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { Fragment } from 'react';
import { Datagrid, FunctionField, Identifier, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import FunctionIconField from '../../../commons/fields/function/functionIconField';
import FunctionMediaField from '../../../commons/fields/function/functionMediaField';
import { selectIncidentType } from '../../../redux/actions/incidentTypes';
import { CustomReducerState } from '../../../redux/reducers';
import { getIncidentTypeSelected } from '../../../redux/reducers/incidentTypes';
import { FieldHeaderListStyles, ImageStyles } from '../../../theme';
import { TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD } from '../../../utils/CONST';
import { CUSTOM_FIELDS_ICON_SVG_COLLECTION } from '../../../utils/metadataAvatarType';
import { hasPermissions } from '../../../utils/permissions';
import {
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT,
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE,
} from '../../../utils/properties/metadataAvatarTypeProperties';
import { ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../../utils/ROLES';
import { rowStyle } from '../../../utils/rowListStyles';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { MetadataAvatarType } from '../../../utils/types';
import ForceMetadataAvatarTypePropertiesField from '../field/ForceMetadataAvatarTypePropertiesField';
import IncidentBulkActionButtons from '../../incidents/IncidentBulkActionButtons';

const useStyles = makeStyles({
    // @ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
        // ...FieldCapitalizeListStyles,
    },
    image: {
        ...ImageStyles,
    },
});

const IncidentTypesDatagrid = () => {
    const translate = useTranslate();
    const dispatch = useDispatch();

    const classes = useStyles();
    const selected: MetadataAvatarType | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getIncidentTypeSelected(state)
    );

    const { loaded: loadedPermissions, permissions } = usePermissions();

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectIncidentType.fn(record as MetadataAvatarType));
        // @ts-ignore
        return Promise.resolve(undefined);
    };

    return (
        <Datagrid
            rowClick={onClick}
            rowStyle={(record, index) => rowStyle(record, index, selected)}
            hasBulkActions={true}
        >
            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.incidentTypes.name'}
                render={(record: Record | undefined) => {
                    if (
                        record &&
                        'customFields' in record &&
                        CUSTOM_FIELDS_ICON_SVG_COLLECTION in record.customFields &&
                        record.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                    ) {
                        return (
                            <FunctionIconField
                                headerClassName={classes.fieldHeader}
                                findImageInAnotherResource={false}
                                label={translate('app.incidentTypes.name')}
                                record={record}
                            />
                        );
                    } else {
                        return (
                            <Fragment>
                                {hasPermissions(
                                    [ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD],
                                    loadedPermissions,
                                    permissions
                                ) ? (
                                    <FunctionMediaField
                                        headerClassName={classes.fieldHeader}
                                        findImageInAnotherResource={false}
                                        label={translate('app.incidentTypes.name')}
                                        record={record}
                                    />
                                ) : (
                                    <FunctionField
                                        headerClassName={classes.fieldHeader}
                                        label={'app.incidentTypes.name'}
                                        render={() => {
                                            return (
                                                <img
                                                    src={'/tmp-image.png'}
                                                    className={classes.image}
                                                    alt=''
                                                    style={{ margin: '0 0 -5px 0', height: 25, width: 25 }}
                                                />
                                            );
                                        }}
                                    />
                                )}
                            </Fragment>
                        );
                    }
                }}
            />

            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.incidentTypes.repeatable'}
                render={(record: Record | undefined) => {
                    const incidentType = record ? (record as MetadataAvatarType) : null;
                    return incidentType ? (
                        <Typography>
                            {incidentType.customFields[TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD] ===
                            '*'
                                ? capitalizeFirstLetter(translate('app.yes'))
                                : capitalizeFirstLetter(translate('app.no'))}
                        </Typography>
                    ) : (
                        <Typography>{'...'}</Typography>
                    );
                }}
            />
            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.incidentTypes.forceText'}
                render={(record: Record | undefined) => {
                    const incidentType = record ? (record as MetadataAvatarType) : null;
                    return incidentType ? (
                        <ForceMetadataAvatarTypePropertiesField
                            metadataAvatarType={incidentType}
                            propertyName={EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT}
                        />
                    ) : (
                        <Typography>{'...'}</Typography>
                    );
                }}
            />

            <FunctionField
                headerClassName={classes.fieldHeader}
                label={'app.incidentTypes.forceImage'}
                render={(record: Record | undefined) => {
                    const incidentType = record ? (record as MetadataAvatarType) : null;
                    return incidentType ? (
                        <ForceMetadataAvatarTypePropertiesField
                            metadataAvatarType={incidentType}
                            propertyName={EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE}
                        />
                    ) : (
                        <Typography>{'...'}</Typography>
                    );
                }}
            />
        </Datagrid>
    );
};

export default IncidentTypesDatagrid;
