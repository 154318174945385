import Grid from '@material-ui/core/Grid';
import React from 'react';
import { BooleanInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

// interface Props {}

const useStyles = makeStyles(() => ({
    gridPaddingTop: {
        marginLeft: 30,
        paddingTop: 10,
    },
    gridDependent: {
        // marginLeft: 46,
    },
}));
const TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD = 'takeIntoAccountAutomaticallyUserUnderContract';
const HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD = 'hasTakeIntoAccountSubProcess';

const BooleanInputDependent = () => {
    const translate = useTranslate();
    const { input: hasTakeInputAccountSubProcessInput } = useField(HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD);
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item>
                <Grid item className={classes.gridPaddingTop}>
                    <BooleanInput
                        label={
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                {translate('app.navigation.process.askConfirmationToServiceProvider')}
                            </Typography>
                        }
                        source={HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD}
                    />
                </Grid>
                <Grid item className={classes.gridPaddingTop}>
                    <BooleanInput
                        disabled={!(hasTakeInputAccountSubProcessInput.value == true)}
                        label={
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                {translate('app.navigation.process.takeIntoAccountAutomatically')}
                            </Typography>
                        }
                        source={TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BooleanInputDependent;
