import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import React, { FC, useEffect, useState } from 'react';
import { CreateResult, useDataProvider, useNotify, usePermissions, useTranslate } from 'react-admin';
import AvatarForm from '../../../../../commons/avatar/form/updateAvatar';
import UpdateAvatarEnabledForm from '../../../../../commons/avatar/updateEnabledForm/updateAvatarEnabled';
import AvatarPropertyCreateOrUpdateForm from '../../../../../commons/avatarProperties/avatarPropertyFixedKey/createOrUpdate';
import HeaderBlock from '../../../../../commons/HeaderBlock/HeaderBlock';
import SwitchIconChildrenContainer from '../../../../../commons/SwitchIconChildrenContainer/SwitchIconChildrenContainer';
import {
    ITEM_CONTACT_DEFINITION,
    ITEM_NAME_CONTACT_FIELD,
    ITEM_PHONE_CONTACT_FIELD,
    TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD,
} from '../../../../../utils/CONST';
import { Avatar, MetadataAvatarTypeProperty } from '../../../../../utils/types';
import ExtraInformationHeaderBlock from './ExtraInformation';
import {
    ROLE_CREATE_AVATAR_PROPERTY,
    ROLE_READ_AVATAR_PROPERTY,
    ROLE_UPDATE_AVATAR,
    ROLE_UPDATE_AVATAR_PROPERTY,
} from '../../../../../utils/ROLES';
import { hasPermissions } from '../../../../../utils/permissions';
import { METADATA_AVATAR_TYPE_PROPERTIES } from '../../../../../providers/resources';

interface Props {
    item: Avatar;
}

const InformationContact: FC<Props> = ({ item }: Props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { loaded, permissions } = usePermissions();
    const [showFileSystemMapperEnableField, setShowFileSystemMapperEnableField] = useState(false);

    const hasAvatarEditPermissions =
        loaded && permissions && Array.isArray(permissions) && permissions.includes(ROLE_UPDATE_AVATAR);

    const hasAvatarPropertyCreateEditAndReadPermissions = hasPermissions(
        [ROLE_UPDATE_AVATAR_PROPERTY, ROLE_CREATE_AVATAR_PROPERTY, ROLE_READ_AVATAR_PROPERTY],
        loaded,
        permissions
    );

    const getMetadataAvatarTypeProperties = (metadataAvatarTypeId: string, name: string) => {
        dataProvider
            .getList(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {
                        metadataAvatarType: metadataAvatarTypeId,
                        name,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (
                            response &&
                            'data' in response &&
                            response.data &&
                            Array.isArray(response.data) &&
                            response.data.length > 0
                        ) {
                            if ((response.data[0] as MetadataAvatarTypeProperty).values.length === 0) {
                                setShowFileSystemMapperEnableField(false);
                                return;
                            }
                            if (
                                (response.data[0] as MetadataAvatarTypeProperty).values[0] === 'true' ||
                                (response.data[0] as MetadataAvatarTypeProperty).values[0] === 'false'
                            ) {
                                setShowFileSystemMapperEnableField(
                                    JSON.parse((response.data[0] as MetadataAvatarTypeProperty).values[0])
                                );
                            } else {
                                setShowFileSystemMapperEnableField(
                                    (response.data[0] as MetadataAvatarTypeProperty).values[0]
                                );
                            }
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getPropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => console.error(error));
    };

    useEffect(() => {
        const metadataAvatarTypeId =
            typeof item.metadataAvatarType === 'string' // item.metadataAvatarType is string before getting metadata avatar type object
                ? item.metadataAvatarType
                : (item.metadataAvatarType.id as string);
        metadataAvatarTypeId &&
            getMetadataAvatarTypeProperties(metadataAvatarTypeId, TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD);
    }, []);

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <SwitchIconChildrenContainer
                    icon={<MailOutlineIcon />}
                    switchC={<Switch disabled />}
                    rootStyles={{ border: '1px solid #E4E5EB', padding: 5, marginBottom: 20 }}
                >
                    <Typography variant={'body2'}>{translate('app.navigation.configuration.enableSending')}</Typography>
                </SwitchIconChildrenContainer>
            </Grid>

            <Grid item>
                <AvatarForm
                    avatar={item}
                    itemKey={'name'}
                    label={translate('app.navigation.configuration.itemName')}
                    disabled={!hasAvatarEditPermissions}
                />

                {/*<HeaderBlock*/}
                {/*    icon={<InfoIcon />}*/}
                {/*    label={translate('app.navigation.configuration.itemInformation.privateLabel')}*/}
                {/*    iconTooltipMessage={translate('app.navigation.configuration.itemInformation.privateInformation')}*/}
                {/*>*/}
                <AvatarForm
                    type={'radio-input'}
                    choices={[
                        { id: true, name: translate('app.navigation.configuration.itemInformation.privateArea') },
                        { id: false, name: translate('app.navigation.configuration.itemInformation.publicArea') },
                    ]}
                    label={translate('app.navigation.configuration.itemInformation.privacyArea')}
                    avatar={item}
                    itemKey={'private'}
                    searchItem={true}
                    disabled={!hasAvatarEditPermissions}
                />

                <UpdateAvatarEnabledForm
                    choices={[
                        { id: false, name: translate('app.navigation.configuration.itemInformation.inMaintenance') },
                        { id: true, name: translate('app.navigation.configuration.itemInformation.outOfMaintenance') },
                    ]}
                    label={translate('app.navigation.configuration.itemInformation.maintenanceStatus')}
                    avatar={item}
                    searchItem={true}
                    disabled={!hasAvatarEditPermissions}
                />

                {showFileSystemMapperEnableField && (
                    <AvatarPropertyCreateOrUpdateForm
                        type={'radio-input'}
                        choices={[
                            {
                                id: 'true',
                                name: translate(
                                    'app.navigation.configuration.itemInformation.fileSystemMapper.enabled'
                                ),
                            },
                            {
                                id: 'false',
                                name: translate(
                                    'app.navigation.configuration.itemInformation.fileSystemMapper.disabled'
                                ),
                            },
                        ]}
                        disabled={!hasAvatarPropertyCreateEditAndReadPermissions}
                        icon={<MailOutlineIcon />}
                        avatar={item}
                        itemKey={TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD}
                        source={'forceFileSystemMapper'}
                        itemValue=''
                        fieldType={'string'}
                        definition={null}
                        searchItem={true}
                        label={translate('app.navigation.configuration.itemInformation.fileSystemMapper.label')}
                    />
                )}
                {/*</HeaderBlock>*/}
            </Grid>

            <Grid item>
                <HeaderBlock label={translate('app.navigation.configuration.itemInformation.contact')}>
                    <AvatarPropertyCreateOrUpdateForm
                        disabled={!hasAvatarPropertyCreateEditAndReadPermissions}
                        icon={<MailOutlineIcon />}
                        avatar={item}
                        itemKey={ITEM_PHONE_CONTACT_FIELD}
                        itemValue=''
                        fieldType={'string'}
                        definition={ITEM_CONTACT_DEFINITION}
                        searchItem={true}
                        label={translate('app.navigation.configuration.itemInformation.contactPhone')}
                    />

                    <AvatarPropertyCreateOrUpdateForm
                        disabled={!hasAvatarPropertyCreateEditAndReadPermissions}
                        icon={<MailOutlineIcon />}
                        avatar={item}
                        itemKey={ITEM_NAME_CONTACT_FIELD}
                        itemValue=''
                        fieldType={'string'}
                        definition={ITEM_CONTACT_DEFINITION}
                        searchItem={true}
                        label={translate('app.navigation.configuration.itemInformation.contactName')}
                    />
                </HeaderBlock>
            </Grid>

            <Grid item>
                <ExtraInformationHeaderBlock item={item} disabled={!hasAvatarPropertyCreateEditAndReadPermissions} />
            </Grid>
        </Grid>
    );
};

export default InformationContact;
