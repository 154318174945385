import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SortIcon from '@material-ui/icons/Sort';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import {
    Datagrid,
    FunctionField,
    List as RAList,
    Record,
    useNotify,
    usePermissions,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import DeleteFormDialog from '../../../../../../commons/dialog/delete';
import MediaField from '../../../../../../commons/fields/media';
import { navigation } from '../../../../../../pages/paths';
import {
    GROUP_METADATA_AVATAR_TYPES,
    TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
} from '../../../../../../providers/resources';
import { CustomReducerState } from '../../../../../../redux/reducers';
import { CustomIconButton, CustomPointerAvatar, FieldHeaderListStyles } from '../../../../../../theme';
import { TAG_TICKETING_LOGO } from '../../../../../../utils/CONST';
import extractIdFromURI, { getUriFromItems } from '../../../../../../utils/id';
import { getNameWithoutPrefixFromJson } from '../../../../../../utils/resources/GroupMetadataAvatarType';
import { capitalizeFirstLetter } from '../../../../../../utils/strings';
import {
    Avatar,
    GroupMetadataAvatarType,
    MetadataAvatarType,
    SubcategoryEventTypeDependencies,
} from '../../../../../../utils/types';
import Actions from './actions';
import AddIncidentTypeDialog from './form/addEventType';
import CreateSubcategoryEventTypeDependenciesDialog from './form/createSubcategoryEventTypeDependencies';
import './index.css';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../../../utils/validations/parseValidations';
import ReferenceField from '../../../../../../commons/ra/field/ReferenceField';
import {
    ROLE_DELETE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
    ROLE_READ_OBJECT_TYPE,
    ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
} from '../../../../../../utils/ROLES';
import { hasPermissions } from '../../../../../../utils/permissions';
import EventTypesField from './EventTypesField';
import SingleFieldList from './SingleFieldList';

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
});

const CustomChip = styled(Chip)({
    margin: 2,
});

const CustomTypography = styled(Typography)({
    fontSize: 14,
});

const CustomDivFlex = styled('div')({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
});

interface Props {
    avatar: Avatar;
}

const useStyles = makeStyles(() => ({
    field: {
        ...FieldHeaderListStyles,
    },
    gridItemAlignCenter: {
        alignSelf: 'center',
    },
    image: {
        marginRight: 5,
        height: 23,
        width: 23,
    },
}));

const List = ({ avatar }: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const dndState: boolean = useSelector((state: CustomReducerState) => state.ticketing.dnd.active);

    const [update] = useUpdate(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES);

    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<SubcategoryEventTypeDependencies | undefined>();

    const listProps = {
        resource: TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: navigation,
        sort: { field: 'updatedAtTimestamp', order: 'DESC' },
        perPage: 25,
        filter: {
            serialization_groups: ['read_meta', 'ticketing:metaadataavatartype-dependencies:read-subcategory'],
            // place: avatar.id,
            'place.root':
                avatar && typeof avatar?.root === 'string' ? avatar.root : avatar && (avatar.root as Avatar).id,
            'place.lvl__lte__': avatar.lvl,
            'place.lft__lte__': avatar.lft,
            'place.rgt__gte__': avatar.rgt,
            group_by_sub_category: true,
        },
    };

    const dismissCreateFormDialog = () => {
        setShowCreateForm(false);
        refresh();
    };
    const cancelCreateFormDialog = () => {
        setShowCreateForm(false);
    };

    const handleOnClickCreate = () => {
        setShowCreateForm(true);
    };

    const handleOnClickDeleteSubCategoryEventTypeDependencies = (item: SubcategoryEventTypeDependencies) => () => {
        setShowDeleteForm(true);
        setItemToDelete(item);
    };

    const dismissDeleteFormDialog = (itemDeleted: boolean) => {
        setShowDeleteForm(false);
        setItemToDelete(undefined);
        if (!itemDeleted) return;
        refresh();
    };

    const onDeleteEventType = (record: SubcategoryEventTypeDependencies, eventType: MetadataAvatarType) => () => {
        const dataToSend: { eventTypes: string[] } = {
            eventTypes: getUriFromItems(record.eventTypes.filter((event) => event.id !== eventType.id)),
        };

        update(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, record.id, dataToSend, record, {
            onSuccess: () => {
                notify('app.navigation.configuration.incident.eventType.removed.notification', 'info');
                refresh();
            },
            onFailure: (...args: unknown[]) => {
                const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                if (violations && violations.length > 0) {
                    const violation = violations[0];
                    notify(`${getCodeFromViolationMessage(violation?.message)}`, 'error');
                } else {
                    notify('app.navigation.configuration.incident.eventType.removed.error', 'warning');
                }
                console.error(
                    `ERRROR removing, event types ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`,
                    ...args
                );
            },
        });
    };

    const renderActions = () => {
        return hasPermissions(
            [ROLE_UPDATE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, ROLE_READ_OBJECT_TYPE],
            loadedPermissions,
            permissions
        ) ? (
            <Actions handleOnClickCreate={handleOnClickCreate} />
        ) : undefined;
    };

    return (
        <Grid container direction='column' className='subcategory'>
            <RAList
                {...listProps}
                exporter={false}
                bulkActionButtons={false}
                empty={renderActions()}
                actions={renderActions()}
            >
                <Datagrid>
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.incident.subCategory.title')}
                        render={(record: Record | undefined) => {
                            const subCategoryEventTypeDependencies = record
                                ? (record as SubcategoryEventTypeDependencies)
                                : null;
                            const subCategory: MetadataAvatarType | null = subCategoryEventTypeDependencies
                                ? (subCategoryEventTypeDependencies.subCategory as MetadataAvatarType)
                                : null;
                            const subCategoryImage =
                                subCategory && typeof subCategory === 'object' && 'image' in subCategory
                                    ? subCategory.image
                                    : null;
                            const subCategoryId = extractIdFromURI(subCategory?.id as string);

                            return (
                                <>
                                    {subCategory && subCategoryImage && subCategoryId ? (
                                        <CustomDiv>
                                            <MediaField
                                                key={subCategory.id as string}
                                                imageId={subCategoryImage}
                                                resourceId={subCategoryId}
                                                tags={[TAG_TICKETING_LOGO]}
                                            />
                                            <CustomTypography>
                                                {capitalizeFirstLetter(getNameWithoutPrefixFromJson(subCategory))}
                                            </CustomTypography>
                                        </CustomDiv>
                                    ) : (
                                        <CustomDiv>
                                            <img src={'/tmp-image.png'} className={classes.image} alt='' />
                                            <CustomTypography>
                                                {subCategory
                                                    ? capitalizeFirstLetter(getNameWithoutPrefixFromJson(subCategory))
                                                    : ''}
                                            </CustomTypography>
                                        </CustomDiv>
                                    )}
                                </>
                            );
                        }}
                    />

                    <ReferenceField
                        headerClassName={classes.field}
                        reference={GROUP_METADATA_AVATAR_TYPES}
                        source='subCategory.groupMetadataAvatarType'
                        // linkType={false}
                        link={false}
                        label={translate('app.navigation.configuration.incident.category')}
                    >
                        <FunctionField
                            render={(record: Record | undefined) =>
                                record
                                    ? capitalizeFirstLetter(
                                          getNameWithoutPrefixFromJson(record as GroupMetadataAvatarType)
                                      )
                                    : ''
                            }
                        />
                    </ReferenceField>

                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.incident.assigned')}
                        render={(record: Record | undefined) => {
                            return (
                                record && (
                                    <EventTypesField
                                        subcategoryEventTypeDependencies={record as SubcategoryEventTypeDependencies}
                                        place={avatar}
                                    />
                                )
                            );
                        }}
                    />

                    {hasPermissions(
                        [ROLE_DELETE_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES],
                        loadedPermissions,
                        permissions
                    ) && (
                        <FunctionField
                            headerClassName={classes.field}
                            label={translate('app.navigation.configuration.incident.action.remove.label')}
                            render={(record: Record | undefined) => {
                                return record ? (
                                    <CustomIconButton
                                        onClick={handleOnClickDeleteSubCategoryEventTypeDependencies(
                                            record as SubcategoryEventTypeDependencies
                                        )}
                                        disabled={dndState}
                                    >
                                        <DeleteOutlineIcon />
                                    </CustomIconButton>
                                ) : (
                                    ''
                                );
                            }}
                        />
                    )}
                </Datagrid>
            </RAList>
            {showCreateForm && (
                <CreateSubcategoryEventTypeDependenciesDialog
                    avatar={avatar}
                    handleClose={dismissCreateFormDialog}
                    handleCancel={cancelCreateFormDialog}
                    open={true}
                />
            )}
            {/*{showUpdateEventTypeForm && itemToUpdate && (*/}
            {/*    <AddIncidentTypeDialog*/}
            {/*        place={pla}*/}
            {/*        item={itemToUpdate}*/}
            {/*        handleClose={dismissUpdateEventTypeFormDialog}*/}
            {/*        open={true}*/}
            {/*        handleCancel={cancelUpdateEventTypeFormDialog}*/}
            {/*    />*/}
            {/*)}*/}
            {showDeleteForm && itemToDelete && (
                <DeleteFormDialog
                    place={avatar}
                    title={translate('app.navigation.configuration.incident.action.remove.title')}
                    subtitle={translate('app.navigation.configuration.incident.action.remove.subtitle', {
                        name: itemToDelete?.subCategory?.name,
                    })}
                    onSuccessMessage={'app.navigation.configuration.incident.action.remove.notification'}
                    onFailureMessage={'app.navigation.configuration.incident.action.remove.error'}
                    item={itemToDelete}
                    handleClose={dismissDeleteFormDialog}
                    open={true}
                    resource={TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}
                />
            )}
        </Grid>
    );
};

export default List;
