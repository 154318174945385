import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import {
    CreateResult,
    Record,
    TextInput,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
    RadioButtonGroupInput,
} from 'react-admin';
import { Form } from 'react-final-form';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_EVENT, GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../../utils/CONST';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';

interface CreateFormProps {
    open: boolean;
    fromExternalInput?: boolean;
    initialName?: string;
    handleClose: () => void;
    handleCreateOnSuccess?: (data: Record) => void;
}

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: '100%',
    },
}));

const NAME_FIELD = 'name';

const CreateForm = ({ open, handleClose, fromExternalInput, handleCreateOnSuccess, initialName }: CreateFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const [createGroupMetadataAvatarType, { loading }] = useMutation({
        resource: GROUP_METADATA_AVATAR_TYPES,
        type: 'create',
        payload: { data: {} },
    });

    const handleSubmit = (formValues: { [NAME_FIELD]: string }) => {
        createGroupMetadataAvatarType(
            {
                payload: {
                    data: {
                        name: formValues[NAME_FIELD],
                        alias: GROUP_METADATA_AVATAR_TYPE_EVENT,
                        customFields: {},
                    },
                },
            },
            {
                onSuccess: (response: CreateResult) => {
                    const data = response && 'data' in response ? response.data : undefined;
                    data && handleCreateOnSuccess && handleCreateOnSuccess(data);
                    notify('app.category.created.notification', 'success');

                    if (!fromExternalInput) {
                        handleClose();
                        refresh();
                    }
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                },
            }
        );
    };

    return (
        <DialogTemplate
            title={translate('app.category.create.title')}
            open={open}
            onClose={handleClose}
            icon={<AddIcon />}
            maxWidth={'md'}
        >
            <Form
                onSubmit={handleSubmit}
                initialValues={{
                    [NAME_FIELD]: fromExternalInput && initialName ? initialName : '',
                }}
                render={({ handleSubmit, submitting, pristine }) => {
                    return (
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <Grid item xs>
                                <TextInput
                                    autoFocus
                                    variant={'standard'}
                                    required
                                    key={NAME_FIELD}
                                    fullWidth
                                    type={'text'}
                                    label={translate('app.category.create.input.name')}
                                    source={NAME_FIELD}
                                />
                            </Grid>

                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={handleClose}
                                withCreate
                                isValidForm={initialName ? true : !(loading || submitting || pristine)}
                                isSubmitting={loading}
                                cancelButtonLabel={translate('app.cancel')}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};
export default CreateForm;
