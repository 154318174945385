import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'easyqrcodejs';
import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QrContainer from './QrContainer/qrContainer';
import qrCodeLogo360 from '../../theme/images/qrCodeLogo360.png';

const useStyles = makeStyles((theme) => ({
    divGroupButtonButton: {
        padding: 8,
        textAlign: 'center',
    },
    groupButtonButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    selectedButton: {
        backgroundColor: '#DBDBDB',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            backgroundColor: '#DBDBDB',
        },
    },
}));

const optionsMIME = [
    { label: 'jpeg', MIME: 'image/jpeg', format: 'jpeg' },
    { label: 'svg', MIME: 'image/svg', format: 'svg' },
    { label: 'png', MIME: 'image/png', format: 'png' },
];

const optionsSize = [
    { label: '256px', size: 256 },
    { label: '300px', size: 300 },
    { label: '512px', size: 512 },
];

const getTextSize = (option: any) => {
    switch (option.size) {
        case 256: {
            return 16;
        }
        case 300: {
            return 20;
        }
        case 512: {
            return 20;
        }
        default: {
            return 16;
        }
    }
};

const getImageStyle = (option: any) => {
    switch (option.size) {
        case 256:
            return {
                width: 256,
                height: 22,
            };
        case 300:
            return {
                width: 300,
                height: 25,
            };
        case 512:
            return {
                width: 350,
                height: 28,
            };
        default:
            return {
                width: 256,
                height: 22,
            };
    }
};

interface Props {
    url: string;
    name: string;
    showSizeOptionButtons: boolean;
    showFormatOptionButtons: boolean;
    showText: boolean;
    showCopyURL?: boolean;
}

const QrCode = (props: Props) => {
    const qrcode: any = React.createRef();
    const [selectedMIME, setSelectedMIME] = useState(optionsMIME[0]);
    const [selectedSize, setSelectedSize] = useState(optionsSize[0]);
    const [qrCode, setQrCode] = useState<any>(null);
    const notify = useNotify();
    const translate = useTranslate();
    const classes: any = useStyles();

    const renderQrCode = () => {
        const { url } = props;
        const options: any = {
            text: url,
            width: selectedSize.size,
            height: selectedSize.size,
            level: 'L',
        };
        const qr = new QRCode(qrcode.current, options);
        setQrCode(qr);
    };

    useEffect(() => {
        // qrCode && qrCode.resize && qrCode.resize(selectedSize.size, selectedSize.size);
        qrCode && qrCode.clear();
        renderQrCode();
    }, [selectedSize]);

    const downloadQrCode = () => {
        const { MIME: type, format } = selectedMIME;
        // @ts-ignore
        html2canvas(document.querySelector('.outside')).then((canvas: any) => {
            const a = document.createElement('a');
            a.href = canvas.toDataURL(type).replace(type, 'image/octet-stream');
            a.download = `${props.name}.${format}`;
            a.click();
        });
    };

    const onCopy = () => {
        notify(translate('app.notification.copied'));
    };

    const { showFormatOptionButtons, showSizeOptionButtons, showText = false, showCopyURL } = props;
    const textSize = getTextSize(selectedSize);
    const { size } = selectedSize;
    return (
        <Grid container direction='column' justify='flex-start' alignItems='center'>
            <Grid item>
                <QrContainer
                    key={size}
                    topComponent={showText ? <img src={qrCodeLogo360} style={getImageStyle(selectedSize)} /> : null}
                    rightComponent={
                        showText ? (
                            <div>
                                <Typography variant={'body1'} style={{ fontWeight: 'bold', fontSize: textSize }}>
                                    {'SCAN ME'}
                                </Typography>
                            </div>
                        ) : null
                    }
                    bottomComponent={
                        showText ? (
                            <div>
                                <Typography variant={'body1'} style={{ fontWeight: 'bold', fontSize: textSize }}>
                                    {'TRAÇABILITÉ POUR TOUS'}
                                </Typography>
                            </div>
                        ) : null
                    }
                    leftComponent={
                        showText ? (
                            <div>
                                <Typography variant={'body1'} style={{ fontWeight: 'bold', fontSize: textSize }}>
                                    {'SCANNEZ-MOI'}
                                </Typography>
                            </div>
                        ) : null
                    }
                    centerComponent={<div className='HpQrcode' style={{ backgroundColor: 'white' }} ref={qrcode} />}
                />
            </Grid>

            {showFormatOptionButtons && (
                <Grid item className={classes.divGroupButtonButton}>
                    <Grid container alignContent={'center'}>
                        {optionsMIME.map((option) => {
                            return (
                                <Button
                                    key={option.label}
                                    color='primary'
                                    onClick={() => setSelectedMIME(option)}
                                    className={
                                        selectedMIME.label === option.label
                                            ? classes.selectedButton
                                            : classes.groupButtonButton
                                    }
                                >
                                    {option.label}
                                </Button>
                            );
                        })}
                    </Grid>
                </Grid>
            )}

            {showSizeOptionButtons && (
                <Grid item className={classes.divGroupButtonButton}>
                    <Grid container alignContent={'center'}>
                        {optionsSize.map((optionSize) => {
                            return (
                                <Button
                                    key={optionSize.label}
                                    color='primary'
                                    onClick={() => setSelectedSize(optionSize)}
                                    className={
                                        selectedSize.label === optionSize.label
                                            ? classes.selectedButton
                                            : classes.groupButtonButton
                                    }
                                >
                                    {optionSize.label}
                                </Button>
                            );
                        })}
                    </Grid>
                </Grid>
            )}

            {showCopyURL && (
                <Grid item className={classes.divGroupButtonButton}>
                    <CopyToClipboard text={props.url} onCopy={onCopy}>
                        <Button color='primary' variant='contained'>
                            {translate('app.mc.copy')}
                        </Button>
                    </CopyToClipboard>
                </Grid>
            )}

            <Grid item className={classes.divGroupButtonButton}>
                <Button onClick={() => downloadQrCode()} color='primary' variant='contained'>
                    {translate('app.download')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default QrCode;
