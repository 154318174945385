import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslate } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import DatePeriodFilter from '../filters/DatePeriodFilter';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { updateGlobalFilter } from '../../../redux/actions/dashboard';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { toShortFormatDate } from '../../../utils/date';
import Checkbox from '@material-ui/core/Checkbox';
import { ButtonFontWeightBoldStyles } from '../../../theme';

const momentFormat = 'YYYY-MM-DD';

const useStyles = makeStyles({
    button: {
        paddingLeft: 10,
        paddingRight: 10,
        ...ButtonFontWeightBoldStyles,
    },
    icon: {
        marginRight: 5,
        marginTop: -1,
    },
    menuItem: {
        fontWeight: 500,
    },
    rootGrid: {},
    centerAlignGrid: {
        alignSelf: 'center',
        paddingTop: '0px !important',
    },
});

interface MenuItemProp {
    label: string;
    value: string;
    getDateFilterConfigured?: (data: { beforeAt: string | undefined; afterAt: string | undefined }) => any;
    enabled: boolean;
}

const PreconfiguredPeriodFilterButton = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const periodConfig = useSelector((state: CustomReducerState) => state.ticketing.dashboards.globalFilters.period);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const menuItems: MenuItemProp[] = [
        {
            label: 'app.dashboards.filters.period.lastWeek',
            value: 'last-week',
            getDateFilterConfigured: (data) => {
                const afterAt = moment(new Date()).subtract(1, 'weeks');
                return {
                    afterAt: toShortFormatDate(afterAt.toString(), momentFormat),
                    beforeAt: toShortFormatDate(new Date().toString(), momentFormat),
                };
            },
            enabled: true,
        },
        {
            label: 'app.dashboards.filters.period.lastMonth',
            value: 'last-month',
            getDateFilterConfigured: (data) => {
                const afterAt = moment(new Date()).subtract(1, 'months');
                return {
                    afterAt: toShortFormatDate(afterAt.toString(), momentFormat),
                    beforeAt: toShortFormatDate(new Date().toString(), momentFormat),
                };
            },
            enabled: true,
        },
        {
            label: 'app.dashboards.filters.period.lastYear',
            value: 'last-year',
            getDateFilterConfigured: (data) => {
                const afterAt = moment(new Date()).subtract(1, 'years');
                return {
                    afterAt: toShortFormatDate(afterAt.toString(), momentFormat),
                    beforeAt: toShortFormatDate(new Date().toString(), momentFormat),
                };
            },
            enabled: true,
        },
    ];

    const handleChange = (
        data: { beforeAt: string | undefined; afterAt: string | undefined },
        selectedOption: string | undefined = undefined
    ) => {
        dispatch(
            updateGlobalFilter.fn(
                data.beforeAt,
                data.afterAt,
                periodConfig.enabled,
                selectedOption ? selectedOption : periodConfig.selectedOption
            )
        );
    };

    const updateEnabled = (enabled: boolean) => {
        dispatch(
            updateGlobalFilter.fn(periodConfig.beforeAt, periodConfig.afterAt, enabled, periodConfig.selectedOption)
        );
    };

    return (
        <Grid container direction='row' spacing={2} className={classes.rootGrid}>
            <Grid item>
                <Checkbox
                    name={'enabled'}
                    onChange={(event) => {
                        updateEnabled(event.target.checked);
                    }}
                />
            </Grid>
            <Grid item>
                <DatePeriodFilter
                    enabled={!!periodConfig.enabled}
                    handleChange={(data: any) => {
                        handleChange(data);
                    }}
                    afterAt={periodConfig.afterAt}
                    beforeAt={periodConfig.beforeAt}
                />
            </Grid>
            <Grid item className={classes.centerAlignGrid}>
                <Button
                    disabled={!periodConfig.enabled}
                    className={classes.button}
                    color='primary'
                    aria-label='Menu'
                    onClick={handleOpenMenu}
                >
                    <DateRangeIcon className={classes.icon} />
                    {translate('app.dashboards.actions.enableDashboards')}
                </Button>
                <Menu id='long-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                    {menuItems.map((menuItem, index) => (
                        <MenuItem
                            key={`${menuItem.label}_${index}`}
                            disabled={!menuItem.enabled}
                            selected={periodConfig && menuItem.value === periodConfig.selectedOption}
                            onClick={() => {
                                // @ts-ignore
                                const { beforeAt, afterAt } = menuItem.getDateFilterConfigured({
                                    beforeAt: periodConfig.beforeAt,
                                    afterAt: periodConfig.afterAt,
                                });

                                handleChange({ beforeAt, afterAt }, menuItem.value);
                                handleChange({ beforeAt, afterAt }, menuItem.value);
                                handleCloseMenu();
                            }}
                        >
                            {translate(menuItem.label)}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </Grid>
    );
};
export default PreconfiguredPeriodFilterButton;
