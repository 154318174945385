import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useMutation, useNotify, useRefresh, useUpdate } from 'react-admin';
import { METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { MetadataAvatarType } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';

interface DeleteFormProps {
    open: boolean;
    handleClose: () => void;
    selected: MetadataAvatarType | undefined;
}

const DeleteForm = ({ open, handleClose, selected }: DeleteFormProps) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const [updateMetadataAvatarType, { loading }] = useUpdate(METADATA_AVATAR_TYPES);

    const deleteFn = () => {
        updateMetadataAvatarType(
            METADATA_AVATAR_TYPES,
            selected?.id,
            {
                hidden: true,
            },
            selected,
            {
                onSuccess: () => {
                    // dispatch(selectEquipmentType.fn(null));
                    notify('app.subCategory.archive.success', 'success');
                    handleClose();
                    refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);

                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                    handleClose();
                },
            }
        );
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <GlobalForm
                    title={'app.subCategory.archive.title'}
                    subtitle={'app.subCategory.archive.subtitle'}
                    subtitleTranslateOption={{
                        name: selected?.name,
                    }}
                    titleIcon={<DeleteIcon color={'secondary'} />}
                />
                <ButtonsBlockForm
                    disableValidButton={loading}
                    onCancel={handleClose}
                    onValid={deleteFn}
                    labelValidButton={'app.archive'}
                    validButtonIsSubmitType={false}
                />
            </DialogContent>
        </Dialog>
    );
};
export default DeleteForm;
