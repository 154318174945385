import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Record, ReduxState, useCreate, useNotify, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { TICKETING_EVENT_USERS, USERS } from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import { getIncidentSelected } from '../../../../redux/reducers/incidents';
import {
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
} from '../../../../utils/CONST';
import { Avatar, Company, TicketingEventUser, User } from '../../../../utils/types';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import AutocompleteServiceProviderInput from '../../../../commons/inputs/AutocompleteServiceProviderInput';

interface CreateFormProps {
    open: boolean;
    handleClose: () => void;
    handleSuccessfully?: () => void;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const NAME_FIELD = 'name';

const AssignProviderForm = ({ open, handleClose, handleSuccessfully }: CreateFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const [create, { loading }] = useCreate();
    const incidentSelected: Avatar | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getIncidentSelected(state)
    );
    const companyIdUserConnected: string | number | undefined = useSelector(
        (state: CustomReducerState) => (state.ticketing.user.userConnected?.company as Company)?.id
    );

    const currentTicketingEventUser: Record | undefined = useSelector((state: ReduxState) =>
        Object.values(state.admin.resources[TICKETING_EVENT_USERS].data).find(
            (eventUser: Record) => incidentSelected && (eventUser as TicketingEventUser).event === incidentSelected.id
        )
    );

    const currentServiceProvider: Record | undefined = useSelector((state: ReduxState) =>
        Object.values(state.admin.resources[USERS].data).find(
            (user) => currentTicketingEventUser && user.id === (currentTicketingEventUser as TicketingEventUser).user
        )
    );

    //@ts-ignore
    const createAddUserToTicketingEvent = (data: Record<string, unknown>) => {
        const userId = data[NAME_FIELD];
        if (incidentSelected) {
            const dataToSend = {
                user: userId,
                event: incidentSelected?.id.toString(),
                notifyUser: true,
            };

            create(TICKETING_EVENT_USERS, dataToSend, {
                onSuccess: () => {
                    handleSuccessfully && handleSuccessfully();
                    notify('app.incidents.addServiceProvider.added.ok', 'success');
                    handleClose();
                },
                onFailure: () => {
                    notify('app.incidents.addServiceProvider.added.ko', 'warning');
                },
            });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <GlobalForm
                    title={'app.incidents.addServiceProvider.title'}
                    titleIcon={<AddIcon color={'secondary'} />}
                >
                    <Form
                        initialValues={{
                            [NAME_FIELD]: (currentServiceProvider as User)?.id,
                        }}
                        onSubmit={(values) => createAddUserToTicketingEvent(values)}
                        render={({ handleSubmit, pristine }) => {
                            return (
                                <Grid container>
                                    <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                        <Grid item xs>
                                            <ReferenceInput
                                                key={NAME_FIELD}
                                                variant={'standard'}
                                                fullWidth
                                                label={translate('app.incidents.addServiceProvider.name')}
                                                source={NAME_FIELD}
                                                reference={USERS}
                                                filter={{
                                                    'tags[one]': [
                                                        TICKETING_USER_SERVICE_PROVIDER_TAG,
                                                        TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
                                                    ],
                                                    wcCompanyId: companyIdUserConnected
                                                        ? companyIdUserConnected.toString()
                                                        : null,
                                                }}
                                                filterToQuery={(searchText) => ({
                                                    lastName: searchText,
                                                })}
                                            >
                                                <AutocompleteServiceProviderInput />
                                            </ReferenceInput>
                                        </Grid>
                                        <ButtonsBlockForm
                                            disableValidButton={
                                                loading || !incidentSelected || !companyIdUserConnected || pristine
                                            }
                                            onCancel={handleClose}
                                            validButtonIsSubmitType={true}
                                        />
                                    </form>
                                </Grid>
                            );
                        }}
                    />
                </GlobalForm>
            </DialogContent>
        </Dialog>
    );
};
export default AssignProviderForm;
