import React, { useState } from 'react';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';

interface Props {
    buttonLabel: string;
    menuActions: MenuItemType[];
}
const OptionMenu = (props: Props) => {
    const [menuState, setMenuState] = useState(false);
    const handleToggle = () => {
        setMenuState(!menuState);
    };

    return (
        <MenuButtonActions
            buttonLabel={props.buttonLabel}
            overwriteButtonLabel
            menuActions={props.menuActions}
            menuState={menuState}
            onButtonClick={handleToggle}
        />
    );
};

export default OptionMenu;
