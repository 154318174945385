import React from 'react';
import { AutocompleteInput } from 'react-admin';
import { User } from '../../utils/types';
import {
    getTicketingField,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../utils/user/customFields';

const AutocompleteServiceProviderInput = (props: any) => {
    const getServiceType = (user: User) => {
        const value = getTicketingField(
            user,
            `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}`
        );
        return value ? `(${value})` : '';
    };

    const optionRenderer = (user: User | null) => (user ? `${user.username} ${getServiceType(user)}` : '');

    return (
        <AutocompleteInput
            {...props}
            optionText={optionRenderer}
            resettable
            filterToQuery={(search: string) => ({ lastName: search })}
        />
    );
};

export default AutocompleteServiceProviderInput;
