// @ts-ignore
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import { ReduxState } from 'react-admin';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
// import { routerMiddleware, connectRouter } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import customSaga from './saga';
import customReducer, { CustomReducerState } from './reducers';

// @ts-ignore
import logger from 'redux-logger';
import { DataProvider } from '../providers/dataProvider';
import { AuthProvider } from '../providers/authProvider';

interface CreateProps {
    authProvider: AuthProvider;
    dataProvider: DataProvider;
    history: any;
}

export type GlobalState = ReduxState & CustomReducerState;

export default ({ authProvider, dataProvider, history }: CreateProps) => {
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        ticketing: customReducer,
    });

    const resettableAppReducer = (state: GlobalState, action: any) =>
        // @ts-ignore
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    const saga = function* rootSaga() {
        // @ts-ignore
        yield all([adminSaga(dataProvider, authProvider), customSaga(dataProvider)].map(fork));
    };

    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers =
        // @ts-ignore
        (process.env.NODE_ENV === 'development' &&
            // @ts-ignore
            typeof window !== 'undefined' &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;

    const store = createStore(
        // @ts-ignore
        resettableAppReducer,
        {
            /* set your initial state here */
        },
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                logger
                // add your own middlewares here
            )
            // add your own enhancers here
        )
    );

    sagaMiddleware.run(saga);
    return store;
};
