import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';
import { useNotify, useTranslate } from 'react-admin';
import makeStyles from '@material-ui/styles/makeStyles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Remove from '@material-ui/icons/RemoveCircle';
import 'cropperjs/dist/cropper.css';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import './styles.css';

const useStyles = makeStyles(() => ({
    cropImage: {
        margin: '0px !important',
    },
    cropContainer: {
        width: '100%',
        height: '400px',
        position: 'relative',
    },
    iconActions: {
        display: 'none',
    },
    loadingIcon: {
        display: 'none',
    },
    icon: {
        fontSize: '35px',
        marginRight: '5px',
        marginLeft: '5px',
        cursor: 'pointer',
    },
    imageRounded: {
        borderRadius: '50%',
        border: '4px solid #D4D4D4',
        height: '143px',
        width: '143px',
    },
    buttonFab: {
        height: '143px',
        width: '143px',
        backgroundColor: '#80808042 !important',
        '&:hover': {
            zIndex: 1,
            opacity: 1,
            '& $iconActions': {
                zIndex: 1,
                display: 'inherit',
                position: 'absolute',
                top: '55px',
                left: '25px',
                '& $icon': {
                    color: 'steelblue',
                },
                '& $icon#delete': {
                    color: 'orangered',
                },
            },
        },
    },
}));

interface ImageProps {
    data: string | ArrayBuffer | null;
    name: string;
}

interface Props {
    handleImageDataUser: (data: { name: string; data: string } | null) => void;
    handleOnCancel?: () => void;
    initialImageData?: ImageProps;
    loadingImage?: boolean;
}

const MaxAllowedSizeFile = 5; //MegaBytes max

const ImageCropperField = (props: Props) => {
    const [imageTemp, setImageTemp] = useState<ImageProps | undefined>();
    const [croppedImageUrl, setCroppedImageUrl] = useState<string | undefined>();
    const [image, setImage] = useState<ImageProps | undefined>(
        props.initialImageData
            ? {
                  name: props.initialImageData.name,
                  data: props.initialImageData.data,
              }
            : undefined
    );
    const [editingImage, setEditingImage] = useState(false);
    const [removedImage, setRemoveImage] = useState(false);
    const cropperRef = useRef(null);

    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();

    useEffect(() => {
        // this condition is true when only initial Image Data have data
        if (
            props.initialImageData &&
            !props.loadingImage &&
            props.initialImageData.data !== image?.data &&
            !imageTemp &&
            !removedImage
        ) {
            setImage({
                name: props.initialImageData.name,
                data: props.initialImageData.data,
            });
        }
    }, [props.initialImageData, image, props.loadingImage, removedImage]);

    useEffect(() => {
        if (!editingImage && !props.loadingImage && image && image.data) {
            props.handleImageDataUser({ name: image.name, data: image.data as string });
        }
    }, [image, editingImage]);

    const onCrop = () => {
        if (cropperRef) {
            const imageElement = cropperRef.current;
            if (imageElement && 'cropper' in imageElement) {
                //@ts-ignore
                const cropper = imageElement.cropper;
                const url = cropper ? cropper.getCroppedCanvas().toDataURL() : undefined;
                setCroppedImageUrl(url);
            }
        }
    };

    const onFileChange = async (e: ChangeEventHandler<HTMLInputElement>) => {
        //@ts-ignore
        if (e && 'target' in e && e.target && e.target.files && e.target.files.length > 0) {
            //@ts-ignore
            const file = e.target.files[0] as File;
            const imageDataUrl = await readFile(file);
            setImageTemp({
                data: imageDataUrl,
                name: file.name,
            });
            setEditingImage(true);
        }
    };

    const readFile = (file: File): Promise<string | ArrayBuffer | null> => {
        const fileSizeMb = file.size / 1024 / 1024; // size MB
        if (fileSizeMb > MaxAllowedSizeFile) {
            notify(translate('app.media.imageMaxSizeUpload'), 'warning', 3000);
            return new Promise((resolve) => {
                resolve(null);
            });
        } else {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }
    };

    const removeImage = () => {
        setImageTemp(undefined);
        setCroppedImageUrl(undefined);
        setImage(undefined);
        setRemoveImage(true);
        props.handleImageDataUser(null);
        props.handleOnCancel && props.handleOnCancel();
    };

    const handleAddImage = () => {
        if (!croppedImageUrl || !imageTemp?.data) return;
        setImage({
            data: croppedImageUrl ? croppedImageUrl : imageTemp?.data,
            name: imageTemp ? imageTemp?.name : 'user_logo',
        });
        setEditingImage(false);
    };

    const handleCancelAddImage = () => {
        setEditingImage(false);
        setImageTemp(undefined);
        setCroppedImageUrl(undefined);
        setImage(undefined);
    };

    return (
        <>
            {imageTemp && imageTemp.data && editingImage && (
                <div>
                    <div className={classes.cropContainer}>
                        <Cropper
                            center={true}
                            autoCrop={true}
                            src={imageTemp.data as string}
                            style={{ height: 320, width: '100%' }}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                        />
                    </div>
                    <Grid
                        container
                        spacing={4}
                        justify={'center'}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <Grid item>
                            <Button color='primary' variant={'contained'} onClick={handleAddImage}>
                                <CheckIcon />
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button color='primary' variant={'contained'} onClick={handleCancelAddImage}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}
            {!editingImage && (
                <div>
                    <Tooltip title={translate('app.media.addImage')}>
                        <Fab color='secondary' aria-label='add' className={classes.buttonFab}>
                            <div className={classes.iconActions}>
                                <label htmlFor='contained-button-file'>
                                    <input
                                        id='contained-button-file'
                                        multiple
                                        type='file'
                                        style={{ display: 'none' }}
                                        // @ts-ignore
                                        onChange={onFileChange}
                                        accept='image/png, image/jpeg, image/jpg, image/gif, image/bmp'
                                    />
                                    <PhotoCamera className={classes.icon} />
                                </label>
                                <Remove className={classes.icon} id='delete' onClick={removeImage} />
                            </div>
                            {image && image.data && (
                                <img src={image.data as string} className={classes.imageRounded} alt={image.name} />
                            )}
                        </Fab>
                    </Tooltip>
                </div>
            )}
        </>
    );
};
export default ImageCropperField;
