import { makeStyles } from '@material-ui/core/styles';
import React, { cloneElement, useMemo } from 'react';
import { METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { FieldCapitalizeListStyles, FieldHeaderListStyles } from '../../../theme';
import { CUSTOM_FIELDS_ICON_SVG_COLLECTION } from '../../../utils/metadataAvatarType';
import LogoIcon from '../logoIcon';
import MetadataAvatarTypeImageField from '../metadataAvatarTypeMediaField';
import useGetList from '../../react-query-hooks/useGetList';

const useTreeItemStyles = makeStyles({
    image: {
        marginRight: 5,
        height: 20,
        width: 20,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
    },
    label: {
        fontSize: 14,
        marginRight: 10,
    },
    labelMarginLeft: {
        fontSize: 14,
        marginRight: 10,
        marginLeft: 12,
    },
    labelMarginLeftWithBadge: {
        fontSize: 14,
        marginRight: 10,
        marginLeft: 17,
    },
    iconButton: {
        padding: 2,
        marginLeft: 5,
    },
    icon: {
        fontSize: 20,
    },
    checkbox: {
        padding: 0,
    },
    //@ts-ignore
    fieldHeader: {
        ...FieldHeaderListStyles,
        ...FieldCapitalizeListStyles,
    },
    metadataAvatarTypeImageField: {
        marginRight: 5,
        display: 'flex',
    },
    badgeRoot: {
        backgroundColor: 'red',
    },
});

interface Props {
    itemId: string;
    defaultIcon?: any;
}

const ShowMediaResolver = ({ itemId, defaultIcon }: Props) => {
    const classes = useTreeItemStyles();

    const { data } = useGetList(
        METADATA_AVATAR_TYPES,
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'DESC' },
            filter: { serialization_groups: ['read_meta_files', 'link'], id: itemId as string },
        },
        { keyToFind: `metadataAvatarType_${itemId}` }
    );
    const metadataAvatarTypeInState = useMemo(() => (data && data.length > 0 ? data[0] : undefined), [data]);

    return metadataAvatarTypeInState ? (
        <div>
            {metadataAvatarTypeInState &&
            'customFields' in metadataAvatarTypeInState &&
            CUSTOM_FIELDS_ICON_SVG_COLLECTION in metadataAvatarTypeInState.customFields &&
            metadataAvatarTypeInState.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION] ? (
                <LogoIcon record={metadataAvatarTypeInState} />
            ) : (
                <MetadataAvatarTypeImageField
                    rootClassName={classes.metadataAvatarTypeImageField}
                    itemId={metadataAvatarTypeInState.id as string}
                />
            )}
        </div>
    ) : defaultIcon ? (
        cloneElement(defaultIcon)
    ) : (
        <img src={'/tmp-image.png'} className={classes.image} alt='' />
    );
};

export default ShowMediaResolver;
