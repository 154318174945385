import React from 'react';
import { useSelector } from 'react-redux';
import Authentication from '../commons/auth/Authentication';
import CategoriesEvent from '../components/categoriesEvent';
import { CustomReducerState } from '../redux/reducers';
import { categoryLevelNameRoute } from './paths';

const CategoriesEventPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={categoryLevelNameRoute}>{userConnected && <CategoriesEvent />}</Authentication>;
};

export default CategoriesEventPage;
