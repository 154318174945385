export interface ClearTicketingStateCreator {
    action: string;
    fn: () => ClearTicketingStateAction;
}

export interface ClearTicketingStateAction {
    type: typeof clearTicketingStateCreator.action;
}

export const clearTicketingStateCreator: ClearTicketingStateCreator = {
    action: '360sc_TICKETING/CLEAR_TICKETING_STATE',
    fn: () => ({
        type: clearTicketingStateCreator.action,
    }),
};

export type ACTIONS = ClearTicketingStateAction;
