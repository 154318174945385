import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import MenuButtonActions, { MenuItemType } from '../menuButtonActions';
import { useAuthProvider, useLogout, useTranslate } from 'react-admin';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { AuthProvider } from '../../providers/authProvider';
import { useHistory } from 'react-router-dom';

interface UserInformationProps {
    companyName: string | null;
    userName: string | null;
    userImageSrc: string | null;
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '45px auto 45px',
        alignItems: 'center',
        backgroundColor: 'white',
        // margin: '10px 9px 0',
        padding: '5px',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        maxWidth: 200,
        color: 'black',
    },
    left: {},
    center: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        // maxWidth: "130px",
    },
    right: { color: 'black' },
    icon: { color: 'black' },
}));

const UserInformation = (props: UserInformationProps) => {
    const classes = useStyles();
    const history = useHistory();

    const [menuState, setMenuState] = useState(false);
    const handleToggle = () => {
        setMenuState(!menuState);
    };
    const translate = useTranslate();
    // @ts-ignore
    const authProvider: AuthProvider = useAuthProvider();

    const logout = useLogout();

    // const logout = () => {
    //     authProvider
    //         .logout()
    //         .then((redirect) => {
    //             if (typeof redirect === 'string') {
    //                 history.push(redirect);
    //             }
    //         })
    //         .catch((error: any) => console.error(error));
    // };
    const menuActions: MenuItemType[] = [
        {
            icon: <LogoutIcon />,
            text: translate('app.logout'),
            onClick: () => {
                logout();
            },
            disabled: false,
            roles: [],
        },
    ];

    return (
        <div className={classes.container}>
            <div className={classes.left}>{props.userImageSrc && <Avatar src={props.userImageSrc} />}</div>
            <div className={classes.center}>
                {props.userName && (
                    <Grid item>
                        <Tooltip title={props.userName}>
                            <Typography noWrap className={classes.title}>
                                {props.userName}
                            </Typography>
                        </Tooltip>
                    </Grid>
                )}
                {props.companyName && (
                    <Grid item className={classes.title}>
                        <Typography noWrap>{props.companyName}</Typography>
                    </Grid>
                )}
            </div>
            <div className={classes.right}>
                <MenuButtonActions
                    popperZIndexOverride={5000}
                    startIcon={<MoreVertIcon className={classes.icon} />}
                    justIcon={true}
                    buttonLabel={'app.menuActions.title'}
                    overwriteButtonLabel
                    menuActions={menuActions}
                    menuState={menuState}
                    onButtonClick={handleToggle}
                />
            </div>
        </div>
    );
};

export default UserInformation;
