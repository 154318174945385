import React, { useState } from 'react';
import { Avatar } from '../../../../utils/types';
import { ThemeProvider } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import { CustomIconButton, GreenButtonTheme } from './commons';
import { AVATAR_IDENTIFIER_REMINDER_TICKETING_GOOGLE_CALENDAR_EVENT } from '../../../../utils/CONST';
import { useRefresh, useTranslate } from 'react-admin';
import AlertConfigForm from '../alertConfig';

interface Props {
    record: Avatar;
}

const ReminderButton = (props: Props) => {
    const { record } = props;
    const translate = useTranslate();
    const refresh = useRefresh();
    // const dataProvider = useDataProvider();
    // const notify = useNotify();
    const [showAddAlertDialog, setShowAddAlertDialog] = useState<boolean>(false);

    const handleShowAddAlertDialog = (e: Event) => {
        e.stopPropagation();
        setShowAddAlertDialog(true);
    };

    const handleOnCloseAlertDialog = () => {
        setShowAddAlertDialog(false);
        refresh();
    };

    const reminderEventIdentifier =
        record &&
        'identifiers' in record &&
        (record as Avatar).identifiers &&
        (record as Avatar)?.identifiers?.find((identifier: string) =>
            identifier.toLowerCase().startsWith(AVATAR_IDENTIFIER_REMINDER_TICKETING_GOOGLE_CALENDAR_EVENT)
        );

    const removeAlert = () => {
        if (!reminderEventIdentifier) return;
        // const newIdentifiers =
        //     record &&
        //     (record as Avatar)?.identifiers?.filter((identifier: string) => identifier !== reminderEventIdentifier);
        // if (newIdentifiers) {
        //     dataProvider
        //         .update(AVATARS, {
        //             id: record.id,
        //             data: {
        //                 identifiers: newIdentifiers,
        //             },
        //             previousData: {
        //                 ...(record as Avatar),
        //             },
        //         })
        //         .then((response) => notify('app.incidents.alertConfig.removed'))
        //         .catch((error: any) => notify('app.incidents.alertConfig.removedError'));
        // }
    };
    return (
        <div>
            <ThemeProvider theme={GreenButtonTheme}>
                <CustomIconButton onClick={(e: any) => handleShowAddAlertDialog(e)}>
                    <Tooltip
                        title={
                            reminderEventIdentifier
                                ? translate('app.incidents.alertConfig.edit')
                                : translate('app.incidents.alertConfig.create')
                        }
                    >
                        <AlarmOnIcon color={reminderEventIdentifier ? 'secondary' : 'primary'} />
                    </Tooltip>
                </CustomIconButton>
            </ThemeProvider>
            {showAddAlertDialog && record && (
                <AlertConfigForm
                    search={!!reminderEventIdentifier}
                    event={record as Avatar}
                    open={true}
                    onClose={handleOnCloseAlertDialog}
                    onCancel={() => setShowAddAlertDialog(false)}
                    removeAlert={removeAlert}
                />
            )}
        </div>
    );
};

export default ReminderButton;
