import { BooleanInput, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import { USERS } from '../../../../providers/resources';
import { TICKETING_USER_SERVICE_PROVIDER_TAG, USER_PROVIDER_TAGS } from '../../../../utils/CONST';
import AutocompleteServiceProviderInput from '../../../../commons/inputs/AutocompleteServiceProviderInput';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import { Company } from '../../../../utils/types';
import { useField, useForm } from 'react-final-form';
import HeaderBlock from '../../../../commons/HeaderBlock/HeaderBlock';
import { TypographyClassKey } from '@material-ui/core/Typography/Typography';
import { styled } from '@material-ui/core/styles';

const REASSIGN_CURRENT_USER_PROVIDER_FIELD = 'reassignCurrentUserProvider';
const NAME_FIELD = 'name';
const NEW_USER_PROVIDER_FIELD = 'newUserProvider';

const CustomTypography = styled(Typography)({
    fontSize: 15,
    fontWeight: 700,
});

const UserConfigInput = (props: any) => {
    const translate = useTranslate();

    const companyIdUserConnected: string | number | undefined = useSelector(
        (state: CustomReducerState) => (state.ticketing.user.userConnected?.company as Company)?.id
    );

    const reassignCurrentUserProviderField = useField(REASSIGN_CURRENT_USER_PROVIDER_FIELD);

    return (
        <>
            <BooleanInput
                label={
                    <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                        {translate('app.incidents.restart.reassignCurrentUserProvider')}
                    </Typography>
                }
                source={REASSIGN_CURRENT_USER_PROVIDER_FIELD}
            />

            {!reassignCurrentUserProviderField.input.value && (
                <>
                    <CustomTypography variant={'body1'}>
                        {translate('app.incidents.restart.newUserProvider')}{' '}
                    </CustomTypography>

                    <ReferenceInput
                        key={NEW_USER_PROVIDER_FIELD}
                        variant={'standard'}
                        fullWidth
                        label={translate('app.incidents.addServiceProvider.name')}
                        source={NEW_USER_PROVIDER_FIELD}
                        reference={USERS}
                        filter={{
                            'tags[one]': USER_PROVIDER_TAGS,
                            wcCompanyId: companyIdUserConnected ? companyIdUserConnected.toString() : null,
                        }}
                        filterToQuery={(searchText) => ({
                            lastName: searchText,
                        })}
                    >
                        <AutocompleteServiceProviderInput />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

export default UserConfigInput;
