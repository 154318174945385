import { Tooltip, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { GetOneResult, Identifier, ReduxState, useDataProvider, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import DashboardBox from '../../commons/dashboard-box/dashboard-box';
import ShowTreeDialog from '../../commons/dialog/ShowTreeDialog';
import GetMetabaseUrlOfTheData from '../../commons/get-metabase-url-of-the-data/get-metabase-url-of-the-data';
import { AVATARS } from '../../providers/resources';
import { updateGlobalDashboard } from '../../redux/actions/dashboard';
import { CustomReducerState } from '../../redux/reducers';
import extractIdFromURI from '../../utils/id';
import { ResponseForwarderToMetabaseType } from '../../utils/ticketingBridgeMetabase/viewsDynamic';
import { Avatar, ChartDashboardComponent } from '../../utils/types';

// const QUESTION = 10;

const CustomTypography = styled(Typography)({
    fontSize: 72,
    fontWeight: 500,
});

const RenderData = ({ data: dataFromProps }: { data: ResponseForwarderToMetabaseType }) => {
    const [data, setData] = useState<any>(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setData(dataFromProps);
    }, [dataFromProps]);

    useEffect(() => {
        const mData = data && 'data' in data ? data.data : null;
        const rows = mData && 'rows' in mData ? mData['rows'] : [];
        setRows(rows);
    }, [data]);

    return (
        <CustomTypography align={'center'}>
            {Array.isArray(rows) && rows.length > 0 ? rows[0][0] : '0'}
        </CustomTypography>
    );
};

interface Props extends ChartDashboardComponent {
    infoText: string;
    dialogTitle: string;
    label: string;
    dashboardTitle: string;
    dialogHelpText: string;
    requestQuestionId: number;
    menuOptionLabel: {
        [key: string]: string;
    };
}

interface ConfigProp {
    [key: string]: Identifier;
}

const PLACE = 'place';

const QuantityOfOpenAndContractedIncidentsDashboard = (props: Props) => {
    const [version, setVersion] = useState(0);

    const dashboardVersion: number = useSelector((state: CustomReducerState) => state.ticketing.dashboards.version);

    const translate = useTranslate();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const config: ConfigProp | undefined = useSelector((state: CustomReducerState) => {
        const ownDashboardType = state.ticketing.dashboards.global[props.id];
        const chartRegisterData = ownDashboardType.find((item) => item.instanceId === props.instanceId);
        return chartRegisterData ? chartRegisterData.customFields : undefined;
    });

    const placeIdSelected: string | undefined = config && PLACE in config ? (config[PLACE] as string) : undefined;

    const [placeSelected, setPlaceSelected] = useState<Avatar | undefined>(undefined);

    // update version when global dashboard versio change
    useEffect(() => {
        if (dashboardVersion) {
            setVersion(version + 1);
        }
    }, [dashboardVersion]);

    // get place selected and update version
    useEffect(() => {
        if (placeSelected) {
            setVersion(version + 1);
        }
    }, [placeSelected]);

    useEffect(() => {
        if (placeIdSelected) {
            // search place
            dataProvider
                .getOne(
                    AVATARS,
                    { id: placeIdSelected },
                    {
                        onSuccess: (response: GetOneResult) => {
                            if (response && 'data' in response && response.data) {
                                // @ts-ignore
                                setPlaceSelected(response.data as Avatar);
                            }
                        },
                        onFailure: (error: any) => {
                            console.error('Failure getting one ', AVATARS, error);
                            setPlaceSelected(undefined);
                        },
                    }
                )
                .catch((e: any) => {
                    console.error('Catch exception getting one ', e);
                    setPlaceSelected(undefined);
                });
        }
    }, [placeIdSelected]);

    const validateSelectedPlace = (place?: Avatar) => {
        if (!place) return;
        updateConfig({ [PLACE]: place.id });
    };

    const updateConfig = (config: ConfigProp) => {
        dispatch(updateGlobalDashboard.fn(props.id, props.instanceId, config));
    };

    return (
        <DashboardBox
            config={
                <ShowTreeDialog
                    closeAfterValidate={true}
                    showButton={true}
                    handleValidate={validateSelectedPlace}
                    dialogTitle={props.dialogTitle}
                    dialogHelpText={props.dialogHelpText}
                />
            }
            icon={
                <Tooltip title={translate(props.infoText, { question: props.requestQuestionId })}>
                    <InfoIcon />
                </Tooltip>
            }
            title={translate('app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.title')}
            subtitle={
                placeSelected
                    ? translate('app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.in', {
                          name: (placeSelected as Avatar).name,
                      })
                    : translate('app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.nonePlace')
            }
            onClose={props.close}
        >
            <GetMetabaseUrlOfTheData
                version={version}
                requestBody={{
                    id: props.requestQuestionId,
                    dashboard_type: 'question',
                    place_id: placeSelected ? extractIdFromURI(placeSelected.id as string) : null,
                }}
            >
                {(data: any) => <RenderData data={data} />}
            </GetMetabaseUrlOfTheData>
        </DashboardBox>
    );
};

export default QuantityOfOpenAndContractedIncidentsDashboard;
