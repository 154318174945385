import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import FiberNew from '@material-ui/icons/FiberNew';
import * as React from 'react';
import { TopToolbar, useTranslate } from 'react-admin';
import { ButtonFontWeightBoldStyles, TopToolbarStyles } from '../../../../../../../theme';

const useStyles = makeStyles({
    // @ts-ignore
    topToolbar: {
        ...TopToolbarStyles,
    },
    buttonFontWeightBold: {
        ...ButtonFontWeightBoldStyles,
    },
});

const CustomButton = styled(Button)({});

const Actions = ({ handleOnClickCreate }: { handleOnClickCreate: () => void }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <TopToolbar className={classes.topToolbar}>
            <Grid container alignItems={'center'}>
                <Grid item>
                    <CustomButton
                        className={classes.buttonFontWeightBold}
                        startIcon={<FiberNew />}
                        onClick={handleOnClickCreate}
                        color={'primary'}
                    >
                        {translate('app.navigation.configuration.subcategory.selectLabel')}
                    </CustomButton>
                </Grid>
            </Grid>
        </TopToolbar>
    );
};

export default Actions;
