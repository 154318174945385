import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CreateResult, Record, TextInput, useDataProvider, useNotify, useTranslate } from 'react-admin';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { USER_PASSWORD_FORGOTTEN } from '../../../../providers/resources';
import { RestartPasswordForgottenResponse } from '../../../../utils/types';

interface Props {
    handleCancelClick: () => void;
}

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        justifyContent: 'center',
        alignContent: 'center',
    },
    cardRoot: {
        height: 'auto',
        minWidth: '300px',
        width: '300px',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 14,
        paddingRight: 14,
    },
    fullWidth: {
        width: '100%',
    },
});

const PasswordForgotForm = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values: { usernameOrEmail: string }) => {
        const data = {
            usernameOrEmail: values.usernameOrEmail,
        };
        setLoading(true);
        dataProvider
            .create(USER_PASSWORD_FORGOTTEN, { data: JSON.stringify(data) })
            .then((result: CreateResult) => {
                const data: RestartPasswordForgottenResponse | Record | null =
                    result && 'data' in result && result.data ? result.data : null;
                if (data && 'isOverRetryTtl' in data && data.isOverRetryTtl) {
                    notify('app.users.resetPassword.overRetryTtl', 'warning');
                } else if (data && 'isOverRetryTtl' in data && !data.isOverRetryTtl) {
                    notify('app.users.resetPassword.emailSentMessage', 'info');
                    props.handleCancelClick();
                }
                setLoading(false);
            })
            .catch((error: any) => {
                console.error(error);
                notify('app.users.resetPassword.emailNoSent', 'error');
                setLoading(false);
            });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, valid, pristine, values }) => (
                <>
                    <Grid container>
                        <Grid item>
                            <Typography align={'center'} variant={'body1'}>
                                {translate('app.users.passwordForgotten')}
                            </Typography>
                        </Grid>
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <Grid item xs>
                                <TextInput
                                    autoFocus
                                    variant={'standard'}
                                    required
                                    key={'username'}
                                    fullWidth
                                    type={'text'}
                                    label={translate('app.login.usernameOrEmail')}
                                    source={'usernameOrEmail'}
                                />
                            </Grid>
                            <DialogTemplateButtons
                                outside
                                withCancel={true}
                                withCreate
                                cancelButtonLabel={translate('app.cancel')}
                                onCancelClick={props.handleCancelClick}
                                isSubmitting={loading}
                                isValidForm={valid && !pristine}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    </Grid>
                </>
            )}
        />
    );
};

export default PasswordForgotForm;
