import {
    CreateResult,
    GetListResult,
    Record,
    ReduxState,
    useDataProvider,
    useMutation,
    useNotify,
    useSafeSetState,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { resolveApiSchemaCreator } from '../redux/actions/hydraDataProvider';
import { CustomReducerState } from '../redux/reducers';
import { LANGUAGES } from '../providers/resources';
import { Language } from '../utils/types';

const useCreateLanguage = (twoLetters: string, value: string) => {
    const notify = useNotify();
    const languagesData = useSelector((state: ReduxState) => state.admin.resources[LANGUAGES].data);

    const [language, setLanguage] = useSafeSetState<Record | undefined>(
        languagesData
            ? Object.values(languagesData).find(
                  (item: Record) =>
                      (item as Language).twoLetters.toLowerCase().trim() === twoLetters.toLowerCase().trim() &&
                      (item as Language).value.toLowerCase().trim() === value.toLowerCase().trim()
              )
            : undefined
    );

    const [postLanguage] = useMutation({ type: 'create', resource: LANGUAGES });
    const dataProvider = useDataProvider();

    const createLanguage = (
        twoLetters: string,
        value: string,
        onSuccess?: (response: CreateResult) => void,
        onFailure?: (error: any) => void
    ): Promise<any> | void => {
        return postLanguage(
            {
                payload: {
                    data: {
                        twoLetters,
                        value,
                    },
                },
            },
            {
                onSuccess: (response: CreateResult) => {
                    onSuccess && onSuccess(response);
                },
                onFailure: (error: any) => {
                    onFailure && onFailure(error);
                    notify('app.messages.createLanguagesError', 'error', { twoLetters, value });
                },
            }
        );
    };

    const getLanguages = () => {
        dataProvider.getList(
            LANGUAGES,
            {
                pagination: {
                    perPage: 1,
                    page: 1,
                },
                sort: {
                    order: 'DESC',
                    field: 'id',
                },
                filter: {
                    twoLetters,
                    value,
                },
            },
            {
                onSuccess: (response: GetListResult) => {
                    if (response && 'data' in response && response.data.length > 0) setLanguage(response.data[0]);
                    else {
                        createLanguage(twoLetters, value);
                    }
                },
                onFailure: (error: any) => {
                    console.error('GETTING LANGUAGES', twoLetters, value);
                    notify('app.messages.getLanguagesError', 'error', { twoLetters, value });
                },
            }
        );
    };

    useEffect(() => {
        if (!language) {
            getLanguages();
        }
    }, [language]);

    return language;
};

export default useCreateLanguage;
