import React from 'react';
import CreateForm from './index';
import { useCreateSuggestionContext } from 'react-admin';

const CreateFromAutocompleteInput = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value] = React.useState(filter || '');

    return (
        <CreateForm
            fromExternalInput={true}
            initialName={value}
            open={true}
            handleClose={onCancel}
            handleCreateOnSuccess={onCreate}
        />
    );
};
export default CreateFromAutocompleteInput;
