import React, { cloneElement, ReactElement } from 'react';
import {
    Identifier,
    RadioButtonGroupInput,
    TextInput,
    useCreate,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import { AVATAR_PROPERTY } from '../../../providers/resources';
import { Avatar, AvatarProperty } from '../../../utils/types';
import CustomSubmitButton from '../../inputs/CustomSubmitButton';
import CustomSwitchInput from '../../inputs/CustomSwitchInput';
import SwitchIconChildrenContainer from '../../SwitchIconChildrenContainer/SwitchIconChildrenContainer';
import makeStyles from '@material-ui/styles/makeStyles';

interface FormProps {
    avatar: Avatar;
    item: AvatarProperty | null;
    itemKey: string;
    itemValue: string | number;
    fieldType: string;
    label: string;
    definition: string | null;
    forceRefresh: () => void;
    icon: ReactElement;
    formBody?: ReactElement | null;
    disabled: boolean;
    type?: 'radio-input';
    choices?: any[];
    source?: string;
}

const PROPERTY_ENABLED = 'enabled';

const useStyles = makeStyles({
    input: {},
});
const Form = (props: FormProps) => {
    const notify = useNotify();
    const translate = useTranslate();
    const [create] = useCreate();
    const [update] = useUpdate();
    const classes = useStyles();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onSubmit = (data: { [key: string]: any }) => {
        if (!props.item) {
            createAvatarProperty(data);
        } else {
            updateAvatarProperty(data, props.item);
        }
    };
    const createAvatarProperty = (data: { [key: string]: any }) => {
        const dataToSend: {
            name: string;
            value: string;
            avatar: Identifier;
            definition?: string;
            private: boolean;
            enabled: boolean;
        } = {
            name: props.itemKey,
            value: data[props.source ? props.source : props.itemKey],
            avatar: props.avatar.id,
            private: false,
            enabled: data[PROPERTY_ENABLED],
        };
        if (props.definition) {
            dataToSend['definition'] = props.definition;
        }
        create(AVATAR_PROPERTY, dataToSend, {
            onSuccess: () => {
                notify('app.avatarProperty.created.notification', 'success', {
                    property_name: props.label,
                });
                props.forceRefresh();
            },
            onFailure: (error: any) => {
                console.error('Error creating avatar property', error);
                notify('app.avatarProperty.created.error', 'warning', { property_name: props.label });
            },
        });
    };
    const updateAvatarProperty = (data: { [key: string]: any }, item: AvatarProperty) => {
        const dataToSend = {
            value: data[props.source ? props.source : props.itemKey],
            enabled: data[PROPERTY_ENABLED],
        };
        update(AVATAR_PROPERTY, item.id, dataToSend, props.item, {
            onSuccess: () => {
                notify('app.avatarProperty.updated.notification', 'success', {
                    property_name: props.label,
                });
                props.forceRefresh();
            },
            onFailure: (error: any) => {
                console.error('Error updating avatar property', error);
                notify('app.avatarProperty.updated.error', 'warning', { property_name: props.label });
            },
        });
    };

    return (
        <FinalForm
            initialValues={{
                [props.source ? props.source : props.itemKey]: props.itemValue,
                [PROPERTY_ENABLED]: props.item ? props.item.enabled : false,
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        {!props.type && !props.formBody && (
                            <SwitchIconChildrenContainer
                                switchC={<CustomSwitchInput source={PROPERTY_ENABLED} disabled={props.disabled} />}
                                icon={props.icon}
                            >
                                <TextInput
                                    disabled={props.disabled}
                                    variant={'standard'}
                                    source={props.source ? props.source : props.itemKey}
                                    label={props.label}
                                    helperText={false}
                                    fullWidth
                                    className={classes.input}
                                />
                                <CustomSubmitButton
                                    handleSubmit={handleSubmit}
                                    fieldKeys={[PROPERTY_ENABLED, props.source ? props.source : props.itemKey]}
                                />
                            </SwitchIconChildrenContainer>
                        )}
                        {!props.type && props.formBody && cloneElement(props.formBody, { handleSubmit: handleSubmit })}

                        {props.type && props.type === 'radio-input' && (
                            <div style={{ display: 'flex' }}>
                                <RadioButtonGroupInput
                                    fullWidth
                                    disabled={props.disabled}
                                    variant={'standard'}
                                    source={props.source ? props.source : props.itemKey}
                                    label={props.label}
                                    helperText={false}
                                    choices={props.choices}
                                />
                                <CustomSubmitButton
                                    fieldKeys={[props.source ? props.source : props.itemKey]}
                                    handleSubmit={handleSubmit}
                                />
                            </div>
                        )}
                    </form>
                );
            }}
        />
    );
};

export default Form;
