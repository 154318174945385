import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const restHeight = 138;

const useStyles = makeStyles({
    backDiv: {
        borderTop: '2px solid #C4D600',
        overflow: 'auto',
        backgroundColor: 'white',
        padding: 10,
    },
});

interface Props {
    children: JSX.Element | ((props: any) => JSX.Element);
    overrideClasses?: string;
}

const BackDiv = ({ children, overrideClasses }: Props) => {
    const classes = useStyles();
    const [divHeight, setDivHeight] = useState(300);
    const [render, setRender] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => setDivHeight(window.innerHeight - restHeight));
        if (typeof window !== 'undefined') setDivHeight(window.innerHeight - restHeight);
        setRender(true);
    }, []);

    return (
        <div style={{ height: divHeight }} className={overrideClasses ? overrideClasses : classes.backDiv}>
            {render && typeof children === 'function' ? children({ divHeight }) : children}
        </div>
    );
};

BackDiv.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.any,
};

export default BackDiv;
