import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import {
    AutocompleteArrayInput,
    DataProviderContext,
    GetListResult,
    Identifier,
    Record,
    required,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import {
    MEDIA,
    METADATA_AVATAR_TYPES,
    TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
} from '../../../../../../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_EVENT } from '../../../../../../../../utils/CONST';
import { getResourceURIFROMAnotherURI, getUriFromItems } from '../../../../../../../../utils/id';
import { Avatar, SubcategoryEventTypeDependencies } from '../../../../../../../../utils/types';
import ButtonsBlockForm from '../../../../../../../forms/buttonsBlock';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../../../../../utils/validations/parseValidations';
import CreateFromAutocompleteInput from '../../../../../../../incidentTypes/actions/create/CreateFromAutoCompleteInput';
import ReferenceArrayInput from '../../../../../../../../commons/ra/input/ReferenceArrayInput';
import DialogTemplateButtons from '../../../../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';

export const EVENT_TYPES_FIELD = 'eventTypes';

interface FormProps {
    item: SubcategoryEventTypeDependencies;
    handleSave: () => void;
    handleCancel: () => void;
    place: Avatar;
}

const AddEventTypeForm = ({ item, handleCancel, handleSave, place }: FormProps) => {
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);

    const [update] = useUpdate(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES);

    const getData = (): Promise<SubcategoryEventTypeDependencies | null> =>
        dataProvider
            .getList(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, {
                filter: {
                    place: place.id,
                    serialization_groups: ['read_meta', 'ticketing:metaadataavatartype-dependencies:read-eventtypes'],
                    subCategory: item.subCategory.id,
                },
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                sort: {
                    field: 'id',
                    order: 'ASC',
                },
            })
            .then((response: GetListResult<Record>): SubcategoryEventTypeDependencies | null => {
                return 'total' in response && response.total === 1
                    ? (response.data[0] as SubcategoryEventTypeDependencies)
                    : null;
            })
            .catch((error) => {
                console.error(error);

                return null;
            });

    const onSubmit = async (data: { [key: string]: any }) => {
        const subCategoryUri = getResourceURIFROMAnotherURI(
            place.id as string,
            item.subCategory.id as string,
            METADATA_AVATAR_TYPES
        );

        const dataToSendCreate = {
            place: place.id,
            subCategory: subCategoryUri,
        };

        let subCategoryEventTypeDeps: SubcategoryEventTypeDependencies | null | void = null;
        subCategoryEventTypeDeps = await getData().catch((error: any) =>
            console.error('Error getting ', TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, error)
        );
        subCategoryEventTypeDeps = await createDependencies(
            subCategoryEventTypeDeps,
            dataToSendCreate
        ).catch((error: any) =>
            console.error('Error creating  ', TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, error)
        );
        updateDependencies(subCategoryEventTypeDeps, data[EVENT_TYPES_FIELD]);
    };

    const updateDependencies = (
        subCategoryToUpdate: SubcategoryEventTypeDependencies | null | void,
        eventTypes: Identifier[]
    ) => {
        if (!subCategoryToUpdate) {
            handleSave();
            return;
        }

        const dataToSendUpdate: { eventTypes: any } = {
            eventTypes: [
                ...getUriFromItems(subCategoryToUpdate?.eventTypes ? subCategoryToUpdate?.eventTypes : []),
                ...eventTypes,
            ],
        };

        return update(
            TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
            subCategoryToUpdate.id,
            dataToSendUpdate,
            subCategoryToUpdate,
            {
                onSuccess: () => {
                    notify('app.navigation.configuration.incident.eventType.updated.notification', 'success');
                    handleSave();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(
                            `app.navigation.configuration.incident.eventType.updated.${getCodeFromViolationMessage(
                                violation?.message
                            )}`,
                            'error'
                        );
                    } else {
                        notify('app.navigation.configuration.incident.eventType.updated.error', 'error');
                    }
                    handleCancel();

                    console.error(
                        `ERRROR updating, event types ${TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES}`,
                        ...args
                    );
                },
            }
        );
    };

    const createDependencies = async (
        data: SubcategoryEventTypeDependencies | null | void,
        dataToSend: any
    ): Promise<SubcategoryEventTypeDependencies | null> => {
        if (!data) {
            const response = await dataProvider.create(TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, {
                data: dataToSend,
            });

            return 'data' in response ? (response.data as SubcategoryEventTypeDependencies) : null;
        }
        return new Promise((resolve, reject) => resolve(data));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, submitting, valid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <ReferenceArrayInput
                                    validate={[required()]}
                                    reference={METADATA_AVATAR_TYPES}
                                    filterToQuery={(searchText: string) => ({
                                        name: searchText,
                                    })}
                                    filter={{
                                        'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_EVENT,
                                        hidden: false,
                                    }}
                                    source={EVENT_TYPES_FIELD}
                                    label={translate('app.navigation.configuration.incident.eventType.title')}
                                >
                                    <AutocompleteArrayInput
                                        variant={'standard'}
                                        fullWidth
                                        filterToQuery={(search: string) => ({ name: search })}
                                        create={<CreateFromAutocompleteInput />}
                                        // optionText={(record) =>
                                        //     capitalizeFirstLetter(getNameWithoutPrefixFromJson(record))
                                        // }
                                    />
                                </ReferenceArrayInput>
                            </Grid>
                            <Grid item>
                                <DialogTemplateButtons
                                    outside
                                    withCancel={true}
                                    withCreate
                                    cancelButtonLabel={translate('app.cancel')}
                                    onCancelClick={handleCancel}
                                    isSubmitting={submitting}
                                    isValidForm={valid && !pristine}
                                    createButtonLabel={translate('app.add')}
                                />
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        />
    );
};

export default AddEventTypeForm;
