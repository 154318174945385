export const AVATARS = 'avatars';
export const AVATARS_MOVE_TO = 'avatars/move_to';
export const AVATARS_CLONE = 'ticketing/places_or_events/clone';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const METADATA_AVATAR_TYPE_PROPERTIES = 'metadata_avatar_type_properties';
export const GROUP_METADATA_AVATAR_TYPES = 'group_metadata_avatar_types';
export const USERS = 'users';
export const RUNNER_TEMPLATES = 'runner_templates';
export const FINGERS = 'fingers';
export const COMPANIES = 'companies';
export const MEDIAS = 'medias';
export const MEDIA = 'media';
export const IMAGES = 'images';
export const YOURLS = 'yourls';
export const TICKETING_EVENT_USERS = 'ticketing/event_users';
export const TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES = 'ticketing/sub_category_event_types_dependencies';
export const TICKETING_USER_ALLOCATIONS = 'ticketing/user_allocations';
export const AVATAR_PROPERTY = 'avatar_properties';
export const REORDER_AVATAR = 'avatars/reorder';
export const TICKETING_PLACE_OR_EVENT = 'ticketing/places_or_events';
export const TICKETING_PLACE_OR_EVENT_RESET_EVENT = 'ticketing/place_or_events/restart_event';
export const TICKETING_PLACE_OR_EVENT_ROOT_EVENT = 'ticketing/places_or_events/root_event';
export const TICKETING_PROCESS_CUSTOMIZATIONS = 'ticketing/process_customizations';
export const AVATAR_TRACEABILITY = 'traceability/current_referencing';
export const TRACEABILITY_TRACKING = 'traceability/tracking';
export const AVATAR_TRACEABILITY_NOTES = 'avatar_traceability_notes';
export const TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS = 'ticketing/user_provider_event_configurations';
export const TICKETING_MANAGER_NOTIFICATIONS = 'ticketing/manager_notifications';
export const TICKETING_MANAGER_PLACES = 'ticketing/manager_places';
export const LANGUAGES = 'languages';
export const PROFILES = 'profiles';
export const MEDIA_LINKS = 'media_links';
export const MEDIA_LINKS_FOR_CREATE = 'medias/link';
export const USER_PASSWORD_FORGOTTEN = 'users/password_forgotten';
export const TICKETING_EVENT_INSTRUCTION_HELPS = 'ticketing/event_instruction_helps';
