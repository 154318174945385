import DialogTemplate from '../Dialog/DialogTemplate/DialogTemplate';
import SettingsIcon from '@material-ui/icons/Settings';
import Grid from '@material-ui/core/Grid';
import ButtonsBlockForm from '../../components/forms/buttonsBlock';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { IncidentStatus } from '../../utils/CONST';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/styles/makeStyles';
import { APP_COLOR_PRIMARY } from '../../theme';

const useStyles = makeStyles({
    chipSelected: {
        marginLeft: 5,
        marginBottom: 5,
        backgroundColor: APP_COLOR_PRIMARY,
        color: 'white',
        '&:hover': {
            backgroundColor: APP_COLOR_PRIMARY,
            color: 'white',
        },
        '&:clickable': {
            backgroundColor: APP_COLOR_PRIMARY,
            color: 'white',
        },
        '&:focus': {
            backgroundColor: APP_COLOR_PRIMARY,
            color: 'white',
        },
    },
    chip: {
        marginLeft: 5,
        marginBottom: 5,
    },
});
interface Props {
    initialValues: string[] | null;
    dialogTitle: string;
    handleValidate: (status: string[]) => void;
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    thirdButtonAction: () => void;
    thirdButtonLabel: string;
}

const ShowIncidentStatusDialog = ({
    dialogTitle,
    handleValidate,
    onClose,
    open,
    onCancel,
    initialValues,
    thirdButtonAction,
    thirdButtonLabel,
}: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [statusSelected, setStatusSelected] = useState<string[]>(initialValues ? initialValues : []);

    const onSelectChip = (index: number) => () => {
        if (isChipSelected(index)) {
            removeChipSelected(index);
            return;
        }
        const newStatusSelected = [...statusSelected, Object.keys(IncidentStatus)[index]];
        setStatusSelected(newStatusSelected);
    };

    const isChipSelected = (index: number) => {
        return statusSelected.includes(Object.keys(IncidentStatus)[index]);
    };

    const removeChipSelected = (index: number) => {
        const indexStatusSelected = statusSelected.findIndex((status) => Object.keys(IncidentStatus)[index] === status);
        if (indexStatusSelected === -1) return;
        delete statusSelected[indexStatusSelected];
        const newStatusSelected = statusSelected.filter((status) => status !== undefined);
        setStatusSelected(newStatusSelected);
    };

    return (
        <DialogTemplate
            open={open}
            title={translate(dialogTitle)}
            onClose={onClose}
            icon={<SettingsIcon />}
            maxWidth={'md'}
        >
            {Object.values(IncidentStatus).map((label: string, index: number) => (
                <Chip
                    key={`chip_${index}`}
                    label={translate(label)}
                    onClick={onSelectChip(index)}
                    // onDelete={removeChipSelected(index)}
                    className={isChipSelected(index) ? classes.chipSelected : classes.chip}
                />
            ))}

            <Grid item style={{ paddingTop: 8 }}>
                <ButtonsBlockForm
                    onValid={() => {
                        handleValidate(statusSelected);
                    }}
                    disableValidButton={!statusSelected}
                    validButtonIsSubmitType={false}
                    labelValidButton={'app.valid'}
                    onCancel={onCancel}
                    thirdButtonAction={thirdButtonAction}
                    thirdButtonLabel={translate(thirdButtonLabel)}
                />
            </Grid>
        </DialogTemplate>
    );
};

export default ShowIncidentStatusDialog;
