import { Identifier, ReduxState } from 'react-admin';
import { MetadataAvatarType } from '../../utils/types';
import { CustomReducerState } from './index';
import { METADATA_AVATAR_TYPES } from '../../providers/resources';
import { selectSubCategory, SelectSubCategoryAction } from '../actions/subCategories';
import { clearTicketingStateCreator } from '../actions/ticketingState';

export interface SubCategoryState {
    selected: {
        [key: Identifier]: MetadataAvatarType;
    };
}

const InitialState: SubCategoryState = {
    selected: {},
};

export const getSubCategorySelected = (state: CustomReducerState & ReduxState): MetadataAvatarType | undefined => {
    const selected = state.ticketing.subCategories.selected;
    const selectedUriKey = selected ? Object.keys(selected) : [];
    if (
        selected &&
        selectedUriKey.length > 0 &&
        state.admin.resources[METADATA_AVATAR_TYPES].list.ids.includes(selectedUriKey[0])
    ) {
        return state.admin.resources[METADATA_AVATAR_TYPES].data[selectedUriKey[0]] as MetadataAvatarType;
    }
    return undefined;
};

const reducer = (state: SubCategoryState = InitialState, action: any) => {
    switch (action.type) {
        case selectSubCategory.action: {
            const iAction = action as SelectSubCategoryAction;
            const newRecord = iAction.payload.record
                ? {
                      // @ts-ignore
                      [iAction.payload.record.id]: {
                          ...iAction.payload.record,
                      },
                  }
                : {};

            return {
                ...state,
                selected: {
                    ...newRecord,
                },
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
