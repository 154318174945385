import React, { useEffect, useState } from 'react';
import CreateSubCategoryForm from './index';
import { useCreateSuggestionContext } from 'react-admin';

const CreateFromAutocompleteInput = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value] = React.useState(filter || '');
    const [open, setOpen] = useState(true);

    const onClose = () => {
        setOpen(false);
        onCancel();
    };

    return (
        <CreateSubCategoryForm
            initialName={value}
            fromExternalInput={true}
            open={open}
            handleClose={onClose}
            handleCreateOnSuccess={onCreate}
        />
    );
};
export default CreateFromAutocompleteInput;
