import React, { useState } from 'react';
import { Avatar } from '../../utils/types';

const useActionsController = () => {
    const [moveActionData, setMoveActionData] = useState<{
        active: boolean;
        item: Avatar | null;
        to: Avatar | null;
    }>({
        active: false,
        item: null,
        to: null,
    });

    const [reorderActionData, setReorderActionData] = useState<{
        active: boolean;
        item: Avatar | null;
        to: Avatar | null;
    }>({
        active: false,
        item: null,
        to: null,
    });

    const [cloneActionData, setCloneActionData] = useState<{
        active: boolean;
        item: Avatar | null;
        to: Avatar | null;
    }>({
        active: false,
        item: null,
        to: null,
    });

    const handleCancelMoveTo = () => {
        setMoveActionData({
            active: false,
            item: null,
            to: null,
        });
    };

    const handleCancelClone = () => {
        setCloneActionData({
            active: false,
            item: null,
            to: null,
        });
    };

    const handleCancelReorder = () => {
        setReorderActionData({
            active: false,
            item: null,
            to: null,
        });
    };

    const setMoveActionState = (active: boolean) => {
        setMoveActionData((previousData) => ({ ...previousData, active }));
    };

    const setItemToMoveAction = (item: Avatar) => {
        setMoveActionData((previousData) => ({ ...previousData, item }));
    };

    const setItemMoveToAction = (to: Avatar) => {
        setMoveActionData((previousData) => ({ ...previousData, to }));
    };

    const setCloneActionState = (active: boolean) => {
        setCloneActionData((previousData) => ({ ...previousData, active }));
    };

    const setItemToCloneAction = (item: Avatar) => {
        setCloneActionData((previousData) => ({ ...previousData, item }));
    };

    const setItemCloneToAction = (to: Avatar) => {
        setCloneActionData((previousData) => ({ ...previousData, to }));
    };

    const setReorderActionState = (active: boolean) => {
        setReorderActionData((previousData) => ({ ...previousData, active }));
    };

    const setItemToReorderAction = (item: Avatar) => {
        setReorderActionData((previousData) => ({ ...previousData, item }));
    };

    const setItemReorderToAction = (to: Avatar) => {
        setReorderActionData((previousData) => ({ ...previousData, to }));
    };

    return {
        moveActionState: moveActionData.active,
        itemToMove: moveActionData.item,
        moveTo: moveActionData.to,
        cloneActionState: cloneActionData.active,
        itemToClone: cloneActionData.item,
        cloneTo: cloneActionData.to,
        reorderActionState: reorderActionData.active,
        reorderTo: reorderActionData.to,
        itemToReorder: reorderActionData.item,
        handleCancelMoveTo: handleCancelMoveTo,
        handleCancelClone: handleCancelClone,
        handleCancelReorder: handleCancelReorder,
        setMoveActionState: setMoveActionState,
        setItemToMoveAction: setItemToMoveAction,
        setItemMoveToAction: setItemMoveToAction,
        setCloneActionState: setCloneActionState,
        setItemToCloneAction: setItemToCloneAction,
        setItemCloneToAction: setItemCloneToAction,
        setReorderActionState: setReorderActionState,
        setItemToReorderAction: setItemToReorderAction,
        setItemReorderToAction: setItemReorderToAction,
    };
};

export default useActionsController;
