import React from 'react';
import { ListContextProvider, ReduxState, useListController } from 'react-admin';
import { useSelector } from "react-redux";
import {
    TICKETING_EVENT_INSTRUCTION_HELPS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS
} from '../../../../providers/resources';
import { MetadataAvatarType } from '../../../../utils/types';
import InstructionsDialog from './index';

interface Props {
    open: boolean;
    onClose: any;
    subCategory: MetadataAvatarType;
}

const InstructionsContainer = ({ subCategory, open, onClose }: Props) => {
    const reduxStateData = useSelector((state: ReduxState) => ({
        data: state.admin.resources[TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS].data,
        ids: state.admin.resources[TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS].list.ids
    }))

    const listProps = {
        resource: TICKETING_EVENT_INSTRUCTION_HELPS,
        syncWithLocation: false,
        basePath: '',
        perPage: 50,
        filter: {
            subCategory: subCategory['@id'],
        },
        data: reduxStateData.data,
        ids: reduxStateData.ids
    };

    return (
        <ListContextProvider value={useListController(listProps)}>
            <InstructionsDialog subCategory={subCategory} open={open} onClose={onClose} />
        </ListContextProvider>
    );
};

export default InstructionsContainer;
