import { styled } from '@material-ui/core/styles';
import MUIAvatar from '@material-ui/core/Avatar';
import { findMediaByImage, MediaStateProp } from '../../../redux/reducers/medias';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { User } from '../../../utils/types';
import { getDownloadFileSystemMapperUrl } from '../../../utils/medias';
import React, { useEffect } from 'react';
import { resolveMedia as resolveMediaAction } from '../../../redux/actions/medias';
import { Typography } from '@material-ui/core';

interface Props {
    user: User;
}

const CustomMUIAvatar = styled(MUIAvatar)({
    height: 20,
    width: 20,
});

const CustomMUIAvatarTypography = styled(Typography)({
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
});

const schemaAndHostUrlRootServer: string | undefined = process.env.REACT_APP_ASSETS_ROOT;

const UserLogoAvatar = (props: Props) => {
    const dispatch = useDispatch();

    const currentMediaInState: MediaStateProp | undefined = useSelector((state: CustomReducerState) => {
        if (props.user && 'image' in props.user && props.user?.image) {
            return findMediaByImage(state, props.user.image);
        }
    });

    useEffect(() => {
        if (props.user.image && !currentMediaInState?.media) {
            let imageUri =
                props.user.image && typeof props.user.image === 'object' && 'id' in props.user.image
                    ? props.user.image.id
                    : null;
            imageUri = typeof props.user.image === 'string' ? props.user.image : imageUri;
            if (imageUri) {
                dispatch(resolveMediaAction.fn(imageUri, props.user.id));
            }
        }
    }, [props.user.image]);

    return (
        <>
            {currentMediaInState && currentMediaInState?.media && currentMediaInState.fileSystemMapper ? (
                <CustomMUIAvatar
                    src={getDownloadFileSystemMapperUrl(
                        currentMediaInState.media,
                        currentMediaInState.fileSystemMapper
                    )}
                />
            ) : (
                <CustomMUIAvatar>
                    <CustomMUIAvatarTypography>
                        {props.user.firstName.substring(0, 1)}
                        {props.user.lastName.substring(0, 1)}
                    </CustomMUIAvatarTypography>
                </CustomMUIAvatar>
            )}
        </>
    );
};

export default UserLogoAvatar;
