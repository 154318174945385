export const REFRESH_TOKEN_ROUTE_PARAMS = 'refreshToken';
export const REDIRECT_TO_QUERY_PARAMS = 'redirectTo';
export const help_no_token_word = 'no-token';

export const index = `${process.env.REACT_APP_PREFIX}c/:${REFRESH_TOKEN_ROUTE_PARAMS}`;
export const index_no_token = `${process.env.REACT_APP_PREFIX}c/${help_no_token_word}`;

export const login = 'login';
export const main = 'navigation';
export const incidentsNameRoute = 'incidents';
export const usersNameRoute = 'users';
export const incidentTypeNameRoute = 'incidentTypes';
export const subCategoryNameRoute = 'subCategories';
export const servicesProvidersNameRoute = 'servicesProviders';
export const categoryLevelNameRoute = 'categoriesLevel';
export const categoryEventNameRoute = 'categoriesEvent';
export const statisticsNameRoute = 'statistics';

// export const buildings = `${process.env.REACT_APP_PREFIX}p/buildings`;
// export const placeTypes = `${process.env.REACT_APP_PREFIX}p/placeTypes`;
// export const equipmentTypes = `${process.env.REACT_APP_PREFIX}p/equipmentTypes`;
// export const placesAndEquipments = `${process.env.REACT_APP_PREFIX}p/placesAndEquipments`;
export const incidentTypes = `${process.env.REACT_APP_PREFIX}p/${incidentTypeNameRoute}`;
export const categoriesLevel = `${process.env.REACT_APP_PREFIX}p/${categoryLevelNameRoute}`;
export const categoriesEvent = `${process.env.REACT_APP_PREFIX}p/${categoryEventNameRoute}`;
export const subCategories = `${process.env.REACT_APP_PREFIX}p/${subCategoryNameRoute}`;
export const navigation = `${process.env.REACT_APP_PREFIX}p/${main}`;
export const incidents = `${process.env.REACT_APP_PREFIX}p/${incidentsNameRoute}`;
export const servicesProviders = `${process.env.REACT_APP_PREFIX}p/${servicesProvidersNameRoute}`;
export const statistics = `${process.env.REACT_APP_PREFIX}p/${statisticsNameRoute}`;
export const users = `${process.env.REACT_APP_PREFIX}p/${usersNameRoute}`;
export const refreshPage = `${process.env.REACT_APP_PREFIX}c`;
