import React, { useEffect, useState } from 'react';
import {
    GetListResult,
    Identifier,
    Record,
    ReduxState,
    translate,
    useDataProvider,
    useNotify,
    useTranslate,
} from 'react-admin';
import {
    Avatar,
    PlaceOrEventPath,
    TicketingEventUser,
    TicketingUserProviderEventConfiguration,
    User,
} from '../../../utils/types';
import { useSelector } from 'react-redux';
import {
    TICKETING_EVENT_USERS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../providers/resources';
import { getTicketingUserProviderEventConfig } from '../../../utils/user/resolutionDeadline';
import Typography from '@material-ui/core/Typography';
import moment, { Moment } from 'moment';
import { CustomReducerState } from '../../../redux/reducers';
import { TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG } from '../../../utils/CONST';
import { capitalizeFirstLetter } from '../../../utils/strings';

interface Props {
    event: Avatar | undefined;
}

const CountdownField = ({ event }: Props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();

    const [userWorkResolutionDelay, setUserWorkResolutionDelay] = useState<number | null>(null);
    const [countdown, setCountdown] = useState<string | null>(null);

    const [elapseTimeFromAssigned, setElapseTimeFromAssigned] = useState<string | null>(null);

    const placesOrEventsPath: PlaceOrEventPath[] = useSelector((state: CustomReducerState) =>
        event && event?.id in state.ticketing.placesOrEventsPath ? state.ticketing.placesOrEventsPath[event.id] : []
    );

    const ticketingEventUser: Record | undefined = useSelector((state: ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_EVENT_USERS].data).find(
            (datum) => (datum as TicketingEventUser).event === event?.id
        );
    });

    const user: Record | undefined = useSelector((state: ReduxState) => {
        return ticketingEventUser
            ? Object.values(state.admin.resources[USERS].data).find(
                  (datum) =>
                      (datum as User).id === (ticketingEventUser as TicketingEventUser).user &&
                      (datum as User).tags?.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG)
              )
            : undefined;
    });

    const ticketingUserProviderEventConfig: Record | undefined = useSelector((state: ReduxState) => {
        return user
            ? Object.values(state.admin.resources[TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS].data).find(
                  (datum) => (datum as TicketingUserProviderEventConfiguration).user === ((user as User).id as string)
              )
            : undefined;
    });

    const getWorkResolutionDelay = (user: User, incident: Avatar, placeId: Identifier) => {
        const onSuccess = (response: GetListResult<Record>) => {
            if (response && 'data' in response && response.data && response.data.length === 1) {
                setUserWorkResolutionDelay(
                    (response.data[0] as TicketingUserProviderEventConfiguration).workResolutionDelay
                );
            }
        };
        const onFailure = (error: any) => {
            console.error('Getting', TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, error);
            notify('app.incidents.requestWorkResolutionDelay.get.error', 'error');
        };
        getTicketingUserProviderEventConfig(
            dataProvider,
            user,
            incident,
            incident.metadataAvatarType.id,
            placeId,
            onSuccess,
            onFailure
        );
    };

    useEffect(() => {
        if (!ticketingUserProviderEventConfig && user && event && placesOrEventsPath && placesOrEventsPath.length > 0) {
            getWorkResolutionDelay(user as User, event, placesOrEventsPath[0].id);
        }
        if (ticketingUserProviderEventConfig) {
            setUserWorkResolutionDelay(
                (ticketingUserProviderEventConfig as TicketingUserProviderEventConfiguration).workResolutionDelay
            );
        }
    }, [event, ticketingUserProviderEventConfig, user, placesOrEventsPath]);

    useEffect(() => {
        if (!ticketingEventUser || !userWorkResolutionDelay) return;

        let globalDelay: Moment = moment((ticketingEventUser as TicketingEventUser).updatedAt).add(
            userWorkResolutionDelay,
            'hours'
        );

        if (userWorkResolutionDelay >= 1) {
            globalDelay = moment((ticketingEventUser as TicketingEventUser).updatedAt).add(
                userWorkResolutionDelay,
                'hours'
            );
        } else if (userWorkResolutionDelay > 0 && userWorkResolutionDelay < 1) {
            globalDelay = moment((ticketingEventUser as TicketingEventUser).updatedAt).add(
                userWorkResolutionDelay * 100,
                'minutes'
            );
        }

        if (moment().isBefore(globalDelay)) {
            setElapseTimeFromAssigned(moment().to(globalDelay));
        } else {
            setElapseTimeFromAssigned(
                `${capitalizeFirstLetter(translate('app.incidents.delay'))} ${globalDelay.from(moment(), true)}`
            );
        }
    }, [ticketingEventUser, userWorkResolutionDelay]);

    return (
        <span>
            <Typography variant={'body2'}>{elapseTimeFromAssigned}</Typography>
        </span>
    );
};

export default CountdownField;
