import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Record, ReduxState, useDelete, useNotify } from 'react-admin';
import { ActionFormButtonStyles, APP_COLOR_PRIMARY } from '../../../theme';
import makeStyles from '@material-ui/styles/makeStyles';
import ButtonsBlockForm from '../../../components/forms/buttonsBlock';
import GlobalForm from '../../../components/forms';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { Avatar } from '../../../utils/types';
import { MappingItem } from '../../../redux/reducers/ticketingSubcategoryEventTypesDependenciesNavigation';

interface Props {
    place: Avatar;
    open: boolean;
    handleClose: (itemDeleted: boolean) => void;
    resource: string;
    item: Record;
    title?: string;
    subtitle?: string;
    onSuccessMessage: string;
    onFailureMessage: string;
}

const useStyles = makeStyles(() => ({
    deleteIcon: {
        color: APP_COLOR_PRIMARY,
    },
    deleteIconDisabled: {
        color: 'gray',
    },
    addButtonRoot: {
        ...ActionFormButtonStyles.root,
    },
    addButtonRootText: {
        ...ActionFormButtonStyles.root,
    },
    gridItemTitle: {
        marginTop: 4,
        marginBottom: 4,
    },
    buttonDelete: {
        backgroundColor: APP_COLOR_PRIMARY,
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 16,
        '&:hover': {
            backgroundColor: APP_COLOR_PRIMARY,
            paddingLeft: 21,
            paddingRight: 21,
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    textButtonDelete: {
        color: 'white',
        fontWeight: 'bold',
    },
    gridButtonBlock: {
        paddingTop: 20,
    },
}));

const DeleteFormDialog = ({
    place,
    open,
    handleClose,
    title,
    subtitle,
    resource,
    item,
    onFailureMessage,
    onSuccessMessage,
}: Props) => {
    const classes = useStyles();
    const notify = useNotify();

    const [remove] = useDelete(resource);

    const itemIdToRemove: MappingItem | null = useSelector((state: CustomReducerState & ReduxState) => {
        const ticketingSubcategoryEventTypesDependenciesInNavigation: any =
            state.ticketing.ticketingSubcategoryEventTypesDependenciesInNavigation
                .mappingTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn;
        return place.id in ticketingSubcategoryEventTypesDependenciesInNavigation &&
            item.id in ticketingSubcategoryEventTypesDependenciesInNavigation[place.id]
            ? ticketingSubcategoryEventTypesDependenciesInNavigation[place.id][item.id]
            : null;
    });

    const onDelete = () => {
        if (itemIdToRemove == null) return;
        remove(
            resource,
            itemIdToRemove.idShown,
            { id: itemIdToRemove.idShown },
            {
                onSuccess: () => {
                    notify(onSuccessMessage, 'info');
                    handleClose(true);
                },
                onFailure: (error: any) => {
                    notify(onFailureMessage, 'warning');
                    console.error('ERRROR when deleting', resource, error);
                    handleClose(false);
                },
            }
        );
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby='form-dialog-title'>
            <DialogContent>
                <GlobalForm title={title} subtitle={subtitle} titleIcon={<DeleteIcon color={'secondary'} />} />
                <Grid container justifyContent={'flex-end'} className={classes.gridButtonBlock}>
                    <ButtonsBlockForm
                        validButtonIsSubmitType={false}
                        onValid={onDelete}
                        showValidButton={itemIdToRemove != null && itemIdToRemove.toDelete}
                        showCancelButton={true}
                        onCancel={() => handleClose(false)}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default DeleteFormDialog;
