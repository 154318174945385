import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    CreateResult,
    Identifier,
    Record,
    ReduxState,
    required,
    TextInput,
    UpdateResult,
    useCreate,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import useCreateLanguage from '../../../../hooks/useCreateLanguages';
import { incidentTypes } from '../../../../pages/paths';
import {
    GROUP_METADATA_AVATAR_TYPES,
    LANGUAGES,
    MEDIA_LINKS,
    MEDIA_LINKS_FOR_CREATE,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
} from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import {
    GROUP_METADATA_AVATAR_TYPE_EVENT,
    TAG_TICKETING_LOGO,
    TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD,
    TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD,
} from '../../../../utils/CONST';
import {
    ASK_CODE_PIN_FIELD,
    ASK_INCIDENT_CREATOR_EMAIL_FIELD,
    CATEGORY_FIELD,
    CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER,
    CUSTOM_FIELDS_ICON_SVG_COLLECTION,
    FORCE_IMAGE_FIELD,
    FORCE_TEXT_FIELD,
    ICON_FIELD,
    ICON_SVG_COLLECTION_FIELD,
    IMAGE_FIELD,
    NAME_FIELD,
    NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD,
    NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD,
    REPEATABLE_FIELD,
    SVG_ICON_SYSTEM,
    WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD,
} from '../../../../utils/metadataAvatarType';
import {
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT,
    EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE,
    eventTypeCustomProcessFileSystemMapperEnabledLanguages,
    eventTypeCustomProcessForceImageLanguages,
    eventTypeCustomProcessForceTextLanguages,
} from '../../../../utils/properties/metadataAvatarTypeProperties';
import { getCleanMediaName } from '../../../../utils/strings';
import { Language, Media, MetadataAvatarType, MetadataAvatarTypeProperty, User } from '../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import CreateFromAutocompleteInput from '../../../categoriesEvent/actions/create/CreateFromAutoCompleteInput';
import BooleanInputEventRepeatable from '../../../../commons/inputs/BooleanInputEventRepeatable';
import ImageIconSelect from '../../../image-icon-select/image-icon-select';

const collection = process.env.REACT_APP_ICONS_COLLECTION;

interface EditFormProps {
    loadImage: boolean;
    imageSrc?: string;
    currentMedia?: Media;
    selected: MetadataAvatarType;
    updateAndResolveImage: (data: any, justRemove?: boolean) => Promise<void>;
    onCancel: () => void;
    submitFinish: boolean;
    removingMedia: boolean;
    savingMedia: boolean;
    handleFinish: any;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const URL_KEY = 'url';

const EditForm = ({
    selected,
    loadImage,
    imageSrc,
    currentMedia,
    updateAndResolveImage,
    onCancel,
    removingMedia,
    submitFinish,
    savingMedia,
    handleFinish,
}: EditFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [icon, setIcon] = useState<any>(null);
    const [create, { loading: loadingCreate }] = useCreate();
    const [update, { loading: loadingUpdate }] = useUpdate();
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );

    const [updateMetadataAvatarType, { loading }] = useUpdate(METADATA_AVATAR_TYPES);
    const [updateMetadataAvatarTypeProperties] = useUpdate(METADATA_AVATAR_TYPE_PROPERTIES);

    const propertiesDataInState = useSelector(
        (state: ReduxState) => state.admin.resources[METADATA_AVATAR_TYPE_PROPERTIES].data
    );
    const forceTextPropertyInState: Record | undefined = Object.values(propertiesDataInState).find(
        (property) =>
            (property as MetadataAvatarTypeProperty).metadataAvatarType === selected.id &&
            (property as MetadataAvatarTypeProperty).name.toLowerCase() ===
                EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT.toLowerCase()
    );

    const forceImagePropertyInState: Record | undefined = Object.values(propertiesDataInState).find(
        (property) =>
            (property as MetadataAvatarTypeProperty).metadataAvatarType === selected.id &&
            (property as MetadataAvatarTypeProperty).name.toLowerCase() ===
                EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE.toLowerCase()
    );

    const [initialForceTextProperty, setInitialForceTextProperty] = useState<boolean | undefined>(
        forceTextPropertyInState
            ? JSON.parse((forceTextPropertyInState as MetadataAvatarTypeProperty).values[0])
            : undefined
    );

    const [initialForceImageProperty, setInitialForceImageProperty] = useState<boolean | undefined>(
        forceImagePropertyInState
            ? JSON.parse((forceImagePropertyInState as MetadataAvatarTypeProperty).values[0])
            : undefined
    );

    const findLogoInMedias = (medias: any[]) => {
        let logo = null;

        medias.map((media: any) => {
            if (
                typeof media === 'object' &&
                'customFields' in media &&
                'type' in media.customFields &&
                media.customFields.type === TAG_TICKETING_LOGO
            ) {
                logo = media;
            }
        });

        return logo;
    };

    useEffect(() => {
        if (selected) {
            const medias = 'medias' in selected ? selected.medias : [];
            const logo: any = findLogoInMedias(medias);
            if (logo) {
                setIcon(logo);
            }
        }
    }, [selected]);

    const findLogo = () => {
        const medias = 'medias' in selected ? selected.medias : [];
        const logo: any = findLogoInMedias(medias);
        if (logo) {
            const { fileType } = logo.customFields;
            const { name } = logo;
            return `${name}.${fileType}`;
        }

        return null;
    };

    useEffect(() => {
        if (forceTextPropertyInState === undefined) {
            getProperties(selected.id as string, EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT, (value: boolean) =>
                setInitialForceTextProperty(value)
            );
        }
        if (forceImagePropertyInState === undefined) {
            getProperties(selected.id as string, EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE, (value: boolean) =>
                setInitialForceImageProperty(value)
            );
        }
    }, [forceTextPropertyInState, forceImagePropertyInState]);

    /**Create and get languages*/
    for (const key of Object.keys(eventTypeCustomProcessForceTextLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessForceTextLanguages[key]);
    }
    for (const key of Object.keys(eventTypeCustomProcessForceImageLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessForceImageLanguages[key]);
    }
    for (const key of Object.keys(eventTypeCustomProcessFileSystemMapperEnabledLanguages)) {
        // @ts-ignore
        useCreateLanguage(key, eventTypeCustomProcessFileSystemMapperEnabledLanguages[key]);
    }

    /**Get properties from state*/
    const getLanguagesFromState = (propertyLanguages: { en: string; fr: string; es: string }) => {
        const languagesData = useSelector((state: ReduxState) => state.admin.resources[LANGUAGES].data);
        return Object.values(languagesData)
            .map((item: Record) => {
                for (const key of Object.keys(propertyLanguages)) {
                    if (
                        (item as Language).twoLetters.toLowerCase().trim() === key.toLowerCase().trim() &&
                        (item as Language).value.toLowerCase().trim() ===
                            // @ts-ignore
                            propertyLanguages[key].toLowerCase().trim()
                    ) {
                        return (item as Language).id;
                    }
                }
            })
            .filter((item) => item !== undefined);
    };

    const eventTypeCustomProcessForceImageLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessForceImageLanguages
    );

    const eventTypeCustomProcessForceTextLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessForceTextLanguages
    );

    const eventTypeCustomProcessFileSystemMapperEnabledLanguagesInState = getLanguagesFromState(
        eventTypeCustomProcessFileSystemMapperEnabledLanguages
    );

    const handleUpdateMetadataAvatarType = (
        metadataAvatarTypeId: any,
        data: any,
        previousData: MetadataAvatarType,
        onSuccessCallback: any
    ) => {
        update(METADATA_AVATAR_TYPES, metadataAvatarTypeId, data, previousData, {
            onSuccess: () => {
                notify('app.incidentTypes.edit.success', 'success');
                onSuccessCallback();
            },
            onFailure: (error: any) => {
                console.log('error', error);
                notify('app.incidentTypes.edit.error', 'error');
            },
        });
    };

    const handleCreateIconMedia = (data: any, onSuccessCallback: any) => {
        create(MEDIA_LINKS_FOR_CREATE, data, {
            onSuccess: ({ data }: CreateResult) => {
                onSuccessCallback(data);
            },
            onFailure: (error) => {
                console.log('error', error);
                notify('app.subCategory.edit.errors.iconNotAdded', 'error');
            },
        });
    };

    const filterMedias = (medias: any[], iconId: string) => {
        const newMedias = [...medias.map((media) => media['@id'])];
        const oldIconIndex = newMedias.findIndex((id) => id.includes(MEDIA_LINKS));
        if (oldIconIndex !== -1) {
            newMedias[oldIconIndex] = iconId;
        } else {
            newMedias.push(iconId);
        }
        return newMedias;
    };

    const filterMediasToDelete = (medias: any[]) => {
        const newMedias = [...medias.map((media) => media['@id'])];
        return newMedias.filter((id) => !id.includes(MEDIA_LINKS));
    };

    const handleSubmit = (formValues: {
        [NAME_FIELD]: string;
        [CATEGORY_FIELD]: string;
        [IMAGE_FIELD]: { fileName: string; fileData: string; cropping?: boolean };
        [REPEATABLE_FIELD]: string;
        [WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]: boolean;
        [FORCE_IMAGE_FIELD]: boolean;
        [FORCE_TEXT_FIELD]: boolean;
        [ICON_SVG_COLLECTION_FIELD]: boolean;
        [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]: boolean;
        [ASK_INCIDENT_CREATOR_EMAIL_FIELD]: boolean;
        [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]: boolean;
        [ASK_CODE_PIN_FIELD]: boolean;
        [ICON_FIELD]: string;
    }) => {
        let newData: any = {
            alphaId: !selected.alphaId ? moment().toString() : selected.alphaId,
            name: formValues[NAME_FIELD],
            groupMetadataAvatarType: formValues[CATEGORY_FIELD],
            customFields: {
                [TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD]: formValues[REPEATABLE_FIELD] ? '*' : 1,
                [TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD]: formValues[
                    REPEATABLE_FIELD
                ]
                    ? formValues[WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]
                    : false,
                [CUSTOM_FIELDS_ICON_SVG_COLLECTION]: formValues[ICON_SVG_COLLECTION_FIELD],
                [CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER]: !formValues[ICON_SVG_COLLECTION_FIELD],
                [ASK_INCIDENT_CREATOR_EMAIL_FIELD]: formValues[ASK_INCIDENT_CREATOR_EMAIL_FIELD],
                [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]: formValues[NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD],
                [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]:
                    formValues[NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD],
                [ASK_CODE_PIN_FIELD]: formValues[ASK_CODE_PIN_FIELD],
            },
        };

        const iconInitValue = hasIcon() ? findLogo() : null;
        const currentIconNameValue = ICON_FIELD in formValues ? formValues[ICON_FIELD] : null;
        const iconEdited = iconInitValue !== currentIconNameValue;

        const handleIcon = (data: any, iconEdited: boolean, currentIconNameValue: string | null) => {
            const updateFn = (data: any) => {
                handleUpdateMetadataAvatarType(selected?.id, data, selected, () => {
                    handleFinish();
                });
            };

            const deleteFn = (mediaId: string, onSuccess: () => void) => {
                dataProvider.delete(
                    MEDIA_LINKS,
                    { id: mediaId },
                    {
                        onSuccess: () => {
                            onSuccess();
                        },
                        onFailure: (error) => {
                            console.log('error', error);
                            notify('app.subCategory.edit.errors.iconNotRemoved', 'error');
                        },
                    }
                );
            };

            const createIconMedia = () => {
                if (currentIconNameValue) {
                    handleCreateIconMedia(
                        JSON.stringify({
                            fileType: 'svg',
                            name: currentIconNameValue.split('.')[0],
                            customFields: {
                                collection,
                                type: TAG_TICKETING_LOGO,
                            },
                            destinationCompany: userConnected?.company['@id'],
                            source: SVG_ICON_SYSTEM,
                        }),
                        (createdIcon: any) => {
                            newData = { ...data, medias: filterMedias(selected.medias, createdIcon['@id']) };
                            updateFn(newData);
                        }
                    );
                } else {
                    newData = { ...data, medias: filterMediasToDelete(selected.medias) };
                    updateFn(newData);
                }
            };

            if (iconEdited) {
                if (icon) deleteFn(icon['@id'], createIconMedia);
                else {
                    createIconMedia();
                }
            } else {
                updateFn(newData);
            }
        };

        const handleMedia = (justRemoveImage?: boolean) => {
            updateMetadataAvatarType(METADATA_AVATAR_TYPES, selected?.id, newData, selected, {
                onSuccess: async () => {
                    await updateAndResolveImage(formValues, justRemoveImage);
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.messages.imageNotRemoved', 'warning');
                    }
                },
            });
        };

        const previousIconValue: boolean =
            selected && 'customFields' in selected && CUSTOM_FIELDS_ICON_SVG_COLLECTION in selected.customFields
                ? selected.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                : false;
        const currentIconValue = formValues[ICON_SVG_COLLECTION_FIELD];

        if (currentIconValue) {
            if (previousIconValue !== currentIconValue) {
                handleMedia(true);
            }
            handleIcon(newData, iconEdited, currentIconNameValue);
        } else {
            if (previousIconValue !== currentIconValue) {
                handleIcon(newData, true, null);
            }
            handleMedia();
        }

        if (
            !(
                formValues[FORCE_IMAGE_FIELD] === initialForceImageProperty &&
                formValues[FORCE_TEXT_FIELD] === initialForceTextProperty
            )
        ) {
            createOrUpdateMetadataAvatarTypeProperties(formValues[FORCE_IMAGE_FIELD], formValues[FORCE_TEXT_FIELD]);
        }
    };

    const createOrUpdateMetadataAvatarTypeProperties = (forceImage: boolean, forceText: boolean) => {
        // update forceImageProperty if the state value is different to value entered by user

        if (
            forceImagePropertyInState &&
            JSON.parse((forceImagePropertyInState as MetadataAvatarTypeProperty).values[0]) !== forceImage
        ) {
            updateMetadataAvatarTypeProperties(
                METADATA_AVATAR_TYPE_PROPERTIES,
                forceImagePropertyInState.id,
                {
                    id: forceImagePropertyInState.id,
                    values: [forceImage ? 'true' : 'false'],
                },
                {
                    ...forceImagePropertyInState,
                },
                {
                    onSuccess: (response: UpdateResult) => {
                        if (response && 'data' in response && response.data) {
                            refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.updateForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                (forceImagePropertyInState as MetadataAvatarTypeProperty).name.toLowerCase() ===
                                EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE.toLowerCase()
                                    ? translate('app.incidentTypes.create.input.forceImage')
                                    : translate('app.incidentTypes.create.input.forceText'),
                        });
                    },
                }
            );
        } else if (!forceImagePropertyInState) {
            createMetadataAvatarTypeProperty(
                selected.id as string,
                EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE,
                [forceImage ? 'true' : 'false'],
                eventTypeCustomProcessForceImageLanguagesInState
            );
        }

        // update forceTextProperty if the state value is different to value entered by user
        if (
            forceTextPropertyInState &&
            JSON.parse((forceTextPropertyInState as MetadataAvatarTypeProperty).values[0]) !== forceText
        ) {
            updateMetadataAvatarTypeProperties(
                METADATA_AVATAR_TYPE_PROPERTIES,
                forceTextPropertyInState.id,
                {
                    id: forceTextPropertyInState.id,
                    values: [forceText ? 'true' : 'false'],
                },
                {
                    ...forceTextPropertyInState,
                },
                {
                    onSuccess: (response: UpdateResult) => {
                        if (response && 'data' in response && response.data) {
                            refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.updateForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                (forceTextPropertyInState as MetadataAvatarTypeProperty).name.toLowerCase() ===
                                EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT.toLowerCase()
                                    ? translate('app.incidentTypes.create.input.forceImage')
                                    : translate('app.incidentTypes.create.input.forceText'),
                        });
                    },
                }
            );
        } else if (!forceTextPropertyInState) {
            createMetadataAvatarTypeProperty(
                selected.id as string,
                EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT,
                [forceText ? 'true' : 'false'],
                eventTypeCustomProcessForceTextLanguagesInState
            );
        }
    };

    const createMetadataAvatarTypeProperty = (
        metadataAvatarTypeId: string,
        name: string,
        values: string[],
        languages: (Identifier | undefined)[],
        fieldName = 'boolean',
        option = 'pre_filled',
        _private = false
    ) => {
        dataProvider
            .create(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    data: {
                        metadataAvatarType: metadataAvatarTypeId,
                        name,
                        values,
                        languages,
                        fieldName,
                        option,
                        private: _private,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (response && 'data' in response && response.data) {
                            refreshProperties((response.data as MetadataAvatarTypeProperty).id as string);
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.createForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                                    ? translate('app.incidentTypes.edit.input.forceImage')
                                    : translate('app.incidentTypes.edit.input.forceText'),
                        });
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.createForceMetadataAvatarTypePropertyError', 'error', {
                    propertyName:
                        name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                            ? translate('app.incidentTypes.edit.input.forceImage')
                            : translate('app.incidentTypes.edit.input.forceText'),
                });
            });
    };

    const getProperties = (metadataAvatarTypeId: string, name: string, onSuccess: (value: boolean) => void) => {
        dataProvider
            .getList(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {
                        metadataAvatarType: selected.id,
                        name,
                    },
                },
                {
                    onSuccess: (response: CreateResult) => {
                        if (
                            response &&
                            'data' in response &&
                            response.data &&
                            Array.isArray(response.data) &&
                            response.data.length > 0
                        ) {
                            if ((response.data[0] as MetadataAvatarTypeProperty).values.length === 0) return;
                            onSuccess(JSON.parse((response.data[0] as MetadataAvatarTypeProperty).values[0]));
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getForceMetadataAvatarTypePropertyError', 'error', {
                            propertyName:
                                name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                                    ? translate('app.incidentTypes.edit.input.forceImage')
                                    : translate('app.incidentTypes.edit.input.forceText'),
                        });
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.getForceMetadataAvatarTypePropertyError', 'error', {
                    propertyName:
                        name === EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE
                            ? translate('app.incidentTypes.edit.input.forceImage')
                            : translate('app.incidentTypes.edit.input.forceText'),
                });
            });
    };

    const refreshProperties = (id: string) => {
        dataProvider
            .getOne(
                METADATA_AVATAR_TYPE_PROPERTIES,
                {
                    id,
                },
                {
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.messages.getIncidentTypePropertyError', 'error');
                    },
                }
            )
            .catch((error: any) => {
                console.error(error);
                notify('app.messages.getIncidentTypePropertyError', 'error');
            });
    };

    const hasImage = () => imageSrc && currentMedia?.name;

    const hasIcon = () => selected && findLogo() !== null;

    return (
        <Form
            initialValues={{
                [NAME_FIELD]: selected?.name,
                [REPEATABLE_FIELD]:
                    TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD in selected.customFields
                        ? selected.customFields[TICKETING_ALLOWED_MULTIPLE_EVENTS_METADATA_AVATAR_TYPE_FIELD] === '*'
                        : false,
                [WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD]:
                    selected &&
                    'customFields' in selected &&
                    TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD in
                        selected.customFields
                        ? selected.customFields[
                              TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE_METADATA_AVATAR_TYPE_FIELD
                          ]
                        : false,
                ...(hasImage() && currentMedia
                    ? {
                          [IMAGE_FIELD]: {
                              [URL_KEY]: imageSrc,
                              fileName: getCleanMediaName(currentMedia.name),
                          },
                      }
                    : {}),
                ...(hasIcon()
                    ? {
                          [ICON_FIELD]: findLogo(),
                      }
                    : {}),
                [CATEGORY_FIELD]: selected?.groupMetadataAvatarType,
                [FORCE_IMAGE_FIELD]: initialForceImageProperty,
                [FORCE_TEXT_FIELD]: initialForceTextProperty,
                [ICON_SVG_COLLECTION_FIELD]:
                    selected && 'customFields' in selected && CUSTOM_FIELDS_ICON_SVG_COLLECTION in selected.customFields
                        ? selected.customFields[CUSTOM_FIELDS_ICON_SVG_COLLECTION]
                        : false,
                [NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]:
                    selected &&
                    'customFields' in selected &&
                    NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD in selected.customFields
                        ? selected.customFields[NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD]
                        : false,
                [ASK_INCIDENT_CREATOR_EMAIL_FIELD]:
                    selected && 'customFields' in selected && ASK_INCIDENT_CREATOR_EMAIL_FIELD in selected.customFields
                        ? selected.customFields[ASK_INCIDENT_CREATOR_EMAIL_FIELD]
                        : false,
                [ASK_CODE_PIN_FIELD]:
                    selected && 'customFields' in selected && ASK_CODE_PIN_FIELD in selected.customFields
                        ? selected.customFields[ASK_CODE_PIN_FIELD]
                        : false,
                [NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]:
                    selected &&
                    'customFields' in selected &&
                    NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD in selected.customFields
                        ? selected.customFields[NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD]
                        : false,
            }}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit, valid, pristine, values, form: { change } }) => {
                return (
                    <Grid container>
                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                            <Grid item xs>
                                <TextInput
                                    variant={'standard'}
                                    required
                                    key={NAME_FIELD}
                                    fullWidth
                                    type={'text'}
                                    label={translate('app.incidentTypes.edit.input.name')}
                                    source={NAME_FIELD}
                                />
                            </Grid>
                            <Grid item>
                                <ReferenceInput
                                    basePath={incidentTypes}
                                    reference={GROUP_METADATA_AVATAR_TYPES}
                                    filter={{
                                        alias: GROUP_METADATA_AVATAR_TYPE_EVENT,
                                        hidden: false,
                                        order__createdAt: 'DESC',
                                    }}
                                    isRequired={true}
                                    source={CATEGORY_FIELD}
                                    label={translate('app.incidentTypes.edit.input.selectEvent')}
                                    validate={[required()]}
                                    filterToQuery={(search: string) => ({ name: search })}
                                >
                                    <AutocompleteInput
                                        create={<CreateFromAutocompleteInput />}
                                        optionValue={'@id'}
                                        optionText={'name'}
                                        fullWidth
                                        variant={'standard'}
                                        filterToQuery={(search: string) => ({ name: search })}
                                    />
                                </ReferenceInput>
                            </Grid>

                            <BooleanInputEventRepeatable />

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.forceText')}
                                        </Typography>
                                    }
                                    source={FORCE_TEXT_FIELD}
                                />
                            </Grid>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.forceImage')}
                                        </Typography>
                                    }
                                    source={FORCE_IMAGE_FIELD}
                                />
                            </Grid>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.noLoggerUserCanCreateEvents')}
                                        </Typography>
                                    }
                                    source={NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD}
                                />
                            </Grid>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.noLoggerUserCanSeeHistory')}
                                        </Typography>
                                    }
                                    source={NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD}
                                />
                            </Grid>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.askIncidentCreatorEmail')}
                                        </Typography>
                                    }
                                    source={ASK_INCIDENT_CREATOR_EMAIL_FIELD}
                                />
                            </Grid>

                            <Grid item>
                                <BooleanInput
                                    label={
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.edit.input.askIncidentCodePin')}
                                        </Typography>
                                    }
                                    source={ASK_CODE_PIN_FIELD}
                                />
                            </Grid>

                            <ImageIconSelect
                                values={values}
                                iconOrImageActive={values[ICON_SVG_COLLECTION_FIELD]}
                                changeIconType={change}
                                name={IMAGE_FIELD}
                                accept='image/*'
                            />

                            {loadImage && <LinearProgress style={{ marginBottom: 20 }} />}

                            <DialogTemplateButtons
                                outside
                                withCancel
                                onCancelClick={onCancel}
                                withCreate
                                isSubmitting={loadingCreate || loadingUpdate || loading || savingMedia || removingMedia}
                                isValidForm={valid && !pristine && !submitFinish && !values[IMAGE_FIELD]?.cropping}
                                cancelButtonLabel={translate('app.cancel')}
                                createButtonLabel={translate('app.valid')}
                            />
                        </form>
                    </Grid>
                );
            }}
        />
    );
};

export default EditForm;
