import React, { useEffect, useState } from 'react';
import {
    JEFFREY_USER_INTERNAL_NAMES_MAPPER,
    USER_MANAGER_FIELD,
    USER_SERVICE_PROVIDER_FIELD,
    USER_SUB_MANAGER_FIELD,
    USER_TYPE_FIELD,
    userTypeInteralNameProfileMapper,
    ACCESS_TO_SERVICE_FIELD,
} from './CONST';
import { useField, useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { RadioButtonGroupInput, Record, ReduxState, useDataProvider, useTranslate } from 'react-admin';
import UserManagerInput from '../inputs/userManagerInput';
import UserServiceProviderInput from '../inputs/userServiceProviderInput';
import { PROFILES } from '../../../providers/resources';
import { useSelector } from 'react-redux';
import { Profile, User } from '../../../utils/types';

interface Props {
    onHandleProfile?: (profile: Profile | undefined) => void;
    searchProfile?: boolean;
    onHandleAccessService?: (accessService: string | undefined) => void;
    initializeUserType?: boolean;
    initializeUserServiceProviderType?: boolean;
    initialImageData?: { name: string; data: string };
    handleImageDataUser?: (data: { name: string; data: string } | null) => void;
    loadingImage?: boolean;
    initialUser?: User;
}

const UserConfig = (props: Props) => {
    const translate = useTranslate();
    const { input: userType } = useField(USER_TYPE_FIELD);
    const { input: accessToServiceField } = useField(ACCESS_TO_SERVICE_FIELD);

    const dataProvider = useDataProvider();

    const profilesForCurrentUserTypeSelected: Record | undefined = useSelector((state: ReduxState) =>
        Object.values(state.admin.resources[PROFILES].data).find(
            (profile) => (profile as Profile).internalName === userTypeInteralNameProfileMapper[userType.value]
        )
    );

    const resolveProfiles = (internalName: string) => {
        dataProvider
            .getList(PROFILES, {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                sort: {
                    order: '',
                    field: '',
                },
                filter: {
                    internalName,
                },
            })
            .then((response) => console.log('resolveProfiles', response))
            .catch((error: any) => console.log('error', error));
    };

    useEffect(() => {
        if (props.initializeUserType) {
            userType.onChange(USER_SERVICE_PROVIDER_FIELD);
        }
        if (props.searchProfile && !profilesForCurrentUserTypeSelected) {
            Object.values(JEFFREY_USER_INTERNAL_NAMES_MAPPER).forEach((internalName) => resolveProfiles(internalName));
        }
    }, []);

    useEffect(() => {
        props.onHandleProfile &&
            props.onHandleProfile(
                profilesForCurrentUserTypeSelected ? (profilesForCurrentUserTypeSelected as Profile) : undefined
            );
    }, [profilesForCurrentUserTypeSelected]);

    useEffect(() => {
        props.onHandleAccessService && props.onHandleAccessService(accessToServiceField.value);
    }, [accessToServiceField]);

    return (
        <Grid container>
            <Grid container>
                <RadioButtonGroupInput
                    label={translate('app.users.type')}
                    source={USER_TYPE_FIELD}
                    choices={[
                        { id: USER_SERVICE_PROVIDER_FIELD, name: translate('app.users.serviceProvider.label') },
                        { id: USER_MANAGER_FIELD, name: translate('app.users.manager.label') },
                        { id: USER_SUB_MANAGER_FIELD, name: translate('app.users.subManager.label') },
                    ]}
                />
            </Grid>

            {(userType.value === USER_MANAGER_FIELD || userType.value === USER_SUB_MANAGER_FIELD) && (
                <Grid container>
                    <UserManagerInput
                        handleImageDataUser={
                            props.handleImageDataUser
                                ? props.handleImageDataUser
                                : () => {
                                      //
                                  }
                        }
                        loadingImage={props.loadingImage}
                        initialImageData={props.initialImageData}
                    />
                </Grid>
            )}

            <Grid container>
                {userType.value === USER_SERVICE_PROVIDER_FIELD && (
                    <UserServiceProviderInput
                        handleImageDataUser={
                            props.handleImageDataUser
                                ? props.handleImageDataUser
                                : () => {
                                      //
                                  }
                        }
                        loadingImage={props.loadingImage}
                        initialImageData={props.initialImageData}
                        initializeUserServiceProviderType={props.initializeUserServiceProviderType}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default UserConfig;
