import { put, takeLatest } from 'redux-saga/effects';
import { DataProvider } from '../../../providers/dataProvider';
import { resolveApiSchemaCreator, resolvedApiSchemaCreator } from '../../actions/hydraDataProvider';

export const resolveApiSchema = (hydraDataProvider: DataProvider) =>
    function* () {
        // @ts-ignore
        const result = yield hydraDataProvider.introspect();

        if ('data' in result) {
            yield put(resolvedApiSchemaCreator.fn(result.data));
        } else {
            console.error('Error requesting api schema');
        }
    };

export default (hydraDataProvider: DataProvider) =>
    function* () {
        yield takeLatest(resolveApiSchemaCreator.action, resolveApiSchema(hydraDataProvider));
    };
