import { GROUP_METADATA_AVATAR_TYPE_EVENT } from '../utils/CONST';

const locale = {
    app: {
        actions: 'Actions',
        add: 'Ajouter',
        addIncidentAttribution: {
            title: 'Attribution automatique d’incident',
            addPostalCode:
                'Quel est le code postal (séparé par une virgule si plusieurs) qui entre en compte dans la zone d’intervention du prestataire ?',
            addBuilding: 'Choisissez les batiments à attribuer :',
            addPlaces: 'Choisissez les lieux à attribuer :',
            addEquipment: 'Choisissez les équipements à attribuer :',
            addIncident: 'Choisissez les type d’incident possible :',
        },
        api: {
            errors: {
                avatar_property: {
                    not_unique: "L'identifiant ne doit pas se répéter. Veuillez le corriger",
                },
            },
        },
        archive: 'Archiver',
        avatar: {
            updated: {
                notification: 'Élément modifié',
                error: 'Il y a eu une erreur dans la modification de %{value}',
            },
        },
        avatarProperty: {
            addressRoot: 'Adresse',
            location: 'Localisation',
            created: {
                notification: '%{property_name} ajouté',
                error: "Il y a eu une erreur lors de l'ajoute de %{property_name}",
            },
            updated: {
                notification: '%{property_name} modifié',
                error: 'Il y a eu une erreur dans la modification de %{property_name}',
            },
            get: {
                error: 'Il y a eu une erreur lors la récupération de %{property_name}',
            },
        },
        buttons: {
            add: 'Ajouter',
            addIcon: 'Ajounter une icone',
            cancel: 'Annuler',
            cancelClone: 'Annuler',
            cancelMove: 'Annuler',
            cancelReorder: 'Annuler',
            cloneAsRoot: 'Cloner en tant que racine',
            create: 'Créer',
            delete: 'Supprimer',
            edit: 'Modifier',
            iconLibrary: "bibliothéque d'icones",
            next: 'Suivant',
            no: 'non',
            remove: 'Retirer',
            return: 'Retour',
            selectIcon: "Sélectionner l'icône",
            uploadFile: 'teéverser un fichier',
            valid: 'Valider',
            validate: 'Valider',
            yes: 'oui',
        },
        cancel: 'Annuler',
        category: {
            name: 'Nom',
            title: 'Catégorie',
            categories: 'Catégories',
            subcategories: 'Sous catégories',
            categoriesAndSubcategories: 'Catégorie et sous catégorie',
            create: {
                title: 'Créer une catégorie',
                input: {
                    name: 'Nom',
                },
            },
            edit: {
                title: 'Modifier une catégorie',
                input: {
                    name: 'Nom',
                },
            },
            delete: {
                title: 'Supprimer la catégorie ',
                subtitle: 'Êtes vous sûr de vouloir supprimer cette catégorie?',
            },
            archive: {
                title: 'Archiver la catégorie',
                subtitle: 'Êtes vous sûr de vouloir archiver %{name}?',
                success: 'Catégorie archivé avec succès',
            },
            created: {
                notification: "Catégorie d'événement créée avec succès",
                error: 'Il y a eu une erreur lors de la création de la catégorie',
            },
            updated: {
                notification: "Catégorie d'événement modifiée avec succès",
                error: 'Il y a eu une erreur lors de la modification de la catégorie',
            },
            [GROUP_METADATA_AVATAR_TYPE_EVENT]: 'Incident',
            breadcrumbs: {
                1: 'Configuration',
                0: 'mes catégories',
            },
            event: {
                breadcrumbs: {
                    1: 'Configuration',
                    0: "mes catégories d'événement ",
                },
            },
        },
        updated: {
            notification: 'Category edited successfully',
            error: 'There was an error editing the category',
        },
        dashboards: {
            filters: {
                period: {
                    lastWeek: 'Semaine dernière',
                    lastMonth: 'Mois dernier ',
                    lastYear: 'Année dernière',
                },
                afterAt: 'Après',
                beforeAt: 'Avant',
            },
            in: 'À: %{name} ',
            nonePlace: '',
            option: 'Option',
            quantityOfOpenAndContractedIncidentsDashboard: {
                title: "Nb d'incidents ouverts et sous contrat",
                infoText: '%{question}',
                helpText: 'Emplacement choisi: %{name}',
                place: 'Lieux',
                in: 'À: %{name} ',
                nonePlace: '',
            },
            numberOfIncidentsAwaitingAllocationDashboard: {
                title: "Nb d'incidents en attente d'attribution",
                infoText: 'Dashboard numéro : %{question}',
                helpText: 'Emplacement choisi : %{name}',
                place: 'Lieux',
                in: 'À: %{name} ',
                nonePlace: '',
            },
            topCategoriesWithIncidentsFilteredByCategoryDashboard: {
                title: 'Top "%{item}" ayant des incidents',
                helpText: 'Emplacement choisi %{name}',
                titleShort: 'Top catégories ayant des incidents',
                selectCategory: 'Choisissez une catégorie',
                selectSubcategory: 'Choisissez une sous catégorie',
                fields: {
                    ranking: 'classement',
                    category: '%{item_field}',
                    subcategory: '%{item_field}',
                    avatar: '%{item_field}',
                    numberOfIncidents: "Nombre d'incidents",
                    incidentPercent: "% D'incidents",
                },
                infoText: 'Dashboard numéro : %{question}',
                category: 'Catégorie',
                subcategory: 'Sous catégorie',
                place: 'Emplacement',
                eventRunning: 'Incident ouverts',
                invalidSubcategoryForPlace: "%{name} n'appartient pas à la sous catégorie choisit",
            },
            topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard: {
                title: 'Top "%{item}" ayant des incidents',
                helpText: 'Emplacement choisi : %{name}',
                titleShort: 'Top sous catégories ayant des incidents',
                selectSubcategory: 'Choisissez une sous catégorie',
                selectCategory: 'Choisissez une catégorie',
                fields: {
                    ranking: 'classement',
                    category: '%{item_field}',
                    numberOfIncidents: "Nombre d'incidents",
                    incidentPercent: "% D'incidents",
                },
                infoText: 'Dashboard numéro : %{question}',
                category: 'Catégorie',
                subcategory: 'Sous catégorie',
                place: 'Emplacement',
                eventRunning: 'Incident ouverts',
                invalidCategoryForPlace: "%{name} n'appartient pas à la catégorie choisit",
            },
            topIncidentsDashboard: {
                title: 'Top incidents les plus signalés',
                fields: {
                    ranking: 'classement',
                    incidentType: "Type d'incidents",
                    numberOfIncidents: "Nombre d'incidents",
                    incidentPercent: "% D'incidents",
                },
                infoText: 'Dashboard numéro :  %{question}',
                helpText: 'Emplacement choisi : %{name}',
                selectIncident: 'Choisissez un incident',
                incident: 'Incident',
                place: 'Emplacement',
                in: 'À: %{name} ',
                nonePlace: '',
                eventRunning: 'Incident ouverts',
            },
            incidentTimeFromTheBeginningDashboard: {
                title: 'Incident en cours non résolu',
                fields: {
                    time: 'temps',
                    date: 'date',
                    user: 'utilisateur',
                    navigation: 'navegation',
                    avatarName: "Nom de l'objet",
                },
                infoText: 'Dashboard numéro : %{question}',
                helpText: 'Emplacement choisi : %{name}',
                selectIncident: 'Choisissez un incident',
                incident: 'Incident',
                place: 'Emplacement',
                in: 'À: %{name} ',
                nonePlace: '',
                eventRunning: 'Incident ouverts',
            },
            totalNumberOfIncidentsByCompanyDashboard: {
                title: "Nombre total d'incidents",
                infoText: 'Dashboard numéro :  %{question}',
                helpText: 'Emplacement choisi : %{name}',
                place: 'Lieux',
                in: 'À: %{name} ',
                nonePlace: '',
                eventRunning: 'Incident in progress',
            },
            actions: {
                enableDashboards: "Personnaliser l'affichage",
            },
        },
        delete: 'Supprimer',
        download: 'Télécharger',
        edit: 'Modifier',
        errors: {
            server: "Il a eu une erreur. Veillez contacter l'administrateur",
        },
        filters: {
            startDate: 'Début',
            endDate: 'Fin',
            takeIntoAccount: 'Pris en compte',
        },
        headers: {
            incidents: 'Mes incidents',
            navigation: 'Navigation',
            operations: 'Opérations',
            configuration: 'Configuration',
            categoriesLevel: 'Mes catégories',
            categoriesEvent: "Mes catégories d'événements",
            subCategories: 'Mes sous catégories',
            incidentTypes: "Type d'incidents",
            serviceProviders: 'Prestataires',
            dashboard: 'Dashboard',
            statistics: 'Statistiques',
            users: 'Utilisateurs',
        },
        hours: '%{time} heure(s)',
        incidentTypes: {
            name: 'Nom',
            incidents: 'Incidents',
            repeatable: 'Incident répétable',
            forceImage: 'Image requis',
            forceText: 'Commentaire requis',
            repeatableInfo:
                'Activez si ce type d’incident peut être déclaré plusieurs fois comme pour l’incident Autre : on peut déclarer plusieurs incidents différents, ou encore Graffitis, un mur peut avoir plusieurs graffitis, tâches…',
            create: {
                title: "Créer un type d'incident",
                input: {
                    name: "Nom du type d'incident",
                    image: 'Icône',
                    repeatable: 'Incident répétable (activez pour les incidents non attribué "autre")',
                    titleOverwrite: 'Activez pour que le titre soit vide',
                    titleOverwriteInfo:
                        "Lorsqu'un incident est répétable,on invite le déclarant à modifier le titre qui est par défaut pré rempli avec le nom du type d'incident. Pour obliger le déclarant à réfléchir au titre, vous pouvez activer cette option et le titre sera vide donc le déclarant se verra obligé de le remplir",
                    imageButtons: {
                        load: 'Charger une image',
                        applyCrop: 'Appliquer le recadrage',
                        cancelCrop: 'Annuler le recadrage',
                        zoomCrop: 'Zoom',
                        cropSize: 'Taille de la récolte',
                    },
                    selectEvent: 'Événement',
                    forceImage: 'Image requis',
                    forceText: 'Commentaire requis',
                    iconSource: "Activer (icône) - Désactiver (icône d'image)",
                    noLoggerUserCanSeeHistory: "Utilisateurs non connectés peuvent voir l'historique de l'incident",
                    noLoggerUserCanCreateEvents: 'Utilisateurs non connectés peuvent voir les incidents créés',
                    askIncidentCreatorEmail: 'Demander à l’usager son email s’il veut être notifié de sa resolution',
                    askIncidentCodePin: "Demander le code pin de l'incident avant l'intervention",
                },
                notification: "Type d'incident type créé",
                error: "Il y a eu une erreur lorsque la création du type d'incident",
            },
            edit: {
                label: 'Modifier',
                severalItem: "Modifier plusieurs type d'incident",
                severalItemsEdited: "Les types d'incident ont été bien mis à jour",
                title: "Modifier un type d'incident",
                input: {
                    name: "Nom du type d'incident",
                    image: 'Icône',
                    repeatable: 'Incident répétable? (activez pour les incidents non attribué "autre")',
                    titleOverwrite: "Activez pour ajouter un nouveau titre à l'incident",
                    selectEvent: 'Événement',
                    forceImage: 'Image requis',
                    forceText: 'Commentaire requis',
                    noLoggerUserCanSeeHistory: "Utilisateurs non connectés peuvent voir l'historique de l'incident",
                    noLoggerUserCanCreateEvents: 'Utilisateurs non connectés peuvent voir les incidents créés',
                    askIncidentCreatorEmail:
                        "Demander à l’usager son email s’il veut être notifié de la resolution de l'incident",
                    askIncidentCodePin: "Demander le code pin de l'incident avant l'intervention",
                },
                error: "Il y a eu une erreur lorsque la modification du type d'incident",
                success: "Le type d'incident a étè modifié avec succès",
            },
            delete: {
                title: "Supprimer le type d'incident",
                subtitle: "Êtes vous sûr de vouloir supprimer ce type d'incident?",
            },
            archive: {
                title: "Archiver le type d'incident",
                subtitle: 'Êtes vous sûr de vouloir archiver %{name}?',
                error: "Il y a eu une erreur lorsque l'archivage du type d'incident",
                success: 'Incident type archivé avec succès',
            },
            add: {
                title: "Écrivez un nom d'un incident à ajouter",
            },
            deleted: "Le type d'incident a étè bien supprimé",
            archived: "Le type d'incident a étè bien archivé",
            breadcrumbs: {
                1: 'Configuration',
                0: "Type d'incident",
            },
            instructions: {
                labelList: 'Consigne',
                dialogTitle: 'Consignes',
                createLabel: 'Choisissez ou créer une consigne',
                createDialogTitle: 'Créér une consigne pour',
                notifications: {
                    createSuccess: 'La consigne a étè bien créée',
                    createError: "Une erreur s'est produite lors de la création de la consigne",
                    editSuccess: 'La consigne a étè bien modifiée',
                    editError: "Une erreur s'est produite lors de la mise à jour de la consigne",
                    deleteSuccess: 'La consigne a été bien supprimée',
                    deleteError: 'Il y a eu une erreur lors de la suppression de la consigne',
                },
                editDialogTitle: 'Modifier la consigne',
                addImageDialogTitle: 'Ajouter une image',
                addImageInputLabel: 'Image',
                clickImage: "Choisissez une image pour l'ajouter à l'editeur de text",
                tooltipAddImage: 'Cliquez pour insèrer une image',
                imageLibrary: "Bibliothèque d'image",
                helpText: 'Les images effacées ne seront pas visible dans la consigne',
            },
            error: "Il y a eu une erreur lorsque la récuperation du type d'incident",
        },
        incidents: {
            name: 'Incident',
            equipment: 'Équipement',
            equipmentsTypes: "Types d'équipements",
            incidentsTypes: "Types d'incidents",
            imageAndComment: 'Image et commentaire',
            imageAndCommentTitle: 'Image et commentaire : ',
            delegatedToTitle: 'Incident délégué à :',
            comment: "Commentaire de l'incident : %{eventName}",
            places: 'Lieux',
            shortAddress: 'Adresse courte',
            building: 'Bâtiment',
            serviceProvider: 'Prestataire',
            serviceProviderTitle: 'Prestataire :',
            rapportDate: 'Date de signalement',
            rapportDateTitle: 'Date de signalement :',
            resolveDate: 'Date de résolution',
            resolveDateTitle: 'Date de résolution :',
            attributionDate: "Date d'attribution",
            workResolutionDelay: 'Délai de résolution contractuel',
            workResolutionDelayTitle: 'Délai de résolution contractuel :',
            state: 'Statut',
            stateTitle: 'Statut :',
            city: 'Ville',
            fullAddress: 'Adresse complète',
            delay: 'retard : ',
            considerationDeadlines: 'Contractual consideration deadlines',
            resolutionDeadlines: 'Contractual resolution deadlines',
            postalCode: 'Code postal',
            showIncidentResolved: 'Cacher les incidents résolus',
            showIncidentClosed: 'Cacher les incidents clôturés',
            navigationPath: 'Emplacement',
            navigationPathTitle: 'Emplacement :',
            incidentTimeDefined: 'Temps depuis signalement',
            incidentTimeDefinedTitle: 'Temps depuis signalement :',
            countdown: 'Temps restant au prestataire',
            countdownTitle: 'Temps restant au prestataire :',
            addServiceProvider: {
                title: 'Attribuer au prestataire',
                toAssign: 'Attribuer ?',
                name: "Nom d'utilisateur",
                added: {
                    ok: "L'incident a été attribué à l'utilisateur",
                    ko: "Il y a eu un problème lorsque l'attribution de l'incident",
                },
                notServiceProvider: 'Pas de prestataire',
                forAssigningAServiceProviderInfo:
                    "Pour attribuer un prestataire l'incident doit être au statut En attente d'attribution par le gestionnaire ou rédamarrer l'incident en attribuant un prestataire",
            },
            restart: {
                title: 'Redémarrer %{name} ',
                label: 'Redémarrer',
                sendEmail: 'Envoyer une notification via email',
                reassignCurrentUserProvider: 'Réattribuer au prestataire actuel',
                newUserProvider: 'Attribuer à autre prestataire',
                fieldRequired: "Réattribuez l'incident au prestaire actuel ou un autre",
                noCurrentServiceProvider: "Attention, cet incident n'a pas un prestataire attribué",
            },
            close: {
                title: "Clôturer l'incident : %{name} ",
                label: '',
                comment: 'Commentaire',
                commentSubtitle:
                    "Ce commentaire figurera dans l'email envoyé à toutes les personnes ayant déclaré leur intérêt d'être notifié par la résolution de cet incident",
            },
            deleted: "L'incident a étè bien supprimé",
            delete: {
                title: "Supprimer l'incident ",
                subtitle: 'Êtes vous sûr de vouloir supprimer cet incident ?',
            },
            information: {
                title: "Voir l'incident",
                seeIncident: "Voir l'incident",
                state: 'Statut',
                closeIncident: "Clôturer l'incident",
                close: 'Clôturer',
                incidentClosed: 'Incident clôturé',
                incidentRestarted: 'Incident redémarré',
                incidentRestarting: "L'incident est en train de redémarrer",
                notClosedCorrectly: "L'incident %{name} n'a pas été clôturé avec succès",
                notRestartedCorrectly: "L'incident %{name} n'a pas été redémarré avec succès",
                closedCorrectly: 'Les incidents ont été clôturé avec succès',
                pinCode: "Code d'incident",
                reDelegateUser: 'Déléguer à autre sous gestionnaire',
                delegateUser: 'Déléguer à un sous gestionnaire',
                delegatedTo: 'Délégué à :',
                invoice: 'Facture',
                quote: 'Devis',
                invoiceAndQuotes: 'Factures / Devis',
            },
            notification: {
                incidentNotClosed: "Il y a eu une erreur lors la clôture de l'incident",
                pathError: "Il y a eu une erreur lors le chargement de l'emplacement de l'incident %{name}",
                incidentNotRestart: "Incident n'a pas été redémarré",
                getServiceProviderError: 'Il y a eu une erreur lors la récupération du prestataire',
                getReminderEventError: 'Il y a eu une erreur lors la récupération de la note du rappel',
                createReminderEventError: 'Il y a eu une erreur lors la création de la note du rappel',
                reminderEventCreated: 'Note du rappel créée avec succès',
                reminderEventEdited: 'Note du rappel modifiée avec succès',
                getDelegatedUserError:
                    "Il y a eu une erreur lors la récupération du gestionnaire délégué a l'incident %{incidentName}",
            },
            filters: {
                serviceProvider: {
                    title: 'Prestataires',
                    assigned: ' Affectation des incidents',
                    withContract: 'Avec contrat',
                    withoutContract: 'Sans contrat',
                    withoutAndWithContract: 'Avec et sans contrat',
                    with: 'Avec',
                    without: 'Sans',
                    notAttributed: 'Non attribué',
                    none: 'Tous les incidents',
                },
                categoriesAndSubcategories: 'Catégorie et sous catégorie',
                categoriesAndIncidentType: 'Catégorie',
                subcategoriesAndIncidentType: "Sous catégorie et type d'incident",
                subcategoriesAndIncidentTypeSwitch: "Activez le filtrage par souscatégorie ou type d'incident",
                category: {
                    title: 'Catégorie',
                    categories: 'Catégories',
                    subcategories: 'Sous catégories',
                },
                subcategories: {
                    title: 'Sous catégories',
                },
                incidentType: {
                    title: "Type d'incidents",
                },
                place: {
                    title: 'Filtre avancé localisation',
                    selected: 'Emplacement choisi: %{name}',
                    noFilter: 'Annuler le filtre',
                    label: 'Emplacement',
                },
                status: {
                    title: 'Choisissez les statut des incidents',
                    label: 'Filtre par statut',
                    noFilter: 'Annuler le filtre',
                },
                managerSubManager: {
                    title: 'Gestionnaire / Sous Gestionnaire',
                },
            },
            eventAssignedDate: 'Date d’attribution',
            eventAssignedDateTitle: 'Date d’attribution :',
            breadcrumbs: {
                1: 'Opérations',
                0: 'Incidents',
            },
            requestWorkResolutionDelay: {
                get: {
                    error: "Une erreur s'est produite lors l'obtention du délai de résolution de travail",
                },
            },
            pinCode: "Code d'incident",
            pinCodeTitle: "Code d'incident :",
            status: {
                assignServiceProvider: "En attente d'attribution par le gestionnaire",
                waitTakeIntoAccount: "Attribué, en attente de prise en charge par l'intervenant",
                refusedTakeIntoAccount: 'Refusé',
                takenIntoAccount: 'Pris en compte',
                pauseCounterTime: 'Intervention suspendue',
                repairing: 'Intervention en cours',
                repaired: "Fin d'intervention",
                closed: 'Clôturé',
                opened: 'Ouvert',
                startOfIntervention: "Début de l'intervention",
            },
            alertConfig: {
                message: 'Note du rappel',
                triggerDate: 'Date de déclenchement',
                edit: 'Modifier le rappel',
                create: 'Créer un rappel',
                delete: "Supprimer l'alert",
                removed: 'Rappel supprimé',
                removedError: 'Il y a eu une erreur lors la suppression du rappel',
                eventNameInfo: "Un rappel a été programmé au sujet de l'incident : ",
                eventNameValue: '%{eventName}',
                triggerDateInfo: 'pour le : ',
                triggerDateValue: '%{triggerDate}',
                messageInfo: 'avec une note du rappel : ',
                messageValue: '%{message}',
            },
            delegateDialog: {
                title: 'Déléguer à un sous gestionnaire ou gestionnaire',
                user: 'Sous gestionnaire / Gestionnaire',
                update: {
                    success: 'Le gestionnaire a été bien délégué à cet incident',
                    fail: 'Il y a eu une erreur lors la délégation de cet incident à le gestionnaire',
                },
                create: {
                    success: 'Le gestionnaire a été bien délégué à cet incident',
                    fail: 'Il y a eu une erreur lors la délégation de cet incident à le gestionnaire',
                },
                delete: {
                    success: "L'incident n'est plus délégué à ce gestionnaire ou sous gestionnaire",
                    fail: 'Il y a eu une erreur: Code %{errorCode}',
                },
            },
            edit: {
                label: 'Modifier le nom',
                title: "Modifier le nom de l'incident",
                name: 'Nom',
                success: "L'incident a été bien mis à jour",
                fail: "Il y a eu une erreur lors de la mise à jour de l'incident",
            },
            seeMore: 'Voir plus',
            view: {
                kanban: 'Kanban (beta)',
                list: 'Liste',
            },
            empty: "Pas encore d'incidents",
            confirmationAmount: "Combien de fois l'incident a été confirmé",
            showDocs: {
                label: 'Documents',
            },
            files: {
                type: 'Factures / Devis',
                date: 'Date',
                owner: 'Utilisateur',
            },
            resolutionOpinion: {
                label: 'Avis sur des indents clôturés',
                seeMore: 'Voir plus',
                title: 'Avis sur le processus de gestion des incidents',
                evaluation: "Note de la gestion de l'incident",
                questions: {
                    label: 'Selon les questions posées sur : ',
                    qualityIntervention: "Qualité d'intervention",
                    speedIntervention: "Rapidité d'intervention",
                    reportingExperienceIntervention: "Expérience de signalement d'incident",
                },
                averageEvaluation: 'Note moyenne : %{average}',
                comment: 'Commentaire',
            },
        },
        login: {
            usernameOrEmail: 'Utilisateur ou email',
            password: 'Mot de passe',
            label: 'Connexion',
            passwordForgot: 'Mot de passe oublié?',
            invalidCredentials: "Échec de l'authentification, merci de réessayer.",
        },
        logout: 'Déconnexion',
        mc: {
            parameter: 'Parametre "MC"',
            information: 'Information',
            name: 'Nom',
            general: 'General',
            changePict: 'Changer de picto',
            application: 'Application',
            downloadQrcode: 'Telecharger le qrcode',
            showQrcode: 'Voir le QR Code',
            exportQrcode: 'Exporter le QR Code',
            editDialogTitle: 'Modifier le MC',
            edit: 'Modifier',
            visitApp: 'Afficher l´application',
            copy: "Copier l'URL",
            creationDate: {
                value: 'Date de création',
                afterFilter: 'Après',
                beforeFilter: 'Avant',
            },
            yourls: {
                shortUrl: 'Url Courte',
            },
            enabled: {
                yes: 'Oui',
                no: 'Non',
            },
            mcName: 'Nommer les modules communicants',
            remove: 'Supprimer',
            add: 'Ajouter',
        },
        media: {
            errorGettingImage: "Il y a eu une erreur lors de la récuperation de l'image",
            errorGettingFile: 'Il y a eu une erreur lors de la récuperation du fichier',
            addImage: 'Ajouter une image',
            imageMaxSizeUpload: 'La taille du fichier est trop grande. Essayez avec un fichier inférieur à 5 Mb.',
            errorCreatingImage: "Il y a eu une erreur lors de l'enregistrement de l'image",
            errorDeletingImage: "Il y a eu une erreur lors de la suppression de l'image",
            deleted: 'Le fichier a étè bien supprimé',
            notDownloaded:
                "Il y a eu une erreur lors de le téléchargement du fichier. Contactez à l'admin avec le code: DOWNLOAD FILE",
        },
        menuActions: {
            title: 'Actions',
            create: 'Créer',
            edit: 'Modifier',
            delete: 'Supprimer',
            refresh: 'Actualiser',
            simulateApp: 'Simuler App',
            customize: 'Personnaliser',
            addItem: 'Ajouter un élément',
            editItem: "Modifier l'élément",
            moveTo: 'Déplacer vers',
            reorder: 'Réorganiser',
            cancelMoveTo: 'Annuler déplacer vers',
            seePinCode: "Voir consignes d'accès",
            remove: 'Supprimer',
            config: 'Config',
            archive: 'Archiver',
            changeView: "Mode d'affichage",
            clone: 'Clone',
            instructions: 'Consignes',
        },
        messages: {
            selectItemToMove: 'Sélectionnez un élément à déplacer',
            selectItemToClone: 'Sélectionnez un élément à cloner',
            selectWhereToMoveTheItem: "Sélectionnez où déplacer l'élément",
            success: 'Succès',
            creationError: 'Erreur de création',
            imageNotAdded: "Il y a eu une erreur lors de l'ajoute de l'image",
            imageNotRemoved: "Il y a eu une erreur lors de la suppression de l'image",
            getLanguagesError: 'Il y a une erreur lors de la traduction de %{value} en %{twoLetters}.',
            createLanguagesError:
                'Il y a une erreur lors de la création de la traduction de %{value} en %{twoLetters}.',
            createForceMetadataAvatarTypePropertyError:
                'Il y a une erreur lors de la configuration du %{propertyName}.',
            updateForceMetadataAvatarTypePropertyError:
                'Il y a une erreur lors de la mise à jour de la configuration du %{propertyName}.',
            getForceMetadataAvatarTypePropertyError:
                "Il y a une erreur lors de l'obtention de la configuration du %{propertyName}.",
            getIncidentTypePropertyError:
                "Il y a une erreur lors de l'obtention de la configuration du type d'incident. Code Properties",
            getPropertyError: "Il y a une erreur lors de l'obtention de la configuration. Code Properties",

            updateFileSystemMapperEnabledMetadataAvatarTypePropertyError:
                'Il y a une erreur lors de la mise à jour de la configuration de gestion de documents.',
            getSubcategoriesPropertyError:
                "Il y a une erreur lors de l'obtention de la configuration de la sous catégorie. Code Properties",
            editMultipleIncidentTypes:
                "Il y eu des erreurs lors la mise à jour des type d'incidents. Verifiez bien les données modifiées.",
        },
        minutes: '%{time} minute(s)',
        name: "Suivi d'incidents",
        navigation: {
            tree: {
                root: 'Racine',
                shortRoot: 'R',
                loading: 'Chargement',
                empty: 'Aucun élément',
            },
            list: {
                columns: {
                    name: 'Nom',
                    category: 'Catégorie',
                    userApplied: 'Utilisateur / Prestataire de services par défaut',
                    pairedUpFromField: 'Marqueur posé',
                    subCategory: 'Sous catégorie',
                    subCategoryOrIncidentType: "Sous catégorie ou type d'incident",
                    incidentInProgress: 'Incident en cours',
                    ctn: "Numéro d'étiquette",
                },
            },
            configuration: {
                title: 'Paramètres',
                defineRoot: 'Définir en tant que racine',
                notDefinedRoot: 'Existe déjà une racine au niveau %{place}',
                rootExisted: 'Existe déjà une racine',
                location: {
                    label: 'Localisation',
                    address: 'Adresse',
                    postalAddress: 'Adresse postale',
                    city: 'Ville',
                    country: 'Pays',
                    postalCode: 'Code postal',
                },
                serviceProvider: {
                    label: 'Prestataires par défaut',
                    usageDescription:
                        'Cette page permet de définir les prestataires qui seront alertés suite à la déclaration de l’incident. Votre choix s’appliquera aux éléments inférieurs non configurés.',
                    incidentType: {
                        label: "Type d'incident",
                    },
                    subCategory: {
                        removed: {
                            notification: "Le prestataire %{username} n'est plus appliqué à l'incident %{incident}",
                            error: "Une erreur s'est produite lors de la suppression du fournisseur de services de l'incident",
                        },
                        updated: {
                            notification: "Le prestataire est appliqué à l'incident",
                            error: "Une erreur s'est produite lors de la modification du fournisseur de services sur l'incident",
                        },
                        created: {
                            notification: "Le prestataire est appliqué à l'incident",
                            error: "Une erreur s'est produite lors de la modification du fournisseur de services sur l'incident",
                        },
                        added: {
                            notification: "Le prestataire est appliqué à l'incident",
                            error: "Une erreur s'est produite lors de la modification du fournisseur de services sur l'incident",
                        },
                    },
                    createDescription: 'Veuillez attribuer un incident par défaut',
                    title: 'Attribuer ou modifier un utilisateur par défaut dans un incident',
                    workResolutionDelay: {
                        label: 'Délai de résolution de travail (en heures)',
                        updated: {
                            notification: 'Le délai de résolution de travail a étè bien modifié',
                            error: 'Il y a eu une erreur lors la modification du délai de résolution de travail',
                        },
                        created: {
                            notification: 'Le délai de résolution de travail a étè bien ajouté',
                            error: "Une erreur s'est produite lors la modification du délai de résolution de travail",
                        },
                        get: {
                            error: "Une erreur s'est produite lors l'obtention du délai de résolution de travail",
                        },
                        hours: 'heures',
                    },
                    alreadyAssigned: 'Un seul utilisateur peut être attribué par défaut dans un incident.',
                    assigned: 'Utilisateur attribué à %{place_name}',
                },
                processResolution: 'Processus de résolution des incidents',
                incidentByDefault: {
                    label: 'Incidents par défaut',
                    create: {
                        label: 'Ajouter des incidents par défaut à: ',
                    },
                },
                itemName: "Nom de l'élément",
                enableSending:
                    'Ce bouton permet d’activer ou de désactiver l’envoi de l’information (n° de téléphone, nom et/ou digicode) par email au prestataire pour l’aider à la résolution de l’incident. Pensez à enregistrer chaque ligne.',
                itemInformation: {
                    label: "Information de l'élément",
                    contact: 'Contact',
                    contactName: 'Nom',
                    contactPhone: 'Numéro de téléphone',
                    extraInformationLabel: 'Informations complémentaires',
                    extraInformation:
                        "Afin de faciliter l’intervention des prestataires, les champs ci-dessous vous permettent de renseigner les lieux et contacts liés à l'élément choisi. Par exemple, si vous ajoutez une information complémentaire sur le contact sur site, vous pourriez indiquer dans le Titre : Nom du contact sur site, puis dans Texte de l’information : son nom ; pensez à ajouter une ligne supplémentaire pour son téléphone. Pensez à enregistrer chaque ligne.",
                    pinCode: "Consignes d’accès (digicode, lieu du coffret électrique, de l'arrivée d’eau…)",
                    add: 'Ajouter une information complémentaire',
                    customInformationFieldID: 'Titre de l’information',
                    customInformationFieldValue: 'Texte de l’information',
                    privateArea: 'Partie privative',
                    publicArea: 'Partie commune',
                    privacyArea: 'Accessibilité de l’emplacement',
                    privateLabel: "Visibilité de l'élément",
                    privateInformation:
                        'Cocher privé pour un logement privatif qui ne doit pas apparaître depuis les parties communes',
                    fileSystemMapper: {
                        label: 'Gestion de documents',
                        enabled: 'Activé',
                        disabled: 'Desactivé',
                    },
                    inMaintenance: 'En maintenance',
                    outOfMaintenance: 'Operationel',
                    maintenanceStatus: 'Service par QRCode',
                },
                incident: {
                    description:
                        'Choisissez les incidents attribués par défaut appliqués à cet élément et aux éléments inférieurs.',
                    category: 'Catégorie',
                    assigned: 'Incident attribué par défaut',
                    subCategory: {
                        title: 'Sous Catégorie',
                        added: {
                            notification: 'Sous catégorie ajoutée',
                            error: "Il y a eu une erreur lorsque l'ajoute de la sous catégorie",
                            TicketingDepsOneAllowed: 'Cette categorie a déjà un emplacement configuré',
                        },
                    },
                    eventType: {
                        title: 'Incident à attribuer',
                        assigned: 'Incident attribué à : %{place_name}',
                        updated: {
                            success: 'Incident modifié avec succès',
                            notification: 'Incident(s) attribué',
                            error: "Il y a eu une erreur lorsque l'ajoute de l'incident",
                            TicketingDepsOneAllowed: 'Cette categorie a déjà un emplacement configuré',
                        },
                        removed: {
                            notification: 'Incident enlevé',
                            error: "Il y a eu une erreur lorsque l'enlèvement de l'incident",
                        },
                    },
                    action: {
                        remove: {
                            label: 'Supprimer',
                            title: 'Supprimer la sous catégorie?',
                            subtitle: 'Êtes-vous sûr de vouloir supprimer la sous catégorie %{name}.?',
                            notification: 'La sous catégorie a étè bien supprimé',
                            error: 'Il y a eu une erreur lorsque la supprimision de la sous catégorie',
                        },
                    },
                },
                managerNotification: {
                    label: 'Gestionnaire - Notifications',
                    description: {
                        line0: 'Si vous souhaitez ne pas recevoir de notifications pour certaines sous catégories remplissez le tableau ci-dessous.',
                        line1: 'Vous avez 3 choix à chaque fois, vous pouvez choisir: ',
                        line2: 'de ne jamais être notifié pour cette sous catégorie',
                        line3: 'de ne pas être notifié si l’incident est paramétré avec un prestataire par défaut sans contrat',
                        line4: 'ou de ne pas être notifié si le prestataire par défaut est sous contrat',
                    },
                    empty: " Vous n'avez pas encore configuré d'incidents par défaut sur cette racine",
                    subcategory: "Pour l'incident",
                    assignedUnderContract: 'Prestataire par défaut sous contrat',
                    assignedWithoutContract: 'Prestataire par défaut sans contrat',
                    hasNotUserProvider: 'Pas de prestataire?',
                    never: 'Jamais',
                    user: 'Gestionnaire',
                    action: {
                        create: {
                            error: 'Il y a eu une erreur lors de la création de la notification',
                            notification: 'La notification a été bien créée',
                            title: 'Créer une notification',
                        },
                        edit: {
                            label: 'Modifier',
                            title: 'Modifier la notification',
                            notification: 'La notification a été bien mise à jour',
                            error: 'Il y a eu une erreur lors de la mise à jour de la notification',
                        },
                        remove: {
                            label: 'Supprimer',
                            title: 'Supprimer la notification ?',
                            subtitle:
                                'Êtes vous sûr de vouloir supprimer la notification pour le gestionnaire %{userName} ?',
                        },
                        list: {
                            error: 'Il y a eu une erreur lors de la obtention de notifications de cette racine et cet incident %{incidentName}',
                        },
                    },
                    inheritedFromParents: 'Gestionnaire hérité du parent',
                },
                managerPlace: {
                    description: {
                        line0: 'Choisissez le gestionnaire de cette racine, celui-ci sera notifié à chaque incident et à chaque évolution dans le process de résolution.',
                    },
                    action: {
                        create: {
                            error: "Une erreur s'est produite lors de la définition du gestionnaire de cette racine.",
                            notification: 'Le gestionnaire a été correctement ajouté à cette racine',
                        },
                        delete: {
                            error: "Une erreur s'est produite lors de la supprimation du gestionnaire de cette racine.",
                            notification: 'Le gestionnaire a été correctement supprimé à cette racine',
                        },
                        edit: {
                            label: 'Edit',
                            title: 'Edit the notification',
                            notification: 'Le gestionnaire a été correctement mise à jour.',
                            error: 'Il y a eu une erreur lors de la mise à jour du gestionnaire.',
                        },
                        list: {
                            error: "Il y a eu une erreur lors de l'obtention du gestionnaire de cette racine.",
                        },
                    },
                },
                fileManager: {
                    label: 'Documents',
                },
                subcategory: {
                    selectLabel: 'Choisir une sous catégorie',
                },
            },
            removeItem: {
                removed: 'Élément supprimé',
                error: 'Il y a eu une erreur lors de la suppression',
                helpText: 'Êtes vous sûr de vouloir supprimer cet élément ?',
                title: 'Supprimer',
            },
            breadcrumbs: {
                1: 'Opérations',
                0: 'Navigation',
            },
            moveDialog: {
                title: "Déplacer l'élément ",
                item: 'Élément',
                moveTo: 'Déplacer vers',
                twoRootsError: 'Dans une arborescence ne peut pas exister deux racines imbriquées',
            },
            cloneDialog: {
                title: "Cloner l'élément",
                item: 'Élément',
                moveTo: 'Clone vers',
            },
            reorderDialog: {
                title: "Placer l'élément : %{name}",
                position: {
                    down: "Dessous l'élément : %{name}",
                    up: "Dessus l'élément :  %{name}",
                },
            },
            process: {
                name: 'Nom du processus',
                enableServiceProvider:
                    'Activer les prestataires par défaut pour la résolution des incidents. Lorsque cette option est activée, les prestataires par défaut sont automatiquement notifiés lorsqu’un incident est ouvert.',
                askConfirmationToServiceProvider:
                    'Demander aux prestataires de confirmer la prise en compte de l’incident lorsqu’il reçoit l’alerte d’incident ouvert.',
                takeIntoAccountAutomatically:
                    'Confirmation de prise en compte automatique lorsque le prestaire est sous contrat.',
                created: {
                    notification: 'La configuration a été créée avec succès',
                    error: 'Il y a eu une erreur lors de la configuration',
                },
                updated: {
                    notification: 'La configuration a été modifié avec succès',
                    error: 'Il y a eu une erreur lors de la modification de la configuration',
                },
                service: {
                    name: 'Nom du service',
                },
                get: {
                    error: 'Il y a eu une erreur lors du chargement de la configuration',
                },
            },
            addItem: {
                title: 'ajouter un élément',
                metadataType: 'Sous catégories',
                name: 'Nom de l’élément',
                selectSubCategory: 'Choisissez une sous catégorie',
                asChild: "En tant qu'enfant",
            },
            editItem: {
                title: 'Modifier l’élément',
                metadataType: 'Sous catégories',
                name: 'Nom de l’élément',
                selectSubcategory: 'Choisissez une sous catégorie',
            },
        },
        next: 'Suivant',
        no: 'non',
        noElement: 'Aucun élément',
        noneItem: 'Aucun élément',
        notification: {
            updated: 'Élement mis à jour',
            created: 'Élement créé',
            archived: 'Élement archivé',
            exported: 'Èlements exportés',
            failed_created: "Il y a eu un problème dans la création de l'élement",
            failed_updated: "Il y a eu un problème dans la mise à jour de l'élement",
            failed_simulated: 'Il y a eu un problème dans la simulation',
            copied: 'Copié avec succès',
        },
        objects: {
            addImages: 'Cliquez ici pour ajouter des images',
            tabs: {
                mc: 'STOCK NON APPAIRÉ DE MODULES COMMUNICANTS',
            },
            info: {
                id: 'Id',
                name: "Nom de l'objet",
                type: "Type d'objet",
                description: 'Description',
                reference: 'Référence',
                created: 'Date de création',
                enterprise: 'Entreprise',
                localization: 'Localisation',
                latitude: 'Latitude',
                longitude: 'Longitude',
                inputMap: 'Cliquez dans le texte pour afficher la carte',
                altitude: 'Altitude',
                address: 'Adresse',
                street: 'Rue',
                additionalAddress: "Complément d'adresse",
                postalCode: 'Code postal',
                city: 'Ville',
                country: 'Pays',
                images: 'Images',
                image: 'Image',
                showImages: 'Afficher toutes les images',
                attachImages: 'Ajouter des images',
                attachOneImage: 'Ajouter une image',
                attachImage: 'Importer une image',
                attachDocuments: 'Ajouter des documents',
                statistics: 'Statistiques',
                statistics_redirection: { title: "Statistiques détaillé de l'objet" },
                extradata: 'Données supplémentaires',
                amountNotes: 'Nombre de notes',
                lastNote: 'Dernière note',
                stateObject: "Statut de l'objet",
                enabled: 'Active',
                enabledDate: "Date d'activation",
                extradata_infos: {
                    dimension: 'Dimension',
                    profession_data: 'Données Métier',
                    empty_:
                        "Veuillez lancer le Finger (WebApp) afin de les consulter ou contactez l'admin pour la" +
                        ' personnalisation',
                },
                tracking: {
                    name_state: 'Statut',
                    date_state: 'Date du Statut',
                    _date_state: 'Date&#8239du&#8239Statut',
                    title: 'Suivie',
                    goNext: 'Allez ensuite',
                    goPrevious: 'Aller précédent',
                    selectNode: 'Sélectionnez un nœud',
                    fullName: 'Nom et prénom',
                    currentNode: 'Nœud actuel',
                },
                nature: 'Type de MC',
                filter: {
                    name: 'Filtre',
                    note: 'Notes',
                    traceability: 'Traçabilité',
                    avatarGroup: "Groupe d'objet",
                    history_created_at: {
                        value: 'Date historique',
                        afterFilter: 'Date historique - Après',
                        beforeFilter: 'Date historique - Avant',
                    },
                    metadataAvatarType: "Type d'objet",
                    company: 'Entreprise',
                    apply: 'Appliquer en tant que filtre',
                },
                redirectTo: {
                    show: {
                        company: 'Afficher dans la page entreprise',
                        metadataAvatarType: "Afficher dans la page type d'objet",
                    },
                },
                groupAvatar: {
                    name: 'Groupes',
                },
                createdAt: 'Date de Création',
                mcs: 'Modules de Communication',
                position: "Position de l'objet",
            },
            export: {
                historic: {
                    csv: 'Historique (CSV)',
                    xlsx: 'Historique (XLSX)',
                },
                objects: {
                    csv: 'Inventaire (CSV)',
                    xlsx: 'Inventaire (XLSX)',
                },
            },
            deploy: 'Deploy',
            nature: {
                nfc: 'NFC',
                st25: 'ST25',
                qrcode: 'QrCode',
            },
            generate: {
                simulate: 'Simuler',
                create_from_file: "Créer des MCs à partir d'un fichier",
            },
            description: 'Description',
            information: 'Information',
            historic: 'Historique',
            objects: 'Objets',
            alphaId: 'Référence',
            object: 'Objet',
            typesObjects: "Type d'objets",
            groupObjects: "Groupe d'objets",
            type: 'Type Objet',
            name: 'Nom',
            nameObject: 'Nom de l’objet',
            photoObject: 'Photo de l’objet',
            moduleCommunications: 'Module Communicants',
            location: 'Location',
            date: 'Date de Création',
            _date: 'Date&#8239de&#8239Création',
            interfaces: 'Interface',
            finger: 'Finger',
            mc: 'Modules Communicants',
            notDefined: 'Non défini',
            documents: 'Documents',
            applications: 'Service de redirections',
            webServiceDisplay: 'Service Web à afficher',
            quantityMCs: 'Nombre de Modules',
            url: 'Url',
            addObject: 'Ajouter un objet',
            createModal: {
                p1text1: 'Pour enregistrer ou ajouter un objet sur la' + " plateforme, vous devez utiliser l'",
                p1text2: "application mobile d'appairage",
                p1text3: " sur l'",
                p1text4: 'objet réel',
                p1text5: ' integrant un ou plusieurs module(s) communicant(s) type NFC ou Qrcode.',
                p2text1: "Pour plus d'informations contactez le ",
                p2text2: 'support',
            },
            deleteModal: "Êtes vous sûre de vouloir archiver l'élément(s) séléctionné(s)?",
            deleteModalAlways: "Êtes vous sûre de vouloir supprimer l'élément(s) séléctionné(s)?",
            deleteModalNotCancel: "Êtes vous sûre de vouloir supprimer l'élément séléctionné ?",
            filters: {
                shortUrl: 'ShortUrl',
                factory: 'Usine',
                business: 'Affaire',
                metadataAvatarType: "Type d'Objet",
                site: 'Chantier',
                typeObject: "Nom du type d'objet",
                objectName: "Nom de l'objet",
                history_name: 'Nom du Statut',
                objectGroup: "Groupe d'objet",
                history_created_at: {
                    value: "Date de l'historique",
                    afterFilter: "Date de l'historique - Après",
                    beforeFilter: "Date de l'historique - Avant",
                },
            },
            typeObject: {
                applicationDisponibles: 'Service de redirection disponibles',
            },
            createObjectMultiple: {
                helpText: {
                    step1Part1:
                        'Vous êtes sur le point de créer un objet manuellement. Il existe une manière plus optimisée' +
                        ' de créer un objet, pour cela voir cette page',
                    step1CreateObject: 'Comment créer un objet',
                    step1Part2: "Choisissez votre type d'objet pour préconfigurer votre nouvel objet",
                    step2ValidatePreFillData: 'Validez ces données pré-remplies',
                    step3Line1:
                        "Pour importer les données d'un fichier G-Sheet, veuillez à ce que le format de donnée soit" +
                        ' compatible.',
                    step3Line2: 'Veuillez insérer le lien de partage du fichier G-Sheet.',
                    step3Line3:
                        'Vous devez choisir le numéro ligne à importer, de la première ligne à la dernière ligne' +
                        ' séparé par un tiret, exemple "2-6".',
                    step3Line4: "Les lignes suivantes vont être crée en tant qu'objet :",
                    withoutFile: 'Sans fichier',
                    withFile: 'Création avec un tableau G-sheet :',
                    amount: "Nombre d'objets à créer",
                },
                errors: {
                    readLine: "Les numéros de ligne à importer n'ont pas de données",
                    nogapi: "Impossible d'accéder à l'API google. Veuillez contacter l'administrateur du service.",
                    emptyLine: 'Un problème est survenu. Une ligne sans valeur',
                    spreadsheet:
                        "Une erreur s'est produite lors de l'importation des données à partir de la feuille de calcul",
                    notFoundPropertyInTable: "La proprieté %{property} n'est pas dans le tableau %{table}",
                    notFoundTableInSpreadsheet: "La %{table} n'est pas dans le spreadsheet",
                    notFoundDataInTableInSpreadsheet: "La %{table} n'a pas des données",
                },
                title: 'Créer plusieurs objets',
            },
            createObject: {
                finger: {
                    add: 'Ajouter des redirections',
                },
                groupObjects: { add: "Groupe d'objets" },
                error: {
                    simulateMc: "La simulation des mcs n'a pas été effectuée",
                    createMc: "MC n'a pas pu être créé",
                    addToGroup: "Ce n'est pas possible d'ajouter l'objet au groupe d'objets",
                },
                notification: {
                    addToGroup: "L'objet a été ajouté au groupe",
                },
                title: 'Créer un objet',
                helpText: {
                    createObject:
                        'Vous être sur le point de créer un objet manuellement. Il existe une façon plus optimisé' +
                        ' pour créer un objet, pour cela consultez cette page ',
                    createObjectPage: 'Comment créer un objet',
                    chooseTypeObject: "Choisissez votre type d'objet pour pré-configurer votre nouvel objet : ",
                },
                advancedForm: 'Formulaire Avancé',
                simplifiedForm: 'Forme Simplifiée',
                // create: "Créer",
                validatePrevData: 'Validez les données pré-remplies',
            },
            editObject: {
                title: 'Modifier l’objet',
                add: {
                    mc: {
                        title: "Ajouter un Module Communicant à l'objet",
                        error: "Le Module Communicant n'a pas été ajouté",
                        added: 'Le Module Communicant a été ajouté',
                    },
                },
            },
            notSelected: "Il n'y pas un objet est sélectionné",
            bulk_action: {
                titleAddToGroup: "Choisissez un groupe d'objet afin de mettre à jour les objects choisis",
                titleChangeFinger: 'Choisissez une redirection afin de mettre à jour les objects choisis',
                titleChangeFingerForMC: 'Choisissez une redirection afin de mettre à jour le MC',
                titleAddTraceability:
                    'Choisissez un processus de traçabilité à modifier. Veuillez noter que le changement ne fonctionne' +
                    " que si l'objet utilise une application Web qui utilise la traçabilité (ex: MemoTagPro)",
                titleRemoveGroup: "Choisissez le groupe que vous souhaitez dissocier de l'objet sélectionné",
                addToObjectGroup: 'Grouper',
                removeToObjectGroup: 'Dissocier',
                changeFinger: 'Redirection',
                error: {
                    title: "Certains objets n'ont pas été mis à jour",
                    titleUpdateMCs: "Le MC n'a pas été mis à jour",
                    headerTable: 'Erreur',
                    notAdded: "L'objet n'a pas été ajouté au groupe",
                    notChangeApp: "La redirection n'a pas été modifiée",
                    notChangeTraceability: "La traçabilité n'a pas été modifiée",
                    notRemoveFromGroup: "L'objet n'a pas été supprimé au groupe",
                    diferentCompany:
                        'Veuillez vérifier que tous les objets appartiennt au même compte du groupe d’objet' +
                        ' %{object_group_name}',
                },
                addTraceability: 'Traçabilité',
            },
            list: {
                actions: {
                    customize_list: 'Personnaliser',
                },
            },
            actions: {
                deleteAction: {
                    name: 'Supprimer',
                    keepMcsName: 'Supprimer et garder le Module de Com',
                },
                changeWebApp: "Modifier l'application web",
                simulateWebApp: 'Simuler Web App',
                changeTraceability: 'Modifier la traçabilité',
                additionalInformation: 'Information Additionnelle',
            },
            objectMenu: {
                title: 'Chosissez un objet',
            },
            service: 'Nom du service de redirection',
            additionalInformation: {
                title: 'Information additionnelle',
                name: 'Titre de l’information',
                googleSheetURL: 'G-Sheet URL',
                lineNumber: 'Choix du numéro de ligne',
                value: 'Valeur (données) d’information',
                creator: 'Créateur',
                private: "Règle d'affichage",
                fieldType: 'Type d’information',
                unit: 'Unité',
                definition: 'Definition name',
                lineToImport: 'Choice number of line (example "1-4")',
                privateOptions: {
                    public:
                        "Public : l'information sera visible sans avoir besoin de s'identifier avec un compte" +
                        " depuis les Web Applications utilisant les informations supplémentaire de l'objet.",
                    private:
                        "Privé : la donnée de l'information sera crypté et pourra être visible lorsque qu'un" +
                        ' utilisateur ayant les droit est identifié.',
                },
                fieldTypeOptions: {
                    string: 'Texte',
                    number: 'Number',
                    boolean: 'Booléen (“oui” or “non)',
                    date: 'Date',
                },
                addProperty: {
                    helpText:
                        "Définissez l'information supplémentaire. Le choix de l'affichage permet de définir si" +
                        " l'information est accessible sans mot de passe depuis les Applications Mobile Web" +
                        ' compatibles (contactez nous pour en savoir plus). La définition permet de regrouper' +
                        ' des informations entre elles.',
                    finish: 'Terminer',
                },
                helpText: {
                    additionalInformation:
                        'Configurez les informations supplémentaires que vous souhaitez enregistrer sur votre objet',
                    name: 'Définissez le titre de l’information supplémentaire',
                    private:
                        "Le choix de l'affichage défini si l'information est accessible sans mot de passe depuis" +
                        ' les Applications Mobile Web compatible (contactez nous pour en savoir plus).',
                    fieldType:
                        "Le type d'information est paramètre qui peut être utilisé dans des fonctionnalités tiers" +
                        ' ou Application Mobile compatible (contactez nous pour en savoir plus).',
                    definition: 'La définition permet de regrouper des informations entre elles.',
                    googleSheet:
                        "Afin d'importer des données à partir d'un fichier G-Sheet, veuillez vous assurer que le" +
                        ' format de données est compatible',
                    googleSheetURL: 'Veuillez insérer le lien de partage de fichiers G-Sheet.',
                    googleSheetLine: 'Vous devez écrire le numéro de ligne à importer.',
                    createSpreadsheetsTemplate:
                        'Configurez les propriétées que vous voulez générer dans votre fichier G-Sheet',
                    spreadsheetCreate: 'Le modèle G-Sheet a été créé',
                    unit: 'Unité',
                },
                error: {
                    spreadsheet:
                        "Une erreur s'est produite lors de l'importation des données à partir de la feuille de calcul",
                    spreadsheetURL: "L'URL doit être une Spreadsheet",
                    noData: 'Aucune donnée disponible.',
                    googleSheetLine: 'Le numéro de ligne doit être supérieur à un',
                    properties: 'Vous devez ajouter au moins une propriété',
                    addSheets: "Verifiez que le G-Sheet n'a pas des données",
                    addColumnsData: "Une erreur s'est produite lors de l'ajout de colonnes dans la feuille SC-DATA",
                    addDataTypes: "Une erreur s'est produite lors de l'ajout de colonnes dans la feuille SC-DATA-TYPE",
                    noValue: 'Valeur indéfinie',
                },
                actions: {
                    addProperty: 'Ajouter une propriété',
                    editProperty: 'Modifier la propriété',
                    importDataGS: 'Importer les données GSheet',
                    createGSTemplate: 'Créer un modèle G-Sheet',
                    showPrivate: 'Propriétés Privées visibles',
                    hidePrivate: 'Propriétés Privées invisibles',
                    importDataTypeObject: 'Importer une donnée du type d’objet',
                    addNewName: 'Nouveau nom',
                    // createProperty: ' ',
                },
                tooltipActionIcons: {
                    edit: 'Modifier',
                    remove: 'Effacer',
                    private: 'Information Privée',
                },
            },
        },
        qrCode: 'QR-Code',
        return: 'Retour',
        selectIcon: {
            select: "Sélectionner l'icône",
        },
        serviceProviders: {
            title: 'Prestataire',
            assigned: ' Affectation des incidents',
            withContract: 'Avec contrat',
            withoutContract: 'Sans contrat',
            withoutAndWithContract: 'Avec et sans contrat',
            with: 'Avec',
            without: 'Sans',
            notAttributed: 'Non attribué',
            assignedByPossibleIncidents: 'Providers assigned / Types of possible incidents',
            name: 'Prénom',
            username: 'Utilisateur',
            lastName: 'Nom',
            company: 'Entreprise',
            address: 'Adresse complète',
            email: 'Email',
            postalCode: 'Code postal',
            menuActions: {
                incidentAttribution: "Attribution automatique d'incident",
            },
            create: {
                title: 'Créer un prestataire',
                input: {
                    name: 'Nom',
                    lastName: 'Prénom',
                    company: 'Entreprise',
                    address: 'Adresse',
                    postalCode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                    telephone: 'Numéro de téléphone',
                    email: 'Adresse Email',
                },
            },
            edit: {
                title: 'Modifier un prestataire',
                input: {
                    name: 'Nom',
                    lastName: 'Prénom',
                    company: 'Entreprise',
                    address: 'Adresse',
                    postalCode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                    telephone: 'Numéro de téléphone',
                    email: 'Adresse Email',
                },
            },
            delete: {
                title: 'Supprimer le prestataire ',
                subtitle: 'Êtes vous sûr de vouloir supprimer ce prestaire?',
            },
            deleted: 'Le prestataire a étè bien supprimé',
            tag: {
                title: 'Tag',
                serviceProvider: 'Prestataire',
            },
            enabled: 'Activé',
            lastLogin: 'Dernière connection',
            isSemiAnonymous: 'Semi-Anonymous',
            breadcrumbs: {
                1: 'Operations',
                0: 'Prestataires',
            },
        },
        statistics: {
            breadcrumbs: {
                1: 'Dashboard',
                0: 'Dashboard %{company_name}',
            },
        },
        subCategory: {
            breadcrumbs: {
                1: 'Configuration',
                0: 'Sous catégories',
            },
            categories: 'Catégories',
            label: 'Sous catégorie',
            category: 'Catégorie',
            fileSystemMapperEnabled: 'Gestion de documents',
            create: {
                title: 'Créer une sous catégorie',
                input: {
                    name: 'Nom',
                    image: 'Icône',
                    categories: 'Choissisez une catégorie',
                    fileSystemMapperEnabled: 'Gestion de documents',
                    iconSource: "Activer (icône) - Désactiver (icône d'image)",
                },
                notification: 'Sous catégorie créée avec succès',
                error: 'Il y a eu une erreur lors de la création de la sous catégorie',
                errors: {
                    subcategoryNotUpdated: 'Il y a eu une erreur lors de la création de la sous catégorie',
                    subcategoryNotAdded: 'Il y a eu une erreur lors de la création de la sous catégorie',
                    iconNotAdded: 'Icône non ajoutée',
                    propertyNotCreated: 'Propriété non créée',
                },
            },
            edit: {
                title: 'Modifier la sous catégorie',
                input: {
                    name: 'Nom',
                    image: 'Icône',
                    notification: 'La sous catégorie a été modifié avec succès',
                    categories: 'Choissisez une catégorie',
                    fileSystemMapperEnabled: 'Gestion de documents',
                    iconSource: "Activer (icône) - Désactiver (icône d'image)",
                },
                error: 'Il y a eu une erreur lors de la mofication de la sous catégorie',
                errors: {
                    iconNotRemoved: "Il y a eu une erreur lors de la suppression de l'icone",
                    subcategoryNotUpdated: 'Il y a eu une erreur lors de la modification de la sous catégorie',
                    iconNotAdded: "Il y a eu une erreur lors de l'ajoute de l'icone",
                },
            },
            delete: {
                title: 'Supprimer la sous catégorie ',
                subtitle: 'Êtes vous sûr de vouloir supprimer cette sous catégorie?',
            },
            archive: {
                title: 'Archiver la sous catégorie',
                subtitle: 'Êtes vous sûr de vouloir archiver %{name}?',
                success: 'La sous catégorie a étè bien archivé',
                error: "Il y a eu une erreur lors de l'archivage de la sous catégorie",
            },
        },
        users: {
            breadcrumbs: {
                0: 'Utilisateurs',
                1: 'Configuration',
            },
            changePassword: 'Modifier mon mot de passe',
            passwordForgotten:
                "Veuillez saisir votre adresse électronique ou votre nom d'utilisateur et nous vous enverrons en mail avec la procédure à suivre pour le réinitialiser.",
            create: {
                title: 'Créer un utilisateur',
                success: "L'utilisateur a étè bien créé",
                fail: "Une erreur s'est produite lors de la création de l'utilisateur",
            },
            edit: {
                title: "Modfier l'utilisateur",
                success: "L'utilisateur a étè bien modifié",
                fail: "Une erreur s'est produite lors de la mise à jour de l'utilisateur",
            },
            delete: {
                title: "Supprimer l'utilisateur ",
                subtitle: "Êtes vous sûr de vouloir supprimer l'utilisateur %{username}?",
                success: "L'utilisateur a étè bien supprimé",
            },
            serviceProviderOrManager: 'Prestataire / Gestionnaire',
            serviceProviderPerson: 'Prestaire',
            serviceProviderCompany: 'Entreprise prestataire',
            companyName: "Nom de l'entreprise",
            type: 'Créer un :',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Mail',
            username: 'Utilisateur',
            phone: 'Numéro de téléphone',
            accessToService: 'Accès au service',
            serviceProvider: {
                label: 'Prestataire',
                type: 'Le prestataire est ',
                person: 'Une personne',
                company: 'Une entreprise',
                underContract: 'Sous contrat',
                serviceType: 'Type de prestation(s)',
                referenceClientOrContract: 'Réference client/contrat',
            },
            manager: {
                label: 'Gestionnaire',
            },
            subManager: {
                label: 'Sous gestionnaire',
            },
            resetPassword: {
                title: 'Réinitialiser votre mot de passe',
                emailSentMessage: "Un mail vient d'être envoyé, checkez votre boite aux mail.",
                overRetryTtl: 'Un mail a dèjà été  envoyé. Réessayez dans 10 minutes.',
                sendingEmail: "Le mail est en train de s'envoyer.",
                emailSent: 'Mail envoyé.',
                emailNoSent:
                    "Il y a eu un problème lors l'envoie de le mail. Veillez essayez plus tard. Si vous n'arrivez pas, contactez l'administrateur.",
            },
            errors: {
                get: "Il y a eu une erreur lors la récuperation de l'info de l'utilisateur",
                getAccessToService: 'Il y a eu une erreur lors la récuperation des accèss aux services',
            },
        },
        valid: 'Valider',
        yes: 'oui',
    },
};

export default locale;
