import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslate } from 'react-admin';
import BackDiv from '../../commons/BackDiv/BackDiv';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import IncidentsTypesList from './list/index';
import './styles.css';

const IncidentTypesPage = () => {
    const translate = useTranslate();

    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.incidentTypes.breadcrumbs.1')]}
                    lastItem={translate('app.incidentTypes.breadcrumbs.0')}
                />
            </Grid>

            <Grid item>
                <BackDiv>
                    {({ divHeight }: { divHeight: number }) => (
                        <div className='incidentsTable' style={{ height: divHeight }}>
                            <IncidentsTypesList divHeight={divHeight} />
                        </div>
                    )}
                </BackDiv>
            </Grid>
        </Grid>
    );
};

export default IncidentTypesPage;
