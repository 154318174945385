import { Identifier } from 'react-admin';
import { removedItemsActionCreator } from '../actions/removedItems';
import { clearTicketingStateCreator } from '../actions/ticketingState';

export interface RemovedItemsState {
    resources: {
        [key: string]: {
            [key: Identifier]: boolean;
        };
    };
}

const InitialState: RemovedItemsState = {
    resources: {},
};

const reducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case removedItemsActionCreator.type:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [action.payload.resource]: {
                        ...(action.payload.resource in state.resources ? state.resources[action.payload.resource] : {}),
                        [action.payload.id]: true,
                    },
                },
            };
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
