import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslate } from 'react-admin';
import { ChartDashboardId } from '../../../utils/types';
import { charts } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { registerGlobalDashboard } from '../../../redux/actions/dashboard';
import { v4 as uuidv4 } from 'uuid';
import { DashboardGlobalState } from '../../../redux/reducers/dashboards';
import { CustomReducerState } from '../../../redux/reducers';
import { ButtonFontWeightBoldStyles } from '../../../theme';

const useStyles = makeStyles({
    button: {
        paddingLeft: 10,
        paddingRight: 10,
        ...ButtonFontWeightBoldStyles,
    },
    icon: {
        marginRight: 5,
        marginTop: -1,
    },
    menuItem: {
        fontWeight: 500,
    },
});

const PersonalizeDashboardButton = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();

    const dashboardsEnabled: DashboardGlobalState = useSelector(
        (state: CustomReducerState) => state.ticketing.dashboards.global
    );

    const handleActiveChart = (id: ChartDashboardId, maxRepeated: number) => {
        if (canAdd(id, maxRepeated)) {
            dispatch(registerGlobalDashboard.fn(id, uuidv4()));
        }
    };

    const canAdd = (id: ChartDashboardId, maxRepeated: number) => {
        if (maxRepeated === -1) return true;

        let can = false;
        if (!(id in dashboardsEnabled)) {
            can = true;
        }

        if (!can && id in dashboardsEnabled && dashboardsEnabled[id].length < maxRepeated) {
            can = true;
        }

        return can;
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                className={classes.button}
                color='primary'
                aria-label='Menu'
                onClick={handleOpenMenu}
                data-test-sc={'buttonCustom'}
            >
                <MenuIcon className={classes.icon} />
                {translate('app.dashboards.actions.enableDashboards')}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                {charts.map((chart) => (
                    <MenuItem
                        key={chart.id}
                        // value={chart}
                        disabled={!canAdd(chart.id, chart.maxRepeated)}
                        onClick={() => handleActiveChart(chart.id, chart.maxRepeated)}
                        // disabled={!chart.multiple && isChartActive(chart)}
                        className={classes.menuItem}
                    >
                        {translate(chart.labelShort ? chart.labelShort : chart.label)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
export default PersonalizeDashboardButton;
