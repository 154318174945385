import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { IncidentStatus } from '../../../utils/CONST';
//@ts-ignore
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ListColumn from './ListColumn';
import { APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST } from '../../../theme';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { changeKanbanListOrder } from '../../../redux/actions/incidents';
import { CustomReducerState } from '../../../redux/reducers';
import { ReduxState } from 'react-admin';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const reorderQuoteMap = ({
    quoteMap,
    source,
    destination,
}: {
    quoteMap: any[];
    source: { index: number; droppableId: string };
    destination: { droppableId: string; index: number };
}) => {
    //@ts-ignore
    const current = [...quoteMap[source.droppableId]];
    //@ts-ignore
    const next = [...quoteMap[destination.droppableId]];
    const target = current[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
        const reordered = reorder(current, source.index, destination.index);
        const result = {
            ...quoteMap,
            [source.droppableId]: reordered,
        };
        return {
            quoteMap: result,
        };
    }

    // moving to different list
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    const result = {
        ...quoteMap,
        [source.droppableId]: current,
        [destination.droppableId]: next,
    };

    return {
        quoteMap: result,
    };
};

const Container = styled('div')({
    display: 'inline-flex',
    width: window.innerWidth - 325,
});

const Root = styled('div')({});

const dataModel = Object.keys(IncidentStatus).map((state, index) => ({
    id: index,
    name: state,
}));

interface DragEndResult {
    combine: any | null;
    destination: { droppableId: string; index: number };
    draggableId: string;
    mode: string;
    reason: string;
    source: { index: number; droppableId: string };
    type: string;
}

const useStyles = makeStyles({
    root: {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#000000',
            border: '2px solid #555555',
        },
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '10px !important',
            backgroundColor: '#F5F5F5',
        },
    },
});

const DndKanbanBoardColumList = (props: { defaultFilters: any }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const itemOrdered = useSelector(
        (state: CustomReducerState & ReduxState) => state.ticketing.incidents.forKanban.columnOrder
    );

    const enabledColumns: { [key: string]: boolean } = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.customizedColumns.KANBAN_LIST
    );

    const [ordered, setOrdered] = useState(itemOrdered);
    const [columns, setColumns] = useState(dataModel);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [version, setVersion] = useState(1);

    const onDragEnd = (result: DragEndResult) => {
        console.log('onDragEnd', result);
        // console.log(result, columns, ordered);
        if (result.combine) {
            if (result.type === 'COLUMN') {
                const shallow = [...ordered];
                shallow.splice(result.source.index, 1);
                setOrdered(shallow);
                return;
            }

            //@ts-ignore
            const column = columns[result.source.droppableId];
            const withQuoteRemoved = [...column];
            withQuoteRemoved.splice(result.source.index, 1);
            const newColumns = {
                ...columns,
                [result.source.droppableId]: withQuoteRemoved,
            };
            setColumns(newColumns);
            return;
        }

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        // did not move anywhere - can bail early
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        // reordering column
        if (result.type === 'COLUMN') {
            const newOrdered = reorder(ordered, source.index, destination.index);
            setOrdered(newOrdered);
            return;
        }

        const data = reorderQuoteMap({
            quoteMap: columns,
            source,
            destination,
        });
        setColumns(data.quoteMap);
    };

    const updateOrderedColumnsShown = () => {
        Object.keys(enabledColumns).map((stateNameFromEnabledColumns) => {
            const stateNameFoundIndex = ordered.findIndex(
                (columnOrdered) => stateNameFromEnabledColumns === columnOrdered
            );
            if (stateNameFoundIndex >= 0 && !enabledColumns[stateNameFromEnabledColumns]) {
                ordered.splice(stateNameFoundIndex, 1);
                setOrdered(ordered);
                setVersion(version + 1);
                dispatch(changeKanbanListOrder.fn(ordered));
            }
            if (stateNameFoundIndex < 0 && enabledColumns[stateNameFromEnabledColumns]) {
                const initialPosition = Object.keys(IncidentStatus).findIndex(
                    (state) => state === stateNameFromEnabledColumns
                );
                if (initialPosition >= 0) {
                    ordered.splice(initialPosition, 0, stateNameFromEnabledColumns);
                    setOrdered(ordered);
                    setVersion(version + 1);
                    dispatch(changeKanbanListOrder.fn(ordered));
                }
            }
        });
    };

    useEffect(() => {
        if (dataLoaded) {
            dispatch(changeKanbanListOrder.fn(ordered));
        }
    }, [ordered]);

    useEffect(() => {
        if (dataLoaded) {
            updateOrderedColumnsShown();
        }
    }, [enabledColumns]);

    useEffect(() => {
        setDataLoaded(true);
    }, []);

    const board = (
        <Droppable
            droppableId='board'
            type='COLUMN'
            direction='horizontal'
            className={classes.root}
            // ignoreContainerClipping={Boolean(containerHeight)}
            // isCombineEnabled={this.props.isCombineEnabled}
        >
            {(provided: any) => {
                return (
                    <Container key={'container-columns'} ref={provided.innerRef} {...provided.droppableProps}>
                        {ordered &&
                            Array.isArray(ordered) &&
                            ordered.map((key, index) => (
                                <ListColumn
                                    key={`${key}_${index}`}
                                    index={index}
                                    state={key}
                                    defaultFilters={props.defaultFilters}
                                />
                            ))}
                        {provided.placeholder}
                    </Container>
                );
            }}
        </Droppable>
    );
    return <Root>{version && <DragDropContext onDragEnd={onDragEnd}>{board}</DragDropContext>}</Root>;
};

export default DndKanbanBoardColumList;
