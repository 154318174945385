export const resolveApiSchemaCreator = {
    action: '360SC_ANOMALY_TICKETING/RESOLVE_API_SCHEMA',
    fn: () => ({
        type: resolveApiSchemaCreator.action,
        payload: {}
    })
}

export const resolvedApiSchemaCreator = {
    action: '360SC_ANOMALY_TICKETING/RESOLVED_API_SCHEMA',
    fn: (apiSchema: any) => ({
        type: resolvedApiSchemaCreator.action,
        payload: { apiSchema }
    })
}