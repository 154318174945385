import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface Props {
    open: boolean;
    autoplay: boolean;
    handleOnClose: () => void;
    imageJsons: Array<ImageJson>;
}

interface State {
    photoIndex: number;
}

type ImageJson = {
    name: string;
    url?: string;
    [key: string]: any;
};

const ASSETS_ROOT = process.env.NEXT_PUBLIC_ASSETS_ROOT;

const getImageUrl = (image: any) => {
    return image
        ? 'url' in image && image.url
            ? image.url
            : `${ASSETS_ROOT}/files/${image['name']}`
        : `${ASSETS_ROOT}/images/${IMAGE_LOADING}`;
};

const convertImagesJsonToImagePath = (imagesJson: ImageJson) => {
    return getImageUrl(imagesJson);
};

const IMAGE_LOADING = 'default-image-loading.svg';

const staticClassName = '___Lightbox___showimages';

const ShowImages = ({ open, handleOnClose, imageJsons }: any) => {
    let interval: any;
    let intervalRepetition = 1000;

    const [photoIndex, setPhotoIndex] = useState(0);

    const updateReactModalZIndex = () => {
        if (open && intervalRepetition > 0) {
            interval = setInterval(() => {
                const nodeList = document.querySelectorAll(`.${staticClassName}`);

                if (nodeList.length > 0) {
                    const firstItem: any = nodeList[0];

                    firstItem.parentElement.parentElement.style.zIndex = '100000';

                    clearInterval(interval);
                }

                intervalRepetition -= 1;
            }, 10);
        }
    };

    useEffect(() => {
        updateReactModalZIndex();
    }, []);

    const imagePaths = imageJsons.map((imageJson: any) => convertImagesJsonToImagePath(imageJson));

    return (
        // @ts-ignore
        <Lightbox
            wrapperClassName={staticClassName}
            mainSrc={imagePaths[photoIndex]}
            nextSrc={imagePaths[(photoIndex + 1) % imagePaths.length]}
            prevSrc={imagePaths[(photoIndex + imagePaths.length - 1) % imagePaths.length]}
            onCloseRequest={handleOnClose}
            onMovePrevRequest={() => {
                setPhotoIndex((photoIndex + imagePaths.length - 1) % imagePaths.length);
            }}
            onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % imagePaths.length);
            }}
            imageTitle={``}
        />
    );
};

export default ShowImages;
