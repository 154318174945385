import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { useCreate, useTranslate } from 'react-admin';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { REORDER_AVATAR } from '../../../../providers/resources';
import { Avatar } from '../../../../utils/types';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';
import { useActionsContext } from '../../../../commons/tree/useActionsContext';

interface Props {
    onClose: () => void;
    onCancel: () => void;
}

const ReorderItemDialog = ({ onClose, onCancel }: Props) => {
    const translate = useTranslate();
    const [create] = useCreate();
    const queryClient = useQueryClient();
    const { reorderTo, itemToReorder } = useActionsContext();

    const [loadingReorder, setLoadingReorder] = useState(false);

    const reorderAvatarRequest = (data: any) => {
        // @ts-ignore
        const parentId =
            itemToReorder && 'parent' in itemToReorder && itemToReorder.parent ? itemToReorder.parent['@id'] : null;

        let list: any = [];

        if (parentId)
            // @ts-ignore
            list = queryClient.getQueriesData([`navigationParent_${extractIdFromURI(parentId as string)}`])[0][1].data;

        // @ts-ignore
        const indexItem1 = list.findIndex((item: Avatar) => item['@id'] === itemToReorder['@id']);
        // @ts-ignore
        const indexItem2 = list.findIndex((item: Avatar) => item['@id'] === reorderTo['@id']);

        const difference = indexItem1 - indexItem2;
        let amount = 0;
        let upOrDownValue = false;
        if (difference > 0) {
            upOrDownValue = true;
            if (upOrDown === 'down') {
                amount = difference - 1;
            } else {
                amount = difference;
            }
        } else {
            upOrDownValue = false;
            const differenceInverse = difference * -1;
            if (upOrDown === 'up') {
                amount = differenceInverse - 1;
            } else {
                amount = differenceInverse;
            }
        }

        if (amount === 0) {
            onClose();
        } else {
            setLoadingReorder(true);
            // @ts-ignore
            create(
                REORDER_AVATAR,
                // @ts-ignore
                JSON.stringify({
                    ...data,
                    upOrDown: upOrDownValue,
                    amount,
                }),
                {
                    onSuccess: () => {
                        setLoadingReorder(false);

                        if (parentId) {
                            queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(parentId as string)}`]);
                        }

                        onClose();
                    },
                    onFailure: (error: any) => {
                        setLoadingReorder(false);
                        onClose();
                    },
                }
            );
        }
    };

    const [upOrDown, setUpOrDown] = useState<string | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpOrDown((event.target as HTMLInputElement).value);
    };

    const handleReorder = () => {
        // @ts-ignore
        reorderAvatarRequest({ item: itemToReorder['@id'] });
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.reorderDialog.title', { name: itemToReorder?.name })}
            open={true}
            onClose={() => null}
            icon={<SettingsIcon />}
            maxWidth={'xs'}
            buttons={
                <DialogTemplateButtons
                    withCancel
                    onCancelClick={onCancel}
                    withDelete
                    onDeleteClick={handleReorder}
                    deleteButtonLabel={translate('app.buttons.validate')}
                    isValidForm={upOrDown !== null}
                    isSubmitting={loadingReorder}
                />
            }
        >
            <Grid container direction={'column'}>
                <Grid item>
                    <FormControl>
                        <RadioGroup name='controlled-radio-buttons-group' value={upOrDown} onChange={handleChange}>
                            <FormControlLabel
                                value='up'
                                control={<Radio color={'primary'} />}
                                label={translate('app.navigation.reorderDialog.position.up', { name: reorderTo?.name })}
                            />
                            <FormControlLabel
                                value='down'
                                control={<Radio color={'primary'} />}
                                label={translate('app.navigation.reorderDialog.position.down', {
                                    name: reorderTo?.name,
                                })}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </DialogTemplate>
    );
};

export default ReorderItemDialog;
