import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import { index_no_token } from '../../pages/paths';

interface Props {
    redirectTo: string;
    children: any;
}

const Authentication = (props: Props) => {
    const user = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);

    return (
        <>
            {user && user.id && props.children}
            {(!user || !user.id) && <Redirect to={`${index_no_token}?redirectTo=${props.redirectTo}`} />}
        </>
    );
};

export default Authentication;
