import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../utils/strings';
import { renderProgress } from '../render-progress/render-progress';
import { DataGrid } from '@material-ui/data-grid';
import { toFormatDate } from '../../utils/date';
import { FieldsDashboard } from '../../utils/types';

const useStyles = makeStyles({
    root: {
        border: 'none',
        height: 480,
        width: 1500,
    },
});

interface Props {
    data: any;
    fieldsToShow?: FieldsDashboard;
}
const getTimeFormatted = (item: { name: string }, fieldsToShow: { [key: string]: any }) => {
    return fieldsToShow &&
        item.name in fieldsToShow &&
        'type' in fieldsToShow[item.name] &&
        fieldsToShow[item.name].type === 'time_hours'
        ? { renderCell: (params: any) => <span>{`${parseFloat(params.value).toFixed(0)}h`}</span> }
        : {};
};

const getDateFormatted = (item: { name: string }, fieldsToShow: { [key: string]: any }) => {
    return fieldsToShow &&
        item.name in fieldsToShow &&
        'type' in fieldsToShow[item.name] &&
        fieldsToShow[item.name].type === 'date'
        ? { renderCell: (params: any) => <span>{toFormatDate(params.value)}</span> }
        : {};
};

const DashboardTable = ({ data: dataProp, fieldsToShow = {} }: Props) => {
    const classes = useStyles();
    const [data, setData] = useState({ rows: [], columns: [] });

    const convertDataToShow = (data: any) => {
        if (!data || !('data' in data) || !data?.data || !('cols' in data?.data) || !('rows' in data?.data))
            return { rows: [], columns: [] };

        const columns = data.data.cols.map((item: any) => {
            return {
                field: item.name,
                headerName: capitalizeFirstLetter(
                    fieldsToShow && item.name in fieldsToShow ? fieldsToShow[item.name].label : item.name
                ),
                ...(fieldsToShow && item.name in fieldsToShow && 'width' in fieldsToShow[item.name]
                    ? { width: fieldsToShow[item.name].width }
                    : { width: 300 }),
                ...(fieldsToShow &&
                item.name in fieldsToShow &&
                'type' in fieldsToShow[item.name] &&
                fieldsToShow[item.name].type === 'percent'
                    ? { renderCell: renderProgress }
                    : {}),
                ...getDateFormatted(item, fieldsToShow),
                ...getTimeFormatted(item, fieldsToShow),
            };
        });

        const rows = data.data.rows.map((row: any, index: number) => {
            const out: any = {};
            out['id'] = index;
            columns.map((column: any, index: number) => {
                out[column.field] = row[index];
            });
            return out;
        });

        const newColumns: any = [];
        columns.map((item: any) => {
            if (item.field in fieldsToShow) {
                newColumns.push(item);
            }
        });

        return {
            rows,
            columns: newColumns,
        };
    };

    useEffect(() => {
        setData(convertDataToShow(dataProp));
    }, [dataProp]);

    return <DataGrid rows={data.rows} columns={data.columns} pageSize={7} className={classes.root} />;
};

export default DashboardTable;
