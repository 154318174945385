import { TicketingBridgeMetabaseViewDynamic } from '../types';

export const GET_PATH_VIEWS_DYNAMIC_ID_API = 9;
export const INCIDENT_FILTERED_BY_CATEGORY_VIEWS_DYNAMIC_ID_API = 2;

export interface ResponseForwarderToMetabaseType {
    data: {
        rows: string[][];
        cols: {
            display_name: string;
            source: string;
            field_ref: [];
            name: string;
            base_type: string;
            effective_type: string;
        }[];
    };
    loading: boolean;
}

const getViewsDynamic = async (
    token: string | undefined,
    id: number,
    filters: { [key: string]: any }
): Promise<TicketingBridgeMetabaseViewDynamic | null> => {
    if (!token || !id) return null;
    const url = `${process.env.REACT_APP_TICKETING_BRIDGE_METABASE_URI_ENTRYPOINT}/api/views/viewsdynamic`;
    const body = {
        token,
        dashboard_type: 'question',
        id,
        ...filters,
    };
    return await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((response: Response) => {
        if (response.ok && response.status === 200) {
            return response.json();
        }
        return null;
    });
};

const getResponseForwarderToMetabaseApi = async (url: string): Promise<ResponseForwarderToMetabaseType | null> => {
    return await fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
    })
        .then((response: Response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            return null;
        })
        .catch((error: any) => {
            console.error('ERROR in ', url, 'error message ', error);
            return null;
        });
};

const resolveNavigationPath = (responseData: ResponseForwarderToMetabaseType): string => {
    let result = '...';
    if (responseData.data && responseData.data.rows.length > 0) {
        const rows = responseData.data.rows;
        if (rows.length > 0 && Array.isArray(rows[0]) && rows[0].length >= 4) {
            result = rows[0][0];
        }
    }
    return result;
};

const getRowDataInResponseForwarderToMetabaseByDisplayName = (
    responseData: ResponseForwarderToMetabaseType,
    displayName: string
): string | null => {
    let result: (string | null)[] = [];
    let displayNameIndex: number | null = null;

    if (!responseData.data.rows || !responseData.data.cols) {
        console.log('getRowDataInResponseForwarderToMetabaseByDisplayName', responseData.data);
    }

    if (responseData.data && responseData.data.cols.length > 0) {
        displayNameIndex = responseData.data.cols.findIndex((col) => col.display_name === displayName);
    }
    if (responseData.data && responseData.data.rows.length > 0) {
        const rows = responseData.data.rows;
        if (rows.length > 0) {
            result = rows
                .map((rowArray) => (displayNameIndex ? rowArray[displayNameIndex] : null))
                .filter((value) => value !== null);
        }
    }
    return result && result.length > 0 ? result[0] : null;
};

const groupingRowsDataInResponseForwarderToMetabaseByDisplayName = (
    responseData: ResponseForwarderToMetabaseType,
    displayName: string
) => {
    let result: (string | null)[] = [];
    let displayNameIndex: number | null = null;

    if (responseData.data && responseData.data.cols.length > 0) {
        displayNameIndex = responseData.data.cols.findIndex((col) => col.display_name === displayName);
    }
    if (responseData.data && responseData.data.rows.length > 0) {
        const rows = responseData.data.rows;
        if (rows.length > 0) {
            result = rows
                .map((rowArray) => (displayNameIndex ? rowArray[displayNameIndex] : null))
                .filter((value) => value !== null);
        }
    }
    return result;
};

export {
    getViewsDynamic,
    getResponseForwarderToMetabaseApi,
    resolveNavigationPath,
    groupingRowsDataInResponseForwarderToMetabaseByDisplayName,
    getRowDataInResponseForwarderToMetabaseByDisplayName,
};
