import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { List, usePermissions } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { incidents } from '../../pages/paths';
import { AVATARS } from '../../providers/resources';
import { CustomReducerState } from '../../redux/reducers';
import { FieldHeaderListStyles } from '../../theme';
import {
    CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER,
    GROUP_METADATA_AVATAR_TYPE_EVENT,
    NodeTypeFiltersForHiddenClosedIncidents,
} from '../../utils/CONST';
import { detectBrowserName, FIREFOX_KEY } from '../../utils/detect-browser-name';

import { hasPermissions } from '../../utils/permissions';
import { ROLE_UPDATE_TRACEABILITY_STATE } from '../../utils/ROLES';
import { IncidentListViewMode, SIMPLE_LIST } from '../../utils/types';
import IncidentsListActions from './actions';
import {
    COUNTDOWN_COLUMN,
    EVENT_ASSIGNED_DATE,
    IMAGE_AND_COMMENT,
    INCIDENT_TIME_FROM_THE_BEGINNING_COLUMN,
    NAME_COLUMN,
    NAVIGATION_PATH_COLUMN,
    PIN_CODE_COLUMN,
    RAPPORT_DATE_COLUMN,
    RESOLVE_DATE_COLUMN,
    SERVICE_PROVIDER_COLUMN,
    STATE_COLUMN,
    WORK_RESOLUTION_DELAY,
} from './actions/dynamicColumns';

import FilterSidebar from './filters';
import IncidentBulkActionButtons from './IncidentBulkActionButtons';
import './index.css';
import { EnabledColumns } from './list/types';
import DndKanbanBoardColumList from './dndKanbanList';
import IncidentSimpleDatagrid from './list/CustomDatagrid';
import { registerCustomizeColumnsIncidentsInListView } from '../../redux/actions/incidents';

const useStyles = (divHeight: number, width: number) =>
    makeStyles({
        main: {
            height: divHeight,
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#000000',
                border: '2px solid #555555',
            },
            '&::-webkit-scrollbar': {
                width: '5px',
                height: '10px !important',
                backgroundColor: '#F5F5F5',
            },
        },
        content: {
            width,
            overflowY: 'scroll',
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#000000',
                border: '2px solid #555555',
            },
            '&::-webkit-scrollbar': {
                width: '5px',
                height: '10px !important',
                backgroundColor: '#F5F5F5',
            },
        },
        //@ts-ignore
        field: {
            ...FieldHeaderListStyles,
            // ...FieldCapitalizeListStyles,
        },
        fullWidthField: {
            ...FieldHeaderListStyles,
            width: 'fit-content',
        },
        gridItemAlignCenter: {
            alignSelf: 'center',
        },
        bulkActionsDisplayed: {
            marginTop: '0px !important',
        },
    });

interface Props {
    divHeight: number;
}

const IncidentsList = ({ divHeight }: Props) => {
    const classes = useStyles(divHeight - 40, window.innerWidth - (detectBrowserName() === FIREFOX_KEY ? 325 : 335))();
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const viewMode: IncidentListViewMode = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.viewMode
    );

    const columnsEnabled = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.customizedColumns.SIMPLE_LIST
    );
    const dispatch = useDispatch();

    const defaultFilters = {
        'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_EVENT,
        serialization_groups: [
            'read_meta',
            'read_mc',
            'read_simple_company',
            'read_avatar_current_referencing',
            'read_avatar_current_referencing_nodes',
            'read_meta_group_meta',
            // 'read_avatar_parent', // avatar parent
        ],
    };

    const listProps = {
        resource: AVATARS,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: incidents,
        sort: { field: 'creationDate', order: 'DESC' },
        perPage: 25,
        filter: defaultFilters,
        classes: {
            main: classes.main,
            content: classes.content,
            bulkActionsDisplayed: classes.bulkActionsDisplayed,
        },
        filterDefaultValues:
            viewMode === SIMPLE_LIST
                ? {
                      [CURRENT_HISTORY_NOTE_TYPES_ONE_FILTER]: NodeTypeFiltersForHiddenClosedIncidents,
                  }
                : {},
    };

    if (viewMode !== IncidentListViewMode.SIMPLE_LIST) {
        // @ts-ignore
        listProps['pagination'] = <span />;
    }

    const [enabledColumns, setEnabledColumns] = useState<EnabledColumns | { [key: string]: boolean }>(
        columnsEnabled && Object.keys(columnsEnabled).length === 0
            ? {
                  [STATE_COLUMN]: true,
                  [NAME_COLUMN]: true,
                  [IMAGE_AND_COMMENT]: false,
                  [NAVIGATION_PATH_COLUMN]: true,
                  [SERVICE_PROVIDER_COLUMN]: true,
                  [RAPPORT_DATE_COLUMN]: true,
                  [INCIDENT_TIME_FROM_THE_BEGINNING_COLUMN]: true,
                  [RESOLVE_DATE_COLUMN]: true,
                  [EVENT_ASSIGNED_DATE]: true,
                  [WORK_RESOLUTION_DELAY]: true,
                  [COUNTDOWN_COLUMN]: true,
                  [PIN_CODE_COLUMN]: true,
              }
            : columnsEnabled
    );

    const toggleColumn = (column: string, enabled: boolean) => {
        const newCols = {
            ...enabledColumns,
            [column]: enabled,
        };
        setEnabledColumns(newCols);
        dispatch(registerCustomizeColumnsIncidentsInListView.fn(newCols));
    };

    return (
        <div className='incidentsList' style={{ height: divHeight }}>
            <List
                {...listProps}
                exporter={false}
                aside={<FilterSidebar divHeight={divHeight} />}
                bulkActionButtons={
                    hasPermissions([ROLE_UPDATE_TRACEABILITY_STATE], loadedPermissions, permissions) && (
                        <IncidentBulkActionButtons />
                    )
                }
                actions={<IncidentsListActions toggleColumn={toggleColumn} enabledColumns={enabledColumns} />}
                empty={<span>{'...'}</span>}
                syncWithLocation
            >
                {/*<span />*/}
                {viewMode === IncidentListViewMode.SIMPLE_LIST ? (
                    <IncidentSimpleDatagrid enabledColumns={enabledColumns as EnabledColumns} />
                ) : (
                    // <IncidentKanbanColumns defaultFilters={defaultFilters} />
                    <DndKanbanBoardColumList defaultFilters={defaultFilters} />
                )}
            </List>
        </div>
    );
};

export default IncidentsList;
