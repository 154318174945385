import React from 'react';
import { USERS } from '../../../providers/resources';
import { TICKETING_MANAGER_TAG, TICKETING_SUB_MANAGER_TAG } from '../../../utils/CONST';
import { AutocompleteInput } from '../../../commons/ra/input/AutocompleteInput';
import ReferenceInput from '../../../commons/ra/input/ReferenceInput';
import { User } from '../../../utils/types';
import makeStyles from '@material-ui/styles/makeStyles';

interface Props {
    label: string;
    alwaysOn: boolean;
    disabled?: boolean;
    emptyText?: string;
}

const useStyles = makeStyles({
    inputFilter: {
        marginTop: '30px !important',
    },
});

const ManagerAndSubmanagerInputFilter = ({ label, alwaysOn, emptyText, disabled = false }: Props) => {
    const renderOptionText = (user: User | undefined) => {
        return user ? `${user?.username}` : emptyText ? emptyText : '';
    };

    return (
        <ReferenceInput
            key={'managerAndSubmanager_input_filter'}
            disabled={disabled}
            alwaysOn={alwaysOn}
            variant={'standard'}
            label={label}
            filterToQuery={(searchText: string) => ({
                lastName: searchText,
            })}
            filter={{
                'tags[one]': [TICKETING_SUB_MANAGER_TAG, TICKETING_MANAGER_TAG],
            }}
            reference={USERS}
            source={'ticketing_manager'}
        >
            <AutocompleteInput
                key={'managerAndSubmanager_autocomplate_input_filter'}
                resettable
                translateChoice={false}
                fullWidth
                emptyValue={null}
                optionText={renderOptionText}
                optionValue='id'
                filterToQuery={(searchText: string) => ({
                    lastName: searchText,
                })}
            />
        </ReferenceInput>
    );
};
export default ManagerAndSubmanagerInputFilter;
