import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
import React from 'react';
import { useTranslate } from 'react-admin';
import { TopToolbarButtonStyled } from '../../../theme';

const useStyles = makeStyles({
    topToolbar: {
        marginLeft: '0',
        marginRight: 'auto',
        // paddingTop: "8px",
        paddingBottom: '8px',
        flexDirection: 'row-reverse',
    },
    buttonFontWeightBold: {
        fontWeight: 'bold',
    },
    fontWeightBold: {
        fontWeight: 'bold',
    },
});

interface Props {
    toggleColumn: (column: string, enabled: boolean) => void;
    enabledColumns: {
        [key: string]: boolean;
    };
}

export const NAME_COLUMN = 'name';
// export const EQUIPMENT_COLUMN = "equipment";
export const NAVIGATION_PATH_COLUMN = 'navigationPath';
export const PLACES_COLUMN = 'places';
export const SHORT_ADDRESS_COLUMN = 'shortAddress';
export const BUILDING_COLUMN = 'building';
export const POSTAL_CODE_COLUMN = 'postalCode';
export const CITY_COLUMN = 'city';
export const FULL_ADDRESS_COLUMN = 'fullAddress';
export const SERVICE_PROVIDER_COLUMN = 'serviceProvider';
export const RAPPORT_DATE_COLUMN = 'rapportDate';
export const RESOLVE_DATE_COLUMN = 'resolveDate';
export const TAKEN_INTO_ACCOUNT_DATE_COLUMN = 'takenIntoAccountDate';
export const TAKEN_INTO_ACCOUNT_TIME_COLUMN = 'takenIntoAccountTime';
export const WORK_RESOLUTION_DELAY = 'workResolutionDelay';
export const STATE_COLUMN = 'state';
export const INCIDENT_TIME_FROM_THE_BEGINNING_COLUMN = 'incidentTimeDefined';
export const COUNTDOWN_COLUMN = 'countdown';
export const PIN_CODE_COLUMN = 'pinCode';
export const EVENT_ASSIGNED_DATE = 'eventAssignedDate';
export const IMAGE_AND_COMMENT = 'imageAndComment';

const DynamicColumns = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuClick = (column: string) => () => {
        const isEnabled = column in props.enabledColumns ? props.enabledColumns[column] : null;
        if (isEnabled === null) {
            console.error(`Column ${column} is not registered as a List's column`);
            alert(`Column ${column} is not registered as a List's column`);
        }

        props.toggleColumn(column, !isEnabled);
        // handleClose();
    };

    return (
        <Grid item>
            <TopToolbarButtonStyled
                size={'small'}
                aria-controls='simple-menu'
                aria-haspopup='true'
                className={classes.buttonFontWeightBold}
                startIcon={<ListIcon />}
                onClick={handleClick}
            >
                {translate('app.menuActions.customize')}
            </TopToolbarButtonStyled>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {Object.keys(props.enabledColumns).map((enabledColumnName) => (
                    <MenuItem onClick={onMenuClick(enabledColumnName)} key={`menu_item_${enabledColumnName}`}>
                        <FormControlLabel
                            className={classes.fontWeightBold}
                            control={
                                <Checkbox
                                    size={'small'}
                                    checked={props.enabledColumns[enabledColumnName]}
                                    color='primary'
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    name={translate(`app.incidents.${enabledColumnName}`)}
                                />
                            }
                            label={
                                <Typography
                                    noWrap
                                    variant={'button'}
                                    color={'primary'}
                                    className={classes.fontWeightBold}
                                >
                                    {' '}
                                    {translate(`app.incidents.${enabledColumnName}`)}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
};

export default DynamicColumns;
