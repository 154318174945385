import React from 'react';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import Authentication from '../commons/auth/Authentication';
import { subCategoryNameRoute } from './paths';
import SubCategory from '../components/subCategories';

const SubCategoryPage = () => {
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    return <Authentication redirectTo={subCategoryNameRoute}>{userConnected && <SubCategory />}</Authentication>;
};
export default SubCategoryPage;
