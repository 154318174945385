import MuiCard from '@material-ui/core/Card';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { DateInput, Filter, FilterList, FilterListItem, SearchInput, useTranslate } from 'react-admin';
import { sideBarMinWidth } from '../../../theme';
import './index.css';
import CurrentHistoryIncidentsFilter from './CurrentHistoryIncidentsFilter';
import Divider from '@material-ui/core/Divider';
import { IncidentListViewMode, UserCategory } from '../../../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { getUserCategory } from '../../../utils/user/tags';
import PlaceRootFilter from './PlaceRootFilter';
import EventStateFilter from './EventStateFilter';
import { GridTitleFilterListStyled } from '../../../commons/filters/utils';
import {
    TICKETING_EVENT_WITH_USER_FILTER,
    USER_PROVIDER_CATEGORY,
    USER_UNDER_CONTRACT_FILTER,
} from '../../../utils/CONST';
import ServiceProviderFilter from './ServiceProviderFilter';
import ManagerAndSubmanagerInputFilter from './ManagerAndSubManagerFilter';
import CategoryReferenceInputFilter from '../../../commons/filters/category';
import SubcategoryAndIncidentTypeSwitchFilter from '../../../commons/filters/subcategoryAndIncidentTypeFilter/SubcategoryAdnIncidentTypeSwitchFilter';
import moment from 'moment';

const FilterCardSideBarStyled = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        order: -1, // display on the left rather than on the right of the list
        width: sideBarMinWidth,
        minWidth: sideBarMinWidth,
        border: '1px solid #E6E6E6',
        borderRadius: 5,
        marginRight: 15,
        padding: 10,
        overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const useStyles = makeStyles({
    filterForm: {
        marginTop: '0 !important',
        display: 'grid !important',
    },
    fontWeightBold: {
        fontWeight: 700,
    },
    marginTop: {
        marginTop: 4,
    },
    paddingTop: {
        paddingTop: 32,
    },
    customFilter: {
        paddingTop: 32,
        marginLeft: 12,
        marginRight: 12,
    },
    filterFormPaddingTop: {
        paddingTop: 32,
        width: '100%',
    },
    paddingTopBold: {
        fontWeight: 'bold',
        paddingTop: 15,
    },
    fontWeightBoldAndPaddingTop: {
        fontWeight: 'bold',
        paddingTop: 15,
    },
    inputFilter: {
        marginBottom: 20,
    },
    clearButton: {
        width: 24,
        height: 24,
        padding: 0,
        minWidth: 24,
        color: 'grey',
        background: 'transparent',
    },
    closeIcon: {
        width: 16,
        height: 24,
    },
    filterMarginBottomForm: {
        marginTop: '0 !important',
        display: 'grid !important',
        marginBottom: '-16px',
    },
});

interface Props {
    divHeight: number;

    [key: string]: any;
}

const FilterSidebar = (props: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const userConnected = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    const userCategory: UserCategory = getUserCategory(userConnected) as UserCategory;

    const viewMode: IncidentListViewMode = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.viewMode
    );

    return (
        <FilterCardSideBarStyled
            style={{ height: props.divHeight - 62, width: 240 }}
            className={'incidentsFilter'}
            key={'FilterCardSideBarStyled'}
        >
            <Filter {...props} classes={{ form: classes.filterForm }} key={'search_filters_0'}>
                <SearchInput source='name' alwaysOn fullWidth className='searchInput' variant={'standard'} />
                {viewMode === IncidentListViewMode.SIMPLE_LIST ? <Divider /> : <span />}
                {viewMode === IncidentListViewMode.SIMPLE_LIST ? (
                    <CurrentHistoryIncidentsFilter alwaysOn={true} key={'CurrentHistoryIncidentsFilter'} />
                ) : (
                    <span />
                )}
            </Filter>

            <PlaceRootFilter />

            {viewMode === IncidentListViewMode.SIMPLE_LIST ? (
                <>
                    <Divider />
                    <EventStateFilter />
                </>
            ) : (
                <span />
            )}

            <Divider />

            {userConnected && userCategory !== USER_PROVIDER_CATEGORY ? (
                <>
                    {/*Service Provider filters*/}
                    <GridTitleFilterListStyled>
                        <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                            {translate('app.incidents.filters.serviceProvider.title')}
                        </Typography>
                    </GridTitleFilterListStyled>
                    <Filter {...props} classes={{ form: classes.filterMarginBottomForm }} key={'search_filters_1'}>
                        <ServiceProviderFilter
                            {...props}
                            label={'app.incidents.filters.serviceProvider.title'}
                            alwaysOn={true}
                            emptyText={`--${translate('app.noneItem')}--`}
                            key={'service_provider'}
                        />
                    </Filter>
                    <Divider />
                </>
            ) : (
                <span />
            )}

            {userConnected && userCategory !== USER_PROVIDER_CATEGORY ? (
                <>
                    {/*Manager and submanager filters*/}
                    <GridTitleFilterListStyled>
                        <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                            {translate('app.incidents.filters.managerSubManager.title')}
                        </Typography>
                    </GridTitleFilterListStyled>

                    <Filter {...props} classes={{ form: classes.filterMarginBottomForm }}>
                        <ManagerAndSubmanagerInputFilter
                            label={'app.incidents.filters.managerSubManager.title'}
                            alwaysOn={true}
                            emptyText={`--${translate('app.noneItem')}--`}
                            key={'manager_and_submanager'}
                        />
                    </Filter>
                    <Divider />
                </>
            ) : (
                <span />
            )}

            {/* Category filters*/}
            <GridTitleFilterListStyled>
                <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                    {translate('app.incidents.filters.categoriesAndIncidentType')}
                </Typography>
            </GridTitleFilterListStyled>

            <Filter {...props} classes={{ form: classes.filterFormPaddingTop }} key={'category_filter'}>
                <CategoryReferenceInputFilter
                    alwaysOn={true}
                    // label={translate('app.incidents.filters.category.title')}
                    label={''}
                    emptyText={`--${translate('app.noneItem')}--`}
                />
            </Filter>

            {/*Subcategory and incident type*/}
            <GridTitleFilterListStyled>
                <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                    {translate('app.incidents.filters.subcategoriesAndIncidentType')}
                </Typography>
            </GridTitleFilterListStyled>

            <Filter {...props} classes={{ form: classes.filterFormPaddingTop }} key={'sub_category_filter'}>
                <SubcategoryAndIncidentTypeSwitchFilter
                    label={translate('app.incidents.filters.subcategoriesAndIncidentType')}
                    alwaysOn={true}
                />
            </Filter>

            <Divider />

            {userConnected && userCategory !== USER_PROVIDER_CATEGORY ? (
                <>
                    {/*Service provider filters*/}
                    <GridTitleFilterListStyled>
                        <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                            {translate('app.incidents.filters.serviceProvider.assigned')}
                        </Typography>
                    </GridTitleFilterListStyled>
                    <FilterList label={''} icon={<span />}>
                        <FilterListItem
                            key={'serviceProvider.withContract'}
                            label={translate('app.incidents.filters.serviceProvider.withContract')}
                            value={{
                                [USER_UNDER_CONTRACT_FILTER]: true,
                                [TICKETING_EVENT_WITH_USER_FILTER]: undefined,
                            }}
                        />
                        <FilterListItem
                            key={'serviceProvider.withoutContract'}
                            label={translate('app.incidents.filters.serviceProvider.withoutContract')}
                            value={{
                                [USER_UNDER_CONTRACT_FILTER]: false,
                                [TICKETING_EVENT_WITH_USER_FILTER]: undefined,
                            }}
                        />
                        <FilterListItem
                            key={'serviceProvider.withoutAndWithContract'}
                            label={translate('app.incidents.filters.serviceProvider.withoutAndWithContract')}
                            value={{
                                [USER_UNDER_CONTRACT_FILTER]: undefined,
                                [TICKETING_EVENT_WITH_USER_FILTER]: true,
                            }}
                        />
                        <FilterListItem
                            key={'serviceProvider.notAttributed'}
                            label={translate('app.incidents.filters.serviceProvider.notAttributed')}
                            value={{
                                [USER_UNDER_CONTRACT_FILTER]: undefined,
                                [TICKETING_EVENT_WITH_USER_FILTER]: false,
                            }}
                        />
                        <FilterListItem
                            key={'serviceProvider.none'}
                            label={translate('app.incidents.filters.serviceProvider.none')}
                            value={{
                                [USER_UNDER_CONTRACT_FILTER]: null,
                                [TICKETING_EVENT_WITH_USER_FILTER]: undefined,
                            }}
                        />
                    </FilterList>
                    <Divider />
                </>
            ) : (
                <span />
            )}

            {/*// Date filter 0*/}
            <GridTitleFilterListStyled>
                <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                    {translate('app.incidents.rapportDate')}
                </Typography>
            </GridTitleFilterListStyled>

            <Filter {...props} classes={{ form: classes.filterFormPaddingTop }}>
                <DateInput
                    variant={'standard'}
                    label={translate('app.filters.startDate')}
                    source={'history_created_at__after'}
                    alwaysOn
                />
                <DateInput
                    variant={'standard'}
                    label={translate('app.filters.endDate')}
                    source={'history_created_at__before'}
                    alwaysOn
                />
            </Filter>

            {/*<Filter {...props} classes={{ form: classes.filterFormPaddingTop }} key={'creationDate_filter'}>*/}
            {/*    <DateInput*/}
            {/*        fullWidth*/}
            {/*        variant={'standard'}*/}
            {/*        label={translate('app.filters.startDate')}*/}
            {/*        source={'creationDate__after'}*/}
            {/*        alwaysOn*/}
            {/*    />*/}
            {/*    <DateInput*/}
            {/*        fullWidth*/}
            {/*        variant={'standard'}*/}
            {/*        label={translate('app.filters.endDate')}*/}
            {/*        source={'creationDate__before'}*/}
            {/*        parse={(d: any) => {*/}
            {/*            if (!moment(d).isValid()) return d; // this condition allows dont send an invalid date to api*/}
            {/*            return moment(d).endOf('day').format('YYYY-MM-DD k:mm:ss');*/}
            {/*        }}*/}
            {/*        alwaysOn*/}
            {/*    />*/}
            {/*</Filter>*/}

            <Divider />

            {/*// Date filter 1*/}
            <GridTitleFilterListStyled>
                <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                    {translate('app.incidents.attributionDate')}
                </Typography>
            </GridTitleFilterListStyled>
            <Filter
                {...props}
                classes={{ form: classes.filterFormPaddingTop }}
                key={'ticketing_user_provider__date_filter'}
            >
                <DateInput
                    fullWidth
                    variant={'standard'}
                    label={translate('app.filters.startDate')}
                    source={'ticketing_user_provider__date_after'}
                    alwaysOn
                />

                <DateInput
                    fullWidth
                    variant={'standard'}
                    label={translate('app.filters.endDate')}
                    source={'ticketing_user_provider__date_before'}
                    alwaysOn
                />
            </Filter>
        </FilterCardSideBarStyled>
    );
};

export default FilterSidebar;
