import React, { useState } from 'react';
import { Avatar, TicketingManagerPlaces } from '../../../../utils/types';
import { hasPermissions } from '../../../../utils/permissions';
import { ROLE_UPDATE_AVATAR } from '../../../../utils/ROLES';
import { ThemeProvider } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Record, ReduxState, useDataProvider, useNotify, usePermissions, useRefresh, useTranslate } from 'react-admin';
import { CustomIconButton, GreenButtonTheme } from './commons';
import DelegateConfigForm from '../delegate';
import { TICKETING_MANAGER_PLACES } from '../../../../providers/resources';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';

interface Props {
    record: Avatar;
}

const DelegateButton = (props: Props) => {
    const { record } = props;
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const translate = useTranslate();
    const refresh = useRefresh();

    const [showDelegateDialog, setShowDelegateDialog] = useState<boolean>(false);

    const isPossibleToEdit = (record as Avatar)?.mcs && (record as Avatar).mcs.length > 0;

    const onDelegate = (e: Event) => {
        e.stopPropagation();
        setShowDelegateDialog(true);
    };

    const onCloseDelegateForm = () => {
        setShowDelegateDialog(false);
        refresh();
    };

    const currentDelegatedManagerPlace: Record | undefined = useSelector((state: CustomReducerState & ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_MANAGER_PLACES].data).find(
            (recordManagerPlace: Record) => {
                return (recordManagerPlace as TicketingManagerPlaces).placeOrEvent === record.id;
            }
        );
    });

    return (
        <>
            {record && hasPermissions([ROLE_UPDATE_AVATAR], loadedPermissions, permissions) && (
                <div>
                    <ThemeProvider theme={GreenButtonTheme}>
                        <CustomIconButton
                            onClick={(e: any) => onDelegate(e)}
                            disabled={!('company' in record && isPossibleToEdit)}
                        >
                            <Tooltip
                                title={
                                    currentDelegatedManagerPlace
                                        ? translate('app.incidents.information.reDelegateUser')
                                        : translate('app.incidents.information.delegateUser')
                                }
                            >
                                <PersonAddIcon
                                    color={
                                        currentDelegatedManagerPlace && isPossibleToEdit
                                            ? 'secondary'
                                            : isPossibleToEdit
                                            ? 'primary'
                                            : 'disabled'
                                    }
                                />
                            </Tooltip>
                        </CustomIconButton>
                    </ThemeProvider>
                </div>
            )}
            {showDelegateDialog && record && (
                <DelegateConfigForm
                    onCancel={() => setShowDelegateDialog(false)}
                    avatar={record as Avatar}
                    open={showDelegateDialog}
                    onClose={onCloseDelegateForm}
                />
            )}
        </>
    );
};
export default DelegateButton;
