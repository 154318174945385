import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { FileSystemMapper, Media, MetadataAvatarType } from '../../utils/types';
import { CustomReducerState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGES, MEDIAS, METADATA_AVATAR_TYPES } from '../../providers/resources';
import { GetOneResult, useDelete, useNotify, usePermissions, useRefresh, useTranslate, useUpdate } from 'react-admin';
import extractIdFromURI, { getURIFROMAnotherURI, getUriFromItems } from '../../utils/id';
import { getDownloadFileSystemMapperUrl, saveMedia } from '../../utils/medias';
import { TAG_TICKETING_LOGO } from '../../utils/CONST';
import DialogTemplate from '../Dialog/DialogTemplate/DialogTemplate';
import { selectIncidentType } from '../../redux/actions/incidentTypes';
import { getMediaByImagesById, MediaStateProp } from '../../redux/reducers/medias';
import { deleteMedia } from '../../redux/actions/medias';
import EditIncidentTypeForm from '../../components/incidentTypes/actions/edit';
import EditSubCategoryForm from '../../components/subCategories/actions/edit';
import { IMAGE_FIELD } from '../../utils/metadataAvatarType';
import { hasPermissions } from '../../utils/permissions';
import { ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../utils/ROLES';

export const INCIDENT_TYPE_EDIT_FORM_NAME = 'incident_type_edit_form';
export const SUBCATEGORY_EDIT_FORM_NAME = 'subcategory_edit_form';

interface EditFormProps {
    open: boolean;
    handleClose: () => void;
    selected: MetadataAvatarType;
    formNameToShow: typeof INCIDENT_TYPE_EDIT_FORM_NAME | typeof SUBCATEGORY_EDIT_FORM_NAME;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const URL_KEY = 'url';

const UpdateMetadataAvatarTypeAndTicketingLogoDialog = ({
    open,
    handleClose,
    selected,
    formNameToShow,
}: EditFormProps) => {
    const [currentMedia, setCurrentMedia] = useState<Media | undefined>();
    const [currentImageSrc, setCurrentImageSrc] = useState<string | undefined>();
    const [submitFinish, setSubmitFinish] = useState<boolean>(false);
    const [loadImage, setLoadImage] = useState<boolean>(false);
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const currentMediaInStateByImageId: MediaStateProp | undefined = useSelector((state: CustomReducerState) => {
        if (selected && 'images' in selected && selected?.images) {
            return getMediaByImagesById(state, selected.images);
        }
        return undefined;
    });

    useEffect(() => {
        if (selected && selected?.images && selected?.images.length > 0) {
            setLoadImage(true);
            if (
                currentMediaInStateByImageId &&
                (currentMediaInStateByImageId as MediaStateProp).media &&
                (currentMediaInStateByImageId as MediaStateProp).fileSystemMapper
            ) {
                if (
                    loadedPermissions &&
                    permissions &&
                    hasPermissions([ROLE_FM_CLOUD_READ_DOWNLOAD], loadedPermissions, permissions)
                ) {
                    setCurrentMedia((currentMediaInStateByImageId as MediaStateProp).media as Media);
                    setCurrentImageSrc(
                        getDownloadFileSystemMapperUrl(
                            (currentMediaInStateByImageId as MediaStateProp).media as Media,
                            (currentMediaInStateByImageId as MediaStateProp).fileSystemMapper as FileSystemMapper
                        )
                    );
                }
            }
            setLoadImage(false);
        }
    }, [loadedPermissions, permissions, currentMediaInStateByImageId]);

    const translate = useTranslate();
    const notify = useNotify();
    const dispatch = useDispatch();
    const refresh = useRefresh();

    const refreshSelectedItem = (selectedItemUpdated: MetadataAvatarType) => {
        dispatch(selectIncidentType.fn(selectedItemUpdated));
    };

    const [updateMetadataAvatarType, { loading }] = useUpdate(METADATA_AVATAR_TYPES);
    const [removeMedia, { loading: removingMedia }] = useDelete(MEDIAS);
    const [savingMedia, setSavingMedia] = useState(false);

    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );

    const resolveImageAndUpdateResource = async (
        fileData: string,
        fileName: string,
        metadataAvatarType: MetadataAvatarType,
        imageIdRemoved?: string
    ) => {
        if (!tokenWithRoles || !metadataAvatarType) return;
        setSavingMedia(true);

        const metadataAvatarTypeCompanyId =
            typeof metadataAvatarType.company === 'object' ? metadataAvatarType.company.id : metadataAvatarType.company;
        const resourceId = extractIdFromURI(metadataAvatarType.id as string);
        const imageAdded: Media | undefined = resourceId
            ? await saveMedia(fileData, fileName, metadataAvatarTypeCompanyId as string, resourceId, tokenWithRoles, [
                  TAG_TICKETING_LOGO,
              ])
            : undefined;
        setSavingMedia(false);
        if (imageAdded) {
            const imageUri =
                imageAdded && imageAdded?.id
                    ? `${getURIFROMAnotherURI(metadataAvatarType.id as string)}/${IMAGES}/${imageAdded.id}`
                    : null;
            updateImageMetadataAvatarType(imageUri, metadataAvatarType, imageIdRemoved);
        } else {
            console.error('The image can not be added, check the token send in the request');
            notify('app.messages.imageNotAdded');
        }
    };

    const updateImageMetadataAvatarType = (
        image: string | null,
        metadataAvatarType: MetadataAvatarType,
        imageIdRemoved?: string
    ) => {
        let newImages = [...getUriFromItems(metadataAvatarType.images), ...(image ? [image] : [])];
        if (imageIdRemoved && getUriFromItems(metadataAvatarType.images).includes(imageIdRemoved)) {
            const index = newImages.findIndex((i) => i === imageIdRemoved);
            index !== -1 && delete newImages[index];
        }
        newImages = newImages.filter((n) => n !== null);

        updateMetadataAvatarType(
            METADATA_AVATAR_TYPES,
            metadataAvatarType.id as string,
            {
                alphaId: !metadataAvatarType.alphaId ? moment().toString() : metadataAvatarType.alphaId,
                images: [...newImages],
            },
            metadataAvatarType,
            {
                onSuccess: ({ data }: GetOneResult) => {
                    if (data) {
                        refreshSelectedItem(data as MetadataAvatarType);
                    }
                    setSubmitFinish(true);
                    handleClose();
                    refresh();
                },
                onFailure: () => {
                    notify('app.incidentTypes.edit.error', 'warning');
                    console.error('ERROR editing', METADATA_AVATAR_TYPES, 'adding media ', image);
                },
            }
        );
    };

    const updateAndResolveImage = async (
        formValues: {
            [IMAGE_FIELD]: { fileData: string; fileName: string; url?: string };
        },
        justRemoveImage?: boolean
    ) => {
        if (justRemoveImage && currentMedia) {
            removeMedia(MEDIAS, currentMedia.id, currentMedia, {
                onSuccess: () => {
                    // update medias state in local
                    dispatch(deleteMedia.fn(currentMedia.id));
                    updateImageMetadataAvatarType(null, selected, currentMedia.id as string);
                    handleClose();
                },
                onFailure: (error: unknown) => {
                    console.error('ERROR removing media ', currentMedia.id, error);
                    notify('app.errors.server', 'warning');
                },
            });
        } else {
            if ((!formValues[IMAGE_FIELD] && !currentMedia) || (formValues[IMAGE_FIELD]?.[URL_KEY] && currentMedia)) {
                handleClose();
                refresh();
            } else if (
                // the user adds a new image, so the current Media will be removed
                formValues[IMAGE_FIELD]?.fileData &&
                currentMedia
            ) {
                // the user adds a new image, so the current Media will be removed.
                // remove current media and update resource
                removeMedia(MEDIAS, currentMedia.id, currentMedia, {
                    onSuccess: async () => {
                        // update medias state in local
                        dispatch(deleteMedia.fn(currentMedia.id));
                        await resolveImageAndUpdateResource(
                            formValues[IMAGE_FIELD].fileData,
                            formValues[IMAGE_FIELD].fileName,
                            selected,
                            currentMedia.id as string
                        );
                    },
                    onFailure: (error: unknown) => {
                        console.error('ERROR removing media ', currentMedia.id, error);
                        notify('app.errors.server', 'warning');
                    },
                });
            } else if (!formValues[IMAGE_FIELD] && currentMedia) {
                // the user remove the current media and doesnt add image
                // remove current media and update resource
                removeMedia(MEDIAS, currentMedia.id, currentMedia, {
                    onSuccess: () => {
                        // update medias state in local
                        dispatch(deleteMedia.fn(currentMedia.id));
                        updateImageMetadataAvatarType(null, selected, currentMedia.id as string);
                        handleClose();
                    },
                    onFailure: (error: unknown) => {
                        console.error('ERROR removing media ', currentMedia.id, error);
                        notify('app.errors.server', 'warning');
                    },
                });
            } else if (formValues[IMAGE_FIELD].fileData && !currentMedia) {
                // the user adds an image for first time
                await resolveImageAndUpdateResource(
                    formValues[IMAGE_FIELD].fileData,
                    formValues[IMAGE_FIELD].fileName,
                    selected
                );
            }
        }

        setSubmitFinish(true);
    };

    const handleFinish = () => {
        setSubmitFinish(true);
        handleClose();
        // refresh();
    };

    const dialogTitle =
        formNameToShow === INCIDENT_TYPE_EDIT_FORM_NAME ? 'app.incidentTypes.edit.title' : 'app.subCategory.edit.title';

    return (
        <DialogTemplate
            title={translate(dialogTitle)}
            open={open}
            onClose={handleClose}
            icon={<EditIcon />}
            maxWidth={'md'}
        >
            {formNameToShow === INCIDENT_TYPE_EDIT_FORM_NAME && (
                <EditIncidentTypeForm
                    savingMedia={savingMedia}
                    removingMedia={removingMedia}
                    onCancel={handleClose}
                    updateAndResolveImage={updateAndResolveImage}
                    selected={selected}
                    loadImage={loadImage}
                    currentMedia={currentMedia}
                    imageSrc={currentImageSrc}
                    submitFinish={submitFinish}
                    handleFinish={handleFinish}
                />
            )}
            {formNameToShow === SUBCATEGORY_EDIT_FORM_NAME && (
                <EditSubCategoryForm
                    savingMedia={savingMedia}
                    removingMedia={removingMedia}
                    onCancel={handleClose}
                    updateAndResolveImage={updateAndResolveImage}
                    selected={selected}
                    loadImage={loadImage}
                    currentMedia={currentMedia}
                    imageSrc={currentImageSrc}
                    submitFinish={submitFinish}
                    handleFinish={handleFinish}
                />
            )}
        </DialogTemplate>
    );
};

export default UpdateMetadataAvatarTypeAndTicketingLogoDialog;
