import React from 'react';
import {
    USER_EMAIL_FIELD,
    USER_FIRST_NAME_FIELD,
    USER_LAST_NAME_FIELD,
    USER_PHONE_FIELD,
    USER_USER_NAME_FIELD,
} from '../actions/CONST';
import Grid from '@material-ui/core/Grid';
import { TextInput, useTranslate } from 'react-admin';
import ImageCropperField from '../field/ImageCropperField';

const UserInfoInput = ({ isUserAPerson }: { isUserAPerson: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <Grid item xs={12} lg={12} sm={12} md={12}>
                <TextInput
                    autoFocus
                    variant={'standard'}
                    required
                    fullWidth
                    type={'text'}
                    label={isUserAPerson ? translate('app.users.firstName') : translate('app.users.companyName')}
                    source={USER_FIRST_NAME_FIELD}
                />
            </Grid>
            {isUserAPerson && (
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <TextInput
                        variant={'standard'}
                        required
                        fullWidth
                        type={'text'}
                        label={translate('app.users.lastName')}
                        source={USER_LAST_NAME_FIELD}
                    />
                </Grid>
            )}
            {isUserAPerson && (
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <TextInput
                        variant={'standard'}
                        required
                        fullWidth
                        type={'text'}
                        label={translate('app.users.username')}
                        source={USER_USER_NAME_FIELD}
                    />
                </Grid>
            )}
            <Grid item xs={12} lg={12} sm={12} md={12}>
                <TextInput
                    variant={'standard'}
                    required
                    fullWidth
                    type={'text'}
                    label={translate('app.users.email')}
                    source={USER_EMAIL_FIELD}
                />
            </Grid>
            <Grid item xs={12} lg={12} sm={12} md={12}>
                <TextInput
                    variant={'standard'}
                    fullWidth
                    type={'text'}
                    label={translate('app.users.phone')}
                    source={USER_PHONE_FIELD}
                />
            </Grid>
        </>
    );
};

export default UserInfoInput;
