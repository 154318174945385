import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import React, { Fragment, useState } from 'react';
import { ReduxState, useDataProvider, useNotify, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';
import { CustomReducerState } from '../../../redux/reducers';
import { simulateMainFingerMc } from '../../../utils/simulateApp';
import { Avatar } from '../../../utils/types';
import { getItemListSelected } from '../../../redux/reducers/navigation';

const SimpleActionMenu = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const moveActionState: boolean = useSelector(
        (state: CustomReducerState) => state.ticketing.navigation.actions.moveTo.active
    );
    const [menuState, setMenuState] = useState(false);

    const handleToggle = () => {
        setMenuState(!menuState);
    };

    const simulateApp = (selectedItem: Avatar | undefined) => () => {
        if (!selectedItem) return;
        simulateMainFingerMc(selectedItem, dataProvider)
            .then((result) => {
                result && window.open(result.mcUrl, '_blank', 'width=411px,height=731px');
            })
            .catch(() => {
                notify(translate('app.errors.sever', 'error'));
            });
    };

    const selectedItemList: Avatar | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getItemListSelected(state)
    );

    const menuActions: MenuItemType[] = [
        {
            icon: <PhoneIcon />,
            text: translate('app.menuActions.simulateApp'),
            onClick: simulateApp(selectedItemList),
            disabled: moveActionState || !(selectedItemList && selectedItemList.mcs.length >= 1),
            roles: [],
        },
    ];

    return (
        <Fragment>
            <MenuButtonActions
                buttonLabel={'app.menuActions.title'}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />
        </Fragment>
    );
};

export default SimpleActionMenu;
