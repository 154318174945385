import { ChartDashboardId } from '../../../utils/types';

export interface RegisterGlobalDashboardAction {
    type: typeof registerGlobalDashboard.action;
    payload: {
        chartDashboardId: ChartDashboardId;
        instanceId: string;
        add: boolean;
        customFields: any;
    };
}

export const registerGlobalDashboard = {
    action: '360SC_TICKETING/REGISTER_GLOBAL_DASHBOARD',
    fn: (
        chartDashboardId: ChartDashboardId,
        instanceId: string,
        add = true,
        customFields: any = {}
    ): RegisterGlobalDashboardAction => ({
        type: registerGlobalDashboard.action,
        payload: {
            chartDashboardId,
            instanceId,
            add,
            customFields,
        },
    }),
};

export interface UpdateGlobalDashboardAction {
    type: typeof updateGlobalDashboard.action;
    payload: {
        chartDashboardId: ChartDashboardId;
        instanceId: string;
        customFields: any;
    };
}

export const updateGlobalDashboard = {
    action: '360SC_TICKETING/UPDATE_GLOBAL_DASHBOARD',
    fn: (
        chartDashboardId: ChartDashboardId,
        instanceId: string,
        customFields: any = {}
    ): UpdateGlobalDashboardAction => ({
        type: updateGlobalDashboard.action,
        payload: {
            chartDashboardId,
            instanceId,
            customFields,
        },
    }),
};

export interface UpdateGlobalFilterAction {
    type: typeof updateGlobalFilter.action;
    payload: {
        beforeAt: string | undefined;
        afterAt: string | undefined;
        selectedOption: string | undefined;
        enabled: boolean;
    };
}
export const updateGlobalFilter = {
    action: '360SC_TICKETING/UPDATE_GLOBAL_FILTER',
    fn: (
        beforeAt: string | undefined,
        afterAt: string | undefined,
        enabled: boolean | undefined,
        selectedOption: string | undefined
    ): UpdateGlobalFilterAction => ({
        type: updateGlobalFilter.action,
        payload: {
            beforeAt: !enabled ? undefined : beforeAt,
            afterAt: !enabled ? undefined : afterAt,
            selectedOption,
            enabled: !!enabled,
        },
    }),
};

export interface UpdateDashboardVersionAction {
    type: typeof updateDashboardVersion.action;
    payload: {
        version: number;
    };
}
export const updateDashboardVersion = {
    action: '360SC_TICKETING/UPDATE_DASHBOARD_VERSION',
    fn: (version: number): UpdateDashboardVersionAction => ({
        type: updateDashboardVersion.action,
        payload: {
            version,
        },
    }),
};

export type ACTIONS =
    | RegisterGlobalDashboardAction
    | UpdateGlobalDashboardAction
    | UpdateGlobalFilterAction
    | UpdateDashboardVersionAction;
