import React, { useEffect, useState } from 'react';
import { GetListResult, Identifier, Record, ReduxState, useDataProvider, useNotify, useTranslate } from 'react-admin';
import {
    Avatar,
    PlaceOrEventPath,
    TicketingEventUser,
    TicketingUserProviderEventConfiguration,
    User,
} from '../../../utils/types';
import { useSelector } from 'react-redux';
import {
    TICKETING_EVENT_USERS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../providers/resources';
import { getTicketingUserProviderEventConfig } from '../../../utils/user/resolutionDeadline';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { ResponseForwarderToMetabaseType } from '../../../utils/ticketingBridgeMetabase/viewsDynamic';
import { CustomReducerState } from '../../../redux/reducers';

interface Props {
    event: Avatar | undefined;
    metabaseData?: ResponseForwarderToMetabaseType;
}

const UserResolutionDeadlineField = ({ event, metabaseData }: Props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();
    const [userWorkResolutionDelay, setUserWorkResolutionDelay] = useState<number | null>(null);

    const placesOrEventsPath: PlaceOrEventPath[] = useSelector((state: CustomReducerState) =>
        event && event?.id in state.ticketing.placesOrEventsPath ? state.ticketing.placesOrEventsPath[event.id] : []
    );

    const ticketingEventUser: Record | undefined = useSelector((state: ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_EVENT_USERS].data).find(
            (datum) => (datum as TicketingEventUser).event === event?.id
        );
    });

    const user: Record | undefined = useSelector((state: ReduxState) => {
        return ticketingEventUser
            ? Object.values(state.admin.resources[USERS].data).find(
                  (datum) => (datum as User).id === (ticketingEventUser as TicketingEventUser).user
              )
            : undefined;
    });

    const getWorkResolutionDelay = (user: User, incident: Avatar, placeId: Identifier) => {
        const onSuccess = (response: GetListResult<Record>) => {
            if (response && 'data' in response && response.data && response.data.length === 1) {
                setUserWorkResolutionDelay(
                    (response.data[0] as TicketingUserProviderEventConfiguration).workResolutionDelay
                );
            }
        };
        const onFailure = (error: any) => {
            console.error('Getting', TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, error);
            notify('app.incidents.requestWorkResolutionDelay.get.error', 'error');
        };
        getTicketingUserProviderEventConfig(
            dataProvider,
            user,
            incident,
            incident.metadataAvatarType.id,
            placeId,
            onSuccess,
            onFailure
        );
    };

    useEffect(() => {
        if (user && event && placesOrEventsPath && placesOrEventsPath.length > 0) {
            getWorkResolutionDelay(user as User, event, placesOrEventsPath[0].id);
        }
    }, [user, placesOrEventsPath, event]);

    const renderUserWorkResolutionDelayText = () => {
        if (userWorkResolutionDelay && userWorkResolutionDelay >= 1) {
            return translate('app.hours', { time: userWorkResolutionDelay });
        } else if (userWorkResolutionDelay && userWorkResolutionDelay < 1 && userWorkResolutionDelay > 0) {
            return translate('app.minutes', { time: userWorkResolutionDelay * 100 });
        } else {
            return '...';
        }
    };

    return (
        <span>
            <Grid item>
                <Typography variant={'body2'}>{renderUserWorkResolutionDelayText()}</Typography>
            </Grid>
        </span>
    );
};

export default UserResolutionDeadlineField;
