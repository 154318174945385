import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
    CreateResult,
    GetListResult,
    Record,
    ReferenceArrayInput,
    useCreate,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { AutocompleteInput } from '../../../../../../../commons/ra/input/AutocompleteInput';
import {
    TICKETING_USER_ALLOCATIONS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../../../../../providers/resources';
import {
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
} from '../../../../../../../utils/CONST';
import extractIdFromURI from '../../../../../../../utils/id';
import { Avatar, MetadataAvatarType, TicketingUserAllocation, User } from '../../../../../../../utils/types';
import DefineTimeInput from './defineTimeInput';
import {
    getTicketingField,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../../../../../utils/user/customFields';
import DialogTemplateButtons from '../../../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';

interface Props {
    subCategory: MetadataAvatarType;
    onSave: () => void;
    handleCancel: () => void;
    place: Avatar;
    isRoot: boolean;
}

const USER_FIELD = 'user';
const USER_WORK_RESOLUTION_DELAY_FIELD = 'workResolutionDelay';
const USER_PROVIDER_EVENT_CONFIG_URI_FIELD = 'userProviderEventConfigUri';

const AddServiceProviderForm = ({ subCategory, handleCancel, place, onSave, isRoot }: Props) => {
    // console.log('AddServiceProviderForm isRoot***', isRoot);
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const users = useSelector((state: any) => state.admin.resources.users.data);

    const checkIfServiceProviderUnderContract = (user: any) => {
        const tags = user.tags;

        if (!Array.isArray(tags)) return false;

        return (
            (tags.includes(TICKETING_USER_SERVICE_PROVIDER_TAG) &&
                tags.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG)) ||
            (tags.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG) &&
                tags.includes(TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG))
        );
    };

    const [addOrRemoveTicketingUserAllocation] = useUpdate(TICKETING_USER_ALLOCATIONS);
    const [createTicketingUserAllocation] = useCreate(TICKETING_USER_ALLOCATIONS);

    const onSubmit = async (data: { [key: string]: any }) => {
        const userId = data[USER_FIELD];
        let workResolutionDelay = 0;
        if (USER_WORK_RESOLUTION_DELAY_FIELD in data && data[USER_WORK_RESOLUTION_DELAY_FIELD]) {
            workResolutionDelay = parseFloat(data[USER_WORK_RESOLUTION_DELAY_FIELD]);
        }

        await dataProvider
            .getList(TICKETING_USER_ALLOCATIONS, {
                filter: {
                    'user.id': userId,
                    'place.id': place.id,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
                pagination: {
                    perPage: 1,
                    page: 1,
                },
            })
            .then((result: GetListResult<Record>) => {
                const data = result.data;
                const datum: Record | null = data.length > 0 ? data[0] : null;
                if (datum) {
                    addSubcategoryAndWorkResolutionDelayToTicketingUserAllocation(
                        subCategory,
                        datum as TicketingUserAllocation,
                        workResolutionDelay,
                        true,
                        userId
                    );
                } else {
                    createTicketingUserAllocation(
                        TICKETING_USER_ALLOCATIONS,
                        {
                            // subCategories: [subCategory.id],
                            place: place.id,
                            user: userId,
                            company: typeof place.company === 'object' ? place.company.id : place.company,
                        },
                        {
                            onSuccess: (response: CreateResult) => {
                                notify(
                                    'app.navigation.configuration.serviceProvider.subCategory.created.notification',
                                    'success'
                                );
                                if (response && 'data' in response && response.data) {
                                    addSubcategoryAndWorkResolutionDelayToTicketingUserAllocation(
                                        subCategory,
                                        response.data as TicketingUserAllocation,
                                        workResolutionDelay,
                                        true,
                                        userId
                                    );
                                }
                            },
                            onFailure: (error: any) => {
                                notify(
                                    'app.navigation.configuration.serviceProvider.subCategory.created.error',
                                    'warning'
                                );
                                console.error(`creating, user in  ${TICKETING_USER_ALLOCATIONS}`, error);
                                handleCancel();
                            },
                        }
                    );
                }
            })
            .catch((error) => {
                console.error('ERRROR getting', TICKETING_USER_ALLOCATIONS, error);
                notify('app.errors.server', 'error');
            });
    };

    const addSubcategoryAndWorkResolutionDelayToTicketingUserAllocation = (
        subcategory: MetadataAvatarType,
        ticketingUserAllocation: TicketingUserAllocation,
        workResolutionDelay: number,
        add: boolean,
        userId: string
    ) => {
        addOrRemoveTicketingUserAllocation(
            TICKETING_USER_ALLOCATIONS,
            `${ticketingUserAllocation.id}/add_or_remove`,
            {
                subCategory: subcategory.id as string,
                add,
                workResolutionDelay,
            },
            ticketingUserAllocation,
            {
                onSuccess: () => {
                    notify('app.navigation.configuration.serviceProvider.subCategory.created.notification', 'success');
                    refreshTicketingUSerProviderEventConfig(userId);
                    onSave();
                },
                onFailure: (error: any) => {
                    console.error('Add or remove', TICKETING_USER_ALLOCATIONS, error);
                    handleCancel();
                    notify('app.navigation.configuration.serviceProvider.subCategory.added.error', 'error');
                },
            }
        );
    };

    const refreshTicketingUSerProviderEventConfig = (userId: string) => {
        dataProvider
            .getList(TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
                filter: {
                    place: extractIdFromURI(place.id as string),
                    subCategory: extractIdFromURI(subCategory.id as string),
                    user: extractIdFromURI(userId),
                },
            })
            .catch((error) => console.error(error));
    };

    const getServiceType = (user: User) => {
        const value = getTicketingField(
            user,
            `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}`
        );
        return value ? `(${value})` : '';
    };

    const renderOptionText = (user: User | undefined) => (user ? `${user?.username} ${getServiceType(user)}` : '');

    return (
        <FinalForm
            onSubmit={onSubmit}
            validate={(values) => {
                const errors: {
                    [key: string]: string | undefined;
                } = {
                    [USER_FIELD]: undefined,
                };
                if (!values[USER_FIELD]) {
                    errors[USER_FIELD] = 'ra.validation.required';
                }
                return errors;
            }}
            render={({ handleSubmit, pristine, submitting, values, valid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <ReferenceArrayInput
                                    reference={USERS}
                                    isRequired
                                    filterToQuery={(searchText: string) => ({
                                        lastName: searchText,
                                    })}
                                    filter={{
                                        'tags[one]': [
                                            TICKETING_USER_SERVICE_PROVIDER_TAG,
                                            TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
                                        ],
                                    }}
                                    source={USER_FIELD}
                                    label={translate('app.navigation.configuration.serviceProvider.label')}
                                >
                                    <AutocompleteInput
                                        resettable
                                        filterToQuery={(search: string) => ({ lastName: search })}
                                        isRequired
                                        variant={'standard'}
                                        optionText={renderOptionText}
                                        fullWidth
                                    />
                                </ReferenceArrayInput>
                            </Grid>
                            {/*{isRoot &&*/}
                            {/*    'user' in values &&*/}
                            {/*    values.user in users &&*/}
                            {/*    checkIfServiceProviderUnderContract(users[values.user]) && (*/}
                            {/*        <Grid item>*/}
                            {/*            <DefineTimeInput*/}
                            {/*                fieldNameUserProviderEventConfig={USER_PROVIDER_EVENT_CONFIG_URI_FIELD}*/}
                            {/*                fieldNameUserWorkResolutionDelay={USER_WORK_RESOLUTION_DELAY_FIELD}*/}
                            {/*                fieldNameOnChangeSubscription={USER_FIELD}*/}
                            {/*                subCategory={subCategory}*/}
                            {/*                place={place}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*    )}*/}
                            {'user' in values &&
                                values.user in users &&
                                checkIfServiceProviderUnderContract(users[values.user]) && (
                                    <Grid item>
                                        <DefineTimeInput
                                            fieldNameUserProviderEventConfig={USER_PROVIDER_EVENT_CONFIG_URI_FIELD}
                                            fieldNameUserWorkResolutionDelay={USER_WORK_RESOLUTION_DELAY_FIELD}
                                            fieldNameOnChangeSubscription={USER_FIELD}
                                            subCategory={subCategory}
                                            place={place}
                                        />
                                    </Grid>
                                )}
                            <Grid item>
                                <DialogTemplateButtons
                                    outside
                                    withCancel={true}
                                    withCreate
                                    cancelButtonLabel={translate('app.cancel')}
                                    onCancelClick={handleCancel}
                                    isSubmitting={submitting}
                                    isValidForm={valid && !pristine}
                                    createButtonLabel={translate('app.valid')}
                                />
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        />
    );
};
export default AddServiceProviderForm;
