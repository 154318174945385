import { Identifier, ReduxState } from 'react-admin';
import { Avatar, IncidentListViewMode } from '../../utils/types';
import {
    changeKanbanListOrder,
    ChangeKanbanListOrderAction,
    changeViewList,
    ChangeViewListAction,
    registerCustomizeColumnsIncidentsInKanbanListView,
    registerCustomizeColumnsIncidentsInListView,
    RegisterCustomizeColumnsInKanbanListView,
    RegisterCustomizeColumnsInListView,
    registerMetabaseData,
    RegisterMetabaseDataAction,
    registerPlaceIncidentFilter,
    RegisterPlaceIncidentFilterAction,
    selectIncident,
    SelectIncidentAction,
} from '../actions/incidents';
import { CustomReducerState } from './index';
import { AVATARS } from '../../providers/resources';
import { clearTicketingStateCreator } from '../actions/ticketingState';
import { IncidentStatus } from '../../utils/CONST';
import {
    getCustomizeColumnsEnabledInKanbanViewModeByUserIdConnectedFromLocalStorage,
    getCustomizeColumnsEnabledInListViewModeByUserIdConnectedFromLocalStorage,
    getKanbanViewListOrderedByUserIdConnectedFromLocalStorage,
    getViewModeListByUserIdConnectedFromLocalStorage,
} from '../../utils/localStorage/incident';
import { tokenFoundCreator } from '../actions/users';

const renderViewMode = () => {
    const viewMode = getViewModeListByUserIdConnectedFromLocalStorage();
    if (!viewMode || (viewMode && viewMode === IncidentListViewMode.SIMPLE_LIST)) {
        return IncidentListViewMode.SIMPLE_LIST;
    }
    return IncidentListViewMode.KANBAN_LIST;
};

const renderKanbanViewListOrder = () => {
    const kanbanViewListOrdered = getKanbanViewListOrderedByUserIdConnectedFromLocalStorage();
    if (!kanbanViewListOrdered) {
        return Object.keys(IncidentStatus);
    }
    return JSON.parse(kanbanViewListOrdered);
};

const renderCustomizeIncidentListColumnsEnabled = () => {
    const columnsEnabled = getCustomizeColumnsEnabledInListViewModeByUserIdConnectedFromLocalStorage();
    if (!columnsEnabled) {
        return {};
    }
    return JSON.parse(columnsEnabled);
};

const getEnabledColumnsByIncidentStatus = () => {
    let enabledColumns: {
        [key: string]: boolean;
    } = {};
    Object.keys(IncidentStatus).forEach((state) => {
        enabledColumns = {
            ...enabledColumns,
            [state]: true,
        };
    });
    return enabledColumns;
};

const renderCustomizeIncidentKanbanListColumnsEnabled = () => {
    const columnsEnabled = getCustomizeColumnsEnabledInKanbanViewModeByUserIdConnectedFromLocalStorage();
    if (!columnsEnabled) {
        return getEnabledColumnsByIncidentStatus();
    }
    return JSON.parse(columnsEnabled);
};

export interface IncidentsState {
    selected: {
        [key: Identifier]: Avatar;
    };
    metabaseData: {
        [key: Identifier]: {
            // question number => data
            [key: number]: any;
        };
    };
    filters: {
        place: Avatar | null;
    };
    viewMode: IncidentListViewMode;
    customizedColumns: {
        //Mapped Types
        [viewMode in IncidentListViewMode.KANBAN_LIST | IncidentListViewMode.SIMPLE_LIST]: {
            [key: string]: boolean;
        };
    };
    forKanban: {
        columnOrder: string[];
        columnEnabled: string[];
    };
}

const InitialState: IncidentsState = {
    selected: {},
    metabaseData: {},
    filters: {
        place: null,
    },
    viewMode: renderViewMode(),
    customizedColumns: {
        [IncidentListViewMode.SIMPLE_LIST]: renderCustomizeIncidentListColumnsEnabled(),
        [IncidentListViewMode.KANBAN_LIST]: renderCustomizeIncidentKanbanListColumnsEnabled(),
    },
    forKanban: {
        columnOrder: renderKanbanViewListOrder(),
        columnEnabled: [],
    },
};

export const getIncidentSelected = (state: CustomReducerState & ReduxState): Avatar | undefined => {
    const selected = state.ticketing.incidents.selected;
    const selectedUriKey = selected ? Object.keys(selected) : [];
    if (selected && selectedUriKey.length > 0 && state.admin.resources[AVATARS].list.ids.includes(selectedUriKey[0])) {
        return state.admin.resources[AVATARS].data[selectedUriKey[0]] as Avatar;
    }
    return undefined;
};

const reducer = (state: IncidentsState = InitialState, action: any) => {
    switch (action.type) {
        case selectIncident.action: {
            const iAction: SelectIncidentAction = action;
            const newRecord = iAction.payload.record
                ? {
                      // @ts-ignore
                      [iAction.payload.record.id]: {
                          ...iAction.payload.record,
                      },
                  }
                : {};

            return {
                ...state,
                selected: {
                    ...newRecord,
                },
            };
        }
        case registerMetabaseData.action: {
            const iAction: RegisterMetabaseDataAction = action;

            if (!(iAction.payload.eventId in state.metabaseData)) {
                state.metabaseData[iAction.payload.eventId] = {
                    [iAction.payload.question]: {},
                };
            }

            return {
                ...state,
                metabaseData: {
                    ...state.metabaseData,
                    [iAction.payload.eventId]: {
                        ...state.metabaseData[iAction.payload.eventId],
                        [iAction.payload.question]: iAction.payload.data,
                    },
                },
            };
        }
        case changeViewList.action: {
            const iAction: ChangeViewListAction = action;
            return {
                ...state,
                viewMode: iAction.payload.viewMode,
            };
        }
        case changeKanbanListOrder.action: {
            const iAction: ChangeKanbanListOrderAction = action;
            return {
                ...state,
                forKanban: {
                    ...state.forKanban,
                    columnOrder: iAction.payload.itemsOrdered,
                },
            };
        }
        case tokenFoundCreator.action: {
            return {
                ...state,
                viewMode: renderViewMode(),
                forKanban: {
                    ...state.forKanban,
                    columnOrder: renderKanbanViewListOrder(),
                },
            };
        }
        case registerPlaceIncidentFilter.action: {
            const iAction: RegisterPlaceIncidentFilterAction = action;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    place: iAction.payload.place,
                },
            };
        }
        case registerCustomizeColumnsIncidentsInListView.action: {
            const iAction: RegisterCustomizeColumnsInListView = action;
            return {
                ...state,
                customizedColumns: {
                    ...state.customizedColumns,
                    [IncidentListViewMode.SIMPLE_LIST]: {
                        ...state.customizedColumns[IncidentListViewMode.SIMPLE_LIST],
                        ...iAction.payload.cols,
                    },
                },
            };
        }
        case registerCustomizeColumnsIncidentsInKanbanListView.action: {
            const iAction: RegisterCustomizeColumnsInKanbanListView = action;
            return {
                ...state,
                customizedColumns: {
                    ...state.customizedColumns,
                    [IncidentListViewMode.KANBAN_LIST]: {
                        ...state.customizedColumns[IncidentListViewMode.KANBAN_LIST],
                        ...iAction.payload.cols,
                    },
                },
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
