import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { PasswordInput, TextInput, useAuthProvider, useNotify, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import DialogTemplateButtons from '../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AuthProvider } from '../providers/authProvider';
import { tokenFoundCreator } from '../redux/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { incidentsNameRoute, REDIRECT_TO_QUERY_PARAMS, refreshPage, statisticsNameRoute } from './paths';
import PasswordForgotForm from '../components/users/actions/password/PasswordForgotForm';
import { CustomReducerState } from '../redux/reducers';
import { getUserCategory } from '../utils/user/tags';
import { UserCategory } from '../utils/types';
import { USER_PROVIDER_CATEGORY } from '../utils/CONST';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: 'white',
    },
    cardRoot: {
        height: 'auto',
        minWidth: '300px',
        width: '300px',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 14,
        paddingRight: 14,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        // marginBottom: 12,
    },
    fullWidth: {
        width: '100%',
    },
    displayGrid: {
        display: 'grid',
    },
    divMain: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        position: 'absolute',
    },
});

const LoginPage = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const notify = useNotify();

    const user = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const refreshToken: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.refreshToken
    );
    const [enablePasswordForgot, setEnablePasswordForgot] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // @ts-ignore
    const authProvider: AuthProvider = useAuthProvider();

    const handleSubmit = async (formsValues: { username: string; password: string }) => {
        setLoading(true);
        const tokens: { token: string; refreshToken: string } | undefined = await authProvider.login({
            username: formsValues['username'],
            password: formsValues['password'],
        });
        if (tokens && tokens.refreshToken && tokens.token) {
            dispatch(tokenFoundCreator.fn(tokens.token, tokens.refreshToken));
            setLoading(false);
            setSubmitted(true);
        } else {
            setLoading(false);
            notify(translate('app.login.invalidCredentials'), 'error');
        }
    };

    const handlePasswordForgotClick = () => {
        setEnablePasswordForgot(true);
    };

    const handleCancelPasswordForgotClick = () => {
        setEnablePasswordForgot(false);
    };

    useEffect(() => {
        if (refreshToken && user && !loading && submitted) {
            const userCategory = getUserCategory(user) as UserCategory;
            if (userCategory === USER_PROVIDER_CATEGORY) {
                history.push(`${refreshPage}/${refreshToken}?${REDIRECT_TO_QUERY_PARAMS}=${incidentsNameRoute}`);
            } else {
                history.push(`${refreshPage}/${refreshToken}?${REDIRECT_TO_QUERY_PARAMS}=${statisticsNameRoute}`);
            }
        }
    }, [refreshToken, user]);

    return (
        <div className={classes.divMain}>
            <Grid container className={classes.root}>
                <Card className={classes.cardRoot}>
                    {!enablePasswordForgot ? (
                        <Form
                            onSubmit={handleSubmit}
                            render={({ handleSubmit, valid, pristine, values }) => (
                                <>
                                    <Grid container>
                                        <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                            <Grid item xs>
                                                <TextInput
                                                    autoFocus
                                                    variant={'standard'}
                                                    required
                                                    key={'username'}
                                                    fullWidth
                                                    type={'text'}
                                                    label={translate('app.login.usernameOrEmail')}
                                                    source={'username'}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <PasswordInput
                                                    variant={'standard'}
                                                    fullWidth
                                                    source='password'
                                                    label={translate('app.login.password')}
                                                />
                                            </Grid>
                                            <DialogTemplateButtons
                                                outside
                                                withCancel={false}
                                                // withCancel={true}
                                                withCreate
                                                cancelButtonLabel={translate('app.login.passwordForgot')}
                                                onCancelClick={handlePasswordForgotClick}
                                                isSubmitting={loading}
                                                isValidForm={valid && !pristine}
                                                createButtonLabel={translate('app.login.label')}
                                            />
                                        </form>
                                    </Grid>
                                </>
                            )}
                        />
                    ) : (
                        <PasswordForgotForm handleCancelClick={handleCancelPasswordForgotClick} />
                    )}
                </Card>
            </Grid>
        </div>
    );
};

export default LoginPage;
