import { Identifier } from 'react-admin';
import { FileSystemMapper, Media } from '../../utils/types';
import { ACTIONS, deleteMedia, DeleteMediaAction, registerMedia, RegisterMediaAction } from '../actions/medias';
import { CustomReducerState } from './index';
import { clearTicketingStateCreator } from '../actions/ticketingState';

export interface MediaStateProp {
    media: Media | null;
    fileSystemMapper: FileSystemMapper | null;
}

export interface MediasState {
    [key: Identifier]: MediaStateProp;
}

const InitialState: MediasState = {};

export const getMediaByImages = (state: CustomReducerState, images: string[]): MediaStateProp | undefined => {
    if (images.length > 0) {
        let imageFound = undefined;
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            imageFound = image in state.ticketing.medias ? state.ticketing.medias[image] : undefined;
            if (imageFound) break;
        }
        return imageFound;
    }
    return undefined;
};

export const getMediaByImagesById = (state: CustomReducerState, images: string[]): MediaStateProp | undefined => {
    if (images.length > 0) {
        let imageFound = undefined;
        for (let i = 0; i < images.length; i++) {
            const image: any = images[i];
            let imageId = image && typeof image === 'object' && '@id' in image && image['@id'] ? image['@id'] : null;
            imageId = !imageId && image && typeof image === 'string' ? image : imageId;
            imageFound = imageId && imageId in state.ticketing.medias ? state.ticketing.medias[imageId] : undefined;
            if (imageFound) break;
        }
        return imageFound;
    }
    return undefined;
};

export const findMediaByImage = (
    state: CustomReducerState,
    image: string | { name: string; id: string }
): MediaStateProp | undefined => {
    let imageFound = undefined;
    let imageId = image && typeof image === 'object' && 'id' in image && image['id'] ? image['id'] : null;
    imageId = !imageId && image && typeof image === 'string' ? image : null;
    imageFound = imageId && imageId in state.ticketing.medias ? state.ticketing.medias[imageId] : undefined;
    return imageFound;
};

const reducer = (state: MediasState = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case registerMedia.action: {
            const iAction: RegisterMediaAction = action as RegisterMediaAction;
            const newRecord = iAction.payload
                ? {
                      [iAction.payload.id]: {
                          media: iAction.payload.media,
                          fileSystemMapper: iAction.payload.fileSystemMapper,
                      },
                  }
                : {};

            return {
                ...state,
                ...newRecord,
            };
        }
        case deleteMedia.action: {
            const iAction: DeleteMediaAction = action as DeleteMediaAction;
            delete state[iAction.payload.id];

            return {
                ...state,
            };
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
