//All Mc Finger of Avatar point to same address
import { Avatar, MC, Yourls } from './types';
import { YOURLS } from '../providers/resources';
import { DataProviderProxy } from 'ra-core';
import { GetOneResult, Record } from 'react-admin';
import { getAvatarCtnIdentifiers } from './identifier';

const simulateFirstFingerMc = (
    avatar: Avatar,
    dataProvider: DataProviderProxy
): Promise<GetOneResult<Record> | { empty: '' }> => {
    if (avatar.mcs.length >= 1) {
        const mc: MC | string = avatar.mcs[0];
        if (typeof mc !== 'string' && 'yourls' in mc) {
            return dataProvider.getOne(YOURLS, { id: mc.yourls });
        }
    }
    return new Promise((resolve) => resolve({ empty: '' }));
};

const simulateMainFingerMc = async (avatar: Avatar, dataProvider: DataProviderProxy): Promise<Yourls | null> => {
    const ctnAvatarIdentifier = getAvatarCtnIdentifiers(avatar);
    if (avatar.mcs.length >= 1) {
        let yourlsToSimulate = null;

        for (let i = 0; i < avatar.mcs.length; i++) {
            const mc: MC | string = avatar.mcs[i];

            if (typeof mc !== 'string' && 'yourls' in mc) {
                const result = await dataProvider.getOne(YOURLS, { id: mc.yourls });
                const yourlsData = result && 'data' in result ? (result['data'] as Yourls) : null;
                const ctn = yourlsData?.mcUrl ? getCtnValue(yourlsData?.mcUrl) : null;

                if (i === 0) {
                    yourlsToSimulate = yourlsData;
                }

                if (ctn === ctnAvatarIdentifier) {
                    return yourlsData;
                }
            }
        }
        return yourlsToSimulate;
    }
    return new Promise((resolve) => resolve(null));
};

const getCtnValue = (link: string): string | null => {
    const url = new URL(link);
    const pathname = url.pathname;
    const short = pathname.split('/').reverse()[0];
    return url.searchParams.get('ctn');
};

export { simulateFirstFingerMc, simulateMainFingerMc };
