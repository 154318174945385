import React, { ReactElement, useEffect, useState } from 'react';
import { Avatar, AvatarProperty } from '../../../utils/types';
import { GetListResult, Record, useDataProvider, useNotify } from 'react-admin';
import { AVATAR_PROPERTY } from '../../../providers/resources';
import Form, { CREATE_CALLED, UPDATE_CALLED, DELETE_CALLED } from './form';

interface CreateOrUpdateProps {
    avatar: Avatar;
    propertyDefinition: string;
    excludesNames: string[];
    icon: ReactElement;
    labelId: string;
    labelValue: string;
    forceRefresh: number;
    disabled: boolean;
}

/**
 * Create or update properties within the definition "propertyDefinition"
 * @param props
 */
const createOrUpdateDynamicsKeysAndMultipleFields = (props: CreateOrUpdateProps) => {
    const [data, setData] = useState<AvatarProperty[]>([]);
    const dataProvider = useDataProvider();

    const [forceRefresh, setForceRefresh] = useState<number>(0);

    const executeForceRefresh = () => {
        setForceRefresh(forceRefresh + 1);
    };

    useEffect(() => {
        dataProvider
            .getList(AVATAR_PROPERTY, {
                pagination: { page: 1, perPage: 20 },
                filter: {
                    'avatar.id': props.avatar.id,
                    definition: props.propertyDefinition,
                },
                sort: { field: 'timestamp', order: 'asc' },
            })
            .then((result: GetListResult<Record>) => {
                setData(result.data as AvatarProperty[]);
            })
            .catch((error: any) => console.log('ERROR getting AVATAR PROPERTY', error));
    }, [props.forceRefresh, forceRefresh]);

    return (
        <>
            {data
                .filter((datum) => !props.excludesNames.includes(datum.name))
                .map((datum) => (
                    <Form
                        disabled={props.disabled}
                        labelId={props.labelId}
                        labelValue={props.labelValue}
                        icon={props.icon}
                        key={datum.id}
                        avatar={props.avatar}
                        item={datum}
                        propertyDefinition={props.propertyDefinition}
                        advertise={executeForceRefresh}
                    />
                ))}
        </>
    );
};

export default createOrUpdateDynamicsKeysAndMultipleFields;

export {
    Form as CreateOrUpdateDynamicsKeysAndOneField,
    CREATE_CALLED as CreateOrUpdateDynamicsKeysAndOneFieldAdvertiseCreate,
    UPDATE_CALLED as CreateOrUpdateDynamicsKeysAndOneFieldAdvertiseUpdate,
    DELETE_CALLED as CreateOrUpdateDynamicsKeysAndOneFieldAdvertiseDelete,
};
