import React, { useState } from 'react';
import { Record, useTranslate } from 'react-admin';
import { Avatar } from '../../../utils/types';
import IncidentNameAndExtraInformation from '../fields/IncidentNameAndExtraInformation';
import DialogTemplate from '../../../commons/Dialog/DialogTemplate/DialogTemplate';
import _ from 'lodash';
import SeeIncidentButton from '../actions/listActionsButtons/seeIncidentButton';
import DialogTemplateButtons from '../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ReminderButton from '../actions/listActionsButtons/reminderButton';
import Grid from '@material-ui/core/Grid';
import RestartButton from '../actions/listActionsButtons/restartButton';
import DelegateButton from '../actions/listActionsButtons/delegateButton';
import EditButton from '../actions/listActionsButtons/editButton';
import { getValueFromNode } from '../../../utils/avatars';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles, styled } from '@material-ui/core/styles';
import ServiceProviderButtonField from '../../../commons/fields/serviceProvider/ServiceProviderButtonField';
import ManagerAndDelegatedSubManager from '../fields/ManagerAndDelegatedSubManager';
import PlaceOrEventNavigationField from '../fields/PlaceOrEventNavigation';
import { toFormatDate } from '../../../utils/date';
import IncidentTimeFromTheBeginningField from '../fields/IncidentTimeFromTheBeginningField';
import extractIdFromURI from '../../../utils/id';
import ResolvedIncidentDateField from '../fields/ResolvedIncidentDateField';
import RecoverMetabaseData from '../fields/recoverMetabaseData';
import EventAssignedDateField from '../fields/EventAssignedDateField';
import UserResolutionDeadlineField from '../fields/UserResolutionDeadlineField';
import CountdownField from '../fields/CountdownField';
import { AVATAR_IDENTIFIER_ACCESS_CODE, AVATAR_IDENTIFIER_SEPARATOR, END_NODE_TYPE } from '../../../utils/CONST';
import { selectIncident } from '../../../redux/actions/incidents';
import { useDispatch } from 'react-redux';
import EventOpenedStateInfo from '../fields/EventOpenedStateInfo';
import ResolutionIncidentOpinionField from '../fields/ResolutionIncidentOpinionField';

const INCIDENT_TIME_QUESTION = 13;

const RootDiv = styled('div')({
    backgroundColor: 'white',
    marginBottom: 5,
    padding: '5px 5px 10px 10px',
});

const useStyles = makeStyles({
    title: {
        fontWeight: 'bold',
    },
    gridAlignCenter: {
        display: 'grid',
        alignItems: 'center',
    },
    gridPaddingTopBottom: {
        paddingTop: 8,
        paddingBottom: 8,
    },
});

const ItemCard = ({ record, id }: { record?: Record; id?: any }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    const onClick = () => {
        setOpenDialog(true);
        dispatch(selectIncident.fn(record as Avatar));
    };
    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    const getPinCode = (record: Avatar): string => {
        let pinCode = '...';
        if (record.identifiers && record.identifiers.length > 0) {
            const identifier = record.identifiers.find((identifier: string) =>
                identifier.toLowerCase().startsWith(AVATAR_IDENTIFIER_ACCESS_CODE)
            );

            const splitArray: string[] | undefined = identifier?.split(AVATAR_IDENTIFIER_SEPARATOR);
            pinCode =
                splitArray &&
                splitArray.length >= 2 &&
                splitArray[0].toLowerCase() === AVATAR_IDENTIFIER_ACCESS_CODE.toLowerCase()
                    ? splitArray[1]
                    : '...';
        }

        return pinCode;
    };

    return (
        <RootDiv>
            <div onClick={onClick}>
                <IncidentNameAndExtraInformation avatar={record as Avatar} />
            </div>
            {openDialog && (
                <DialogTemplate open={openDialog} onClose={onCloseDialog}>
                    {/*<Grid container spacing={1}>*/}
                    {/*    <Grid item>*/}
                    {/*        <Typography>{_.capitalize((record as Avatar).name)}</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item className={classes.gridAlignCenter}>*/}
                    {/*        <ManagerAndDelegatedSubManager record={record as Avatar} />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<Grid container>*/}
                    {/*    <Grid>*/}
                    {/*        {canBeShownClosedEventOpinion() && (*/}
                    {/*            <ResolutionIncidentOpinionField*/}
                    {/*                event={avatar}*/}
                    {/*                enableDialog={true}*/}
                    {/*                showSeeMoreText={true}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    <IncidentNameAndExtraInformation avatar={record as Avatar} nameTypographyVariant={'body1'} />

                    <Grid container spacing={1} className={classes.gridPaddingTopBottom}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.stateTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>
                                {`${_.capitalize(getValueFromNode(record as Avatar, 'name'))}`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider />

                    {/*Action Buttons */}
                    <Grid container className={classes.gridPaddingTopBottom}>
                        <Grid item>
                            <SeeIncidentButton record={record as Avatar} />
                        </Grid>
                        <Grid item>
                            <ReminderButton record={record as Avatar} />
                        </Grid>
                        <Grid item>
                            <RestartButton record={record as Avatar} />
                        </Grid>
                        <Grid item>
                            <DelegateButton record={record as Avatar} />
                        </Grid>
                        <Grid item>
                            <EditButton record={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*Location*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.navigationPathTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <PlaceOrEventNavigationField event={record as Avatar} inline={true} />
                        </Grid>
                    </Grid>

                    {/*Service Provider*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.serviceProviderTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <ServiceProviderButtonField record={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*RapportDate*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.rapportDateTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>
                                {toFormatDate((record as Avatar).creationDate as string)}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/*time since report*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.incidentTimeDefinedTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <IncidentTimeFromTheBeginningField record={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*Resolve date*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.resolveDateTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <RecoverMetabaseData
                                // @ts-ignore
                                eventId={record.id}
                                question={INCIDENT_TIME_QUESTION}
                                requestBody={{
                                    id: INCIDENT_TIME_QUESTION,
                                    dashboard_type: 'question',
                                    // @ts-ignore
                                    event_id: extractIdFromURI(record.id as string),
                                    place_id: null,
                                }}
                            >
                                {({ data, loading }: { data: any; loading: boolean }) => (
                                    <ResolvedIncidentDateField record={record as Avatar} />
                                )}
                            </RecoverMetabaseData>{' '}
                        </Grid>
                    </Grid>

                    {/*event assigned date*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.eventAssignedDateTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <EventAssignedDateField event={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*work resolution delay*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.workResolutionDelayTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <UserResolutionDeadlineField event={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*countdown*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.countdownTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <CountdownField event={record as Avatar} />
                        </Grid>
                    </Grid>

                    {/*pin code*/}
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant={'subtitle2'} className={classes.title}>{`${translate(
                                'app.incidents.pinCodeTitle'
                            )}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>{getPinCode(record as Avatar)}</Typography>
                        </Grid>
                    </Grid>

                    {/*/!*Image and comment*!/*/}
                    {/*<Grid container direction={'column'} spacing={1}>*/}
                    {/*    <Grid item>*/}
                    {/*        <Typography className={classes.title}>{`${translate(*/}
                    {/*            'app.incidents.imageAndCommentTitle'*/}
                    {/*        )}`}</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item>*/}
                    {/*        <EventOpenedStateInfo event={record as Avatar} />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    <DialogTemplateButtons
                        outside
                        withCancel
                        onCancelClick={onCloseDialog}
                        cancelButtonLabel={translate('app.return')}
                    />
                </DialogTemplate>
            )}
        </RootDiv>
    );
};

export default ItemCard;
