import React, { useEffect, useState } from 'react';
import { GetListResult, Identifier, Record, ReduxState, useDataProvider, useNotify } from 'react-admin';
import {
    Avatar,
    PlaceOrEventPath,
    TicketingEventUser,
    TicketingUserProviderEventConfiguration,
    User,
} from '../../../utils/types';
import { useSelector } from 'react-redux';
import {
    TICKETING_EVENT_USERS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
    USERS,
} from '../../../providers/resources';
import { getTicketingUserProviderEventConfig } from '../../../utils/user/resolutionDeadline';
import Typography from '@material-ui/core/Typography';
import moment, { Moment } from 'moment';
import { CustomReducerState } from '../../../redux/reducers';
import { toFormatDate } from '../../../utils/date';

interface Props {
    event: Avatar | undefined;
}

const EventAssignedDateField = ({ event }: Props) => {
    const ticketingEventUser: TicketingEventUser | undefined = useSelector((state: ReduxState) => {
        return Object.values(state.admin.resources[TICKETING_EVENT_USERS].data).find(
            (datum) => (datum as TicketingEventUser).event === event?.id
        ) as TicketingEventUser;
    });

    return (
        <span>
            <Typography variant={'body2'}>
                {ticketingEventUser ? toFormatDate(ticketingEventUser.updatedAt) : '...'}
            </Typography>
        </span>
    );
};

export default EventAssignedDateField;
