import Grid from '@material-ui/core/Grid';
import React from 'react';
import { BooleanInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { REPEATABLE_FIELD, WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD } from '../../utils/metadataAvatarType';

const useStyles = makeStyles(() => ({}));

const BooleanInputEventRepeatable = () => {
    const translate = useTranslate();
    const { input: isRepeatable } = useField(REPEATABLE_FIELD);

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <BooleanInput
                    label={
                        <Tooltip placement={'right'} title={translate('app.incidentTypes.repeatableInfo')}>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                        {translate('app.incidentTypes.create.input.repeatable')}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop: '4px' }}>
                                    <InfoIcon />
                                </Grid>
                            </Grid>
                        </Tooltip>
                    }
                    source={REPEATABLE_FIELD}
                />
            </Grid>

            <Grid item>
                {isRepeatable.value && (
                    <BooleanInput
                        label={
                            <Tooltip
                                placement={'right'}
                                title={translate('app.incidentTypes.create.input.titleOverwriteInfo')}
                            >
                                <Grid container alignItems={'center'}>
                                    <Grid item>
                                        <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                            {translate('app.incidentTypes.create.input.titleOverwrite')}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginTop: '4px' }}>
                                        <InfoIcon />
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        }
                        source={WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default BooleanInputEventRepeatable;
