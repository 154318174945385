import { Identifier } from 'react-admin';

export interface RegisterTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumnAction {
    type: typeof registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.action;
    payload: {
        ticketingSubcategoryEventTypesDepsInList: Identifier;
        ticketingSubcategoryEventTypesDepsIdShownInEachColumn: Identifier;
        placeId: Identifier;
        toDelete: boolean;
    };
}

export const registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn = {
    action:
        '360SC_TICKETING/REGISTER_TICKETING_SUBCATEGORY_EVENT_TYPES_DEPENDENCIES_IN_LIST_AND_ID_SHOW_IN_EACH_COLUMN',
    fn: (payload: {
        ticketingSubcategoryEventTypesDepsInList: Identifier;
        ticketingSubcategoryEventTypesDepsIdShownInEachColumn: Identifier;
        placeId: Identifier;
        toDelete: boolean;
    }): RegisterTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumnAction => ({
        type: registerTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumn.action,
        payload: {
            ...payload,
        },
    }),
};

export type ACTIONS = RegisterTicketingSubcategoryEventTypesDepsInListAndIdShownInEachColumnAction;
