import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TopToolbarButtonStyled } from '../../theme';
import Typography from '@material-ui/core/Typography';
import { Identifier, useListContext, useTranslate } from 'react-admin';
import EditSeveralIncidentTypes from './actions/edit/editMultipleItems';

interface Props {
    resource?: string;
    basePath?: string;
    filterValues?: { [key: string]: any };
    selectedIds?: Identifier[];
}

const IncidentTypeBulkActionButtons = (props: Props) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const [openEditFormDialog, setOpenEditFormDialog] = useState(false);

    const { onUnselectItems } = useListContext();

    const handleEditClick = () => {
        setOpenEditFormDialog(true);
    };
    const handleCloseEditForm = () => {
        setOpenEditFormDialog(false);
        onUnselectItems();
    };

    return (
        <Fragment>
            {loading ? (
                <Grid>
                    <CircularProgress />
                </Grid>
            ) : (
                <TopToolbarButtonStyled disabled={loading} onClick={handleEditClick}>
                    <Typography variant={'button'}> {translate('app.incidentTypes.edit.label')}</Typography>
                </TopToolbarButtonStyled>
            )}

            {openEditFormDialog ? (
                // @ts-ignore
                <EditSeveralIncidentTypes
                    handleClose={handleCloseEditForm}
                    selectedIds={props.selectedIds}
                    open={openEditFormDialog}
                />
            ) : (
                <span />
            )}
        </Fragment>
    );
};

export default IncidentTypeBulkActionButtons;
