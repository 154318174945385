import React, { useEffect, useState } from 'react';
import { Avatar, PlaceOrEventCount } from '../../../utils/types';
import { FunctionField } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import extractIdFromURI from '../../../utils/id';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import { TICKETING_PLACE_OR_EVENT } from '../../../providers/resources';
import { getPlaceOrEventCount } from '../../../utils/placeOrEvent';
import { registerPlacesOrEventsPlaceCount } from '../../../redux/actions/placesOrEvents';

interface Props {
    label: string;
    overwriteHeaderClassName: string;
    record?: Avatar;
}

const useStyles = makeStyles({
    linearProgress: {
        height: 3,
        width: 20,
        margin: 0,
    },
});

const IncidentInProgressField = ({ label, overwriteHeaderClassName, record }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);

    const currentPlacesOrEventsCount: number | undefined = useSelector((state: CustomReducerState) => {
        const placeOrEventId = Object.keys(state.ticketing.placesOrEventsCount).find(
            (placeOrEventId) => record && placeOrEventId === record.id
        );
        return placeOrEventId ? state.ticketing.placesOrEventsCount[placeOrEventId].count : undefined;
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | undefined>(
        currentPlacesOrEventsCount ? currentPlacesOrEventsCount : 0
    );

    useEffect(() => {
        if (record && !currentPlacesOrEventsCount) {
            setLoading(true);
            getPlaceOrEventCount(
                (dataJson: any) => {
                    if (dataJson && Array.isArray(dataJson) && dataJson.length >= 0) {
                        const placeOrEvent: PlaceOrEventCount = dataJson[0];
                        setAmount(placeOrEvent?.count);
                        setLoading(false);
                        dispatch(registerPlacesOrEventsPlaceCount.fn(placeOrEvent, record.id));
                    }
                },
                (error: any) => {
                    setLoading(false);
                    console.error('Getting ', TICKETING_PLACE_OR_EVENT, 'count', error);
                },
                extractIdFromURI(record.id as string),
                tokenWithRoles,
                true
            ).catch((error: any) => {
                console.error('Getting ', TICKETING_PLACE_OR_EVENT, 'count', error);
            });
        }
    }, [record?.id]);

    return (
        <FunctionField
            headerClassName={overwriteHeaderClassName}
            label={label}
            render={() => {
                return (
                    <>
                        {loading && null === amount && <LinearProgress className={classes.linearProgress} />}
                        {!loading && <span>{amount}</span>}
                    </>
                );
            }}
        />
    );
};
export default IncidentInProgressField;
