import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import { ListItemCollapseDatum, ListItemProps } from '../../utils/types';
import { usePermissions } from 'react-admin';

const useStyles = makeStyles(() => ({
    root: {},
    listItemIcon: {
        minWidth: 37,
    },
    listItemText: {
        fontSize: 14,
    },
    nested: {
        paddingLeft: 35,
    },
}));

const RenderListItem = ({ onClick, collapseData, witCollapse, icon, label, openByDefault }: ListItemProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [authorizedCollapseData, setAuthorizedCollapseData] = useState<ListItemCollapseDatum[]>([]);
    const { loaded, permissions } = usePermissions();

    useEffect(() => {
        if (loaded && permissions && collapseData) {
            setAuthorizedCollapseData([
                ...collapseData.filter((collapseDatum) => {
                    return collapseDatum.roles.every((role) => {
                        return permissions.includes(role);
                    });
                }),
            ]);
        }
        if (witCollapse && openByDefault) {
            setOpen(true);
        }
    }, [loaded]);

    return (
        <Fragment key={label}>
            <ListItem
                button
                onClick={
                    witCollapse
                        ? () => {
                              setOpen(!open);
                          }
                        : onClick
                }
                dense
            >
                {icon && (
                    <ListItemIcon className={classes.listItemIcon}>
                        {cloneElement(icon, { color: 'primary' })}
                    </ListItemIcon>
                )}

                <ListItemText primary={label} classes={{ primary: classes.listItemText }} />

                {witCollapse && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>

            {witCollapse && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {authorizedCollapseData &&
                            authorizedCollapseData.map(({ label, onClick, icon, disabled }) => (
                                <ListItem
                                    key={label}
                                    button
                                    className={classes.nested}
                                    dense
                                    onClick={onClick}
                                    disabled={disabled ? disabled : false}
                                >
                                    {icon && (
                                        <ListItemIcon className={classes.listItemIcon}>
                                            {cloneElement(icon, { color: 'primary' })}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={label} classes={{ primary: classes.listItemText }} />
                                </ListItem>
                            ))}
                    </List>
                </Collapse>
            )}
        </Fragment>
    );
};

const SideBarMenu = ({ list }: { list: ListItemProps[] }) => {
    const classes = useStyles();

    return (
        <List component='nav' className={classes.root}>
            {list.map((item) => (
                <RenderListItem key={item.label} {...item} />
            ))}
        </List>
    );
};

export default SideBarMenu;
