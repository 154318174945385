import AddIcon from '@material-ui/icons/Add';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { ReduxState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';
import { CustomReducerState } from '../../../redux/reducers';
import { getSubCategorySelected } from '../../../redux/reducers/subCategories';
import { MetadataAvatarType } from '../../../utils/types';
import CreateSubCategoryForm from './create';
import UpdateMetadataAvatarTypeAndTicketingLogoDialog, {
    SUBCATEGORY_EDIT_FORM_NAME,
} from '../../../commons/dialog/UpdateMetadataAvatarTypeAndTicketingLogoDialog';
import {
    ROLE_CREATE_OBJECT_TYPE,
    ROLE_CREATE_OBJECT_TYPE_PROPERTIES,
    ROLE_DELETE_OBJECT_TYPE,
    ROLE_READ_OBJECT_TYPE_PROPERTIES,
    ROLE_UPDATE_OBJECT_TYPE,
    ROLE_UPDATE_OBJECT_TYPE_PROPERTIES,
} from '../../../utils/ROLES';
import DeleteForm from './delete';

const SimpleActionMenu = () => {
    const translate = useTranslate();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [menuState, setMenuState] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);

    const selected: MetadataAvatarType | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getSubCategorySelected(state)
    );

    const showCreateFormFn = () => {
        setShowCreateForm(true);
    };

    const showEditFormFn = () => {
        setShowEditForm(true);
    };

    const showDeleteFormFn = () => {
        setShowDeleteForm(true);
    };

    const menuActions: MenuItemType[] = [
        {
            icon: <AddIcon />,
            text: translate('app.menuActions.create'),
            onClick: showCreateFormFn,
            disabled: false,
            roles: [
                ROLE_UPDATE_OBJECT_TYPE,
                ROLE_CREATE_OBJECT_TYPE,
                ROLE_READ_OBJECT_TYPE_PROPERTIES,
                ROLE_CREATE_OBJECT_TYPE_PROPERTIES,
            ],
        },
        {
            icon: <BorderColorIcon />,
            text: translate('app.menuActions.edit'),
            onClick: showEditFormFn,
            disabled: !selected,
            roles: [ROLE_UPDATE_OBJECT_TYPE, ROLE_READ_OBJECT_TYPE_PROPERTIES, ROLE_UPDATE_OBJECT_TYPE_PROPERTIES],
        },
        {
            icon: <DeleteIcon />,
            text: translate('app.menuActions.archive'),
            onClick: showDeleteFormFn,
            disabled: !selected,
            roles: [ROLE_DELETE_OBJECT_TYPE],
        },
    ];

    const handleToggle = () => {
        setMenuState(!menuState);
    };

    return (
        <div>
            <MenuButtonActions
                buttonLabel={'app.menuActions.title'}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />

            {showCreateForm && <CreateSubCategoryForm open={true} handleClose={() => setShowCreateForm(false)} />}

            {showEditForm && selected && (
                // <EditSubCategoryForm selected={selected} open={true} handleClose={() => setShowEditForm(false)} />
                <UpdateMetadataAvatarTypeAndTicketingLogoDialog
                    open={true}
                    handleClose={() => setShowEditForm(false)}
                    selected={selected}
                    formNameToShow={SUBCATEGORY_EDIT_FORM_NAME}
                />
            )}

            {showDeleteForm && selected && (
                <DeleteForm selected={selected} open={true} handleClose={() => setShowDeleteForm(false)} />
            )}
        </div>
    );
};

export default SimpleActionMenu;
