import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { cloneElement, ReactElement } from 'react';

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto auto',
    backgroundColor: '#FFFFFF',
    borderColor: '#0000004a',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: 5,
});

const useStyles = makeStyles({
    icon: {
        fontSize: 23,
    },
    title: {
        marginLeft: 5,
        marginRight: 5,
        fontWeight: 500,
        fontSize: 13,
        textTransform: 'uppercase',
    },
    titleItem: {
        marginBottom: 10,
    },
    childrenItem: {
        // width: '100%',
    },
});

interface Props {
    icon: ReactElement;
    children: any;
    title: any;
    subtitle?: any;
    onClose?: () => void;
    config?: ReactElement;
    closeIconProps?: { [key: string]: any };
}

const DashboardBox = ({ icon, title, children, onClose, config, closeIconProps, subtitle }: Props) => {
    const classes = useStyles();

    return (
        <CustomDiv>
            <div className={classes.titleItem}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item>{cloneElement(icon, { ...icon.props, className: classes.icon })}</Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography className={classes.title} noWrap>
                            {title}
                        </Typography>
                    </Grid>

                    {config && <Grid item>{config}</Grid>}

                    {onClose && (
                        <Grid item>
                            <IconButton
                                onClick={onClose}
                                className={classes.icon}
                                style={{ padding: 2, marginLeft: 10 }}
                            >
                                <CloseIcon color={'primary'} />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                {subtitle && (
                    <Grid item xs zeroMinWidth>
                        <Typography variant={'body1'} noWrap>
                            {subtitle}
                        </Typography>
                    </Grid>
                )}
            </div>

            {children}
        </CustomDiv>
    );
};

export default DashboardBox;
