import React from 'react';
import { useListContext, ListControllerProps } from 'react-admin';

// @ts-ignore
export const _ParentListContext = React.createContext<ListControllerProps>({});

const ParentListContext = (props: any) => {
    const context = useListContext();
    return <_ParentListContext.Provider value={context}>{props.children}</_ParentListContext.Provider>;
};
export default ParentListContext;
