import { Identifier } from 'react-admin';
import { PlaceOrEventPath } from '../../utils/types';
import { ACTIONS, RegisterPlacesOrEventsPath, registerPlacesOrEventsPlacePath } from '../actions/placesOrEvents';
import { clearTicketingStateCreator } from '../actions/ticketingState';

/**
 * key is incident's id
 */
export interface PlacesOrEventsPathState {
    [key: Identifier]: PlaceOrEventPath[];
}

const InitialState: PlacesOrEventsPathState = {};

const reducer = (state = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case registerPlacesOrEventsPlacePath.action:
            return {
                ...state,
                [(action as RegisterPlacesOrEventsPath).payload.eventOrPlaceId]: [
                    ...(action as RegisterPlacesOrEventsPath).payload.path,
                ],
            };
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
