import { ChartDashboard } from '../../utils/types';
import QuantityOfOpenAndContractedIncidentsDashboard from '../dashboards/quantity-of-open-and-contracted-incidents-dashboard';
import NumberOfIncidentsAwaitingAllocationDashboard from '../dashboards/number-of-incidents-awaiting-allocation-dashboard';
import TopCategoriesWithIncidentsFilteredByCategoryDashboard from '../dashboards/top-categories-with-incidents-filtered-by-category-dashboard';
import TopIncidentsDashboard from '../dashboards/top-incidents-dashboard';
import IncidentTimeFromTheBeginningDashboard from '../dashboards/incident-time-from-the-beginning-dashboard';
import TotalNumberOfIncidentsByCompanyDashboard from '../dashboards/total-number-of-incidents-by-company-dashboard';
import { GROUP_METADATA_AVATAR_TYPE_EVENT, GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../utils/CONST';
import { AVATARS, GROUP_METADATA_AVATAR_TYPES, METADATA_AVATAR_TYPES } from '../../providers/resources';
import TopSubcategoriesWithIncidentsDashboard from '../dashboards/top-subcategories-with-incidents-dashboard';

export const charts: ChartDashboard[] = [
    {
        id: 10,
        label: 'app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.title',
        // @ts-ignore
        component: QuantityOfOpenAndContractedIncidentsDashboard,
        maxRepeated: 1,
        componentProps: {
            infoText: 'app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.infoText',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.title',
            dialogHelpText: 'app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.helpText',
            requestQuestionId: 10,
            menuOptionLabel: {
                place: 'app.dashboards.quantityOfOpenAndContractedIncidentsDashboard.place',
            },
        },
    },
    {
        id: 11,
        label: 'app.dashboards.numberOfIncidentsAwaitingAllocationDashboard.title',
        // @ts-ignore
        component: NumberOfIncidentsAwaitingAllocationDashboard,
        maxRepeated: 1,
        componentProps: {
            infoText: 'app.dashboards.numberOfIncidentsAwaitingAllocationDashboard.infoText',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.numberOfIncidentsAwaitingAllocationDashboard.title',
            dialogHelpText: 'app.dashboards.numberOfIncidentsAwaitingAllocationDashboard.helpText',
            requestQuestionId: 11,
            menuOptionLabel: {
                place: 'app.dashboards.numberOfIncidentsAwaitingAllocationDashboard.place',
            },
        },
    },
    {
        id: 6,
        label: 'app.dashboards.totalNumberOfIncidentsByCompanyDashboard.title',
        // @ts-ignore
        component: TotalNumberOfIncidentsByCompanyDashboard,
        maxRepeated: -1,
        componentProps: {
            infoText: 'app.dashboards.totalNumberOfIncidentsByCompanyDashboard.infoText',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.totalNumberOfIncidentsByCompanyDashboard.title',
            dialogHelpText: 'app.dashboards.totalNumberOfIncidentsByCompanyDashboard.helpText',
            requestQuestionId: 6,
            menuOptionLabel: {
                place: 'app.dashboards.totalNumberOfIncidentsByCompanyDashboard.place',
            },
        },
    },
    {
        id: 2,
        label: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.title',
        labelShort: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.titleShort',
        // @ts-ignore
        component: TopCategoriesWithIncidentsFilteredByCategoryDashboard,
        maxRepeated: -1,
        componentProps: {
            filters: {
                'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
            },
            reference: METADATA_AVATAR_TYPES,
            filterToQuery: (data: any) => ({
                name: data,
            }),
            label: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.selectSubcategory',
            formFieldName: 'subcategory',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.title',
            dialogHelpText: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.helpText',
            infoText: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.infoText',
            requestFieldName: 'metadata_avatar_type_id',
            requestQuestionId: 2,
            menuOptionLabel: {
                subcategory: 'app.dashboards.topCategoriesWithIncidentsFilteredByCategoryDashboard.subcategory',
            },
        },
    },
    {
        id: 12,
        label: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.title',
        labelShort: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.titleShort',
        // @ts-ignore
        component: TopSubcategoriesWithIncidentsDashboard,
        maxRepeated: -1,
        componentProps: {
            filters: {
                alias: GROUP_METADATA_AVATAR_TYPE_LEVEL,
            },
            reference: GROUP_METADATA_AVATAR_TYPES,
            filterToQuery: (data: any) => ({
                name: data,
            }),
            label: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.selectCategory',
            formFieldName: 'category',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.title',
            dialogHelpText: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.helpText',
            infoText: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.infoText',
            requestFieldName: 'group_metadata_avatar_type_id',
            requestQuestionId: 12,
            menuOptionLabel: {
                category: 'app.dashboards.topSubcategoriesWithIncidentsFilteredBySubcategoryDashboard.category',
            },
        },
    },
    {
        id: 7,
        label: 'app.dashboards.topIncidentsDashboard.title',
        // @ts-ignore
        component: TopIncidentsDashboard,
        maxRepeated: -1,
        componentProps: {
            infoText: 'app.dashboards.topIncidentsDashboard.infoText',
            dialogTitle: 'app.dashboards.option',
            dialogHelpText: 'app.dashboards.topIncidentsDashboard.helpText',
            requestQuestionId: 7,
        },
    },
    {
        id: 13,
        label: 'app.dashboards.incidentTimeFromTheBeginningDashboard.title',
        // @ts-ignore
        component: IncidentTimeFromTheBeginningDashboard,
        maxRepeated: 1,
        componentProps: {
            infoText: 'app.dashboards.incidentTimeFromTheBeginningDashboard.infoText',
            dialogTitle: 'app.dashboards.option',
            dashboardTitle: 'app.dashboards.incidentTimeFromTheBeginningDashboard.title',
            dialogHelpText: 'app.dashboards.incidentTimeFromTheBeginningDashboard.helpText',
            label: 'app.dashboards.incidentTimeFromTheBeginningDashboard.selectIncident',
            requestQuestionId: 13,
        },
    },
];
