import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import {
    BooleanInput,
    CreateResult,
    GetListResult,
    Record,
    ReferenceArrayInput,
    UpdateResult,
    useCreate,
    useDataProvider,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Form as FinalForm } from 'react-final-form';
import HeaderBlock from '../../../../../commons/HeaderBlock/HeaderBlock';
import { FINGERS, RUNNER_TEMPLATES, TICKETING_PROCESS_CUSTOMIZATIONS } from '../../../../../providers/resources';
import { Avatar, TicketingProcessCustomizations } from '../../../../../utils/types';
import ButtonsBlockForm from '../../../../forms/buttonsBlock';
import BooleanInputDependent from './input/BooleanInputDependent';
import { AutocompleteInput } from '../../../../../commons/ra/input/AutocompleteInput';
import LinearProgress from '@material-ui/core/LinearProgress';

interface Props {
    place: Avatar;
}

const useStyles = makeStyles(() => ({
    switchLabel: {
        fontSize: 13,
        fontWeight: 700,
    },
    gridPaddingTop: {
        marginLeft: 30,
        paddingTop: 10,
    },
    linearProgress: {
        height: 3,
        width: '100%',
        margin: 0,
    },
}));

const TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD = 'takeIntoAccountAutomaticallyUserUnderContract';
const ADD_AUTOMATICALLY_USER_PROVIDER_FIELD = 'addAutomaticallyUserProvider';
const HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD = 'hasTakeIntoAccountSubProcess';
const FINGER_FIELD = 'finger';
const RUNNER_TEMPLATE_FIELD = 'runnerTemplate';
const RUNNER_TEMPLATE_VERSION = 3;
const UNIQUE_KEY_BETWEEN_VERSION_TICKETING = process.env.REACT_APP_FINGER_UNIQUE_KEY_BETWEEN_VERSION_VALUE;

const ProcessConfigurationBlock = ({ place }: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const dataProvider = useDataProvider();

    const [create] = useCreate(TICKETING_PROCESS_CUSTOMIZATIONS);
    const [update] = useUpdate(TICKETING_PROCESS_CUSTOMIZATIONS);

    const notify = useNotify();

    const [initialTicketingProcess, setInitialTicketingProcess] = useState<TicketingProcessCustomizations | undefined>(
        undefined
    );

    useEffect(() => {
        dataProvider.getList(
            TICKETING_PROCESS_CUSTOMIZATIONS,
            {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: {
                    place: place.id as string,
                },
            },
            {
                onSuccess: ({ data }: GetListResult) => {
                    if (data && data.length > 0) {
                        const ticketingProcess = data[0] as TicketingProcessCustomizations;
                        setInitialTicketingProcess({
                            ...ticketingProcess,
                        });
                    }
                },
                onFailure: (error: any) => {
                    console.error('ERROR getting', TICKETING_PROCESS_CUSTOMIZATIONS, error);
                    notify('app.navigation.process.get.error', 'warning');
                },
            }
        );
    }, []);

    const placeCompanyUri =
        typeof place.company === 'object' && 'id' in place.company ? place.company.id : place.company;

    const resetInitialState = (ticketingProcessData: TicketingProcessCustomizations) => {
        setInitialTicketingProcess({
            id: ticketingProcessData.id,
            [TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD]:
                ticketingProcessData.takeIntoAccountAutomaticallyUserUnderContract,
            [ADD_AUTOMATICALLY_USER_PROVIDER_FIELD]: ticketingProcessData.addAutomaticallyUserProvider,
            [HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD]: ticketingProcessData.hasTakeIntoAccountSubProcess,
            createdAt: ticketingProcessData.createdAt,
            place: ticketingProcessData.place,
            company: ticketingProcessData.company,
            runnerTemplate: ticketingProcessData.runnerTemplate,
            finger: ticketingProcessData.finger,
        });
    };
    const onSubmit = (formData: { [key: string]: any }) => {
        const dataToSend = {
            [ADD_AUTOMATICALLY_USER_PROVIDER_FIELD]: formData[ADD_AUTOMATICALLY_USER_PROVIDER_FIELD],
            [TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD]:
                formData[TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD],
            [HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD]: formData[HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD],
            [RUNNER_TEMPLATE_FIELD]: formData[RUNNER_TEMPLATE_FIELD],
            [FINGER_FIELD]: formData[FINGER_FIELD],
        };
        //update
        if (initialTicketingProcess) {
            update(TICKETING_PROCESS_CUSTOMIZATIONS, initialTicketingProcess.id, dataToSend, initialTicketingProcess, {
                onSuccess: ({ data }: UpdateResult<Record>) => {
                    if (data) {
                        const ticketingProcessData: TicketingProcessCustomizations = data as TicketingProcessCustomizations;
                        resetInitialState(ticketingProcessData);
                        notify('app.navigation.process.updated.notification', 'success');
                    }
                },
                onFailure: (error: any) => {
                    console.error('ERROR updating', TICKETING_PROCESS_CUSTOMIZATIONS, error);
                    notify('app.navigation.process.updated.error', 'error');
                },
            });
        } else {
            //create
            create(
                TICKETING_PROCESS_CUSTOMIZATIONS,
                { ...dataToSend, place: place.id as string, company: placeCompanyUri },
                {
                    onSuccess: ({ data }: CreateResult<Record>) => {
                        if (data) {
                            const ticketingProcessData: TicketingProcessCustomizations = data as TicketingProcessCustomizations;
                            resetInitialState(ticketingProcessData);
                            notify('app.navigation.process.created.notification', 'success');
                        }
                    },
                    onFailure: (error: any) => {
                        console.error('ERROR creating', TICKETING_PROCESS_CUSTOMIZATIONS, error);
                        notify('app.navigation.process.created.error', 'error');
                    },
                }
            );
        }
    };

    return (
        <HeaderBlock icon={<InfoIcon />} label={''}>
            {!initialTicketingProcess && <LinearProgress />}
            <FinalForm
                // keepDirtyOnReinitialize={true}
                initialValues={{
                    ...initialTicketingProcess,
                    [ADD_AUTOMATICALLY_USER_PROVIDER_FIELD]: initialTicketingProcess
                        ? initialTicketingProcess[ADD_AUTOMATICALLY_USER_PROVIDER_FIELD]
                        : false,
                    [HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD]: initialTicketingProcess
                        ? initialTicketingProcess[HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD]
                        : false,
                    [TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD]: initialTicketingProcess
                        ? initialTicketingProcess[TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD]
                        : false,
                }}
                validate={(values) => {
                    const errors: {
                        [key: string]: string | undefined;
                    } = {
                        [FINGER_FIELD]: undefined,
                        [RUNNER_TEMPLATE_FIELD]: undefined,
                        [ADD_AUTOMATICALLY_USER_PROVIDER_FIELD]: undefined,
                        [TAKE_INTO_ACCOUNT_AUTOMATICALLY_USER_UNDER_CONTACT_FIELD]: undefined,
                        [HAS_TAKE_INTO_ACCOUNT_SUB_PROCESS_FIELD]: undefined,
                    };
                    if (!values[RUNNER_TEMPLATE_FIELD]) {
                        errors[RUNNER_TEMPLATE_FIELD] = 'ra.validation.required';
                    }
                    if (!values[FINGER_FIELD]) {
                        errors[FINGER_FIELD] = 'ra.validation.required';
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, submitting }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid item className={classes.gridPaddingTop}>
                                <ReferenceArrayInput
                                    reference={RUNNER_TEMPLATES}
                                    filterToQuery={(searchText: string) => ({
                                        name: searchText,
                                    })}
                                    filter={{
                                        'version[gte]': RUNNER_TEMPLATE_VERSION,
                                    }}
                                    isRequired
                                    source={RUNNER_TEMPLATE_FIELD}
                                    label={translate('app.navigation.process.name')}
                                >
                                    <AutocompleteInput
                                        resettable
                                        isRequired
                                        variant={'standard'}
                                        optionText='name'
                                        fullWidth
                                        filterToQuery={(search: string) => ({ name: search })}
                                    />
                                </ReferenceArrayInput>
                            </Grid>

                            <Grid item className={classes.gridPaddingTop}>
                                <ReferenceArrayInput
                                    isRequired
                                    reference={FINGERS}
                                    filterToQuery={(searchText: string) => ({
                                        name: searchText,
                                    })}
                                    filter={{
                                        uniqueKeyBetweenVersion: UNIQUE_KEY_BETWEEN_VERSION_TICKETING,
                                    }}
                                    source={FINGER_FIELD}
                                    label={translate('app.navigation.process.service.name')}
                                >
                                    <AutocompleteInput
                                        resettable
                                        filterToQuery={(searchText: string) => ({
                                            name: searchText,
                                        })}
                                        isRequired
                                        variant={'standard'}
                                        optionText='name'
                                        fullWidth
                                    />
                                </ReferenceArrayInput>
                            </Grid>

                            <Grid container>
                                <Grid item className={classes.gridPaddingTop}>
                                    <BooleanInput
                                        label={
                                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                {translate('app.navigation.process.enableServiceProvider')}
                                            </Typography>
                                        }
                                        source={ADD_AUTOMATICALLY_USER_PROVIDER_FIELD}
                                    />
                                </Grid>
                            </Grid>

                            <BooleanInputDependent />

                            <ButtonsBlockForm
                                validButtonIsSubmitType={true}
                                showValidButton={true}
                                disableValidButton={pristine || submitting}
                                showCancelButton={false}
                            />
                        </form>
                    );
                }}
            />
        </HeaderBlock>
    );
};

export default ProcessConfigurationBlock;
