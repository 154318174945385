import { makeStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(() => ({
    overline: {
        fontWeight: 700,
    },
}));

interface GlobalFormProps {
    titleIcon?: any;
    title?: string;
    titleTranslateOption?: any;
    subtitleTranslateOption?: any;
    subtitle?: string;
    children?: any;
}

const GlobalForm = ({
    titleIcon,
    title,
    titleTranslateOption,
    subtitle,
    children,
    subtitleTranslateOption,
}: GlobalFormProps) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1}>
                {titleIcon && (
                    <Grid item alignContent={'center'}>
                        {titleIcon}
                    </Grid>
                )}
                {title && (
                    <Grid item alignContent={'center'}>
                        <Typography variant={'overline'} paragraph color={'secondary'} className={classes.overline}>
                            {titleTranslateOption ? translate(title, titleTranslateOption) : translate(title)}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Grid container>
                {subtitle && (
                    <Grid item>
                        <Typography variant={'subtitle2'} paragraph>
                            {/*{translate(subtitle)}*/}
                            {subtitleTranslateOption
                                ? translate(subtitle, subtitleTranslateOption)
                                : translate(subtitle)}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container>{children}</Grid>
        </>
    );
};

export default GlobalForm;
