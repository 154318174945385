// @ts-nocheck

import React, { Children, cloneElement, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
    useInput,
    // useReferenceInputController,
    InputProps,
    warning as warningLog,
    ListContextProvider,
    ReferenceInputValue,
    UseInputValue,
    ResourceContextProvider,
} from 'react-admin';

import sanitizeInputRestProps from './sanitizeInputRestProps';
import ReferenceError from './ReferenceError';
import { useReferenceInputController } from '../controllers/useRefernceInputController';

/**
 * It's mandatory to use
 *
 * Error fixed in useReferenceInputController. used useGetOne instead of useReference
 *
 * @param props
 * @constructor
 */
const ReferenceInput = (props: ReferenceInputProps) => {
    const { format, onBlur, onChange, onFocus, parse, validate, ...rest } = props;

    const inputProps = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        validate,
        ...rest,
    });

    return (
        <ReferenceInputView {...inputProps} {...rest} {...useReferenceInputController({ ...rest, ...inputProps })} />
    );
};

ReferenceInput.propTypes = {
    allowEmpty: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    filter: PropTypes.object,
    filterToQuery: PropTypes.func.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    perPage: PropTypes.number,
    record: PropTypes.object,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string,
    sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.oneOf(['ASC', 'DESC']),
    }),
    source: PropTypes.string,
};

ReferenceInput.defaultProps = {
    filter: {},
    filterToQuery: (searchText) => (searchText ? { q: searchText } : {}),
    perPage: 25,
    sort: { field: 'id', order: 'DESC' },
};

export interface ReferenceInputProps extends InputProps {
    allowEmpty?: boolean;
    basePath?: string;
    children: ReactElement;
    classes?: any;
    className?: string;
    filterToQuery?: (filter: string) => any;
    label?: string;
    perPage?: number;
    reference: string;
    // @deprecated
    referenceSource?: (resource: string, source: string) => string;
    resource?: string;
    [key: string]: any;
}

const sanitizeRestProps = ({
    dataStatus = null,
    filter = null,
    filterToQuery = null,
    onChange = null,
    perPage = null,
    reference = null,
    referenceRecord = null,
    referenceSource = null,
    sort = null,
    validation = null,
    ...rest
}) => sanitizeInputRestProps(rest);

export interface ReferenceInputViewProps extends ReferenceInputValue, ReferenceInputProps, Omit<UseInputValue, 'id'> {}

export const ReferenceInputView = (props: ReferenceInputViewProps) => {
    const {
        allowEmpty,
        basePath,
        children,
        choices,
        classes,
        className,
        error,
        helperText,
        id,
        input,
        isRequired,
        label,
        meta,
        possibleValues,
        resource,
        reference,
        setFilter,
        setPagination,
        setSort,
        source,
        warning,
        ...rest
    } = props;
    if (Children.count(children) !== 1) {
        throw new Error('<ReferenceInput> only accepts a single child');
    }

    // This is not a final-form error but an unrecoverable error from the
    // useReferenceInputController hook
    if (error) {
        return <ReferenceError label={label} error={error} />;
    }

    // When the useReferenceInputController returns a warning, it means it
    // had an issue trying to load the referenced record
    // We display it by overriding the final-form meta
    const finalMeta = warning
        ? {
              ...meta,
              error: warning,
          }
        : meta;

    // helperText should never be set on ReferenceInput, only in child component
    // But in a Filter component, the child helperText have to be forced to false
    warningLog(
        helperText !== undefined && helperText !== false,
        "<ReferenceInput> doesn't accept a helperText prop. Set the helperText prop on the child component instead"
    );

    const disabledHelperText = helperText === false ? { helperText } : {};

    const childrenProps = {
        allowEmpty,
        classes,
        className,
        input,
        isRequired,
        label,
        resource,
        meta: finalMeta,
        source,
        choices,
        basePath,
        setFilter,
        setPagination,
        setSort,
        translateChoice: false,
        ...disabledHelperText,
        ...sanitizeRestProps(rest),
    };

    return (
        <ResourceContextProvider value={reference}>
            <ListContextProvider value={possibleValues}>{cloneElement(children, childrenProps)}</ListContextProvider>
        </ResourceContextProvider>
    );
};

export default ReferenceInput;
