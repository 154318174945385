import React, { useEffect, useState } from 'react';
import { Avatar, TicketingEventUser, User } from '../../../utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import {
    FunctionField,
    GetListResult,
    Record,
    useDataProvider,
    useNotify,
    usePermissions,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { TICKETING_EVENT_USERS, USERS } from '../../../providers/resources';
import ReferenceField from '../../ra/field/ReferenceField';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { APP_COLOR_PRIMARY } from '../../../theme';
import { selectIncident } from '../../../redux/actions/incidents';
import AssignProviderForm from '../../../components/incidents/actions/asignProvider';
import { ROLE_CREATE_TICKETING_EVENT_USER, ROLE_TICKETING_VIEW_EVENT_APPLY_USER } from '../../../utils/ROLES';
import { hasPermissions } from '../../../utils/permissions';
import { isNodeType } from '../../../utils/getListOfNodesFromRunnerTempates';
import { TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE } from '../../../utils/CONST';

interface Props {
    record?: Avatar;
}

const useStyles = makeStyles({
    fontWeightBold: {
        fontWeight: 'bold',
        color: APP_COLOR_PRIMARY,
    },
    disabled: {},
});

const ServiceProviderButtonField = ({ record }: Props) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const refresh = useRefresh();
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const selected = useSelector((state: CustomReducerState) => state.ticketing.incidents.selected);
    const [ticketingEventUser, setTicketingEventUser] = useState<TicketingEventUser | undefined>(undefined);
    const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);

    const resolveEventUser = () => {
        if (!record) return;
        dataProvider
            .getList(TICKETING_EVENT_USERS, {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                filter: {
                    event: record.id,
                },
                sort: {
                    order: 'DESC',
                    field: 'updatedAt',
                },
            })
            .then((result: GetListResult) => {
                if (result && 'data' in result && result.data.length > 0) {
                    setTicketingEventUser(result.data[0] as TicketingEventUser);
                }
            })
            .catch((error: any) => {
                console.error(error);
                notify('app.incidents.notification.getServiceProviderError', 'warning');
            });
    };

    useEffect(() => {
        resolveEventUser();
    }, []);

    const onClickAssignBlock = () => {
        if (record && !(record?.id in selected)) {
            dispatch(selectIncident.fn(record));
        }
        setOpenAssignDialog(true);
    };

    const handleCloseAssignDialog = () => {
        setOpenAssignDialog(false);
    };

    const handleSuccessfullyAssign = () => {
        // resolveEventUser();
        refresh();
    };

    const isWaitingAssignServiceProvider = record
        ? isNodeType(record, TICKETING_WAITING_ASSIGN_SERVICE_PROVIDER_NODE_TYPE)
        : false;

    const renderFieldOrButton = () => {
        if (ticketingEventUser) {
            return (
                <ReferenceField
                    reference={USERS}
                    source={'user'}
                    linkType={false}
                    link={false}
                    record={ticketingEventUser}
                >
                    <FunctionField
                        render={(user: Record | undefined) => {
                            return <Typography variant={'body2'}>{`${(user as User)?.username} `} </Typography>;
                        }}
                    />
                </ReferenceField>
            );
        } else {
            return hasPermissions(
                [ROLE_TICKETING_VIEW_EVENT_APPLY_USER, ROLE_CREATE_TICKETING_EVENT_USER],
                loadedPermissions,
                permissions
            ) ? (
                <Tooltip
                    title={
                        isWaitingAssignServiceProvider
                            ? ''
                            : translate('app.incidents.addServiceProvider.forAssigningAServiceProviderInfo')
                    }
                >
                    <span
                        onClick={
                            isWaitingAssignServiceProvider
                                ? onClickAssignBlock
                                : () => {
                                      //
                                  }
                        }
                    >
                        <Typography
                            variant={'body2'}
                            className={isWaitingAssignServiceProvider ? classes.fontWeightBold : classes.disabled}
                            noWrap
                            // color={isWaitingAssignServiceProvider ? 'primary' : 'secondary'}
                        >
                            {isWaitingAssignServiceProvider
                                ? translate('app.incidents.addServiceProvider.toAssign')
                                : translate('app.incidents.addServiceProvider.notServiceProvider')}
                        </Typography>
                    </span>
                </Tooltip>
            ) : (
                <span />
            );
        }
    };

    return (
        <>
            <span>{renderFieldOrButton()}</span>
            {openAssignDialog && (
                <AssignProviderForm
                    open={openAssignDialog}
                    handleClose={handleCloseAssignDialog}
                    handleSuccessfully={handleSuccessfullyAssign}
                />
            )}
        </>
    );
};

export default ServiceProviderButtonField;
