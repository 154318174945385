import _ from 'lodash';
import { User } from '../types';

export const TICKETING_CUSTOM_FIELD = 'ticketing';
export const TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD = 'serviceProvider';
export const TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD = 'reference';
export const TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD = 'serviceType';

export const getTicketingField = (user: User, field: string) => {
    return _.get(user.customFields, `${TICKETING_CUSTOM_FIELD}.${field}`, null);
};
