import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Avatar } from '../../../utils/types';
import { RadioButtonGroupInput, TextInput, useNotify, useUpdate } from 'react-admin';
import CustomSubmitButton from '../../inputs/CustomSubmitButton';
import { AVATARS } from '../../../providers/resources';

interface FormProps {
    avatar: Avatar;
    label: string;
    disabled: boolean;
    forceRefresh: () => void;
    type?: string;
    choices?: any[];
}

const ENABLE_KEY = 'enabled';

const UpdateAvatarEnabledForm = ({ avatar, label, forceRefresh, disabled, choices }: FormProps) => {
    const notify = useNotify();
    const [update] = useUpdate();

    const onSubmit = (data: { [key: string]: any }) => {
        updateAvatar(data, avatar);
    };

    const updateAvatar = (data: { [key: string]: any }, item: Avatar) => {
        const dataToSend = {
            [ENABLE_KEY]: data[ENABLE_KEY],
            children: true,
        };
        update(AVATARS, `${item.id}/enable`, dataToSend, avatar, {
            onSuccess: () => {
                forceRefresh();
                notify('app.avatar.updated.notification', 'success', {
                    value: label,
                });
            },
            onFailure: (error: any) => {
                console.error('Error updating avatar property', error);
                notify('app.avatar.updated.error', 'warning', {
                    value: label,
                });
            },
        });
    };

    return (
        <FinalForm
            initialValues={{
                [ENABLE_KEY]: 'enabled' in avatar ? avatar.enabled : true,
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
                    <RadioButtonGroupInput
                        disabled={disabled}
                        variant={'standard'}
                        source={ENABLE_KEY}
                        label={label}
                        helperText={false}
                        fullWidth
                        choices={choices}
                    />
                    <CustomSubmitButton fieldKeys={[ENABLE_KEY]} handleSubmit={handleSubmit} />
                </form>
            )}
        />
    );
};

export default UpdateAvatarEnabledForm;
