import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import {
    Datagrid,
    FunctionField,
    List,
    Pagination,
    Record,
    ReferenceManyField,
    useDataProvider,
    usePermissions,
    useRefresh,
    useTranslate,
} from 'react-admin';
import TicketingUserAllocationChipField from '../../../../../../commons/fields/ticketingUserAllocation/userChipFieldv2';
import {
    METADATA_AVATAR_TYPES,
    TICKETING_USER_ALLOCATIONS,
    TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS,
} from '../../../../../../providers/resources';
import { CustomPointerAvatar, FieldHeaderListStyles } from '../../../../../../theme';
import extractIdFromURI from '../../../../../../utils/id';
import { getNameWithoutPrefixFromJson } from '../../../../../../utils/resources/GroupMetadataAvatarType';
import { capitalizeFirstLetter } from '../../../../../../utils/strings';
import { Avatar, MetadataAvatarType, TicketingUserAllocation } from '../../../../../../utils/types';
import AddServiceProviderFormDialog from '../form/add';
import './index.css';
import SingleFieldList from './SingleFieldList';
import {
    ROLE_CREATE_TICKETING_USER_ALLOCATION,
    ROLE_READ_TICKETING_USER_ALLOCATION,
    ROLE_READ_USER,
    ROLE_UPDATE_TICKETING_USER_ALLOCATION,
} from '../../../../../../utils/ROLES';
import { hasPermissions } from '../../../../../../utils/permissions';

const CustomDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
});

interface Props {
    avatar: Avatar;
    isRoot: boolean;
}

const useStyles = makeStyles(() => ({
    field: {
        ...FieldHeaderListStyles,
    },
    gridItemAlignCenter: {
        alignSelf: 'center',
    },
    image: {
        marginRight: 5,
        height: 23,
        width: 23,
    },
    secondaryColor: {
        color: 'red',
    },
}));

const EmptyPage = ({ message }: { avatar?: Avatar; message: string }) => {
    const classes = useStyles();
    return (
        <span>
            <Typography className={classes.secondaryColor}> {message} </Typography>
        </span>
    );
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[]} {...props} />;

const IncidentTypesList = ({ avatar, isRoot }: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const [showAddServiceProviderForm, setShowAddServiceProviderForm] = useState<boolean>(false);
    const [itemToEdit, setItemToEdit] = useState<MetadataAvatarType | undefined>();
    const refresh = useRefresh();
    const listProps = {
        resource: METADATA_AVATAR_TYPES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: '',
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            'ticketing_available_event_types[at_and_under_place_id]': extractIdFromURI(avatar.id as string),
        },
        pagination: <ListPagination />,
    };
    const dataProvider = useDataProvider();

    const handleOnClickAddUser = (record: MetadataAvatarType) => () => {
        setShowAddServiceProviderForm(true);
        setItemToEdit(record);
    };

    const handleCloseAddUserFormDialog = () => {
        setShowAddServiceProviderForm(false);
    };

    const onSaveInAddUserForDialog = () => {
        setShowAddServiceProviderForm(false);
        refresh();
    };

    const getTimeDefinerForThePlaceAndSubcategory = () => {
        dataProvider
            .getList(TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, {
                pagination: {
                    page: 1,
                    perPage: 25,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
                filter: {
                    place: extractIdFromURI(avatar.id as string),
                },
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getTimeDefinerForThePlaceAndSubcategory();
    }, []);

    return (
        <Grid container direction='column' className='incident'>
            <List
                {...listProps}
                exporter={false}
                bulkActionButtons={false}
                actions={false}
                empty={
                    <EmptyPage
                        avatar={avatar}
                        message={translate('app.navigation.configuration.serviceProvider.createDescription')}
                    />
                }
            >
                <Datagrid>
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.navigation.configuration.serviceProvider.incidentType.label')}
                        render={(record: Record | undefined) => (
                            <Typography>
                                {capitalizeFirstLetter(getNameWithoutPrefixFromJson(record as MetadataAvatarType))}
                            </Typography>
                        )}
                    />
                    {hasPermissions([ROLE_READ_TICKETING_USER_ALLOCATION], loadedPermissions, permissions) && (
                        <FunctionField
                            label={translate('app.navigation.configuration.serviceProvider.label')}
                            headerClassName={classes.field}
                            render={(subCategory: Record | undefined) => (
                                <CustomDiv>
                                    {hasPermissions(
                                        [
                                            ROLE_READ_TICKETING_USER_ALLOCATION,
                                            ROLE_CREATE_TICKETING_USER_ALLOCATION,
                                            ROLE_UPDATE_TICKETING_USER_ALLOCATION,
                                            ROLE_READ_USER,
                                        ],
                                        loadedPermissions,
                                        permissions
                                    ) && (
                                        <CustomPointerAvatar
                                            onClick={handleOnClickAddUser(subCategory as MetadataAvatarType)}
                                        >
                                            <AddIcon />
                                        </CustomPointerAvatar>
                                    )}

                                    {hasPermissions(
                                        [
                                            ROLE_READ_TICKETING_USER_ALLOCATION,
                                            ROLE_UPDATE_TICKETING_USER_ALLOCATION,
                                            ROLE_READ_USER,
                                        ],
                                        loadedPermissions,
                                        permissions
                                    ) && (
                                        <ReferenceManyField
                                            target={'subCategories.id'}
                                            reference={TICKETING_USER_ALLOCATIONS}
                                            filter={{
                                                'order[place.lvl]': 'DESC',
                                                'place.root':
                                                    typeof avatar?.root === 'object' ? avatar.root.id : avatar.root,
                                                'place.lvl[lte]': avatar?.lvl,
                                                'place.lft[lte]': avatar?.lft,
                                                'place.rgt[gte]': avatar?.rgt,
                                            }}
                                            record={{
                                                'subCategories.id': extractIdFromURI(
                                                    (subCategory as MetadataAvatarType).id as string
                                                ),
                                                id: (subCategory as MetadataAvatarType).id as string,
                                            }}
                                        >
                                            {/*// @ts-ignore*/}
                                            <SingleFieldList>
                                                <FunctionField
                                                    render={(record: Record | undefined) => (
                                                        <TicketingUserAllocationChipField
                                                            ticketingUserAllocation={record as TicketingUserAllocation}
                                                            place={avatar}
                                                            subCategory={subCategory as MetadataAvatarType}
                                                        />
                                                    )}
                                                />
                                            </SingleFieldList>
                                        </ReferenceManyField>
                                    )}
                                </CustomDiv>
                            )}
                        />
                    )}
                </Datagrid>
            </List>

            {showAddServiceProviderForm && itemToEdit && (
                <AddServiceProviderFormDialog
                    isRoot={isRoot}
                    subCategory={itemToEdit}
                    place={avatar}
                    open={true}
                    onSave={onSaveInAddUserForDialog}
                    handleClose={handleCloseAddUserFormDialog}
                />
            )}
        </Grid>
    );
};

export default IncidentTypesList;
