import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Avatar } from '../../../../../utils/types';
import SubcategoryEventTypeDependenciesList from './subcategoryEventTypeDependencies';

const CustomTypography = styled(Typography)({
    border: '1px solid #E4E5EB',
    padding: 10,
});

interface Props {
    item: Avatar;
}

const IncidentConfiguration: FC<Props> = ({ item }: Props) => {
    const translate = useTranslate();

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <CustomTypography variant={'body2'} style={{}}>
                    {translate('app.navigation.configuration.incident.description')}
                </CustomTypography>
            </Grid>
            <Grid item>
                <SubcategoryEventTypeDependenciesList avatar={item} />
            </Grid>
        </Grid>
    );
};

export default IncidentConfiguration;
