import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { styled, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ListContextProvider, ListControllerProps, useListContext, useListController } from 'react-admin';
import { incidents } from '../../../pages/paths';
import { AVATARS } from '../../../providers/resources';
import { GROUP_METADATA_AVATAR_TYPE_EVENT, IncidentNameAndColorStyleStatus } from '../../../utils/CONST';
import { detectBrowserName, FIREFOX_KEY } from '../../../utils/detect-browser-name';
import { _ParentListContext } from './ParentListContext';
import ItemCard from './ItemCard';

const restHeight = detectBrowserName() === FIREFOX_KEY ? 280 : 305;

const CustomIconButton = styled(IconButton)({
    padding: 5,
});

const EmptyId = styled('div')({
    height: 50,
});

const PageNumberTypography = styled(Typography)({
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    padding: '2px 10px 2px 10px',
    borderRadius: 5,
});

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 16,
    },
}))(Tooltip);

const RootDiv = styled('div')({
    padding: '0 5px 0 5px',
    width: 192,
    backgroundColor: '#eaeaee',
    display: 'grid',
    gridTemplateRows: '50px auto',
});

const StateDiv = styled('div')({
    padding: 5,
    textAlign: 'center',
    display: 'grid',
});

const TitleTypography = styled(Typography)({
    alignSelf: 'center',
    fontWeight: 'bold',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineHeight: 1.3,
});

const ConsumerFilterListContext = (props: { columnName: string; children?: any }) => {
    return (
        <_ParentListContext.Consumer>
            {(value: ListControllerProps) => (
                <WrapperDataGrid parentListContext={value} columnName={props.columnName}>
                    {props.children}
                </WrapperDataGrid>
            )}
        </_ParentListContext.Consumer>
    );
};

const WrapperDataGrid = ({
    parentListContext,
    children,
}: {
    parentListContext: ListControllerProps;
    children?: any;
    columnName: string;
}) => {
    const { setFilters, displayedFilters, setPage, setPerPage, ids, data, page, total } = useListContext();

    const [divHeight, setDivHeight] = useState(300);

    useEffect(() => {
        window.addEventListener('resize', () => setDivHeight(window.innerHeight - restHeight));
        if (typeof window !== 'undefined') setDivHeight(window.innerHeight - restHeight);
    }, []);

    useEffect(() => {
        setFilters({ ...parentListContext.filterValues }, displayedFilters);
    }, [parentListContext.filterValues]);

    useEffect(() => {
        setPage(parentListContext.page);
    }, [parentListContext.page]);

    useEffect(() => {
        setPerPage(parentListContext.perPage);
    }, [parentListContext.perPage]);

    return (
        <div>
            <div style={{ height: divHeight, overflow: 'scroll' }}>
                {ids.map((id) =>
                    id in data
                        ? React.cloneElement(children, {
                              ...children.props,
                              record: data[id],
                              id,
                          })
                        : null
                )}
            </div>

            {ids.length > 0 ? (
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-around'}
                    style={{
                        height: 50,
                    }}
                >
                    <Grid item>
                        <CustomIconButton onClick={() => setPage(page - 1)} disabled={page === 1}>
                            <NavigateBeforeIcon style={{ fontSize: 20 }} />
                        </CustomIconButton>
                    </Grid>

                    <Grid item>
                        <PageNumberTypography>{page}</PageNumberTypography>
                    </Grid>

                    <Grid item>
                        <CustomIconButton onClick={() => setPage(page + 1)} disabled={Math.ceil(total / 10) === page}>
                            <NavigateNextIcon style={{ fontSize: 20 }} />
                        </CustomIconButton>
                    </Grid>
                </Grid>
            ) : (
                <EmptyId />
            )}
        </div>
    );
};

interface StateListItemProps {
    status: string;
    filter: { [key: string]: any };
    dragHandleProps: any;
}

const StateListItemDragged = ({ status, filter, dragHandleProps }: StateListItemProps) => {
    const listProps = {
        syncWithLocation: false,
        resource: `${AVATARS}-${status}`,
        basePath: incidents,
        filter: {
            'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_EVENT,
            serialization_groups: [
                'read_meta',
                'read_mc',
                'read_simple_company',
                'read_avatar_current_referencing',
                'read_avatar_current_referencing_nodes',
                'read_meta_group_meta',
                // 'read_avatar_parent', // avatar parent
            ],
            ...filter,
        },
    };

    return (
        <RootDiv>
            <CustomTooltip title={status} placement={'top'}>
                <StateDiv
                    style={{
                        color:
                            status in IncidentNameAndColorStyleStatus
                                ? IncidentNameAndColorStyleStatus[status].color
                                : '',
                        backgroundColor:
                            status in IncidentNameAndColorStyleStatus
                                ? IncidentNameAndColorStyleStatus[status].backgroundColor
                                : '',
                    }}
                >
                    <TitleTypography align='center' variant='subtitle2' {...dragHandleProps}>
                        {status}
                    </TitleTypography>
                </StateDiv>
            </CustomTooltip>
            <ListContextProvider value={useListController(listProps)}>
                <ConsumerFilterListContext columnName={status}>
                    <ItemCard />
                </ConsumerFilterListContext>
            </ListContextProvider>
        </RootDiv>
    );
};

export default StateListItemDragged;
