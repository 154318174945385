import React, { ReactElement, useEffect, useState } from 'react';
import { Avatar, AvatarProperty } from '../../../utils/types';
import Form from './form';
import { GetListResult, Record, useDataProvider } from 'react-admin';
import { AVATAR_PROPERTY } from '../../../providers/resources';

interface CreateOrUpdateProps {
    avatar: Avatar;
    itemKey: string;
    itemValue: string | number;
    fieldType: string;
    definition: string | null;
    searchItem: boolean;
    label: string;
    icon: ReactElement;
    disabled: boolean;
    type?: 'radio-input';
    choices?: any[];
    source?: string;
}

const CreateOrUpdate = (props: CreateOrUpdateProps) => {
    const { searchItem, ...restProps } = props;
    const dataProvider = useDataProvider();
    const [item, setItem] = useState<AvatarProperty | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<any>();
    const [forcedRefresh, setForceRefresh] = useState<number>(0);

    const forceRefresh = () => setForceRefresh(forcedRefresh + 1);

    useEffect(() => {
        if (searchItem) {
            setLoading(true);
            dataProvider
                .getList(AVATAR_PROPERTY, {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {
                        ...(props.definition ? { definition: props.definition } : {}),
                        name: props.itemKey,
                        'avatar.id': props.avatar.id,
                    },
                })
                .then((response: GetListResult<Record>) => {
                    setItem(response.data[0] as AvatarProperty);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [forcedRefresh]);

    return (
        <Form
            {...restProps}
            forceRefresh={forceRefresh}
            item={item}
            itemValue={item ? item.value : null}
            icon={props.icon}
            disabled={props.disabled}
        />
    );
};

export default CreateOrUpdate;

export { Form as CreateOrUpdateForm };
