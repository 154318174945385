import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { TextInput, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { AVATARS } from '../../../../providers/resources';
import { Avatar } from '../../../../utils/types';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import {
    getCodeFromViolationMessage,
    getViolations,
    ValidationError,
    ViolationResolved,
} from '../../../../utils/validations/parseValidations';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';

interface EditFormProps {
    open: boolean;
    handleClose: () => void;
    handleCancel: () => void;
    selected: Avatar | undefined;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const NAME_FIELD = 'name';

const EditForm = ({ open, handleClose, selected, handleCancel }: EditFormProps) => {
    const [_open, setOpen] = useState<boolean>(open);
    const translate = useTranslate();
    const classes = useStyles();

    const refresh = useRefresh();
    const notify = useNotify();

    const _handleClose = () => {
        setOpen(false);
        handleClose();
    };

    const [edit, { loading }] = useMutation({
        type: 'update',
        resource: AVATARS,
        payload: { id: string, data: {} },
    });

    const handleSubmit = (formValues: Record<string, unknown>) => {
        edit(
            {
                payload: {
                    id: selected?.id,
                    data: {
                        ...formValues,
                    },
                },
            },
            {
                onSuccess: () => {
                    _handleClose();
                    refresh();
                },
                onFailure: (...args: unknown[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0] as ValidationError);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.errors.server', 'warning');
                    }
                },
            }
        );
    };
    return (
        <Dialog open={_open} onClose={_handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <Form
                    initialValues={{
                        ...selected,
                    }}
                    onSubmit={handleSubmit}
                    render={({ handleSubmit }) => {
                        return (
                            <Grid container>
                                <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                    <GlobalForm
                                        title={'app.incidentTypes.edit.title'}
                                        titleIcon={<BorderColorIcon color={'secondary'} />}
                                    >
                                        <Grid item xs>
                                            <TextInput
                                                autoFocus
                                                variant={'standard'}
                                                required
                                                key={NAME_FIELD}
                                                fullWidth
                                                type={'text'}
                                                label={translate('app.incidentTypes.edit.input.name')}
                                                source={NAME_FIELD}
                                            />
                                        </Grid>
                                        <ButtonsBlockForm
                                            disableValidButton={loading || !selected}
                                            onCancel={handleCancel}
                                            validButtonIsSubmitType={true}
                                        />
                                    </GlobalForm>
                                </form>
                            </Grid>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};
export default EditForm;
