import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslate } from 'react-admin';
import Breadcrumbs from '../../commons/Breadcrumbs/Breadcrumbs';
import BackDiv from '../../commons/BackDiv/BackDiv';
import IncidentsList from './list';

const Incidents = () => {
    const translate = useTranslate();
    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs
                    items={[translate('app.incidents.breadcrumbs.1')]}
                    lastItem={translate('app.incidents.breadcrumbs.0')}
                />
            </Grid>

            <Grid item>
                <BackDiv>{({ divHeight }: { divHeight: number }) => <IncidentsList divHeight={divHeight} />}</BackDiv>
            </Grid>
        </Grid>
    );
};

export default Incidents;
