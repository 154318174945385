import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import React, { useState } from 'react';
import {
    BooleanInput,
    CreateResult,
    Identifier,
    Record,
    ReduxState,
    useDataProvider,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { TICKETING_EVENT_USERS, TICKETING_PLACE_OR_EVENT_RESET_EVENT } from '../../../../providers/resources';
import { Avatar, TicketingEventUser, TicketingPlaceOrEventResetEvent } from '../../../../utils/types';
import GlobalForm from '../../../forms';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import { findNodeByNodeType, getPathProcess } from '../../../../utils/getListOfNodesFromRunnerTempates';
import { TICKETING_OPENED_NODE_TYPE } from '../../../../utils/CONST';
import extractIdFromURI from '../../../../utils/id';
import Typography from '@material-ui/core/Typography';
import UserConfigInput from './userConfigInput';
import { useSelector } from 'react-redux';

interface RestartEventFormProps {
    event: Avatar;
    open: boolean;
    handleClose?: () => void;
}

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
});

const REASSIGN_CURRENT_USER_PROVIDER_FIELD = 'reassignCurrentUserProvider';
const SEND_EMAIL_FIELD = 'sendMail';
const NEW_USER_PROVIDER_FIELD = 'newUserProvider';
const NAME_FIELD = 'name';

const RestartEventFormDialog = ({ event, open, handleClose }: RestartEventFormProps) => {
    const [_open, setOpen] = useState<boolean>(open);
    const [loading, setLoading] = useState<boolean>(false);
    const translate = useTranslate();
    const classes = useStyles();

    const currentTicketingEventUser: Record | undefined = useSelector((state: ReduxState) =>
        Object.values(state.admin.resources[TICKETING_EVENT_USERS].data).find(
            (eventUser: Record) => (eventUser as TicketingEventUser).event === event.id
        )
    );

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const _handleClose = () => {
        setOpen(false);
        handleClose && handleClose();
    };

    const handleSubmit = (formData: {
        [REASSIGN_CURRENT_USER_PROVIDER_FIELD]: boolean;
        [SEND_EMAIL_FIELD]: boolean;
        [NEW_USER_PROVIDER_FIELD]: Identifier;
    }) => {
        const firstNode = event.currentReferencing?.standardReferencing?.runner?.firstNode;
        const nodes = getPathProcess(firstNode);
        const nodeToChange = findNodeByNodeType(nodes, TICKETING_OPENED_NODE_TYPE);

        if (nodeToChange && 'id' in nodeToChange && event.currentReferencing?.standardReferencing?.id) {
            const reassignCurrentUserProvider = formData[REASSIGN_CURRENT_USER_PROVIDER_FIELD]
                ? formData[REASSIGN_CURRENT_USER_PROVIDER_FIELD]
                : false;
            const newUserProvider = !reassignCurrentUserProvider ? formData[NEW_USER_PROVIDER_FIELD] : null;

            const data: TicketingPlaceOrEventResetEvent = {
                extraData: [],
                event: event['@id'],
                // @ts-ignore
                nodeId: extractIdFromURI(nodeToChange.id),
                reassignCurrentUserProvider,
                sendMail: formData[SEND_EMAIL_FIELD] ? formData[SEND_EMAIL_FIELD] : false,
            };

            if (newUserProvider) {
                data.newUserProvider = newUserProvider;
            }
            notify('app.incidents.information.incidentRestarting', 'info');

            setLoading(true);
            dataProvider
                .create(TICKETING_PLACE_OR_EVENT_RESET_EVENT, {
                    data: JSON.stringify(data),
                })
                .then((result: CreateResult) => {
                    setLoading(false);
                    notify('app.incidents.information.incidentRestarted', 'success');
                    refresh();
                })
                .catch((error: any) => {
                    console.error(error);
                    setLoading(false);
                    notify('app.incidents.information.notRestartedCorrectly', 'error', { name: event.name });
                });
        }
    };

    return (
        <Dialog open={_open} onClose={_handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                <Form
                    onSubmit={handleSubmit}
                    validate={(values) => validate(values)(currentTicketingEventUser)}
                    render={({ handleSubmit, pristine, valid }) => {
                        return (
                            <Grid container>
                                <form onSubmit={handleSubmit} className={classes.fullWidth}>
                                    <GlobalForm
                                        titleTranslateOption={{ name: event.name }}
                                        title={'app.incidents.restart.title'}
                                        titleIcon={<BorderColorIcon color={'secondary'} />}
                                    >
                                        <Grid item xs>
                                            <BooleanInput
                                                label={
                                                    <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                                        {translate('app.incidents.restart.sendEmail')}
                                                    </Typography>
                                                }
                                                source={SEND_EMAIL_FIELD}
                                            />
                                            <UserConfigInput />
                                            <ButtonsBlockForm
                                                disableValidButton={loading || pristine || !valid}
                                                onCancel={_handleClose}
                                                validButtonIsSubmitType={true}
                                            />
                                        </Grid>
                                    </GlobalForm>
                                </form>
                            </Grid>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

const validate = (values: { [key: string]: any }) => (currentServiceProvider: Record | undefined) => {
    const errors: { [key: string]: string } = {};

    if (values[REASSIGN_CURRENT_USER_PROVIDER_FIELD] && !currentServiceProvider) {
        errors[REASSIGN_CURRENT_USER_PROVIDER_FIELD] = 'app.incidents.restart.noCurrentServiceProvider';
    }

    if (!values[REASSIGN_CURRENT_USER_PROVIDER_FIELD] && !values[NEW_USER_PROVIDER_FIELD] && currentServiceProvider) {
        errors[REASSIGN_CURRENT_USER_PROVIDER_FIELD] = 'app.incidents.restart.fieldRequired';
    }
    return errors;
};

export default RestartEventFormDialog;
