import { GetListResult } from 'react-admin';
import { put, takeEvery } from 'redux-saga/effects';
import { DataProvider } from '../../../providers/dataProvider';
import { AVATARS } from '../../../providers/resources';
import { Avatar } from '../../../utils/types';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import { GetTreeItemListAction, getTreeListActionCreator, setLoadedLevelItemCreator } from '../../actions/navigation';

const resolveTreeItemPLace = (hydraDataProvider: DataProvider) =>
    function* (action: GetTreeItemListAction) {
        const result: GetListResult = yield hydraDataProvider.getList(AVATARS, {
            pagination: {
                page: 1,
                perPage: 100,
            },
            sort: {
                field: 'lft',
                order: 'asc',
            },
            filter: {
                parent: action.payload.parentId,
                'metadataAvatarType.groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                serialization_groups: ['read_avatar_parent', 'read_avatar_root'],
            },
        });

        if (result && 'data' in result) {
            yield put(setLoadedLevelItemCreator.fn(action.payload.parentId, result.data as Avatar[]));
        } else {
            console.error('Not items found for parent ', action.payload.parentId);
            yield null;
        }
    };

export default (hydraDataProvider: DataProvider) => {
    return function* () {
        yield takeEvery(getTreeListActionCreator.action, resolveTreeItemPLace(hydraDataProvider));
    };
};
