import { styled } from '@material-ui/core/styles';

import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Record, useDataProvider } from 'react-admin';
import { MEDIA_LINKS } from '../../../providers/resources';
import { FieldHeaderListStyles, ImageStyles } from '../../../theme';
import { TAG_TICKETING_LOGO } from '../../../utils/CONST';
import IconField from '../icon';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../utils/id';

const useStyles = makeStyles({
    field: {
        ...FieldHeaderListStyles,
    },
    gridIconRightMargin: {
        marginRight: 5,
    },
    image: {
        ...ImageStyles,
    },
});

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
});

interface Props {
    record?: Record;
}

const LogoIcon = ({ record }: Props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [icon, setIcon] = useState<any>(null);
    const queryClient = useQueryClient();

    const findLogoInMedias = (medias: any[]) => {
        let logo = null;

        medias.map((media: any) => {
            if (
                typeof media === 'object' &&
                'customFields' in media &&
                'type' in media.customFields &&
                media.customFields.type === TAG_TICKETING_LOGO
            ) {
                logo = media;
            }
        });

        return logo;
    };

    useEffect(() => {
        if (record) {
            const medias = 'medias' in record ? record.medias : [];

            const logo = findLogoInMedias(medias);

            if (logo) {
                queryClient
                    .fetchQuery({
                        queryKey: [`logo_${extractIdFromURI(record['@id'])}_${logo['@id']}`],
                        queryFn: () => dataProvider.getOne(MEDIA_LINKS, { id: `${logo['@id']}` }),
                    })
                    .then(({ data }) => {
                        setIcon(data);
                    });
            }
        }
    }, [record]);

    return (
        <CustomDiv>
            {icon ? <IconField iconData={icon} /> : <img src={'/tmp-image.png'} className={classes.image} alt='' />}
        </CustomDiv>
    );
};

export default LogoIcon;
