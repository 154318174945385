import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAuthProvider, useLogout } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthProvider } from '../providers/authProvider';
import { tokenFoundCreator } from '../redux/actions/users';
import { CustomReducerState } from '../redux/reducers';
import { help_no_token_word, incidents, login, main, REDIRECT_TO_QUERY_PARAMS, statisticsNameRoute } from './paths';
// import { isUserAServiceProvider } from '../utils/user/tags';

const RefreshTokenPage = () => {
    const [tokenResolved, setTokenResolved] = useState<boolean>(false);
    const user = useSelector((state: CustomReducerState) => state.ticketing.user.userConnected);
    const [redirectTo, setRedirectTo] = useState<string | null>(null);

    // @ts-ignore
    const authProvider: AuthProvider = useAuthProvider();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const logout = useLogout();

    // @ts-ignore
    let { refreshToken } = useParams();

    useEffect(() => {
        if (user && redirectTo) {
            history.push(`/p/${redirectTo}`);
        }
    }, [user, redirectTo]);

    const resolveToken = async () => {
        if (!refreshToken || refreshToken === help_no_token_word) {
            refreshToken = localStorage.getItem(
                // @ts-ignore
                process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE
            );
        }

        if (refreshToken && refreshToken !== help_no_token_word) {
            const tokens = await authProvider.refreshToken({
                refreshToken,
            });
            if (tokens && 'token' in tokens) {
                setTokenResolved(true);
                const searchParams = new URLSearchParams(location.search ? location.search.substring(1) : '');
                // const redirectTo = searchParams.has(REDIRECT_TO_QUERY_PARAMS)
                //     ? searchParams.get(REDIRECT_TO_QUERY_PARAMS)
                //     : isUserAServiceProvider(user?.tags)
                //     ? incidents
                //     : statisticsNameRoute;

                const redirectTo = searchParams.has(REDIRECT_TO_QUERY_PARAMS)
                    ? searchParams.get(REDIRECT_TO_QUERY_PARAMS)
                    : statisticsNameRoute;

                setRedirectTo(redirectTo);

                dispatch(tokenFoundCreator.fn(tokens.token, tokens.refreshToken));
            }
        } else {
            // history.push('/unauthorized');
            logout();
        }
    };

    useEffect(() => {
        resolveToken().finally();
    }, []);

    return <div style={{ textAlign: 'center' }}>{!tokenResolved && <CircularProgress />}</div>;
};

export default RefreshTokenPage;
