import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useDelete, useNotify, useTranslate, useUpdate } from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DialogTemplate from '../../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import ImageCropField from '../../../../../commons/ImageCrop/ImageCropField';
import { IMAGES, MEDIAS, METADATA_AVATAR_TYPES } from '../../../../../providers/resources';
import { CustomReducerState } from '../../../../../redux/reducers';
import { TAG_INSTRUCTION_HELP } from '../../../../../utils/CONST';
import extractIdFromURI, { getURIFROMAnotherURI, getUriFromItems } from '../../../../../utils/id';
import {
    getFileSystemMappersByResource,
    METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
    saveMedia,
} from '../../../../../utils/medias';
import { IMAGE_FIELD } from '../../../../../utils/metadataAvatarType';
import { FileSystemMapper, Media, MetadataAvatarType } from '../../../../../utils/types';
import ShowImage from './show-image';

const AddDiv = styled('div')({
    height: 90,
    border: '2px solid lightgray',
    borderRadius: 5,
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});

const TopTypography = styled(Typography)({
    fontWeight: 700,
    fontSize: '0.875rem',
});

const BottomTypography = styled(Typography)({
    fontSize: 16,
});

const HelpTextTypography = styled(Typography)({
    fontSize: 16,
    color: 'grey',
});

interface Props {
    subCategory: MetadataAvatarType;
    insertImage: any;
}

const Gallery = ({ subCategory, insertImage }: Props) => {
    const [addImageDialogState, setAddImageDialogState] = useState<boolean>(false);
    const tokenWithRoles: string | undefined = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const [update] = useUpdate();
    const [deleteOne] = useDelete();
    const notify = useNotify();
    const translate = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const dataProvider = useDataProvider();
    const [imagesFromFileSystemMapper, setImagesFromFileSystemMapper] = useState<FileSystemMapper[]>([]);

    const getMetadataAvatarType = (metadataAvatarTypeUri: string, successCallback: any, failureCallback: any) => {
        dataProvider.getOne(
            METADATA_AVATAR_TYPES,
            {
                id: `${metadataAvatarTypeUri}?${queryString.stringify(
                    {
                        serialization_groups: ['read_meta_files', 'link'],
                    },
                    { arrayFormat: 'bracket' }
                )}`,
            },
            {
                onSuccess: () => {
                    successCallback();
                },
                onFailure: (error: any) => {
                    failureCallback(error);
                },
            }
        );
    };

    const handleSubmitImage = async (formValues: {
        [IMAGE_FIELD]: { fileData: string; fileName: string; cropping?: boolean };
    }) => {
        const fileData = formValues[IMAGE_FIELD].fileData;
        const fileName = formValues[IMAGE_FIELD].fileName;

        if (!tokenWithRoles || !subCategory) return;

        setLoading(true);

        const companyId = typeof subCategory.company === 'object' ? subCategory.company['@id'] : subCategory.company;
        const resourceId: any = extractIdFromURI(subCategory['@id'] as string);

        const imageAdded: Media | undefined = await saveMedia(
            fileData,
            fileName,
            companyId as string,
            resourceId,
            tokenWithRoles,
            [TAG_INSTRUCTION_HELP]
        );

        if (imageAdded && imageAdded.id) {
            const imageUri = `${getURIFROMAnotherURI(subCategory['@id'] as string)}/${IMAGES}/${imageAdded.id}`;

            update(
                METADATA_AVATAR_TYPES,
                subCategory['@id'],
                {
                    medias: [...getUriFromItems(subCategory.medias), imageUri],
                },
                subCategory,
                {
                    onSuccess: () => {
                        getMetadataAvatarType(
                            subCategory['@id'],
                            () => {
                                setAddImageDialogState(false);
                                setLoading(false);
                            },
                            () => {
                                notify('app.incidentTypes.error', 'error');
                                setLoading(false);
                            }
                        );
                    },
                    onFailure: () => {
                        notify('app.incidentTypes.edit.error', 'error');
                        setLoading(false);
                    },
                }
            );
        } else {
            notify('app.media.errorCreatingImage', 'error');
        }
    };

    const handleRemoveImage = (mediaUriToDelete: string, callback: any) => {
        const medias = 'medias' in subCategory ? [...getUriFromItems(subCategory.medias)] : [];
        const index = medias.findIndex((item: string) => item === mediaUriToDelete);
        if (index !== -1) medias.splice(index, 1);

        update(
            METADATA_AVATAR_TYPES,
            subCategory['@id'],
            {
                medias,
            },
            subCategory,
            {
                onSuccess: () => {
                    deleteMediaRequest(mediaUriToDelete);

                    getMetadataAvatarType(
                        subCategory['@id'],
                        () => {
                            setLoading(false);
                        },
                        () => {
                            notify('app.incidentTypes.error', 'error');
                            setLoading(false);
                        }
                    );
                },
                onFailure: () => {
                    notify('app.incidentTypes.edit.error', 'error');
                    setLoading(false);
                    callback();
                },
            }
        );
    };

    const deleteMediaRequest = (mediaUri: string) => {
        deleteOne(MEDIAS, mediaUri, undefined, {
            onFailure: () => {
                notify('app.media.errorDeletingImage', 'error');
            },
        });
    };

    const getImagesFromFileSystemMapper = async () => {
        const imagesFromFileSystemMapper = await getFileSystemMappersByResource(
            METADATA_AVATAR_TYPE_RESOURCE_SUPPORTED_MEDIA,
            extractIdFromURI(subCategory['@id']) as string,
            [TAG_INSTRUCTION_HELP],
            tokenWithRoles as string
        );

        setImagesFromFileSystemMapper(imagesFromFileSystemMapper ? imagesFromFileSystemMapper : []);
    };

    useEffect(() => {
        getImagesFromFileSystemMapper();
    }, [subCategory]);

    const findMedia = (item: FileSystemMapper): Media | null => {
        const mediaId = item.metadata['metadata.extra']['coreapi.mediaid'];
        const medias = Array.isArray(subCategory.medias) ? subCategory.medias : [];
        const index = medias.findIndex((item) => extractIdFromURI(item['@id']) === mediaId);
        return index !== -1 ? medias[index] : null;
    };

    return (
        <Grid container direction={'column'} spacing={1}>
            <Grid item>
                <Grid container direction={'column'}>
                    <Grid item>
                        <TopTypography>{translate('app.incidentTypes.instructions.imageLibrary')}</TopTypography>
                    </Grid>
                    <Grid item>
                        <BottomTypography>{translate('app.incidentTypes.instructions.clickImage')}</BottomTypography>
                    </Grid>
                    <Grid item>
                        <HelpTextTypography>{translate('app.incidentTypes.instructions.helpText')}</HelpTextTypography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Tooltip title={translate('app.incidentTypes.instructions.tooltipAddImage')} placement={'top'}>
                            <AddDiv onClick={() => setAddImageDialogState(true)}>
                                <AddIcon />
                            </AddDiv>
                        </Tooltip>
                    </Grid>

                    {imagesFromFileSystemMapper.map((item) => {
                        const media: Media | null = findMedia(item);
                        return media ? (
                            <Grid item xs={4} key={item['id']}>
                                <ShowImage
                                    fileSystemMapperId={extractIdFromURI(item['@id'])}
                                    mediaId={media.id as string}
                                    token={media.contentUrlTokenAccess}
                                    onRemove={handleRemoveImage}
                                    insertImage={insertImage}
                                />
                            </Grid>
                        ) : null;
                    })}
                </Grid>
            </Grid>

            {addImageDialogState && (
                <DialogTemplate
                    title={translate('app.incidentTypes.instructions.addImageDialogTitle')}
                    open={addImageDialogState}
                    onClose={() => setAddImageDialogState(false)}
                    icon={<AddIcon />}
                    maxWidth={'xs'}
                >
                    <Form
                        onSubmit={handleSubmitImage}
                        render={({ handleSubmit, valid, pristine, values }) => {
                            return (
                                <Grid container>
                                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                        <Grid item>
                                            <ImageCropField
                                                name={IMAGE_FIELD}
                                                accept='image/*'
                                                inputLabel={translate(
                                                    'app.incidentTypes.instructions.addImageInputLabel'
                                                )}
                                            />
                                        </Grid>

                                        <DialogTemplateButtons
                                            outside
                                            withCancel
                                            onCancelClick={() => setAddImageDialogState(false)}
                                            withCreate
                                            isSubmitting={loading}
                                            isValidForm={valid && !pristine && !values[IMAGE_FIELD]?.cropping}
                                            createButtonLabel={translate('app.valid')}
                                        />
                                    </form>
                                </Grid>
                            );
                        }}
                    />
                </DialogTemplate>
            )}
        </Grid>
    );
};

export default Gallery;
