import { CircularProgress, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { APP_COLOR_DISABLED, ButtonSubmitStyled } from '../../theme';

const CustomDiv = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 7,
});

const useStyles = makeStyles(() => ({
    overline: {
        fontWeight: 700,
    },
    disabled: {
        color: APP_COLOR_DISABLED,
    },
}));

interface Props {
    validButtonIsSubmitType: boolean;
    onCancel?: () => void;
    onValid?: () => void;
    labelCancelButton?: string;
    labelValidButton?: string;
    disableValidButton?: boolean;
    disableThirdButton?: boolean;
    showValidButton?: boolean;
    showCancelButton?: boolean;
    thirdButtonLabel?: string;
    thirdButtonAction?: () => void;
    loading?: boolean;
}

const ButtonsBlockForm = ({
    validButtonIsSubmitType,
    onCancel,
    onValid,
    labelCancelButton = 'app.cancel',
    labelValidButton = 'app.valid',
    disableValidButton = false,
    showValidButton = true,
    showCancelButton = true,
    thirdButtonAction,
    thirdButtonLabel,
    disableThirdButton,
    loading = false,
}: Props) => {
    const translate = useTranslate();
    const classes = useStyles();

    // const renderButton = (onClick, label, props, index) => (
    //     <div className={classes.wrapper} {...(index !== undefined ? { key: index } : {})}>
    //         <Button onClick={onClick} className={classes.button} {...props}>
    //             {label}
    //         </Button>
    //         {props.hasOwnProperty('disabled') &&
    //             props.disabled &&
    //             props.hasOwnProperty('withLoading') &&
    //             props.withLoading && (
    //                 <CircularProgress
    //                     size={24}
    //                     className={classes.buttonProgress}
    //                     data-test-sc='dialogTemplateButtonsLoading'
    //                 />
    //             )}
    //     </div>
    // );

    return (
        <CustomDiv>
            {thirdButtonAction && thirdButtonLabel && (
                <Button
                    disabled={disableThirdButton}
                    color={'primary'}
                    onClick={thirdButtonAction}
                    className={classes.overline}
                >
                    {translate(thirdButtonLabel)}
                </Button>
            )}
            {showCancelButton && (
                <Button color={'primary'} onClick={onCancel} className={classes.overline}>
                    {translate(labelCancelButton)}
                </Button>
            )}
            {showValidButton && (
                <Fragment>
                    {validButtonIsSubmitType && (
                        <ButtonSubmitStyled type={'submit'} disabled={disableValidButton}>
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                translate(labelValidButton)
                            )}
                        </ButtonSubmitStyled>
                    )}
                    {!validButtonIsSubmitType && (
                        <ButtonSubmitStyled disabled={disableValidButton} onClick={onValid ? onValid : () => null}>
                            {translate(labelValidButton)}
                        </ButtonSubmitStyled>
                    )}
                </Fragment>
            )}
        </CustomDiv>
    );
};

export default ButtonsBlockForm;
