import React, { useEffect, useState } from 'react';
import { Avatar, AvatarProperty } from '../../../utils/types';
import Grid from '@material-ui/core/Grid';
import { LinearProgress, Tooltip, Typography } from '@material-ui/core';
import DialogTemplateButtons from '../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import DialogTemplate from '../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { GetListResult, Record, useDataProvider, useNotify, useTranslate } from 'react-admin';
import makeStyles from '@material-ui/styles/makeStyles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { APP_COLOR_PRIMARY } from '../../../theme';
import Divider from '@material-ui/core/Divider';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { AVATAR_PROPERTY } from '../../../providers/resources';
import {
    TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY,
    TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY,
    TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY,
    TICKETING_REPORTER_EVALUATION_AVATAR_PROPERTY_DEFINITION,
    TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY,
} from '../../../utils/CONST';

interface OverrideStylesProps {
    seeMoreDiv: any;
}

interface Props {
    event: Avatar;
    enableDialog?: boolean;
    showSeeMoreText?: boolean;
    overrideStyles?: OverrideStylesProps;
}

const useStyles = makeStyles({
    imageField: {
        height: 60,
        width: 60,
        margin: '0 0 -5px 0',
    },
    displayGridCenter: {
        display: 'grid',
        justifySelf: 'center',
    },
    fontWeightPrimaryBold: {
        fontWeight: 'bold',
        color: APP_COLOR_PRIMARY,
        fontSize: 'smaller',
    },
    fontWeightBold: {
        fontWeight: 'bold',
    },
    disabled: {
        color: 'grey',
    },
    labelMarginLeftWithBadge: {
        // fontSize: 14,
        marginRight: 10,
        marginLeft: 24,
        fontWeight: 'bold',
        color: APP_COLOR_PRIMARY,
        fontSize: 'smaller',
    },
});

const HIGHER_EVALUATION = 5;
const TOTAL_OF_EVALUATIONS = 3;

const ResolutionIncidentOpinionField = (props: Props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const [averageEvaluation, setAverageEvaluation] = useState<number>(0);
    const [reporterEvaluationAvatarProperties, setReporterEvaluationAvatarProperties] = useState<AvatarProperty[]>([]);
    const [loadingReporterEvaluationAvatarProperties, setLoadingReporterEvaluationAvatarProperties] = useState<boolean>(
        false
    );
    const [interventionEvaluationData, setInterventionEvaluationData] = useState<{
        [key: string]: AvatarProperty | any;
    }>({
        [TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]: {},
        [TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]: {},
        [TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]: {},
        [TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]: {},
    });

    const getReporterEvaluationAvatarProperty = () => {
        setLoadingReporterEvaluationAvatarProperties(true);
        dataProvider
            .getList(AVATAR_PROPERTY, {
                sort: { field: 'id', order: 'asc' },
                pagination: { page: 1, perPage: 20 },
                filter: {
                    'avatar.id': props.event.id,
                    definition: TICKETING_REPORTER_EVALUATION_AVATAR_PROPERTY_DEFINITION,
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && result.data) {
                    setReporterEvaluationAvatarProperties(result.data as AvatarProperty[]);
                    setLoadingReporterEvaluationAvatarProperties(false);
                }
            })
            .catch((e: any) => {
                setLoadingReporterEvaluationAvatarProperties(false);
                console.error(
                    'error getting avatar-properties with definition ',
                    TICKETING_REPORTER_EVALUATION_AVATAR_PROPERTY_DEFINITION,
                    e
                );
                notify('app.avatarProperty.get.error', 'warning', {
                    property_name: `${translate('app.incidents.resolutionOpinion.evaluation')} ${props.event.name}`,
                });
            });
    };

    const resolverReporterEvaluationData = () => {
        const newValues: { [key: string]: AvatarProperty | any } = {};
        const commentProperty = reporterEvaluationAvatarProperties.find(
            (property) => property.name === TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
        );

        if (commentProperty) {
            newValues[TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY] = commentProperty;
        }
        const qualityProperty = reporterEvaluationAvatarProperties.find(
            (property) => property.name === TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
        );

        if (qualityProperty) {
            newValues[TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY] = qualityProperty;
        }
        const speedProperty = reporterEvaluationAvatarProperties.find(
            (property) => property.name === TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
        );

        if (speedProperty) {
            newValues[TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY] = speedProperty;
        }
        const experienceProperty = reporterEvaluationAvatarProperties.find(
            (property) => property.name === TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
        );

        if (experienceProperty) {
            newValues[TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY] = experienceProperty;
        }

        let averageEvaluationFromPropertiesValues = 0;
        Object.keys(newValues).forEach((key) => {
            if (newValues[key]?.value && key !== TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY) {
                averageEvaluationFromPropertiesValues =
                    averageEvaluationFromPropertiesValues + parseInt(newValues[key].value);
            }
        });

        const float2int = (value: number) => {
            return value | 0;
        };

        averageEvaluationFromPropertiesValues = averageEvaluationFromPropertiesValues / TOTAL_OF_EVALUATIONS;

        if (averageEvaluationFromPropertiesValues)
            setAverageEvaluation(float2int(averageEvaluationFromPropertiesValues));

        setInterventionEvaluationData({
            ...newValues,
        });
    };

    const renderBadge = () =>
        reporterEvaluationAvatarProperties.length > 0 ? (
            <div style={props.overrideStyles ? { ...props.overrideStyles?.seeMoreDiv } : { display: 'flex' }}>
                <Tooltip title={translate('app.incidents.resolutionOpinion.seeMore')}>
                    <Typography
                        variant={'subtitle2'}
                        className={classes.fontWeightPrimaryBold}
                        noWrap
                        onClick={handleOnClickLink}
                    >
                        {translate('app.incidents.resolutionOpinion.averageEvaluation', {
                            average: `${averageEvaluation}/${HIGHER_EVALUATION}`,
                        })}
                    </Typography>
                </Tooltip>
            </div>
        ) : (
            <span />
        );

    useEffect(() => {
        getReporterEvaluationAvatarProperty();
    }, []);

    useEffect(() => {
        if (reporterEvaluationAvatarProperties && reporterEvaluationAvatarProperties.length > 0) {
            resolverReporterEvaluationData();
        }
    }, [reporterEvaluationAvatarProperties]);

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOnClickLink = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setOpenDialog(true);
    };

    const getIconsEvaluation = (evaluationPoints: number): JSX.Element[] => {
        let evaluationsIcons: JSX.Element[] = [];
        if (evaluationPoints < HIGHER_EVALUATION) {
            for (let i = 0; i < evaluationPoints; i++) {
                evaluationsIcons = [...evaluationsIcons, <StarIcon key={`star_icon_${i}`} />];
            }
            for (let i = 0; i < HIGHER_EVALUATION - evaluationPoints; i++) {
                evaluationsIcons = [...evaluationsIcons, <StarBorderIcon key={`star_border_icon_${i}`} />];
            }
        }
        if (evaluationPoints === HIGHER_EVALUATION) {
            for (let i = 0; i < HIGHER_EVALUATION; i++) {
                evaluationsIcons = [...evaluationsIcons, <StarIcon key={`star_icon_high_${i}`} />];
            }
        }
        return evaluationsIcons;
    };

    return (
        <>
            {renderBadge()}
            {props.enableDialog ? (
                <DialogTemplate
                    title={translate('app.incidents.resolutionOpinion.title')}
                    open={openDialog}
                    onClose={onCloseDialog}
                    icon={<RateReviewIcon />}
                    maxWidth={'sm'}
                >
                    {interventionEvaluationData[TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]
                        ?.value && (
                        <Grid container>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                                <Typography variant={'subtitle1'}>
                                    {translate('app.incidents.resolutionOpinion.questions.qualityIntervention')}
                                </Typography>
                            </Grid>
                            {loadingReporterEvaluationAvatarProperties ? (
                                <LinearProgress />
                            ) : (
                                getIconsEvaluation(
                                    parseInt(
                                        interventionEvaluationData[
                                            TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
                                        ]?.value
                                    )
                                ).map((iconEvaluation, index) => (
                                    <Grid item key={`icon_evaluation_${index}`}>
                                        {iconEvaluation}
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}

                    {interventionEvaluationData[TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]?.value && (
                        <Grid container>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                                <Typography variant={'subtitle1'}>
                                    {translate('app.incidents.resolutionOpinion.questions.speedIntervention')}
                                </Typography>
                            </Grid>
                            {loadingReporterEvaluationAvatarProperties ? (
                                <LinearProgress />
                            ) : (
                                getIconsEvaluation(
                                    parseInt(
                                        interventionEvaluationData[
                                            TICKETING_SPEED_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
                                        ]?.value
                                    )
                                ).map((iconEvaluation, index) => (
                                    <Grid item key={`icon_evaluation_${index}`}>
                                        {iconEvaluation}
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}
                    {interventionEvaluationData[TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]
                        ?.value && (
                        <Grid container>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                                <Typography variant={'subtitle1'}>
                                    {translate(
                                        'app.incidents.resolutionOpinion.questions.reportingExperienceIntervention'
                                    )}
                                </Typography>
                            </Grid>
                            {loadingReporterEvaluationAvatarProperties ? (
                                <LinearProgress />
                            ) : (
                                getIconsEvaluation(
                                    parseInt(
                                        interventionEvaluationData[
                                            TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
                                        ]?.value
                                    )
                                ).map((iconEvaluation, index) => (
                                    <Grid item key={`icon_evaluation_${index}`}>
                                        {iconEvaluation}
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}

                    <Divider variant={'fullWidth'} />

                    {interventionEvaluationData[TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY]
                        ?.value && (
                        <Grid container style={{ marginTop: 8 }}>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                                <Typography variant={'subtitle1'} className={classes.fontWeightBold}>
                                    {translate('app.incidents.resolutionOpinion.comment')}
                                </Typography>
                            </Grid>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
                                {loadingReporterEvaluationAvatarProperties ? (
                                    <LinearProgress />
                                ) : (
                                    <Typography variant={'subtitle1'}>
                                        {
                                            interventionEvaluationData[
                                                TICKETING_COMMENT_OF_INTERVENTION_EVALUATION_AVATAR_PROPERTY
                                            ]?.value
                                        }
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <DialogTemplateButtons outside withCancel onCancelClick={onCloseDialog} />
                </DialogTemplate>
            ) : (
                <span />
            )}
        </>
    );
};
export default ResolutionIncidentOpinionField;
