import React from 'react';
import UserInfoInput from './userInfoInput';
import ImageCropperField from '../field/ImageCropperField';
import { SelectInput, usePermissions, useTranslate, SelectArrayInput, ReferenceArrayInput } from 'react-admin';
import { hasPermissions } from '../../../utils/permissions';
import { ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD } from '../../../utils/ROLES';
import Grid from '@material-ui/core/Grid';
import { PROFILES } from '../../../providers/resources';
import ReferenceInput from '../../../commons/ra/input/ReferenceInput';
import { ACCESS_TO_SERVICE_FIELD } from '../actions/CONST';
import { TICKETING_MEMORANDUM_TRUSTEE_INTERNAL_NAME_PROFILE } from '../../../utils/CONST';

interface Props {
    handleImageDataUser: (data: { name: string; data: string } | null) => void;
    initialImageData?: { name: string; data: string };
    loadingImage?: boolean;
}

const UserManagerInput = (props: Props) => {
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const translate = useTranslate();

    return (
        <>
            <UserInfoInput isUserAPerson={true} />

            {/*<Grid item xs={12} lg={12} sm={12} md={12}>*/}
            {/*    <ReferenceInput*/}
            {/*        fullWidth*/}
            {/*        reference={PROFILES}*/}
            {/*        source={ACCESS_TO_SERVICE_FIELD}*/}
            {/*        label={translate('app.users.accessToService')}*/}
            {/*        filter={{*/}
            {/*            internalName: TICKETING_MEMORANDUM_TRUSTEE_INTERNAL_NAME_PROFILE,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <SelectInput variant={'standard'} resettable />*/}
            {/*    </ReferenceInput>*/}
            {/*</Grid>*/}

            {hasPermissions([ROLE_FM_CLOUD_READ, ROLE_FM_CLOUD_READ_DOWNLOAD], loadedPermissions, permissions) && (
                <ImageCropperField
                    loadingImage={props.loadingImage}
                    handleImageDataUser={props.handleImageDataUser}
                    initialImageData={props.initialImageData}
                />
            )}
        </>
    );
};

export default UserManagerInput;
