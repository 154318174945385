import React from 'react';
import { useField, useForm } from 'react-final-form';
import Switch from '@material-ui/core/Switch';

interface CustomSwitchInputProps {
    source: string;
    disabled?: boolean;
}

const label = { inputProps: { 'aria-label': 'primary checkbox' } };

const CustomSwitchInput = (props: CustomSwitchInputProps) => {
    const field = useField(props.source);
    const form = useForm();

    const onClick = () => {
        form.change(props.source, !field.input.value);
    };

    return (
        <Switch
            {...label}
            onClick={onClick}
            checked={field.input.value}
            color={'primary'}
            disabled={props.disabled ? props.disabled : false}
        />
    );
};

export default CustomSwitchInput;
