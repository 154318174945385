import { Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@material-ui/core/styles';
import React, { Fragment, useEffect, useState } from 'react';
import {
    getResponseForwarderToMetabaseApi,
    getViewsDynamic,
    ResponseForwarderToMetabaseType,
} from '../../utils/ticketingBridgeMetabase/viewsDynamic';
import { TicketingBridgeMetabaseViewDynamic } from '../../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';

const CustomDiv = styled('div')({
    position: 'relative',
    marginTop: 2,
});

const CustomLinearProgress = styled(LinearProgress)({
    position: 'absolute',
    top: 0,
    width: '100%',
});

interface Props {
    requestBody: {
        id: number;
        dashboard_type: string;
        creation_date_before?: string | null;
        creation_date_after?: string | null;
        opened_incidents?: boolean | null;
        place_id?: string | null;
        metadata_avatar_type_id?: string | null;
        event_id?: string | null;
        event_running?: string;
    };
    children: any;
    version: number;
    passLoadingToChildren?: boolean;
}

const GetMetabaseUrlOfTheData = ({ requestBody, children, version, passLoadingToChildren }: Props) => {
    const [loading, setLoading] = useState(true);
    const [dataResponse, setDataResponse] = useState<ResponseForwarderToMetabaseType | null>(null);
    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);

    useEffect(() => {
        setLoading(true);
        getViewsDynamic(tokenWithRoles, requestBody.id, {
            ...requestBody,
        })
            .then((response: TicketingBridgeMetabaseViewDynamic | null) => {
                response &&
                    getResponseForwarderToMetabaseApi(response.get_response_forwarder_to_metabase_api)
                        .then((response: ResponseForwarderToMetabaseType | null) => {
                            try {
                                if (response) {
                                    setDataResponse(response);
                                }
                                setLoading(false);
                            } catch (e) {
                                setDataResponse(null);
                                setLoading(false);
                                console.error('ERROR getting get_response_forwarder_to_metabase_api', e);
                            }
                        })
                        .catch((error) => {
                            console.error('ERROR getting get_response_forwarder_to_metabase_api', error);
                            setDataResponse(null);
                            setLoading(false);
                        });
            })
            .catch((error: any) => {
                console.error('ERROR getting ticketingBridgeMetabase', error);
                setDataResponse(null);
                setLoading(false);
            });
    }, [version]);

    return (
        <CustomDiv>
            {!passLoadingToChildren && loading && <CustomLinearProgress />}
            {!passLoadingToChildren && dataResponse !== null && children({ data: dataResponse.data })}
            {passLoadingToChildren && children({ data: dataResponse ? dataResponse.data : {}, loading })}
        </CustomDiv>
    );
};

export default GetMetabaseUrlOfTheData;
