import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/strings';

const ContainerDiv = styled('div')({});

const CustomDiv = styled('div')({
    margin: 2,
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    cursor: 'default',
    height: 32,
    display: 'inline-flex',
    outline: 0,
    padding: 0,
    fontSize: '0.8125rem',
    boxSizing: 'border-box',
    transition:
        ' background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    whiteSpace: 'nowrap',
    borderRadius: 16,
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
});

const CustomSpan = styled('span')({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 6,
    paddingRight: 6,
    textOverflow: 'ellipsis',
});

const CustomIconButtonLeft = styled(IconButton)({
    padding: 0,
    marginRight: 5,
});

const CustomIconButtonRight = styled(IconButton)({
    padding: 0,
    marginLeft: 5,
});

interface Props {
    dndList: any[];
    setDndList: any;
}

const DndContainer = ({ dndList, setDndList }: Props) => {
    const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
        const newArray: any = [...arr];
        const oldItem = newArray[oldIndex];
        const newItem = newArray[newIndex];
        newArray[oldIndex] = newItem;
        newArray[newIndex] = oldItem;
        return newArray;
    };

    return (
        <ContainerDiv>
            {dndList.map((item: any, index: number) => (
                <CustomDiv key={item.id}>
                    <CustomIconButtonRight
                        onClick={() => {
                            setDndList(arrayMove(dndList, index, index - 1));
                        }}
                        disabled={index === 0}
                    >
                        <ArrowLeftIcon />
                    </CustomIconButtonRight>
                    <CustomSpan>{capitalizeFirstLetter(item.name)}</CustomSpan>
                    <CustomIconButtonLeft
                        onClick={() => {
                            setDndList(arrayMove(dndList, index, index + 1));
                        }}
                        disabled={dndList.length === index + 1}
                    >
                        <ArrowRightIcon />
                    </CustomIconButtonLeft>
                </CustomDiv>
            ))}
        </ContainerDiv>
    );
};

export default DndContainer;
