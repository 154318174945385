import { Identifier, Record } from 'react-admin';
import { Avatar, IncidentListViewMode } from '../../../utils/types';

export interface SelectIncidentAction {
    type: typeof selectIncident.action;
    payload: {
        record: Record | Avatar | null;
    };
}

export const selectIncident = {
    action: '360SC_TICKETING/SELECT_INCIDENT',
    fn: (record: Record | null): SelectIncidentAction => ({
        type: selectIncident.action,
        payload: {
            record: record,
        },
    }),
};

export interface RegisterPlaceIncidentFilterAction {
    type: typeof registerPlaceIncidentFilter.action;
    payload: {
        place: Record | Avatar | null;
    };
}

export const registerPlaceIncidentFilter = {
    action: '360SC_TICKETING/REGISTER_PLACE_INCIDENT_FILTER',
    fn: (record: Record | null): RegisterPlaceIncidentFilterAction => ({
        type: registerPlaceIncidentFilter.action,
        payload: {
            place: record,
        },
    }),
};

export interface ChangeViewListAction {
    type: typeof changeViewList.action;
    payload: {
        viewMode: IncidentListViewMode;
    };
}

export const changeViewList = {
    action: '360SC_TICKETING/CHANGE_INCIDENT_LIST_VIEW',
    fn: (viewMode: IncidentListViewMode): ChangeViewListAction => ({
        type: changeViewList.action,
        payload: {
            viewMode,
        },
    }),
};

export interface ChangeKanbanListOrderAction {
    type: typeof changeKanbanListOrder.action;
    payload: {
        itemsOrdered: string[];
    };
}

export const changeKanbanListOrder = {
    action: '360SC_TICKETING/CHANGE_INCIDENT_KANBAN_LIST_ORDER_VIEW',
    fn: (itemsOrdered: string[]): ChangeKanbanListOrderAction => ({
        type: changeKanbanListOrder.action,
        payload: {
            itemsOrdered,
        },
    }),
};

export interface RegisterMetabaseDataAction {
    type: typeof registerMetabaseData.action;
    payload: {
        question: number;
        data: any;
        eventId: Identifier;
    };
}

export const registerMetabaseData = {
    action: '360SC_TICKETING/REGISTER_METABASE_DATA',
    fn: (eventId: Identifier, question: number, data: any): RegisterMetabaseDataAction => ({
        type: registerMetabaseData.action,
        payload: {
            question,
            data,
            eventId,
        },
    }),
};

export interface RegisterCustomizeColumnsInListView {
    type: typeof registerCustomizeColumnsIncidentsInListView.action;
    payload: {
        cols: { [key: string]: boolean };
    };
}

export const registerCustomizeColumnsIncidentsInListView = {
    action: '360SC_TICKETING/REGISTER_CUSTOMIZE_INCIDENTS_COLUMNS_LIST_VIEW',
    fn: (cols: { [key: string]: boolean }): RegisterCustomizeColumnsInListView => ({
        type: registerCustomizeColumnsIncidentsInListView.action,
        payload: {
            cols,
        },
    }),
};

export interface RegisterCustomizeColumnsInKanbanListView {
    type: typeof registerCustomizeColumnsIncidentsInKanbanListView.action;
    payload: {
        cols: { [key: string]: boolean };
    };
}

export const registerCustomizeColumnsIncidentsInKanbanListView = {
    action: '360SC_TICKETING/REGISTER_CUSTOMIZE_INCIDENTS_COLUMNS_KANBAN_LIST_VIEW',
    fn: (cols: { [key: string]: boolean }): RegisterCustomizeColumnsInKanbanListView => ({
        type: registerCustomizeColumnsIncidentsInKanbanListView.action,
        payload: {
            cols,
        },
    }),
};
