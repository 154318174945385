import React, { useState } from 'react';
import { Avatar } from '../../../../utils/types';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { usePermissions, useRefresh, useTranslate } from 'react-admin';
import { CustomIconButton } from './commons';
import { hasPermissions } from '../../../../utils/permissions';
import { ROLE_UPDATE_AVATAR } from '../../../../utils/ROLES';
import ChangeNameDialogForm from '../changeName';

interface Props {
    record: Avatar;
}

const EditButton = (props: Props) => {
    const { record } = props;
    const translate = useTranslate();
    const refresh = useRefresh();

    const [showChangeNameDialog, setShowChangeNameDialog] = useState<boolean>(false);
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const onCloseUpdateName = () => {
        setShowChangeNameDialog(false);
        refresh();
    };

    const isPossibleToEdit = record?.mcs && record.mcs.length > 0;

    return (
        <>
            <Tooltip title={isPossibleToEdit ? translate('app.incidents.edit.label') : ''}>
                <CustomIconButton
                    onClick={
                        isPossibleToEdit
                            ? () => setShowChangeNameDialog(true)
                            : () => {
                                  //
                              }
                    }
                >
                    <BorderColorIcon color={isPossibleToEdit ? 'primary' : 'disabled'} />
                </CustomIconButton>
            </Tooltip>
            {showChangeNameDialog && record && hasPermissions([ROLE_UPDATE_AVATAR], loadedPermissions, permissions) && (
                <ChangeNameDialogForm
                    avatar={record as Avatar}
                    open={showChangeNameDialog}
                    onClose={onCloseUpdateName}
                    onCancel={() => setShowChangeNameDialog(false)}
                />
            )}
        </>
    );
};
export default EditButton;
