import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import './index.css';
import ShowTreeDialog from '../../../commons/dialog/ShowTreeDialog';
import { Avatar } from '../../../utils/types';
import extractIdFromURI from '../../../utils/id';
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { registerPlaceIncidentFilter } from '../../../redux/actions/incidents';
import { GridTitleFilterListStyled } from '../../../commons/filters/utils';

const useStyles = makeStyles({
    fontWeightBold: {
        fontWeight: 700,
    },
    inputFilter: {
        marginBottom: 20,
    },
});

interface Props {
    filters?: any;
    alwaysOn?: boolean;
}

const PlaceRootFilter = (props: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();

    const { setFilters, displayedFilters, filterValues } = useListContext();
    const [showTreeDialog, setShowTreeDialog] = useState<boolean>(false);

    const placeFilterInState: Avatar | null = useSelector(
        (state: CustomReducerState) => state.ticketing.incidents.filters.place
    );

    const [placeFilter, setPlaceFilter] = useState<Avatar | null | undefined>(placeFilterInState);

    const handleTreeItemSelected = (item: Avatar | undefined) => {
        if (!item || !item.root) return;
        dispatch(registerPlaceIncidentFilter.fn(item));
        setFilters(
            {
                ...filterValues,
                // parent: extractIdFromURI(item.id as string),
                'lvl[gte]': item.lvl,
                'lft[gte]': item.lft,
                'rgt[lte]': item.rgt,
                root:
                    typeof item.root === 'object'
                        ? extractIdFromURI(item.root.id as string)
                        : //@ts-ignore
                          extractIdFromURI(item.root),
            },
            displayedFilters
        );
        setPlaceFilter(item);
    };

    const showTreeDialogFn = () => {
        setShowTreeDialog(true);
    };

    const closeTreeDialog = () => {
        setShowTreeDialog(false);
    };

    const cancelTreeItemSelected = () => {
        setPlaceFilter(null);
        if ('lvl[gte]' in filterValues) {
            delete filterValues['lvl[gte]'];
            delete filterValues['lft[gte]'];
            delete filterValues['rgt[lte]'];
            delete filterValues['root'];
            setFilters(
                {
                    ...filterValues,
                },
                displayedFilters
            );
        }
        dispatch(registerPlaceIncidentFilter.fn(null));
        closeTreeDialog();
    };

    return (
        <>
            <GridTitleFilterListStyled>
                <Typography variant={'subtitle2'} className={classes.fontWeightBold}>
                    {translate('app.incidents.filters.place.title')}
                </Typography>
            </GridTitleFilterListStyled>

            <Input
                key={'place_list'}
                onClick={showTreeDialogFn}
                fullWidth
                placeholder={translate('app.incidents.filters.place.label')}
                className={classes.inputFilter}
                value={placeFilter ? placeFilter.name : ''}
            />
            {showTreeDialog && (
                <ShowTreeDialog
                    closeAfterValidate={true}
                    handleValidate={handleTreeItemSelected}
                    showButton={false}
                    dialogHelpText={'app.incidents.filters.place.selected'}
                    dialogTitle={''}
                    thirdButtonLabel={'app.incidents.filters.place.noFilter'}
                    onClose={closeTreeDialog}
                    unSelectItem={cancelTreeItemSelected}
                />
            )}
        </>
    );
};

export default PlaceRootFilter;
