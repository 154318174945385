import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogTemplate from '../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { AutocompleteInput, ReferenceArrayInput, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import ButtonsBlockForm from '../../forms/buttonsBlock';
import { Form as FinalForm } from 'react-final-form';
import CreateForm from '../../categoriesLevel/actions/create';

interface Props {
    setConfig: (data: any) => void;
    formFieldName: string;
    filters: any;
    label: string;
    dialogTitle: string;
    dialogHelpText: string;
    reference: string;
    filterToQuery: (data: any) => any;
    showButton?: boolean;
    onClose?: () => void;
}

const ShowResourceAutocompleteDialog = ({
    setConfig,
    formFieldName,
    filters,
    label,
    reference,
    filterToQuery,
    dialogTitle,
    dialogHelpText,
    showButton = false,
    onClose,
}: Props) => {
    const [open, setOpen] = useState<boolean>(!showButton);
    const handleOnClick = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };
    const translate = useTranslate();

    const onSubmit = (data: any) => {
        setConfig(data);
        handleClose();
        onClose && onClose();
    };

    return (
        <>
            {showButton && (
                <IconButton onClick={handleOnClick}>
                    <SettingsIcon />
                </IconButton>
            )}
            {open && (
                <DialogTemplate
                    open={open}
                    helpText={translate(dialogHelpText)}
                    title={translate(dialogTitle)}
                    onClose={handleClose}
                    icon={<SettingsIcon />}
                    maxWidth={'md'}
                >
                    <FinalForm
                        onSubmit={onSubmit}
                        render={({ handleSubmit, pristine, submitting }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            <ReferenceArrayInput
                                                reference={reference}
                                                filterToQuery={filterToQuery}
                                                filter={filters}
                                                source={formFieldName}
                                                label={translate(label)}
                                            >
                                                <AutocompleteInput
                                                    resettable
                                                    variant={'standard'}
                                                    optionText='name'
                                                    fullWidth
                                                    optionValue='@id'
                                                    filterToQuery={filterToQuery}
                                                />
                                            </ReferenceArrayInput>
                                        </Grid>
                                        <Grid item>
                                            <ButtonsBlockForm
                                                disableValidButton={pristine || submitting}
                                                validButtonIsSubmitType={true}
                                                labelValidButton={'app.valid'}
                                                onCancel={handleClose}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </DialogTemplate>
            )}
        </>
    );
};
export default ShowResourceAutocompleteDialog;
