import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Avatar } from '../../../../../utils/types';
import ProcessConfigurationBlock from './Configurations';

interface Props {
    item: Avatar;
}

const ProcessConfiguration: FC<Props> = ({ item }: Props) => {
    return (
        <Grid container direction={'column'}>
            <Grid item>
                {/*<TopDiv>*/}
                {/*    <Typography variant={'body2'}>*/}
                {/*        {translate('app.navigation.configuration.serviceProvider.usageDescription')}*/}
                {/*    </Typography>*/}
                {/*</TopDiv>*/}
                <ProcessConfigurationBlock place={item} />
            </Grid>
        </Grid>
    );
};

export default ProcessConfiguration;
