import { styled } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishTokenActionCreator } from '../../../../../redux/actions/fileManager/fileManagerActionCreator';
import { CustomReducerState } from '../../../../../redux/reducers';
import extractIdFromURI from '../../../../../utils/id';
import { Avatar } from '../../../../../utils/types';

const MERCURE_PREFIX = '360sc:mercure_id:';
const FINISH_PRODUCE_TOKEN = 'FINISH_PRODUCE_TOKEN';
const avatarFolderPrefix = 'sc:folder-avatar';
const companyFolderPrefix = 'sc:folder-company';
export const COMPANY_FOLDER_TYPE = 'COMPANY_FOLDER';
export const AVATAR_FOLDER_TYPE = 'AVATAR_FOLDER';
export const FOLDERS_FROM_ROOT = 'FOLDERS_FROM_ROOT';
const FOLDER_TYPE = AVATAR_FOLDER_TYPE;
const MERCURE_ENTRYPOINT = process.env.REACT_APP_MERCURE_ENTRYPOINT;

const CustomIframe = styled('iframe')({
    backgroundImage: "url('/backgroundLoading.png')",
    backgroundPosition: '50% 50%',
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: 296,
    border: 0,
});

const CustomDiv = styled('div')({
    backgroundImage: "url('/backgroundLoading.png')",
    backgroundPosition: '50% 50%',
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: 296,
    border: 0,
});

const encodeQueryParams = (query: any) =>
    Object.keys(query)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
        .join('&');

interface Props {
    item: Avatar;
}

const FileManager: FC<Props> = ({ item: object }) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    let publishInterval: any;
    const tokenWithRoles: any = useSelector(
        (state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles
    );
    const tokenData = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData);

    const publishToken = async (mercureId: string) => {
        publishInterval = setInterval(() => {
            dispatch(publishTokenActionCreator.fn(mercureId, tokenWithRoles));
        }, 2000);
    };

    const subscribe = (mercureId: string) => {
        const eventSource = new EventSource(
            `${MERCURE_ENTRYPOINT}?topic=${encodeURIComponent(`${MERCURE_PREFIX}${mercureId}`)}`
        );

        setTimeout(() => {
            eventSource.close();
            clearInterval(publishInterval);
        }, 100000);

        eventSource.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            const action = eventData && 'action' in eventData ? eventData.action : null;
            if (action && action === FINISH_PRODUCE_TOKEN) {
                clearInterval(publishInterval);
                eventSource.close();
            }
        };
    };

    const resolveUrl = () => {
        const timeStamp = new Date().getTime();
        const mercureId = btoa(`${tokenData ? tokenData.username : ''}_${timeStamp}`);
        subscribe(mercureId);
        publishToken(mercureId);

        const id = extractIdFromURI(object['@id']);

        let folderName = null;
        if (FOLDER_TYPE === AVATAR_FOLDER_TYPE) {
            folderName = `${avatarFolderPrefix}:${id}`;
        } else if (FOLDER_TYPE === COMPANY_FOLDER_TYPE) {
            folderName = `${companyFolderPrefix}:${id}`;
        }

        // This code shows only the file into the current avatar
        // setUrl(
        //     `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_URI_ENTRYPOINT}?${encodeQueryParams({
        //         mercure_id: mercureId,
        //         height: 296 - 16,
        //         folder_name: folderName,
        //         folder_type: FOLDER_TYPE,
        //         // locale: router.locale,
        //         locale: 'en',
        //     })}`
        // );

        // This code shows all files from root folder
        setUrl(
            `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_URI_ENTRYPOINT}/foldersFromRoot?${encodeQueryParams({
                mercure_id: mercureId,
                height: 296 - 16,
                avatar_id: id,
                folder_type: FOLDERS_FROM_ROOT,
                // locale: router.locale,
                locale: 'en',
            })}`
        );
    };

    useEffect(() => {
        if (object) {
            resolveUrl();
        }
    }, [object]);

    return <CustomDiv>{url !== '' && <CustomIframe src={url} />} </CustomDiv>;
};

export default FileManager;
