export const NAME_FIELD = 'name';
export const IMAGE_FIELD = 'image';
export const ICON_FIELD = 'icon';
export const CATEGORY_FIELD = 'groupMetadataAvatarType';
export const REPEATABLE_FIELD = 'repeatable';
export const WHEN_REPEATABLE_TITLE_SHOULD_BE_OVERWRITE_FIELD = 'when_repetable_title_should_be_overwrite';
export const FORCE_TEXT_FIELD = 'forceText';
export const FORCE_IMAGE_FIELD = 'forceImage';
export const NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD = 'ticketing_no_logged_users_can_see_history';
export const NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD = 'ticketing_no_logged_users_can_see_created_events';
export const ASK_INCIDENT_CREATOR_EMAIL_FIELD = 'ticketing_ask_incident_creator_email';
export const ASK_CODE_PIN_FIELD = 'ticketing_ask_code_pin';
export const FORCE_FILE_SYSTEM_MAPPER_FIELD = 'forceFileSystemMapper';
export const TICKETING_FILESYSTEM_MAPPER_ENABLED = 'ticketing.file_system.enabled';
export const ICON_SVG_COLLECTION_FIELD = 'icon_svg_collection';
export const TICKETING_FILESYSTEM_MAPPER_ENABLED_FIELD = 'ticketing_file_system_enabled';
export const CUSTOM_FIELDS_ICON_SVG_COLLECTION = 'icon-svg-collection';
export const CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER = 'icon-file-system-mapper';
export const SVG_ICON_SYSTEM = 'SVG_ICON_SYSTEM';
