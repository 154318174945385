import Grid from '@material-ui/core/Grid';
import React, { FC, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import CustomBooleanInput from './CustomBooleanInput';
import { Avatar, MetadataAvatarType, TicketingManagerNotification } from '../../../../../../utils/types';
import {
    GetListResult,
    Identifier,
    Record,
    ReduxState,
    UpdateResult,
    useCreate,
    useDataProvider,
    useNotify,
    useUpdate,
} from 'react-admin';
import { TICKETING_MANAGER_NOTIFICATIONS } from '../../../../../../providers/resources';
import extractIdFromURI from '../../../../../../utils/id';
import { useSelector } from 'react-redux';

interface Props {
    source: string;
    label: string;
    subcategory?: MetadataAvatarType;
    place: Avatar;
}

const BooleanFormView: FC<Props> = (props: Props) => {
    const dataProvider = useDataProvider();
    const [update] = useUpdate(TICKETING_MANAGER_NOTIFICATIONS);
    const [create] = useCreate(TICKETING_MANAGER_NOTIFICATIONS);
    const notify = useNotify();

    const ticketingManagerNotificationInState: Record | undefined = useSelector((state: ReduxState) => {
        const value = Object.values(state.admin.resources[TICKETING_MANAGER_NOTIFICATIONS].data).find(
            (item) =>
                (item as TicketingManagerNotification).subCategory === props.subcategory?.id &&
                (item as TicketingManagerNotification).place === props.place.id
        );
        return value ? (value as TicketingManagerNotification) : undefined;
    });

    useEffect(() => {
        if (!props.subcategory) return;
        if (ticketingManagerNotificationInState) return;
        dataProvider
            .getList(TICKETING_MANAGER_NOTIFICATIONS, {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'DESC' },
                filter: {
                    subCategory: extractIdFromURI(props.subcategory.id as string),
                    place: extractIdFromURI(props.place.id as string),
                },
            })
            .catch((error) => {
                console.error('get List', TICKETING_MANAGER_NOTIFICATIONS, error);
                notify('app.navigation.configuration.managerNotification.action.list.error', 'error', {
                    incidentName: props.subcategory?.name,
                });
            });
    }, [props.subcategory]);

    const getOrCreateTicketingNotification = (data: any) => {
        if (!props.subcategory) return;
        dataProvider
            .getList(
                TICKETING_MANAGER_NOTIFICATIONS,
                {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {
                        subCategory: extractIdFromURI(props.subcategory.id as string),
                        place: extractIdFromURI(props.place.id as string),
                    },
                },
                {
                    onSuccess: (result: GetListResult) => {
                        if (result && 'data' in result && result.data.length === 0) {
                            create(TICKETING_MANAGER_NOTIFICATIONS, {
                                company: props.place.company,
                                place: props.place.id,
                                subCategory: props.subcategory?.id,
                                ...data,
                            });
                        }
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.navigation.configuration.managerNotification.action.list.error', 'error', {
                            incidentName: props.subcategory?.name,
                        });
                    },
                }
            )
            .catch((error) => {
                console.error('get List', TICKETING_MANAGER_NOTIFICATIONS, error);
                notify('app.navigation.configuration.managerNotification.action.list.error', 'error', {
                    incidentName: props.subcategory?.name,
                });
            });
    };

    const handleSubmit = (data: any) => {
        if (ticketingManagerNotificationInState) {
            update(
                TICKETING_MANAGER_NOTIFICATIONS,
                (ticketingManagerNotificationInState as TicketingManagerNotification).id,
                {
                    ...data,
                },
                {
                    ...ticketingManagerNotificationInState,
                },
                {
                    onSuccess: (result: UpdateResult) => {
                        notify('app.navigation.configuration.managerNotification.action.edit.notification', 'success');
                        if (result && 'data' in result && result.data)
                            refreshTicketingNotificationUpdated(result.data.id);
                    },
                    onFailure: (error: any) => {
                        console.error(error);
                        notify('app.navigation.configuration.managerNotification.action.edit.error', 'error');
                    },
                }
            );
        } else {
            getOrCreateTicketingNotification(data);
        }
    };

    const refreshTicketingNotificationUpdated = (id: Identifier) => {
        dataProvider
            .getOne(TICKETING_MANAGER_NOTIFICATIONS, {
                id: id as string,
            })
            .catch((error: any) => {
                console.error(error);
                notify('app.navigation.configuration.managerNotification.action.list.error', 'error', {
                    incidentName: props.subcategory?.name,
                });
            });
    };
    return (
        <Grid container direction={'column'} alignItems={'center'}>
            <FinalForm
                initialValues={{
                    [props.source]:
                        ticketingManagerNotificationInState && props.source in ticketingManagerNotificationInState
                            ? ticketingManagerNotificationInState[props.source]
                            : null,
                }}
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <CustomBooleanInput {...props} />
                        </form>
                    );
                }}
            />
        </Grid>
    );
};

export default BooleanFormView;
