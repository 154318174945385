import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
import { Datagrid, FunctionField, Identifier, List, Record, ReduxState, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { incidentTypes } from '../../../pages/paths';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { selectCategory } from '../../../redux/actions/categories';
import { CustomReducerState } from '../../../redux/reducers';
import { getCategorySelected } from '../../../redux/reducers/categories';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../utils/CONST';
import { rowStyle } from '../../../utils/rowListStyles';
import { GroupMetadataAvatarType } from '../../../utils/types';
import Actions from '../actions';
import CreateForm from '../actions/create';
import FilterSidebar from '../filters';
import { Typography } from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../utils/strings';

const useStyles = (divHeight: number) =>
    makeStyles({
        //@ts-ignore
        field: {
            // ...FieldHeaderListStyles,
            // ...FieldCapitalizeListStyles,
        },
        content: {
            overflow: 'auto',
        },
        main: {
            height: divHeight,
        },
    });

const CategoriesLevelList = ({ divHeight }: { divHeight: number }) => {
    const classes = useStyles(divHeight - 40)();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(true);
    const onCloseCreateForm = () => {
        setShowCreateForm(false);
    };

    const listProps = {
        resource: GROUP_METADATA_AVATAR_TYPES,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: incidentTypes,
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 25,
        filter: {
            alias: GROUP_METADATA_AVATAR_TYPE_LEVEL,
            hidden: false,
        },
        classes: {
            main: classes.main,
            content: classes.content,
        },
    };

    const translate = useTranslate();
    const dispatch = useDispatch();

    const selected: GroupMetadataAvatarType | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getCategorySelected(state)
    );

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectCategory.fn(record));
        // @ts-ignore
        return Promise.resolve(undefined);
    };

    return (
        <List
            {...listProps}
            empty={<CreateForm open={showCreateForm} handleClose={onCloseCreateForm} />}
            aside={<FilterSidebar divHeight={divHeight} />}
            bulkActionButtons={false}
            actions={<Actions />}
        >
            <Datagrid rowClick={onClick} rowStyle={(record, index) => rowStyle(record, index, selected)}>
                <FunctionField
                    sortable={false}
                    label={translate('app.category.name')}
                    headerClassName={classes.field}
                    render={(record: Record | undefined) => (
                        <Typography variant={'body2'}>
                            {' '}
                            {record ? capitalizeFirstLetter(record?.name) : '...'}
                        </Typography>
                    )}
                />
            </Datagrid>
        </List>
    );
};
export default CategoriesLevelList;
