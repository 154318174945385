import { styled } from '@material-ui/styles';
import React, { cloneElement, ReactElement } from 'react';

const CustomDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

interface Props {
    icon?: ReactElement;
    switchC: ReactElement;
    children: any;
    rootStyles?: { [key: string]: any };
}

const SwitchIconChildrenContainer = ({ switchC, icon, children, rootStyles = {} }: Props) => {
    return (
        <CustomDiv style={rootStyles}>
            {switchC}
            {icon && cloneElement(icon, { style: { marginRight: 20 } })}
            {children}
        </CustomDiv>
    );
};

export default SwitchIconChildrenContainer;
