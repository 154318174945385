import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {
    AutocompleteInput,
    Identifier,
    Record as DataRecord,
    ReduxState,
    required,
    TextInput,
    useCreate,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Field, FieldProps, Form as FinalForm } from 'react-final-form';
import CheckBox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import DialogTemplateButtons from '../../../../commons/Dialog/DialogTemplateButtons/DialogTemplateButtons';
import { AVATARS, METADATA_AVATAR_TYPES, TICKETING_PLACE_OR_EVENT } from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import { GROUP_METADATA_AVATAR_TYPE_LEVEL } from '../../../../utils/CONST';
import { Avatar, User } from '../../../../utils/types';
import { navigation } from '../../../../pages/paths';
import ReferenceInput from '../../../../commons/ra/input/ReferenceInput';
import CreateSubcategoryFromAutocompleteInput from '../../../subCategories/actions/create/CreateFromAutocompleteInput';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';

interface Props {
    open: boolean;
    fromEmptyList?: boolean;
    onClose: () => void;
    onCancel: () => void;
    item?: Avatar;
}

const AddItemDialog = ({ open, onClose, onCancel, item }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const queryClient = useQueryClient();
    const { selectedItem, selectedItemData }: { selectedItem: Identifier | null; selectedItemData: Avatar | null } =
        useSelector((state: CustomReducerState & ReduxState) => ({
            selectedItem: state.ticketing.navigation.tree.levelItemSelected,
            selectedItemData:
                state.ticketing.navigation.tree.levelItemSelected &&
                state.ticketing.navigation.tree.levelItemSelected in state.admin.resources[AVATARS].data
                    ? (state.admin.resources[AVATARS].data[state.ticketing.navigation.tree.levelItemSelected] as Avatar)
                    : null,
        }));

    const userConnected: User | DataRecord | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );
    const [create, createData] = useCreate(TICKETING_PLACE_OR_EVENT);

    const onSubmit = (data: Record<string, unknown>) => {
        const { asChild, ...restData } = data;

        let dataToSend = {};

        if ((asChild === true || asChild === undefined) && selectedItem) {
            dataToSend = {
                ...restData,
                parent: selectedItem,
                company: userConnected?.company['@id'],
                createMc: true,
            };
        } else {
            dataToSend = {
                ...restData,
                company: userConnected?.company['@id'],
                createMc: true,
            };
        }

        create(TICKETING_PLACE_OR_EVENT, dataToSend, {
            onSuccess: () => {
                notify('app.messages.success', 'success');
                onClose();
                refresh();

                if (item) {
                    if (asChild !== undefined) {
                        if (asChild) {
                            queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(item.id as string)}`]);
                        } else {
                            queryClient.invalidateQueries([`rootNavigationAvatars`]);
                        }
                    } else {
                        queryClient.invalidateQueries([`navigationParent_${extractIdFromURI(item.id as string)}`]);
                    }
                }
            },
            onFailure: (error: unknown) => {
                notify('app.messages.creationError', 'error');
                console.error('ERROR when creating', TICKETING_PLACE_OR_EVENT, error);
            },
        });
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.addItem.title')}
            open={open}
            onClose={onClose}
            icon={<AddIcon />}
            maxWidth={'sm'}
        >
            <FinalForm
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, valid }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <ReferenceInput
                                        basePath={navigation}
                                        reference={METADATA_AVATAR_TYPES}
                                        filter={{
                                            'groupMetadataAvatarType.alias': GROUP_METADATA_AVATAR_TYPE_LEVEL,
                                            hidden: false,
                                            order__createdAt: 'DESC',
                                        }}
                                        filterToQuery={(search: string) => ({ name: search })}
                                        source={'metadataAvatarType'}
                                        label={translate('app.navigation.addItem.selectSubCategory')}
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput
                                            resettable
                                            filterToQuery={(search: string) => ({ name: search })}
                                            create={<CreateSubcategoryFromAutocompleteInput />}
                                            optionValue={'@id'}
                                            optionText={'name'}
                                            fullWidth
                                            variant={'standard'}
                                        />
                                    </ReferenceInput>
                                </Grid>

                                <Grid item>
                                    <TextInput
                                        variant={'standard'}
                                        source={'name'}
                                        label={translate('app.navigation.addItem.name')}
                                        helperText={true}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                {selectedItemData && 'lvl' in selectedItemData && selectedItemData.lvl === 0 && (
                                    <Grid item>
                                        <Field defaultValue={true} required name='asChild'>
                                            {(props: FieldProps<any, any>) => (
                                                <FormControlLabel
                                                    label={translate('app.navigation.addItem.asChild')}
                                                    control={
                                                        <CheckBox
                                                            name={props.input.name}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                            checked={props.input.value}
                                                        />
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                )}
                            </Grid>

                            <DialogTemplateButtons
                                outside
                                // withCancel={!fromEmptyList}
                                withCancel={true}
                                cancelButtonLabel={translate('app.cancel')}
                                onCancelClick={handleCancel}
                                withCreate
                                createButtonLabel={translate('app.buttons.valid')}
                                isValidForm={valid}
                                isSubmitting={createData.loading}
                            />
                        </form>
                    );
                }}
            />
        </DialogTemplate>
    );
};

const validate = (values: { [key: string]: string }) => {
    const errors: { [key: string]: string } = {};
    if (!values.metadataAvatarType) {
        errors.metadataAvatarType = 'ra.validation.required';
    }
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};

export default AddItemDialog;
