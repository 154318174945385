import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Datagrid, FunctionField, Identifier, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { selectIncident } from '../../../redux/actions/incidents';
import { CustomReducerState } from '../../../redux/reducers';
import { getIncidentSelected } from '../../../redux/reducers/incidents';
import { getValueFromNode } from '../../../utils/avatars';
import { AVATAR_IDENTIFIER_ACCESS_CODE, AVATAR_IDENTIFIER_SEPARATOR } from '../../../utils/CONST';
import { rowStyle } from '../../../utils/rowListStyles';
import { Avatar } from '../../../utils/types';
import {
    COUNTDOWN_COLUMN,
    EVENT_ASSIGNED_DATE,
    IMAGE_AND_COMMENT,
    INCIDENT_TIME_FROM_THE_BEGINNING_COLUMN,
    NAME_COLUMN,
    NAVIGATION_PATH_COLUMN,
    PIN_CODE_COLUMN,
    RAPPORT_DATE_COLUMN,
    RESOLVE_DATE_COLUMN,
    SERVICE_PROVIDER_COLUMN,
    STATE_COLUMN,
    WORK_RESOLUTION_DELAY,
} from '../actions/dynamicColumns';
import '../index.css';
import ResolvedIncidentDateField from '../fields/ResolvedIncidentDateField';
import { toFormatDate } from '../../../utils/date';
import UserResolutionDeadlineField from '../fields/UserResolutionDeadlineField';
import IncidentTimeFromTheBeginningField from '../fields/IncidentTimeFromTheBeginningField';
import PlaceOrEventNavigationField from '../fields/PlaceOrEventNavigation';
import CountdownField from '../fields/CountdownField';
import {
    ROLE_READ_AVATAR,
    ROLE_READ_TICKETING_EVENT_USER,
    ROLE_READ_TICKETING_USER_PROVIDER_EVENT_CONFIGURATION,
    ROLE_READ_USER,
} from '../../../utils/ROLES';
import { hasPermissions } from '../../../utils/permissions';
import { FieldHeaderListStyles } from '../../../theme';
import EventAssignedDateField from '../fields/EventAssignedDateField';
import ListActionButtons from './actionButtons';
import ServiceProviderButtonField from '../../../commons/fields/serviceProvider/ServiceProviderButtonField';
import EventOpenedStateInfo from '../fields/EventOpenedStateInfo';
import IncidentNameAndExtraInformation from '../fields/IncidentNameAndExtraInformation';
import RecoverMetabaseData from '../fields/recoverMetabaseData';
import extractIdFromURI from '../../../utils/id';
import { EnabledColumns } from './types';
import EventStateColorByName from '../../../commons/eventStateColor/EventStateColorByName';

const INCIDENT_TIME_QUESTION = 13;
const useStyles = makeStyles({
    content: {
        overflow: 'auto',
    },
    //@ts-ignore
    field: {
        ...FieldHeaderListStyles,
        // ...FieldCapitalizeListStyles,
    },
    fullWidthField: {
        ...FieldHeaderListStyles,
        width: 'fit-content',
    },
    gridItemAlignCenter: {
        alignSelf: 'center',
    },
    bulkActionsDisplayed: {
        marginTop: '0px !important',
    },
    statusColor: {
        backgroundColor: 'red !important',
        color: 'white !important',
    },
});

interface Props {
    enabledColumns: EnabledColumns;
}

const CustomDatagrid = (props: Props) => {
    const classes = useStyles();
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const translate = useTranslate();

    const selected: Avatar | undefined = useSelector((state: CustomReducerState & ReduxState) =>
        getIncidentSelected(state)
    );

    const dispatch = useDispatch();

    const onClick = (id: Identifier, basePath: string, record: Record): Promise<string> => {
        dispatch(selectIncident.fn(record));
        return Promise.resolve('');
    };

    return (
        <Datagrid
            rowClick={onClick}
            rowStyle={(record, index) => rowStyle(record, index, selected)}
            hasBulkActions={true}
        >
            {/*Actions*/}
            <FunctionField
                headerClassName={classes.field}
                label={''}
                render={(record: Record | undefined) =>
                    record ? <ListActionButtons record={record as Avatar} /> : <span />
                }
            />

            {/*State*/}
            {props.enabledColumns[STATE_COLUMN] && (
                <FunctionField
                    headerClassName={classes.field}
                    label={translate('app.incidents.state')}
                    // @ts-ignore
                    render={(record: Avatar) => {
                        return (
                            <EventStateColorByName
                                event={record}
                                name={getValueFromNode(record, 'name')}
                                nodeTypes={getValueFromNode(record, 'nodeTypes')}
                            />
                        );
                    }}
                />
            )}

            {/*Incident name*/}
            {props.enabledColumns[NAME_COLUMN] && (
                <FunctionField
                    headerClassName={classes.field}
                    label={translate('app.incidents.name')}
                    render={(record: Record | undefined) =>
                        record ? <IncidentNameAndExtraInformation avatar={record as Avatar} /> : <span />
                    }
                />
            )}
            {/*information about event state*/}
            {props.enabledColumns[IMAGE_AND_COMMENT] && (
                <FunctionField
                    headerClassName={classes.field}
                    sortable={false}
                    label={translate('app.incidents.imageAndComment')}
                    // @ts-ignore
                    render={(record: Avatar) => (record ? <EventOpenedStateInfo event={record} /> : <span />)}
                />
            )}

            {/*Path*/}
            {props.enabledColumns[NAVIGATION_PATH_COLUMN] &&
                hasPermissions([ROLE_READ_AVATAR], loadedPermissions, permissions) && (
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.incidents.navigationPath')}
                        render={(record: Record | undefined) => {
                            return <PlaceOrEventNavigationField event={record as Avatar} />;
                        }}
                    />
                )}

            {/*Service provider*/}
            {props.enabledColumns[SERVICE_PROVIDER_COLUMN] &&
                loadedPermissions &&
                hasPermissions([ROLE_READ_TICKETING_EVENT_USER, ROLE_READ_USER], loadedPermissions, permissions) && (
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.incidents.serviceProvider')}
                        render={(record: Record | undefined) =>
                            record ? <ServiceProviderButtonField record={record as Avatar} /> : <span />
                        }
                    />
                )}

            {/*Rapport date*/}
            {props.enabledColumns[RAPPORT_DATE_COLUMN] && (
                <FunctionField
                    label={translate('app.incidents.rapportDate')}
                    headerClassName={classes.field}
                    render={(record: Record | undefined) => {
                        return toFormatDate((record as Avatar).creationDate as string);
                    }}
                />
            )}

            {/*time since report*/}
            {props.enabledColumns[INCIDENT_TIME_FROM_THE_BEGINNING_COLUMN] && (
                <FunctionField
                    headerClassName={classes.field}
                    label={translate('app.incidents.incidentTimeDefined')}
                    render={(record: Record | undefined) => {
                        return <IncidentTimeFromTheBeginningField record={record as Avatar} />;
                    }}
                />
            )}

            {/*Resolve date*/}
            {props.enabledColumns[RESOLVE_DATE_COLUMN] && (
                <FunctionField
                    label={translate('app.incidents.resolveDate')}
                    headerClassName={classes.field}
                    render={(record: Record | undefined) =>
                        record ? (
                            <RecoverMetabaseData
                                // @ts-ignore
                                eventId={record.id}
                                question={INCIDENT_TIME_QUESTION}
                                requestBody={{
                                    id: INCIDENT_TIME_QUESTION,
                                    dashboard_type: 'question',
                                    // @ts-ignore
                                    event_id: extractIdFromURI(record.id as string),
                                    place_id: null,
                                }}
                            >
                                {({ data, loading }: { data: any; loading: boolean }) => (
                                    <ResolvedIncidentDateField record={record as Avatar} />
                                )}
                            </RecoverMetabaseData>
                        ) : (
                            <span />
                        )
                    }
                ></FunctionField>
            )}

            {/*event assigned date*/}
            {props.enabledColumns[EVENT_ASSIGNED_DATE] && hasPermissions([], loadedPermissions, permissions) && (
                <FunctionField
                    headerClassName={classes.field}
                    label={translate('app.incidents.eventAssignedDate')}
                    render={(record: Record | undefined) => {
                        return <EventAssignedDateField event={record as Avatar} />;
                    }}
                />
            )}

            {/*work resolution delay*/}
            {props.enabledColumns[WORK_RESOLUTION_DELAY] &&
                hasPermissions(
                    [ROLE_READ_TICKETING_USER_PROVIDER_EVENT_CONFIGURATION],
                    loadedPermissions,
                    permissions
                ) && (
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.incidents.workResolutionDelay')}
                        render={(record: Record | undefined) =>
                            record ? (
                                <UserResolutionDeadlineField event={record as Avatar} />
                            ) : (
                                // <GetMetabaseUrlOfTheData
                                //     version={0}
                                //     requestBody={{
                                //         id: INCIDENT_TIME_QUESTION,
                                //         dashboard_type: 'question',
                                //         event_id: extractIdFromURI(record.id as string),
                                //     }}
                                // >
                                //     {(data: ResponseForwarderToMetabaseType) => {
                                //         return (
                                //             <UserResolutionDeadlineField
                                //                 event={record as Avatar}
                                //                 metabaseData={data}
                                //             />
                                //         );
                                //     }}
                                // </GetMetabaseUrlOfTheData>
                                '...'
                            )
                        }
                    />
                )}

            {/*countdown*/}
            {props.enabledColumns[COUNTDOWN_COLUMN] &&
                hasPermissions(
                    [ROLE_READ_TICKETING_USER_PROVIDER_EVENT_CONFIGURATION],
                    loadedPermissions,
                    permissions
                ) && (
                    <FunctionField
                        headerClassName={classes.field}
                        label={translate('app.incidents.countdown')}
                        render={(record: Record | undefined) => {
                            return <CountdownField event={record as Avatar} />;
                        }}
                    />
                )}

            {/*pin code*/}
            {props.enabledColumns[PIN_CODE_COLUMN] && (
                <FunctionField
                    headerClassName={classes.field}
                    sortable={false}
                    label={translate('app.incidents.pinCode')}
                    // @ts-ignore
                    render={(record: Avatar) => {
                        let pinCode = '...';
                        if (record.identifiers && record.identifiers.length > 0) {
                            const identifier = record.identifiers.find((identifier: string) =>
                                identifier.toLowerCase().startsWith(AVATAR_IDENTIFIER_ACCESS_CODE)
                            );

                            const splitArray: string[] | undefined = identifier?.split(AVATAR_IDENTIFIER_SEPARATOR);
                            pinCode =
                                splitArray &&
                                splitArray.length >= 2 &&
                                splitArray[0].toLowerCase() === AVATAR_IDENTIFIER_ACCESS_CODE.toLowerCase()
                                    ? splitArray[1]
                                    : '...';
                        }

                        return pinCode;
                    }}
                />
            )}
        </Datagrid>
    );
};

export default CustomDatagrid;
