import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { FunctionField, Record, useDataProvider } from 'react-admin';
import { MEDIA_LINKS } from '../../../../providers/resources';
import { FieldHeaderListStyles, ImageStyles } from '../../../../theme';
import { TAG_TICKETING_LOGO } from '../../../../utils/CONST';
import IconField from '../../icon';

const useStyles = makeStyles({
    field: {
        ...FieldHeaderListStyles,
    },
    gridIconRightMargin: {
        marginRight: 5,
    },
    image: {
        ...ImageStyles,
    },
});

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
});

interface Props {
    record?: Record;
    label: string;
    findImageInAnotherResource: boolean;
    keyForFindImageInAnotherResource?: string;
    imagesKey?: string;
    tags?: string[];
    headerClassName?: string;
    resourceSupported?: string;
}

const FunctionIconField = ({ record, label, headerClassName }: Props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [icon, setIcon] = useState<any>(null);

    const findLogoInMedias = (medias: any[]) => {
        let logo = null;

        medias.map((media: any) => {
            if (
                typeof media === 'object' &&
                'customFields' in media &&
                'type' in media.customFields &&
                media.customFields.type === TAG_TICKETING_LOGO
            ) {
                logo = media;
            }
        });

        return logo;
    };

    useEffect(() => {
        if (record) {
            const medias = 'medias' in record ? record.medias : [];

            const logo = findLogoInMedias(medias);

            if (logo) {
                dataProvider.getOne(
                    MEDIA_LINKS,
                    { id: `${logo['@id']}` },
                    {
                        onSuccess: ({ data }) => {
                            setIcon(data);
                        },
                        onFailure: (error) => {
                            console.log('error', error);
                        },
                    }
                );
            }
        }
    }, [record]);

    return (
        <FunctionField
            sortable={false}
            headerClassName={headerClassName}
            source='name'
            label={label}
            render={(resourceRecord: Record | undefined) => {
                return resourceRecord ? (
                    <CustomDiv>
                        {icon ? (
                            <IconField iconData={icon} />
                        ) : (
                            <img src={'/tmp-image.png'} className={classes.image} alt='' />
                        )}

                        <Typography noWrap={true} variant={'body2'}>
                            {resourceRecord ? resourceRecord.name : ''}
                        </Typography>
                    </CustomDiv>
                ) : (
                    <img src={'/tmp-image.png'} className={classes.image} alt='' />
                );
            }}
        />
    );
};

export default FunctionIconField;
