import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { Fragment } from 'react';

const collection = process.env.REACT_APP_ICONS_COLLECTION;
const iconsUrl = process.env.REACT_APP_ICONS_URL;

const CustomDiv = styled('div')({
    width: 50,
    height: 50,
});

const CustomImg = styled('img')({
    width: 50,
    height: 50,
});

const ShowDiv = styled('div')({
    width: '100%',
    height: 400,
    backgroundColor: '#E4E5EB',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
});

const ShowImg = styled('img')({
    width: 100,
    height: 100,
});

const CustomIconButton = styled(IconButton)({
    color: '#D1D3DA',
    padding: 0,
});

const CustomAddIcon = styled(AddIcon)({
    width: 150,
    height: 150,
});

interface Props {
    iconName: string | null;
    selectedIconName?: any;
    onSelect?: any;
    onlyShow?: boolean;
    onClick?: any;
}

const ShowIconContainer = ({ iconName, selectedIconName, onlyShow = false, onSelect, onClick }: Props) => {
    return (
        <Fragment>
            {onlyShow ? (
                <ShowDiv>
                    {iconName ? (
                        <ShowImg
                            src={`${iconsUrl}/collections/${collection}/svg/${iconName}`}
                            onClick={() => {
                                if (onSelect) {
                                    onSelect(iconName);
                                }
                            }}
                        ></ShowImg>
                    ) : (
                        <CustomIconButton onClick={onClick ? onClick : null} style={{ marginBottom: 50 }}>
                            <CustomAddIcon />
                        </CustomIconButton>
                    )}
                </ShowDiv>
            ) : (
                <CustomDiv
                    style={{
                        ...(selectedIconName === iconName
                            ? { border: '2px solid #3F51B5', padding: 2, borderRadius: 5 }
                            : { padding: 4 }),
                        cursor: 'pointer',
                    }}
                >
                    {iconName && (
                        <CustomImg
                            src={`${iconsUrl}/collections/${collection}/svg/${iconName}`}
                            onClick={() => onSelect(iconName)}
                        ></CustomImg>
                    )}
                </CustomDiv>
            )}
        </Fragment>
    );
};

export default ShowIconContainer;
