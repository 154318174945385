import moment from 'moment';

export const getPathProcess = (startNode) => {
    let nodesTemp = [];
    let result = [];

    if (
        startNode &&
        'isStartNodeBridgeToStartProcess' in startNode &&
        startNode.isStartNodeBridgeToStartProcess === true
    ) {
        startNode =
            Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ? startNode.nextNodes[0] : null;
    }

    if (startNode && 'nextNodes' in startNode) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while (nodesTemp.length > 0) {
        let node = nodesTemp.shift();
        // @ts-ignore
        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;
        if (nextNodes) {
            nextNodes.forEach((nextN) => {
                nodesTemp.push(nextN);
                result.push(nextN);
            });
        }
    }

    return result;
};

const getNodeNames = (runnerTemplates = []) => {
    const setValue = runnerTemplates
        .map((runnerTemplate) => getPathProcess(runnerTemplate.node))
        .reduce((accumulate, nodeList) => {
            return [...accumulate, ...nodeList];
        }, [])
        .reduce((accumulate, node) => {
            return new Set([...Array.from(accumulate.values()), node.name]);
        }, new Set([]));

    return Array.from(setValue.values());
};

export default getNodeNames;

export const getNode = (runnerTemplates = []) => {
    const setValue = runnerTemplates
        .map((runnerTemplate) => getPathProcess(runnerTemplate.node))
        .reduce((accumulate, nodeList) => {
            return [...accumulate, ...nodeList];
        }, [])
        .reduce((accumulate, node) => {
            return new Set([...Array.from(accumulate.values()), node]);
        }, new Set([]));
    return Array.from(setValue.values());
};

export const findNodeByNodeType = (nodes, nodeType) => {
    let out;
    nodes.map((node) => {
        if (node) {
            const index = node.nodeTypes.findIndex((item) => item === nodeType);
            if (index !== -1) out = node;
        }
    });
    return out;
};

export const isNodeType = (record, nodeType) => {
    const node = record.currentReferencing?.standardReferencing?.runner?.node;
    if (node) {
        const index = node.nodeTypes.findIndex((item) => item === nodeType);
        return index !== -1;
    }
    return false;
};

export const sortNodesByDateOfTakesPlace = (nodes) => {
    if (!nodes || !Array.isArray(nodes)) return;
    return nodes
        .filter((node) => node && 'dateOfTakesPlace' in node && 'extraData' in node && 'auth' in node['extraData'])
        .sort((nodeA, nodeB) => {
            if (moment(nodeA['dateOfTakesPlace']).isSame(moment(nodeB['dateOfTakesPlace']))) return 0;
            // console.log('nodeA', nodeA.name, moment(nodeA['dateOfTakesPlace']));
            // console.log('nodeB', nodeB.name, moment(nodeB['dateOfTakesPlace']));
            // console.log('momentC', moment(nodeA['dateOfTakesPlace']).isAfter(moment(nodeB['dateOfTakesPlace'])));
            return moment(nodeA['dateOfTakesPlace']).isBefore(moment(nodeB['dateOfTakesPlace'])) ? 1 : -1;
        });
};
