import { Avatar, MetadataAvatarType, User } from '../types';
import { TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS } from '../../providers/resources';
import extractIdFromURI from '../id';
import { DataProvider, GetListResult, Identifier, Record } from 'react-admin';

const getTicketingUserProviderEventConfig = (
    dataProvider: DataProvider,
    user: User,
    incident: Avatar,
    subcategory: Identifier,
    placeId: Identifier,
    onSuccess: (response: GetListResult<Record>) => void,
    onFailure: (error: any) => void
) => {
    dataProvider
        .getList(TICKETING_USER_PROVIDER_EVENT_CONFIGURATIONS, {
            pagination: {
                page: 1,
                perPage: 1,
            },
            sort: {
                field: 'id',
                order: 'DESC',
            },
            filter: {
                event_id: extractIdFromURI(incident.id as string),
                user: extractIdFromURI(user.id as string),
                subCategory: extractIdFromURI(subcategory as string),
                place: extractIdFromURI(placeId as string),
            },
        })
        .then((response: GetListResult<Record>) => onSuccess(response))
        .catch((error: any) => onFailure(error));
};

export { getTicketingUserProviderEventConfig };
