export interface SetDndStateAction {
    type: typeof setDndState.action;
    payload: {
        state: boolean;
    };
}

export const setDndState = {
    action: '360SC_TICKETING/SET_DND_STATUS',
    fn: (state: boolean): SetDndStateAction => ({
        type: setDndState.action,
        payload: {
            state,
        },
    }),
};

export interface SetDndIdAction {
    type: typeof setDndId.action;
    payload: {
        id: string | null;
    };
}

export const setDndId = {
    action: '360SC_TICKETING/SET_DND_ID',
    fn: (id: string | null): SetDndIdAction => ({
        type: setDndId.action,
        payload: {
            id,
        },
    }),
};

export interface SetDndListAction {
    type: typeof setDndList.action;
    payload: {
        list: any[];
    };
}

export const setDndList = {
    action: '360SC_TICKETING/SET_DND_LIST',
    fn: (list: any[]): SetDndListAction => ({
        type: setDndList.action,
        payload: {
            list,
        },
    }),
};
