import { MetadataAvatarType } from '../../../utils/types';

export interface SelectIncidentTypeAction {
    type: typeof selectIncidentType.action;
    payload: {
        record: MetadataAvatarType | null;
    };
}

export const selectIncidentType = {
    action: '360SC_TICKETING/SELECT_INCIDENT_TYPE',
    fn: (record: MetadataAvatarType | null): SelectIncidentTypeAction => ({
        type: selectIncidentType.action,
        payload: {
            record: record,
        },
    }),
};

export type ACTIONS = SelectIncidentTypeAction;
