import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import CropFreeIcon from '@material-ui/icons/CropFree';
import React from 'react';
import { useTranslate } from 'react-admin';
import QrCode from './QrCode';

const useStyles = makeStyles((theme) => ({
    icons: {
        // @ts-ignore
        marginRight: theme.spacing.unit,
        // @ts-ignore
        fontSize: `${theme.spacing.unit * 2}px`,
    },
    cardActions: {
        width: '100%',
        marginLeft: -20,
        marginTop: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        zIndex: 1,
    },
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        marginRight: 5,
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
    },
    menuText: {
        fontSize: 13,
        fontWeight: 500,
    },
    rootCheckbox: {
        padding: 0,
        // @ts-ignore
        marginRight: theme.spacing.unit,
    },
    multiSelectLabel: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
        color: theme.palette.primary.main,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 10,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
    },
}));

interface Props {
    open: boolean;
    url: string;
    onClose: any;
    name: string;
}

const QrCodeDialog = ({ open, url, onClose, name }: Props) => {
    const classes: any = useStyles();
    const translate = useTranslate();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.paper,
            }}
            scroll='paper'
            maxWidth={'xl'}
        >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div className={classes.container}>
                    {/*<SvgIcon component={QrIconSvg} color={'primary'} />*/}
                    <CropFreeIcon />
                    <Typography color={'primary'} className={classes.dialogTitleText} data-test-sc={'titleText'}>
                        {translate('app.qrCode')}
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <QrCode
                    name={name}
                    url={url}
                    showFormatOptionButtons={true}
                    showSizeOptionButtons={true}
                    showText={true}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} variant='contained' color='primary' autoFocus className={classes.button}>
                    {translate('app.buttons.return')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QrCodeDialog;
