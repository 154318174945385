import { useSafeSetState } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {resolveApiSchemaCreator} from "../redux/actions/hydraDataProvider";
import {CustomReducerState} from "../redux/reducers";

const useApiSchema = () => {
	const dispatch = useDispatch();
	const apiSchema = useSelector((state: CustomReducerState) => state.ticketing.hydraDataProvider.apiSchema);
	const [loading, setLoading] = useSafeSetState(apiSchema === null)

	useEffect(() => {
		if (!apiSchema) {
			dispatch(resolveApiSchemaCreator.fn());
		}
		else {
			setLoading(false);
		}
	}, [apiSchema])

	return loading
}

export default useApiSchema;