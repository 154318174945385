import {
    TICKETING_MANAGER_TAG,
    TICKETING_SUB_MANAGER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    USER_MANAGER_CATEGORY,
    USER_PROVIDER_CATEGORY,
    USER_SUB_MANAGER_CATEGORY,
} from '../CONST';
import { User, UserCategory } from '../types';

const POSSIBLE_USER_TAGS_TRANSLATIONS: { [key: string]: string } = {
    [TICKETING_USER_SERVICE_PROVIDER_TAG]: 'app.users.serviceProviderPerson',
    [TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG]: 'app.users.serviceProviderCompany',
    [TICKETING_SUB_MANAGER_TAG]: 'app.users.subManager.label',
    [TICKETING_MANAGER_TAG]: 'app.users.manager.label',
};

const resolverUserTagNameTranslations = (tags: string[]): string | null => {
    const tagsPossibles = Object.keys(POSSIBLE_USER_TAGS_TRANSLATIONS);
    const tagFound: string | undefined = tags.find((tag) => tagsPossibles.includes(tag));
    return tagFound ? POSSIBLE_USER_TAGS_TRANSLATIONS[tagFound] : null;
};

const isUserAServiceProvider = (tags?: string[]): boolean => {
    if (!tags) return false;
    return (
        tags.includes(TICKETING_USER_SERVICE_PROVIDER_TAG) || tags.includes(TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG)
    );
};

const getUserCategory = (user: User | null): UserCategory | null => {
    if (!user || !user?.tags) return null;

    if (isUserAServiceProvider(user.tags)) {
        return USER_PROVIDER_CATEGORY;
    } else if (user.tags?.includes(TICKETING_SUB_MANAGER_TAG)) {
        return USER_SUB_MANAGER_CATEGORY;
    } else if (user.tags?.includes(TICKETING_MANAGER_TAG)) {
        return USER_MANAGER_CATEGORY;
    }
    return null;
};

export { getUserCategory, isUserAServiceProvider, resolverUserTagNameTranslations };
