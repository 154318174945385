import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { List } from 'react-admin';
import { navigation } from '../../../pages/paths';
import { AVATARS } from '../../../providers/resources';
import NavigationListActions from '../actions';
import AddItemDialog from '../actions/AddItemDialog/AddItemDialog';
import NavigationSideBar from '../aside';
import CustomBreadcrumbs from '../CustomBreadcrumbs/CustomBreadcrumbs';
import NavigationDatagrid from '../datagrid';
import './styles.css';

const useStyles = (divHeight: number) =>
    makeStyles({
        main: {
            height: divHeight,
        },
        content: {
            // marginTop: 48,
            overflow: 'auto',
        },
    });

interface Props {
    divHeight: number;
    isEmpty: boolean;
}

const NavigationList = ({ divHeight, isEmpty }: Props) => {
    const classes = useStyles(divHeight - 40)();

    const [openAddItemDialog, setOpenAddItemDialog] = useState<boolean>(isEmpty);

    const listProps = {
        resource: AVATARS,
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        basePath: navigation,
        sort: { field: 'lft', order: 'ASC' },
        perPage: 25,
        filter: {
            serialization_groups: [
                'read_meta',
                'read_mc',
                'read_simple_company',
                'read_avatar_current_referencing',
                'read_avatar_current_referencing_nodes',
                'read_meta_group_meta',
                'read_meta_files',
                'link',
                'read_avatar_parent'
            ],
        },
        classes: {
            main: classes.main,
            content: classes.content,
        },
    };

    const onCloseAddItemDialog = () => {
        setOpenAddItemDialog(false);
    };

    return (
        <div className='navigationTable' style={{ height: divHeight }}>
            {/*<CustomBreadcrumbs />*/}

            <List
                {...listProps}
                aside={<NavigationSideBar divHeight={divHeight} />}
                actions={<NavigationListActions />}
                bulkActionButtons={false}
                empty={
                    <AddItemDialog
                        fromEmptyList={true}
                        open={openAddItemDialog}
                        onClose={onCloseAddItemDialog}
                        onCancel={onCloseAddItemDialog}
                    />
                }
            >
                <NavigationDatagrid />
            </List>
        </div>
    );
};

export default NavigationList;
