import React, { Children, cloneElement } from 'react';
import { Record, useListContext, RecordContextProvider, useResourceContext, Identifier } from 'react-admin';

interface SingleFieldListProps {
    children?: React.ReactElement;
    resource: string;
    data?: {
        [key: Identifier]: Record;
    };
}

export interface RecordAware extends Record {
    _metadata_: {
        index: number;
    };
}

const SingleFieldList = (props: SingleFieldListProps) => {
    const { data } = useListContext(props);
    const resource = useResourceContext(props);

    return (
        <>
            {/*// @ts-ignore*/}
            {Object.values(data).map((record: Record, index: number) => (
                <RecordContextProvider value={record} key={record.id}>
                    {props.children &&
                        cloneElement(Children.only(props.children), {
                            record: {
                                ...record,
                                _metadata_: {
                                    index,
                                },
                            },
                            resource,
                        })}
                </RecordContextProvider>
            ))}
        </>
    );
};

export default SingleFieldList;
