import React, { useEffect, useState } from 'react';
import { Avatar, PlaceOrEventPath } from '../../../utils/types';
import { useNotify } from 'react-admin';
import extractIdFromURI from '../../../utils/id';
import { TICKETING_PLACE_OR_EVENT } from '../../../providers/resources';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';
import { getPlaceOrEventPath } from '../../../utils/placeOrEvent';
import { registerPlacesOrEventsPlacePath } from '../../../redux/actions/placesOrEvents';
import { makeStyles } from '@material-ui/core/styles';
import { FieldHeaderListStyles } from '../../../theme';
import { Typography } from '@material-ui/core';

interface Props {
    event?: Avatar;
    inline?: boolean;
}
const PATCH_SEPARATOR = ' / ';

const useStyles = makeStyles({
    inlineText: {
        display: 'grid',
        inlineSize: 'max-content',
    },
});

const PlaceOrEventNavigationField = (props: Props) => {
    const classes = useStyles();

    const [navigation, setNavigation] = useState<string | undefined>();
    const notify = useNotify();
    const tokenWithRoles = useSelector((state: CustomReducerState) => state.ticketing.user.tokenData?.tokenWithRoles);

    const data = useSelector((state: CustomReducerState) =>
        props.event && props.event?.id in state.ticketing.placesOrEventsPath
            ? state.ticketing.placesOrEventsPath[props.event.id]
            : []
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.event) {
            getPlaceOrEventPath(
                (dataJson: any) => {
                    if (dataJson && Array.isArray(dataJson) && dataJson.length > 0) {
                        // setData(dataJson as PlaceOrEventPath[]);
                        props.event &&
                            dispatch(
                                registerPlacesOrEventsPlacePath.fn(dataJson as PlaceOrEventPath[], props.event.id)
                            );
                    }
                },
                (error: any) => {
                    console.error(
                        'Getting ',
                        `api/${TICKETING_PLACE_OR_EVENT}/${extractIdFromURI(props.event?.id as string)}/path`,
                        error
                    );
                    notify('app.incidents.notification.pathError', 'error', { name: props.event?.name });
                },
                extractIdFromURI(props.event?.id as string),
                tokenWithRoles
            ).catch((error: any) => {
                console.error('Getting ', TICKETING_PLACE_OR_EVENT, 'count', error);
            });
        }
    }, []);

    const formatNavigationPath = () => {
        let path = '';
        data?.reverse().forEach((datum, index) => {
            if (index === 0) {
                path = capitalizeFirstLetter(datum.name);
            } else if (index === data.length - 1) {
                path = `${path}`;
            } else {
                path = `${path} ${PATCH_SEPARATOR} ${capitalizeFirstLetter(datum.name)}`;
            }
        });
        setNavigation(path);
    };

    useEffect(() => {
        formatNavigationPath();
    }, [data]);

    return (
        <>
            {!navigation && <span>{'...'}</span>}
            {/*{navigation && <span> {navigation}</span>}*/}
            {navigation &&
                !props.inline &&
                navigation.split(PATCH_SEPARATOR).map((nav, index) => (
                    <span key={`span_${nav}_${index}`} className={classes.inlineText}>
                        {nav}
                        <br />
                    </span>
                ))}
            {navigation && props.inline && (
                <Typography noWrap variant={'body2'}>
                    {navigation}
                </Typography>
            )}
        </>
    );
};

export default PlaceOrEventNavigationField;
