import { styled } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Avatar } from '../../../../../utils/types';
import InformationContact from './InformationContact';

const CustomDiv = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto auto',
});

interface Props {
    item: Avatar;
}

const Information: FC<Props> = ({ item }: Props) => {
    return (
        <CustomDiv>
            <InformationContact item={item} />
        </CustomDiv>
    );
};

export default Information;
