import AddIcon from '@material-ui/icons/Add';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { Record, ReduxState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import MenuButtonActions, { MenuItemType } from '../../../commons/menuButtonActions';
import { CustomReducerState } from '../../../redux/reducers';
import CreateForm from './create';
import { getUserSelected } from '../../../redux/reducers/user';
import { User } from '../../../utils/types';
import EditForm from './edit';
import DeleteForm from './delete';
import {
    ROLE_CREATE_USER,
    ROLE_DELETE_USER,
    ROLE_READ_PROFILE,
    ROLE_READ_USER,
    ROLE_UPDATE_USER,
} from '../../../utils/ROLES';
import { redirectToUserPage } from '../../../utils/redirectTo360scBackoffice';
import extractIdFromURI, { getUriFromItem } from '../../../utils/id';

const SimpleActionMenu = () => {
    const translate = useTranslate();
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);
    const [menuState, setMenuState] = useState(false);

    const selected: User | undefined = useSelector((state: CustomReducerState & ReduxState) => getUserSelected(state));
    const userConnected: User | Record | null = useSelector(
        (state: CustomReducerState) => state.ticketing.user.userConnected
    );

    const showCreateFormFn = () => {
        setShowCreateForm(true);
    };

    const showEditFormFn = () => {
        setShowEditForm(true);
    };

    const showDeleteFormFn = () => {
        setShowDeleteForm(true);
    };

    const redirectToUsersPageIn360scBackoffice = () => {
        userConnected && redirectToUserPage(extractIdFromURI(getUriFromItem(userConnected?.company) as string));
    };

    const menuActions: MenuItemType[] = [
        {
            icon: <AddIcon />,
            text: translate('app.menuActions.create'),
            onClick: showCreateFormFn,
            disabled: false,
            roles: [ROLE_CREATE_USER, ROLE_READ_PROFILE],
        },
        {
            icon: <BorderColorIcon />,
            text: translate('app.menuActions.edit'),
            onClick: showEditFormFn,
            disabled: !selected,
            roles: [ROLE_UPDATE_USER, ROLE_READ_USER, ROLE_READ_PROFILE],
        },
        {
            icon: <BorderColorIcon />,
            text: translate('app.users.changePassword'),
            onClick: redirectToUsersPageIn360scBackoffice,
            disabled: !selected,
            roles: [ROLE_UPDATE_USER],
        },
        {
            icon: <DeleteIcon />,
            text: translate('app.menuActions.delete'),
            onClick: showDeleteFormFn,
            disabled: !selected,
            roles: [ROLE_DELETE_USER],
        },
    ];

    const handleToggle = () => {
        setMenuState(!menuState);
    };

    return (
        <div>
            <MenuButtonActions
                buttonLabel={translate('app.menuActions.title')}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />

            {showCreateForm && <CreateForm open={true} handleClose={() => setShowCreateForm(false)} />}

            {showEditForm && selected && (
                <EditForm selected={selected} open={true} handleClose={() => setShowEditForm(false)} />
            )}

            {showDeleteForm && selected && (
                <DeleteForm selected={selected} open={true} handleClose={() => setShowDeleteForm(false)} />
            )}
        </div>
    );
};

export default SimpleActionMenu;
