import {
    getTicketingField,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../utils/user/customFields';
import {
    TICKETING_USER_SERVICE_PROVIDER_COMPANY_TAG,
    TICKETING_USER_SERVICE_PROVIDER_TAG,
    TICKETING_USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG,
} from '../../../utils/CONST';

export const USER_MANAGER_FIELD = 'user_manager';
export const USER_SUB_MANAGER_FIELD = 'user_sub_manager';
export const USER_SERVICE_PROVIDER_FIELD = 'user_service_provider';
export const USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD = 'is_user_service_provider_under_contract';

export const USER_USER_NAME_FIELD = 'username';
export const USER_FIRST_NAME_FIELD = 'firstName';
export const USER_LAST_NAME_FIELD = 'lastName';
export const USER_EMAIL_FIELD = 'email';
export const USER_PHONE_FIELD = 'mobile';
export const USER_SERVICE_TYPE_FIELD = 'serviceType';
export const USER_TYPE_FIELD = 'userType';
export const ACCESS_TO_SERVICE_FIELD = 'accessToService';

export const USER_SERVICE_PROVIDER_COMPANY_TYPE_FIELD = 'user_service_provider_company';
export const USER_SERVICE_PROVIDER_PERSON_TYPE_FIELD = 'user_service_provider_person';
export const USER_SERVICE_PROVIDER_TYPE_FIELD = 'userServiceType';

export const JEFFREY_MANAGER_SUB_ADMIN = 'JEFFREY_MANAGER_SUB_ADMIN';
export const JEFFREY_MANAGER_SERVICE_PROVIDER = 'JEFFREY_MANAGER_SERVICE_PROVIDER';
export const JEFFREY_MANAGER_CLIENT_ADMIN = 'JEFFREY_MANAGER_CLIENT_ADMIN';

export const JEFFREY_USER_INTERNAL_NAMES_MAPPER = {
    manager: JEFFREY_MANAGER_CLIENT_ADMIN,
    subManager: JEFFREY_MANAGER_SUB_ADMIN,
    serviceProvider: JEFFREY_MANAGER_SERVICE_PROVIDER,
};

//@ts-ignore
export const userTypeInteralNameProfileMapper: { [key: string]: string } = {
    [USER_SERVICE_PROVIDER_FIELD]: JEFFREY_USER_INTERNAL_NAMES_MAPPER['serviceProvider'],
    [USER_MANAGER_FIELD]: JEFFREY_USER_INTERNAL_NAMES_MAPPER['manager'],
    [USER_SUB_MANAGER_FIELD]: JEFFREY_USER_INTERNAL_NAMES_MAPPER['subManager'],
};

export interface FormCreateUserData {
    [TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD]?: string;
    [TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD]?: string;
    [USER_TYPE_FIELD]: string;
    [USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD]?: boolean;
    [USER_SERVICE_PROVIDER_TYPE_FIELD]: string;
    [ACCESS_TO_SERVICE_FIELD]?: string;
}

export interface FormEditUserData {
    [TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD]?: string;
    [TICKETING_SERVICE_PROVIDER_REFERENCE_CUSTOM_FIELD]?: string;
    [USER_TYPE_FIELD]: string;
    [USER_IS_SERVICE_PROVIDER_UNDER_CONTRACT_FIELD]?: boolean;
    [ACCESS_TO_SERVICE_FIELD]?: string;
    [USER_SERVICE_PROVIDER_TYPE_FIELD]: string;
    [USER_FIRST_NAME_FIELD]: string;
    [USER_LAST_NAME_FIELD]: string;
    [USER_USER_NAME_FIELD]: string;
    [USER_EMAIL_FIELD]: string;
    [USER_PHONE_FIELD]: string;
}
