import { takeEvery } from 'redux-saga/effects';
import {
    changeKanbanListOrder,
    ChangeKanbanListOrderAction,
    changeViewList,
    ChangeViewListAction,
    RegisterCustomizeColumnsInListView,
    registerCustomizeColumnsIncidentsInListView,
    RegisterCustomizeColumnsInKanbanListView,
    registerCustomizeColumnsIncidentsInKanbanListView,
} from '../../actions/incidents';
import { getTokenData } from '../../../utils/token';

const saveChangeViewListIncidents = () =>
    function* (action: ChangeViewListAction) {
        // @ts-ignore
        const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        const tokenDecoded = token ? getTokenData(token) : null;
        // @ts-ignore
        localStorage.setItem(
            `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_VIEW_LIST}_${tokenDecoded?.user_id}`,
            action.payload.viewMode
        );
        yield null;
    };

const saveChangeKanbanViewListOrderIncidents = () =>
    function* (action: ChangeKanbanListOrderAction) {
        // @ts-ignore
        const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        const tokenDecoded = token ? getTokenData(token) : null;

        localStorage.setItem(
            // @ts-ignore
            `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_KANBAN_VIEW_LIST_ORDER}_${tokenDecoded?.user_id}`,
            JSON.stringify(action.payload.itemsOrdered)
        );
        yield null;
    };

const saveCustomizeIncidentsColumnsEnabledInListViewMode = () =>
    function* (action: RegisterCustomizeColumnsInListView) {
        // @ts-ignore
        const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        const tokenDecoded = token ? getTokenData(token) : null;

        localStorage.setItem(
            // @ts-ignore
            `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_LIST_VIEW_COLUMNS_ENABLED}_${tokenDecoded?.user_id}`,
            JSON.stringify(action.payload.cols)
        );
        yield null;
    };

const saveCustomizeIncidentsColumnsEnabledInKanbanListViewMode = () =>
    function* (action: RegisterCustomizeColumnsInKanbanListView) {
        // @ts-ignore
        const token = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        const tokenDecoded = token ? getTokenData(token) : null;

        localStorage.setItem(
            // @ts-ignore
            `${process.env.REACT_APP_LOCALSTORAGE_INCIDENT_KANBAN_VIEW_COLUMNS_ENABLED}_${tokenDecoded?.user_id}`,
            JSON.stringify(action.payload.cols)
        );
        yield null;
    };

export default () => {
    return function* () {
        yield takeEvery(changeViewList.action, saveChangeViewListIncidents());
        yield takeEvery(changeKanbanListOrder.action, saveChangeKanbanViewListOrderIncidents());
        yield takeEvery(
            registerCustomizeColumnsIncidentsInListView.action,
            saveCustomizeIncidentsColumnsEnabledInListViewMode()
        );
        yield takeEvery(
            registerCustomizeColumnsIncidentsInKanbanListView.action,
            saveCustomizeIncidentsColumnsEnabledInKanbanListViewMode()
        );
    };
};
