import { GroupMetadataAvatarType, MetadataAvatarType } from '../types';

export const getNameWithoutPrefix = (nameWithPrefix: string) => {
    if (nameWithPrefix) {
        const r = nameWithPrefix.split('-prefix-');
        if (Array.isArray(r) && r.length > 1) {
            return r[0];
        }
    }
    return nameWithPrefix;
};

export const getNameWithoutPrefixFromJson = (item: GroupMetadataAvatarType | MetadataAvatarType) =>
    getNameWithoutPrefix(item.name);
