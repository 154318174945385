import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Identifier, ImageField, Record, usePermissions } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { resolveMedia as resolveMediaAction } from '../../../redux/actions/medias';
import { CustomReducerState } from '../../../redux/reducers';
import { MediaStateProp } from '../../../redux/reducers/medias';
import { ImageStyles } from '../../../theme';
import extractIdFromURI from '../../../utils/id';
import { getDownloadFileSystemMapperUrl } from '../../../utils/medias';

interface MediaFieldProps {
    imageId: string;
    resourceId: Identifier;
    tags: string[];
    imageFieldClassOverride?: any;
}

const useStyles = makeStyles({
    image: {
        ...ImageStyles,
    },
    imageField: {
        height: 25,
        width: 25,
        margin: '0 0 -5px 0',
    },
    linearProgress: {
        height: 3,
        width: 20,
        margin: 0,
    },
});

const MediaField = ({ imageId, resourceId, tags, imageFieldClassOverride }: MediaFieldProps) => {
    const classes = useStyles();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [loadImage, setLoadImage] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const currentMediaByImageId: MediaStateProp | undefined = useSelector((state: CustomReducerState) =>
        imageId in state.ticketing.medias ? state.ticketing.medias[imageId as string] : undefined
    );

    const PreviewImage = ({ record, source }: { record?: Record; source: string }) => {
        return (
            <ImageField
                emptyText={''}
                record={record}
                source={source}
                classes={{ image: !imageFieldClassOverride ? classes.imageField : imageFieldClassOverride }}
            />
        );
    };

    useEffect(() => {
        if (!currentMediaByImageId) {
            setLoadImage(true);
            const recordId = extractIdFromURI(resourceId as string);
            recordId && dispatch(resolveMediaAction.fn(imageId, recordId));
        } else {
            if (currentMediaByImageId.media && currentMediaByImageId.fileSystemMapper) {
                setImageSrc(
                    getDownloadFileSystemMapperUrl(currentMediaByImageId.media, currentMediaByImageId.fileSystemMapper)
                );
            }
            setLoadImage(false);
        }
    }, [resourceId]);

    useEffect(() => {
        if (currentMediaByImageId && currentMediaByImageId.fileSystemMapper && currentMediaByImageId.media) {
            setImageSrc(
                getDownloadFileSystemMapperUrl(currentMediaByImageId.media, currentMediaByImageId.fileSystemMapper)
            );
            loadImage && setLoadImage(false);
        }
    }, [currentMediaByImageId]);

    return (
        <>
            {imageSrc && <PreviewImage source={'url'} record={{ id: resourceId, url: imageSrc }} />}
            {!imageSrc && !loadImage && <img src={'/tmp-image.png'} className={classes.image} alt='' />}
            {loadImage && <LinearProgress className={classes.linearProgress} />}
        </>
    );
};

export default MediaField;
