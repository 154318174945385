import * as _ from 'lodash';
import { Identifier, ReduxState } from 'react-admin';
import { AVATARS } from '../../providers/resources';
import { Avatar, NavigationConfiguration } from '../../utils/types';
import {
    ACTIONS,
    SelectItemListAction,
    selectItemListActionCreator,
    SelectTreeLevelItemAction,
    selectTreeLevelItemCreator,
    SelectTreeLevelZeroItemsAction,
    selectTreeLevelZeroItemsCreator,
    SetCloneActionToStateAction,
    setCloneActionToStateActionCreator,
    SetCloneItemToAction,
    setCloneItemToActionCreator,
    SetConfigurationAction,
    setConfigurationCreator,
    SetItemToCloneAction,
    setItemToCloneActionCreator,
    SetItemToMoveAction,
    setItemToMoveActionCreator,
    setItemToReorderActionCreator,
    SetLoadedLevelAction,
    setLoadedLevelCreator,
    SetLoadedLevelItemAction,
    setLoadedLevelItemCreator,
    SetMoveActionToStateAction,
    setMoveActionToStateActionCreator,
    SetMoveItemToAction,
    setMoveItemToActionCreator,
    SetReorderActionToStateAction,
    setReorderActionToStateActionCreator,
    setReorderItemToActionCreator,
    updateTreeVersionCreator,
} from '../actions/navigation';
import { CustomReducerState } from './index';
import { clearTicketingStateCreator } from '../actions/ticketingState';

type AvatarIdentifier = Identifier;

interface AvatarWithChildren extends Avatar {
    children: Avatar[];
}

export interface NavigationState {
    tree: {
        version: number;
        levelItemSelected: Identifier | null;
        levelZeroItems: Avatar[];
        isLevelLoaded: {
            [key: AvatarIdentifier]: boolean;
        };
        levelLoadedItems: {
            // parentIdentifier: Children
            [key: AvatarIdentifier]: AvatarWithChildren;
        };
        avatarMapper: {
            [key: AvatarIdentifier]: Avatar;
        };
        configuration: NavigationConfiguration;
        editedItem: {
            parentItemIdentifier: Identifier | null; // When parent's children are changed
            itemIdentifier: Identifier | null; // When a level's properties are changed
        };
    };
    itemListSelected: {
        [key: AvatarIdentifier]: Avatar;
    };
    actions: {
        moveTo: {
            active: boolean;
            item: Avatar | null;
            to: Avatar | null;
        };
        reorder: {
            active: boolean;
            item: Avatar | null;
            to: Avatar | null;
        };
        clone: {
            active: boolean;
            item: Avatar | null;
            to: Avatar | null;
        };
    };
}

const InitialState: NavigationState = {
    tree: {
        version: 0,
        levelItemSelected: null,
        levelZeroItems: [],
        isLevelLoaded: {},
        levelLoadedItems: {},
        avatarMapper: {},
        configuration: {
            options: {
                enabled: true,
            },
        },
        editedItem: {
            itemIdentifier: null,
            parentItemIdentifier: null,
        },
    },
    itemListSelected: {},
    actions: {
        moveTo: {
            active: false,
            item: null,
            to: null,
        },
        reorder: {
            active: false,
            item: null,
            to: null,
        },
        clone: {
            active: false,
            item: null,
            to: null,
        },
    },
};

export const getItemListSelected = (state: CustomReducerState & ReduxState): Avatar | undefined => {
    const selected = state.ticketing.navigation.itemListSelected;
    const selectedUriKeys = selected ? Object.keys(selected) : [];
    if (selectedUriKeys.length > 0 && Object.keys(state.admin.resources[AVATARS].data).includes(selectedUriKeys[0])) {
        return state.admin.resources[AVATARS].data[selectedUriKeys[0]] as Avatar;
    }
    return undefined;
};

const reducer = (state: NavigationState = InitialState, action: ACTIONS) => {
    switch (action.type) {
        case updateTreeVersionCreator.action: {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    version: state.tree.version + 1,
                },
            };
        }
        case selectTreeLevelItemCreator.action: {
            const levelIdentifier = (action as SelectTreeLevelItemAction).payload.levelIdentifier;
            const clear = (action as SelectTreeLevelItemAction).payload.clear;

            if (levelIdentifier !== state.tree.levelItemSelected && !clear) {
                return {
                    ...state,
                    tree: {
                        ...state.tree,
                        levelItemSelected: (action as SelectTreeLevelItemAction).payload.levelIdentifier,
                    },
                };
            }

            if (clear) {
                return {
                    ...state,
                    tree: {
                        ...state.tree,
                        levelItemSelected: null,
                    },
                };
            }

            return state;
        }
        case selectTreeLevelZeroItemsCreator.action: {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    levelZeroItems: (action as SelectTreeLevelZeroItemsAction).payload.items,
                    avatarMapper: {
                        ...state.tree.avatarMapper,
                        ...(action as SelectTreeLevelZeroItemsAction).payload.items.reduce((acc, avatar: Avatar) => {
                            return {
                                ...acc,
                                [avatar['@id']]: avatar,
                            };
                        }, {}),
                    },
                },
            };
        }
        case setLoadedLevelCreator.action: {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    isLevelLoaded: {
                        ...state.tree.isLevelLoaded,
                        [(action as SetLoadedLevelAction).payload.identifier]: (action as SetLoadedLevelAction).payload
                            .loaded,
                    },
                },
            };
        }
        case setLoadedLevelItemCreator.action: {
            const {
                payload: { identifier, children },
            } = action as SetLoadedLevelItemAction;

            return {
                ...state,
                tree: {
                    ...state.tree,
                    levelLoadedItems: {
                        ...state.tree.levelLoadedItems,
                        [identifier]: {
                            ...state.tree.avatarMapper[identifier],
                            children,
                        },
                    },
                    isLevelLoaded: {
                        ...state.tree.isLevelLoaded,
                        [identifier]: true,
                    },
                    avatarMapper: {
                        ...state.tree.avatarMapper,
                        ...children.reduce((acc, avatar: Avatar) => {
                            return {
                                ...acc,
                                [avatar['@id']]: avatar,
                            };
                        }, {}),
                    },
                },
            };
        }
        case setMoveActionToStateActionCreator.action: {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    moveTo: {
                        ...state.actions.moveTo,
                        active: (action as SetMoveActionToStateAction).payload.toState,
                    },
                },
            };
        }
        case setItemToMoveActionCreator.action: {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    moveTo: {
                        ...state.actions.moveTo,
                        item: (action as SetItemToMoveAction).payload.item,
                    },
                },
            };
        }
        case setMoveItemToActionCreator.action: {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    moveTo: {
                        ...state.actions.moveTo,
                        to: (action as SetMoveItemToAction).payload.item,
                    },
                },
            };
        }
        case selectItemListActionCreator.action: {
            const currentAction = action as SelectItemListAction;
            return {
                ...state,
                itemListSelected: currentAction.payload.item
                    ? {
                          [currentAction.payload.item.id]: currentAction.payload.item,
                      }
                    : {},
            };
        }
        case setConfigurationCreator.action: {
            const currentAction: SetConfigurationAction = action as SetConfigurationAction;

            if (!currentAction.payload.clear) {
                return {
                    ...state,
                    tree: {
                        ...state.tree,
                        configuration: {
                            ...currentAction.payload.configuration,
                        },
                    },
                };
            }

            return {
                ...state,
                tree: {
                    ...state.tree,
                    configuration: {
                        ...InitialState.tree.configuration,
                    },
                },
            };
        }
        case setReorderActionToStateActionCreator.action: {
            return _.merge(state, {
                actions: {
                    reorder: {
                        active: (action as SetCloneActionToStateAction).payload.toState,
                    },
                },
            });
        }
        case setItemToReorderActionCreator.action: {
            return _.merge(state, {
                actions: {
                    reorder: {
                        item: (action as SetItemToMoveAction).payload.item,
                    },
                },
            });
        }
        case setReorderItemToActionCreator.action: {
            return _.merge(state, {
                actions: {
                    reorder: {
                        to: (action as SetItemToMoveAction).payload.item,
                    },
                },
            });
        }
        case setCloneActionToStateActionCreator.action: {
            return _.merge(state, {
                actions: {
                    clone: {
                        active: (action as SetReorderActionToStateAction).payload.toState,
                    },
                },
            });
        }
        case setItemToCloneActionCreator.action: {
            return _.merge(state, {
                actions: {
                    clone: {
                        item: (action as SetItemToCloneAction).payload.item,
                    },
                },
            });
        }
        case setCloneItemToActionCreator.action: {
            return _.merge(state, {
                actions: {
                    clone: {
                        to: (action as SetCloneItemToAction).payload.item,
                    },
                },
            });
        }
        case clearTicketingStateCreator.action: {
            return {
                ...InitialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
