import React from 'react';
import { USERS } from '../../../providers/resources';
import { TICKETING_USER_SERVICE_PROVIDER_TAG } from '../../../utils/CONST';
import { AutocompleteInput } from '../../../commons/ra/input/AutocompleteInput';
import ReferenceInput from '../../../commons/ra/input/ReferenceInput';
import { User } from '../../../utils/types';
import {
    getTicketingField,
    TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD,
    TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD,
} from '../../../utils/user/customFields';

interface Props {
    label: string;
    alwaysOn: boolean;
    disabled?: boolean;
    emptyText?: string;
}

const ServiceProviderReferenceInputFilter = ({ label, alwaysOn, emptyText, disabled = false }: Props) => {
    const getServiceType = (user: User) => {
        const value = getTicketingField(
            user,
            `${TICKETING_SERVICE_PROVIDER_CUSTOM_FIELD}.${TICKETING_SERVICE_PROVIDER_SERVICE_TYPE_CUSTOM_FIELD}`
        );
        return value ? `(${value})` : '';
    };

    const renderOptionText = (user: User | undefined) => {
        return user ? `${user?.username} ${getServiceType(user)}` : emptyText ? emptyText : '';
    };

    return (
        <ReferenceInput
            disabled={disabled}
            alwaysOn={alwaysOn}
            variant={'standard'}
            label={label}
            filterToQuery={(searchText: string) => ({
                lastName: searchText,
            })}
            filter={{
                'tags[one]': TICKETING_USER_SERVICE_PROVIDER_TAG,
            }}
            reference={USERS}
            source={'ticketing_user_provider__user_id'}
        >
            <AutocompleteInput
                resettable
                translateChoice={false}
                fullWidth
                emptyValue={null}
                optionText={renderOptionText}
                optionValue='id'
                filterToQuery={(searchText: string) => ({
                    lastName: searchText,
                })}
            />
        </ReferenceInput>
    );
};
export default ServiceProviderReferenceInputFilter;
