export const EVENT_TYPE_CUSTOM_PROCESS_ENABLED = 'EVENT_TYPE_CUSTOM_PROCESS_ENABLED';
export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT';
export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE';

export const eventTypeCustomProcessForceTextLanguages = {
    en: 'Comment Required',
    fr: 'Commentaire requis',
    es: 'Comentario requerido',
};

export const eventTypeCustomProcessForceImageLanguages = {
    en: 'Image required',
    fr: 'Image requis',
    es: 'Imagen requerida',
};

export const eventTypeCustomProcessFileSystemMapperEnabledLanguages = {
    en: 'File system mapper enabled',
    fr: 'Système de fichiers active',
    es: 'Sistema de ficheros activo',
};
