import React from 'react';
import { styled } from '@material-ui/core/styles';
//@ts-ignore
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StateListItemDragged from './StateListItemDragged';
import ParentListContext from './ParentListContext';

const Container = styled('div')({
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
});

interface Props {
    index: number;
    state: string;
    defaultFilters: any;
}

const ListColumn = (props: Props) => {
    const { index, state } = props;

    return (
        <Draggable draggableId={state} index={index}>
            {(provided: any, snapshot: any) => {
                return (
                    <Container ref={provided.innerRef} {...provided.draggableProps}>
                        <ParentListContext>
                            <StateListItemDragged
                                dragHandleProps={{ ...provided.dragHandleProps }}
                                status={state}
                                filter={{
                                    ...props.defaultFilters,
                                    'currentNode.name': state,
                                }}
                            />
                        </ParentListContext>
                    </Container>
                );
            }}
        </Draggable>
    );
};
export default ListColumn;
