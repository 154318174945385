import { FormControlLabel } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { styled, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import {
    CreateResult,
    GetListResult,
    Record,
    useDataProvider,
    useNotify,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import DialogTemplate from '../../../../commons/Dialog/DialogTemplate/DialogTemplate';
import { AVATAR_PROPERTY, TICKETING_PLACE_OR_EVENT_ROOT_EVENT } from '../../../../providers/resources';
import { updateTreeVersionCreator } from '../../../../redux/actions/navigation';
import { DIVIDER_COLOR } from '../../../../theme';
import { extractError } from '../../../../utils/api';
import {
    PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION,
    PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION,
} from '../../../../utils/CONST';
import { hasPermissions } from '../../../../utils/permissions';
import {
    ROLE_CREATE_AVATAR_PROPERTY,
    ROLE_CREATE_TICKETING_PROCESS_CUSTOMIZATION,
    ROLE_READ_AVATAR_PROPERTY,
    ROLE_READ_FINGER,
    ROLE_READ_OBJECT_TYPE,
    ROLE_READ_OBJECT_TYPE_PROPERTIES,
    ROLE_READ_TICKETING_MANAGER_NOTIFICATION,
    ROLE_READ_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES,
    ROLE_READ_TRACEABILITY_RUNNER_TEMPLATE,
    ROLE_TICKETING_CREATE_ROOT_PLACE_OR_EVENT,
    ROLE_UPDATE_AVATAR,
    ROLE_UPDATE_AVATAR_PROPERTY,
    ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION,
    ROLE_UPDATE_TICKETING_PROCESS_CUSTOMIZATION,
} from '../../../../utils/ROLES';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { Avatar } from '../../../../utils/types';
import ButtonsBlockForm from '../../../forms/buttonsBlock';
import FileManager from './fileManager';
import IncidentConfiguration from './incident';
import Information from './information';
import Location from './location';
import NotifyIncidentsToUsers from './notifyIncidentsToUsers';
import ProcessConfiguration from './process';
import ServiceProviderConfiguration from './serviceProvider';
import { useQueryClient } from '@tanstack/react-query';
import extractIdFromURI from '../../../../utils/id';
import useGetList from '../../../../commons/react-query-hooks/useGetList';
import ShowMediaResolver from '../../../../commons/fields/showMediaResolver/showMediaResolver';

const GridDivCenterV = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
});

const GridBottomDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: '350px auto',
    alignItems: 'flex-start',
    marginTop: 20,
});

const useStyles = makeStyles({
    root: {
        padding: 20,
    },
    menuItemGrid: {},
    dialogPaperRoot: {
        minWidth: 1000,
    },
    menuList: {
        border: `1px solid ${DIVIDER_COLOR}`,
        paddingTop: 0,
        paddingBottom: 0,
    },
    gridButtonBlock: {
        paddingTop: 20,
    },
    image: {
        marginRight: 5,
        height: 23,
        width: 23,
    },
    itemName: {
        fontSize: 14,
    },
    switchLabel: {
        fontSize: 13,
        fontWeight: 700,
    },
    metadataAvatarTypeImageField: {
        marginRight: 5,
    },
});

interface ItemActionDialogProps {
    open: boolean;
    handleClose: () => void;
    item: Avatar;
}

interface LeftMenuItem {
    label: string;
    action: () => void;
    enable: boolean;
    show: boolean;
    onlyRoot: boolean;
    roles: string[];
}

const regex = /\((\w+)-(\w+)\)$/;

const StyledBadge = withStyles((theme) => ({
    badge: {
        fontSize: 10,
        right: -8,
        top: 8,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const ItemActionDialog = ({ item, open, handleClose }: ItemActionDialogProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [currentComponentAction, setCurrentComponentAction] = useState<any>();
    const [leftItemLabelEnabled, setLeftItemLabelEnabled] = useState<string>(
        translate('app.navigation.configuration.itemInformation.label')
    );
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [refreshProperty, setRefreshProperty] = useState<number>(0);
    const queryClient = useQueryClient();

    const [leftItemsMenuAuthorized, setLeftItemsMenuAuthorized] = useState<LeftMenuItem[]>([]);
    const { loaded: loadedPermissions, permissions } = usePermissions();

    const { data: rootAvatarPropertyList } = useGetList(
        AVATAR_PROPERTY,
        {
            pagination: {
                page: 1,
                perPage: 1,
            },
            sort: {
                field: 'id',
                order: 'ASC',
            },
            filter: {
                'avatar.id': item.id,
                definition: PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION,
                name: PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION,
            },
        },
        {
            keyToFind: `navigationIsRoot_${extractIdFromURI(item.id as string)}`,
            enabled: !('parent' in item && item.parent !== null),
        }
    );
    const isRoot = useMemo(
        () =>
            rootAvatarPropertyList && rootAvatarPropertyList.length > 0
                ? JSON.parse(rootAvatarPropertyList[0].value)
                : false,
        [rootAvatarPropertyList]
    );

    const [dialogRef, setDialogRef] = useState(null);

    const definePlaceAsRoot = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (item.root) {
            const checked = event.target.checked;
            dataProvider
                .create(TICKETING_PLACE_OR_EVENT_ROOT_EVENT, {
                    data: JSON.stringify({
                        place: item.id,
                        isRoot: checked,
                    }),
                })
                .then((result: CreateResult<Record>) => {
                    queryClient.invalidateQueries([`navigationIsRoot_${extractIdFromURI(item.id as string)}`]);
                })
                .catch((error) => {
                    if (error.status === 400) {
                        try {
                            const description = extractError(error);
                            const value = description[0]['@value'];
                            const splits = value.match(regex);

                            notify('app.navigation.configuration.notDefinedRoot', 'warning', {
                                place: capitalizeFirstLetter(splits[splits.length - 2]),
                            });
                        } catch (e) {
                            console.error('ERROR defining place as root', e);
                            notify('app.navigation.configuration.rootExisted', 'warning', {
                                place: '',
                            });
                        }
                    }
                });
        }
    };

    useEffect(() => {
        setCurrentComponentAction(<Information item={item} />);
        dataProvider
            .getList(AVATAR_PROPERTY, {
                sort: { field: 'id', order: 'asc' },
                pagination: { page: 1, perPage: 1 },
                filter: {
                    'avatar.id': item.id,
                    definition: PLACE_PROPERTY_TICKETING_SYSTEM_CONFIGURATION,
                    name: PLACE_PROPERTY_ADDRESS_ROOT_TICKETING_SYSTEM_CONFIGURATION,
                    fieldType: 'boolean',
                },
            })
            .then((result: GetListResult<Record>) => {
                if (result && 'data' in result) {
                    dispatch(updateTreeVersionCreator.fn());
                }
            })
            .catch((e) => {
                console.error('avatar-property', e);
                notify('app.avatarProperty.get.error', 'warning', {
                    property_name: translate('app.avatarProperty.addressRoot'),
                });
            });
    }, [refreshProperty]);

    const switchLabel = { inputProps: { 'aria-label': translate('app.navigation.configuration.defineRoot') } };

    const leftItemsMenu: LeftMenuItem[] = [
        {
            label: translate('app.navigation.configuration.itemInformation.label'),
            action: () => setCurrentComponentAction(<Information item={item} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [
                ROLE_UPDATE_AVATAR,
                ROLE_UPDATE_AVATAR_PROPERTY,
                ROLE_CREATE_AVATAR_PROPERTY,
                ROLE_READ_OBJECT_TYPE_PROPERTIES,
            ],
        },
        {
            label: translate('app.navigation.configuration.location.label'),
            action: () => setCurrentComponentAction(<Location item={item} disabled={false} isRoot={isRoot} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [
                ROLE_UPDATE_AVATAR,
                ...(!isRoot
                    ? [ROLE_UPDATE_AVATAR_PROPERTY, ROLE_CREATE_AVATAR_PROPERTY, ROLE_READ_AVATAR_PROPERTY]
                    : []),
            ],
        },
        {
            label: translate('app.navigation.configuration.managerNotification.label'),
            action: () => setCurrentComponentAction(<NotifyIncidentsToUsers place={item} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [
                ROLE_READ_OBJECT_TYPE,
                ROLE_READ_TICKETING_MANAGER_NOTIFICATION,
                ROLE_UPDATE_TICKETING_MANAGER_NOTIFICATION,
            ],
        },
        {
            label: translate('app.navigation.configuration.processResolution'),
            action: () => setCurrentComponentAction(<ProcessConfiguration item={item} />),
            enable: isRoot,
            show: true,
            onlyRoot: true,
            roles: [
                ROLE_CREATE_TICKETING_PROCESS_CUSTOMIZATION,
                ROLE_UPDATE_TICKETING_PROCESS_CUSTOMIZATION,
                ROLE_READ_FINGER,
                ROLE_READ_TRACEABILITY_RUNNER_TEMPLATE,
            ],
        },
        {
            label: translate('app.navigation.configuration.incidentByDefault.label'),
            action: () => setCurrentComponentAction(<IncidentConfiguration item={item} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [ROLE_READ_TICKETING_SUB_CATEGORY_EVENT_TYPES_DEPENDENCIES, ROLE_READ_OBJECT_TYPE],
        },
        {
            label: translate('app.navigation.configuration.serviceProvider.label'),
            action: () => setCurrentComponentAction(<ServiceProviderConfiguration item={item} isRoot={isRoot} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [ROLE_READ_OBJECT_TYPE],
        },
        {
            label: translate('app.navigation.configuration.fileManager.label'),
            action: () => setCurrentComponentAction(<FileManager item={item} />),
            enable: true,
            show: true,
            onlyRoot: false,
            roles: [],
        },
    ];

    useEffect(() => {
        if (loadedPermissions && permissions && Array.isArray(permissions)) {
            setLeftItemsMenuAuthorized([
                ...leftItemsMenu.filter((menuAction) => {
                    return menuAction.roles.every((role) => permissions.includes(role));
                }),
            ]);
        }
    }, [loadedPermissions, isRoot]);

    const clickOnMenuItem = (item: LeftMenuItem) => {
        item.action();
        setLeftItemLabelEnabled(item.label);
    };

    return (
        <DialogTemplate
            title={translate('app.navigation.configuration.title')}
            open={open}
            onClose={handleClose}
            icon={<SettingsIcon />}
            maxWidth={'lg'}
            setRef={setDialogRef}
            ref={dialogRef}
        >
            <Grid container direction={'column'}>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <GridDivCenterV>
                                <ShowMediaResolver
                                    itemId={
                                        item.metadataAvatarType && typeof item.metadataAvatarType === 'object'
                                            ? (item.metadataAvatarType.id as string)
                                            : item.metadataAvatarType
                                    }
                                />
                                <Typography className={classes.itemName}>{capitalizeFirstLetter(item.name)}</Typography>
                            </GridDivCenterV>
                        </Grid>
                        {hasPermissions(
                            [ROLE_TICKETING_CREATE_ROOT_PLACE_OR_EVENT],
                            loadedPermissions,
                            permissions
                        ) && (
                            <Grid item style={{ marginLeft: 30 }}>
                                <FormControlLabel
                                    classes={{ label: classes.switchLabel }}
                                    control={
                                        <Switch
                                            checked={isRoot}
                                            {...switchLabel}
                                            onChange={definePlaceAsRoot}
                                            color={'primary'}
                                        />
                                    }
                                    label={translate('app.navigation.configuration.defineRoot')}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <GridBottomDiv>
                        <div style={{ paddingRight: 20 }}>
                            <MenuList className={classes.menuList}>
                                {leftItemsMenuAuthorized.map((menuItem: LeftMenuItem, index: number) => {
                                    return (
                                        menuItem.show && (
                                            <div key={`left-menu-${index}`}>
                                                <MenuItem
                                                    disabled={!menuItem.enable}
                                                    selected={menuItem.label === leftItemLabelEnabled}
                                                    onClick={() =>
                                                        menuItem.enable ? clickOnMenuItem(menuItem) : () => ({})
                                                    }
                                                >
                                                    {menuItem.onlyRoot ? (
                                                        <StyledBadge
                                                            badgeContent={translate('app.navigation.tree.shortRoot')}
                                                            color='primary'
                                                        >
                                                            <ListItemText>{menuItem.label}</ListItemText>
                                                        </StyledBadge>
                                                    ) : (
                                                        <ListItemText>{menuItem.label}</ListItemText>
                                                    )}
                                                </MenuItem>
                                                {index !== leftItemsMenu.length - 1 && <Divider light={true} />}
                                            </div>
                                        )
                                    );
                                })}
                            </MenuList>
                        </div>

                        <div
                            // @ts-ignore
                            style={{ overflow: 'auto', ...(dialogRef ? { height: dialogRef.clientHeight - 250 } : {}) }}
                        >
                            {currentComponentAction}
                        </div>
                    </GridBottomDiv>
                </Grid>
                <Grid item className={classes.gridButtonBlock}>
                    <ButtonsBlockForm
                        labelCancelButton={'app.return'}
                        validButtonIsSubmitType={false}
                        onCancel={handleClose}
                        showValidButton={false}
                    />
                </Grid>
            </Grid>
        </DialogTemplate>
    );
};

export default ItemActionDialog;
